import { makeStyles } from '@material-ui/core/styles';

import { black, outrageousOrange } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  subTitle: {
    fontSize: 15,
    lineHeight: '20px',
    opacity: 0.5,
  },
  selectContainer: {
    marginLeft: 0,
    marginBottom: 26,
  },
  buttonRemove: {
    background: 'rgba(255, 99, 51, 0.1)',
  },
  buttonCancel: {
    minWidth: 166,
    background: 'rgba(0, 0, 0, 0.06)',
  },
  buttonRemoveText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: outrageousOrange,
  },
  buttonCancelText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: black,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxRoot: {
    paddingLeft: 0,
  },
  description: {
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    opacity: 0.5,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
  fullWidth: {
    flex: 1,
  },
  halfWidth: {
    width: 166,
  },
});
