import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import saltedgeApi from '../../../services/bankIntegrations/saltedge';
import Storages, { StorageKey } from '../../../services/Storages';
import { useStyles } from './styles';

function ReconnectSuccessDialog({ onClose }: { onClose(): void }) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const saltedgeIntegration = Storages.get(StorageKey.saltedge);
    saltedgeApi.updateConnection({
      integrationCredentialsId: saltedgeIntegration.integrationCredentialsId,
      connectionId: saltedgeIntegration.connectionId,
    });
  }, []);

  return (
    <Dialog isOpened onClose={onClose} title={t('bank.saltedge.authDone')}>
      <Typography className={classes.description}>
        {t('bank.saltedge.nextAuth')}
      </Typography>
      <CustomButton
        fullWidth
        action={onClose}
        className={classes.button}
        title={t('bank.saltedge.wellDone')}
      />
    </Dialog>
  );
}

export default React.memo(ReconnectSuccessDialog);
