import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteSelector from '../../../../components/AutocompleteSelector';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import {
  PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_RU,
  PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_UA,
} from '../../../../constants';
import FacebookPixelContext from '../../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import { getLanguage } from '../../../../selectors/main';
import integrationActions from '../../../../store/integrations/actions';
import {
  getIntegrationInProgress,
  getLoadingIntegrationCredentials,
  getPrivateCreateCredentialsError,
  selectPrivat24BusinessAccountsAutocompleteProps,
  selectPrivat24BusinessIntegrationCredentials,
} from '../../../../store/integrations/selectors';
import { IntegrationTypeId } from '../../../../store/integrations/types';
import { AutoCompleteProps } from '../../../../store/types';
import { minOperationDate } from '../../../../utils/dateFormat';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import CalendarComponent from '../../../Operations/Components/CalendarComponent/CalendarComponent';
import { useStyles } from './styles';
import { Props } from './types';

function Private24BusinessDialog(props: Props) {
  const { onClose, newAccountName, selectedAccountId, onCloseAccountsDialog } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();
  const facebookContext = useContext(FacebookPixelContext);
  const dispatch = useDispatch();

  const prevLoadingCreateIntegration = useRef(false);

  const [showAccountsDialog, setShowAccountsDialog] = useState(false);

  const [autoClientId, setAutoClientId] = useState('');
  const [token, setToken] = useState('');

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [integrationAccount, setIntegrationAccount] =
    useState<AutoCompleteProps | null>(null);

  const [error, setError] = useState({
    token: false,
    autoClientId: false,
    account: false,
    date: false,
  });

  const language = useSelector(getLanguage);
  const integrationAccounts = useSelector(
    selectPrivat24BusinessAccountsAutocompleteProps,
  );
  const integrationCredentials = useSelector(
    selectPrivat24BusinessIntegrationCredentials,
  );
  const loadingIntegrationCredentials = useSelector(
    getLoadingIntegrationCredentials,
  );
  const loadingCreateIntegration = useSelector(getIntegrationInProgress);
  const privateCreateCredentialsError = useSelector(
    getPrivateCreateCredentialsError,
  );

  const handleSetError = useCallback((name: string, value: boolean) => {
    setError((errors) => ({
      ...errors,
      [name]: value,
    }));
  }, []);

  const handleChangeClientId = useCallback(
    (value: string) => {
      setAutoClientId(value);

      handleSetError('autoClientId', false);

      dispatch(integrationActions.resetIntegrationError());
    },
    [handleSetError, dispatch],
  );

  const handleChangeToken = useCallback(
    (value: string) => {
      setToken(value);

      handleSetError('token', false);

      dispatch(integrationActions.resetIntegrationError());
    },
    [handleSetError, dispatch],
  );

  const handleCreateCredentials = useCallback(async () => {
    if (!autoClientId) {
      handleSetError('autoClientId', true);
    }

    if (!token) {
      handleSetError('token', true);
    }

    if (autoClientId && token) {
      dispatch(integrationActions.createCredentials({ autoClientId, token }));
    }
  }, [autoClientId, token, handleSetError, dispatch]);

  const handleSelectAccount = useCallback(
    (item: any) => {
      setIntegrationAccount(item);

      handleSetError('account', false);
    },
    [handleSetError],
  );

  const handleChangeDate = useCallback(
    (value: any) => {
      setSelectedDate(value);

      handleSetError('date', false);
    },
    [handleSetError],
  );

  const handleCreateIntegration = useCallback(() => {
    if (!integrationAccount) {
      handleSetError('account', true);
    }

    if (!selectedDate) {
      handleSetError('date', true);
    }

    if (integrationAccount && selectedDate) {
      dispatch(
        integrationActions.updateCredentials({
          autoClientId,
          token,
          acc: integrationAccount.id,
        }),
      );
    }
  }, [
    token,
    dispatch,
    selectedDate,
    autoClientId,
    handleSetError,
    integrationAccount,
  ]);

  const handleOpenAccountsDialog = useCallback(() => {
    setShowAccountsDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(integrationActions.resetIntegrationError());

    onClose();
  }, [onClose, dispatch]);

  const handleCloseAccountsDialog = useCallback(() => {
    setShowAccountsDialog(false);

    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (integrationCredentials) {
      handleOpenAccountsDialog();
    }
  }, [handleOpenAccountsDialog, integrationCredentials]);

  useEffect(() => {
    if (!loadingCreateIntegration && prevLoadingCreateIntegration.current) {
      if (!selectedDate) {
        return;
      }

      const acc = integrationCredentials?.accounts.find(
        (el) => el.acc === integrationAccount?.id,
      );

      if (newAccountName && acc) {
        dispatch(
          integrationActions.createAccountAndIntegration({
            name: newAccountName,
            currencyId: acc.currency,
            startDate: new Date(selectedDate).getTime() + getTimeOffset(),
            typeId: IntegrationTypeId.Private24Business,
          }),
        );
      } else if (selectedAccountId) {
        dispatch(
          integrationActions.createIntegration({
            typeId: IntegrationTypeId.Private24Business,
            startDate: new Date(selectedDate).getTime() + getTimeOffset(),
            accountId: selectedAccountId,
            facebookContext,
          }),
        );
      }

      handleCloseAccountsDialog();

      if (onCloseAccountsDialog) {
        onCloseAccountsDialog();
      }

      handleClose();
    }

    prevLoadingCreateIntegration.current = loadingCreateIntegration;
  }, [
    dispatch,
    handleClose,
    selectedDate,
    newAccountName,
    facebookContext,
    selectedAccountId,
    integrationAccount,
    onCloseAccountsDialog,
    integrationCredentials,
    loadingCreateIntegration,
    handleCloseAccountsDialog,
  ]);

  if (showAccountsDialog) {
    return (
      <Dialog
        isOpened
        onClose={handleCloseAccountsDialog}
        title={t('integrations.accountSelection')}
      >
        <CalendarComponent
          onChange={handleChangeDate}
          placeholder={t('integrations.dateDescription')}
          // @ts-ignore
          value={selectedDate}
          fullWidth
          maxDate={new Date()}
          minDate={minOperationDate()}
          error={error.date}
          fromIntegration
        />
        <AutocompleteSelector
          label={t('integrations.yourCards')}
          onChange={handleSelectAccount}
          data={integrationAccounts}
          value={integrationAccount}
          error={error.account}
        />
        <CustomButton
          disabled={!autoClientId || !token}
          title={t('privat24business.connect')}
          action={handleCreateIntegration}
          loading={loadingCreateIntegration}
          fullWidth
        />
      </Dialog>
    );
  }

  return (
    <Dialog isOpened onClose={onClose} title={t('privat24business.title')}>
      <Typography className={classes.step}>
        {`${t('privat24business.step')} 1.`}
      </Typography>

      <Typography className={cn(classes.text, classes.marginBottom8)}>
        {`${t('privat24business.description.createIn')} `}
        <a
          href="https://24.privatbank.ua/"
          className={classes.bold}
          target="_blank"
          rel="noreferrer"
        >
          {t('bank.privat24Business')}
        </a>
        <span>{` ${t('privat24business.description.client')}`}</span>
      </Typography>

      <a
        href={
          language === 'ru'
            ? PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_RU
            : PRIVAT_BUSINESS_AUTO_CLIENT_LINK_HELP_UA
        }
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        {` ${t('privat24business.description.instruction')}`}
      </a>

      <Typography className={classes.step}>
        {`${t('privat24business.step')} 2.`}
      </Typography>

      <Typography className={cn(classes.text, classes.marginBottom16)}>
        {t('privat24business.description.second')}
      </Typography>

      <TextFieldComponent
        value={autoClientId}
        onChange={handleChangeClientId}
        label={t('privat24business.clientId')}
        placeholder={t('privat24business.clientId')}
        isError={error.autoClientId}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        value={token}
        type="password"
        onChange={handleChangeToken}
        label={t('privat24business.token')}
        placeholder={t('privat24business.token')}
        isError={error.token}
        errorText={t('errors.reqField')}
        maxLength={500}
        wrapperInputRoot={classes.wrapperInputRoot}
      />

      <CustomButton
        title={t('common.next')}
        action={handleCreateCredentials}
        loading={loadingIntegrationCredentials}
        fullWidth
        errorText={
          (privateCreateCredentialsError &&
            t('integrations.error.privateCreateCredentialsError')) ||
          ''
        }
      />
    </Dialog>
  );
}

export default React.memo(Private24BusinessDialog);
