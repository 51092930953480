import Http from '../Http';

const getCredentials = (apiKey: string, login: string) =>
  Http.makeInstance().post(
    '/integrations/westernbidCredentials/setLoginAndKey',
    {
      apiKey,
      login,
    },
  );

export default {
  getCredentials,
};
