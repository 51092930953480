import { blueRibbon } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: '9px 16px',
  },
  textRoot: {
    flex: 1,
    maxHeight: 56,
  },
  textRootComment: {
    minHeight: 112,
    maxHeight: 'none',
    alignItems: 'flex-start',
  },
  filterComment: {
    padding: '10px 20px 10px 45px',
  },
  inputWithError: {
    marginBottom: 10,
  },
  icon: {
    top: '30%',
    left: '2%',
    position: 'absolute',
  },
  blueBackground: {
    background: 'rgba(6, 91, 255, 0.12)',
    marginBottom: '0 !important',
  },
  blueColor: {
    color: blueRibbon,
    border: 'none',
  },
  amountFlex: {
    flex: 1,
    position: 'relative',
    marginBottom: 0,
  },
  marginBottom32: {
    marginBottom: 32,
  },
});
