import { Typography } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CardIcon from '../../../assets/images/svg/bank-card.svg';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import { SHOW_DOWNLOAD_PAYMENT_RECEIPT } from '../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../hooks/useUnleash';
import { getPaymentHistory } from '../../../store/paymentHistory/actions';
import { PaymentHistoryState } from '../../../store/paymentHistory/types';
import { useStyles } from './styles';
import { Props } from './types';

function SubscriptionHistoryDialog(props: Props) {
  const { onClose } = props;
  const showDownloadPaymentReceipt = useUnleash(SHOW_DOWNLOAD_PAYMENT_RECEIPT);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const paymentHistories = useSelector((state) => state.payment.data);

  useEffect(() => {
    dispatch(getPaymentHistory());
  }, [dispatch]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('subscription.details.paymentHistory')}
    >
      {paymentHistories?.length ? (
        <div className={classes.historyContainer}>
          {paymentHistories.map((paymentHistory: PaymentHistoryState) => (
            <div className={classes.row} key={paymentHistory.paymentDate}>
              <div className={classes.titleWrapper}>
                <Typography className={cn(classes.leftText, classes.title)}>
                  {moment(paymentHistory.paymentDate).format('DD MMM YYYY')}
                </Typography>
              </div>
              <div className={classes.historyContentWrapper}>
                <div className={classes.historyTextWrapper}>
                  <Typography className={classes.leftText}>
                    {t('subscription.rate')}
                  </Typography>
                  <Typography
                    className={cn(classes.leftText, classes.historyValue)}
                  >
                    {paymentHistory.tariffCode}
                  </Typography>
                </div>
                <div className={classes.historyTextWrapper}>
                  <Typography className={classes.leftText}>
                    {t('subscription.term')}
                  </Typography>
                  <Typography
                    className={cn(classes.leftText, classes.historyValue)}
                  >
                    {paymentHistory.period} {paymentHistory.periodUnit}
                  </Typography>
                </div>
                <div className={classes.historyTextWrapper}>
                  <Typography className={classes.leftText}>
                    {t('subscription.price')}
                  </Typography>
                  <Typography
                    className={cn(classes.leftText, classes.historyValue)}
                  >
                    {paymentHistory.currencyCode} {paymentHistory.amount}
                  </Typography>
                </div>
                <div className={classes.historyTextWrapper}>
                  <Typography className={classes.leftText}>
                    {t('subscription.details.paymentMethod')}
                  </Typography>
                  <div className={classes.cardTextWrapper}>
                    <img src={CardIcon} alt="card icon" />
                    <Typography
                      className={cn(classes.leftText, classes.historyValue)}
                    >
                      {paymentHistory.maskedCardNumber}
                    </Typography>
                  </div>
                </div>
                {showDownloadPaymentReceipt && (
                  <div className={classes.historyTextWrapper}>
                    <Typography className={classes.leftText}>
                      {t('subscription.check')}
                    </Typography>
                    <Typography
                      className={cn(classes.leftText, classes.downloadText)}
                    >
                      {t('subscription.downloadPaymentReceipt')}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Loader size="medium" />
      )}
    </Dialog>
  );
}

export default React.memo(SubscriptionHistoryDialog);
