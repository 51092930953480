export enum DebitCreditByDate {
  NOWADAYS = 'nowadays',
  YESTERDAY = 'yesterday',
  END_LAST_MONTH = 'endLastMonth',
  CHOOSE_DATE = 'chooseDate',
}

export type Props = {
  onChangeTimeOfPeriod(date: number | null): void;
};
