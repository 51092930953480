import Http from '../../services/Http';
import {
  CreateAutoRulePayload,
  DeleteAutoRulePayload,
  UpdateAutoRulePayload,
  UpdateAutoRulesOrderPayload,
} from './sagas.types';

const getAutoRulesList = () => Http.makeInstance().get('/rules');

const createAutoRule = (rule: CreateAutoRulePayload) =>
  Http.makeInstance().post('/rules', rule);

const getUserAutoRuleStatus = () => Http.makeInstance().get('/rules/status');

const setUserAutoRulesStatus = (enabled: boolean) =>
  Http.makeInstance().patch(`/rules/status/${enabled}`);

const deleteAutoRule = ({ ruleId }: DeleteAutoRulePayload) =>
  Http.makeInstance().delete(`/rules/${ruleId}`);

const updateAutoRule = (data: UpdateAutoRulePayload) =>
  Http.makeInstance().patch(`/rules/${data.ruleId}`, { ...data.rule });

const updateAutoRulesOrder = (rules: UpdateAutoRulesOrderPayload[]) =>
  Http.makeInstance().patch('/rules/order', { rules });

export default {
  createAutoRule,
  deleteAutoRule,
  updateAutoRule,
  getAutoRulesList,
  updateAutoRulesOrder,
  getUserAutoRuleStatus,
  setUserAutoRulesStatus,
};
