import { CREATE_INTEGRATIONS_PENDING } from './actions';
import { CreateIntegrationPayload } from './sagas.types';

export const SET_BELVO_INTEGRATION_ID = 'SET_BELVO_INTEGRATION_ID';

const setBelvoIntegrationId = (id: string) => ({
  type: SET_BELVO_INTEGRATION_ID,
  payload: { id },
});

const createBelvoIntegration = (data: CreateIntegrationPayload) => ({
  type: CREATE_INTEGRATIONS_PENDING,
  payload: data,
});

export default {
  setBelvoIntegrationId,
  createBelvoIntegration,
};
