import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze, blueRibbon, white } from '../../../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '9px 0 9px 16px',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  amountContainer: {
    marginBottom: '0 !important',
    flex: 1,
    transition: 'width 700ms',
    marginRight: 8,
  },
  currenciesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 56,
    marginBottom: 0,
    borderRadius: 12,
    background: aquaHaze,
    paddingLeft: 16,
    paddingRight: 15,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  rateContainer: {
    width: 204,
    marginBottom: 0,
    [theme.breakpoints.down(768)]: {
      width: '44%',
      marginRight: 8,
    },
  },
  accountAmountContainer: {
    background: white,
    border: '1px solid black',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  revertIcon: {
    cursor: 'pointer',
    paddingRight: 17,
  },
  blueBackground: {
    background: 'rgba(6, 91, 255, 0.12)',
  },
  blueColor: {
    color: blueRibbon,
    border: 'none',
  },
  amountFlex: {
    flex: 1,
    position: 'relative',
  },
  marginBottom32: {
    marginBottom: 32,
  },
  disabledInput: {
    cursor: 'unset',
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  disabledInputOpacity: {
    opacity: 0.1,
  },
  amountError: {
    left: 0,
    whiteSpace: 'nowrap',
  },
}));
