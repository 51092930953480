import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  container: {
    width: '100%',
    marginTop: 100,
    height: '100%',
    position: 'relative',
  },
});
