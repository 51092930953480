import { Typography } from '@material-ui/core';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RevertIcon from '../../../../assets/images/svg/revert-course.svg';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import TransitionComponent from '../../../../components/TransitionComponent';
import usePermissions from '../../../../hooks/usePermissions';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { AccountValue } from '../../../../store/accounts/types';
import { selectCompanyCurrency } from '../../../../store/company/selectors';
import {
  selectCryptoCurrenciesForOperationAutoComplete,
  selectCryptoCurrencyCodes,
  selectCurrenciesForOperationAutoComplete,
} from '../../../../store/currency/selectors';
import nextOperationActions from '../../../../store/historyOperationReducer/actions';
import currentOperationActions from '../../../../store/operations/actions';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../../store/operations/selectors';
import { formatStringPrice } from '../../../../utils/parseStringToNumber';
import { useCompareStyles } from '../compareStyles';
import PopperCurrencySelector from '../PopperCurrencySelector';
import { useStyles } from './styles';
import { Props } from './types';

const inputProps: StandardInputProps['inputProps'] = {
  inputMode: 'decimal',
};

function AmountComponent(props: Props) {
  const {
    isEdit,
    isError,
    isCompare,
    difference,
    isNewState,
    ownCurrencies,
    operationCurrencyId,
    operationCurrencySymbol,
    allowShowCurrenciesBlock,
    customCompanyAmountCurrencyPlaceholder,
  } = props;

  const prevLoadingRates = useRef(false);
  const prevAccount = useRef<AccountValue | null>(null);

  const [rateWasChanged, setRateWasChanged] = useState(false);

  const opProps = useSelector(getOperationProps);
  const nextOperationProps = useSelector(getHistoryOperationProps);

  const operationProps = isNewState ? nextOperationProps : opProps;
  const operationActions = isNewState
    ? nextOperationActions
    : currentOperationActions;

  const {
    botId,
    amount,
    account,
    externalId,
    revertState,
    exchangeRate,
    loadingRates,
    integrationId,
    transactionSum,
    createAndCopyState,
    transactionCurrency,
    revertedExchangeRate,
    currencyValue: currency,
    transactionCurrencyRate,
    currencyAmount: companyAmount,
  } = operationProps;

  if (!prevAccount.current && account) {
    prevAccount.current = account;
  }

  const companyCurrency = useSelector(selectCompanyCurrency);
  const allCurrencies = useSelector(selectCurrenciesForOperationAutoComplete);

  const currencies = ownCurrencies || allCurrencies;

  const cryptoCurrencies = useSelector(
    selectCryptoCurrenciesForOperationAutoComplete,
  );
  const cryptoCurrencyIds = useSelector(selectCryptoCurrencyCodes);

  let updatedCryptoCurrenciesList = [...cryptoCurrencies];

  if (cryptoCurrencyIds.includes(account?.currency._id || '')) {
    updatedCryptoCurrenciesList = cryptoCurrencies.filter(
      (el) => el.symbol === account?.currency.symbol || '',
    );
  }

  const isCrypto = account?.crypto;

  const balanceDigits = isCrypto ? 9 : 3;
  const exchangeRageDigits = isCrypto ? 9 : 9;

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const accountCurrency = account?.currency;

  const dispatch = useDispatch();

  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const { t } = useTranslation();
  const classes = useStyles();
  const compareClasses = useCompareStyles();

  const disableCurrencySelector =
    (allowShowCurrenciesBlock &&
      accountCurrency?._id !== operationCurrencyId) ||
    isCompare;

  const [showCurrenciesList, setShowCurrenciesList] = useState(false);
  const [isDifferentCurrency, setIsDifferentCurrency] = useState(
    accountCurrency && accountCurrency._id !== currency?.id && !!externalId,
  );

  const currencyValueSymbol = currency?.symbol || '';
  const accountCurrencySymbol = accountCurrency?.symbol || '';

  const handleCloseCurrenciesList = useCallback(() => {
    setShowCurrenciesList(false);
  }, []);

  const handleToggleCurrenciesList = useCallback(() => {
    if (
      subscriptionActive &&
      operationEnable &&
      !disableCurrencySelector &&
      (!externalId || botId)
    ) {
      setShowCurrenciesList(!showCurrenciesList);
    }
  }, [
    botId,
    externalId,
    operationEnable,
    showCurrenciesList,
    subscriptionActive,
    disableCurrencySelector,
  ]);

  const handleChangeAmount = useCallback(
    (val: string | number) => {
      const amountValue = formatStringPrice(val, false, balanceDigits);

      dispatch(operationActions.setAmount({ amount: amountValue }));

      if (exchangeRate && isDifferentCurrency) {
        if (revertState && revertedExchangeRate) {
          dispatch(
            operationActions.setCurrencyAmount({
              currencyAmount: formatStringPrice(
                parseFloat(amountValue) / parseFloat(revertedExchangeRate),
                false,
                balanceDigits,
              ),
            }),
          );
        } else {
          dispatch(
            operationActions.setCurrencyAmount({
              currencyAmount: formatStringPrice(
                parseFloat(amountValue) * parseFloat(exchangeRate),
                false,
                balanceDigits,
              ),
            }),
          );
        }
      } else {
        dispatch(
          operationActions.setCurrencyAmount({ currencyAmount: amountValue }),
        );
      }
    },
    [
      dispatch,
      revertState,
      exchangeRate,
      balanceDigits,
      operationActions,
      isDifferentCurrency,
      revertedExchangeRate,
    ],
  );

  const handleChangeExchangeRate = useCallback(
    (value: string | number) => {
      const rateValue = formatStringPrice(value, false, exchangeRageDigits);

      if (rateValue) {
        if (revertState) {
          dispatch(
            operationActions.setRevertedExchangeRate({
              revertedExchangeRate: rateValue,
            }),
          );
          dispatch(
            operationActions.setExchangeRate({
              // @ts-ignore
              exchangeRate: 1 / rateValue,
            }),
          );
        } else {
          dispatch(
            operationActions.setRevertedExchangeRate({
              // @ts-ignore
              revertedExchangeRate: 1 / rateValue,
            }),
          );
          dispatch(
            operationActions.setExchangeRate({
              exchangeRate: rateValue,
            }),
          );
        }
      } else {
        dispatch(
          operationActions.setRevertedExchangeRate({
            revertedExchangeRate: null,
          }),
        );
        dispatch(
          operationActions.setExchangeRate({
            exchangeRate: null,
          }),
        );
      }

      if (amount) {
        const currencyAmountValue = revertState
          ? parseFloat(amount) / parseFloat(rateValue)
          : parseFloat(amount) * parseFloat(rateValue);

        dispatch(
          operationActions.setCurrencyAmount({
            currencyAmount: formatStringPrice(
              currencyAmountValue,
              false,
              balanceDigits,
            ),
          }),
        );
      }
    },
    [
      amount,
      dispatch,
      revertState,
      balanceDigits,
      operationActions,
      exchangeRageDigits,
    ],
  );

  const handleChangeCompanyAmount = useCallback(
    (value: string | number) => {
      const currencyAmountValue = formatStringPrice(
        value,
        false,
        balanceDigits,
      );

      dispatch(
        operationActions.setCurrencyAmount({
          currencyAmount: currencyAmountValue,
        }),
      );

      if (amount) {
        const rate = parseFloat(currencyAmountValue) / parseFloat(amount);
        const revertedRate =
          parseFloat(amount) / parseFloat(currencyAmountValue);

        dispatch(
          operationActions.setExchangeRate({
            exchangeRate: formatStringPrice(rate, false, exchangeRageDigits),
          }),
        );
        dispatch(
          operationActions.setRevertedExchangeRate({
            revertedExchangeRate: formatStringPrice(
              revertedRate,
              false,
              exchangeRageDigits,
            ),
          }),
        );
      }

      setRateWasChanged(true);
    },
    [amount, dispatch, balanceDigits, operationActions, exchangeRageDigits],
  );

  const handleChangeCurrency = useCallback(
    (id: string) => {
      const value = [...currencies, ...cryptoCurrencies].find(
        (el) => el.id === id,
      );

      if (!value) {
        return;
      }

      dispatch(
        operationActions.setCurrencyValue({
          currencyValue: value,
        }),
      );

      dispatch(operationActions.setExchangeRate({ exchangeRate: null }));
      dispatch(
        operationActions.setRevertedExchangeRate({
          revertedExchangeRate: null,
        }),
      );

      if (accountCurrency) {
        if (accountCurrency._id !== id) {
          dispatch(
            operationActions.getExchangeRateFromTo({
              from: id,
              to: accountCurrency._id,
            }),
          );
          dispatch(
            operationActions.getExchangeRateToFrom({
              from: id,
              to: accountCurrency._id,
            }),
          );
        } else if (companyCurrency && accountCurrency._id === id) {
          dispatch(
            operationActions.getExchangeRateFromTo({
              from: accountCurrency._id,
              to: companyCurrency._id,
            }),
          );
        }
      } else if (companyCurrency && companyCurrency._id !== id) {
        dispatch(
          operationActions.getExchangeRateFromTo({
            from: id,
            to: companyCurrency._id,
          }),
        );
      } else {
        dispatch(operationActions.setExchangeRate({ exchangeRate: null }));
      }

      dispatch(operationActions.setRevertState(false));

      setIsDifferentCurrency(
        (!!accountCurrency && accountCurrency._id !== id) || !!externalId,
      );

      handleCloseCurrenciesList();
    },
    [
      dispatch,
      currencies,
      externalId,
      companyCurrency,
      accountCurrency,
      cryptoCurrencies,
      operationActions,
      handleCloseCurrenciesList,
    ],
  );

  const handleResetCreateAndCopyState = useCallback(() => {
    dispatch(
      operationActions.setCreateAndCopyState({
        createAndCopyState: false,
      }),
    );
  }, [dispatch, operationActions]);

  const handleClickRevertExchangeRate = useCallback(() => {
    if (exchangeRate) {
      let rate = Number(exchangeRate);
      let revertedRate = Number(revertedExchangeRate);

      if (rateWasChanged && amount && companyAmount) {
        rate = parseFloat(companyAmount) / parseFloat(amount);
        revertedRate = parseFloat(amount) / parseFloat(companyAmount);
      }

      dispatch(
        operationActions.setExchangeRate({
          exchangeRate: formatStringPrice(rate, false, exchangeRageDigits),
        }),
      );

      dispatch(
        operationActions.setRevertedExchangeRate({
          revertedExchangeRate: formatStringPrice(
            revertedRate,
            false,
            exchangeRageDigits,
          ),
        }),
      );
    }

    dispatch(operationActions.setRevertState(!revertState));
  }, [
    amount,
    dispatch,
    revertState,
    exchangeRate,
    companyAmount,
    rateWasChanged,
    operationActions,
    exchangeRageDigits,
    revertedExchangeRate,
  ]);

  useEffect(() => {
    if (!loadingRates && prevLoadingRates.current && exchangeRate && amount) {
      dispatch(
        operationActions.setCurrencyAmount({
          currencyAmount: formatStringPrice(
            parseFloat(exchangeRate) * parseFloat(amount),
            false,
            balanceDigits,
          ),
        }),
      );

      prevLoadingRates.current = false;
    }
  }, [
    amount,
    dispatch,
    loadingRates,
    exchangeRate,
    balanceDigits,
    operationActions,
  ]);

  useEffect(() => {
    if (loadingRates) {
      prevLoadingRates.current = loadingRates;
    }
  }, [loadingRates]);

  useEffect(() => {
    if (account) {
      if (!isEdit || prevAccount.current?._id !== account._id) {
        dispatch(
          operationActions.setCurrencyValue({
            currencyValue: {
              id: account.currency._id,
              symbol: account.currency.symbol,
              name: account.currency.name,
              label: account.currency.label,
            },
          }),
        );
      }
    }
  }, [account, dispatch, operationActions, isEdit]);

  useEffect(() => {
    if (externalId && !botId && account) {
      dispatch(
        operationActions.setCurrencyValue({
          currencyValue: {
            id: account.currency._id,
            symbol: account.currency.symbol,
            name: account.currency.name,
            label: account.currency.label,
          },
        }),
      );
    }
  }, [botId, account, dispatch, externalId, operationActions]);

  useEffect(() => {
    if (isEdit && transactionCurrency) {
      const value = [...currencies, ...cryptoCurrencies].find(
        (el) => el.id === transactionCurrency,
      );

      if (value) {
        setIsDifferentCurrency(true);

        dispatch(
          operationActions.setCurrencyValue({
            currencyValue: value,
          }),
        );

        if (transactionCurrencyRate) {
          dispatch(
            operationActions.setExchangeRate({
              exchangeRate: String(transactionCurrencyRate),
            }),
          );
        }

        if (transactionSum) {
          dispatch(
            operationActions.setAmount({
              amount: String(transactionSum),
            }),
          );
        }
      }
    }
  }, [
    isEdit,
    dispatch,
    currencies,
    transactionSum,
    cryptoCurrencies,
    operationActions,
    transactionCurrency,
    transactionCurrencyRate,
  ]);

  useEffect(() => {
    if (accountCurrency) {
      if (
        externalId ||
        (currency && accountCurrency._id !== currency.id) ||
        (allowShowCurrenciesBlock &&
          operationCurrencyId &&
          accountCurrency._id !== operationCurrencyId)
      ) {
        setIsDifferentCurrency(true);
      } else {
        setIsDifferentCurrency(false);
      }
    }
  }, [
    externalId,
    accountCurrency,
    allowShowCurrenciesBlock,
    currency,
    operationCurrencyId,
  ]);

  useEffect(() => {
    if (
      externalId &&
      amount &&
      companyAmount &&
      companyAmount === amount.toString() &&
      accountCurrency?._id === companyCurrency?._id
    ) {
      setIsDifferentCurrency(false);
    }

    if (
      !externalId &&
      accountCurrency?._id === currency?.id &&
      accountCurrency?._id !== companyCurrency?._id
    ) {
      setIsDifferentCurrency(true);
    }
  }, [
    amount,
    currency,
    externalId,
    companyAmount,
    companyCurrency,
    accountCurrency,
  ]);

  useEffect(() => {
    if (
      !isEdit &&
      companyCurrency &&
      accountCurrency &&
      companyCurrency._id !== accountCurrency._id
    ) {
      dispatch(operationActions.setExchangeRate({ exchangeRate: null }));
      dispatch(
        operationActions.setRevertedExchangeRate({
          revertedExchangeRate: null,
        }),
      );

      dispatch(
        operationActions.getExchangeRateFromTo({
          to: companyCurrency._id,
          from: accountCurrency._id,
        }),
      );

      dispatch(
        operationActions.getExchangeRateToFrom({
          to: companyCurrency._id,
          from: accountCurrency._id,
        }),
      );
    }
  }, [isEdit, dispatch, accountCurrency, companyCurrency, operationActions]);

  let companyAmountPlaceholder =
    externalId && !botId
      ? companyCurrency?.symbol
      : operationCurrencySymbol || accountCurrencySymbol;

  if (accountCurrency?._id === currency?.id && (!externalId || botId)) {
    companyAmountPlaceholder = companyCurrency?.symbol;
  }

  let exchangeRateTitle = revertState
    ? `${
        customCompanyAmountCurrencyPlaceholder ?? companyAmountPlaceholder
      } / ${currencyValueSymbol}`
    : `${currencyValueSymbol} / ${
        customCompanyAmountCurrencyPlaceholder ?? companyAmountPlaceholder
      }`;

  if (externalId) {
    exchangeRateTitle = revertState
      ? `${accountCurrencySymbol} / ${companyCurrency?.symbol}`
      : `${companyCurrency?.symbol} / ${accountCurrencySymbol}`;
  }

  return (
    <>
      <div
        className={cn(
          classes.flexRow,
          createAndCopyState && classes.marginBottom32,
        )}
      >
        <div
          onFocus={handleResetCreateAndCopyState}
          className={classes.amountFlex}
        >
          <TextFieldComponent
            isError={isError}
            id="cypress-operation-amount"
            disabled={!subscriptionActive || !operationEnable || isCompare}
            rootClassName={cn(
              classes.amountContainer,
              difference?.sumDouble && compareClasses.root,
              createAndCopyState && classes.blueBackground,
              (!subscriptionActive || isCompare || !operationEnable) &&
                classes.disabledInput,
            )}
            value={amount ?? ''}
            placeholder={`${t('common.sum')}, ${currencyValueSymbol}`}
            onChange={handleChangeAmount}
            inputProps={inputProps}
          />
          <TransitionComponent
            className={classes.blueColor}
            enter={createAndCopyState}
            text={t('operationDialogs.setPaymentAmount')}
          />
          <TransitionComponent
            className={classes.amountError}
            enter={!!isError}
            text={t('system.fieldMustFilled')}
          />
        </div>
        <div
          className={cn(
            classes.currenciesContainer,
            // @ts-ignore
            difference?.currencyValue && compareClasses.root,
            !disableCurrencySelector && classes.cursorPointer,
            (!subscriptionActive ||
              isCompare ||
              (integrationId && !botId) ||
              !operationEnable) &&
              classes.disabledInput,
          )}
          ref={anchorRef}
          onClick={handleToggleCurrenciesList}
        >
          <Typography
            className={cn(
              (!subscriptionActive || isCompare || !operationEnable) &&
                classes.disabledInputOpacity,
            )}
          >
            {`${currency?.id} (${currency?.symbol})`}
          </Typography>
          {!disableCurrencySelector &&
            showCurrenciesList &&
            (!externalId || botId) && (
              <ArrowDropUpIcon
                className={cn(
                  (!subscriptionActive || isCompare || !operationEnable) &&
                    classes.disabledInputOpacity,
                )}
              />
            )}
          {!disableCurrencySelector &&
            !showCurrenciesList &&
            (!externalId || botId) && (
              <ArrowDropDownIcon
                className={cn(
                  (!subscriptionActive || isCompare || !operationEnable) &&
                    classes.disabledInputOpacity,
                )}
              />
            )}
        </div>
      </div>
      {isDifferentCurrency && (
        <div className={classes.flexRow}>
          <TextFieldComponent
            disabled={!subscriptionActive || isCompare || !operationEnable}
            rootClassName={cn(
              classes.rateContainer,
              difference?.exchangeRate && compareClasses.root,
              (!subscriptionActive || isCompare || !operationEnable) &&
                classes.disabledInput,
            )}
            containerClassName={classes.container}
            value={(revertState ? revertedExchangeRate : exchangeRate) || ''}
            placeholder={`${t('common.rate')} ${exchangeRateTitle}`}
            onChange={handleChangeExchangeRate}
            AdornmentComponent={
              <img
                src={RevertIcon}
                alt="revert"
                className={classes.revertIcon}
                onClick={handleClickRevertExchangeRate}
              />
            }
            loading={loadingRates}
            inputProps={inputProps}
          />
          <TextFieldComponent
            rootClassName={cn(
              classes.rateContainer,
              classes.accountAmountContainer,
              // @ts-ignore
              difference?.currencyAmount && compareClasses.root,
              (!subscriptionActive || isCompare || !operationEnable) &&
                classes.disabledInput,
            )}
            disabled={!subscriptionActive || isCompare || !operationEnable}
            containerClassName={classes.container}
            placeholder={`${t('common.sum')}, ${
              customCompanyAmountCurrencyPlaceholder ?? companyAmountPlaceholder
            }`}
            value={companyAmount || ''}
            onChange={handleChangeCompanyAmount}
            inputProps={inputProps}
          />
        </div>
      )}
      {showCurrenciesList && (
        <PopperCurrencySelector
          anchorRef={anchorRef}
          onClose={handleCloseCurrenciesList}
          selectedCurrency={currency}
          cryptoCurrenciesList={updatedCryptoCurrenciesList}
          currencies={currencies}
          ownCurrencies={ownCurrencies}
          onChangeCurrency={handleChangeCurrency}
        />
      )}
    </>
  );
}

export default React.memo(AmountComponent);
