import Dialog from '../../wrappers/dialog';
import ChangePasswordScene from './components/change-user-password-scene';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ChangePWDialog({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();

  return (
    <Dialog isOpened onClose={onClose} title={t('userMenu.changePassword')}>
      <ChangePasswordScene />
    </Dialog>
  );
}

export default React.memo(ChangePWDialog);