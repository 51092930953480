import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { CRYPTO_EXCHANGE_RATE_DIGITS,EXCHANGE_RATE_DIGITS } from "../../../../../../constants";
import operationActions from "../../../../../../store/operations/actions";
import { formatStringPrice } from "../../../../../../utils/parseStringToNumber";

function formatValue(value: number | string, isCrypto: boolean) {
  return formatStringPrice(value, false, isCrypto
    ? CRYPTO_EXCHANGE_RATE_DIGITS
    : EXCHANGE_RATE_DIGITS
  );
}

function useChangeRates(revertState: boolean, isCrypto: boolean) {
  const dispatch = useDispatch();

  return useCallback((rate: number | string | null) => {
    if (!rate) {
      dispatch(
        operationActions.setRevertedExchangeRate({ revertedExchangeRate: null }),
      );

      dispatch(
        operationActions.setExchangeRate({ exchangeRate: null }),
      );

      return;
    }

    if (revertState) {
      dispatch(
        operationActions.setRevertedExchangeRate({ revertedExchangeRate: formatValue(rate, isCrypto) }),
      );

      dispatch(
        operationActions.setExchangeRate({ exchangeRate: formatValue(1 / Number(rate), isCrypto) }),
      );
    } else {
      dispatch(
        operationActions.setExchangeRate({ exchangeRate: formatValue(rate, isCrypto) }),
      );

      dispatch(
        operationActions.setRevertedExchangeRate({ revertedExchangeRate: formatValue(1 / Number(rate), isCrypto) }),
      );
    }
  }, [dispatch, revertState, isCrypto]);
}

export default useChangeRates;
