import { isInteger, round } from 'lodash';

export const getBalance = (
  balanceValue: string | number | null,
  digits = 2,
) => {
  if (balanceValue === 0 || balanceValue === '0') {
    return 0;
  }

  if (balanceValue) {
    return round(Number(balanceValue), digits)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return null;
};

export const rates = (balanceValue: string | number | null) => {
  if (balanceValue === 0 || balanceValue === '0') {
    return 0;
  }

  if (balanceValue) {
    if (!isInteger(balanceValue)) {
      return round(Number(balanceValue), 5);
    }
    return balanceValue;
  }

  return null;
};
