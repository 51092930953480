import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ALLOW_CREATE_COMPANY_FROM_PREVIEW } from '../../constants/featureToggles/featureToggle';
import useUnleash from '../../hooks/useUnleash';
import { getLanguage } from '../../selectors/main';
import { selectIsDemoCompany } from '../../store/company/selectors';
import { isPreviewPage } from '../../utils/isLocalhost';
import AlertDialog from '../AlertDialog';
import CustomButton from '../Button';
import Dialog from '../Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';

function PreviewRegisterDialog(props: Props) {
  const { onClose, callback, shouldShowDemoAlertDialog = true } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const language = useSelector(getLanguage);
  const isDemoCompany = useSelector(selectIsDemoCompany);

  const featureAllowCompanyCreateEnable = useUnleash(
    ALLOW_CREATE_COMPANY_FROM_PREVIEW,
  );

  const handleClick = useCallback(() => {
    window.open(
      `https://my.finmap.online/auth/register/${language}?utm-source=preview/`,
      '_blank',
    );
  }, [language]);

  useEffect(() => {
    if (!featureAllowCompanyCreateEnable || !isPreviewPage()) {
      if (callback && (!isDemoCompany || !shouldShowDemoAlertDialog)) {
        callback();
      }
    }
  }, [
    callback,
    isDemoCompany,
    shouldShowDemoAlertDialog,
    featureAllowCompanyCreateEnable,
  ]);

  if (shouldShowDemoAlertDialog && isDemoCompany) {
    return (
      <AlertDialog
        onClose={onClose}
        title={t('company.demoCompany.title')}
        description={t('company.demoCompany.description')}
        onAccept={onClose}
        acceptText={t('subscription.cancel.cool')}
      />
    );
  }

  if (featureAllowCompanyCreateEnable && isPreviewPage()) {
    return (
      <Dialog onClose={onClose} isOpened title={t('preview.register.title')}>
        <Typography className={classes.description}>
          {t('preview.register.description')}
        </Typography>
        <CustomButton
          fullWidth
          action={handleClick}
          title={t('preview.register.action')}
        />
      </Dialog>
    );
  }

  return null;
}

export default React.memo(PreviewRegisterDialog);
