import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
export class SilverbirdBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          const modifiedDocument = importDocument.slice(1).flatMap((item) => {
            const duplicateItem = [...item];
            duplicateItem[1] = 'Fee';
            return [item, duplicateItem];
          });
          this.setDocumentBody(modifiedDocument);
          return (): ImportResultItemMask => {
            const date = moment(
              this.getFirstValidCellByColumn(['Date', 0]),
              'D MMMM YYYY',
              'en',
            ).format('DD.MM.YYYY');

            let debit, credit;
            const comment = this.getFirstValidCellByColumn([
              'Transaction Description',
              1,
            ])?.split('\n')[0];
            const debitArr = this.getFirstValidCellByColumn([
              'Paid Out',
              2,
            ])?.split('\n');
            const creditArr = this.getFirstValidCellByColumn([
              'Paid In',
              3,
            ])?.split('\n');

            if (comment === 'Fee') {
              debit = debitArr ? debitArr[1] : creditArr[1];
            } else {
              debit = debitArr?.[0];
              credit = creditArr?.[0];
            }
            return {
              date,
              comment,
              debit,
              credit,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            interlineSpacingAccuracy: 2,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Date') &&
                  etc?.nextWord?.includes('Transaction Description'),
                (word, etc) =>
                  word?.includes('Fee') && etc?.prevWord?.includes('Fee'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Fee') && etc?.prevWord?.includes('Fee'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word?.startsWith('Total Paid Out'),
                undefined,
              );
              self.defineOperation([
                (value) => /([\d]{1,2}\s[\w]+\s[\d]{4})/.test(value), // 6 September 2023
              ]);
            },
          },
        },
      },
    ],
  };
}
