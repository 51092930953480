import moment from 'moment';

import {
  AnyObject,
  BaseXlsxParser,
} from '@finmap/import-parsers/base-xlsx-parser';

import { IAccount, IStatementOptions } from './base-pdf-parser-types';

export class BasePdfParser extends BaseXlsxParser {
  protected startObj;
  protected COORDINATES = [];
  protected SPEC_SYMBOLS = {
    '%2C': ',',
    '%3A': ':',
    '%2B': '+',
    '%2F': '/',
    '%26': '&',
    '%3B': ';',
    '&quot;': '"',
    '%23': '#',
    ' ': ' ',
  };

  protected COLUMNS_MAP = {};
  protected POSITIONS = [];
  protected DATE_FORMAT = 'DD.MM.YYYY';
  protected MULTY_FIELDS = [];
  protected EXCLUDE_FIELDS = [];
  protected stopAddOperations = false;
  protected currency = undefined;
  protected sheetJson: AnyObject[];

  protected statementsOptions: Array<IStatementOptions> = [];

  protected importName = 'Pdf-parser';
  protected mainAccount: IAccount;
  protected beforeTransformed: AnyObject[];

  protected haSMoreThanOneTypeOfStatement = false;
  protected statementName: string;

  public setRawData(raw, account) {
    this.mainAccount = account;
    const data = typeof raw === 'string' ? JSON.parse(raw) : raw;
    this.sheetJson = this.parseToJson(data);
    this.startObj = this.getStartY(this.sheetJson);
    if (!this.startObj) return; //error invalid file
  }

  public import(acounts: AnyObject[]) {
    this.acounts = acounts;
    this.beforeTransformed = this.doBeforeTranform();
    this.transformedData = this.beforeTransformed
      .map(this.transformOne.bind(this))
      .filter((res) => Object.keys(res).length);
    const result = this.doAfterTranform();
    return result;
  }

  protected parseToJson(pages) {
    const lines = [];
    let counter = 0;
    for (const Texts of pages) {
      lines[counter] = {};
      for (const { R, x, y, w, sw, A } of Texts) {
        if (!R) continue;
        for (const { T, S, TS } of R) {
          if (!T) continue;
          if (!lines[counter][y]) lines[counter][y] = [];
          let text = decodeURIComponent(T);
          Object.keys(this.SPEC_SYMBOLS).forEach(
            (key) =>
              (text = (text as any).replaceAll(key, this.SPEC_SYMBOLS[key])),
          );
          lines[counter][y].push({ text, x, w, sw, A, S, TS });
        }
      }
      const sortedLine = Object.keys(lines[counter])
        .sort((a, b) => Number(a) - Number(b))
        .reduce((acc, cur) => {
          acc[cur] = lines[counter][cur];
          return acc;
        }, {});
      lines[counter] = sortedLine;
      counter++;
    }
    return lines;
  }

  protected getStartY(items) {
    const rawLines = Object.keys(this.COLUMNS_MAP).map((key) => ({
      column: this.COLUMNS_MAP[key].map((el) =>
        (el as any).replaceAll(' ', ''),
      ),
      key,
    }));
    for (let i = 0; i < items.length; i++) {
      //const allData of items){
      const allData = items[i];
      for (const key of Object.keys(allData)) {
        const line = allData[key].map((line) =>
          (line.text as any).replaceAll(' ', ''),
        );
        if (line.length < rawLines.length) continue;
        let finded = true;
        const positions = [];
        for (const { column, key } of rawLines) {
          let position = 0;
          if (
            !column.reduce((acc, cur) => {
              return (
                acc ||
                !!line.find((str, i) => {
                  if (str === cur) position = i;
                  return str === cur;
                })
              );
            }, false)
          ) {
            finded = false;
            break;
          }
          positions[position] = key;
        }
        if (!finded) continue;
        return { y: key, page: i, positions };
      }
    }
    return null;
  }

  protected doBeforeTranform() {
    const beforeTransformed = [];
    for (let i = 0; i < this.sheetJson.length; i++) {
      const res = {};
      const page = this.sheetJson[i];
      const keys = Object.keys(page).map((key) => Number(key));
      for (let j = 0; j < keys.length; j++) {
        const currentKey = keys[j];
        const finded = Object.keys(res)
          .map((key) => Number(key))
          .find((resKey) => Math.abs(resKey - currentKey) < 0.03);
        if (finded) {
          res[finded].push(...page[currentKey]);
        } else {
          res[currentKey] = page[currentKey];
        }
      }
      beforeTransformed[i] = res;
    }
    const rawResult = [];
    for (let i = 0; i < beforeTransformed.length; i++) {
      const page = beforeTransformed[i];
      Object.keys(page).forEach((key, index) => {
        if (
          this.startObj &&
          Number(key) <= Number(this.startObj.y) &&
          i === this.startObj.page
        )
          return;
        const operation = {};
        page[key].forEach((rawOp) => {
          const x = rawOp.x;
          let column;
          this.COORDINATES.forEach((coord, i) => {
            if (i === this.COORDINATES.length - 1) return;
            if (coord < x && this.COORDINATES[i + 1] > x) column = i;
          });
          let position = this.POSITIONS.length
            ? this.POSITIONS
            : this.startObj.positions;
          if (position[column] && !this.EXCLUDE_FIELDS.includes(rawOp.text)) {
            if (this.MULTY_FIELDS.includes(position[column])) {
              if (operation[position[column]])
                operation[position[column]].push(rawOp.text);
              else operation[position[column]] = [rawOp.text];
            } else {
              if (operation[position[column]])
                operation[position[column]] += ' ' + rawOp.text;
              else operation[position[column]] = rawOp.text;
            }
          }
        });
        rawResult.push(operation);
      });
    }
    const result = [];
    for (let i = 0; i < rawResult.length; i++) {
      if (this.stopAddOperations) continue;
      const operation = rawResult[i];
      if (operation.date) {
        if (this.beforeTransformFilter(operation, result))
          result.push(operation);
        continue;
      }
      for (const key of this.MULTY_FIELDS) {
        if (
          operation[key] &&
          result[result.length - 1] &&
          !this.EXCLUDE_FIELDS.includes(result[result.length - 1][key])
        ) {
          if (!result[result.length - 1][key])
            result[result.length - 1][key] = [];
          result[result.length - 1][key].push(...operation[key]);
        }
      }
    }
    return result;
  }

  protected beforeTransformFilter(operation, result) {
    return moment(operation.date, this.DATE_FORMAT, true).isValid();
  }

  protected setStatementOptions() {
    const statementOptions = this.haSMoreThanOneTypeOfStatement
      ? this.determineStatementType()
      : this.statementsOptions[0];

    if (!statementOptions) this.throwError('Unsupported statement type', 0);

    const { columnsMap, coordinatesOfColumnBorder, currency, name } =
      statementOptions;

    this.COLUMNS_MAP = columnsMap;
    this.COORDINATES = coordinatesOfColumnBorder;
    this.currency = currency;
    this.statementName = name;
    this.startObj = this.getStartY(this.sheetJson);
  }

  protected determineStatementType() {
    const firstPDFPage = this.sheetJson[0];

    for (const statementOptions of this.statementsOptions) {
      const { y, yRawIndex, identifyingText } = statementOptions.typeIdentifier;

      const searchingRawText =
        firstPDFPage[y] &&
        firstPDFPage[y][yRawIndex] &&
        firstPDFPage[y][yRawIndex].text
          ? firstPDFPage[y][yRawIndex].text
          : undefined;

      if (searchingRawText && searchingRawText === identifyingText)
        return statementOptions;
    }

    return undefined;
  }
}
