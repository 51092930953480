export enum PipedriveKeys {
  PipedriveId = 'id',
  CommunicationLanguage = 'aac137eecf06b313ea451ca9349b245bf7048377',
  TotalWebSessions = '325fbb6c6575478a44ced8f5c6642329922bf737',
  WebSessionsLast30Days = '67de6ac84c3fb4793994afcece026b2d818bfb00',
  WebSessionsLast5Days = '901f927d2cf401a48fb0a7daa390b4716a8bc0c1',
  FinmapId = '069d1a54797940fdda1b798fc88ffda9373dac70',
  LastLogin = 'aa01c7a48944f8c588089168ac3b66854c233aa3',
  LastTransactionAddedDate = '791aeea7f015fe58572be7538c66586810d4aa75',
  PayBefore = '5d391d46a45c0af242cd80ed4ae2c7db7f265d82',
  Score = 'b03d910c92248f71faaf8950af48a2addac4737e',
  Timezone = '269dfd7d05bcef14c95d214d8341748798ef9ec3',
  TransactionsAdded = 'db7a3853dfab7f8de85b399aabdce54825816eb0',
  TransactionsAddedLast7Days = 'e408d5a5048bbd13b8f252d8af7112abafbd58c4',
  UtmCampaign = '617788c5068a48c40edec294da8716d2b68f84b0',
  UtmContent = '93c59dac7c738a180d65b69b2c000460a78cae7c',
  UtmMedium = '376eeeb3115de1b7f30b41811b3146c2844ddf48',
  UtmSource = '1270774a4c06b5b037295365f4cd862ffa64cc7e',
  UtmTerm = '9f3933d460cd2d65b62824eaa81f8141113d8deb',
  Active = '4705bded3b2ea013546a7a92ec5b9233b6c517b8',
  IntegrationsCount = 'bc1d6bd0ee0a40faa61263032d65d399f7eefcdf',
  UserCompanies = '885099542b365606d4391d3f639b3025649432dc',
  Niche = 'd43a869190c34d8de442fb7180a700f09134380d',
  NewsletterSubscription = 'eda2926f28635028a00b749f6cb5ac912cb8be6c',
  LossCause = 'eecd02f71f8cd3f8ca8db38023168a8deb9eaa91',
  DateLoss = '2929acaa4c4598196ceeebabbe0eb71204958a3d',
  Messengers = 'c5f4133b01ab60a3152d8d2b56bb4063d898418d',
  RegisterDate = 'd3d9ed24278f7119a2ff08a6c2aa817ebb442714',
  TaskReasonLoss = '5227ac297529682281ffabfa749642bfec2bf502',
  TaskReasonDeactive = '34a62a9ba16446048d39da4652235526953bfe2d',
  LTV = 'a0f1d6c20cf3f8489bfe88d82d84180cecb2c753',
  MMR = '3098cfa6138518d2b707ba9d4630f2d54dfee1b2',
  OnboardVersion = '66be5539568191be145fa4c1d25517ae68c5af21',
  Country = '8517ba14bcf80bb70b76a131282cda13d1a65503',
  Pain = '0de97b73985217cff6a3acc4472a415afbac9d1e',
  PaymentLink = 'cde0d4a1ac09ca45c6d5d2d118f00a17beb2620d',
  Position = '37dd8cab80d43198141862a0efcbbe97b1955ff3',
  Request = '939a2151721111cee04506aa0d62a39f3642f285',
  Summary = 'ad067520449934536f7d33d22a8dab9a5510c064',
  ActivityKind = '3c57336d9d972fa8b688762f75c31c5862767f5a',
  Source = 'a2ae1964fc9d77484a3aea3940195237d809f24c',
  FPKChat = '25a09337fc593f8d3b31b69f7c2398b24f82f213',
  Feedback = '2121c4282a7258f3f848edd2feb39f71cf0690c7',
  CreatorEmail = 'd48eb847d72fe9a9393a6867ab048ed69a0a7b22',
  HoldDemo = '8cbdd8aa0469ea020244f3446cfb4970b0af34d0',
  TariffPlanStatus = ' 153bdbf8fce618002a4ed4dd7225b5110f773640',
  PaymentMethod = '76ef6ffc8181dc99f89bd982edf08f18ee8f04b5',
  Industry = 'f0cfafa0eff0d04a26b04cbf84a59cfaa621bd9c',
  DeviceType = '7b8085d23b318ef4d66b7781f55f7ff834a686a6',
  RegistrationDateWeek = 'f202654df1f0003b24359fca98a6d855525e4ac2',
}
