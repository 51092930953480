import { aquaHaze, black } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    '&:hover': {
      background: 'inherit',
    },
    width: '100%',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    color: black,
    marginRight: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '85%',
  },
  circle: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.3)',
    '&:not(:last-child)': {
      marginRight: 3,
    },
    '&:last-of-type': {
      marginRight: 15,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 16,
  },
  icon: {
    marginRight: 20,
  },
  infoRow: {
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: aquaHaze,
      borderRadius: 8,
    },
  },
  show: {
    opacity: 1,
    display: 'flex',
  },
  hide: {
    opacity: 0,
    display: 'none',
    transition: '.55s opacity, .55s visibility',
  },
  summary: {
    margin: 0,
  },
  summaryRoot: {
    padding: 0,
  },
});
