import { Typography } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import CheckmarkIcon from '../../../../../../assets/images/svg/checkmark.svg';
import CopyIcon from '../../../../../../assets/images/svg/copy.svg';
import QuestionIcon from '../../../../../../assets/images/svg/question.svg';
import TrashIcon from '../../../../../../assets/images/svg/trash-red-20-20.svg';
import CustomButton from '../../../../../../components/Button';
import ButtonRowWithIcons from '../../../../../../components/ButtonRowWithIcons';
import Dialog from '../../../../../../components/Dialog/Dialog';
import RemoveDialog from '../../../../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import companyActions from '../../../../../../store/company/actions';
import { ApiKey } from '../../../../../../store/company/types';
import { outrageousOrange } from '../../../../../../theme/colors';
import { showError } from '../../../../../../utils/showError';
import { useStyles } from './styles';

export type Props = {
  apiKey: ApiKey | null;

  onClose(): void;
};

function EditApiKeyDialog(props: Props) {
  const { onClose, apiKey } = props;
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [currentApiKey, setCurrentApiKey] = useState(apiKey);
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    if (currentApiKey) {
      dispatch(companyActions.removeApiKey(currentApiKey._id));
    }

    setShowRemoveDialog(false);
  }, [dispatch, currentApiKey]);

  const handleRemoveKey = useCallback(() => {
    handleCloseRemoveDialog();
    onClose();
  }, [handleCloseRemoveDialog, onClose]);

  const handleChangeName = useCallback(
    (val: string) => {
      if (currentApiKey) {
        setCurrentApiKey(() => ({
          ...currentApiKey,
          name: val,
        }));
      }
    },
    [currentApiKey],
  );

  const handleCopied = useCallback(() => {
    setCopied(true);

    if (!currentApiKey) {
      return;
    }

    try {
      copy(currentApiKey.key);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (e) {
      showError(e);
    }
  }, [currentApiKey]);

  const getTooltipContent = useCallback(
    () => (
      <Typography className={classes.tooltipText}>
        {copied ? t('telegramBot.madeCopy') : t('telegramBot.makeCopy')}
      </Typography>
    ),
    [copied, t, classes],
  );

  return (
    <>
      <Dialog title={t('api.info')} onClose={onClose} isOpened>
        <TextFieldComponent
          readableOnly
          textEllipsis
          value={currentApiKey?.key || ''}
          onChange={handleCopied}
          placeholder={t('api.generatedKey')}
          AdornmentComponent={
            copied ? (
              <img
                src={CheckmarkIcon}
                alt="checkmark"
                data-for="copyKey"
                data-tip
              />
            ) : (
              <img
                src={CopyIcon}
                alt="copy"
                data-for="copyKey"
                data-tip
                onClick={handleCopied}
                className={classes.cursorActive}
              />
            )
          }
        />
        <TextFieldComponent
          disabled
          value={currentApiKey?.name || ''}
          onChange={handleChangeName}
          placeholder={t('common.title')}
        />
        <div className={classes.row}>
          <a
            className={classes.rowWrapper}
            href="https://api.finmap.online"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.questionIcon}
              src={QuestionIcon}
              alt="question"
            />
            <Typography className={classes.text}>
              {t('api.docTitle')}
            </Typography>
          </a>
        </div>
        <ButtonRowWithIcons
          textColor={outrageousOrange}
          onClick={handleShowRemoveDialog}
          icon={TrashIcon}
          alt="remove"
          label={t('api.remove')}
          wrapClasses={classes.margin24}
          showUnderLine={false}
        />
        <CustomButton fullWidth action={onClose} title={t('common.close')} />
        <ReactTooltip
          id="copyKey"
          className={classes.tooltipRoot}
          effect="solid"
          type="light"
          place="top"
          delayShow={500}
          getContent={getTooltipContent}
        />
      </Dialog>
      {showRemoveDialog && (
        <RemoveDialog
          title={t('api.removeTitle', {
            postProcess: 'sprintf',
            sprintf: [currentApiKey?.name],
          })}
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemoveKey}
          type="confirmRemoveApiKey"
          name={currentApiKey?.name || ''}
        />
      )}
    </>
  );
}

export default React.memo(EditApiKeyDialog);
