import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CardIcon from '../../../assets/images/svg/bank-card.svg';
import EditIcon from '../../../assets/images/svg/edit.svg';
import HistoryIcon from '../../../assets/images/svg/history.svg';
import InfoIcon from '../../../assets/images/svg/info.svg';
import TrashIcon from '../../../assets/images/svg/trash-red-20-20.svg';
import AlertDialog from '../../../components/AlertDialog';
import ButtonRowWithIcons from '../../../components/ButtonRowWithIcons';
import Dialog from '../../../components/Dialog/Dialog';
import RemoveDialog from '../../../components/RemoveDialog/RemoveDialog';
import Spinner from '../../../components/Spinner';
import {
  SHOW_CHANGE_PAYMENT_METHOD,
  SHOW_DOWNLOAD_PAYMENT,
  SHOW_NEXT_PAYMENT,
  SHOW_PAY_NOW,
  SHOW_PAYMENT_HISTORY,
  SHOW_UNLINKCARD,
} from '../../../constants/featureToggles/featureToggle';
import useSubscriptionActive from '../../../hooks/useSubscriptionActive';
import useSubscriptionDetails from '../../../hooks/useSubscriptionDetails';
import useUnleash from '../../../hooks/useUnleash';
import subscriptionApi from '../../../services/subscription';
import { selectCurrentCompany } from '../../../store/company/selectors';
import { TariffsId } from '../../../store/company/types';
import { outrageousOrange } from '../../../theme/colors';
import SubscriptionCardDialog from '../SubscriptionCardDialog';
import SubscriptionDialog from '../SubscriptionChooseDialog';
import SubscriptionHistoryDialog from '../SubscriptionHistoryDialog';
import { useStyles } from './styles';
import { Props } from './types';

function SubscriptionDetails(props: Props) {
  const { onClose } = props;
  const showUnlinkCard = useUnleash(SHOW_UNLINKCARD);
  const showChangePaymentMethod = useUnleash(SHOW_CHANGE_PAYMENT_METHOD);
  const showPayNow = useUnleash(SHOW_PAY_NOW);
  const showDownloadPayment = useUnleash(SHOW_DOWNLOAD_PAYMENT);
  const showPaymentHistory = useUnleash(SHOW_PAYMENT_HISTORY);
  const showNextPayment = useUnleash(SHOW_NEXT_PAYMENT);
  const [showHistoryPaymentDialog, setShowHistoryPaymentDialog] =
    useState(false);
  const [showTariffsDialog, setShowTariffsDialog] = useState(false);
  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] =
    useState(false);
  const [
    showErrorCancelSubscriptionDialog,
    setShowErrorCancelSubscriptionDialog,
  ] = useState(false);
  const [errorCancelSubscriptionText, setErrorCancelSubscriptionText] =
    useState('');
  const [showSuccessfulDialog, setShowSuccessfulDialog] = useState(false);
  const [showUnLinkCardDialog, setUnLinkCardDialog] = useState<boolean>(false);

  const { t } = useTranslation();
  const classes = useStyles();

  const {
    period,
    tariffs,
    payedFor,
    yourTariff,
    loadingTariffs,
    mappedPeriodToPrice,
    mappedPeriodToPeriodTitle,
  } = useSubscriptionDetails();

  const currentCompany = useSelector(selectCurrentCompany);
  const subscriptionActive = useSubscriptionActive();

  const tariffCurrencySymbol = useMemo(
    () =>
      tariffs?.find((el) => el._id === TariffsId.Pro)?.currencySymbol ?? '$',
    [tariffs],
  );

  const handleReloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  const handleCloseRemoveSubscriptionDialog = useCallback(() => {
    setShowCancelSubscriptionDialog(false);
  }, []);

  const handleCloseSuccessfullDialog = useCallback(() => {
    setShowSuccessfulDialog(false);

    handleReloadPage();
  }, [handleReloadPage]);

  const handleCloseErrorCancelSubscriptionDialog = useCallback(() => {
    setShowErrorCancelSubscriptionDialog(false);
  }, []);

  const handleShowCancelSubscription = useCallback(() => {
    setShowCancelSubscriptionDialog(true);
  }, []);

  const handleShowPaymentHistory = useCallback(() => {
    setShowHistoryPaymentDialog(true);
  }, []);

  const handleClosePaymentHistory = useCallback(() => {
    setShowHistoryPaymentDialog(false);
  }, []);

  const handleShowTariffsDialog = useCallback(() => {
    setShowTariffsDialog(true);
  }, []);

  const handleCloseTariffsDialog = useCallback(() => {
    setShowTariffsDialog(false);
  }, []);

  const handleShowUnLinkCard = useCallback(() => {
    setUnLinkCardDialog(true);
  }, []);

  const handleCloseUnLinkCard = useCallback(() => {
    setUnLinkCardDialog(false);
  }, []);

  const handleCancelSubscription = useCallback(async () => {
    if (currentCompany) {
      try {
        await subscriptionApi.cancelSubscription(currentCompany.tariffId);

        setShowSuccessfulDialog(true);
      } catch (error) {
        // @ts-ignore
        const status = error?.response?.status;

        if (status === 403) {
          setErrorCancelSubscriptionText(
            t('subscription.cancel.youNotCreator'),
          );
        } else {
          setErrorCancelSubscriptionText(t('subscription.cancel.error'));
        }

        setShowErrorCancelSubscriptionDialog(true);
      }
    }
  }, [currentCompany, t]);

  return (
    <>
      <Dialog
        onClose={onClose}
        title={`${t('subscription.details.title')} ${currentCompany?.name}`}
        isOpened
      >
        <div className={classes.row}>
          <Typography className={classes.leftText}>
            {t('subscription.details.yourTariff')}
          </Typography>
          <Typography className={classes.bold}>{yourTariff}</Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.leftText}>
            {t('subscription.details.price')}
          </Typography>
          {loadingTariffs && <Spinner />}
          {!loadingTariffs && (
            <Typography>
              {`${tariffCurrencySymbol} ${mappedPeriodToPrice[period]}/${mappedPeriodToPeriodTitle[period]}`}
            </Typography>
          )}
        </div>
        <div className={classes.row}>
          <Typography className={classes.leftText}>
            {t('subscription.details.status.title')}
          </Typography>
          <Typography
            className={cn(subscriptionActive && classes.active, classes.bold)}
          >
            {subscriptionActive
              ? t('subscription.details.status.active')
              : t('subscription.details.status.passive')}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.leftText}>
            {t('subscription.details.payedFor')}
          </Typography>
          <Typography>{payedFor}</Typography>
        </div>
        <>
          {showChangePaymentMethod && (
            <div className={classes.paymentMethodRoot}>
              <Typography className={classes.leftText}>
                {t('subscription.details.paymentMethod')}
              </Typography>
              <div className={classes.paymentMethodContainer}>
                <div className={classes.paymentMethodCardWrapper}>
                  <img src={CardIcon} alt="card icon" />
                  <Typography>*** 8452</Typography>
                </div>
                <div className={classes.paymentMethodWrapper}>
                  <Typography className={classes.paymentMethodText}>
                    {t('subscription.details.changePaymentMethod')}
                  </Typography>
                  {showUnlinkCard && (
                    <Typography
                      className={cn(
                        classes.paymentMethodText,
                        classes.marginLeft8,
                      )}
                      onClick={handleShowUnLinkCard}
                    >
                      {t('subscription.details.unLinkCard')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
        <>
          {showNextPayment && (
            <div className={classes.nextPaymentRoot}>
              <Typography className={classes.leftText}>
                {t('subscription.details.nextPayment')}
              </Typography>
              <div className={classes.nextPaymentBtnsWrapper}>
                {loadingTariffs && <Spinner />}
                {!loadingTariffs && (
                  <Typography>
                    {`${tariffCurrencySymbol} ${mappedPeriodToPrice[period]}, ${payedFor}`}
                  </Typography>
                )}
                {showPayNow && (
                  <Button className={classes.nextPaymentBtn}>
                    {t('subscription.details.payNow')}
                  </Button>
                )}
                {showDownloadPayment && (
                  <Typography className={classes.paymentMethodText}>
                    {t('subscription.details.downloadInvoice')}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </>
        <div className={classes.infoWrapper}>
          <img src={InfoIcon} alt="info icon" />
          <Typography className={cn(classes.leftText, classes.infoTitle)}>
            {t('subscription.details.automaticRenewalOfTariff')}
          </Typography>
        </div>
        <>
          {showPaymentHistory && (
            <ButtonRowWithIcons
              onClick={handleShowPaymentHistory}
              icon={HistoryIcon}
              alt="history"
              label={t('subscription.details.paymentHistory')}
              showUnderLine
            />
          )}
        </>
        <ButtonRowWithIcons
          onClick={handleShowTariffsDialog}
          icon={EditIcon}
          alt="remove"
          label={t('subscription.details.change')}
          showUnderLine={!Object.hasOwnProperty}
        />
        <>
          {currentCompany &&
            !Object.hasOwnProperty.call(
              currentCompany,
              'canceledScheduled',
            ) && (
              <ButtonRowWithIcons
                textColor={outrageousOrange}
                onClick={handleShowCancelSubscription}
                icon={TrashIcon}
                alt="remove"
                label={t('subscription.details.cancel')}
                showUnderLine={false}
              />
            )}
        </>
      </Dialog>
      {showUnLinkCardDialog && (
        <SubscriptionCardDialog onClose={handleCloseUnLinkCard} />
      )}
      {showHistoryPaymentDialog && (
        <SubscriptionHistoryDialog onClose={handleClosePaymentHistory} />
      )}
      {showTariffsDialog && (
        <SubscriptionDialog onClose={handleCloseTariffsDialog} />
      )}
      {showCancelSubscriptionDialog && (
        <RemoveDialog
          showCheckbox={false}
          onRemove={handleCancelSubscription}
          title={t('subscription.cancel.title')}
          description={t('subscription.cancel.description')}
          subDescription={t('subscription.cancel.cancelConfirm')}
          onClose={handleCloseRemoveSubscriptionDialog}
          name=""
          type="subscription"
        />
      )}
      {showErrorCancelSubscriptionDialog && (
        <RemoveDialog
          showCheckbox={false}
          onRemove={handleCancelSubscription}
          title={t('common.error')}
          description={errorCancelSubscriptionText}
          onClose={handleCloseErrorCancelSubscriptionDialog}
          name=""
          type="cancelSubscriptionError"
          dialogType="alert"
          alertText={t('subscription.cancel.repeat')}
        />
      )}
      {showSuccessfulDialog && (
        <AlertDialog
          title={t('subscription.cancel.canceled')}
          description={t('subscription.cancel.canceledDescription', {
            postProcess: 'reactPostprocessor',
            paidFor: <b>{payedFor}</b>,
          })}
          acceptText={t('subscription.cancel.cool')}
          onAccept={handleReloadPage}
          onClose={handleCloseSuccessfullDialog}
        />
      )}
    </>
  );
}

export default React.memo(SubscriptionDetails);
