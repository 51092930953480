import { makeStyles } from '@material-ui/core/styles';

import { StyleProps } from '../../foundation/Operations/Components/ConfirmChangeRepeatDialog/types';
import {
  aquaHaze,
  black, flamingo,
  jade,
  persianGreen,
  white,
} from '../../theme/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    marginBottom: (props: StyleProps) => (props.isError ? 28 : 16),
    background: aquaHaze,
    borderRadius: 12,
    height: 56,
    transition: 'margin 700ms',
    boxSizing: 'border-box',
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  container: {
    flex: 1,
    padding: '9px 0',
  },
  popper: {
    width: '87%',
    marginTop: 13,
    height: 216,
    overflow: 'scroll',
    borderRadius: 12,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    background: white,
  },
  selectText: {
    fontSize: 15,
    opacity: 0.5,
  },
  selectContainer: {
    paddingLeft: 16,
    paddingRight: 15,
    background: aquaHaze,
    borderRadius: 12,
    height: 56,
    '&::before': {
      borderBottom: 'none',
    },
  },
  '.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  listItem: {
    height: 48,
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    '&:hover': {
      marginRight: 8,
      paddingTop: 14,
      paddingBottom: 14,
      borderRadius: 8,
      background: aquaHaze,
    },
  },
  dropdownContainer: {
    marginTop: 10,
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    paddingTop: 22,
    paddingLeft: 20,
    paddingRight: 20,
  },
  createCategoryText: {
    color: jade,
    fontSize: 15,
    lineHeight: '20px',
    marginBottom: 23,
  },
  separator: {
    border: '0.5px solid #000000',
    opacity: 0.2,
    '&:not(:last-child)': {
      marginBottom: 22,
    },
    '&:last-of-type': {
      marginBottom: 24,
    },
  },
  subCategory: {
    fontSize: 15,
    lineHeight: '20px',
  },
  inputRoot: {
    display: 'flex',
    height: 56,
    backgroundColor: aquaHaze,
    marginBottom: 20,
    borderRadius: 12,
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  relative: {
    position: 'relative',
  },
  selectedOptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedOptionText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  shortText: {
    display: 'block',
    width: '95%',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  noOptions: {
    opacity: 0.5,
  },
  additionalOptionText: {
    color: persianGreen,
    width: '100%',
  },
  padding20: {
    paddingLeft: 20,
  },
  label: {
    paddingLeft: 20,
  },
  errorText: {
    position: 'absolute',
    top: 58,
    left: 16,
    color: flamingo,
    fontSize: 12,
    lineHeight: '16px',
  },
  error: {
    background: 'rgba(255, 63, 46, 0.12)',
  },
  disabledInput: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  description: {
    fontSize: 13,
    lineHeight: '20px',
    opacity: 0.5,
  },
  icon: {
    marginRight: 7,
  },
  flagIcon: {
    position: 'absolute',
    transform: 'translateY(50%)',
    marginLeft: 15,
  },
  dropDownSplitProjectInputField: {
    width: '100%',
    '& > .marginBtm0': {
      marginBottom: 0,
    },
  },
});
