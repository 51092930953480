import Http from '../../services/Http';
import { CreateUserFilterRequest, UserFilter } from './customFiltersApi.types';

const getCustomFilters = () => Http.makeInstance().get('/filter');

const createCustomFilter = (payload: CreateUserFilterRequest) =>
  Http.makeInstance().post('/filter', { ...payload });

const removeCustomFilter = (id: string) =>
  Http.makeInstance().delete(`/filter/${id}`);

const updateCustomFilter = (filter: UserFilter) =>
  Http.makeInstance().patch(`/filter/${filter._id}`, { ...filter });

export default {
  getCustomFilters,
  createCustomFilter,
  removeCustomFilter,
  updateCustomFilter,
};
