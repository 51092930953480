import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze } from '../../../theme/colors';

export const useStyles = makeStyles({
  button: {
    borderRadius: 20,
    background: aquaHaze,
    height: 72,
  },
  title: {
    paddingRight: 0,
  },
  buttonDescriptionText: {
    opacity: 0.5,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '50%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 12,
  },
  description: {
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 16,
  },
  createContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    background: 'rgba(0, 0, 0, 0.06)',
    marginBottom: 16,
    height: 56,
    cursor: 'pointer',
  },
  text: {
    fontWeight: 'bold',
  },
});
