import { createContext } from 'react';

type Props = {
  hide(): void;
  show(): void;
};
const DialogContext = createContext<Props | null>(null);

export const DialogContextProvider = DialogContext.Provider;
export default DialogContext;
