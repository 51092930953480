import { all, put, takeLatest } from 'redux-saga/effects';

import { categoryActionTypes } from '../store/categories/actions';
import { getClientsByTypeOperations } from '../store/clients/sagas';
import { UrlPrefix } from '../store/clients/types';

import { GET_OPERATION_PROJECTS_PENDING } from '../store/projects/actions';
import { GET_OPERATION_TAGS_PENDING } from '../store/tags/actions';
import { showError } from '../utils/showError';
import { GET_OPERATIONS_ACCOUNTS_PENDING } from '../store/accounts/actions';
import { GET_OPERATION_CONTEXTS } from '../store/company/actions';

function* getOperationContexts() {
  try {
    yield put({ type: GET_OPERATION_TAGS_PENDING });
    yield put({ type: GET_OPERATION_PROJECTS_PENDING });
    yield put({ type: GET_OPERATIONS_ACCOUNTS_PENDING });
    yield put({ type: categoryActionTypes.GET_OPERATION_INCOME_PENDING });
    yield put({ type: categoryActionTypes.GET_OPERATION_CONSUMPTION_PENDING });

    yield all(
      Object.keys(UrlPrefix).map((prefix) =>
        // @ts-ignore
        getClientsByTypeOperations({ payload: { prefix } }),
      ),
    );
  } catch (error) {
    showError(error);
  }
}

export default function operationContexts() {
  return all([takeLatest(GET_OPERATION_CONTEXTS, getOperationContexts)]);
}
