import Http from '../Http';
import { SetCredentialsPayload, UpdateCredentialsPayload } from "./payoneer.types";

const getCredentials = (redirectUrl: string) =>
  Http.makeInstance().get(`/integrations/payoneerCredentials?redirectUrl=${redirectUrl}`);

const updateCredentials = (data: UpdateCredentialsPayload) =>
  Http.makeInstance().post('/integrations/payoneerCredentials',
    {
      code: data.code,
      additionalNotFondyAccount: data.accountId,
      redirectUrl: data.redirectUrl,
    });

const setCredentials = (data: SetCredentialsPayload) =>
  Http.makeInstance().patch('/integrations/payoneerCredentials', {
    ...data
  });

export default {
  getCredentials,
  setCredentials,
  updateCredentials,
};
