import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import { ActionAutoRulesTypes } from './actions';
import api from './api';
import {
  CreateAutoRulePayload,
  DeleteAutoRulePayload,
  GetAutoRulesResponse,
  UpdateAutoRulePayload,
  UpdateAutoRulesOrderPayload,
} from './sagas.types';
import { AutoRule } from './types';

function* getAutoRulesList() {
  try {
    const { data }: AxiosResponse<AutoRule[]> = yield call(
      api.getAutoRulesList,
    );

    yield put({
      type: ActionAutoRulesTypes.GET_AUTO_RULES_SUCCESS,
      payload: { rules: data },
    });
  } catch (error) {
    showError(error);
  }
}

function* createAutoRule(action: TAction<CreateAutoRulePayload>) {
  try {
    const { actions, active, conditions, name, type } = action.payload;

    const { data: status } = yield call(api.getUserAutoRuleStatus);

    if (!status) {
      yield call(api.setUserAutoRulesStatus, true);
    }

    const { data }: AxiosResponse<GetAutoRulesResponse> = yield call(
      api.createAutoRule,
      { active, actions, conditions, name, type },
    );

    yield put({
      type: ActionAutoRulesTypes.CREATE_AUTO_RULE_SUCCESS,
      payload: { rules: data },
    });
  } catch (error) {
    showError(error);
  }
}

function* deleteAutoRule(action: TAction<DeleteAutoRulePayload>) {
  try {
    const { ruleId } = action.payload;

    const { data }: AxiosResponse<GetAutoRulesResponse> = yield call(
      api.deleteAutoRule,
      { ruleId },
    );

    yield put({
      type: ActionAutoRulesTypes.DELETE_AUTO_RULE_SUCCESS,
      payload: { rules: data },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateAutoRulesOrder(action: TAction<UpdateAutoRulesOrderPayload[]>) {
  try {
    const rules = action.payload;

    const { data } = yield call(api.updateAutoRulesOrder, rules);

    yield put({
      type: ActionAutoRulesTypes.UPDATE_AUTO_RULE_ORDER_SUCCESS,
      payload: { rules: data },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateAutoRule(action: TAction<UpdateAutoRulePayload>) {
  try {
    const props = action.payload;

    const { data } = yield call(api.updateAutoRule, props);

    yield put({
      type: ActionAutoRulesTypes.UPDATE_AUTO_RULE_SUCCESS,
      payload: { rules: data },
    });
  } catch (error) {
    showError(error);
  }
}

export default function autoRules() {
  return all([
    takeLatest(ActionAutoRulesTypes.GET_AUTO_RULES_PENDING, getAutoRulesList),
    takeLatest(ActionAutoRulesTypes.CREATE_AUTO_RULE_PENDING, createAutoRule),
    takeLatest(ActionAutoRulesTypes.UPDATE_AUTO_RULE_PENDING, updateAutoRule),
    takeLatest(ActionAutoRulesTypes.DELETE_AUTO_RULE_PENDING, deleteAutoRule),
    takeLatest(
      ActionAutoRulesTypes.UPDATE_AUTO_RULE_ORDER_PENDING,
      updateAutoRulesOrder,
    ),
  ]);
}
