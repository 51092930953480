import i18next from 'i18next';
import { createSelector } from 'reselect';

import {
  SUBSCRIPTION_HALF_YEAR_LITE_DISCOUNT,
  SUBSCRIPTION_HALF_YEAR_PRO_DISCOUNT,
  SUBSCRIPTION_YEAR_LITE_DISCOUNT,
  SUBSCRIPTION_YEAR_PRO_DISCOUNT
} from '../constants';
import { SubscriptionPeriods } from '../store/types';
import { getLanguage } from './main';

export const subscriptionV2Periods = (isPro: boolean) =>
  createSelector(getLanguage, (language) => {
    const i18 = i18next.getFixedT(language);

    const yearDiscount = isPro
      ? `(-${SUBSCRIPTION_YEAR_PRO_DISCOUNT}%)`
      : `(-${SUBSCRIPTION_YEAR_LITE_DISCOUNT}%)`;

    const halfYearDiscount = isPro
      ? `(-${SUBSCRIPTION_HALF_YEAR_PRO_DISCOUNT}%)`
      : `(-${SUBSCRIPTION_HALF_YEAR_LITE_DISCOUNT}%)`;

    return [
      {
        id: SubscriptionPeriods.price1m,
        value: 1,
        title: `1 ${i18('customPeriod.month')}`,
      },
      {
        id: SubscriptionPeriods.price6m,
        value: 6,
        title: `6 ${i18('customPeriod.months')} ${halfYearDiscount}`,
      },
      {
        id: SubscriptionPeriods.price12m,
        value: 12,
        title: `12 ${i18('customPeriod.months')} ${yearDiscount}`,
      },
    ];
  });
