import React from 'react';
import cn from 'classnames';
import { Button, Typography, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from "react-i18next";

import { useStyles } from './styles';
import UnselectedSvg from '../../assets/images/svg/unselected.svg';
import SelectedSvg from '../../assets/images/svg/selected.svg';

export interface RemoveQuestionProps {
  onClose?(): void;
  alertText?: string;
  description?: string;
  subDescription?: string;
  showCheckbox?: boolean;
  dialogType?: string;
  checkboxSelected?: boolean;
  checkboxOnChange?(): void;
  onRemove?(): void;
}

export function RemoveQuestion({
  onClose,
  alertText,
  description,
  subDescription,
  showCheckbox,
  dialogType,
  checkboxSelected,
  checkboxOnChange,
  onRemove,
}: RemoveQuestionProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <>
    <>
      {!!description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
    </>
    <>
      {!!subDescription && (
        <Typography className={classes.description}>
          {subDescription}
        </Typography>
      )}
    </>
    <>
      {showCheckbox && (
        <FormControlLabel
          className={classes.selectContainer}
          control={
            <Checkbox
              disableRipple
              className={classes.checkboxRoot}
              checked={checkboxSelected}
              onChange={checkboxOnChange}
              checkedIcon={<img alt="" src={SelectedSvg} />}
              icon={<img alt="" src={UnselectedSvg} />}
            />
          }
          label={
            <span className={classes.subTitle}>{t('common.notAsk')}</span>
          }
        />
      )}
    </>

    <div className={classes.footer}>
      <Button
        className={cn(
          classes.buttonRemove,
          dialogType === 'alert' ? classes.fullWidth : classes.halfWidth,
        )}
        onClick={onRemove}
        id="cypress-confirm-remove-button"
      >
        <Typography className={classes.buttonRemoveText}>
          {alertText || t('common.remove')}
        </Typography>
      </Button>
      {dialogType === 'confirm' && (
        <Button className={classes.buttonCancel} onClick={onClose}>
          <Typography className={classes.buttonCancelText}>
            <span role="img">{t('system.changeMind')} 🌝</span>
          </Typography>
        </Button>
      )}
    </div>
  </>
}

export default RemoveQuestion;