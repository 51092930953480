import { createContext } from 'react';

export type FacebookPixelContextProps = {
  sendData(): void;
};

const FacebookPixelContext = createContext<FacebookPixelContextProps | null>(
  null,
);

export const FacebookPixelProvider = FacebookPixelContext.Provider;
export default FacebookPixelContext;
