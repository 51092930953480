import { OPERATION_TYPES } from '@finmap/core-constants';

import { StatusData } from '../../scenes/InfoBlock/Analytic/CashFlowAndPL/StatusFilter/StatusesList/types';
import {
  Attachment,
  OperationSubType,
  OperationType,
} from '../operations/types';

export type HeadCell = {
  id: string;
  label: string;
};

export type CustomFilters = {
  status: StatusData | null;
  categoriesByType: string[];
  analyticCategoriesByType?: string[];
  categoriesBySubType: string[] | null;
  categoriesByIds: string[];
  clients: string[] | null;
  projects: string[] | null;
  tags: string[] | null;
  accounts: string[] | null;
};

export type PredictionType = {
  label: string;
  _id: string;
};

export type ProjectObject = {
  _id: string;
  label: string;
  companyCurrencySum: number;
  stake: number;
  sum: number;
  transactionSum?: number;
};

export type ProjectObjectsType = Record<string, ProjectObject>;

export type JournalData = {
  accountName: string;
  accountToId: string;
  accountFromName: string;
  accountToName: string;
  approved: boolean;
  archived: boolean;
  categoryId: string;
  categoryV2Id: string;
  categoryName: string;
  comment: string;
  companyId: string;
  counterpartyId: string;
  counterpartyName: string;
  currencyId: string;
  currencySymbol: string;
  date: number;
  projectIds: string[];
  projects: string;
  subType: OperationSubType;
  sum: number;
  periodSum: string;
  periodSumDouble: number;
  sumDouble: number;
  tagIds: string[];
  tags: string;
  type: OperationType;
  _id: string;
  accountBalanceAfterOperation: number;
  accountFromBalanceAfterOperation: number;
  accountFromId: string;
  salaryPeriodEndTimestamp: number;
  salaryPeriodStartTimestamp: number;
  salaryPeriodId: number;
  dateOfPayment: number;
  isReadOnly: boolean;
  exchangeRate: number;
  schedulePeriodId: number;
  scheduled: boolean;
  scheduleEndDate: number;
  scheduleMaxCount: number;
  scheduleInterval: number;
  scheduleIntervalType: number;
  scheduleDays: string;
  taxPeriodId: number;
  taxPeriodEndTimestamp: number;
  taxPeriodStartTimestamp: number;
  companyCurrencySum: number;
  companyCurrencySumDouble: number;
  dateInString: string[];
  dateOfPaymentInString: string[];
  canEdit?: boolean;
  canDelete?: boolean;
  attachments: Attachment[];
  hasPrediction: boolean;
  timeInString: string;
  predictionTypes: PredictionType[];
  transactionSum: number;
  transactionCurrency: string;
  transactionCurrencyRate: number;
  externalId?: string;
  integrationId?: string;
  importId?: string;
  botId?: string;
  projectObjects?: ProjectObjectsType;
  predictionId?: string;
  hasProjectSumError: boolean;
  hasDeletedProject: boolean;
  hasPartial: boolean;
  isPartial: boolean;
  commonSum: string;
  originalOperation: JournalData;
  isTransformToTransfer?: boolean;
  transferFromIntegratedConsumption?: boolean;
  transferFromIntegratedIncome?: boolean;
  isTransformFromIncome?: boolean;
  fullType?: string;
  consumptionPeriodEndTimestamp?: number;
  consumptionPeriodStartTimestamp?: number;
  consumptionPeriodId?: number;
  incomePeriodEndTimestamp?: number;
  incomePeriodStartTimestamp?: number;
  incomePeriodId?: number;
};

type SortDirection = 'asc' | 'desc';

export type MultiOrderBy = {
  [key in HeadCell['id']]?: SortDirection;
};

export type Journal = {
  items: JournalData[];
  futureItems: JournalData[];
  total: number;
};

export enum Status {
  payed = 'payed',
  future = 'future',
}

export enum PeriodId {
  currentYear = 'currentYear',
  prevYear = 'prevYear',
  currentWeek = 'currentWeek',
  prevWeek = 'prevWeek',
  currentMonth = 'currentMonth',
  prevMonth = 'prevMonth',
  allTime = 'allTime',
}

export type PeriodList = {
  id: PeriodId;
  name: string;
  value: (number | undefined)[];
};

export type JournalState = {
  operationsExist: boolean;
  loading: boolean;
  loadingFuture: boolean;
  order: string;
  orderBy: string;
  multiOrderBy: MultiOrderBy;
  journal: Journal;
  showFuturePayments: boolean;
  isApprovedFuture: boolean;
  totalFutureAmount: number;
};

export type ExcelExportDataType = {
  date: string | Date;
  dateOfPayment: string | Date;
  periodFrom: string | Date;
  periodTo: string | Date;
  type: string;
  subCategoryName: string;
  amount: number;
  companyCurrencySum: number;
  accountFrom: string;
  accountTo: string;
  counterpartyName: string;
  project: string;
  tags: string;
  comment: string;
};

export type LogRequestBody = {
  operationIds?: string[];
  projectIds?: string[];
  ids?: string[];
  search?: string;
  types?: OPERATION_TYPES[];
  fullTypes?: string;
  tagIds?: string[];
  counterpartyIds?: string[];
  categoryV2Ids?: string[];
  accountIds?: string[];
  limit?: number;
  offset?: number;
  approved?: boolean;
  startDate?: number;
  endDate?: number;
  useDateOfPayment?: boolean;
  calculateAccountState?: boolean;
  isPartial?: boolean;
  invoice?: boolean;
  confirmedInvoice?: boolean;
  invoiceStatus?: string;
  desc?: boolean;
  field?: string;
  [d: `desc${number}`]: boolean;
  [d: `field${number}`]: string;
};
