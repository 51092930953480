import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { normalize } from '@finmap/core-utils';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import {
  RESET_CREATE_ELEMENT_LOADING,
  SET_CREATE_ELEMENT_ERROR,
  SET_CREATE_ELEMENT_LOADING,
} from '../common/actions';
import { Context } from '../types';
import {
  CREATE_OPERATION_TAGS_PENDING,
  CREATE_OPERATION_TAGS_SUCCESS,
  CREATE_SETTINGS_TAG_PENDING,
  CREATE_SETTINGS_TAG_SUCCESS,
  DELETE_TAG_PENDING,
  DELETE_TAG_SUCCESS,
  GET_LOG_TAGS_PENDING,
  GET_LOG_TAGS_SUCCESS,
  GET_OPERATION_TAGS_PENDING,
  GET_OPERATION_TAGS_SUCCESS,
  GET_SETTINGS_TAGS_PENDING,
  GET_SETTINGS_TAGS_SUCCESS,
  UPDATE_GROUP_TAGS_PENDING,
} from './actions';
import tagsApi from './api';
import { UpdateGroupTagsPayload } from './sagas.types';
import { selectSettingsTags } from './selectors';
import { CreateTagPayload, Tag } from './types';

export function* getLogTags() {
  try {
    const { data } = yield call(tagsApi.getTags, Context.log);

    yield put({ type: GET_LOG_TAGS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getOperationTags() {
  try {
    const { data } = yield call(tagsApi.getTags, Context.operation);

    yield put({ type: GET_OPERATION_TAGS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getSettingsTags() {
  try {
    const { data } = yield call(tagsApi.getTags, Context.settings);

    yield put({ type: GET_SETTINGS_TAGS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createOperationTag({
  payload: { label },
}: TAction<CreateTagPayload>) {
  try {
    const { data } = yield call(tagsApi.createTag, label, Context.operation);

    yield put({ type: CREATE_OPERATION_TAGS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createSettingsTag({ payload: { label } }: any) {
  try {
    yield put({ type: SET_CREATE_ELEMENT_LOADING });

    const allSettingsTags: Tag[] = yield select(selectSettingsTags);

    const isExistTag = allSettingsTags.some(
      (el) => el.normalizedLabel === normalize(label),
    );

    if (isExistTag) {
      yield put({ type: SET_CREATE_ELEMENT_ERROR });
    } else {
      const { data } = yield call(tagsApi.createTag, label, Context.settings);

      yield put({ type: CREATE_SETTINGS_TAG_SUCCESS, payload: { data } });
    }

    yield put({ type: RESET_CREATE_ELEMENT_LOADING });
  } catch (error) {
    showError(error);
  }
}

export function* deleteTag({ payload: { id } }: any) {
  try {
    const { data } = yield call(tagsApi.deleteTag, id);

    yield put({ type: DELETE_TAG_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupTags(action: TAction<UpdateGroupTagsPayload>) {
  try {
    const { tags: payloadTags } = action.payload;
    if (payloadTags.length === 1) {
      yield put({ type: SET_CREATE_ELEMENT_LOADING });

      const allSettingsTags: Tag[] = yield select(selectSettingsTags);

      const isExistTag = allSettingsTags.some(
        (el) =>
          el.normalizedLabel === normalize(payloadTags[0].label) &&
          el._id !== payloadTags[0]._id,
      );

      if (isExistTag) {
        yield put({ type: SET_CREATE_ELEMENT_ERROR });
      } else {
        yield all(
          payloadTags.map((tag) => {
            const { _id, ...rest } = tag;

            return call(tagsApi.updatePropTag, _id, {
              ...rest,
            });
          }),
        );
        yield put({ type: GET_SETTINGS_TAGS_PENDING });
      }

      yield put({ type: RESET_CREATE_ELEMENT_LOADING });
    } else {
      yield all(
        payloadTags.map((tag) => {
          const { _id, ...rest } = tag;

          return call(tagsApi.updatePropTag, _id, {
            ...rest,
          });
        }),
      );
      yield put({ type: GET_SETTINGS_TAGS_PENDING });
    }
  } catch (error) {
    showError(error);
  }
}

export default function tags() {
  return all([
    takeLatest(GET_LOG_TAGS_PENDING, getLogTags),
    takeLatest(GET_OPERATION_TAGS_PENDING, getOperationTags),
    takeLatest(GET_SETTINGS_TAGS_PENDING, getSettingsTags),
    takeLatest(CREATE_OPERATION_TAGS_PENDING, createOperationTag),
    takeLatest(CREATE_SETTINGS_TAG_PENDING, createSettingsTag),
    takeLatest(DELETE_TAG_PENDING, deleteTag),
    takeLatest(UPDATE_GROUP_TAGS_PENDING, updateGroupTags),
  ]);
}
