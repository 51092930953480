import { AnyAction } from 'redux';

import { PeriodId } from '../journal/types';
import { ActionTypes } from './actions';
import { InvoicingCustomFilters, InvoicingState } from './types';

export const initialInvoicingPeriodFilter = {
  id: PeriodId.allTime,
  startDate: undefined,
  endDate: undefined,
};

const initialInvoicingCustomFilters: InvoicingCustomFilters = {
  clients: null,
  statuses: null,
};

export const initialState: InvoicingState = {
  log: {
    items: [],
    total: 0,
  },
  customFilters: initialInvoicingCustomFilters,
  periodFilter: initialInvoicingPeriodFilter,
  loading: false,
  myProps: [],
  clients: [],
  goods: [],
  multiOrderBy: {
    dateOfPayment: 'desc',
    date: undefined,
    sum: undefined,
    counterpartyName: undefined,
  },
  loadingCompanyInvoicingData: false,
};

const invoicingReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case ActionTypes.SET_LOADING_COMPANY_INVOICING_DATA: {
      return {
        ...state,
        loadingCompanyInvoicingData: payload.data,
      };
    }

    case ActionTypes.SET_SORT_PARAMS: {
      return {
        ...state,
        multiOrderBy: payload.orders,
      };
    }

    case ActionTypes.REMOVE_GOOD_SUCCESS:
    case ActionTypes.CREATE_GOOD_SUCCESS:
    case ActionTypes.UPDATE_INVOICE_GOODS_GROUP_SUCCESS: {
      return {
        ...state,
        goods: payload.data,
      };
    }

    case ActionTypes.GET_INVOICING_GOODS_SUCCESS: {
      return {
        ...state,
        goods: payload.data,
      };
    }

    case ActionTypes.CREATE_INVOICING_MY_PROPS_SUCCESS: {
      return {
        ...state,
        myProps: payload.data,
      };
    }

    case ActionTypes.CREATE_INVOICING_CLIENT_SUCCESS: {
      return {
        ...state,
        clients: payload.data,
      };
    }

    case ActionTypes.CREATE_INVOICING_COUNTERPARTY_PROPS_SUCCESS: {
      return {
        ...state,
        counterpartyProps: payload.data,
      };
    }

    case ActionTypes.GET_MY_INVOICING_PROPS_SUCCESS: {
      return {
        ...state,
        myProps: payload.data,
      };
    }

    case ActionTypes.REMOVE_INVOICE_CLIENT_SUCCESS:
    case ActionTypes.GET_INVOICING_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: payload.data,
      };
    }

    case ActionTypes.GET_INVOICES_FAILED:
    case ActionTypes.GET_INVOICES_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.GET_INVOICES_SUCCESS: {
      const { data, update } = payload;

      const items = update ? state.log.items.concat(data.items) : data.items;

      return {
        ...state,
        log: {
          ...state.log,
          items,
          total: data.total,
        },
        loading: false,
      };
    }

    case ActionTypes.SET_INVOICING_FILTER_PERIOD: {
      return {
        ...state,
        periodFilter: {
          startDate: payload.startDate,
          endDate: payload.endDate,
          id: payload.id,
        },
      };
    }

    case ActionTypes.RESET_INVOICE_CUSTOM_FILTERS: {
      return {
        ...state,
        periodFilter: initialInvoicingPeriodFilter,
        customFilters: initialInvoicingCustomFilters,
      };
    }

    case ActionTypes.SET_CUSTOM_INVOICING_FILTER: {
      const { data, filterType } = payload;

      return {
        ...state,
        customFilters: {
          ...state.customFilters,
          [filterType]: data,
        },
      };
    }

    default:
      return state;
  }
};

export default invoicingReducer;
