import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { persianGreen, white } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  tooltip: {
    background: persianGreen,
    borderRadius: 12,
    padding: '8px 12px',
    maxWidth: 315,
    marginBottom: 10,
  },
  tooltipArrow: {
    color: persianGreen,
  },
});

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  onboardingPopupTitle: {
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '20px',
    marginBottom: 2,
    color: white,
    [theme.breakpoints.down(768)]: {
      fontSize: 15,
    },
  },
  onboardingPopupText: {
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 10,
    color: white,
    [theme.breakpoints.down(768)]: {
      fontSize: 15,
      lineHeight: '19px',
    },
  },
  onboardingPopupActionText: {
    [theme.breakpoints.down(768)]: {
      fontSize: 20,
      lineHeight: '19px',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  underline: {
    textDecoration: 'underline',
  },
  noMargin: {
    margin: 0,
  },
  playIcon: {
    opacity: 0.5,
    marginRight: 4,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 6,
    marginBottom: 6,
  },
}));
