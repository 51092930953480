import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MtbBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Наш рахунок',
              },
            ],
            [
              1,
              {
                eq: 'Наш IBAN',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            fields: {
              credit: {
                column: ['Сума', 11],
                if: {
                  eq: [
                    {
                      column: ['Операція', 2],
                    },
                    'Кредит',
                  ],
                },
              },
              debit: {
                column: ['Сума', 11],
                if: {
                  eq: [
                    {
                      column: ['Операція', 2],
                    },
                    'Дебет',
                  ],
                },
              },
              date: {
                column: ['Дата проведення', 9],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Призначення платежу', 8],
              },
            },
          },
        },
      },
    ],
  };
}
