import i18next from 'i18next';
import { createSelector } from 'reselect';

import countriesJSON from '../../constants/countriesV2.json';
import { getLanguage } from '../../selectors/main';
import { sortByName } from '../../utils/strings';
import { AppState } from '../reducers';

export const getUkrsibCode = (state: AppState) => state.integrations.ukrsibCode;

export const getNordigenIntegrationId = (state: AppState) =>
  state.integrations.nordigenIntegrationId;

export const getBelvoIntegrationId = (state: AppState) =>
  state.integrations.belvoIntegrationId;

export const getPumbIntegrationId = (state: AppState) =>
  state.integrations.pumbIntegrationId;

export const getPayoneerCredentialsId = (state: AppState) =>
  state.integrations.payoneerCredentialsId;

export const getMonoAcquiringIntegrationId = (state: AppState) =>
  state.integrations.monoAcquiringCredentialsId;

export const getUkrsibIntegrationId = (state: AppState) =>
  state.integrations.ukrsibIntegrationId;

export const getFondyIntegrationId = (state: AppState) =>
  state.integrations.fondyIntegrationId;

export const getWesternBidIntegrationId = (state: AppState) =>
  state.integrations.westernBidIntegrationId;

export const getCheckBoxIntegrationId = (state: AppState) =>
  state.integrations.checkBoxIntegrationId;

export const getFacturowniaIntegrationId = (state: AppState) =>
  state.integrations.fakturowniaIntegrationId;

export const getiDokladIntegrationId = (state: AppState) =>
  state.integrations.iDokladIntegrationId;

export const getPosterIntegrationId = (state: AppState) =>
  state.integrations.PosterIntegrationId;

export const getPrivateCreateCredentialsError = (state: AppState) =>
  state.integrations.privateCreateCredentialsError;

export const getTelegramApi = (state: AppState) =>
  state.integrations.telegramApi;

export const getIntegrationInProgress = (state: AppState) =>
  state.integrations.integrationInProgress;

export const getIntegrationAccountsInProgress = (state: AppState) =>
  state.integrations.integrationAccounts;

export const getLoadingIntegrationCredentials = (state: AppState) =>
  state.integrations.loadingIntegrationCredentials;

export const getLoadingMonoIntegration = (state: AppState) =>
  state.integrations.loadingMonoIntegration;

export const selectServerIp = (state: AppState) => state.integrations.serverIp;

export const getIntegrationCredentials = (state: AppState) =>
  state.integrations.integrationCredentials;

export const getPrivat24BusinessIntegrationCredentials = (state: AppState) =>
  state.integrations.integrationPrivat24BusinessCredentials;

export const getInitialMonoCredentials = (state: AppState) =>
  state.integrations.initialMonoCredentials;

export const getMonoIsGranted = (state: AppState): boolean =>
  state.integrations.monoIsGranted ||
  Boolean(state.integrations.initialMonoCredentials?.item.isGranted);

export const getMonoAccounts = (state: AppState) =>
  state.integrations.monoAccounts;

export const selectMonoInitialCredentials = createSelector(
  getInitialMonoCredentials,
  (credentials) => credentials,
);

export const selectMonoAccountsAutocomplete = createSelector(
  getMonoAccounts,
  (accounts) => accounts,
);

export const selectLastIntegrationCredentials = createSelector(
  getIntegrationCredentials,
  (credentials) =>
    credentials.length
      ? credentials[credentials.length - 1]
      : { _id: '', currencyId: '', label: '' },
);

export const selectPrivat24BusinessIntegrationCredentials = createSelector(
  getPrivat24BusinessIntegrationCredentials,
  (credentials) => credentials,
);

export const selectPrivat24BusinessAccounts = createSelector(
  selectPrivat24BusinessIntegrationCredentials,
  (credentials) => (credentials ? credentials.accounts : []),
);

export const selectPrivat24BusinessAccountsAutocompleteProps = createSelector(
  selectPrivat24BusinessAccounts,
  (accounts) =>
    accounts.map((el) => ({
      id: el.acc,
      name: `${el.name} - ${el.balance} ${el.currency}`,
    })),
);

export const selectTelegramApiKeys = createSelector(
  getTelegramApi,
  (data) => data.apiKeys,
);

export const selectLoadingTelegramApi = createSelector(
  getTelegramApi,
  (data) => data.loading,
);

export const selectCountriesAutocomplete = createSelector(
  getLanguage,
  (language) => {
    const i18 = i18next.getFixedT(language);

    // @ts-ignore
    const countries: CountryProps[] = countriesJSON.reduce((acc, el) => {
      const { name, alpha2Code, ...rest } = el;

      if (i18next.exists(`countries.${name}`)) {
        return [
          ...acc,
          {
            ...rest,
            id: alpha2Code,
            alpha2Code,
            name: i18(`countries.${name}`),
          },
        ];
      }

      return acc;
    }, []);

    return countries.sort(sortByName);
  },
);

export const getSaltedgeIntegrationId = (state: AppState) =>
  state.integrations.saltedgeIntegrationId;
