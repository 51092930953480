import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';

import DragAndDropIcon from '../../../../../assets/images/svg/drug_drop_small.svg';
import EditIcon from '../../../../../assets/images/svg/edit.svg';
import UnVisibleIcon from '../../../../../assets/images/svg/eye-hide.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../../../components/PreviewRegisterDialog';
import {
  ALLOW_CREATE_COMPANY_FROM_PREVIEW,
  GLOBAL_COUNTERPARTY_LIST,
} from '../../../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../../../hooks/useUnleash';
import commonActions from '../../../../../store/common/actions';
import { isPreviewPage } from '../../../../../utils/isLocalhost';
import { ComponentProps, ItemBase } from '../EditDialogHOC/types';
import ExportItemsToExcel from '../ExportItemsToExcel';
import CreateDialog from './CreateDialog';
import EditRow from './EditRow';
import { useStyles } from './styles';

const ReactSortableComponent = ReactSortable as React.ElementType;

function EditDialog<T extends ItemBase>(props: ComponentProps<T>) {
  const {
    data,
    type,
    onClose,
    isError,
    isLoading,
    isEditable,
    deleteItem,
    createItem,
    createTitle,
    resetCreateError,
    createButtonText,
    updateGroupItems,
  } = props;
  const classes = useStyles();
  const featureAllowCompanyCreateEnable = useUnleash(
    ALLOW_CREATE_COMPANY_FROM_PREVIEW,
  );
  const isGlobalCounterpartyListFeatureEnable = useUnleash(
    GLOBAL_COUNTERPARTY_LIST,
  );

  const prevStateLoading = useRef(false);

  const dispatch = useDispatch();

  const [items, setItems] = useState<T[]>([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditRow, setShowEditRow] = useState(false);
  const [currentItem, setCurrentItem] = useState<T | null>(null);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const handleShowRestrictedDialog = useCallback(() => {
    dispatch(commonActions.setShowRestrictedDialog(true));
  }, [dispatch]);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenEditRow = useCallback(() => {
    if (!isEditable) {
      handleShowRestrictedDialog();
    } else {
      setShowEditRow(true);
    }
  }, [isEditable, handleShowRestrictedDialog]);

  const handleCloseEditRow = useCallback(() => {
    setShowEditRow(false);
  }, []);

  const handleOpenCreateDialog = useCallback(() => {
    handleClosePreviewRegisterDialog();

    if (!isEditable) {
      handleShowRestrictedDialog();
    } else {
      setShowCreateDialog(true);
    }
  }, [
    handleClosePreviewRegisterDialog,
    isEditable,
    handleShowRestrictedDialog,
  ]);

  const handleCloseCreateDialog = useCallback(() => {
    setShowCreateDialog(false);

    resetCreateError();
  }, [resetCreateError]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleUpdate = useCallback(
    (label: string, visible: boolean) => {
      if (currentItem) {
        const element = {
          ...currentItem,
          label,
          visible,
        };

        updateGroupItems([element]);
      }
    },
    [updateGroupItems, currentItem],
  );

  const handleDelete = useCallback(
    (id: string) => {
      deleteItem(id);
      handleCloseEditRow();
    },
    [deleteItem, handleCloseEditRow],
  );

  const handleCreate = useCallback(
    (name: string) => {
      createItem(name);
    },
    [createItem],
  );

  const onSortStart = useCallback(() => {
    if (!isEditable) {
      handleShowRestrictedDialog();
    }
  }, [isEditable, handleShowRestrictedDialog]);

  const onSortEnd = useCallback(() => {
    const newArray = items.map((item, index) => ({
      ...item,
      orderIndex: index,
    }));

    updateGroupItems(newArray);
  }, [items, updateGroupItems]);

  const handleEditClick = useCallback(
    (event: any) => {
      if (featureAllowCompanyCreateEnable && isPreviewPage()) {
        handleOpenPreviewRegisterDialog();

        return;
      }

      event.stopPropagation();

      const { id } = event.currentTarget;

      if (id.startsWith('edit')) {
        const itemId = id.substr(5);
        const item = items.find((i) => i._id === itemId);

        if (item) {
          setCurrentItem(item);
          handleOpenEditRow();
        }
      } else {
        const itemId = id.substr(10);
        const item = items.find((i) => i._id === itemId);

        if (item) {
          item.visible = true;
          updateGroupItems([item]);
        }
      }
    },
    [
      featureAllowCompanyCreateEnable,
      handleOpenPreviewRegisterDialog,
      items,
      handleOpenEditRow,
      updateGroupItems,
    ],
  );

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (prevStateLoading.current !== isLoading && !isError) {
      handleCloseCreateDialog();
      handleCloseEditRow();

      setCurrentItem(null);
    }

    prevStateLoading.current = isLoading;
  }, [
    isLoading,
    isError,
    handleCloseCreateDialog,
    handleCloseEditRow,
    resetCreateError,
  ]);

  return (
    <>
      <Dialog
        title={createTitle}
        isOpened
        onClose={handleClose}
        className={classes.dialog}
        titleClassName={classes.title}
      >
        <>
          {showEditRow && (
            <div className={cn(classes.sticky, classes.stickyRow)}>
              <EditRow
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                item={currentItem}
                isError={isError}
                type={type}
                onClose={handleCloseEditRow}
                resetCreateError={resetCreateError}
              />
            </div>
          )}
        </>
        <>
          {!showEditRow &&
            !(isGlobalCounterpartyListFeatureEnable && type === 'clients') && (
              <div className={cn(classes.sticky, classes.buttonContainer)}>
                <CustomButton
                  action={handleOpenPreviewRegisterDialog}
                  title={createButtonText}
                  className={classes.button}
                />
                <ExportItemsToExcel />
              </div>
            )}
        </>
        <ReactSortableComponent
          // @ts-ignore
          list={items}
          // @ts-ignore
          setList={setItems}
          onEnd={onSortEnd}
          onStart={onSortStart}
        >
          {items.map((item) => (
            <div
              key={item._id}
              className={cn(classes.row, !item.visible && classes.opacity)}
            >
              <div className={classes.leftBlock}>
                <img
                  alt="dragAndDropIcon"
                  src={DragAndDropIcon}
                  className={cn(classes.opacity, classes.margin16)}
                />
                <Typography className={cn(classes.text, classes.shortNames)}>
                  {item.label}
                </Typography>
              </div>
              <div
                id={item.visible ? `edit-${item._id}` : `invisible-${item._id}`}
                className={classes.rightBlock}
                onClick={handleEditClick}
              >
                {item.visible ? (
                  <img alt="" src={EditIcon} />
                ) : (
                  <img alt="" src={UnVisibleIcon} />
                )}
              </div>
            </div>
          ))}
        </ReactSortableComponent>
      </Dialog>
      {showCreateDialog && (
        <CreateDialog
          title={createTitle}
          buttonText={createButtonText}
          onCreate={handleCreate}
          onClose={handleCloseCreateDialog}
          isLoading={isLoading}
          isError={isError}
          type={type}
          resetCreateError={resetCreateError}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleOpenCreateDialog}
        />
      )}
    </>
  );
}

export default React.memo(EditDialog);
