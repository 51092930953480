import Http from '../Http';

const getMerchantIntegrationData = (accountId: string, merchantId: number) =>
  Http.makeInstance().post(`/integrations/fondyApiCredentials`, {
    merchantId,
    additionalNotFondyAccount: accountId,
  });

const getCredentials = (redirectUrl: string) =>
  Http.makeInstance().get(`/integrations/fondyApiCredentials/v2?redirectUrl=${redirectUrl}`);

const updateCredentials = (accountId: string, code: string) =>
  Http.makeInstance().post('/integrations/fondyApiCredentials/v2',
    {
      code,
      additionalNotFondyAccount: accountId,
    });

export default {
  getCredentials,
  updateCredentials,
  getMerchantIntegrationData,
};
