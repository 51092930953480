import moment from 'moment';

import {
  AnyObject,
  BaseXlsxParser,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class PlanfactXlsxImporter extends BaseXlsxParser {
  protected readonly COLUMNS_MAP = {
    dateOfPayment: [['Дата оплаты'], null],
    paymentState: [['Статус оплаты'], null],
    dateOfAccrual: [['Дата начисления'], null],
    accrualStatus: [['Статус начисления'], null],
    counterparty: [['Контрагент'], null],
    counterpartyINN: [['ИНН контрагента'], null],
    type: [['Тип'], null],
    bill: [['Счет'], null],
    billNum: [['№ Счета'], null],
    bank: [['Банк'], null],
    Bik: [['Бик'], null],
    entity: [['Юрлицо'], null],
    entityINN: [['ИНН юрлица'], null],
    article: [['Статья'], null],
    parentArticles: [['Родительские статьи'], null],
    kindOfActivity: [['Вид деятельности'], null],
    comment: [['Комментарий'], null],
    projects: [['Проекты'], null],
    sum: [['Сумма'], null],
    currency: [['Валюта'], null],
    paymentName: [['Назначение платежа'], null],
  };

  protected doBeforeTranform(): AnyObject[] {
    const errors = this.getErrors();
    const HEADERS = Object.values(this.headersJson);

    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(h))
          return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
      }
    });

    const {
      dateOfPayment: [dateDest],
      dateOfAccrual: [dateOfPaymentDest],
      sum: [sumDest],
    } = this.COLUMNS_MAP;

    if (typeof dateDest !== 'string' && typeof dateOfPaymentDest !== 'string')
      this.throwError(`${errors.dateNotValid}`, 1);
    if (typeof sumDest !== 'string')
      this.throwError(`${errors.sumNotValid}`, 1);

    return this.sheetJson;
  }

  private readonly EXTRA_CATEGORIES = [
    'Расходы',
    'Внеоборотные активы',
    'Обязательства',
    'Доходы',
    'Капитал',
    'Оборотные активы',
  ];

  private transferState: {
    prevDate: string;
    prevType: OperationType;
    prevAbsSum: number;
    wasteOperationIndexes: Set<number>;
    prevBill?: string;
    prevCategory?: string;
  };

  protected importName = 'Planfact';

  private checkCategoryIfExtra(category: string): boolean {
    return this.EXTRA_CATEGORIES.every((ec) => ec !== category);
  }

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const {
      sum,
      parentArticles,
      entity,
      dateOfAccrual,
      dateOfPayment,
      comment,
      projects,
      counterparty,
      article,
      bill,
      currency,
      paymentName,
    } = this.getAllColumns(operation, index);

    let isAfterAccrual, isBeforeAccrual, isAfterPayment, isBeforePayment;
    try {
      isAfterAccrual = moment(dateOfAccrual).isAfter(moment().add(10, 'y'));
      isBeforeAccrual = moment(dateOfAccrual).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      isAfterPayment = moment(dateOfPayment).isAfter(moment().add(10, 'y'));
      isBeforePayment = moment(dateOfPayment).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfterAccrual || isAfterPayment)
      this.throwError(errors.maxDate, index);
    if (isBeforeAccrual || isBeforePayment)
      this.throwError(errors.minDate, index);

    const type = sum >= 0 ? OperationType.INCOME : OperationType.CONSUMPTION;

    const resO: AnyObject = {
      index,
      sum: Math.abs(sum),
      type,
      date: this.dateToFormat2(dateOfPayment || dateOfAccrual),
      dateOfPayment: this.dateToFormat2(dateOfAccrual || dateOfPayment),
    };

    let category = parentArticles ? parentArticles?.split(' - ') : [];
    category = category.filter(this.checkCategoryIfExtra.bind(this));
    category = category.map((c) => c.trim());
    if (category.length > 0)
      resO.category = category.join(this.COMMENT_DEVIDER);

    if (article) {
      if (!resO.category) resO.category = article;
      else resO.subCategory = article;
    }

    this.addIfNotFalsy(resO, {
      counterparty, // ??? counterparty || entity
      project: projects, // ??? projects !== project
      bill,
      subType: this.getSubType(type, resO.category),
      currency,
    });

    this.setComments(resO, [comment, paymentName]); //entity

    return resO;
  }

  protected transferOne(operation: AnyObject, index: number): AnyObject {
    const { prevCategory, prevType, prevAbsSum, prevDate } = this.transferState;

    // const prevSum =
    //   prevType === OperationType.INCOME ? prevAbsSum : -prevAbsSum;
    // const curSum =
    //   operation.type === OperationType.INCOME ? operation.sum : -operation.sum;
    //const isTransfer = curSum + prevSum === 0 && operation.date === prevDate;
    const isTransfer = prevCategory === '[Списание]';

    if (isTransfer) {
      operation.type = OperationType.TRANSFER;
      delete operation.subType;
      this.transferState.wasteOperationIndexes.add(index - 1);
      operation.companyCurrencySum = prevAbsSum;
    }

    let curBill;
    if (
      operation.bill &&
      !operation.bill.startsWith('[') &&
      !operation.bill.endsWith(']')
    )
      curBill = operation.bill;
    const prevBill = this.transferState.prevBill;
    if (operation.type === OperationType.CONSUMPTION && curBill)
      operation.accountFromId = curBill;
    if (operation.type === OperationType.INCOME && curBill)
      operation.accountToId = curBill;
    if (operation.type === OperationType.TRANSFER && curBill && prevBill) {
      operation.accountToId = curBill;
      operation.accountFromId = prevBill;
    }
    delete operation.bill;

    this.setAccountIDsByType(operation, operation.currency, operation.currency);
    delete operation.currency;

    this.transferState.prevDate = operation.date;
    this.transferState.prevType = operation.type;
    this.transferState.prevAbsSum = operation.sum;
    this.transferState.prevBill = curBill;
    this.transferState.prevCategory = operation.category;

    return operation;
  }

  protected doAfterTranform(): AnyObject[] {
    this.transferState = {
      prevDate: this.dateToFormat2(new Date(999, 1)),
      prevType: OperationType.TRANSFER,
      prevAbsSum: 0,
      wasteOperationIndexes: new Set(),
    };

    const withTransfers = this.transformedData.map(this.transferOne.bind(this));

    return withTransfers.filter(
      (_, i) => !this.transferState.wasteOperationIndexes.has(i),
    );
  }
}
