import { ChangeUserPasswordForm } from '@finmap/ui-components';

import { Routes } from '../../../../constants/routes';
import useUnleash from '../../../../hooks/useUnleash';
import { isPreviewPage } from '../../../../utils/isLocalhost';
import authActions from '../actions';
import { ALLOW_CREATE_COMPANY_FROM_PREVIEW } from '../constants';
import { getLanguage, getUser } from '../selectors';
import { ChangePasswordFormValues } from '../types';
import PreviewRegisterDialog from './preview-register-dialog';
import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

function ChangePasswordScene() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);
  const user = useSelector(getUser);

  const featureAllowCompanyCreateEnable = useUnleash(
    ALLOW_CREATE_COMPANY_FROM_PREVIEW,
  );

  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] = useState(
    false,
  );

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const changePassword = useCallback(
    async (data: ChangePasswordFormValues): Promise<void> => {
      if (featureAllowCompanyCreateEnable && isPreviewPage()) {
        handleOpenPreviewRegisterDialog();

        return;
      }

      if (user) {
        const request = {
          user_id: user.id,
          password: data.oldPassword,
          password_new: data.newPassword,
        };

        try {
          await dispatch(authActions.changePassword(request));

          setUpdated(true);
        } catch (e) {
          setError(true);
        }
      } else {
        history.push(`${Routes.LOGIN}/${language}`);
      }
    },
    [
      user,
      history,
      dispatch,
      language,
      handleOpenPreviewRegisterDialog,
      featureAllowCompanyCreateEnable,
    ],
  );

  return (
    <>
      <div>
        {/* eslint-disable-next-line no-nested-ternary */}
        {error ? (
          <Typography>{t('system.error')}</Typography>
        ) : updated ? (
          <Typography>{t('system.pwChangeSuccess')}</Typography>
        ) : (
          <ChangeUserPasswordForm user={user} onSubmit={changePassword} />
        )}
      </div>
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog onClose={handleClosePreviewRegisterDialog} />
      )}
    </>
  );
}

export default React.memo(ChangePasswordScene);
