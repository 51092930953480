import { SubscriptionTariff, TariffsToId } from '../../../store/types';

export const reduceTariffsToId = (tariffs: SubscriptionTariff[] | null) => {
  if (!tariffs) {
    return null;
  }

  return tariffs.reduce((acc, el) => {
    const { _id, label, price3m, price6m, price1m, price12m } = el;

    acc[label] = {
      id: _id,
      price1m,
      price3m,
      price6m,
      price12m,
    };

    return acc;
  }, {} as TariffsToId);
};
