import { SetPumbAccountPayload } from '../../services/bankIntegrations/pumb.types';
import pumbApi from './pumbApi';

export const pumbActionTypes = {
  CREATE_PUMB_CREDENTIALS: 'CREATE_PUMB_CREDENTIALS',
  SET_PUMB_INTEGRATION_ID: 'SET_PUMB_INTEGRATION_ID',
};

const createIntegrationCredentials = (data: SetPumbAccountPayload) => ({
  type: pumbActionTypes.CREATE_PUMB_CREDENTIALS,
  payload: pumbApi.createCredentials(data),
});

const setPumbIntegrationId = (id: string) => ({
  type: pumbActionTypes.SET_PUMB_INTEGRATION_ID,
  payload: { id },
});

export default {
  createIntegrationCredentials,
  setPumbIntegrationId,
};
