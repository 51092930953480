import { OperationType } from '../../../../store/operations/types';

export type Props = {
  type: OperationType;

  onRemove(scheduled: boolean): void;
  onClose(): void;
};

export enum ColorByOperationType {
  income = 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  consumption = 'linear-gradient(88.88deg, #FF9D85 0%, #FFD585 100%)',
  transfer = '#000000',
}

export type StyleProps = {
  color?: string;
  background?: string;
  isError?: boolean;
  width?: number | string;
  height?: number | string;
  multiline?: boolean;
};
