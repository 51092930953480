import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TransferIcon from '../../../../assets/images/svg/arrow-transfer-modal.svg';
import CopyIcon from '../../../../assets/images/svg/copy.svg';
import AddIcon from '../../../../assets/images/svg/payment-plus.svg';
import TrashIcon from '../../../../assets/images/svg/trash-red-20-20.svg';
import CustomButton from '../../../../components/Button';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import DialogContext from '../../../../components/Dialog/DialogRefProvider';
import PreviewRegisterDialog from '../../../../components/PreviewRegisterDialog';
import { OPERATION_TO_TRANSFER } from '../../../../constants/featureToggles/featureToggle';
import usePermissions from '../../../../hooks/usePermissions';
import useRestricted from '../../../../hooks/useRestricted';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import useUnleash from '../../../../hooks/useUnleash';
import { selectIsOwner } from '../../../../selectors/clientPermissions';
import operationActions from '../../../../store/operations/actions';
import { getOperationProps } from '../../../../store/operations/selectors';
import { OperationType } from '../../../../store/operations/types';
import { black, outrageousOrange, white } from '../../../../theme/colors';
import useSelectProps from '../../HOC/createOperationHooks/useSelectProps';
import { useStyles } from './styles';
import { Props } from './types';
import { allowRemoveCategory } from '../../../../store/auth/selectors';
import {
  DEFAULT_CONSUMPTION_EMPTY_ID,
  DEFAULT_INCOME_EMPTY_ID,
} from '../../../../constants/defaultCategories';

function FooterComponent(props: Props) {
  const {
    type,
    isCopy,
    isEdit,
    onCreate,
    isCompare,
    createOperation,
    onClickSaveButton,
    onShowRemoveDialog,
    showProjectsError,
    changeToTransfer,
  } = props;

  const operationToTransfer = useUnleash(OPERATION_TO_TRANSFER);

  const { repeats, canDelete, canEdit } = useSelector(getOperationProps);
  const isOwner = useSelector(selectIsOwner);
  const {
    createTagInProgress,
    creatingClientInProgress,
    creatingProjectInProgress,
    creatingIncomeCategoryInProgress,
    creatingConsumptionCategoryInProgress,
  } = useSelectProps();

  const createElementInProgress =
    createTagInProgress ||
    creatingClientInProgress ||
    creatingProjectInProgress ||
    creatingIncomeCategoryInProgress ||
    creatingConsumptionCategoryInProgress;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();
  const { showRestrictedDialog } = useRestricted();

  const emptyCategoryId =
    type === OperationType.income
      ? DEFAULT_INCOME_EMPTY_ID
      : DEFAULT_CONSUMPTION_EMPTY_ID;

  const allowCreateTransfer = useSelector(
    allowRemoveCategory(emptyCategoryId, type),
  );

  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);
  const [callback, setCallback] = useState<any | null>(null);

  let createGaId = 'profit_add';
  let createGa4TextId = 'profit_add_text';
  let createAndCopyGaId = 'profit_add_and_create';
  let createAndCopyGaIdText = 'profit_add_and_create_text';

  if (type === OperationType.consumption) {
    createGaId = 'expenses_add';
    createGa4TextId = 'expenses_add_text';
    createAndCopyGaId = 'expenses_add_and_create';
    createAndCopyGaIdText = 'expenses_add_and_create_text';
  } else if (type === OperationType.transfer) {
    createGaId = 'transfer_add';
    createGa4TextId = 'transfer_add_text';
    createAndCopyGaId = 'transfer_add_and_create';
    createAndCopyGaIdText = 'transfer_add_and_create_text';
  }

  const handleApplyCallback = useCallback(() => {
    setShowPreviewRegisterDialog(false);

    if (callback) {
      callback();
    }
  }, [callback]);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
    setCallback(null);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const rootDialog = useContext(DialogContext);

  const buttonClassNameMap = useMemo(
    () => ({
      [OperationType.income]: '',
      [OperationType.consumption]: classes.buttonConsumptionContainer,
      [OperationType.transfer]: classes.buttonTransferContainer,
    }),
    [classes],
  );

  const title = useMemo(
    () => ({
      [OperationType.income]: t('operationDialogs.addIncome'),
      [OperationType.consumption]: t('operationDialogs.addConsumption'),
      [OperationType.transfer]: t('operationDialogs.addTransfer'),
    }),
    [t],
  );

  const handleResetAmounts = useCallback(() => {
    dispatch(operationActions.setAmount({ amount: '' }));
    dispatch(operationActions.setCurrencyAmount({ currencyAmount: '' }));
    dispatch(
      operationActions.setCreateAndCopyState({ createAndCopyState: false }),
    );
  }, [dispatch]);

  const handleCreateAndCopy = useCallback(() => {
    createOperation(repeats.id !== 1000, false);

    rootDialog?.hide();

    setTimeout(() => {
      handleResetAmounts();

      dispatch(operationActions.setRepeats({ repeats: { id: 1000 } }));
      dispatch(operationActions.setIsCopyComponent({ isCopyComponent: true }));
      dispatch(
        operationActions.setCreateAndCopyState({ createAndCopyState: true }),
      );
      dispatch(operationActions.setAttachments([]));

      rootDialog?.show();
    }, 300);
  }, [dispatch, repeats, rootDialog, createOperation, handleResetAmounts]);

  const handleSetCopyComponent = useCallback(() => {
    rootDialog?.hide();

    setTimeout(() => {
      handleResetAmounts();

      dispatch(operationActions.setIsCopyComponent({ isCopyComponent: true }));
      dispatch(
        operationActions.setCreateAndCopyState({ createAndCopyState: true }),
      );
      dispatch(operationActions.setAttachments([]));

      rootDialog?.show();
    }, 300);
  }, [dispatch, rootDialog, handleResetAmounts]);

  const handleClick = useCallback(
    (fn: any) => {
      setCallback(() => fn);

      handleOpenPreviewRegisterDialog();
    },
    [handleOpenPreviewRegisterDialog],
  );

  const RenderSetCopyComponent = useCallback(() => {
    const onClick = () => {
      if (!operationEnable) {
        return;
      }

      handleClick(handleSetCopyComponent);
    };

    return (
      <ButtonRowWithIcons
        disabled={!operationEnable}
        onClick={onClick}
        icon={CopyIcon}
        alt="makeDouble"
        label={t('operationDialogs.makeDouble')}
      />
    );
  }, [handleClick, handleSetCopyComponent, t, operationEnable]);

  const RenderOperationToTransferComponent = useCallback(() => {
    const onClick = () => {
      if (!operationEnable) {
        return;
      }

      if (!allowCreateTransfer) {
        showRestrictedDialog();

        return;
      }
      handleClick(changeToTransfer);
    };

    return (
      <ButtonRowWithIcons
        disabled={!operationEnable}
        onClick={onClick}
        icon={TransferIcon}
        alt="to transfer"
        label={t('operationDialogs.changeToTransfer')}
      />
    );
  }, [
    operationEnable,
    t,
    handleClick,
    changeToTransfer,
    showRestrictedDialog,
    allowCreateTransfer,
  ]);

  const RenderShowRemoveDialog = useCallback(() => {
    const onClick = () => {
      handleClick(onShowRemoveDialog);
    };

    return (
      <ButtonRowWithIcons
        disabled={!operationEnable}
        textColor={outrageousOrange}
        onClick={onClick}
        icon={TrashIcon}
        alt="remove"
        label={t('operationDialogs.remove')}
      />
    );
  }, [handleClick, onShowRemoveDialog, t, operationEnable]);

  const RenderSaveButton = useCallback(() => {
    const onClick = () => {
      if (isEdit && !canEdit) {
        showRestrictedDialog(() => handleClick(onClickSaveButton));
      } else {
        handleClick(onClickSaveButton);
      }
    };

    return (
      <CustomButton
        fullWidth
        disabled={
          !subscriptionActive ||
          !operationEnable ||
          createElementInProgress ||
          showProjectsError
        }
        action={onClick}
        title={isCopy ? title[type] : t('saveChanges')}
        className={buttonClassNameMap[type]}
        textColor={type === OperationType.transfer ? white : black}
      />
    );
  }, [
    t,
    type,
    title,
    isEdit,
    isCopy,
    canEdit,
    handleClick,
    operationEnable,
    onClickSaveButton,
    showProjectsError,
    subscriptionActive,
    buttonClassNameMap,
    showRestrictedDialog,
    createElementInProgress,
  ]);

  const RenderCreateButton = useCallback(() => {
    const onClick = () => {
      handleClick(onCreate);
    };

    return (
      <CustomButton
        id={createGaId}
        ga4TextId={createGa4TextId}
        title={title[type]}
        action={onClick}
        fullWidth
        disabled={
          createElementInProgress || showProjectsError || !operationEnable
        }
        className={buttonClassNameMap[type]}
        textColor={type === OperationType.transfer ? white : black}
      />
    );
  }, [
    type,
    title,
    onCreate,
    createGaId,
    handleClick,
    operationEnable,
    createGa4TextId,
    showProjectsError,
    buttonClassNameMap,
    createElementInProgress,
  ]);

  const RenderCreateAndCopy = useCallback(() => {
    const onClick = () => {
      if (!createElementInProgress) {
        handleClick(handleCreateAndCopy);
      }
    };

    return (
      <div
        id={createAndCopyGaId}
        onClick={onClick}
        className={cn(
          classes.flexRow,
          classes.marginTop16,
          classes.height40,
          !createElementInProgress && classes.cursorPointer,
        )}
      >
        <img src={AddIcon} alt="add" className={classes.addIcon} />
        <Typography id={createAndCopyGaIdText} className={classes.buttonText}>
          {t('operationDialogs.createAndCopy')}
        </Typography>
      </div>
    );
  }, [
    t,
    classes,
    handleClick,
    createAndCopyGaId,
    handleCreateAndCopy,
    createAndCopyGaIdText,
    createElementInProgress,
  ]);

  return (
    <>
      {isEdit && !isCopy && !isCompare && (
        <>
          {operationToTransfer && isEdit && type !== OperationType.transfer && (
            <RenderOperationToTransferComponent />
          )}
          <RenderSetCopyComponent />
          {(isOwner || canDelete) && <RenderShowRemoveDialog />}
        </>
      )}
      {!isCompare && (
        <>
          {isEdit && !isCopy && (
            <>
              <div className={classes.separator} />
              <RenderSaveButton />
            </>
          )}
          {(!isEdit || (isEdit && isCopy)) && (
            <>
              <div className={classes.separator} />
              <RenderCreateButton />
              <RenderCreateAndCopy />
            </>
          )}
        </>
      )}
      {showPreviewRegisterDialog && callback && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleApplyCallback}
        />
      )}
    </>
  );
}

export default React.memo(FooterComponent);
