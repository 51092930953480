import PopperComponent from '../../../../components/PopperComponent';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { persianGreen } from '../../../../theme/colors';
import { useStyles } from './styles';
import { Props } from './types';
import { MenuItem, MenuList, Typography } from '@material-ui/core';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function PopperCurrencySelector(props: Props) {
  const { t } = useTranslation();
  const subscriptionActive = useSubscriptionActive();

  const {
    onClose,
    anchorRef,
    currencies,
    ownCurrencies,
    onChangeCurrency,
    selectedCurrency,
    cryptoCurrenciesList,
  } = props;

  const classes = useStyles();

  const handleChangeCurrency = useCallback(
    (event) => {
      const { id } = event.target;

      onChangeCurrency(id);
    },
    [onChangeCurrency],
  );

  return (
    <PopperComponent
      anchorRef={anchorRef}
      onClose={onClose}
      width={200}
      placement="bottom-end"
      paperClasses={cn(
        classes.paperContainer,
        !subscriptionActive && classes.disabledInput,
      )}
    >
      <MenuList
        onClick={handleChangeCurrency}
        className={cn(classes.listRoot, 'scrollable-list')}
      >
        {currencies.map((el) => (
          <MenuItem key={el.id}>
            <div id={el.id} className={classes.menuItem}>
              {`${el.id} (${el.symbol})`}
              {selectedCurrency?.id === el.id && (
                <CheckIcon color={persianGreen} />
              )}
            </div>
          </MenuItem>
        ))}
        {!ownCurrencies && (
          <>
            <Typography className={classes.crypto}>
              {t('currency.crypto').toUpperCase()}
            </Typography>
            {cryptoCurrenciesList.map((el) => (
              <MenuItem key={el.id}>
                <div id={el.id} className={classes.menuItem}>
                  {`${el.id} (${el.symbol})`}
                  {selectedCurrency?.id === el.id && (
                    <CheckIcon color={persianGreen} />
                  )}
                </div>
              </MenuItem>
            ))}
          </>
        )}
      </MenuList>
    </PopperComponent>
  );
}

export default React.memo(PopperCurrencySelector);
