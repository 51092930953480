import { ImportType } from '../../store/import/types';

export const listPFDImports = [
  ImportType.kaspiPrivate,
  ImportType.forteBank,
  ImportType.mBankVATPoland,
  ImportType.ecoCenterBank,
];

export const listAvailablePFDImports = [
  ...listPFDImports,
  ImportType.kaspiBusiness,
];

export const listAvailableCSVImports = [
  ImportType.santanderPoland,
  ImportType['3sMoney'],
  ImportType.sebBankLatvia,
];

export const listAvailableXSLImports = [
  ImportType.planfact,
  ImportType.finolog,
  ImportType.otpBank,
  ImportType.vostokBank,
  ImportType.kaspiPrivate,
  ImportType.kaspiBusiness,
];
