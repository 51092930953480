import { aquaHaze, white } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    background: 'inherit',
    borderRadius: 0,
    height: 56,
    padding: '16px 0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    '&:hover': {
      background: aquaHaze,
      borderRadius: 8,
    },
    cursor: 'pointer',
  },
  selectText: {
    fontSize: 15,
  },
  selectContainer: {
    paddingLeft: 16,
    paddingRight: 15,
    background: aquaHaze,
    borderRadius: 12,
    height: 56,
    '&::before': {
      borderBottom: 'none',
    },
  },
  '.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  popper: {
    zIndex: 100,
    width: '87%',
  },
  selected: {
    fontWeight: 'bold',
  },
  dropdownContainer: {
    marginTop: 10,
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    paddingTop: 22,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 15,
    lineHeight: '20px',
    marginBottom: 23,
  },
  icon: {
    marginRight: 20,
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
});
