import { Client } from '../clients/types';
import { PeriodsFilterTypes } from '../filters/types';
import { JournalData, MultiOrderBy, ProjectObject } from '../journal/types';
import { InvoicingProps } from './sagas.types';

export type InvoicePayload = {
  counterpartyPropsDetails: string;
  invoiceCurrency: string;
  invoiceNumber: string;
  myPropsDetails: string;
  myPropsId: string;
  withVAT: boolean;
  _id: string;
  discountPercentage?: number;
  discountAmount?: number;
  shipping?: number;
};

export type SortLogByDate = {
  [key in string]: InvoiceItemProps[];
};

export type InvoiceItemProps = JournalData &
  InvoicePayload & {
    invoiceGoods: (InvoiceGoodProps & { invoiceGoodId: string })[];
    projects: Omit<ProjectObject, 'label'>[];
    confirmedInvoice: boolean;
  };

export enum InvoicingStatus {
  overdue = 'overdue',
  payed = 'payed',
  notPayed = 'notPayed',
  all = 'all',
}

export type InvoicingCustomFilters = {
  clients: string[] | null;
  statuses: string[] | null;
};

export type InvoiceClient = Client & { detail: string };

export type InvoicingState = {
  log: {
    items: InvoiceItemProps[];
    total: number;
  };
  myProps: (Client & InvoicingProps)[];
  clients: InvoiceClient[];
  goods: InvoiceGoodProps[];
  periodFilter: PeriodsFilterTypes;
  customFilters: InvoicingCustomFilters;
  loading: boolean;
  multiOrderBy: MultiOrderBy;
  loadingCompanyInvoicingData: boolean;
};

export type InvoiceGoodProps = {
  _id: string;
  unit: string;
  count: number | string;
  price: number | string;
  sum: number;
  label: string;
  vat?: number;
};

export type InvoiceProps = {
  scheduled: boolean;
  schedulePeriodId: number;
  tags: string[];
  projects: Omit<ProjectObject, 'label'>[];
  companyCurrencySum: number;
  exchangeRate: number;
  externalId: string;
  scheduleEndDate: number;
  scheduleMaxCount: number;
  scheduleDays: string;
  scheduleInterval: number;
  scheduleIntervalType: number;
  periodEndTimestamp: number;
  periodStartTimestamp: number;
  undoHistoryId: string;
  transactionSum: number;
  transactionCurrency: string;
  transactionCurrencyRate: number;
  accountToId: string;
  categoryId: string;
  sum: number;
  approved: boolean;
  comment: string;
  date: number;
  dateOfPayment: number;
  clientId: string;
  invoiceCurrency: string;
  invoiceNumber: string;
  myPropsId: string;
  counterpartyId: string;
  myPropsDetails: string;
  counterpartyPropsDetails: string;
  withVAT: boolean;
  goods: InvoiceGoodProps[];
  discountPercentage?: number | null;
  discountAmount?: number | null;
  shipping?: number;
};
