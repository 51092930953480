import moment from 'moment';

import { OPERATION_TYPES } from '@finmap/core-constants';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class KredoBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn([
              'Дата проводки',
              'Дата проведення',
              9,
            ]),
            time: moment(
              this.getFirstValidCellByColumn([
                'Время пров.',
                'Час проведення',
                13,
              ]),
              'HH:mm:ss',
            ).toISOString(),
            type: this.getFirstValidCellByColumn([
              'Операция',
              'Операція',
              2,
            ])?.includes('Дебет')
              ? OPERATION_TYPES.CON
              : OPERATION_TYPES.INC,
            sum: this.getFirstValidCellByColumn(['Сумма', 'Сума', 11]),
            comment: this.getFirstValidCellByColumn([
              'Назначение платежа',
              'Призначення платежу',
              8,
            ]),
            currency: this.getFirstValidCellByColumn(['Валюта', 'Валюта', 12]),
            counterparty: this.getFirstValidCellByColumn([
              'Наименование контр.',
              'Найменування контрагента',
              6,
            ]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
