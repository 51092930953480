import { AxiosRequestConfig } from 'axios';

import Http from '../../services/Http';
import { Attachment } from '../operations/types';
import {
  AddFileToInvoicePayload,
  CreateInvoicePayload,
  CreateMyInvoicingPropsPayload,
  CreateNewCompanyInvoiceGoodPayload,
  GetInvoicesLogPayload,
  UpdateCompanyInvoiceGoodPayload,
  UpdateInvoiceByIdPayload,
  UpdateMyInvoicingPropsPayload,
} from './sagas.types';
import { InvoiceGoodProps } from './types';

const createNewInvoice = (
  payload: Omit<CreateInvoicePayload, 'file' | 'client'>,
) => Http.makeInstance().post('/operations/income/invoices', { ...payload });

const updateInvoiceById = (payload: UpdateInvoiceByIdPayload) => {
  const { _id, ...data } = payload;

  return Http.makeInstance().patch(`/operations/income/invoices/${_id}`, {
    ...data,
  });
};

const getInvoiceById = (id: string) =>
  Http.makeInstance().get(`/operations/income/invoices/${id}`);

const deleteInvoices = (ids: string[]) =>
  Http.makeInstance().post('/operations/income/invoices', { ids });

const getAllCompanyInvoiceGoods = () =>
  Http.makeInstance().get('/operations/income/invoices/goods');

const createNewCompanyInvoiceGood = (
  payload: CreateNewCompanyInvoiceGoodPayload,
) =>
  Http.makeInstance().post('/operations/income/invoices/goods', { ...payload });

const updateCompanyInvoiceGood = (payload: UpdateCompanyInvoiceGoodPayload) => {
  const { _id, ...rest } = payload;

  return Http.makeInstance().patch(`/operations/income/invoices/goods/${_id}`, {
    ...rest,
  });
};

const updateGroupGoods = (goods: InvoiceGoodProps[]) =>
  Http.makeInstance().patch('/operations/income/invoices/goods', goods);

const getMyInvoicingProps = () =>
  Http.makeInstance().get(`/operations/income/invoices/props?isMy=true`);

const createNewMyInvoicingProps = (payload: CreateMyInvoicingPropsPayload) =>
  Http.makeInstance().post(`/operations/income/invoices/props?isMy=true`, {
    ...payload,
    isMy: true,
  });

const updateMyInvoicingProps = (payload: UpdateMyInvoicingPropsPayload) => {
  const { _id, ...rest } = payload;

  return Http.makeInstance().patch(
    `/operations/income/invoices/props/${_id}?isMy=true`,
    { ...rest },
  );
};

const downloadAttachment = (contentId: string) =>
  Http.makeInstance().get(`/storage/${contentId}`, {
    responseType: 'blob',
  });

const uploadAttachment = (
  formData: FormData,
  config: AxiosRequestConfig = {},
) =>
  Http.makeInstance().post('/storage/upload', formData, {
    ...config,
  });

const addAttachment = (attachment: Attachment) => {
  const { _id, ...rest } = attachment;
  return Http.makeInstance().put(`/operations/income/invoices/${_id}/files`, {
    ...rest,
  });
};

const removeAttachment = (id: string, fileId: string) =>
  Http.makeInstance().delete(
    `/operations/income/invoices/${id}/files/${fileId}`,
  );

const addFileToInvoice = (payload: AddFileToInvoicePayload) => {
  const { id, ...data } = payload;

  return Http.makeInstance().put(`/operations/income/invoices/${id}/files`, {
    ...data,
  });
};

const deleteFileFromInvoice = (fileId: string) =>
  Http.makeInstance().delete(
    `/operations/income/invoices/{id}/files/${fileId}`,
  );

const getInvoicesLog = (payload: GetInvoicesLogPayload & { query: string }) => {
  const { query, limit, offset } = payload;

  return Http.makeInstance().post('operations/log', {
    query: `offset=${offset}&limit=${limit}&${query}&invoice=true`,
  });
};

const updateApprove = (ids: string[]) =>
  Http.makeInstance().patch('/operations/income/invoices/confirmedInvoice', {
    ids,
  });

const removeInvoice = (id: string) =>
  Http.makeInstance().delete(`/operations/income/invoices/${id}`);

const removeGood = (id: string) =>
  Http.makeInstance().delete(`/operations/income/invoices/goods/${id}`);

const removeMyProps = (id: string) =>
  Http.makeInstance().delete(
    `/operations/income/invoices/props/${id}?isMy=true`,
  );

export default {
  removeGood,
  updateApprove,
  addAttachment,
  removeInvoice,
  removeMyProps,
  getInvoiceById,
  deleteInvoices,
  getInvoicesLog,
  addFileToInvoice,
  uploadAttachment,
  removeAttachment,
  createNewInvoice,
  updateGroupGoods,
  updateInvoiceById,
  downloadAttachment,
  getMyInvoicingProps,
  deleteFileFromInvoice,
  updateMyInvoicingProps,
  updateCompanyInvoiceGood,
  getAllCompanyInvoiceGoods,
  createNewMyInvoicingProps,
  createNewCompanyInvoiceGood,
};
