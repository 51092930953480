import React from 'react';

import ErrorPage from '../scenes/Error';

type Props = {
  children: JSX.Element | JSX.Element[];
};

type State = {
  error: any;
};

class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null };

  componentDidCatch(error: any, _errorInfo: any) {
    if (error.message.includes('Loading chunk')) {
      // tslint:disable-next-line: deprecation
      window.location.reload(true);
    }
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
