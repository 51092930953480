import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { ReactElement } from 'react';

import { FiltersState } from '../../../../store/filters/types';

export type Props = {
  reportType: keyof FiltersState;
};

export enum ReportTypeFilterBy {
  categories = 'categories',
  counterparties = 'counterparties',
  projects = 'projects',
}

export type FilterOnboardingV2TooltipWrapperProps = {
  children: ReactElement;
  placement: TooltipProps['placement'];
  open: boolean;
};
