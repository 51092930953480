import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze } from '../../../../../../theme/colors';

export const useStyles = makeStyles({
  currenciesContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    marginBottom: 0,
    borderRadius: 12,
    background: aquaHaze,
    width: 190,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
  },
});
