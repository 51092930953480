import moment, { Moment, unitOfTime } from 'moment';

export const getTimeOffset = (date?: Date | Moment | number) => {
  if (date) {
    return moment(date).utcOffset() * 60000;
  }

  return moment().utcOffset() * 60000;
};

export const dateToUTC = (date: Date | Moment | number) =>
  moment(date).valueOf() + moment().utcOffset() * 60000;

export const UTCtoDate = (date: Date | Moment | number | null) =>
  moment(date).valueOf() - moment().utcOffset() * 60000;

export function localToUtc(value: string | number | Date | null) {
  return moment(value).utcOffset(0, true);
}

export function utcToLocal(value: string | number | Date) {
  const utcOffset = moment().utcOffset();

  return moment(value).utcOffset(utcOffset, false);
}

export function getDiffBetweenToday(
  a: number | Date | Moment,
  unitTime: unitOfTime.Diff,
  format = 'YYYY-MM-DD',
  b?: number | Date | Moment,
) {
  const start = moment(a).format(format);
  const end = b ? moment(b).format(format) : moment.utc().format(format);

  return moment(start).diff(moment(end), unitTime);
}
