import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorIcon from '../../../../assets/images/svg/error-triangle-splitpayment.svg';
import { getOperationProps } from '../../../../store/operations/selectors';
import { OperationType } from '../../../../store/operations/types';
import { useStyles } from './styles';
import {
  SplitPaymentProjectDetailedInfo,
  SplitPaymentProjectsInfoProps,
} from './types';

function SplitPaymentProjectsInfo({
  operationType,
  setShowDeletedProjectError,
}: SplitPaymentProjectsInfoProps) {
  const { splitPaymentProjects } = useSelector(getOperationProps);

  const classes = useStyles();
  const { t } = useTranslation();

  const isOperationTypeIncome = operationType === OperationType.income;

  const splitPaymentSum: number = Number(
    splitPaymentProjects
      .reduce(
        (acc: number, project: SplitPaymentProjectDetailedInfo) =>
          acc + Number(project.sum),
        0,
      )
      .toFixed(4),
  );
  const splitPaymentPercentages: number = splitPaymentProjects.reduce(
    (acc: number, project: SplitPaymentProjectDetailedInfo) =>
      acc + Number(project.percentages),
    0,
  );

  const getSplitPaymentProjectsInfo = useCallback(() => {
    if (setShowDeletedProjectError) {
      setShowDeletedProjectError(false);
    }

    return splitPaymentProjects.map(
      (project: SplitPaymentProjectDetailedInfo, index) => {
        const isDeletedProject = !project.project?.label;

        if (isDeletedProject && setShowDeletedProjectError) {
          setShowDeletedProjectError(true);
        }

        return (
          <div
            className={cn(
              classes.flexRow,
              classes.noFlexWrap,
              classes.width100,
            )}
            key={`${project.project?._id}_${index}`}
          >
            <div className={cn(classes.splitPaymentInfoCol50)}>
              {isDeletedProject ? (
                <span
                  className={cn(
                    classes.splitPaymentInfoProjectCol,
                    classes.splitPaymentInfoProjectLabel,
                  )}
                >
                  {t('operationDialogs.deletedProject')}
                  <img src={ErrorIcon} alt="error icon" />
                </span>
              ) : (
                <span
                  className={cn(
                    classes.splitPaymentInfoProjectCol,
                    classes.splitPaymentInfoProjectLabel,
                  )}
                >
                  {project.project?.label}
                </span>
              )}
            </div>
            <div
              className={cn(
                classes.splitPaymentInfoCol50,
                classes.justifyEnd,
                classes.flexGap16,
              )}
            >
              {isOperationTypeIncome ? (
                <div className={cn(classes.splitPaymentInfoProjectSumPositive)}>
                  + {project.sum}
                </div>
              ) : (
                <div className={cn(classes.splitPaymentInfoProjectSumNegative)}>
                  - {project.sum}
                </div>
              )}
              <div className={cn(classes.numberCol)}>
                {Number(project.percentages).toFixed(2)}%
              </div>
            </div>
          </div>
        );
      },
    );
  }, [
    classes,
    isOperationTypeIncome,
    setShowDeletedProjectError,
    splitPaymentProjects,
    t,
  ]);

  return (
    <div className={cn(classes.splitPaymentInfoContainer)}>
      {getSplitPaymentProjectsInfo()}
      <div
        className={cn(
          classes.flexRow,
          classes.noFlexWrap,
          classes.width100,
          classes.splitPaymentInfoFinalRow,
        )}
      >
        <div className={cn(classes.splitPaymentInfoCol50)}>
          <span className={cn(classes.splitPaymentInfoProjectCol)}>
            {t('operationDialogs.totalSum')}
          </span>
        </div>
        <div
          className={cn(
            classes.splitPaymentInfoCol50,
            classes.justifyEnd,
            classes.flexGap16,
            classes.boldText,
          )}
        >
          {isOperationTypeIncome ? (
            <div className={cn(classes.splitPaymentInfoProjectSumPositive)}>
              + {splitPaymentSum.toFixed(2)}
            </div>
          ) : (
            <div className={cn(classes.splitPaymentInfoProjectSumNegative)}>
              - {splitPaymentSum.toFixed(2)}
            </div>
          )}
          <div className={cn(classes.numberCol, classes.boldText)}>
            {splitPaymentPercentages.toFixed(2)}%
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SplitPaymentProjectsInfo);
