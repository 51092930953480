import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import Dialog from '../Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';

function AlertDialog(props: Props) {
  const {
    title,
    onClose,
    onAccept,
    acceptText,
    description,
    Description,
    buttonTextColor,
    buttonBackground,
  } = props;
  const classes = useStyles();

  return (
    <Dialog isOpened onClose={onClose} title={title}>
      <>
        {!!description && (
          <Typography className={classes.description}>{description}</Typography>
        )}
        {!!Description && Description}
      </>
      <div className={classes.footer}>
        <Button
          className={cn(classes.button, buttonBackground)}
          onClick={onAccept}
          id="cypress-confirm-remove-button"
        >
          <Typography className={cn(classes.buttonText, buttonTextColor)}>
            {acceptText}
          </Typography>
        </Button>
      </div>
    </Dialog>
  );
}

export default React.memo(AlertDialog);
