import Http from '../../services/Http';

const importOperationsXLSX = (
  operations: any[],
  fileName: string,
  type?: string,
  importParserName?: string,
) =>
  Http.makeInstance().post('/imports', {
    operations,
    fileName,
    type,
    importParserName,
  });

const importKaspiPrivate = (file: ArrayBuffer) => {
  const formData = new FormData();

  formData.append('file', new Blob([file]));

  return Http.makeInstance().post('/pdfParse/upload', formData);
};

export default {
  importKaspiPrivate,
  importOperationsXLSX,
};
