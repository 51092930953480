import { MenuItem, MenuList, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from 'classnames';
import moment from 'moment/moment';
import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';

import PopperComponent from '../../../../../components/PopperComponent';
import {
  MAX_OPERATION_DATE_IN_YEARS,
  MIN_OPERATION_DATE,
} from '../../../../../constants';
import { useStyles } from './RenderMonthElement.styles';
import { RenderMonthElementProps } from './RenderMonthElement.types';

function generateYearsList() {
  const years = [];

  for (
    let a = moment(MIN_OPERATION_DATE).year();
    a <= moment().year() + MAX_OPERATION_DATE_IN_YEARS;
    a += 1
  ) {
    years.push(a);
  }

  return years;
}

function RenderMonthElement(props: RenderMonthElementProps) {
  const { month, onMonthSelect, onYearSelect } = props;

  const [showMonthsList, setShowMonthsList] = useState(false);
  const [showYearsList, setShowYearsList] = useState(false);

  const anchorRefMonth = useRef<HTMLDivElement | null>(null);
  const anchorRefYear = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();

  const handleToggleShowMonthsList = useCallback(() => {
    setShowMonthsList(!showMonthsList);
  }, [showMonthsList]);

  const handleCloseMonthsList = useCallback(() => {
    setShowMonthsList(false);
  }, []);

  const handleToggleShowYearsList = useCallback(() => {
    setShowYearsList(!showYearsList);
  }, [showYearsList]);

  const handleCloseYearsList = useCallback(() => {
    setShowYearsList(false);
  }, []);

  const handleMonthSelect = useCallback(
    (event: SyntheticEvent) => {
      // @ts-ignore
      onMonthSelect(month, event.target.value);

      handleCloseMonthsList();
    },
    [onMonthSelect, month, handleCloseMonthsList],
  );

  const handleYearSelect = useCallback(
    (event: SyntheticEvent) => {
      // @ts-ignore
      onYearSelect(month, event.target.value);
    },
    [onYearSelect, month],
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div
            ref={anchorRefMonth}
            className={classes.row}
            onClick={handleToggleShowMonthsList}
          >
            <Typography>{month.format('MMMM')}</Typography>

            {showMonthsList ? (
              <ArrowDropUpIcon className={classes.arrowIcon} />
            ) : (
              <ArrowDropDownIcon className={classes.arrowIcon} />
            )}
          </div>
          <div
            ref={anchorRefYear}
            className={classes.row}
            onClick={handleToggleShowYearsList}
          >
            <Typography>{month.format('YYYY')}</Typography>
            {showYearsList ? (
              <ArrowDropUpIcon className={classes.arrowIcon} />
            ) : (
              <ArrowDropDownIcon className={classes.arrowIcon} />
            )}
          </div>
        </div>
      </div>
      {showMonthsList && (
        <PopperComponent
          disablePortal={false}
          anchorRef={anchorRefMonth}
          onClose={handleCloseMonthsList}
          placement="bottom-start"
          paperClasses={cn(classes.paper, 'scrollable-list')}
        >
          <MenuList id="menu-months-list-grow">
            {moment.months().map((label, index) => (
              <MenuItem key={index} onClick={handleMonthSelect} value={index}>
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </PopperComponent>
      )}
      {showYearsList && (
        <PopperComponent
          disablePortal={false}
          anchorRef={anchorRefYear}
          onClose={handleCloseYearsList}
          placement="bottom-start"
          paperClasses={cn(classes.paper, 'scrollable-list')}
        >
          <MenuList id="menu-years-list-grow">
            {generateYearsList().map((label, index) => (
              <MenuItem key={index} onClick={handleYearSelect} value={label}>
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </PopperComponent>
      )}
    </>
  );
}

export default React.memo(RenderMonthElement);
