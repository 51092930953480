import { makeStyles } from '@material-ui/core/styles';

import {
  aquaHaze,
  catskillWhite,
  mystic,
} from '../../../../../../theme/colors';

export const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  opacity: {
    opacity: 0.5,
  },
  margin16: {
    marginRight: 16,
  },
  copyField: {
    borderRadius: 12,
    padding: '18px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: aquaHaze,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: mystic,
    },
  },
  tooltipRoot: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '0 20px !important',
    opacity: '1 !important',
    height: 45,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  questionIcon: {
    marginRight: 22,
    width: 20,
    height: 20,
    opacity: 0.5,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 5,
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
  },
  rowWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingRight: 5,
    paddingLeft: 5,
    '&:hover': {
      background: catskillWhite,
      cursor: 'pointer',
      borderRadius: 8,
    },
  },
  apikey: {
    width: 330,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
  },
  margin24: {
    marginBottom: 24,
  },
  tooltipText: {
    fontSize: 13,
    lineHeight: '20px',
  },
  cursorActive: {
    cursor: 'pointer',
  },
});
