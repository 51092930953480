import Http from '../Http';
import { SetPumbAccountPayload } from './pumb.types';

const getAccounts = ({
  login,
  password,
}: {
  login: string;
  password: string;
}) =>
  Http.makeInstance().post('/integrations/pumbForBusinessApiCredentials', {
    login,
    password,
  });

const setAccount = (data: SetPumbAccountPayload) =>
  Http.makeInstance().patch('/integrations/pumbForBusinessApiCredentials', {
    ...data,
  });

export default {
  getAccounts,
  setAccount,
};
