import { Typography } from '@material-ui/core';
import React from 'react';
import cn from 'classnames';

import { useStyles } from './styles';
import GmailIcon from '../../assets/images/svg/gmail.svg';
import FacebookIcon from '../../assets/images/svg/facebook.svg';
import LinkedinIcon from '../../assets/images/svg/linkedin.svg';
import TwitterIcon from '../../assets/images/svg/twitter.svg';

export type SocialNetworksType = 'google' | 'facebook' | 'linkedin' | 'twitter';

export type SocialNetworksMapType = { 
  [Property in SocialNetworksType]: { 
    icon: string, 
    alt: string,
    title: string,
  } 
}

export const SocialNetworksMap: SocialNetworksMapType = {
  'google': {
    icon: GmailIcon,
    alt: 'gmail icon',
    title: 'Google',
  },
  'facebook': {
    icon: FacebookIcon,
    alt: 'facebook icon',
    title: 'Facebook',
  },
  'linkedin': {
    icon: LinkedinIcon,
    alt: 'linkedin icon',
    title: 'Linkedin',
  },
  'twitter': {
    icon: TwitterIcon,
    alt: 'twitter icon',
    title: 'Twitter',
  }
}

export interface SocialNetworkIconProps {
  isActive?: boolean;
  className?: string;
  onClick?(): void;
  socialNetworkType: SocialNetworksType,
}

export function SocialNetworkIcon({
  isActive = true,
  className,
  onClick,
  socialNetworkType,
}: SocialNetworkIconProps) {

  const classes = useStyles();
  const socialNetwork = SocialNetworksMap[socialNetworkType];

  if(!isActive) return null;

  return (
    <div
      className={cn(classes.socialNetworkItem, className)}
      onClick={onClick}
    >
      <div>
        <img
          src={socialNetwork.icon}
          width={24}
          height={24}
          alt={socialNetwork.alt}
          className={classes.socialNetworkImage}
        />
      </div>
      <Typography className={classes.socialNetworkTitle}>
        {socialNetwork.title}
      </Typography>
    </div>
  );
}

export default SocialNetworkIcon;