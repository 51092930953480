import React from 'react';

import { useStyles } from './styles';

export interface ActivityIdentifierProps {
  isActive: boolean;
}

export function ActivityIdentifier(props: ActivityIdentifierProps) {
  const classes = useStyles();

  if(!props.isActive) {
    return null;
  }

  return <div className={classes.filteredIndicator} />
}

export default ActivityIdentifier;
