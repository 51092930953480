import { useSelector } from 'react-redux';

import { selectCompanyCurrency } from '../../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../../store/currency/selectors';
import { getOperationProps } from '../../../../store/operations/selectors';
import { dateToUTC } from '../../../../utils/dateToUTC';
import { calculateSumAndCompanyCurrencySum } from '../utils';
import helpers from './helpers';
import { MainPayloadProps } from './types';

function useMainPayload(props: MainPayloadProps) {
  const { editProps, isEdit } = props;

  const {
    type,
    amount,
    account,
    repeats,
    comments,
    incomeDate,
    revertState,
    selectedTags,
    exchangeRate,
    endTimestamp,
    currencyValue,
    dateOfPayment,
    currencyAmount,
    startTimestamp,
    splitPaymentProjects,
    dateOfPaymentPeriodId,
  } = useSelector(getOperationProps);
  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const isCrypto = cryptoCurrencyCodes.includes(account?.currency.code || '');

  const calculateSumAndCompanyCurrencySumProps = {
    type,
    isEdit,
    amount,
    account,
    isCrypto,
    editProps,
    revertState,
    exchangeRate,
    currencyValue,
    currencyAmount,
    companyCurrency,
  };

  const {
    sum,
    companyCurrencySum,
    exchangeRateValue,
    transactionSum,
    transactionCurrency,
    transactionCurrencyRate,
  } = calculateSumAndCompanyCurrencySum(calculateSumAndCompanyCurrencySumProps);

  const getProjects = () => {
    if (!splitPaymentProjects) {
      return [];
    }

    if (!Array.isArray(splitPaymentProjects)) {
      return [
        {
          // @ts-ignore
          id: splitPaymentProjects?._id,
          stake: 100,
          sum,
          transactionSum,
          companyCurrencySum,
        },
      ];
    }

    if (splitPaymentProjects.length === 1) {
      return [
        {
          id: splitPaymentProjects[0].project?._id,
          stake: 100,
          sum,
          transactionSum,
          companyCurrencySum,
        },
      ];
    }

    const splitPaymentProjectsAmount = splitPaymentProjects.reduce(
      (acc, el) => acc + (el.companyCurrencySum ?? 0),
      0,
    );

    let error = 0;

    if (
      companyCurrencySum &&
      splitPaymentProjectsAmount &&
      companyCurrencySum - splitPaymentProjectsAmount > 0.011
    ) {
      error =
        (companyCurrencySum - splitPaymentProjectsAmount) /
        splitPaymentProjects.length;
    }

    return splitPaymentProjects.map((projectInfo) => ({
      id: projectInfo?.project?._id,
      stake: Number(projectInfo.percentages),
      sum: Number(projectInfo.sum ?? sum),
      // @ts-ignore
      transactionSum: projectInfo.transactionSum + error ?? transactionSum,
      companyCurrencySum:
        // @ts-ignore
        projectInfo.companyCurrencySum + error ?? companyCurrencySum,
    }));
  };

  const { newDateOfPayment, periodStartTimestamp, periodEndTimestamp } =
    helpers.getCustomTimeStamps({
      dateOfPayment,
      startTimestamp,
      endTimestamp,
      incomeDate,
      periodId: dateOfPaymentPeriodId,
    });

  return {
    approved: Boolean(editProps?.approved),
    sum: Number(sum),
    companyCurrencySum,
    exchangeRate: exchangeRateValue,
    transactionSum,
    transactionCurrency,
    transactionCurrencyRate,
    date: incomeDate,
    schedulePeriodId:
      repeats.id >= 0 && repeats.id <= 4 ? repeats.id : undefined,
    tags: selectedTags ? selectedTags.map((t: any) => t.value) : undefined,
    projects: getProjects(),
    comment: comments || '',
    scheduleEndDate: repeats.payload?.scheduleEndDate
      ? dateToUTC(repeats.payload.scheduleEndDate)
      : undefined,
    scheduleMaxCount: repeats.payload?.scheduleMaxCount || undefined,
    scheduleInterval: repeats.payload?.scheduleInterval || undefined,
    scheduleIntervalType: repeats.payload?.scheduleIntervalType || undefined,
    scheduleDays: repeats.payload?.scheduleDays || undefined,
    periodStartTimestamp,
    periodEndTimestamp,
    dateOfPayment: newDateOfPayment,
  };
}

export default useMainPayload;
