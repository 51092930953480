import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 28,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 27,
    cursor: 'pointer',
  },
  textFieldRoot: {
    marginBottom: 0,
    flex: 1,
  },
  error: {
    bottom: -32,
  },
});
