import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RevertIcon from '../../../../../assets/images/svg/revert-course.svg';
import TextFieldComponent from '../../../../../components/TextField/TextFieldComponent';
import TransitionComponent from '../../../../../components/TransitionComponent';
import {
  BALANCE_DIGITS,
  CRYPTO_EXCHANGE_RATE_DIGITS,
  EXCHANGE_RATE_DIGITS,
} from '../../../../../constants';
import useSubscriptionActive from '../../../../../hooks/useSubscriptionActive';
import operationActions from '../../../../../store/operations/actions';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../../../store/operations/selectors';
import { formatStringPrice } from '../../../../../utils/parseStringToNumber';
import { convertExpToString } from '../../../../../utils/stringUtils';
import { useCompareStyles } from '../../compareStyles';
import useChangeRates from './hooks/useChangeRates';
import { useStyles } from './styles';
import { Props } from './types';

const FIXED_BALANCE_DIGITS = 2;

function AmountByCurrencyTransferV2(props: Props) {
  const {
    isEdit,
    isError,
    isCompare,
    difference,
    isNewState,
    isCryptoAccounts,
    isTransformToTransfer = false,
    isTransformFromIncome,
  } = props;

  const opProps = useSelector(getOperationProps);
  const nextOperationProps = useSelector(getHistoryOperationProps);

  const operationProps = isNewState ? nextOperationProps : opProps;

  const {
    amount,
    account,
    toAccount,
    revertState,
    exchangeRate: fullRate,
    currencyAmount,
    createAndCopyState,
    revertedExchangeRate: fullRevertedRate,
  } = operationProps;

  const dispatch = useDispatch();
  const subscriptionActive = useSubscriptionActive();

  const classes = useStyles({ isError });
  const compareClasses = useCompareStyles();
  const { t } = useTranslation();

  const accountCurrencyIdRef = useRef('');
  const toAccountCurrencyIdRef = useRef('');

  const [fieldWasChanged, setFieldWasChanged] = useState({
    amount: false,
    currencyAmount: false,
    exchangeRate: Boolean(isEdit),
    account: false,
    toAccount: false,
  });

  const balanceDigits = isCryptoAccounts
    ? CRYPTO_EXCHANGE_RATE_DIGITS
    : BALANCE_DIGITS;
  const fixedBalanceDigits = isCryptoAccounts
    ? CRYPTO_EXCHANGE_RATE_DIGITS
    : FIXED_BALANCE_DIGITS;
  const exchangeRateDigits = isCryptoAccounts
    ? CRYPTO_EXCHANGE_RATE_DIGITS
    : EXCHANGE_RATE_DIGITS;

  const exchangeRate = fullRate
    ? formatStringPrice(fullRate, false, exchangeRateDigits)
    : fullRate;

  const revertedExchangeRate = fullRevertedRate
    ? formatStringPrice(fullRevertedRate, false, exchangeRateDigits)
    : fullRevertedRate;

  const setRates = useChangeRates(revertState, isCryptoAccounts);

  const handleResetCreateAndCopyState = useCallback(() => {
    dispatch(
      operationActions.setCreateAndCopyState({ createAndCopyState: false }),
    );
  }, [dispatch]);

  const handleChangeAmount = useCallback(
    (value) => {
      setFieldWasChanged((values) => ({
        ...values,
        amount: true,
      }));

      const amountValue = formatStringPrice(value, false, balanceDigits);

      dispatch(
        operationActions.setAmount({
          amount: amountValue,
        }),
      );

      const rate = revertState ? revertedExchangeRate : exchangeRate;
      if (!isTransformToTransfer && rate && fieldWasChanged.exchangeRate) {
        const currencyAmountValue = amountValue
          ? parseFloat(
              formatStringPrice(
                parseFloat(amountValue) * parseFloat(rate),
                false,
                4,
              ),
            ).toFixed(fixedBalanceDigits)
          : null;

        dispatch(
          operationActions.setCurrencyAmount({
            currencyAmount: currencyAmountValue,
          }),
        );
      } else if (currencyAmount) {
        const directRate = parseFloat(currencyAmount) / parseFloat(amountValue);

        setRates(directRate);
      }
    },
    [
      dispatch,
      setRates,
      revertState,
      exchangeRate,
      balanceDigits,
      currencyAmount,
      fieldWasChanged,
      fixedBalanceDigits,
      revertedExchangeRate,
      isTransformToTransfer,
    ],
  );

  const handleChangeCurrencyAmount = useCallback(
    (value) => {
      setFieldWasChanged((values) => ({
        ...values,
        currencyAmount: true,
      }));

      const currencyAmountValue = formatStringPrice(
        value,
        false,
        balanceDigits,
      );

      dispatch(
        operationActions.setCurrencyAmount({
          currencyAmount: currencyAmountValue,
        }),
      );

      const rate = revertState ? revertedExchangeRate : exchangeRate;

      if (!isTransformToTransfer && rate && fieldWasChanged.exchangeRate) {
        const amountValue = currencyAmountValue
          ? parseFloat(
              formatStringPrice(
                parseFloat(currencyAmountValue) / parseFloat(rate),
                false,
                4,
              ),
            ).toFixed(fixedBalanceDigits)
          : null;

        dispatch(
          operationActions.setAmount({
            amount: amountValue,
          }),
        );
      } else if (amount) {
        const directRate = value
          ? parseFloat(currencyAmountValue) / parseFloat(amount)
          : null;

        setRates(directRate);
      }
    },
    [
      amount,
      dispatch,
      setRates,
      revertState,
      exchangeRate,
      balanceDigits,
      fieldWasChanged,
      fixedBalanceDigits,
      revertedExchangeRate,
      isTransformToTransfer,
    ],
  );

  const handleChangeExchangeRateAmount = useCallback(
    (value) => {
      setFieldWasChanged((values) => ({
        ...values,
        exchangeRate: Boolean(value),
      }));

      setRates(value);

      if ((amount && !isTransformFromIncome) || (amount && !currencyAmount)) {
        const currencyAmountValue = Number(value)
          ? parseFloat(
              formatStringPrice(parseFloat(amount) * value, false, 4),
            ).toFixed(fixedBalanceDigits)
          : null;

        dispatch(
          operationActions.setCurrencyAmount({
            currencyAmount: currencyAmountValue,
          }),
        );
      } else if (currencyAmount) {
        const amountValue = Number(value)
          ? parseFloat(
              formatStringPrice(parseFloat(currencyAmount) / value, false, 4),
            ).toFixed(fixedBalanceDigits)
          : null;

        dispatch(
          operationActions.setAmount({
            amount: amountValue,
          }),
        );
      }
    },
    [
      amount,
      dispatch,
      setRates,
      currencyAmount,
      fixedBalanceDigits,
      isTransformFromIncome,
    ],
  );

  const handleClickRevertExchangeRate = useCallback(() => {
    dispatch(operationActions.setRevertState(!revertState));

    dispatch(
      operationActions.setAmount({
        amount: currencyAmount,
      }),
    );
    dispatch(
      operationActions.setCurrencyAmount({
        currencyAmount: amount,
      }),
    );
  }, [amount, dispatch, revertState, currencyAmount]);

  useEffect(() => {
    if (isEdit && currencyAmount && amount) {
      dispatch(
        operationActions.setAmount({
          // @ts-ignore
          amount: convertExpToString(Number(currencyAmount)),
        }),
      );
      dispatch(
        operationActions.setCurrencyAmount({
          currencyAmount: String(amount),
        }),
      );
    }
  }, [dispatch, isEdit]); // eslint-disable-line

  useEffect(() => {
    if (
      account &&
      accountCurrencyIdRef.current &&
      accountCurrencyIdRef.current !== account.currency._id
    ) {
      setFieldWasChanged((fields) => ({
        ...fields,
        account: true,
      }));
    }
    if (
      toAccount &&
      toAccountCurrencyIdRef.current &&
      toAccountCurrencyIdRef.current !== toAccount.currency._id
    ) {
      setFieldWasChanged((fields) => ({
        ...fields,
        toAccount: true,
      }));
    }
  }, [account, toAccount]);

  useEffect(() => {
    if (account && !accountCurrencyIdRef.current) {
      accountCurrencyIdRef.current = account.currency._id;
    }

    if (toAccount && !toAccountCurrencyIdRef.current) {
      toAccountCurrencyIdRef.current = toAccount.currency._id;
    }
  }, [account, toAccount]);

  useEffect(() => {
    const shouldGetRates =
      account?.currency._id !== accountCurrencyIdRef.current ||
      toAccount?.currency._id !== toAccountCurrencyIdRef.current ||
      fieldWasChanged.account ||
      fieldWasChanged.toAccount;

    if (shouldGetRates || !isEdit) {
      if (account && toAccount) {
        // dispatch(
        //   operationActions.getExchangeRateToFrom({
        //     to: toAccount.currency._id,
        //     from: account.currency._id,
        //   }),
        // );
        if (!isTransformToTransfer) {
          dispatch(
            operationActions.getExchangeRateFromTo({
              to: toAccount.currency._id,
              from: account.currency._id,
            }),
          );
        }
      }

      setFieldWasChanged((values) => ({
        ...values,
        exchangeRate: true,
      }));
    }
  }, [
    isEdit,
    account,
    dispatch,
    toAccount,
    fieldWasChanged.account,
    fieldWasChanged.toAccount,
    isTransformToTransfer,
  ]);

  const isDiffCurrencyAmount =
    Boolean(difference?.sum) || Boolean(difference?.exchangeRate);

  const toAccountCurrencySymbol = revertState
    ? account?.currency.symbol
    : toAccount?.currency.symbol;

  const toAccountCurrencyCode = revertState
    ? account?.currency.code
    : toAccount?.currency.code;

  const accountCurrencySymbol = revertState
    ? toAccount?.currency.symbol
    : account?.currency.symbol;

  const accountCurrencyCode = revertState
    ? toAccount?.currency.code
    : account?.currency.code;

  const labelRates = revertState
    ? `${toAccount?.currency.symbol} / ${account?.currency.symbol}`
    : `${account?.currency.symbol} / ${toAccount?.currency.symbol}`;

  const label = exchangeRate ? `${t('common.rate')} ${labelRates}` : '';

  useEffect(() => {
    if (isTransformToTransfer) {
      if (amount !== null && currencyAmount !== null && exchangeRate === '') {
        const rate = revertState
          ? parseFloat(amount) / parseFloat(currencyAmount)
          : parseFloat(currencyAmount) / parseFloat(amount);
        setRates(rate.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, currencyAmount, isTransformToTransfer, exchangeRate]);

  return (
    <>
      <div
        className={cn(
          classes.root,
          createAndCopyState && classes.marginBottom32,
        )}
      >
        <div
          onFocus={handleResetCreateAndCopyState}
          className={cn(
            classes.amountContainer,
            Boolean(difference?.sum) && compareClasses.root,
            createAndCopyState && classes.blueBackground,
          )}
        >
          <TextFieldComponent
            disabled={isCompare || !subscriptionActive}
            onChange={handleChangeAmount}
            rootClassName={cn(isCompare && compareClasses.disabledInput)}
            value={amount || ''}
            label={
              amount ? `${accountCurrencyCode}, ${accountCurrencySymbol}` : ''
            }
            placeholder={
              accountCurrencyCode
                ? `${accountCurrencyCode}, ${accountCurrencySymbol}`
                : ''
            }
          />
        </div>
        <div className={cn(classes.revertIcon, classes.marginRight10)}>
          <img
            src={RevertIcon}
            alt="revert"
            onClick={handleClickRevertExchangeRate}
          />
        </div>
        <div
          className={cn(
            classes.amountContainer,
            isDiffCurrencyAmount && compareClasses.root,
          )}
        >
          <TextFieldComponent
            disabled={isCompare || !subscriptionActive}
            rootClassName={cn(isCompare && compareClasses.disabledInput)}
            onChange={handleChangeCurrencyAmount}
            value={currencyAmount || ''}
            label={
              currencyAmount
                ? `${toAccountCurrencyCode}, ${toAccountCurrencySymbol}`
                : ''
            }
            placeholder={
              toAccountCurrencyCode
                ? `${toAccountCurrencyCode}, ${toAccountCurrencySymbol}`
                : ''
            }
          />
        </div>

        <TransitionComponent
          className={classes.blueColor}
          enter={createAndCopyState}
          text={t('operationDialogs.setPaymentAmount')}
        />
        <TransitionComponent
          enter={isError}
          text={t('system.fieldsMustFilled')}
        />
      </div>
      <div
        className={cn(
          isDiffCurrencyAmount && compareClasses.root,
          classes.marginBottom16,
        )}
      >
        <TextFieldComponent
          disabled={isCompare || !subscriptionActive}
          rootClassName={cn(
            classes.rateContainer,
            Boolean(difference?.exchangeRate) && compareClasses.root,
            isCompare && compareClasses.disabledInput,
          )}
          onChange={handleChangeExchangeRateAmount}
          value={(revertState ? revertedExchangeRate : exchangeRate) || ''}
          label={label}
          placeholder={t('common.rate')}
        />
      </div>
    </>
  );
}

export default React.memo(AmountByCurrencyTransferV2);
