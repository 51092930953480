import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TrashIcon from '../../../assets/images/svg/trash-red.svg';
import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../components/PreviewRegisterDialog';
import RemoveDialog from '../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { getUser } from '../../../store/auth/selectors';
import { selectCurrenciesAutoComplete } from '../../../store/currency/selectors';
import { AutoCompleteProps } from '../../../store/types';
import { useStyles } from './styles';
import { Props } from './types';

function EditCompanyDialog(props: Props) {
  const { onClose, onUpdate, onDelete, currentCompany } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const currencies = useSelector(selectCurrenciesAutoComplete);
  const user = useSelector(getUser);

  const isOwner = currentCompany.createdBy === user?.id;

  const [name, setName] = useState('');
  const [currency, setCurrency] = useState<AutoCompleteProps | null>(null);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showRemovePreviewRegisterDialog, setShowRemovePreviewRegisterDialog] =
    useState(false);
  const [showEditPreviewRegisterDialog, setEditShowPreviewRegisterDialog] =
    useState(false);

  const handleCloseRemovePreviewRegisterDialog = useCallback(() => {
    setShowRemovePreviewRegisterDialog(false);
  }, []);

  const handleOpenRemovePreviewRegisterDialog = useCallback(() => {
    if (isOwner) {
      setShowRemovePreviewRegisterDialog(true);
    }
  }, [isOwner]);

  const handleCloseEditPreviewRegisterDialog = useCallback(() => {
    setEditShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenEditPreviewRegisterDialog = useCallback(() => {
    setEditShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenRemoveDialog = useCallback(() => {
    setShowRemovePreviewRegisterDialog(false);

    if (isOwner) {
      setShowRemoveDialog(true);
    }
  }, [isOwner]);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleChangeName = useCallback((value: string) => {
    setName(value);
  }, []);

  const handleUpdate = useCallback(() => {
    setEditShowPreviewRegisterDialog(false);

    if (name && currency) {
      onUpdate(name, currency.id);
    }
  }, [onUpdate, name, currency]);

  const handleRemove = useCallback(() => {
    onDelete(currentCompany._id);

    handleCloseRemoveDialog();
  }, [onDelete, currentCompany, handleCloseRemoveDialog]);

  const handleChangeCurrency = useCallback((val: any) => {
    setCurrency(val);
  }, []);

  useEffect(() => {
    const { name: accName, currencyId } = currentCompany;
    const companyCurrency = currencies.find((el) => el.id === currencyId);

    setName(accName);

    if (companyCurrency) {
      setCurrency(companyCurrency);
    }
  }, [currentCompany, currencies]);

  return (
    <>
      <Dialog title={t('company.edit')} isOpened onClose={onClose}>
        <div className={classes.fieldWrapper}>
          <TextFieldComponent
            disabled={!isOwner}
            onChange={handleChangeName}
            value={name}
            placeholder={t('common.title')}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <AutocompleteSelector
            disabled
            label={t('currency.mainCurrency')}
            value={currency}
            onChange={handleChangeCurrency}
            data={currencies}
          />
        </div>
        <div
          id="delete_campaign_menu"
          onClick={handleOpenRemovePreviewRegisterDialog}
          className={cn(
            classes.row,
            isOwner && classes.link,
            !isOwner && classes.disabled,
            classes.lastBlock,
          )}
        >
          <img alt="" src={TrashIcon} className={classes.icon} />
          <Typography
            id="delete_campaign_menu_text"
            className={cn(classes.defaultText, classes.deleteText)}
          >
            {t('company.delete')}
          </Typography>
        </div>
        <CustomButton
          id="save_changes_campaign_menu"
          ga4TextId="save_changes_campaign_menu_text"
          action={handleOpenEditPreviewRegisterDialog}
          title={t('saveChanges')}
          disabled={!isOwner}
          fullWidth
        />
      </Dialog>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveAccount"
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemove}
          name={currentCompany.name}
        />
      )}
      {showEditPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleCloseEditPreviewRegisterDialog}
          callback={handleUpdate}
        />
      )}
      {showRemovePreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleCloseRemovePreviewRegisterDialog}
          callback={handleOpenRemoveDialog}
        />
      )}
    </>
  );
}

export default React.memo(EditCompanyDialog);
