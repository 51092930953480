import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PayseraImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLineRaw = this.findString(/Сумма и валюта/)?.raw;
          const lastBodyLineRaw = this.findString(/Остаток в начале/)?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLineRaw]);
          this.setDocumentBody(
            importDocument.slice(firstHeaderLineRaw + 1, lastBodyLineRaw - 1),
          );
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата и время',
              3,
            ])?.slice(0, -'+0300'.length),
            sum: this.getFirstValidCellByColumn(['Сумма', 6]),
            credit: this.getFirstValidCellByColumn(['Credit', 7]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
            currency: this.getFirstValidCellByColumn(['Валюты', 7]),
          });
        },
        caseOptions: { defaultCurrency: 'EUR' },
      },
    ],
  };
}
