export const isLocalhost = () =>
  window.location.origin.indexOf('localhost') !== -1;

export const isQA = () => window.location.origin.indexOf('qa.') !== -1;

export const isStage = () => window.location.origin.indexOf('stage') !== -1;

export const isTestEnvironment = () => 'Cypress' in window;

export const isPreviewPage = () => window.location !== window.parent.location;
