import { createSelector } from 'reselect';

import { EMPTY, WITHOUT_ACCOUNT_NAME } from '../../constants';
import {
  prepareCustomFiltersQuerySelector,
  prepareCustomFiltersQuerySelectorObj,
} from '../../selectors/main';
import {
  selectFilters,
  selectFilterSearch,
  selectFilterTagsSearch,
  selectJournalCustomFilters,
} from '../filters/selectors';
import { FiltersState } from '../filters/types';
import {
  ConsumptionOperationSubType,
  IncomeOperationSubType,
} from '../operations/types';
import { AppState } from '../reducers';
import { JournalData, LogRequestBody, MultiOrderBy } from './types';

export const getJournal = (state: AppState) => state.journal.journal;
export const getShowFuturePayments = (state: AppState) =>
  state.journal.showFuturePayments;

export const getIsApprovedFuture = (state: AppState) =>
  state.journal.isApprovedFuture;

export const getOrdersBy = (state: AppState) => state.journal.multiOrderBy;

export const isJournalLoading = (state: AppState) => state.journal.loading;

export const isFutureJournalLoading = (state: AppState) =>
  state.journal.loadingFuture;

export const getTotalFutureAmount = (state: AppState) =>
  state.journal.totalFutureAmount;

export const selectFutureItems = createSelector(
  getJournal,
  (journal) => journal?.futureItems || [],
);

export const selectJournal = createSelector(
  getJournal,
  getShowFuturePayments,
  (journal, showPayments) => {
    if (!journal) {
      return journal;
    }

    if (showPayments) {
      return {
        ...journal,
        items: [...(journal.futureItems || []), ...journal.items],
      };
    }

    const items = journal.items.filter((el) => el.approved);

    return {
      ...journal,
      items,
      total: journal.total,
    };
  },
);

export const selectEnableEditCount = (isFastPermissions: boolean) =>
  createSelector(
    selectJournal,
    (journal) =>
      journal?.items.reduce(
        (sum: number, item: JournalData) =>
          (item.isReadOnly && !isFastPermissions) || item._id === 'future'
            ? sum
            : sum + 1,
        0,
      ) || 0,
  );

export const getOperationsExist = (state: AppState) =>
  state.journal.operationsExist;

export const selectJournalItems = createSelector(
  selectJournal,
  (journal) => journal.items,
);

export const selectJournalFutureItems = createSelector(
  selectJournal,
  (journal) => journal.futureItems,
);

export const selectJournalItemsCount = createSelector(
  selectJournalItems,
  (items) => items.length,
);

export const selectJournalTotalItems = createSelector(
  selectJournal,
  (journal) => journal.total,
);

export const showProjectColumn = createSelector(selectJournalItems, (items) =>
  Boolean(items?.some((item) => Boolean(item.projects))),
);

export const showAdditionalColumn = createSelector(
  selectJournalItems,
  (items) =>
    Boolean(
      items?.some(
        (item) => !item.tagIds.includes(EMPTY) || !!item.attachments?.length,
      ),
    ),
);

export const showCommentColumn = createSelector(selectJournalItems, (items) =>
  Boolean(items?.some((item) => Boolean(item.comment))),
);

export const selectActiveAccountsIds = createSelector(
  selectJournalCustomFilters,
  (filters) => {
    const { accounts, categoriesBySubType } = filters;
    const accountIds = accounts?.map((el) => el) || [];

    if (
      categoriesBySubType &&
      categoriesBySubType.includes(ConsumptionOperationSubType.generic) &&
      categoriesBySubType.includes(IncomeOperationSubType.generic)
    ) {
      accountIds.push(WITHOUT_ACCOUNT_NAME);
    }

    return accountIds;
  },
);

export const createBaseQuery = (selector: keyof FiltersState) =>
  createSelector(
    selectFilters(selector),
    prepareCustomFiltersQuerySelector(selector),
    getOrdersBy,
    selectFilterSearch,
    selectFilterTagsSearch,
    (allFilters, customFiltersQuery, getSorts, search, tags) => {
      const { id, titlePeriod, ...filters } = allFilters;

      let query = '';

      const ordersQuery =
        Object?.keys(getSorts)
          .reduce((row, order, index) => {
            if (!index) {
              row.push(`desc=${getSorts[order] === 'desc'}&field=${order}`);
              return row;
            }

            if (!getSorts[order]) {
              return row;
            }

            row.push(
              `desc${index}=${
                getSorts[order] === 'desc'
              }&field${index}=${order}`,
            );

            return row;
          }, [] as string[])
          .join('&') || '';

      if (selector === 'journal') {
        let searchRow: string[] = [];

        if (tags.length) {
          // @ts-ignore
          searchRow = tags.map((el: Value) => el.label);
        }

        if (search) {
          searchRow.push(search);
        }

        if (searchRow.length) {
          query += `&search=${searchRow.join(',')}&`;
        }
      }

      return { query, ordersQuery, customFiltersQuery, filters };
    },
  );

export const createBaseQueryObj = (selector: keyof FiltersState) =>
  createSelector(
    selectFilters(selector),
    prepareCustomFiltersQuerySelectorObj(selector),
    getOrdersBy,
    selectFilterSearch,
    selectFilterTagsSearch,
    (
      allFilters,
      customFiltersLogObj: LogRequestBody,
      getSorts: MultiOrderBy,
      search: string,
      tags,
    ): LogRequestBody => {
      const { id, titlePeriod, ...filters } = allFilters;
      const logObjRequestBody: LogRequestBody = {};

      Object.assign(logObjRequestBody, filters);
      Object.assign(logObjRequestBody, customFiltersLogObj);

      Object?.keys(getSorts).forEach((order, index) => {
        if (!index) {
          logObjRequestBody.desc = getSorts[order] === 'desc';
          logObjRequestBody.field = order;
        } else if (getSorts[order]) {
          logObjRequestBody[`desc${index}`] = getSorts[order] === 'desc';
          logObjRequestBody[`field${index}`] = order;
        }
      });

      if (selector === 'journal') {
        const searchRow: string[] = [];

        tags?.forEach((el) => {
          if (Array.isArray(el)) {
            searchRow.push(...el.map((e) => e.label));
          } else if (el && 'label' in el) {
            searchRow.push(el.label);
          }
        });

        if (search) {
          searchRow.push(search);
        }

        if (searchRow.length) {
          logObjRequestBody.search = searchRow.join(',');
        }
      }
      return logObjRequestBody;
    },
  );
