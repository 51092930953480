import { useSelector } from 'react-redux';

import {
  ReportName,
  SettingsName,
} from '../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { getUserPermissions } from '../selectors/clientPermissions';
import {
  allowCreateAllConsumptionCategories,
  allowCreateAllIncomeCategories,
  analyticItemEnable,
  getIsEditableSetting,
  isAccountsVisibilityEnable,
  isAnalyticEnable,
  isCalendarEnable,
  isEmployeesEnable,
  isFuturePaymentsEnable,
  isLogEnable,
  isOperationEnable,
  isSettingsEnable,
} from '../store/auth/selectors';
import {
  selectDeletePermissionIds,
  selectEditPermissionIds,
  selectReadPermissionIds,
  selectResourcesLabelToId,
} from '../store/employees/selectors';

function usePermissions() {
  const selectIsEditableAccounts = getIsEditableSetting(SettingsName.accounts);

  const analyticHistory = analyticItemEnable(ReportName['operations/history']);

  const selectAllowCreateIncomeCategory = getIsEditableSetting(
    SettingsName['categories/income'],
  );

  const selectAllowCreateConsumptionCategory = getIsEditableSetting(
    SettingsName['categories/consumption'],
  );

  return {
    settingsEnable: useSelector(isSettingsEnable),
    resources: useSelector(selectResourcesLabelToId),
    permissions: useSelector(getUserPermissions),
    readPermissionIds: useSelector(selectReadPermissionIds),
    editPermissionIds: useSelector(selectEditPermissionIds),
    deletePermissionIds: useSelector(selectDeletePermissionIds),
    isEditableAccounts: useSelector(selectIsEditableAccounts),
    analyticEnable: useSelector(isAnalyticEnable),
    calendarEnable: useSelector(isCalendarEnable),
    accountsVisibilityEnable: useSelector(isAccountsVisibilityEnable),
    futurePaymentsEnable: useSelector(isFuturePaymentsEnable),
    logEnable: useSelector(isLogEnable),
    operationEnable: useSelector(isOperationEnable),
    employeesEnable: useSelector(isEmployeesEnable),
    historyEnable: useSelector(analyticHistory),
    allowAllIncomeCategories: useSelector(allowCreateAllIncomeCategories),
    allowAllConsumptionCategories: useSelector(
      allowCreateAllConsumptionCategories,
    ),
    isAllowedCreateIncomeCategory: useSelector(selectAllowCreateIncomeCategory),
    isAllowedCreateConsumptionCategory: useSelector(
      selectAllowCreateConsumptionCategory,
    ),
  };
}

export default usePermissions;
