import {
  CreateOnboardingStepPayload,
  DeleteOnboardingPayload,
  OnboardingV2StepInfoProps,
  UpdateOnboardingStepPayload,
} from '@finmap/core-constants';

export const onboardingActionTypes = {
  INIT: 'INIT',
  SET_ONBOARDING_STEP_INFO: 'SET_ONBOARDING_STEP_INFO',
  SET_ONBOARDING_V2_IN_PROGRESS: 'SET_ONBOARDING_V2_IN_PROGRESS',
  CREATE_ONBOARDING_V2_STEP: 'CREATE_ONBOARDING_V2_STEP',
  UPDATE_ONBOARDING_V2_STEP: 'UPDATE_ONBOARDING_V2_STEP',
  REMOVE_ONBOARDING_GREETING_STEP: 'REMOVE_ONBOARDING_GREETING_STEP',
  RESTORE_LAST_STATE: 'RESTORE_LAST_STATE',
  GET_ONBOARDING_INFO: 'GET_ONBOARDING_INFO',
  DELETE_ONBOARDING_STEP: 'DELETE_ONBOARDING_STEP',
  SET_PRE_LAST_STEP_SKIPPED: 'SET_PRE_LAST_STEP_SKIPPED',
  CREATE_ONBOARDING_DEMO_DATA: 'CREATE_ONBOARDING_DEMO_DATA',
  REMOVE_ONBOARDING_DEMO_DATA: 'REMOVE_ONBOARDING_DEMO_DATA',
  SET_SHOW_ONBOARDING_VIDEO: 'SET_SHOW_ONBOARDING_VIDEO',
};

const init = () => ({
  type: onboardingActionTypes.INIT,
});

const setShowVideo = (showVideo: boolean) => ({
  type: onboardingActionTypes.SET_SHOW_ONBOARDING_VIDEO,
  payload: { showVideo },
});

const deleteOnboardingStep = (data: DeleteOnboardingPayload) => ({
  type: onboardingActionTypes.DELETE_ONBOARDING_STEP,
  payload: data,
});

const getOnboardingInfo = () => ({
  type: onboardingActionTypes.GET_ONBOARDING_INFO,
});

const setOnboardingV2InProgress = (onboardingV2InProgress: boolean) => ({
  type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
  payload: { onboardingV2InProgress },
});

const updateOnboardingStep = (data: UpdateOnboardingStepPayload) => ({
  type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
  payload: data,
});

const createOnboardingStep = (data: CreateOnboardingStepPayload) => ({
  type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
  payload: data,
});

const restoreLastState = () => ({
  type: onboardingActionTypes.RESTORE_LAST_STATE,
});

const setOnboardingV2StepInfo = (
  onboardingSteps: OnboardingV2StepInfoProps[],
) => ({
  type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
  payload: { onboardingSteps },
});

const createOnboardingDemoData = () => ({
  type: onboardingActionTypes.CREATE_ONBOARDING_DEMO_DATA,
});

const removeOnboardingDemoData = () => ({
  type: onboardingActionTypes.REMOVE_ONBOARDING_DEMO_DATA,
});

export default {
  init,
  setShowVideo,
  restoreLastState,
  getOnboardingInfo,
  updateOnboardingStep,
  createOnboardingStep,
  deleteOnboardingStep,
  setOnboardingV2StepInfo,
  removeOnboardingDemoData,
  createOnboardingDemoData,
  setOnboardingV2InProgress,
};
