import moment from 'moment';
import xlsx from 'xlsx';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class KaspiXlsxParser extends BaseXlsxParser {
  protected importName = 'Kaspi';
  protected mainAccount: Account;
  protected beforeTransformed: AnyObject[];

  protected headersIdentifier = {
    A: '№\nдокумента',
    B: 'Дата операции',
    C: 'Дебет',
    D: 'Кредит',
    E: 'Наименование бенефициара / отправителя денег',
    F: 'ИИК бенефициара / отправителя денег',
    G: 'БИК банка бенефициара (отправителя денег)',
    H: 'КНП',
    I: 'Назначение платежа',
  };

  protected COLUMNS_MAP = {
    date: [['Дата операции'], null],
    counterparty: [['Наименование бенефициара / отправителя денег'], null],
    nominal_debt: [['Дебет'], null],
    nominal_credit: [['Кредит'], null],
    comment: [['Назначение платежа'], null],
  };

  private currencyIdentifier = { rawIndex: '1', collumn: 'C' };
  protected dateFormat = 'DD-MM-YYYY HH:mm:ss';

  protected statementsOptions: any = [
    {
      dateFormat: this.dateFormat,
      columnsMap: this.COLUMNS_MAP,
      typeIdentifier: {
        headersIdentifier: this.headersIdentifier,
        rawIndex: 8,
      },
      currencyIdentifier: this.currencyIdentifier,
      skipAfterHeader: 2,
      skipFromEnd: 4,
    },
    {
      dateFormat: this.dateFormat,
      columnsMap: this.COLUMNS_MAP,
      typeIdentifier: {
        headersIdentifier: this.headersIdentifier,
        rawIndex: 10,
      },
      currencyIdentifier: this.currencyIdentifier,
      skipAfterHeader: 2,
      skipFromEnd: 4,
    },
  ];

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.values(this.headersJson);

    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(h))
          return (this.COLUMNS_MAP[key] = [h, this.UPPER_ALPHABET[index]]);
      }
    });

    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const currentColumns = this.getAllColumns(operation, index);

    try {
      const isAfter = moment(currentColumns.date).isAfter(
        moment().add(10, 'y'),
      );
      const isBefore = moment(currentColumns.date).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      if (isAfter || isBefore) return {};
    } catch (e) {
      console.log(e);
    }

    const { type, subType, accountFromId, accountToId, sum } =
      this.getTypeAndSum(currentColumns);

    const resultObject = {
      index,
    };
    this.addIfNotFalsy(resultObject, {
      date: moment(this.dateParser(currentColumns.date)).format(
        this.FORMAT2WithTime,
      ),
      accountFromId,
      accountToId,
      counterparty: currentColumns.counterparty,
      comment: currentColumns.comment,
      sum,
      type,
      subType,
    });

    this.setAccountIDsByType(resultObject, this.currency, this.currency);

    return resultObject;
  }

  private getTypeAndSum(currentColumns: AnyObject) {
    if (currentColumns.nominal_debt) {
      return {
        sum: currentColumns.nominal_debt,
        type: OperationType.CONSUMPTION,
        subType: 'supplier',
        accountFromId: this.mainAccount.normalizedLabel,
      };
    } else if (currentColumns.nominal_credit) {
      return {
        sum: currentColumns.nominal_credit,
        type: OperationType.INCOME,
        subType: 'sale',
        accountToId: this.mainAccount.normalizedLabel,
      };
    }

    return {};
  }

  public setRawData(raw, account: any) {
    this.mainAccount = account;
    const wb = xlsx.read(raw, { type: 'buffer', cellDates: true });
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const allSheet = xlsx.utils.sheet_to_json(sheet, { header: 'A' });
    this.setStatementOptions(allSheet);
  }

  protected setStatementOptions(sheet: AnyObject[]) {
    const { posiblyHeader: header, statementOptions } =
      this.determineStatementType(sheet);
    if (!statementOptions) this.throwError('Unsupported statement type', 0);

    const {
      columnsMap,
      currency,
      name,
      dateFormat,
      skipAfterHeader,
      skipFromEnd,
    } = statementOptions;

    this.COLUMNS_MAP = columnsMap;
    this.headersJson = header;

    if (currency) {
      this.currency = currency;
    } else {
      const { collumn, rawIndex } = statementOptions.currencyIdentifier;
      this.currency = sheet[rawIndex][collumn];
    }

    const headerIndex = statementOptions.typeIdentifier.rawIndex;
    if (skipAfterHeader && skipFromEnd)
      this.sheetJson = sheet.slice(
        headerIndex + skipAfterHeader,
        sheet.length - skipFromEnd,
      );

    this.statementName = name;
    this.dateFormat = dateFormat;
  }

  protected determineStatementType(sheet: AnyObject[]): any {
    for (const statementOptions of this.statementsOptions) {
      const identifier = statementOptions.typeIdentifier;

      if (identifier.headersIdentifier) {
        const posiblyHeader = sheet[identifier.rawIndex];
        if (
          JSON.stringify(posiblyHeader) ===
          JSON.stringify(identifier.headersIdentifier)
        )
          return { posiblyHeader, statementOptions };
      }
    }

    return undefined;
  }
}
