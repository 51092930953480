import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AlfaBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Наш рахунок' }]],
          proceedCaseConfig: {
            fields: {
              currency: { column: ['Валюта', 12] },
              credit: {
                column: ['Сума', 11],
                if: { eq: [{ column: ['Операція', 2] }, 'Кредит'] },
              },
              debit: {
                column: ['Сума', 11],
                if: { neq: [{ column: ['Операція', 2] }, 'Кредит'] },
              },
              comment: { column: ['Призначення платежу', 8] },
              counterparty: { column: ['Найменування контрагента', 6] },
              dateAndTime: {
                column: ['Дата проведення', 9],
                add: [' ', { column: ['Час проведення', 13] }],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          proceedCaseConfig: {
            delete: [
              {
                to: [[0, { eq: 'Дата операции' }]],
                count: 1,
              },
            ],
            fields: {
              currency: { column: ['Валюта', 5] },
              date: { column: ['Дата операции', 0] },
              counterparty: { column: ['Корреспондент', 10] },
              debit: { column: ['Дебет', 13] },
              credit: { column: ['Кредит', 14] },
              comment: { column: ['Назначение платежа', 15] },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ';' },
          proceedCaseConfig: {
            fields: {
              currency: { column: ['Валюта', 5] },
              date: { column: ['Дата операции', 0] },
              counterparty: { column: ['Корреспондент', 10] },
              debit: { column: ['Дебет', 13] },
              credit: { column: ['Кредит', 14] },
              comment: { column: ['Назначение платежа', 15] },
            },
          },
        },
      },
    ],
  };
}
