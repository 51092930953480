import { aquaHaze, mystic, persianGreen } from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
  },
  margin8: {
    marginBottom: 8,
  },
  copyField: {
    borderRadius: 12,
    padding: '18px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: aquaHaze,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: mystic,
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  margin20: {
    marginRight: 20,
  },
  margin35: {
    marginBottom: 35,
  },
  link: {
    fontSize: 16,
    lineHeight: '22px',
    color: persianGreen,
  },
  generate: {
    height: 56,
    boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.08)',
    cursor: 'pointer',
    '&:hover': {
      background: aquaHaze,
      borderRadius: 8,
    },
  },
  tooltipRoot: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '0 20px !important',
    opacity: '1 !important',
    fontSize: 13,
    lineHeight: '20px',
    height: 45,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
