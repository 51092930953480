import moment from 'moment';

import { BasePdfParser } from '@finmap/import-parsers/base-pdf-parser';
import {
  AnyObject,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class CentercreditPdfParser extends BasePdfParser {
  importName = 'Centercredit';

  protected currencyCode;

  // public setRawData(raw: any, account: any): void {
  //     super.setRawData(example, account);
  // }

  protected doBeforeTranform() {
    const pagesData = [];
    this.sheetJson.forEach((page) => {
      Object.keys(page).forEach((y) => {
        if (y !== '50.813') pagesData.push(page[y]);
      });
    });
    let operationLines = [];
    for (let i = 0; i < pagesData.length; i++) {
      const line = pagesData[i];
      const resLength = operationLines.length;
      if (!line[0] || !line[0].text) continue;
      if (line[0].text.includes('Жөнелт')) {
        operationLines.push({ personsField: i });
      } else if (resLength) {
        if (line[0].text.includes('Банк к'))
          operationLines[resLength - 1].counterpartyBank = i;
        else if (
          line[0].text.includes('К') &&
          line[1] &&
          line[1].text.includes('орре') &&
          line[2] &&
          line[2].text.includes('сп. а')
        )
          operationLines[resLength - 1].counterpartyUser = i;
        else if (line[0].text.includes('Төлем баст'))
          operationLines[resLength - 1].payer = i;
        else if (line[0].text.includes('Төлем м'))
          operationLines[resLength - 1].commentCoordinate = i;
        else if (
          line[0].text.includes('Банк') &&
          line[1] &&
          line[1].text.includes('тің е')
        )
          operationLines[resLength - 1].bankRate = i;
        else if (line[0].text.includes('Нарықтық бағамы(Қайт'))
          operationLines[resLength - 1].rate = i;
      }
    }
    const filteredLines = operationLines.filter(
      (obj) => Object.keys(obj).length === 7,
    );
    const coordinates = {
      debit: [28.0, 30.0],
      credit: [31.0, 100],
      currency: [22.0, 23.0],
      date: [24.0, 27.0],
      counterparty: [12.0, 100],
      comment: [10, 100],
    };
    const result = [];
    for (const coordinatesFiels of filteredLines) {
      const {
        personsField,
        counterpartyBank,
        payer,
        commentCoordinate,
        counterpartyUser,
        bankRate,
        rate,
      } = coordinatesFiels;
      const debit = this.coordinatesToFields(
        pagesData,
        personsField - 1,
        coordinates.debit,
      );
      const credit = this.coordinatesToFields(
        pagesData,
        personsField - 1,
        coordinates.credit,
      );
      const currency = this.coordinatesToFields(
        pagesData,
        personsField - 1,
        coordinates.currency,
      );
      const date = this.coordinatesToFields(
        pagesData,
        personsField - 1,
        coordinates.date,
      );
      const counterparty = this.coordinatesToFields(
        pagesData,
        counterpartyUser,
        coordinates.counterparty,
      );
      let comment = this.coordinatesToFields(
        pagesData,
        commentCoordinate,
        coordinates.comment,
      );
      for (let i = 1; i < bankRate - commentCoordinate; i++) {
        const text = pagesData[commentCoordinate + i].reduce((acc, cur) => {
          acc += cur.text;
          return acc;
        }, []);
        comment += ' ' + text;
      }

      result.push({
        debit,
        credit,
        currency,
        date,
        counterparty,
        comment,
      });
    }
    return result;
  }

  private coordinatesToFields(allCoordinates, index, searchCoordinates) {
    return allCoordinates[index]
      .filter(({ x }) => x > searchCoordinates[0] && x < searchCoordinates[1])
      .reduce((acc, cur) => {
        acc += cur.text;
        return acc;
      }, '');
  }

  protected DATE_FORMAT = 'DD.MM.YYYY';

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const { debit, credit, currency, date, counterparty, comment } = operation;
    const errors = this.getErrors();
    const correctDate = this.dateParser(date);
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(correctDate, this.DATE_FORMAT).isAfter(
        moment().add(10, 'y'),
      );
      isBefore = moment(correctDate, this.DATE_FORMAT).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const debitNumber = Number((debit as any)?.replaceAll(' ', ''));
    const creditNumber = Number((credit as any)?.replaceAll(' ', ''));
    const sum = debitNumber || creditNumber;
    if (isNaN(sum))
      this.throwError(
        `${debit?.trim() || credit?.trim()} - ${errors.sumNotValid}`,
        index,
      );
    const now = moment();
    const dateResult = moment(correctDate, this.DATE_FORMAT)
      .startOf('day')
      .add(now.seconds(), 'second')
      .add(now.minutes(), 'minute')
      .add(now.hours(), 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    const cur = CURRENCIES.find((cur) => cur.code === currency)?.code;

    const result: any = {
      index,
      date: dateResult,
      comment,
      sum,
      counterparty,
    };
    if (cur) result.currency = cur;

    if (debitNumber) {
      result.type = OperationType.CONSUMPTION;
      result.subType = 'supplier';
      result.accountFromId = this.mainAccount.normalizedLabel;
    } else if (creditNumber) {
      result.type = OperationType.INCOME;
      result.subType = 'sale';
      result.accountToId = this.mainAccount.normalizedLabel;
    }
    this.setAccountIDsByType(result, cur, cur);
    return result;
  }
}
