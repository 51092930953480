import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  description: {
    opacity: 0.5,
    marginBottom: 24,
  },
  button: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
});
