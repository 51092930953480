import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../store/operations/selectors';
import { maxOperationDate, minOperationDate } from '../../../utils/dateFormat';
import CustomPeriod from '../Components/CustomPeriod';
import { CustomPeriodEnum } from '../HOC/types';

type Props = {
  isNewState: boolean | undefined;
  incomeDate: number;
  isEdit: boolean | undefined;
  isCompare: boolean | undefined;
  difference: boolean | undefined;
  prefix?: string;

  onChangePeriodId(id: CustomPeriodEnum): void;
  onChangeDateOfPaymentPeriod(
    startTimestamp: number,
    endTimestamp: number,
  ): void;
};

function TaxPeriodComponent(props: Props) {
  const {
    isEdit,
    prefix,
    isCompare,
    incomeDate,
    isNewState,
    difference,
    onChangePeriodId,
    onChangeDateOfPaymentPeriod,
  } = props;

  const opProps = useSelector(getOperationProps);
  const nextOperationProps = useSelector(getHistoryOperationProps);

  const operationProps = isNewState ? nextOperationProps : opProps;

  const { taxPeriodId, endTimestamp, startTimestamp } = operationProps;

  const handleChangeTaxPeriodId = useCallback(
    (id: CustomPeriodEnum) => {
      onChangePeriodId(id);
    },
    [onChangePeriodId],
  );

  const handleChangeDateOfPaymentPeriod = useCallback(
    (data: number[]) => {
      onChangeDateOfPaymentPeriod(data[0], data[1]);
    },
    [onChangeDateOfPaymentPeriod],
  );

  useEffect(() => {
    if (!taxPeriodId && !isEdit) {
      onChangePeriodId(CustomPeriodEnum.prevMonth);
    }
  }, [onChangePeriodId, taxPeriodId, isEdit]);

  return (
    <CustomPeriod
      startTimestamp={startTimestamp}
      endTimestamp={endTimestamp}
      isEdit={isEdit}
      isCompare={!!isCompare}
      difference={!!difference}
      prefix={prefix}
      periodId={taxPeriodId}
      onChangePeriodId={handleChangeTaxPeriodId}
      date={incomeDate}
      onChange={handleChangeDateOfPaymentPeriod}
      minDate={minOperationDate()}
      maxDate={maxOperationDate(incomeDate)}
    />
  );
}

export default React.memo(TaxPeriodComponent);
