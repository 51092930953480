import { OPERATION_TYPES } from '@finmap/core-constants';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import { isNotEmpty } from 'class-validator';
import moment from 'moment';

export class SenseBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата проведення', 9]),
            sum: this.getFirstValidCellByColumn(['Сума', 11]),
            currency: this.getFirstValidCellByColumn(['Валюта', 12]),
            type:
              this.getFirstValidCellByColumn(['Операція', 2]) === 'Дебет'
                ? OPERATION_TYPES.CON
                : OPERATION_TYPES.INC,
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 8]),
            counterparty: this.getFirstValidCellByColumn([
              'Найменування контрагента',
              6,
            ]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes('Виписка за рахунком'),
            ),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1).filter((el) => el[0]));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата і час', 0]),
            comment: this.getFirstValidCellByColumn(['Деталі', 3]),
            debit: this.getFirstValidCellByColumn(['списання', 6])?.split(
              '\n',
            )[0],
            credit: this.getFirstValidCellByColumn(['зарахування', 7])?.split(
              '\n',
            )[0],
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата і час') &&
                  etc?.nextWord?.includes('Статус'),
                (word, etc) =>
                  word?.includes('зарахування') &&
                  etc?.prevWord?.includes('списання'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('зарахування') &&
                  etc?.prevWord?.includes('списання'),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('ВСЬОГО'),
                (word, etc) =>
                  word?.includes('зарахування') &&
                  etc?.prevWord?.includes('списання'),
              );
              self.deleteFromTo(
                (word) => word?.startsWith('ВСЬОГО'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d+)$/.test(value), // -75 000,00
              ]);
            },
          },
        },
      },
      // case 4
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              value.str.includes('Виписка по особовому рахунку'),
            ),
          );
        },
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          let started = false;
          let dateCount = 0;
          importDocument.slice(1).forEach((el, i) => {
            const isDate = moment(el[1], 'DD.MM.YYYY', true).isValid();
            let start = false;
            if (!started && isDate) {
              started = true;
              start = true;
            }
            if (!started) return;
            if (isDate && (dateCount === 2 || start)) {
              dateCount = 1;
              body.push(el);
            } else {
              if (isDate) dateCount++;
              const lastIndex = body.length - 1;
              if (el[0]) body[lastIndex][0] += `\n${el[0]}`;
              if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
              if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
              if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
              if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
              if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
              if (el[6]) body[lastIndex][6] += `\n${el[6]}`;
            }
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn([
              'Дата\nскладання\nдок-та\nДата\nвідображення\nв балансі',
              1,
            ]).split('\n')[1],
            comment: this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ', 6]),
            debit: this.getFirstValidCellByColumn(['СУМА ДЕБЕТ\nСписано', 4]),
            credit: this.getFirstValidCellByColumn([
              'СУМА КРЕДИТ\nЗараховано',
              5,
            ]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => word === 'Дата' && etc?.nextWord === 'складання',
                (word, etc) =>
                  word === 'в балансі' && etc?.prevWord === 'Ідент.код',
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word === 'Дата' && etc?.nextWord?.includes('складання'),
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word === 'Пiдсумок оборотiв' &&
                  !isNaN(Number(etc?.nextWord?.replaceAll(',', '.'))),
                undefined,
                1,
              );
              self.defineOperation([(value) => true]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            debit: this.getFirstValidCellByColumn(['Дебет', 1]),
            credit: this.getFirstValidCellByColumn(['Кредит', 2]),
            counterparty: this.getFirstValidCellByColumn(['Кореспондент', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 4]),
            date: this.getFirstValidCellByColumn(['Дата проведення', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Номер') && etc?.nextWord?.includes('Дебет'),
                (word, etc) =>
                  word?.includes('Дата проведення') &&
                  /^(\d+)$/.test(etc?.nextWord),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Дата проведення') &&
                  /^(\d+)$/.test(etc?.nextWord),
                1,
              );
              self.deleteFromTo(
                (word) => word?.startsWith('Обороти:'),
                undefined,
              );
              self.defineOperation([
                (value) => /^([\d]+.\d+)$/.test(value), // '16900.00'
              ]);
            },
          },
        },
      },
    ],
  };
}
