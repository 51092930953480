import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../../theme/colors';

export const useStyles = makeStyles({
  row: {
    marginBottom: 24,
  },
  button: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  link: {
    cursor: 'pointer',
    fontSize: 12,
    lineHeight: '20px',
    color: persianGreen,
    width: 'fit-content',
    borderBottom: `1px solid ${persianGreen}`,
    borderBottomStyle: 'dashed',
  },
  marginBottom8: {
    marginBottom: 8,
  },
});
