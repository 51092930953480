import moment from 'moment';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class VostokBankXlsParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    date: [['Дата операції'], null],
    debit: [['Дебет'], null],
    credit: [['Кредит'], null],
    currency: [['Валюта'], null],
    counterparty: [['Кореспондент'], null],
    comment: [['Призначення платежу'], null],
  };

  private mainAccount: Account;

  protected importName = 'VostokBank';

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.entries(this.headersJson);
    HEADERS.forEach(([hKey, hValue], index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(hValue))
          return (this.COLUMNS_MAP[key] = [hValue, hKey]);
      }
    });
    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const allColumns = this.getAllColumns(operation, index);
    const { date, debit, credit, currency, counterparty, comment } = allColumns;

    const correctDate = this.dateParser(date);
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);
    const now = moment();
    const dateResult = moment(correctDate)
      .startOf('day')
      .add(now.seconds(), 'second')
      .add(now.minutes(), 'minute')
      .add(now.hours(), 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    let isAfter, isBefore;
    try {
      isAfter = moment(dateResult).isAfter(moment().add(10, 'y'));
      isBefore = moment(dateResult).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const parsedDebit = this.parseSum(debit);
    const parsedCredit = this.parseSum(credit);
    const parsedSum =
      parsedDebit > parsedCredit || isNaN(parsedCredit)
        ? parsedDebit
        : parsedCredit;

    if (isNaN(parsedSum))
      this.throwError(
        `${parsedDebit > parsedCredit ? debit : credit} - ${
          errors.sumNotValid
        }`,
        index,
      );

    const resO: AnyObject = {
      index,
      date: moment(dateResult).format(this.FORMAT2WithTime),
      sum: parsedSum,
    };

    this.addIfNotFalsy(resO, {
      counterparty,
    });

    if (parsedDebit > parsedCredit || isNaN(parsedCredit)) {
      resO.type = OperationType.CONSUMPTION;
      resO.subType = 'supplier';
      resO.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      resO.type = OperationType.INCOME;
      resO.subType = 'sale';
      resO.accountToId = this.mainAccount.normalizedLabel;
    }

    const cur = CURRENCIES.find(
      (cur) =>
        cur.code === currency.toUpperCase() ||
        cur.native === currency.toUpperCase(),
    )?.code;

    resO.currency = cur;

    super.setAccountIDsByType(resO, cur, cur);

    this.setComments(resO, [comment]);

    return resO;
  }

  public setRawData(raw, account) {
    this.mainAccount = account;
    super.setRawData(raw, account);
  }
}
