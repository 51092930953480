import { Typography } from '@material-ui/core';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EditIcon from '../../../../../assets/images/svg/edit.svg';
import QuestionIcon from '../../../../../assets/images/svg/question.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import companyActions from '../../../../../store/company/actions';
import {
  getWebHooks,
  selectCurrentCompany,
} from '../../../../../store/company/selectors';
import { Webhook } from '../../../../../store/company/types';
import { isLite } from '../../../../../utils/subscriptions';
import SubscriptionDialog from '../../../../Subscriptions/SubscriptionChooseDialog';
import CreateWebHookDialog from './CreateWebhook';
import EditWebHookDialog from './InfoWebhook';
import { useStyles } from './styles';

export type Props = {
  showInDialog?: boolean;

  onClose?(): void;
};

function WebHookDialog(props: Props) {
  const { onClose, showInDialog = true } = props;
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [currentWebHook, setCurrentWebHook] = useState<Webhook | null>(null);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const webHooks = useSelector(getWebHooks);
  const company = useSelector(selectCurrentCompany);

  const handleCloseSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(false);
  }, []);

  const handleOpenEditWebHookDialog = useCallback(
    (event: SyntheticEvent) => {
      const { id } = event.currentTarget;
      const webHook = webHooks.find((el) => el._id === id);

      if (webHook) {
        setCurrentWebHook(webHook);
      }

      setShowEditDialog(true);
    },
    [webHooks],
  );

  const handleCloseEditWebHookDialog = useCallback(() => {
    setShowEditDialog(false);
  }, []);

  const handleOpenCreateWebHookDialog = useCallback(() => {
    if (isLite(company)) {
      setShowSubscriptionDialog(true);

      return;
    }

    setShowCreateDialog(true);
  }, [company]);

  const handleCloseCreateWebHookDialog = useCallback(() => {
    setShowCreateDialog(false);
  }, []);

  const Component = useCallback(
    () => (
      <div className={classes.withoutDialogContainer}>
        <Typography className={classes.title}>
          {t('settingsPage.webhooksTitle')}
        </Typography>
        <CustomButton
          action={handleOpenCreateWebHookDialog}
          title={t('webhook.add')}
          className={classes.button}
        />
        <>
          {webHooks.map((el) => (
            <div
              id={el._id}
              key={el._id}
              className={classes.row}
              onClick={handleOpenEditWebHookDialog}
            >
              <div className={classes.rowWrapper}>
                <Typography>{el.name}</Typography>
                <img alt="" src={EditIcon} />
              </div>
            </div>
          ))}
        </>
        <div className={classes.rowInfo}>
          <a
            className={classes.rowInfoWrapper}
            href="https://api.finmap.online"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.questionIcon}
              src={QuestionIcon}
              alt="question"
            />
            <Typography className={classes.text}>
              {t('webhook.docTitle')}
            </Typography>
          </a>
        </div>
      </div>
    ),
    [
      classes,
      handleOpenCreateWebHookDialog,
      handleOpenEditWebHookDialog,
      t,
      webHooks,
    ],
  );

  useEffect(() => {
    dispatch(companyActions.getWebHooks());
  }, [dispatch]);

  return (
    <>
      {showInDialog && (
        <Dialog title={t('webhook.title')} onClose={onClose} isOpened>
          <Component />
        </Dialog>
      )}
      {!showInDialog && <Component />}
      {showEditDialog && (
        <EditWebHookDialog
          webhook={currentWebHook}
          onClose={handleCloseEditWebHookDialog}
        />
      )}
      {showCreateDialog && (
        <CreateWebHookDialog onClose={handleCloseCreateWebHookDialog} />
      )}
      {showSubscriptionDialog && (
        <SubscriptionDialog
          title={t('subscription.warnings.getWebhooksAccess')}
          onClose={handleCloseSubscriptionDialog}
        />
      )}
    </>
  );
}

export default React.memo(WebHookDialog);
