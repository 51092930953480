import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import { Dialog, Input, Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import i18next from 'i18next';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  DayPickerRangeController,
  DayPickerSingleDateController,
  FocusedInputShape,
} from 'react-dates';
import { useTranslation } from 'react-i18next';

import ArrowDown from '../../../../../assets/images/svg/dropdown-black.svg';
import ArrowUp from '../../../../../assets/images/svg/dropup-black.svg';
import TimeIcon from '../../../../../assets/images/svg/time.svg';
import i18n from '../../../../../i18n';
import { getTimeOffset } from '../../../../../utils/dateToUTC';
import isInclusivelyAfterDay from '../../../../../utils/dateUtils/isInclusivelyAfterDay';
import isInclusivelyBeforeDay from '../../../../../utils/dateUtils/isInclusivelyBeforeDay';
import dateUtils from '../utils';
import RenderMonthElement from './RenderMonthElement';
import { RenderMonthElementProps } from './RenderMonthElement.types';
import { useStyles } from './styles';
import { Props } from './types';

const defaultMaxDate = moment.utc('2041-01-01');
const defaultMinDate = moment.utc('1979-01-01');

function initialSelectedRange(value?: (number | undefined)[]) {
  if (!value) {
    return {
      start: null,
      end: null,
    };
  }

  const [start, end] = value;

  return {
    start: start ? moment.utc(start) : null,
    end: end ? moment.utc(end) : null,
  };
}

let overrideLocale = i18n.language;

if (i18n.language === 'cz') {
  overrideLocale = 'cs';
} else if (i18n.language === 'uz') {
  overrideLocale = 'uz-latn';
}

function CalendarAirbnbVersion(props: Props) {
  const {
    value,
    airbnbValue,
    isSelectRange,
    onChange,
    maxDate,
    minDate,
    onClose,
    nestedDialog,
  } = props;
  const { t } = useTranslation();

  const [locale, setLocale] = useState(overrideLocale);
  const [showTimeView, setShowTimeView] = useState(false);
  const [timeHours, setTimeHours] = useState('');
  const [timeMinutes, setTimeMinutes] = useState('');
  const [selectedDate, setSelectedDate] = useState(
    !value || Array.isArray(value) ? null : value,
  );
  const [selectedStartRange, setSelectedStartRange] = useState(
    initialSelectedRange(airbnbValue).start,
  );
  const [selectedEndRange, setSelectedEndRange] = useState(
    initialSelectedRange(airbnbValue).end,
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    'startDate',
  );

  moment.locale(locale);

  const classes = useStyles();

  const smallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(760),
  );

  const handleClose = useCallback(() => {
    if (!nestedDialog) {
      onClose();
    }
  }, [onClose, nestedDialog]);

  const handleCloseDialog = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onClose();
    },
    [onClose],
  );

  const handleSwitchTimeView = useCallback(() => {
    setShowTimeView(!showTimeView);
  }, [showTimeView]);

  const handleSave = useCallback(
    (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
      if (!startDate || !endDate) {
        return;
      }

      if (startDate.valueOf() === endDate.valueOf()) {
        onChange([
          moment.utc(startDate).startOf('day').valueOf(),
          moment.utc(endDate).endOf('day').valueOf(),
        ]);
      } else if (startDate.valueOf() < endDate.valueOf()) {
        onChange([
          moment.utc(startDate).startOf('day').valueOf(),
          moment.utc(endDate).endOf('day').valueOf(),
        ]);
      }

      handleClose();
    },
    [handleClose, onChange],
  );

  const handleChange = useCallback(
    (date: moment.Moment | null) => {
      if (!date) {
        return;
      }

      const val = dateUtils.getNewSelectedDate(date, timeHours, timeMinutes);

      onChange(moment.utc(val).valueOf());

      handleClose();
    },
    [onChange, handleClose, timeHours, timeMinutes],
  );

  const onUpdateByHours = useCallback(
    (newValue: number) => {
      if (newValue >= 0 && newValue < 24) {
        const amount = newValue < 10 ? `0${newValue}` : newValue.toString();
        setTimeHours(amount);

        if (selectedDate) {
          const val = dateUtils.getNewSelectedDate(
            moment.utc(selectedDate),
            amount,
            timeMinutes,
          );

          setSelectedDate(new Date(val));
        }
      }
    },
    [selectedDate, timeMinutes],
  );

  const onUpdateByMinutes = useCallback(
    (newValue: number) => {
      if (newValue >= 0 && newValue < 60) {
        const amount = newValue < 10 ? `0${newValue}` : newValue.toString();
        setTimeMinutes(amount);

        if (selectedDate) {
          const val = dateUtils.getNewSelectedDate(
            moment.utc(selectedDate),
            timeHours,
            amount,
          );

          setSelectedDate(new Date(val));
        }
      }
    },
    [selectedDate, timeHours],
  );

  const handleChangeHours = useCallback(
    (event: any) => {
      const newValue = Number(event.target.value);

      if (!isNaN(newValue)) {
        onUpdateByHours(newValue);
      } else if (!event.target.value) {
        setTimeHours('');
      }
    },
    [onUpdateByHours],
  );

  const handleChangeMinutes = useCallback(
    (event: any) => {
      const newValue = Number(event.target.value);

      if (!isNaN(newValue)) {
        onUpdateByMinutes(newValue);
      } else if (!event.target.value) {
        setTimeMinutes('');
      }
    },
    [onUpdateByMinutes],
  );

  const handleIncreaseHours = useCallback(() => {
    if (timeHours) {
      onUpdateByHours(Number(timeHours) + 1);
    }
  }, [onUpdateByHours, timeHours]);

  const handleDecreaseHours = useCallback(() => {
    if (timeHours) {
      onUpdateByHours(Number(timeHours) - 1);
    }
  }, [onUpdateByHours, timeHours]);

  const handleIncreaseMinutes = useCallback(() => {
    if (timeMinutes) {
      onUpdateByMinutes(Number(timeMinutes) + 1);
    }
  }, [onUpdateByMinutes, timeMinutes]);

  const handleDecreaseMinutes = useCallback(() => {
    if (timeMinutes) {
      onUpdateByMinutes(Number(timeMinutes) - 1);
    }
  }, [onUpdateByMinutes, timeMinutes]);

  const handleFocusChange = useCallback((arg: FocusedInputShape | null) => {
    setFocusedInput(!arg ? 'startDate' : arg);
  }, []);

  const handleChangeRange = useCallback(
    ({
      startDate,
      endDate,
    }: {
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    }) => {
      if (focusedInput === 'startDate') {
        setSelectedEndRange(null);

        if (startDate) {
          setSelectedStartRange(startDate);
        }
      } else if (endDate) {
        setSelectedEndRange(endDate);

        handleSave(startDate, endDate);
      }
    },
    [focusedInput, handleSave],
  );

  const handleSingleFocusChange = useCallback((arg: { focused: boolean }) => {
    console.log(arg);
  }, []);

  const isOutsideRange = useCallback(
    (day: any) => {
      const inclusivelyAfterDay = isInclusivelyAfterDay(
        day,
        moment(maxDate) || defaultMaxDate,
      );
      const inclusivelyBeforeDay = isInclusivelyBeforeDay(
        day,
        moment(minDate) || defaultMinDate,
      );

      return inclusivelyAfterDay || inclusivelyBeforeDay;
    },
    [maxDate, minDate],
  );

  const renderMonthElement = useCallback(
    ({ month, onMonthSelect, onYearSelect }: RenderMonthElementProps) => (
      <RenderMonthElement
        month={month}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    ),
    [],
  );

  useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      setLocale(lng);
    });
  }, []);

  useEffect(() => {
    if (value) {
      // @ts-ignore
      const currentHours = new Date(value).getHours();
      // @ts-ignore
      const currentMinutes = new Date(value).getMinutes();

      if (currentHours >= 0 && currentHours <= 24) {
        if (currentHours >= 0 && currentHours < 10) {
          // @ts-ignore
          setTimeHours(`0${currentHours}`);
        } else {
          // @ts-ignore
          setTimeHours(currentHours);
        }
      }

      if (currentMinutes >= 0 && currentMinutes <= 60) {
        if (currentMinutes >= 0 && currentMinutes < 10) {
          // @ts-ignore
          setTimeMinutes(`0${currentMinutes}`);
        } else {
          // @ts-ignore
          setTimeMinutes(currentMinutes);
        }
      }
    }
  }, [value]);

  return (
    <Dialog
      open
      PaperProps={{
        className: classes.calendarModalContainer,
      }}
      onBackdropClick={handleCloseDialog}
    >
      <div data-at-id="date-calendar" className={classes.calendarContainer}>
        {isSelectRange && (
          <DayPickerRangeController
            navPosition="navPositionTop"
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            initialVisibleMonth={null}
            startDate={selectedStartRange}
            endDate={selectedEndRange}
            maxDate={maxDate ? moment.utc(maxDate) : defaultMaxDate}
            minDate={minDate ? moment.utc(minDate) : defaultMinDate}
            onDatesChange={handleChangeRange}
            numberOfMonths={smallScreen ? 1 : 2}
            hideKeyboardShortcutsPanel
            daysViolatingMinNightsCanBeClicked
            minimumNights={1}
            renderMonthElement={renderMonthElement}
          />
        )}
        {!isSelectRange && (
          <DayPickerSingleDateController
            focused
            navPosition="navPositionTop"
            initialVisibleMonth={null}
            onFocusChange={handleSingleFocusChange}
            date={
              selectedDate
                ? moment.utc(selectedDate.valueOf() + getTimeOffset())
                : null
            }
            onDateChange={handleChange}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            isDayBlocked={() => false}
            isOutsideRange={isOutsideRange}
            renderMonthElement={renderMonthElement}
          />
        )}
        {showTimeView && (
          <div className={classes.fullTimeBlock}>
            <img src={TimeIcon} alt="time" className={classes.timeIcon} />
            <div className={classes.repeatsSmallContainer}>
              <Input
                autoFocus={showTimeView}
                value={timeHours}
                onChange={handleChangeHours}
              />
              <div className={classes.column}>
                <img src={ArrowUp} alt="up" onClick={handleIncreaseHours} />
                <img src={ArrowDown} alt="down" onClick={handleDecreaseHours} />
              </div>
            </div>
            <Typography className={classes.separator}>:</Typography>
            <div className={classes.repeatsSmallContainer}>
              <Input value={timeMinutes} onChange={handleChangeMinutes} />
              <div className={classes.column}>
                <img src={ArrowUp} alt="up" onClick={handleIncreaseMinutes} />
                <img
                  src={ArrowDown}
                  alt="down"
                  onClick={handleDecreaseMinutes}
                />
              </div>
            </div>
          </div>
        )}
        {!showTimeView && !isSelectRange && (
          <div className={classes.timeBlock} onClick={handleSwitchTimeView}>
            <img src={TimeIcon} alt="time" />
            <Typography className={classes.time}>
              {t('operationDialogs.time')}
            </Typography>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default React.memo(CalendarAirbnbVersion);
