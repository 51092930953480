import { Typography } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export interface SocialNetworkContainerProps {
  isActive?: boolean;
  children?: React.ReactNode,
}

export function SocialNetworkContainer({
  isActive = true,
  children = null,
}: SocialNetworkContainerProps) {

  const { t } = useTranslation();
  const classes = useStyles();

  if(!isActive) return null;

  return (
    <>
      <div className={classes.signUpViaSocialNetworkWrapper}>
        <div className={classes.signUpViaSocialNetworkTitle}>
          <Typography>{t('loginPage.registration.signUpViaSocialNetwork.or')}</Typography>
        </div>
      </div>
      <div className={classes.socialNetworkWrapper}>
        {children}
      </div>
    </>
  );
}

export default SocialNetworkContainer;