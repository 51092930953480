import { makeStyles } from '@material-ui/core/styles';

import { catskillWhite, white } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    top: -35,
    background: white,
    zIndex: 10,
  },
  stickyRow: {
    top: -8,
  },
  title: {
    marginBottom: 0,
  },
  button: {
    minWidth: 160,
    marginTop: 30,
    marginBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    cursor: 'pointer',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: `calc(100% - 25px)`,
  },
  rightBlock: {},
  text: {
    fontSize: 15,
    lineHeight: '20px',
  },
  opacity: {
    opacity: 0.5,
  },
  margin16: {
    marginRight: 16,
  },
  dialog: {
    borderRadius: 24,
  },
  shortNames: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    display: 'block',
    maxWidth: '200',
    width: `calc(100% - 13px)`,
  },
});
