import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class INGBankPLNImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          isCurCase: {
            simple: [[0, { in: 'Lista transakcji' }]],
          },
          preParserConfigs: {
            maxInterlineSpacingPx: 40,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: { in: 'Data transakcji' },
                    nextWord: { in: 'Dane kontrahenta' },
                  },
                ],
                to: [{ word: { in: 'transakcji' }, prevWord: { in: 'po' } }],
              },
              define: [{ dateFormat: 'DD.MM.YYYY', hasEOL: false }],
            },
          },
          proceedCaseConfig: {
            fields: {
              sum: {
                or: [
                  {
                    column: ['Kwota', 4],
                    if: {
                      in: [
                        {
                          column: ['Kwota', 4],
                          trim: true,
                          split: { by: { regexp: { str: '\\s' } }, get: 0 },
                        },
                        'blokada:',
                      ],
                    },
                    split: { by: { regexp: { str: '\\s' } }, get: 1 },
                  },
                  {
                    column: ['Kwota', 4],
                    if: {
                      nin: [
                        {
                          column: ['Kwota', 4],
                          trim: true,
                          split: { by: { regexp: { str: '\\s' } }, get: 0 },
                        },
                        'blokada:',
                      ],
                    },
                    split: { by: { regexp: { str: '\\s' } }, get: -2 },
                  },
                ],
              },
              currency: {
                or: [
                  {
                    column: ['Kwota', 4],
                    if: {
                      in: [
                        {
                          column: ['Kwota', 4],
                          trim: true,
                          split: { by: { regexp: { str: '\\s' } }, get: 0 },
                        },
                        'blokada:',
                      ],
                    },
                    split: { by: { regexp: { str: '\\s' } }, get: 2 },
                  },
                  {
                    column: ['Kwota', 4],
                    if: {
                      nin: [
                        {
                          column: ['Kwota', 4],
                          trim: true,
                          split: { by: { regexp: { str: '\\s' } }, get: 0 },
                        },
                        'blokada:',
                      ],
                    },
                    split: { by: { regexp: { str: '\\s' } }, get: -1 },
                  },
                ],
              },
              comment: { column: ['Tytuł', 2] },
              date: {
                column: ['Data transakcji\n/ data księgow.', 0],
                trim: true,
                split: { by: { regexp: { str: '\\s' } }, get: 0 },
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          isCurCase: {
            simple: [[0, { in: 'Wyciąg z rachunku' }]],
          },
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawConfig: {
              saveToStore: {
                value: 'currency',
                from: [
                  {
                    word: { in: 'Waluta rachunku' },
                    nextWord: { regexp: { str: '^(\\s*[A-Z]{3}\\s*)$' } },
                  },
                ],
                to: [
                  {
                    word: { regexp: { str: '^(\\s*[A-Z]{3}\\s*)$' } },
                    prevWord: { in: 'Nazwa rachunku' },
                  },
                ],
              },
              findHeader: {
                from: [{ word: { in: 'Data' }, nextWord: { in: 'Dane' } }],
                to: [{ word: { in: 'Kwota' }, prevWord: { in: 'operacji' } }],
              },
              delete: [
                {
                  from: [
                    {
                      word: { in: 'Wyciąg z rachunku' },
                      nextWord: { startsWith: 'ING Bank Śląski S.A.' },
                    },
                  ],
                  to: [
                    {
                      word: { in: 'Nr transakcji' },
                      prevWord: { in: 'księgowania' },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'Środki pieniężne zgromadzone na rachunku bankowym podlegają ochronie gwarancyjnej do ',
                      },
                    },
                  ],
                },
              ],
              define: [
                { regexp: { str: '^(-?[\\s\\d]+,\\d+)$' } },
                { dateFormat: 'DD.MM.YYYY' },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: { column: ['Data', 0], split: { by: '\n', get: 0 } },
              counterparty: {
                column: ['Dane Kontrahenta', 1],
                split: { by: '\n', get: 1 },
              },
              comment: { column: ['Tytuł operacji', 2] },
              sum: { column: ['Kwota', 4] },
              currency: { fromStorage: 'currency', trim: true },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'one-line',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: { in: 'Data' },
                    prevWord: { regexp: { str: '^(\\s*\\d+\\s*)$' } },
                  },
                ],
                to: [
                  {
                    word: { in: 'Rachunek firmy' },
                    nextWord: { dateFormat: 'DD-MM-YYYY' },
                  },
                ],
              },
              define: [
                { regexp: { str: '^(-?[\\s\\d]+,\\d+ [A-Z]{3})$' } },
                { dateFormat: 'DD-MM-YYYY' },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: { column: ['Data\nksięgowania', 0] },
              counterparty: { column: ['Kontrahent', 1] },
              comment: { column: ['Tytuł operacji\nTyp zlecenia', 2] },
              sum: {
                or: [
                  {
                    column: ['Kwota\nWaluta', 3],
                    if: {
                      isNum: [
                        {
                          column: ['Kwota\nWaluta', 3],
                          split: { by: ' ', get: 0 },
                          replace: [{ from: ',', to: '.' }],
                        },
                        false,
                      ],
                    },
                    split: { by: ' ', get: 1 },
                    replace: [{ from: ',', to: '.' }],
                    add: [
                      {
                        column: ['Kwota\nWaluta', 3],
                        split: { by: ' ', get: 2 },
                        replace: [{ from: ',', to: '.' }],
                      },
                    ],
                  },
                  {
                    column: ['Kwota\nWaluta', 3],
                    if: {
                      isNum: [
                        {
                          column: ['Kwota\nWaluta', 3],
                          split: { by: ' ', get: 1 },
                          replace: [{ from: ',', to: '.' }],
                        },
                        false,
                      ],
                    },
                    split: { by: ' ', get: 0 },
                    replace: [{ from: ',', to: '.' }],
                  },
                  {
                    column: ['Kwota\nWaluta', 3],
                    if: {
                      isNum: [
                        {
                          column: ['Kwota\nWaluta', 3],
                          split: { by: ' ', get: 1 },
                          replace: [{ from: ',', to: '.' }],
                        },
                        true,
                      ],
                    },
                    split: { by: ' ', get: 0 },
                    replace: [{ from: ',', to: '.' }],
                    add: [
                      {
                        column: ['Kwota\nWaluta', 3],
                        split: { by: ' ', get: 1 },
                        replace: [{ from: ',', to: '.' }],
                      },
                    ],
                  },
                ],
              },
              currency: {
                column: ['Kwota\nWaluta', 3],
                split: { by: ' ', get: -1 },
              },
            },
          },
        },
      },
    ],
  };
}
