import AndIcon from '../../../../assets/images/svg/and.svg';
import SaltageLogo from '../../../../assets/images/svg/saltedge.svg';
import CustomButton from "../../../../components/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { useStyles } from "./styles";
import { Props } from './types';
import { Typography } from "@material-ui/core";
import cn from "classnames";
import React from 'react';
import { useTranslation } from "react-i18next";

function SaltedgeInfoDialog(props: Props) {
  const { onClose, onChange, bank, loading } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.saltedgeInfo.title', {
        postProcess: 'sprintf',
        sprintf: [bank.name],
      })}
    >
      <div className={classes.imagesRootContainer}>
        <div className={cn(classes.firstImageContainer, classes.imageContainer)}>
          <img src={SaltageLogo} alt="logo" />
          <img src={AndIcon} alt="logo" className={classes.andIcon} />
        </div>
        <div className={cn(classes.secondImageContainer, classes.imageContainer)}>
          <img
            src={bank.logo_url}
            alt="logo"
            className={classes.icon}
          />
          <Typography className={classes.bankName}>
            {bank.name}
          </Typography>
        </div>
      </div>
      <div className={classes.descriptionContainer}>
        <Typography className={cn(classes.text, classes.separatorText)}>
          {t('bank.saltedgeInfo.description', {
            postProcess: 'sprintf',
            sprintf: [bank.name],
          })}
        </Typography>
        <a
          href="https://www.saltedge.com"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <Typography className={cn(classes.text, classes.linkText)}>
            www.saltedge.com
          </Typography>
        </a>
      </div>
      <Typography className={cn(classes.text, classes.separator)}>
        {t('bank.saltedgeInfo.youGetEmail')}
      </Typography>
      <CustomButton
        fullWidth
        loading={loading}
        title={t('common.ok')}
        action={onChange}
      />
    </Dialog>
  )
}

export default React.memo(SaltedgeInfoDialog);
