import axios, { AxiosError, AxiosInstance } from 'axios';

import { BASE_URL, Forbidden, PaymentRequired, UI_URL } from '../constants';
import i18n from '../i18n';
import { LOGOUT_USER } from '../store/auth/actions';
import store from '../store/store';
import Storages, { StorageKey } from './Storages';

class Http {
  instance: AxiosInstance;

  constructor(isUI = false) {
    this.instance = axios.create({
      baseURL: isUI ? UI_URL : BASE_URL,
    });
  }

  handleError = (err: AxiosError) => {
    if (err) {
      if (err.response) {
        const { status } = err.response;

        if (status === PaymentRequired || status === Forbidden) {
          const language = Storages.get(StorageKey.language);

          store.dispatch({ type: LOGOUT_USER });
          window.location.href = `/auth/login${language}`;
          return;
        }

        throw err.response && err.response.data;
      } else {
        throw err;
      }
    }
  };

  setAuthHeader() {
    const { user } = store.getState().auth;

    // @ts-ignore
    if (user && user.token) {
      const { headers } = this.instance.defaults;
      // @ts-ignore
      headers.common.Authorization = `Bearer ${user.token}`;
    }
  }

  setLanguageHeader() {
    let { language } = i18n;

    if (language === 'uk') {
      language = 'ua';
    }

    const { headers } = this.instance.defaults;
    headers.common['Accept-Language'] = language;
  }

  makeInstance() {
    this.setAuthHeader();
    this.setLanguageHeader();

    return this.instance;
  }
}
export { Http };
export default new Http();
