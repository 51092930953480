import moment from 'moment';

import { OPERATION_TYPES } from '@finmap/core-constants';
import {
  isEmptyImportField,
  normalize,
  parseCurrency,
  parseDate,
  parseSum,
} from '@finmap/core-utils';

import {
  DATE_AND_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from './base-import-parser-v3.const';
import {
  AccountObject,
  AccountsOrAccount,
  CaseOptions,
  JustPropertyKeys,
} from './base-import-parser-v3.dto';
import { ImportOperation } from './import-operation';

export class ImportOperationMask implements Partial<ImportOperation> {
  project?: string = this.validateAndPrepareString as undefined;
  counterparty?: string = this.validateAndPrepareString as undefined;
  comment?: string = this.validateAndPrepareString as undefined;
  sum?: number = this.validateAndPrepareSum as undefined;
  type?: OPERATION_TYPES = this.validateAndPrepareString as undefined;
  subType?: string = this.validateAndPrepareString as undefined;
  category?: string = this.validateAndPrepareString as undefined;
  subCategory?: string = this.validateAndPrepareString as undefined;
  accountName?: string = this.validateAndPrepareString as undefined;
  accountToName?: string = this.validateAndPrepareString as undefined;
  accountFromName?: string = this.validateAndPrepareString as undefined;
  currency?: string = this.validateAndPrepareCurrency as undefined;
  debit?: number = this.validateAndPrepareSum as undefined;
  credit?: number = this.validateAndPrepareSum as undefined;
  time?: string = this.validateAndPrepareTime as undefined;
  date?: string = this.validateAndPrepareDate as undefined;
  dateAndTime?: string = this.validateAndPrepareDateAndTime as undefined;
  timeOfPayment?: string = this.validateAndPrepareTime as undefined;
  dateOfPayment?: string = this.validateAndPrepareDate as undefined;
  dateAndTimeOfPayment?: string = this
    .validateAndPrepareDateAndTime as undefined;
  counterpartyIncome?: string = this.validateAndPrepareString as undefined;
  counterpartyConsumption?: string = this.validateAndPrepareString as undefined;
  index?: number = ((v) => v) as undefined;
  accountFromId?: string = this.validateAndPrepareString as undefined;
  accountToId?: string = this.validateAndPrepareString as undefined;
  accountFromObject?: AccountObject = ((v) => v) as undefined;
  accountToObject?: AccountObject = ((v) => v) as undefined;
  // skipOperation?: boolean;

  constructor(operationMask) {
    for (const key of Object.keys(this)) {
      const value = this[key](operationMask[key]);
      if (!isEmptyImportField(value)) this[key] = value;
      else delete this[key];
    }
  }

  protected validateAndPrepareString(valuePretender: unknown): string {
    return valuePretender?.toString();
  }

  protected validateAndPrepareDate(valuePretender: unknown): string {
    return moment(parseDate(valuePretender)).format(DATE_FORMAT);
  }

  protected validateAndPrepareTime(valuePretender: unknown): string {
    return moment(parseDate(valuePretender)).format(TIME_FORMAT);
  }

  protected validateAndPrepareDateAndTime(valuePretender: unknown): string {
    return moment(parseDate(valuePretender)).format(DATE_AND_TIME_FORMAT);
  }

  protected validateAndPrepareSum(valuePretender: unknown): number {
    return parseSum(valuePretender);
  }

  protected validateAndPrepareCurrency(valuePretender: unknown): string {
    return parseCurrency(valuePretender);
  }

  prepareOperation(
    accounts: AccountsOrAccount,
    options: CaseOptions,
    START_DATE: Date,
  ) {
    this.prepareDateAndTime(START_DATE);
    this.prepareSums();
    this.prepareType();
    this.prepareSubType();
    this.prepareCounterparties();
    this.prepareCurrency(options);
    this.prepareAccounts(accounts);
    this.prepareCategories();
    this.prepareSumsFinal();
    this.prepareDateAndTimeFinal();
    return this;
  }

  private prepareDateAndTime(START_DATE: Date) {
    if (!this.dateAndTime && (this.date || this.time)) {
      if (this.date) this.dateAndTime = this.date;
      else this.dateAndTime = moment(START_DATE).format(DATE_FORMAT);
      if (this.time) this.dateAndTime += ` ${this.time}`;
      else this.dateAndTime += ` ${moment(START_DATE).format(TIME_FORMAT)}`;
    }
    if (
      !this.dateAndTimeOfPayment &&
      (this.dateOfPayment || this.timeOfPayment)
    ) {
      if (this.dateOfPayment) this.dateAndTimeOfPayment = this.dateOfPayment;
      else this.dateAndTimeOfPayment = moment(START_DATE).format(DATE_FORMAT);
      if (this.timeOfPayment)
        this.dateAndTimeOfPayment += ` ${this.timeOfPayment}`;
      else
        this.dateAndTimeOfPayment += ` ${moment(START_DATE).format(
          TIME_FORMAT,
        )}`;
    }
    if (!this.dateAndTime && this.dateAndTimeOfPayment)
      this.dateAndTime = this.dateAndTimeOfPayment;
  }

  private prepareSums() {
    if (!this.sum && (this.credit || this.debit)) {
      const biggerSum =
        (Math.abs(this.credit) || 0) > (Math.abs(this.debit) || 0)
          ? this.credit
          : this.debit;
      this.sum = biggerSum;
      if (!this.type)
        this.type =
          (Math.abs(this.credit) || 0) > (Math.abs(this.debit) || 0)
            ? OPERATION_TYPES.INC
            : OPERATION_TYPES.CON;
    }
  }

  private prepareType() {
    if (!this.type)
      this.type =
        this.sum?.toString().indexOf('-') === -1
          ? OPERATION_TYPES.INC
          : OPERATION_TYPES.CON;
  }

  private prepareSubType() {
    if (!this.subType) {
      if (this.type === OPERATION_TYPES.INC) this.subType = 'sale';
      if (this.type === OPERATION_TYPES.CON) this.subType = 'supplier';
    }
  }

  private prepareCounterparties() {
    if (!this.counterparty) {
      if (this.type === OPERATION_TYPES.INC && this.counterpartyIncome)
        this.counterparty = this.counterpartyIncome;
      if (this.type === OPERATION_TYPES.CON && this.counterpartyConsumption)
        this.counterparty = this.counterpartyConsumption;
    }
  }

  private prepareCurrency(options: CaseOptions) {
    if (!this.currency && options.defaultCurrency)
      this.currency = options.defaultCurrency;
  }

  private prepareAccounts(accounts: AccountsOrAccount) {
    if (this.accountName && !this.accountToName && !this.accountFromName) {
      this.accountToName = this.accountName;
      this.accountFromName = this.accountName;
    }
    if (Array.isArray(accounts)) {
      if (
        (this.type === OPERATION_TYPES.INC ||
          this.type === OPERATION_TYPES.TRN) &&
        this.accountToName
      ) {
        let accountToWithSuchNameExists = false;
        const neededAccountTo = accounts.find((account) => {
          const isSameName =
            account.normalizedLabel === normalize(this.accountToName);
          const isSameCurrency =
            (account as any)?.currency?.code === this.currency;
          if (isSameName) accountToWithSuchNameExists = true;
          return isSameName && isSameCurrency;
        });
        if (neededAccountTo) {
          this.accountToId = (
            neededAccountTo as AccountsOrAccount & {
              _id: string;
            }
          )._id;
        } else if (accountToWithSuchNameExists) {
          this.accountToObject = {
            name: `${this.accountToName} (${this.currency})`,
            currencyId: this.currency,
          };
        } else {
          this.accountToObject = {
            name: this.accountToName,
            currencyId: this.currency,
          };
        }
      }
      if (
        (this.type === OPERATION_TYPES.CON ||
          this.type === OPERATION_TYPES.TRN) &&
        this.accountFromName
      ) {
        let accountFromWithSuchNameExists = false;
        const neededAccountFrom = accounts.find((account) => {
          const isSameName =
            account.normalizedLabel === normalize(this.accountFromName);
          const isSameCurrency =
            (account as any)?.currency?.code === this.currency;
          if (isSameName) accountFromWithSuchNameExists = true;
          return isSameName && isSameCurrency;
        });
        if (neededAccountFrom) {
          this.accountFromId = (
            neededAccountFrom as AccountsOrAccount & {
              _id: string;
            }
          )._id;
        } else if (accountFromWithSuchNameExists) {
          this.accountFromObject = {
            name: `${this.accountFromName} (${this.currency})`,
            currencyId: this.currency,
          };
        } else {
          this.accountFromObject = {
            name: this.accountFromName,
            currencyId: this.currency,
          };
        }
      }
    } else {
      if (this.type === OPERATION_TYPES.INC)
        this.accountToId = (
          accounts as AccountsOrAccount & {
            _id: string;
          }
        )._id;
      if (this.type === OPERATION_TYPES.CON)
        this.accountFromId = (
          accounts as AccountsOrAccount & {
            _id: string;
          }
        )._id;
    }
  }

  private prepareCategories() {
    if (!this.category) delete this.subCategory;
  }

  private prepareSumsFinal() {
    this.sum = Math.abs(this.sum);
  }

  private prepareDateAndTimeFinal() {
    const dateAndTime = this.dateAndTime;
    delete this.time;
    delete this.date;
    delete this.dateAndTime;
    if (!isEmptyImportField(dateAndTime)) this.date = dateAndTime;

    const dateAndTimeOfPayment = this.dateAndTimeOfPayment;
    delete this.timeOfPayment;
    delete this.dateOfPayment;
    delete this.dateAndTimeOfPayment;
    if (!isEmptyImportField(dateAndTimeOfPayment))
      this.dateOfPayment = dateAndTimeOfPayment;
  }
}

export type ImportResultItemMask = Pick<
  ImportOperationMask,
  JustPropertyKeys<ImportOperationMask>
>;
