import { makeStyles } from '@material-ui/core/styles';

import { codGray, silver } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  invertIconColor: {
    filter: 'invert(50%)',
  },
  marginBottom24: {
    marginBottom: 24,
  },
  withoutDialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    color: codGray,
    fontWeight: 700,
    marginBottom: 16,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  descriptionRow: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '70%',
    marginBottom: 40,
  },
  descriptionText: {
    opacity: 0.5,
    marginLeft: 8,
  },
  separatorRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  separator: {
    display: 'flex',
    flex: 1,
    height: 1,
    background: silver,
  },
  separatorText: {
    fontSize: 13,
    height: 20,
    margin: '0 10px',
  },
  autocompleteSelector: {
    width: 430,
  },
});
