export const allAllowContentKeys = ['anyCurrencies', 'flexAnalytic'];
export const liteContentKeys = [
  'limitedIntegrations',
  'limitedEmployees',
  'noAPI',
  'noCalendar',
];
export const proContentKeys = [
  'unlimitedIntegrations',
  'unlimitedEmployees',
  'useAPI',
  'calendarAccess',
];
