import { ActivePeriod } from '../../scenes/InfoBlock/Settings/components/Subscription/types';
import { AutoCompleteProps } from '../types';

export type CompanyState = {
  errors: Record<string, any>;
  isError: boolean;
  loading: boolean;
  isButtonVisible: boolean;
  currentCompany: Company | null;
  companies: Company[];
  lng: string;
  apiKeys: ApiKey[];
  webhooks: Webhook[];
  companiesSettings: CompanySettings[];
};

export type ApiKey = {
  archived: boolean;
  companyId: string;
  createdAt: string;
  createdBy: string;
  key: string;
  name: string;
  updatedAt: string;
  __v: number;
  _id: string;
};

export type Webhook = {
  _id: string;
  name: string;
  url: string;
};

export enum TariffsId {
  Lite = '6107957d7ff452f8db2561fd',
  Pro = '61079598183c62c3ed36145d',
  Trial = '610795491bdecdc738c7212f',
  TrialWithoutRecurrent = '631b57cf093b827c9bf22f71',
}

export enum TariffsFromId {
  '6107957d7ff452f8db2561fd' = 'Lite',
  '61079598183c62c3ed36145d' = 'Pro',
  '610795491bdecdc738c7212f' = 'Trial',
  '631b57cf093b827c9bf22f71' = 'Trial-without-recurrent',
}

export type Company = {
  archived: boolean;
  _id: string;
  createdBy: string;
  name: string;
  currencyId: string;
  createdAt: string;
  updatedAt: string;
  roleId: string;
  normalizedLabel: string;
  demo: boolean;
  tariffId: TariffsId;
  tariffEndDate: string;
  tariffPeriod: ActivePeriod;
  canceledScheduled?: boolean;
  fastPermissionIds: string[];
  maskedCardNmber?: string;
  cardType?: string;
  infinitySubscription?: boolean;
};

export type CompanySettings = Company & {
  balance?: number;
  employeesCount?: number;
};

export type ChooseCompany = {
  name: string;
  currency: AutoCompleteProps;
  activity: AutoCompleteProps;
};
