import i18n from 'i18next';

import { OperationType } from '../../../../store/operations/types';
import { consumptionColors, incomeColors } from '../colors';

export const patternsByIncomeColor = incomeColors.map((color) => ({
  id: `custom-pattern-${color}`,
  path: {
    d: 'M 0 10 L 10 0 M -2 2 L 2 -2 M 8 12 L 12 8',
    stroke: color,
    strokeWidth: 3,
  },
  opacity: 0.5,
}));

export const patternsByConsumptionColor = consumptionColors.map((color) => ({
  id: `custom-pattern-${color}`,
  path: {
    d: 'M 0 10 L 10 0 M -2 2 L 2 -2 M 8 12 L 12 8',
    stroke: color,
    strokeWidth: 3,
  },
  opacity: 0.5,
}));

export const getColorHex = (index: number, type: OperationType) => {
  const rnd = Math.trunc(index / 30);

  return type === OperationType.income
    ? incomeColors[index < 30 ? index : index - rnd * 30]
    : consumptionColors[index < 30 ? index : index - rnd * 30];
};

export const getRandomColorHex = (type: OperationType) => {
  const colors =
    type === OperationType.income ? incomeColors : consumptionColors;

  return colors[Math.floor(Math.random() * 30)];
};

export const axisLabelFormatter = (num: number) => {
  const sign = num < 0 ? '-' : '';

  const value = Math.abs(num);

  if (value >= 1000000000) {
    return `${sign} ${(value / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (value >= 1000000) {
    return `${sign} ${(value / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (value >= 1000) {
    return `${sign} ${(value / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }

  return value;
};

export const chartToolbox = (
  showFullScreen: boolean,
  onClick: () => void,
  showFullScreenIcon: boolean,
  name?: string,
) => ({
  feature: {
    saveAsImage: {
      name,
      show: true,
      title: i18n.t('common.download'),
      pixelRatio: 3,
      icon: 'image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC40Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjA2MzI3IDExLjg4MzRWMEgxMC45MzgzVjExLjg0NjdMMTUuMDI3NCA4LjIzMDJMMTYuMjI0MiA5Ljc2OThMOS45NjkzMyAxNS4zMDE3TDMuNzczOCA5Ljc2NjYyTDQuOTc3NzMgOC4yMzMzOEw5LjA2MzI3IDExLjg4MzRaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjUgMjBIMi41VjE4SDE3LjVWMjBaIiBmaWxsPSJibGFjayIvPgo8L2c+Cjwvc3ZnPgo=',
    },
    myTool: {
      show: !showFullScreen && showFullScreenIcon,
      title: 'Full screen',
      icon: 'image://data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjRTVFNUU1Ii8+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8wXzEpIj4KPHJlY3Qgd2lkdGg9IjEyODAiIGhlaWdodD0iMTc3NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTYgLTY5OCkiIGZpbGw9IiMxODFEMUYiLz4KPHBhdGggZD0iTS04NjggLTYxOEgyMS42QzM1LjA0MTMgLTYxOCA0MS43NjE5IC02MTggNDYuODk1OCAtNjE1LjM4NEM1MS40MTE3IC02MTMuMDgzIDU1LjA4MzIgLTYwOS40MTIgNTcuMzg0MiAtNjA0Ljg5NkM2MCAtNTk5Ljc2MiA2MCAtNTkzLjA0MSA2MCAtNTc5LjZWMTQ4OEgtODY4Vi02MThaIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii04NDQiIHk9Ii0xNiIgd2lkdGg9Ijg4MCIgaGVpZ2h0PSIzNDEiIHJ4PSIxMiIgZmlsbD0iI0VCRjJGNSIgc3Ryb2tlPSIjRUJGMkY1IiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8ZyBvcGFjaXR5PSIwLjQiPgo8cGF0aCBkPSJNMTIuNSAyLjVIMTcuNVY3LjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik03LjUgMTcuNUgyLjVWMTIuNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjY2NjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE3LjQ5OTMgMi41TDExLjY2NiA4LjMzMzMzIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNjY2NjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMi41IDE3LjUwMDNMOC4zMzMzMyAxMS42NjciIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS42NjY2NyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8wXzEiPgo8cmVjdCB3aWR0aD0iMTI4MCIgaGVpZ2h0PSIxNzc2IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExOTYgLTY5OCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K',
      onclick: onClick,
      pixelRatio: 3,
    },
  },
});
