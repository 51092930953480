import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze, black, white } from '../../../theme/colors';

export const useStyles = makeStyles({
  button: {
    borderRadius: 20,
    background: aquaHaze,
    height: 72,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  buttonDescriptionText: {
    opacity: 0.5,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: '50%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 12,
  },
  fieldWrapper: {
    marginBottom: 14,
  },
  test: {
    zIndex: 1,
  },
  helpInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 25,
  },
  helpInfoTitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    opacity: 0.5,
    color: '#000000',
  },
  tooltipContainer: {
    width: 312,
    background: white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 25,
    color: black,
    fontSize: 13,
    lineHeight: '20px',
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
  },
  arrow: {
    color: white,
    width: 15,
  },
  tooltip: {
    marginLeft: 8,
  },
  tooltipLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    cursor: 'pointer',
  },
  tooltipLinkTitle: {
    marginLeft: 8,
    color: '#00B28E',
  },
  helpInfoIconDefault: {
    opacity: 0.5,
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  helpInfoIcon: {
    opacity: 1,
  },
  blurBackground: {
    backdropFilter: 'blur(5px)',
  },
  helperText: {
    marginLeft: 0,
    fontSize: 12,
    lineHeight: '16px',
    opacity: 0.5,
    color: black,
    marginBottom: 16,
  },
  helperActivityText: {
    marginBottom: 24,
  },
});
