import { CreateProjectPayload, DeleteProjectPayload, Project } from './types';

export const DELETE_PROJECT_PENDING = 'DELETE_PROJECT_PENDING';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';

export const UPDATE_GROUP_PROJECTS_PENDING = 'UPDATE_GROUP_PROJECTS_PENDING';

export const GET_LOG_PROJECTS_PENDING = 'GET_LOG_PROJECTS_PENDING';
export const GET_LOG_PROJECTS_SUCCESS = 'GET_LOG_PROJECTS_SUCCESS';

export const GET_OPERATION_PROJECTS_PENDING = 'GET_OPERATION_PROJECTS_PENDING';
export const GET_OPERATION_PROJECTS_SUCCESS = 'GET_OPERATION_PROJECTS_SUCCESS';

export const GET_SETTINGS_PROJECTS_PENDING = 'GET_SETTINGS_PROJECTS_PENDING';
export const GET_SETTINGS_PROJECTS_SUCCESS = 'GET_SETTINGS_PROJECTS_SUCCESS';

export const CREATE_OPERATION_PROJECTS_PENDING =
  'CREATE_OPERATION_PROJECTS_PENDING';
export const CREATE_OPERATION_PROJECTS_SUCCESS =
  'CREATE_OPERATION_PROJECTS_SUCCESS';

export const CREATE_SETTINGS_PROJECT_PENDING =
  'CREATE_SETTINGS_PROJECT_PENDING';
export const CREATE_SETTINGS_PROJECT_SUCCESS =
  'CREATE_SETTINGS_PROJECT_SUCCESS';

const updateGroupProjects = (projects: Project[]) => ({
  type: UPDATE_GROUP_PROJECTS_PENDING,
  payload: { projects },
});

const deleteProject = (data: DeleteProjectPayload) => ({
  type: DELETE_PROJECT_PENDING,
  payload: data,
});

const createProject = (name: CreateProjectPayload) => ({
  type: CREATE_SETTINGS_PROJECT_PENDING,
  payload: name,
});

const createOperationProject = (data: CreateProjectPayload) => ({
  type: CREATE_OPERATION_PROJECTS_PENDING,
  payload: data,
});

const getSettingsProjects = () => ({
  type: GET_SETTINGS_PROJECTS_PENDING,
});

const getOperationsProjects = () => ({
  type: GET_OPERATION_PROJECTS_PENDING,
});

export default {
  deleteProject,
  createProject,
  updateGroupProjects,
  getSettingsProjects,
  getOperationsProjects,
  createOperationProject,
};
