import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  MappedPeriodToPeriodTitle,
  MappedPeriodToPrice,
} from '../foundation/Subscriptions/SubscriptionDetailsDialog/types';
import api from '../store/common/api';
import { selectCurrentCompany } from '../store/company/selectors';
import { SubscriptionTariff } from '../store/types';
import { isLite, isTrial } from '../utils/subscriptions';

function useSubscriptionDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tariffs, setTariffs] = useState<SubscriptionTariff[] | null>(null);
  const [loadingTariffs, setLoadingTariffs] = useState(true);

  const currentCompany = useSelector(selectCurrentCompany);

  const payedFor = moment
    .utc(currentCompany?.tariffEndDate)
    .format('DD MMMM YYYY');

  let yourTariff;

  if (isLite(currentCompany)) {
    yourTariff = t('subscription.details.businessLite');
  } else if (isTrial(currentCompany)) {
    yourTariff = t('subscription.details.trial');
  } else {
    yourTariff = t('subscription.details.businessPro');
  }

  const period = currentCompany?.tariffPeriod || 1;

  let price1m;
  let price6m;
  let price12m;
  let price7mPerM;
  let price8mPerM;
  let price13mPerM;
  let price14mPerM;

  if (tariffs && currentCompany) {
    const currentTariff = tariffs.find(
      (el) => el._id === currentCompany.tariffId,
    );

    if (currentTariff) {
      price1m = currentTariff.price1m;
      price12m = currentTariff.price12m;
      price6m = currentTariff.price6m;
      price7mPerM = currentTariff.price7mPerM;
      price8mPerM = currentTariff.price8mPerM;
      price13mPerM = currentTariff.price13mPerM;
      price14mPerM = currentTariff.price14mPerM;
    }
  }

  const mappedPeriodToPrice: MappedPeriodToPrice = {
    1: price1m,
    6: price6m,
    7: price7mPerM,
    8: price8mPerM,
    12: price12m,
    13: price13mPerM,
    14: price14mPerM,
  };

  const mappedPeriodToPeriodTitle: MappedPeriodToPeriodTitle = {
    1: t('subscription.shortMonth'),
    7: t('subscription.shortMonth'),
    8: t('subscription.shortMonth'),
    6: t('subscription.halfYear'),
    12: t('subscription.year'),
    13: t('subscription.shortMonth'),
    14: t('subscription.shortMonth'),
  };

  useEffect(() => {
    const getTarrifs = async () => {
      const { data } = await api.getTariffs();

      setTariffs(data);

      setLoadingTariffs(false);
    };

    getTarrifs();
  }, [dispatch]);

  return {
    period,
    tariffs,
    payedFor,
    yourTariff,
    loadingTariffs,
    mappedPeriodToPrice,
    mappedPeriodToPeriodTitle,
  };
}

export default useSubscriptionDetails;
