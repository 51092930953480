import moment from 'moment';

import Http from '../../services/Http';
import { capitalizeFirstLetter } from '../../utils/stringUtils';
import {
  UpdateGroupAccountPayload,
  UpdateGroupApprovePayload,
  UpdateGroupCategoryPayload,
  UpdateGroupClientPayload,
  UpdateGroupCommentPayload,
  UpdateGroupProjectPayload,
  UpdateGroupTagPayload,
} from './sagas.types';
import {
  CreateIncomeInvestmentResponseData,
  CreateIncomeSaleResponseData,
  CreateTransferResponseData,
  OperationSubType,
  OperationType,
} from './types';

const offset = moment().utcOffset() * 60000;

const getOperation = (id: string) =>
  Http.makeInstance().post('/operations/log', {
    query: `limit=1&offset=0&operationIds=${id}`,
  });

const getOperations = (ids: string[]) =>
  Http.makeInstance().post('/operations/log', {
    query: `limit=${ids.length}&offset=0&operationIds=${ids.join(',')}`,
  });

const createOperation = (
  type: OperationType,
  subType: OperationSubType,
  data: any,
  isCopy = false,
) =>
  Http.makeInstance().post(
    `/operations/${type}/${subType}?context=operation&offset=${offset}&isDuplicate=${isCopy}`,
    {
      ...data,
    },
  );

const createIncomeSale = (data: CreateIncomeSaleResponseData) =>
  Http.makeInstance().post(
    `/operations/income/sale?context=operation&offset=${offset}`,
    {
      ...data,
    },
  );

const updateIncomeSale = (id: string, data: CreateIncomeSaleResponseData) =>
  Http.makeInstance().patch(`/operations/income/sale/${id}?offset=${offset}`, {
    ...data,
  });

const deleteOperations = (ids: string[], isBulkUpdate = false) =>
  Http.makeInstance().delete(
    `/operations/income/sale?isBulkUpdate=${isBulkUpdate}`,
    {
      data: { ids },
    },
  );

const deleteOperation = (id: string, scheduled: boolean) => {
  const query = [];

  if (scheduled) {
    query.push('removeAllNextScheduled=true');
  }

  return Http.makeInstance().delete(
    `/operations/income/sale/${id}?${query.join('&')}`,
  );
};

const getIncomeInvest = (saleId: string) =>
  Http.makeInstance().get(`/operations/income/investment/id=${saleId}`);

const createIncomeInvest = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().post(`/operations/income/investment?offset=${offset}`, {
    ...data,
  });

const updateIncomeInvest = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().patch(`/operations/income/investment?offset=${offset}`, {
    ...data,
  });

const deleteIncomeInvest = (id: string) =>
  Http.makeInstance().delete(`/operations/income/investment/${id}`);

const getIncomeLoanRepayment = (saleId: string) =>
  Http.makeInstance().get(`/operations/income/loanRepayment/id=${saleId}`);

const createIncomeLoanRepayment = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().post(
    `/operations/income/loanRepayment?offset=${offset}`,
    {
      ...data,
    },
  );

const updateIncomeLoanRepayment = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().patch(
    `/operations/income/loanRepayment?offset=${offset}`,
    {
      ...data,
    },
  );

const deleteIncomeLoanRepayment = (id: string) =>
  Http.makeInstance().delete(`/operations/income/loanRepayment/${id}`);

const getIncomeLoan = (saleId: string) =>
  Http.makeInstance().get(`/operations/income/loan/id=${saleId}`);

const createIncomeLoan = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().post(
    `/operations/income/loan?context=operation&offset=${offset}`,
    {
      ...data,
    },
  );

const updateIncomeLoan = (data: CreateIncomeInvestmentResponseData) =>
  Http.makeInstance().patch(`/operations/income/loan?offset=${offset}`, {
    ...data,
  });

const deleteIncomeLoan = (id: string) =>
  Http.makeInstance().delete(`/operations/income/loan/${id}`);

const getTransfers = (id: string) =>
  Http.makeInstance().get(`/operations/transfer/id=${id}`);

const createTransfer = (data: CreateTransferResponseData) =>
  Http.makeInstance().post(
    `/operations/transfer?context=operation&offset=${offset}&isDuplicate=${data.isCopy}`,
    {
      ...data,
    },
  );

const updateTransfers = (id: string, data: CreateTransferResponseData) =>
  Http.makeInstance().patch(`/operations/transfer/id=${id}?offset=${offset}`, {
    ...data,
  });

const updateOperation = (
  id: string,
  type: string,
  data: any,
  subType?: string,
) => {
  if (subType) {
    return Http.makeInstance().patch(
      `/operations/${type}/${subType}/${id}?offset=${offset}`,
      {
        ...data,
      },
    );
  }

  return Http.makeInstance().patch(`/operations/${type}/${id}`, {
    ...data,
  });
};

const updateMergeOperation = (type: string, subType: string, data: any) =>
  Http.makeInstance().patch(
    `/operations/${type}/${subType}/combine/predication/future`,
    {
      ...data,
    },
  );

const deleteFuturePrediction = (operationId: string[]) =>
  Http.makeInstance().patch('/predictions/deleteMany', {
    ids: operationId,
  });

const deleteTransfer = (id: string) =>
  Http.makeInstance().delete(`/operations/transfer/${id}`);

const createCancellation = (prefix: string, data: any) =>
  Http.makeInstance().post(
    `/operations/consumption/${prefix}?context=operation&offset=${offset}`,
    {
      ...data,
    },
  );

const updateGroupCategory = (
  data: Omit<UpdateGroupCategoryPayload, 'location'>,
) => {
  const { ids, categoryId, type, subType } = data;

  return Http.makeInstance().patch(
    `/operations/${type}/${subType}/updateCategory`,
    {
      ids,
      categoryId,
    },
  );
};

const updateGroupProject = (
  data: Omit<UpdateGroupProjectPayload, 'location'>,
) => {
  const { ids, projectId } = data;

  const projects = [];
  if (projectId) {
    projects.push({
      id: projectId,
      stake: 100,
    });
  }
  return Http.makeInstance().patch('/operations/income/sale/updateProjects', {
    ids,
    projects,
  });
};

const updateGroupComment = (
  data: Omit<UpdateGroupCommentPayload, 'location'>,
) => {
  const { ids, comment } = data;

  return Http.makeInstance().patch('/operations/income/sale/updateComment', {
    ids,
    comment,
  });
};

const updateGroupAccount = (
  data: Omit<UpdateGroupAccountPayload, 'location'>,
) => {
  const { ids, accountId } = data;

  return Http.makeInstance().patch('/operations/income/sale/updateAccounts', {
    ids,
    accountId,
  });
};

const updateGroupApprove = (
  data: Omit<UpdateGroupApprovePayload, 'location'>,
) => {
  const { ids, date } = data;

  return Http.makeInstance().patch('/operations/income/sale/approve', {
    ids,
    date,
  });
};

const updateGroupTag = (data: Omit<UpdateGroupTagPayload, 'location'>) => {
  const { ids, tags, replace } = data;

  return Http.makeInstance().patch('/operations/income/sale/updateTags', {
    ids,
    tags,
    replace,
  });
};

const updateGroupClient = (
  data: Omit<UpdateGroupClientPayload, 'location'>,
) => {
  const { ids, type, subType, clientType, clientId, clientTypeId } = data;

  return Http.makeInstance().patch(
    `/operations/${type}/${subType}/update${capitalizeFirstLetter(clientType)}`,
    {
      ids,
      [clientTypeId]: clientId,
    },
  );
};

type AddAttachmentToOperationPayload = {
  type: OperationType;
  subType?: OperationSubType;
  fileName: string;
  operationId: string;
  contentId: string;
  previewId?: string;
};

type RemoveAttachmentPayload = {
  type: OperationType;
  subType: OperationSubType;
  operationId: string;
  attachmentId: string;
};

const addAttachmentToOperation = (data: AddAttachmentToOperationPayload) => {
  const { type, subType, fileName, contentId, previewId, operationId } = data;

  if (subType) {
    return Http.makeInstance().put(
      `/operations/${type}/${subType}/${operationId}/files`,
      {
        label: fileName,
        contentId,
        previewId,
      },
    );
  }

  return Http.makeInstance().put(`/operations/${type}/${operationId}/files`, {
    label: fileName,
    contentId,
    previewId,
  });
};

const removeAttachment = (data: RemoveAttachmentPayload) => {
  const { type, subType, operationId, attachmentId } = data;

  return Http.makeInstance().delete(
    `/operations/${type}/${subType}/${operationId}/files/${attachmentId}`,
  );
};

const downloadAttachment = (contentId: string) =>
  Http.makeInstance().get(`/storage/${contentId}`, {
    responseType: 'blob',
  });

const mergeToTransfer = (ids: string[], createFeeOperation: boolean) =>
  Http.makeInstance().patch(
    `/operations/transfer/${ids[0]}/${ids[1]}?createFeeOperation=${createFeeOperation}`,
  );

const operationToTransfer = (id: string, data: CreateTransferResponseData) =>
  Http.makeInstance().patch(
    `/operations/transfer/transformOperationToTransfer/${id}?offset=${offset}`,
    {
      ...data,
    },
  );

export default {
  createOperation,
  createIncomeSale,
  updateIncomeSale,
  deleteOperation,
  deleteOperations,
  getIncomeInvest,
  createIncomeInvest,
  updateIncomeInvest,
  deleteIncomeInvest,
  getIncomeLoan,
  getIncomeLoanRepayment,
  createIncomeLoan,
  createIncomeLoanRepayment,
  updateIncomeLoan,
  updateIncomeLoanRepayment,
  deleteIncomeLoan,
  deleteIncomeLoanRepayment,
  getTransfers,
  createTransfer,
  updateTransfers,
  deleteTransfer,
  createCancellation,
  updateOperation,
  getOperation,
  getOperations,
  updateGroupCategory,
  updateGroupProject,
  updateGroupComment,
  updateGroupTag,
  updateGroupClient,
  updateGroupApprove,
  updateGroupAccount,
  addAttachmentToOperation,
  removeAttachment,
  downloadAttachment,
  mergeToTransfer,
  updateMergeOperation,
  deleteFuturePrediction,
  operationToTransfer,
};
