import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { EMPTY } from '../../constants';
import { TAction } from '../../sagas/types';
import { buildQueryRow } from '../../sagas/utils';
import { ReportTypeFilterBy } from '../../scenes/InfoBlock/Analytic/CashFlowAndPL/types';
import { prepareCustomFiltersQuerySelector } from '../../selectors/main';
import { showError } from '../../utils/showError';
import { selectCustomFilters, selectFilters } from '../filters/selectors';
import { FiltersState, ProjectCustomFilters } from '../filters/types';
import { JOURNAL_INIT } from '../journal/actions';
import { CustomFilters, Status } from '../journal/types';
import { OperationType } from '../operations/types';
import {
  FINISH_LOAD_BALANCE_DATA,
  GET_ACCOUNT_STATEMENT_PENDING,
  GET_ACCOUNT_STATEMENT_SUCCESS,
  GET_CHARTS_PENDING,
  GET_CIRCLE_CHARTS_PENDING,
  GET_CIRCLE_CHARTS_SUCCESS,
  GET_CREDIT_DATA_PENDING,
  GET_CREDIT_DATA_SUCCESS,
  GET_DEBIT_DATA_PENDING,
  GET_DEBIT_DATA_SUCCESS,
  GET_MAIN_CHART_PENDING,
  GET_MAIN_CHART_SUCCESS,
  GET_OTHER_CHARTS,
  GET_PIE_CHAR_DATA_PENDING,
  GET_PIE_CHAR_DATA_SUCCESS,
  GET_PROJECTS_DATA_PENDING,
  GET_PROJECTS_DATA_SUCCESS,
  GET_STACKED_CHART_DATA_PENDING,
  GET_STACKED_CHART_DATA_SUCCESS,
  GET_TABLE_DATA_PENDING,
  GET_TABLE_DATA_SUCCESS,
  SET_COUNTERPARTIES_SUM,
  SET_CREDITORS_SUM,
  SET_CUSTOMERS_SUM,
  SET_DEBTORS_SUM,
  SET_EMPLOYEES_SUM,
  SET_INVESTMENTS_SUM,
  SET_LOADING_CIRCLES,
  SET_LOADING_ERROR,
  SET_LOADING_STACKED,
  SET_STACKED_CHART_DATA_LOADING,
  SET_TAX_SUM,
  START_LOAD_BALANCE_DATA,
} from './actions';
import api from './api';
import { GetChartByTypeResponse } from './api.types';
import { initialState } from './reducer';
import {
  LoadMainChartPayload,
  LoadOtherChartsPayload,
  LoadPieChartPayload,
  LoadProjectsPayload,
  LoadStackedChartPayload,
} from './sagas.types';
import { selectPeriodSeriesMonths } from './selectors';
import { getIncomeCategories } from '../categories/selectors';
import { Category } from '../categories/types';
import categoriesApi from '../categories/api';
import { Context } from '../types';

function* getAccountStatement(): SagaIterator {
  try {
    const customFilters: CustomFilters = yield select(
      selectCustomFilters('statement'),
    );
    const allFilters = yield select(selectFilters('statement'));

    const { id, titlePeriod, ...filters } = allFilters;

    const query =
      Object?.keys(filters)
        .map((filter) =>
          filters[filter] !== undefined ? `${filter}=${filters[filter]}` : '',
        )
        .join('&') || '';

    if (customFilters.accounts) {
      const { data } = yield call(
        api.getAccountStatement,
        customFilters.accounts[0],
        query,
      );

      yield put({ type: GET_ACCOUNT_STATEMENT_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

function* selectCashFlowFilters(selector: keyof FiltersState): SagaIterator {
  const allFilters = yield select(selectFilters(selector));
  const customFiltersQuery = yield select(
    prepareCustomFiltersQuerySelector(selector),
  );
  const { id, titlePeriod, ...filters } = allFilters;

  const query =
    Object?.keys(filters)
      .reduce((acc: string[], filter) => {
        if (filters[filter] !== undefined) {
          acc.push(`${filter}=${filters[filter]}`);
        }

        return acc;
      }, [])
      .join('&') || '';

  return buildQueryRow(query, customFiltersQuery);
}

function* loadPieChartData(action: TAction<LoadPieChartPayload>): SagaIterator {
  try {
    const { type, typeFilter, selector, operationType, onlyApproved } =
      action.payload;

    yield put({
      type: SET_LOADING_CIRCLES,
      payload: { type: operationType, value: true },
    });

    let query = yield selectCashFlowFilters(selector) as any;

    if (onlyApproved) {
      if (query) {
        query += '&approved=true';
      } else {
        query = '?approved=true';
      }
    }

    const chartResponse: Omit<
      GetChartByTypeResponse,
      'operationType' | 'chartType'
    > = {
      type,
      typeFilter,
      query,
    };

    try {
      const { data } = yield call(api.getChartByType, {
        ...chartResponse,
        chartType: 'circles',
        operationType,
      });

      yield put({
        type: GET_PIE_CHAR_DATA_SUCCESS,
        payload: {
          data: {
            [operationType]: data[typeFilter],
          },
          typeFilter,
          operationType,
          onlyApproved,
        },
      });
    } catch (e) {
      yield put({
        type: SET_LOADING_ERROR,
        payload: { error: `${operationType}PieError`, value: true },
      });

      yield put({
        type: SET_LOADING_CIRCLES,
        payload: { type: operationType, value: false },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* loadStackedChartData(
  action: TAction<LoadStackedChartPayload>,
): SagaIterator {
  try {
    const { type, typeFilter, operationType, selector, onlyApproved } =
      action.payload;

    yield put({
      type: SET_LOADING_STACKED,
      payload: { type: operationType, value: true },
    });

    const months = yield select(selectPeriodSeriesMonths);

    let query = yield selectCashFlowFilters(selector) as any;

    if (onlyApproved) {
      if (query) {
        query += '&approved=true';
      } else {
        query = '?approved=true';
      }
    }

    const chartResponse: Omit<
      GetChartByTypeResponse,
      'operationType' | 'chartType'
    > = {
      type,
      typeFilter,
      query: buildQueryRow(query, `tableMonths=${JSON.stringify(months)}`),
    };

    try {
      const { data } = yield call(api.getChartByType, {
        ...chartResponse,
        operationType,
        chartType: 'charts',
      });

      yield put({
        type: GET_STACKED_CHART_DATA_SUCCESS,
        payload: {
          data: {
            [operationType]: data,
          },
          typeFilter,
          operationType,
          onlyApproved,
        },
      });
    } catch (e) {
      yield put({
        type: SET_LOADING_ERROR,
        payload: { error: `${operationType}StackedError`, value: true },
      });

      yield put({
        type: SET_LOADING_STACKED,
        payload: { type: operationType, value: false },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* loadOtherCharts(action: TAction<LoadOtherChartsPayload>) {
  try {
    const { init = false } = action.payload;

    yield put({ type: GET_CIRCLE_CHARTS_PENDING });
    yield put({ type: GET_CHARTS_PENDING });

    yield put({
      type: SET_STACKED_CHART_DATA_LOADING,
      payload: { value: true },
    });

    if (init) {
      yield put({ type: JOURNAL_INIT });
    }

    yield all([
      // @ts-ignore
      loadPieChartData({
        payload: { ...action.payload, operationType: OperationType.income },
      }),
      // @ts-ignore
      loadPieChartData({
        payload: {
          ...action.payload,
          operationType: OperationType.consumption,
        },
      }),
    ]);

    yield put({ type: GET_CIRCLE_CHARTS_SUCCESS });

    // @ts-ignore
    yield loadStackedChartData({
      payload: {
        ...action.payload,
        operationType: OperationType.income,
      },
    });

    // @ts-ignore
    yield loadStackedChartData({
      payload: {
        ...action.payload,
        operationType: OperationType.consumption,
      },
    });
  } catch (error) {
    showError(error);
  }
}

function* loadMainChart(action: TAction<LoadMainChartPayload>): SagaIterator {
  try {
    yield put({
      type: SET_LOADING_ERROR,
      payload: { error: 'periodChartError', value: false },
    });

    const { selector, reportType, typeFilter, onlyUpdate } = action.payload;

    const query = yield selectCashFlowFilters(selector) as any;

    let periodChartData = { ...initialState.cashFlowAndPLData.periodChart };

    try {
      const { data } = yield call(api.getMainChart, reportType, query);

      periodChartData = data.periodChart;

      yield put({
        type: GET_MAIN_CHART_SUCCESS,
        payload: {
          data: {
            periodChart: periodChartData,
          },
        },
      });
    } catch (e) {
      yield put({
        type: SET_LOADING_ERROR,
        payload: { error: 'periodChartError', value: true },
      });
    }

    if (!onlyUpdate) {
      yield put({
        type: GET_OTHER_CHARTS,
        payload: {
          type: reportType,
          typeFilter,
          selector,
          init: true,
        },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* loadTableData(action: TAction<LoadMainChartPayload>): SagaIterator {
  try {
    const { selector, reportType, onlyApproved } = action.payload;

    let query = yield selectCashFlowFilters(selector) as any;

    if (onlyApproved) {
      if (query) {
        query += '&approved=true';
      } else {
        query = '?approved=true';
      }
    }

    const { data } = yield call(api.getTableData, reportType, query);

    yield put({ type: GET_TABLE_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* loadCreditData(): SagaIterator {
  try {
    const customFiltersQuery = yield select(
      prepareCustomFiltersQuerySelector('credit'),
    );

    const { data } = yield call(api.getCreditData, customFiltersQuery);

    yield put({ type: GET_CREDIT_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* loadDebitData(): SagaIterator {
  try {
    const customFiltersQuery = yield select(
      prepareCustomFiltersQuerySelector('debit'),
    );

    const { data } = yield call(api.getDebitData, customFiltersQuery);

    yield put({ type: GET_DEBIT_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* loadBalanceData(): Generator<any, void, any> {
  try {
    const { data: creditData } = yield call(api.getCreditData as any);

    const counterpartiesSum =
      creditData?.suppliersCredit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;
    const taxSum =
      creditData?.taxOrganisationsCredit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;
    const employeesSum =
      creditData?.staffMembersCredit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;
    const creditorsSum =
      creditData?.creditorsCredit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;

    const { data: debitData } = yield call(api.getDebitData as any);
    const customersSum =
      debitData?.clientsDebit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;
    const debtorsSum =
      debitData?.borrowersDebit?.reduce(
        (prev: number, cur: any) => prev + cur.companyCurrencySum,
        0,
      ) || 0;

    const incomeCategories = yield select(getIncomeCategories);
    let investCategory = incomeCategories.settings.length
      ? incomeCategories.settings.find(
          (el: Category) =>
            el.normalizedLabel === 'investment' && el.systemValue,
        )
      : incomeCategories.log.find(
          (el: Category) =>
            el.normalizedLabel === 'investment' && el.systemValue,
        );

    if (!investCategory?._id) {
      const { data: categoryData } = yield call(
        categoriesApi.getIncomeCategories,
        Context.settings,
      );
      investCategory =
        categoryData.find(
          (el: Category) =>
            el.normalizedLabel === 'investment' && el.systemValue,
        ) || 0;
    }

    let investSum = 0;
    if (investCategory?._id) {
      const { data: cashData } = yield call(api.getChartByType as any, {
        type: 'cash',
        chartType: 'circles',
        query: `categoryV2Ids=${investCategory._id}&approved=true`,
        typeFilter: 'categories',
        operationType: OperationType.income,
      });
      investSum = cashData.categories.itemsApproved[0]
        ? cashData.categories.itemsApproved[0].value
        : 0;
    }

    yield put({ type: SET_CUSTOMERS_SUM, payload: { sum: customersSum } });
    yield put({ type: SET_DEBTORS_SUM, payload: { sum: debtorsSum } });
    yield put({ type: SET_INVESTMENTS_SUM, payload: { sum: investSum } });

    yield put({
      type: SET_COUNTERPARTIES_SUM,
      payload: { sum: counterpartiesSum },
    });
    yield put({ type: SET_TAX_SUM, payload: { sum: taxSum } });
    yield put({ type: SET_EMPLOYEES_SUM, payload: { sum: employeesSum } });
    yield put({ type: SET_CREDITORS_SUM, payload: { sum: creditorsSum } });

    yield put({ type: FINISH_LOAD_BALANCE_DATA });
  } catch (error) {
    showError(error);
  }
}

function* loadProjectsData(action: TAction<LoadProjectsPayload>) {
  try {
    const { useProjectsCharts } = action.payload;

    const {
      tags,
      status,
      clients,
      projects,
      accounts,
      categoriesByIds,
      categoriesByType,
      isDateOfPayment,
    }: ProjectCustomFilters = yield select(selectCustomFilters('projects'));

    const { startDate, endDate } = yield select(selectFilters('projects'));

    const query: string[] = [];

    if (tags) {
      const tagIds = tags.length
        ? tags.map((el: string) => el).join(',')
        : [EMPTY];

      query.push(`tagIds=${tagIds}`);
    }

    if (clients) {
      const counterpartyIds = clients.length
        ? clients.map((el: string) => el).join(',')
        : [EMPTY];

      query.push(`counterpartyIds=${counterpartyIds}`);
    }

    if (projects) {
      const projectIds = projects.length
        ? projects.map((el: string) => el).join(',')
        : [EMPTY];

      query.push(`projectIds=${projectIds}`);
    }

    if (accounts) {
      const accountIds = accounts.length
        ? accounts.map((el: string) => el).join(',')
        : [EMPTY];

      query.push(`accountIds=${accountIds}`);
    }

    if (categoriesByIds.length) {
      const categoryV2Ids = categoriesByIds.map((el: string) => el).join(',');

      query.push(`categoryV2Ids=${categoryV2Ids}`);
    }

    if (categoriesByType.length) {
      const types = categoriesByType.map((el: string) => el).join(',');

      query.push(`types=${types}`);
    }

    if (status?.id === Status.payed) {
      query.push('approved=true');
    }

    if (startDate) {
      query.push(`startDate=${startDate}`);
    }

    if (endDate) {
      query.push(`endDate=${endDate}`);
    }

    query.push(`useDateOfPayment=${isDateOfPayment}`);

    if (useProjectsCharts) {
      yield put({
        type: GET_MAIN_CHART_PENDING,
        payload: {
          selector: 'projects',
          reportType: 'profitAndLoss',
          typeFilter: ReportTypeFilterBy.projects,
          onlyUpdate: false,
        },
      });
    }

    const { data } = yield call(api.getProjectsData, query.join('&'));

    yield put({ type: GET_PROJECTS_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export default function analytic() {
  return all([
    takeLatest(GET_ACCOUNT_STATEMENT_PENDING, getAccountStatement),
    takeLatest(GET_CREDIT_DATA_PENDING, loadCreditData),
    takeLatest(GET_DEBIT_DATA_PENDING, loadDebitData),
    takeLatest(GET_PROJECTS_DATA_PENDING, loadProjectsData),
    takeLatest(GET_MAIN_CHART_PENDING, loadMainChart),
    takeLatest(GET_OTHER_CHARTS, loadOtherCharts),
    takeLatest(GET_TABLE_DATA_PENDING, loadTableData),
    takeLatest(GET_PIE_CHAR_DATA_PENDING, loadPieChartData),
    takeLatest(GET_STACKED_CHART_DATA_PENDING, loadStackedChartData),
    takeLatest(START_LOAD_BALANCE_DATA, loadBalanceData),
  ]);
}
