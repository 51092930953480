import { MenuItem, MenuList } from '@material-ui/core';
import cn from 'classnames';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import CheckMark from '../../../../../assets/images/svg/checkmark.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import { languagesData } from '../../../../../constants';
import { Routes } from '../../../../../constants/routes';
import useAdditionalLanguages from '../../../../../hooks/useAdditionalLanguages';
import { useStyles } from './styles';
import { Props } from './types';

function LangDialog(props: Props) {
  const { defaultLanguage, onClose, onSave } = props;
  const classes = useStyles();
  const [language, setLanguage] = useState(defaultLanguage || 'ru');
  const { t } = useTranslation();
  const { update } = useIntercom();
  const location = useLocation();

  const additionalLanguages = useAdditionalLanguages();

  const handleClickButton = useCallback(() => {
    onSave(language);

    setTimeout(() => {
      if (location.pathname === Routes.FAQ) {
        window.location.reload();
      }
    }, 1000);
  }, [onSave, language, location]);

  const handleSet = useCallback(
    (event: SyntheticEvent) => {
      const { id } = event.currentTarget;

      update({ languageOverride: id });

      setLanguage(id);
    },
    [update],
  );

  const RenderLanguage = useCallback(
    ({ item }: { item: any }) => {
      const { shortName, featureToggle } = item;

      // @ts-ignore
      if (!featureToggle || additionalLanguages[featureToggle]) {
        return (
          <MenuItem
            onClick={handleSet}
            className={cn(classes.item, classes.row)}
            id={shortName}
          >
            {item.shortName === 'ru' ? (
              <p>&nbsp;&nbsp;&nbsp;&nbsp;{t(item.name)}</p>
            ) : (
              t(item.name)
            )}
            {item.shortName === language && (
              <img src={CheckMark} alt="checkmark" />
            )}
          </MenuItem>
        );
      }

      return null;
    },
    [classes, handleSet, language, t, additionalLanguages],
  );

  return (
    <Dialog isOpened onClose={onClose} title={t('common.language')}>
      <MenuList className={classes.listRoot}>
        {languagesData(true).map((item) => (
          <RenderLanguage item={item} key={item.isoName} />
        ))}
      </MenuList>
      <CustomButton
        fullWidth
        action={handleClickButton}
        title={t('saveChanges')}
      />
    </Dialog>
  );
}

export default React.memo(LangDialog);
