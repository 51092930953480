import Http from '../Http';

const getLink = () =>
  Http.makeInstance().get('/integrations/posterApiCredentials');

const setTokens = (accountName: string, code: string) =>
  Http.makeInstance().post('/integrations/posterApiCredentials', {
    account: accountName,
    code,
  });

const chooseAccount = (accountId: string, credentialsId: string) =>
  Http.makeInstance().patch('/integrations/posterApiCredentials', {
    accountId,
    integrationCredentialsId: credentialsId,
  });

export default {
  getLink,
  setTokens,
  chooseAccount,
};
