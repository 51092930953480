import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import MsgToast from '../../components/MsgToast';
import {
  LIMIT_ITEMS_PER_PAGE,
  UPDATE_OPERATIONS_TOAST_ID,
} from '../../constants';
import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import clientsApi from '../clients/api';
import { Client, UrlPrefix } from '../clients/types';
import { Attachment } from '../operations/types';
import { Context } from '../types';
import { ActionTypes } from './actions';
import api from './api';
import {
  AddFileToInvoicePayload,
  CreateInvoicePayload,
  CreateInvoicingClientPayload,
  CreateMyInvoicingPropsPayload,
  CreateNewCompanyInvoiceGoodPayload,
  GetInvoicesLogPayload,
  UpdateGroupApprovePayload,
  UpdateGroupGoodsPayload,
  UpdateInvoiceByIdPayload,
  UpdateMyPropsPayload,
} from './sagas.types';
import { getCustomFilters, getOrdersBy, getPeriodFilter } from './selectors';
import { InvoicingCustomFilters } from './types';

const DELAY_UPDATE = 2000;

function prepareInvoicingCustomFiltersQuery(filters: InvoicingCustomFilters) {
  const query: string[] = [];

  const { statuses, clients } = filters;

  if (statuses) {
    query.push(`invoiceStatus=${statuses.join(',')}`);
  }

  if (clients) {
    query.push(`counterpartyIds=${clients.join(',')}`);
  }

  return query.join('&');
}

function* getInvoices(action: TAction<GetInvoicesLogPayload>): SagaIterator {
  try {
    const {
      offset = 0,
      limit = LIMIT_ITEMS_PER_PAGE,
      update,
    } = action.payload ?? {};

    const periodFilter = yield select(getPeriodFilter);
    const customFilters = yield select(getCustomFilters);
    const getSorts = yield select(getOrdersBy);

    const customFiltersQuery = yield prepareInvoicingCustomFiltersQuery(
      customFilters,
    ) as any;

    const { id, ...filters } = periodFilter;

    const ordersQuery =
      Object?.keys(getSorts)
        .reduce((row, order, index) => {
          if (!index) {
            row.push(`desc=${getSorts[order] === 'desc'}&field=${order}`);
            return row;
          }

          if (!getSorts[order]) {
            return row;
          }

          row.push(
            `desc${index}=${getSorts[order] === 'desc'}&field${index}=${order}`,
          );

          return row;
        }, [] as string[])
        .join('&') || '';

    const logQuery =
      Object?.keys(filters)
        .map((filter) =>
          filters[filter] !== undefined ? `${filter}=${filters[filter]}` : '',
        )
        .join('&') || '';

    const query = [customFiltersQuery, ordersQuery, logQuery];

    const { data } = yield call(api.getInvoicesLog, {
      query: query.join('&'),
      offset,
      limit,
    });

    yield put({
      type: ActionTypes.GET_INVOICES_SUCCESS,
      payload: { data, update },
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_INVOICES_FAILED, payload: { error } });
    showError(error);
  }
}

function* getMyInvoicingProps() {
  try {
    const { data } = yield call(api.getMyInvoicingProps);

    yield put({
      type: ActionTypes.GET_MY_INVOICING_PROPS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* getInvoicingClients() {
  try {
    const query = '&invoice=true';

    const { data } = yield call(
      clientsApi.getClients,
      UrlPrefix.clients,
      Context.operation,
      query,
    );

    yield put({
      type: ActionTypes.GET_INVOICING_CLIENTS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* createMyInvoicingProps(
  action: TAction<CreateMyInvoicingPropsPayload>,
) {
  try {
    const { data } = yield call(api.createNewMyInvoicingProps, action.payload);

    yield put({
      type: ActionTypes.CREATE_INVOICING_MY_PROPS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* createInvoicingClient(action: TAction<CreateInvoicingClientPayload>) {
  try {
    const { name } = action.payload;

    const { data } = yield call(
      clientsApi.createClient,
      UrlPrefix.clients,
      name,
      Context.operation,
    );

    const newClient = data.pop();

    const { data: clients } = yield call(
      clientsApi.updateClient,
      UrlPrefix.clients,
      { ...newClient },
      true,
    );

    yield put({
      type: ActionTypes.CREATE_INVOICING_CLIENT_SUCCESS,
      payload: { data: clients },
    });
  } catch (error) {
    showError(error);
  }
}

function* createInvoicingGood(
  action: TAction<CreateNewCompanyInvoiceGoodPayload>,
) {
  try {
    const { data } = yield call(
      api.createNewCompanyInvoiceGood,
      action.payload,
    );

    yield put({
      type: ActionTypes.CREATE_GOOD_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* getInvoicingGoods() {
  try {
    const { data } = yield call(api.getAllCompanyInvoiceGoods);

    yield put({
      type: ActionTypes.GET_INVOICING_GOODS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* createInvoice(action: TAction<CreateInvoicePayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateInvoices')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { file, ...rest } = action.payload;

    const { data } = yield call(api.createNewInvoice, rest);

    if (file) {
      const formData = new FormData();

      formData.append('content', file, file.name);

      const { data: attachment }: AxiosResponse<Attachment> = yield call(
        api.uploadAttachment,
        formData,
      );

      const attachmentPayload: AddFileToInvoicePayload = {
        id: data.operation._id,
        label: file.name,
        contentId: attachment.contentId,
        previewId: attachment.previewId,
      };

      yield call(api.addFileToInvoice, attachmentPayload);
    }

    yield delay(DELAY_UPDATE);

    yield put({ type: ActionTypes.GET_INVOICES_PENDING });

    yield put({
      type: ActionTypes.CREATE_INVOICE_SUCCESS,
      payload: { data },
    });

    toast.update(UPDATE_OPERATIONS_TOAST_ID, {
      render: `${i18next.t('toasts.invoiceSuccessfullySaved')}😎`,
      hideProgressBar: false,
      autoClose: 5000,
    });
  } catch (error) {
    showError(error);
  }
}

function* updateInvoiceById(action: TAction<UpdateInvoiceByIdPayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateInvoices')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { file, prevFileId, ...rest } = action.payload;

    const { data } = yield call(api.updateInvoiceById, rest);

    if (prevFileId) {
      yield call(api.removeAttachment, rest._id, prevFileId);
    }

    if (file) {
      const formData = new FormData();

      formData.append('content', file, file.name);

      const { data: attachment }: AxiosResponse<Attachment> = yield call(
        api.uploadAttachment,
        formData,
      );

      const attachmentPayload: AddFileToInvoicePayload = {
        id: data.operation._id,
        label: file.name,
        contentId: attachment.contentId,
        previewId: attachment.previewId,
      };

      yield call(api.addFileToInvoice, attachmentPayload);
    }

    yield delay(DELAY_UPDATE);

    yield put({ type: ActionTypes.GET_INVOICES_PENDING });

    toast.update(UPDATE_OPERATIONS_TOAST_ID, {
      render: `${i18next.t('toasts.invoiceSuccessfullySaved')}😎`,
      hideProgressBar: false,
      autoClose: 5000,
    });
  } catch (error) {
    showError(error);
  }
}

function* setApproved(action: TAction<UpdateGroupApprovePayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateInvoices')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });
    const { ids } = action.payload;

    yield call(api.updateApprove, ids);

    yield delay(DELAY_UPDATE);

    yield put({ type: ActionTypes.GET_INVOICES_PENDING });

    toast.update(UPDATE_OPERATIONS_TOAST_ID, {
      render: `${i18next.t('toasts.invoiceSuccessfullySaved')}😎`,
      hideProgressBar: false,
      autoClose: 5000,
    });
  } catch (error) {
    showError(error);
  }
}

function* removeInvoice(action: TAction<{ id: string }>) {
  try {
    const { id } = action.payload;

    yield call(api.removeInvoice, id);

    yield put({ type: ActionTypes.GET_INVOICES_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* removeSelectedInvoice(action: TAction<{ ids: string[] }>) {
  try {
    const { ids } = action.payload;

    for (let i = 0; i <= ids.length - 1; i += 1) {
      yield call(api.removeInvoice, ids[i]);
    }

    yield delay(DELAY_UPDATE);

    yield put({ type: ActionTypes.GET_INVOICES_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* getCompanyInvoicingData(): SagaIterator {
  try {
    yield put({
      type: ActionTypes.SET_LOADING_COMPANY_INVOICING_DATA,
      payload: { data: true },
    });

    const [goods, myProps, clients] = yield all([
      call(api.getAllCompanyInvoiceGoods),
      call(api.getMyInvoicingProps),
      call(
        clientsApi.getClients,
        UrlPrefix.clients,
        Context.operation,
        '&invoice=true',
      ),
    ]);

    yield put({
      type: ActionTypes.GET_INVOICING_CLIENTS_SUCCESS,
      payload: { data: clients.data },
    });

    yield put({
      type: ActionTypes.GET_MY_INVOICING_PROPS_SUCCESS,
      payload: { data: myProps.data },
    });

    yield put({
      type: ActionTypes.GET_INVOICING_GOODS_SUCCESS,
      payload: { data: goods.data },
    });

    yield put({
      type: ActionTypes.SET_LOADING_COMPANY_INVOICING_DATA,
      payload: { data: false },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateGroupGoods(action: TAction<UpdateGroupGoodsPayload>) {
  try {
    const { goods } = action.payload;

    const { data } = yield call(api.updateGroupGoods, goods);

    yield put({
      type: ActionTypes.UPDATE_INVOICE_GOODS_GROUP_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateGroupMyProps(action: TAction<UpdateMyPropsPayload>) {
  try {
    const { myProps } = action.payload;

    yield all(myProps.map((el) => call(api.updateMyInvoicingProps, el)));

    yield put({ type: ActionTypes.GET_MY_INVOICING_PROPS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* updateClients(action: TAction<any>) {
  try {
    const { clients } = action.payload;

    yield all(
      clients.map((client: Client) =>
        call(clientsApi.updateClient, UrlPrefix.clients, client, true),
      ),
    );

    yield put({ type: ActionTypes.GET_INVOICING_CLIENTS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* removeGood(action: TAction<{ id: string }>) {
  try {
    const { id } = action.payload;

    const { data } = yield call(api.removeGood, id);

    yield put({
      type: ActionTypes.REMOVE_GOOD_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* removeMyProps(action: TAction<{ id: string }>) {
  try {
    const { id } = action.payload;

    yield call(api.removeMyProps, id);

    yield put({ type: ActionTypes.GET_MY_INVOICING_PROPS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* removeClient(action: TAction<{ id: string }>) {
  try {
    const { id } = action.payload;

    const { data } = yield call(clientsApi.deleteClient, UrlPrefix.clients, id);

    yield put({
      type: ActionTypes.REMOVE_INVOICE_CLIENT_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export default function invoicing() {
  return all([
    takeLatest(ActionTypes.GET_INVOICES_PENDING, getInvoices),
    takeLatest(ActionTypes.GET_MY_INVOICING_PROPS_PENDING, getMyInvoicingProps),
    takeLatest(ActionTypes.GET_INVOICING_CLIENTS_PENDING, getInvoicingClients),
    takeLatest(ActionTypes.GET_INVOICING_GOODS_PENDING, getInvoicingGoods),
    takeLatest(ActionTypes.CREATE_INVOICING_PROPS, createMyInvoicingProps),
    takeLatest(
      ActionTypes.CREATE_INVOICING_CLIENT_PENDING,
      createInvoicingClient,
    ),
    takeLatest(ActionTypes.CREATE_GOOD_PENDING, createInvoicingGood),
    takeLatest(ActionTypes.CREATE_INVOICE_PENDING, createInvoice),
    takeLatest(ActionTypes.UPDATE_INVOICE_PENDING, updateInvoiceById),
    takeLatest(ActionTypes.SET_APPROVED, setApproved),
    takeLatest(ActionTypes.REMOVE_INVOICE_PENDING, removeInvoice),
    takeLatest(ActionTypes.REMOVE_SELECTED_INVOICES, removeSelectedInvoice),
    takeLatest(
      ActionTypes.GET_COMPANY_INVOICING_DATA_PENDING,
      getCompanyInvoicingData,
    ),
    takeLatest(
      ActionTypes.UPDATE_INVOICE_GOODS_GROUP_PENDING,
      updateGroupGoods,
    ),
    takeLatest(ActionTypes.UPDATE_INVOICE_MY_GROUP_PENDING, updateGroupMyProps),
    takeLatest(ActionTypes.UPDATE_INVOICE_CLIENTS, updateClients),
    takeLatest(ActionTypes.REMOVE_INVOICE_CLIENT_PENDING, removeClient),
    takeLatest(ActionTypes.REMOVE_MY_PROPS_PENDING, removeMyProps),
    takeLatest(ActionTypes.REMOVE_GOOD_PENDING, removeGood),
  ]);
}
