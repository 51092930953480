import { Permission } from './types';
import Http from '../../services/Http';

export const getInvites = () => Http.makeInstance().get('/invites');

export const createInvite = (roleId: string, name: string, email: string) =>
  Http.makeInstance().post('/invites', {
    roleId,
    name,
    email,
  });

export const deleteInvite = (id: string) =>
  Http.makeInstance().delete(`/invites/${id}`);

export const getEmployees = () => Http.makeInstance().get('/employees');

export const updateEmployee = (id: string, roleId: string, name: string) =>
  Http.makeInstance().patch(`/employees/${id}`, {
    roleId,
    name,
  });

export const deleteEmployee = (id: string) =>
  Http.makeInstance().delete(`/employees/${id}`);

export const getRoles = () => Http.makeInstance().get('/roles');

export const createRole = (label: string) =>
  Http.makeInstance().post('/roles', {
    label,
  });

export const updateRole = (id: string, label: string) =>
  Http.makeInstance().patch(`/roles/${id}`, {
    label,
  });

export const deleteRole = (id: string) =>
  Http.makeInstance().delete(`/roles/${id}`);

export const getPermissionTypes = () =>
  Http.makeInstance().get('/permissionTypes');

export const getResources = () => Http.makeInstance().get('/resources');

export const getPermissionsValidate = (typeId: string, resourceId: string) =>
  Http.makeInstance().get(
    `/permissions/validate?typeId=${typeId}&resourceId=${resourceId}`,
  );

export const getPermissions = (roleId: string) =>
  Http.makeInstance().get(`/permissions?roleId=${roleId}`);

export const createPermissions = (roleId: string, data: Permission[]) =>
  Http.makeInstance().post('/permissions', {
    roleId,
    permissions: data,
  });

export const deletePermission = (id: string) =>
  Http.makeInstance().delete(`/permissions/${id}`);

export const getFastPermissions = () =>
  Http.makeInstance().get('/fastPermissions');

export const getFastPermissionsByRoleId = (roleId: string) =>
  Http.makeInstance().get(`/fastPermissions/${roleId}`);

export const addFastPermissions = (roleId: string, fastPermissionIds: string[]) =>
  Http.makeInstance().post(`/fastPermissions/${roleId}`, {
    fastPermissionIds
  });

export const updateFastPermissions = (roleId: string, fastPermissionIds: string[]) =>
  Http.makeInstance().patch(`/fastPermissions/${roleId}`, {
    fastPermissionIds
  });

export const deleteFastPermissions = (roleId: string, fastPermissionIds: string[]) =>
  Http.makeInstance().delete(`/fastPermissions/${roleId}`, {
    data: { fastPermissionIds }
  });

export default {
  getInvites,
  createInvite,
  deleteInvite,
  getEmployees,
  updateEmployee,
  deleteEmployee,
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getPermissionTypes,
  getResources,
  getPermissionsValidate,
  getPermissions,
  deletePermission,
  createPermissions,
  getFastPermissions,
  getFastPermissionsByRoleId,
  addFastPermissions,
  updateFastPermissions,
  deleteFastPermissions
};
