import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../../components/Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';

function SubscriptionCardDialog(props: Props) {
  const { onClose } = props;

  const [showUnLinkedDialog, setShowUnLinkedDialog] = useState<boolean>(false);
  const [showSuccessUnlinkedDialog, setShowSuccessUnlinkedDialog] =
    useState<boolean>(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const handleUnlinkCard = useCallback(() => {
    setShowUnLinkedDialog(true);
  }, []);

  const handleCloseUnlinkCard = useCallback(() => {
    setShowUnLinkedDialog(false);
  }, []);

  const handleShowSuccessUnlinkedDialog = useCallback(() => {
    setShowSuccessUnlinkedDialog(true);
    setShowUnLinkedDialog(false);
    onClose();
  }, [onClose]);

  const handleCloseSuccessUnlinkedDialog = useCallback(() => {
    setShowSuccessUnlinkedDialog(false);
  }, []);

  return (
    <>
      <Dialog
        isOpened
        title={t('subscription.sureToUnlinkCard')}
        onClose={onClose}
      >
        <div className={classes.row}>
          <Typography className={classes.cardText}>
            {t('subscription.unbindCard')}
          </Typography>
          <Typography className={cn(classes.cardText, classes.marginTop24)}>
            {t('subscription.confirmUnBinding')}
          </Typography>
        </div>
        <div className={classes.cardButtonsWrapper}>
          <Button
            className={cn(classes.cardUnLinkButton, classes.cardButton)}
            onClick={handleUnlinkCard}
          >
            {t('subscription.unLink')}
          </Button>
          <Button
            className={cn(classes.cancelButton, classes.cardButton)}
            onClick={onClose}
          >
            {t(`subscription.changedMyMind`)} 🌝
          </Button>
        </div>
      </Dialog>
      {showUnLinkedDialog && (
        <Dialog
          isOpened
          title={`${t('subscription.card')} Карта  **** 8809 ${t(
            'subscription.unlinked',
          )}`}
          onClose={handleCloseUnlinkCard}
        >
          <Typography className={cn(classes.cardText, classes.marginTop24)}>
            {t('subscription.addNewPaymentWay')}
          </Typography>
          <div className={classes.cardButtonsWrapper}>
            <Button className={cn(classes.addButton, classes.cardButton)}>
              {t('subscription.addWay')}
            </Button>
            <Button
              className={cn(classes.okButton, classes.cardButton)}
              onClick={handleShowSuccessUnlinkedDialog}
            >
              {t('subscription.ok')} 🌝
            </Button>
          </div>
        </Dialog>
      )}
      {showSuccessUnlinkedDialog && (
        <Dialog isOpened title="" onClose={handleCloseSuccessUnlinkedDialog}>
          <Typography className={classes.successUnlinkedTitle}>
            {`${t('subscription.card')} *** 8809 ${t('subscription.unlinked')}`}{' '}
            😎
          </Typography>
        </Dialog>
      )}
    </>
  );
}
export default SubscriptionCardDialog;
