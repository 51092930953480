import { createSelector } from 'reselect';

import { AppState } from '../reducers';
import { UrlPrefix } from './types';

export const getLanguage = (state: AppState) => state.company.lng;

export const isLoading = (state: AppState) => state.clients.loading;

export const getClients = (state: AppState) => state.clients.clients;

export const getTaxOrganisations = (state: AppState) =>
  state.clients.taxOrganisations;
export const getInvestors = (state: AppState) => state.clients.investors;
export const getCreditors = (state: AppState) => state.clients.creditors;
export const getBorrowers = (state: AppState) => state.clients.borrowers;
export const getOwners = (state: AppState) => state.clients.owners;
export const getSuppliers = (state: AppState) => state.clients.suppliers;
export const getStaffMembers = (state: AppState) => state.clients.staffMembers;

export const selectSettingsClients = createSelector(
  getClients,
  (clients) => clients.settings,
);

export const selectSettingsInvestors = createSelector(
  getInvestors,
  (clients) => clients.settings,
);

export const selectSettingsBorrowers = createSelector(
  getBorrowers,
  (clients) => clients.settings,
);

export const selectSettingsCreditors = createSelector(
  getCreditors,
  (clients) => clients.settings,
);

export const selectSettingsOwners = createSelector(
  getOwners,
  (clients) => clients.settings,
);

export const selectSettingsTaxOrganisations = createSelector(
  getTaxOrganisations,
  (clients) => clients.settings,
);

export const selectSettingsSuppliers = createSelector(
  getSuppliers,
  (clients) => clients.settings,
);

export const selectSettingsStaffMembers = createSelector(
  getStaffMembers,
  (clients) => clients.settings,
);

export const selectOperationsClients = createSelector(
  getClients,
  (clients) => clients.operation,
);

export const selectOperationsInvestors = createSelector(
  getInvestors,
  (clients) => clients.operation,
);

export const selectOperationsBorrowers = createSelector(
  getBorrowers,
  (clients) => clients.operation,
);

export const selectOperationsCreditors = createSelector(
  getCreditors,
  (clients) => clients.operation,
);

export const selectOperationsOwners = createSelector(
  getOwners,
  (clients) => clients.operation,
);

export const selectOperationsSuppliers = createSelector(
  getSuppliers,
  (clients) => clients.operation,
);

export const selectOperationsStaffMembers = createSelector(
  getStaffMembers,
  (clients) => clients.operation,
);

export const selectOperationsTaxOrganisations = createSelector(
  getTaxOrganisations,
  (clients) => clients.operation,
);

export const selectLogClients = createSelector(
  getClients,
  (clients) => clients.log,
);

export const selectLogInvestors = createSelector(
  getInvestors,
  (clients) => clients.log,
);

export const selectLogBorrowers = createSelector(
  getBorrowers,
  (clients) => clients.log,
);

export const selectLogCreditors = createSelector(
  getCreditors,
  (clients) => clients.log,
);

export const selectLogOwners = createSelector(
  getOwners,
  (clients) => clients.log,
);

export const selectLogSuppliers = createSelector(
  getSuppliers,
  (clients) => clients.log,
);

export const selectLogStaffMembers = createSelector(
  getStaffMembers,
  (clients) => clients.log,
);

export const selectLogTaxOrganisations = createSelector(
  getTaxOrganisations,
  (clients) => clients.log,
);

export const selectAllLogClients = createSelector(
  selectLogClients,
  selectLogInvestors,
  selectLogStaffMembers,
  selectLogSuppliers,
  selectLogOwners,
  selectLogBorrowers,
  selectLogCreditors,
  selectLogTaxOrganisations,
  (
    clients,
    investors,
    staffMembers,
    suppliers,
    owners,
    borrowers,
    creditors,
    taxOrganisations,
  ) =>
    clients
      .concat([...investors])
      .concat([...staffMembers])
      .concat([...suppliers])
      .concat([...owners])
      .concat([...borrowers])
      .concat([...creditors])
      .concat([...taxOrganisations]),
);

export const selectAllOperationsClients = createSelector(
  selectOperationsClients,
  selectOperationsInvestors,
  selectOperationsStaffMembers,
  selectOperationsSuppliers,
  selectOperationsOwners,
  selectOperationsBorrowers,
  selectOperationsCreditors,
  selectOperationsTaxOrganisations,
  (
    clients,
    investors,
    staffMembers,
    suppliers,
    owners,
    borrowers,
    creditors,
    TaxOrganisations,
  ) =>
    clients
      .concat([...investors])
      .concat([...staffMembers])
      .concat([...suppliers])
      .concat([...owners])
      .concat([...borrowers])
      .concat([...creditors])
      .concat([...TaxOrganisations]),
);

export const selectClientsAmount = createSelector(
  selectLogClients,
  selectLogInvestors,
  selectLogStaffMembers,
  selectLogSuppliers,
  selectLogOwners,
  selectLogBorrowers,
  selectLogCreditors,
  selectLogTaxOrganisations,
  (
    clients,
    investors,
    staffMembers,
    suppliers,
    owners,
    borrowers,
    creditors,
    taxOrganisations,
  ) =>
    clients.length +
    investors.length +
    staffMembers.length +
    suppliers.length +
    owners.length +
    borrowers.length +
    creditors.length +
    taxOrganisations.length,
);

export const selectSettingsClientsByPrefix = createSelector(
  selectSettingsClients,
  selectSettingsInvestors,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsOwners,
  selectSettingsBorrowers,
  selectSettingsCreditors,
  selectSettingsTaxOrganisations,
  (
    clients,
    investors,
    staffMembers,
    suppliers,
    owners,
    borrowers,
    creditors,
    taxOrganisations,
  ) => ({
    [UrlPrefix.clients]: clients,
    [UrlPrefix.investors]: investors,
    [UrlPrefix.borrowers]: borrowers,
    [UrlPrefix.owners]: owners,
    [UrlPrefix.creditors]: creditors,
    [UrlPrefix.suppliers]: suppliers,
    [UrlPrefix.staffMembers]: staffMembers,
    [UrlPrefix.taxOrganisations]: taxOrganisations,
  }),
);
