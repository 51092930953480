import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import UnionIcon from '../../../../assets/images/svg/union_grey.svg';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import { selectCompanyCurrency } from '../../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../../store/currency/selectors';
import { getOperationProps } from '../../../../store/operations/selectors';
import { Project } from '../../../../store/projects/types';
import { black, lightBlack5, outrageousOrange } from '../../../../theme/colors';
import { formatStringPrice } from '../../../../utils/parseStringToNumber';
import { calculateSumAndCompanyCurrencySum } from '../../HOC/utils';
import { ErrorModal } from './ErrorModal';
import HintsRow from './HintsRow';
import ProjectRow from './ProjectRow';
import { useStyles } from './styles';
import {
  Hint,
  ProjectRowError,
  SplitPaymentProjectDetailedInfo,
  SplitPaymentProjectRow,
  SplittingPaymentDialogProps,
} from './types';

function SplittingPaymentDialog({
  onClose,
  onChangeSplitPaymentProjects,
  onCreateProject,
  projects,
}: SplittingPaymentDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    amount,
    account,
    revertState,
    exchangeRate,
    currencyValue,
    currencyAmount,
    splitPaymentProjects,
  } = useSelector(getOperationProps);

  const realAmount = useMemo((): number => {
    if (account?.currencyId !== currencyValue?.id) {
      return Number(currencyAmount ?? 0);
    }
    return Number(amount ?? 0);
  }, [account?.currencyId, amount, currencyAmount, currencyValue?.id]);

  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const isCrypto = cryptoCurrencyCodes.includes(account?.currency.code || '');

  const defaultSplitProjects = useMemo(() => {
    if (splitPaymentProjects.length === 0)
      return [
        {
          id: uuidv4(),
          projectInfo: { project: null, percentages: '', sum: '' },
        },
        {
          id: uuidv4(),
          projectInfo: { project: null, percentages: '', sum: '' },
        },
      ];
    const splitPaymentProjectsRows: SplitPaymentProjectRow[] =
      splitPaymentProjects.map((projInfo) => ({
        id: uuidv4(),
        projectInfo: {
          project: projInfo.project,
          percentages: projInfo.sum
            ? ((Number(projInfo.sum) * 100) / realAmount).toFixed(4)
            : '',
          sum: projInfo.sum,
        },
      }));

    if (splitPaymentProjects.length === 1)
      return splitPaymentProjectsRows.concat([
        {
          id: uuidv4(),
          projectInfo: { project: null, percentages: '', sum: '' },
        },
      ]);
    return splitPaymentProjectsRows;
  }, [realAmount, splitPaymentProjects]);

  const [projectsRows, setProjectRows] =
    useState<SplitPaymentProjectRow[]>(defaultSplitProjects);
  const [rowsErrors, setRowsErrors] = useState<ProjectRowError[]>([]);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

  const selectedProjects = (): Project[] =>
    projectsRows
      .map((row: SplitPaymentProjectRow) => row.projectInfo.project)
      .filter((elem: Project | null) => elem !== null) as Project[];

  const getSumDiff = useMemo(() => {
    // projectInfo.sum sometimes can be undefined
    const splitSum: number = projectsRows.reduce(
      (acc: number, row: SplitPaymentProjectRow) =>
        acc + Number(row.projectInfo.sum ?? 0),
      0,
    );

    return Number((realAmount - splitSum).toFixed(4));
  }, [projectsRows, realAmount]);

  const getPercentagesDiff = useMemo(() => {
    const splitPercentages: number = projectsRows.reduce(
      (acc: number, row: SplitPaymentProjectRow) =>
        acc + Number(row.projectInfo.percentages),
      0,
    );

    return Number((100 - splitPercentages).toFixed(4));
  }, [projectsRows]);

  const getHintCalculation = useMemo(() => {
    const sumDiff: number = getSumDiff;

    let hintText: string;
    let hintClassName: string;

    if (sumDiff < 0) {
      hintText = t('splitPaymentDialog.reduceBy');
      hintClassName = classes.hintRed;
    } else if (sumDiff > 0) {
      hintText = t('splitPaymentDialog.leftToSplit');
      hintClassName = classes.hintGreen;
    } else {
      hintText = t('splitPaymentDialog.leftToSplit');
      hintClassName = classes.hintGrey;
    }

    return {
      text: hintText,
      className: hintClassName,
      sum: String(Math.abs(sumDiff)),
      percentages: String(Math.abs(getPercentagesDiff)),
    };
  }, [classes, getPercentagesDiff, getSumDiff, t]);

  const validateForm = useCallback(() => {
    const errorArr: ProjectRowError[] = [];
    let isEmptySumField = false;
    // check in all rows fields are filled
    projectsRows.forEach((row: SplitPaymentProjectRow) => {
      if (row.projectInfo.project === null) {
        const errorRowIndex: number = errorArr.findIndex(
          (error: ProjectRowError) => error.rowId === row.id,
        );
        if (errorRowIndex === -1)
          errorArr.push({
            rowId: row.id,
            projectError: true,
            sumPercentagesError: false,
          });
        else errorArr[errorRowIndex].projectError = true;
      }
      if (row.projectInfo.sum === '') {
        isEmptySumField = true;
        const errorRowIndex: number = errorArr.findIndex(
          (error: ProjectRowError) => error.rowId === row.id,
        );
        if (errorRowIndex === -1)
          errorArr.push({
            rowId: row.id,
            projectError: false,
            sumPercentagesError: true,
          });
        else errorArr[errorRowIndex].sumPercentagesError = true;
      }
    });

    const sumDiff: number = getSumDiff;
    // show modal when it is left to split
    if (sumDiff > 0) {
      setIsOpenErrorModal(true);
    }
    // if all sum fields where filled check if sum of projects is equal to amount
    if (!isEmptySumField) {
      if (sumDiff !== 0) {
        // setting error to sum&percentages columns in all project rows
        projectsRows.forEach((row: SplitPaymentProjectRow) => {
          const errorRowIndex: number = errorArr.findIndex(
            (error: ProjectRowError) => error.rowId === row.id,
          );
          if (errorRowIndex === -1)
            errorArr.push({
              rowId: row.id,
              projectError: false,
              sumPercentagesError: true,
            });
          else errorArr[errorRowIndex].sumPercentagesError = true;
        });
      }
    }

    if (errorArr.length > 0) {
      setRowsErrors(errorArr);
      return false;
    }
    return true;
  }, [getSumDiff, projectsRows]);

  const saveForm = useCallback(() => {
    if (validateForm()) {
      // retrieve project info from state
      const projectsInfo: SplitPaymentProjectDetailedInfo[] = projectsRows.map(
        (row: SplitPaymentProjectRow) => {
          let currencyAmountVal: string;
          let amountVal: string;

          if (account?.currencyId !== currencyValue?.id) {
            currencyAmountVal = row.projectInfo.sum;
            amountVal =
              exchangeRate && Number(exchangeRate) !== 0
                ? (Number(row.projectInfo.sum) / Number(exchangeRate)).toFixed(
                    3,
                  )
                : row.projectInfo.sum;
          } else if (exchangeRate) {
            currencyAmountVal = (
              Number(exchangeRate ?? 0) * Number(row.projectInfo.sum)
            ).toFixed(4);
            amountVal = row.projectInfo.sum;
          } else {
            currencyAmountVal = row.projectInfo.sum;
            amountVal = row.projectInfo.sum;
          }
          const calculateSumAndCompanyCurrencySumProps = {
            isEdit: false,
            currencyAmount: currencyAmountVal,
            account,
            isCrypto,
            revertState,
            exchangeRate,
            currencyValue,
            amount: amountVal,
            companyCurrency,
          };

          const {
            companyCurrencySum = null,
            transactionSum = null,
            sum,
          } = calculateSumAndCompanyCurrencySum(
            calculateSumAndCompanyCurrencySumProps,
          );
          return {
            project: row.projectInfo.project,
            percentages: row.projectInfo.percentages,
            sum: sum?.toFixed(4) ?? '',
            transactionSum,
            companyCurrencySum,
          };
        },
      );
      onChangeSplitPaymentProjects(projectsInfo);
      onClose();
    }
  }, [
    account,
    onClose,
    isCrypto,
    revertState,
    exchangeRate,
    projectsRows,
    validateForm,
    currencyValue,
    companyCurrency,
    onChangeSplitPaymentProjects,
  ]);

  const deleteSplitting = useCallback(() => {
    onChangeSplitPaymentProjects([]);
    onClose();
  }, [onChangeSplitPaymentProjects, onClose]);

  const DialogActions = useCallback(
    () => (
      <div className={classes.footer}>
        <CustomButton
          action={saveForm}
          className={classes.buttonAccept}
          title={t('common.done')}
          textColor={black}
        />
        <CustomButton
          action={onClose}
          className={classes.buttonClear}
          title={t('common.cancel')}
          textColor={black}
        />
        {splitPaymentProjects.length > 1 && (
          <CustomButton
            action={deleteSplitting}
            className={classes.buttonDelete}
            title={t('splitPaymentDialog.deleteSplitting')}
            textColor={outrageousOrange}
            wrapperRootClassName={classes.marginLAuto}
          />
        )}
      </div>
    ),
    [
      classes,
      deleteSplitting,
      onClose,
      saveForm,
      splitPaymentProjects.length,
      t,
    ],
  );

  const createProjectRow = useCallback(() => {
    setProjectRows((prev) => [
      ...prev,
      {
        id: uuidv4(),
        projectInfo: { project: null, percentages: '', sum: '' },
      },
    ]);
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: string) => {
      const filteredRows = projectsRows.filter(
        (row: SplitPaymentProjectRow) => row.id !== rowId,
      );

      setProjectRows(filteredRows);
    },
    [projectsRows],
  );

  const handleChangeProject = useCallback(
    (rowId: string, project: Project) => {
      const projectsAr = projectsRows.map(
        (projectRow: SplitPaymentProjectRow) => {
          if (projectRow.id === rowId) {
            projectRow.projectInfo.project = project;
          }
          return projectRow;
        },
      );

      setProjectRows(projectsAr);
    },
    [projectsRows],
  );

  const handleChangePercentages = useCallback(
    (rowId: string, percentagesValue: string) => {
      const val = formatStringPrice(percentagesValue, false, 8);

      const projectSum = val ? String((Number(val) * realAmount) / 100) : '';

      const projectsAr = projectsRows.map(
        (projectRow: SplitPaymentProjectRow) => {
          if (projectRow.id === rowId) {
            projectRow.projectInfo.percentages = val;
            projectRow.projectInfo.sum = projectSum;
          }
          return projectRow;
        },
      );

      setProjectRows(projectsAr);
    },
    [projectsRows, realAmount],
  );

  const handleChangeAmount = useCallback(
    (rowId: string, sumValue: string) => {
      const val = formatStringPrice(sumValue, false, 8);

      const projectPercentages = val
        ? String((Number(val) * 100) / realAmount)
        : '';

      const projectsAr = projectsRows.map(
        (projectRow: SplitPaymentProjectRow) => {
          if (projectRow.id === rowId) {
            projectRow.projectInfo.percentages = projectPercentages;
            projectRow.projectInfo.sum = val;
          }
          return projectRow;
        },
      );

      setProjectRows(projectsAr);
    },
    [projectsRows, realAmount],
  );

  const hintActive = useMemo(() => {
    const countEmptyProjects = projectsRows.reduce((acc, el) => {
      if (!+el.projectInfo.sum) {
        acc += 1;
      }

      return acc;
    }, 0);

    return countEmptyProjects === 1 && !!+getHintCalculation.sum;
  }, [getHintCalculation, projectsRows]);

  const handleSetUnSplittingAmount = useCallback(
    (hint: Hint) => {
      if (!hintActive) {
        return;
      }

      const { sum, percentages } = hint;
      const val = formatStringPrice(sum, false, 8);
      const valPr = formatStringPrice(percentages, false, 8);

      const editProjectsRows = projectsRows.map(
        (el: SplitPaymentProjectRow) => {
          if (!+el.projectInfo.sum) {
            return {
              ...el,
              projectInfo: {
                ...el.projectInfo,
                sum: val,
                percentages: valPr,
              },
            };
          }

          return el;
        },
      );

      setProjectRows(editProjectsRows);
    },
    [projectsRows, hintActive],
  );

  return (
    <>
      <Dialog
        isOpened
        actions={<DialogActions />}
        onClose={onClose}
        className={classes.wide_modal}
        title={t(`splitPaymentDialog.title`, {
          postProcess: 'sprintf',
          sprintf: [realAmount, account?.currency?.symbol ?? ''],
        })}
      >
        <>
          {projectsRows.map((row: SplitPaymentProjectRow, index: number) => (
            <ProjectRow
              key={row.id}
              rowId={row.id}
              handleChangeAmount={handleChangeAmount}
              handleChangePercentages={handleChangePercentages}
              currencyValueSymbol={account?.currency?.symbol ?? ''}
              projects={projects}
              project={row.projectInfo}
              handleChangeProject={handleChangeProject}
              onCreateProject={onCreateProject}
              isPermittedToBeDeleted={projectsRows.length > 2}
              onDeleteRow={handleDeleteRow}
              selectedProjects={selectedProjects()}
              errorsInRow={rowsErrors.find((err) => err.rowId === row.id)}
              hint={
                index === 1 &&
                projectsRows.length === 2 &&
                splitPaymentProjects.length !== 0
                  ? getHintCalculation
                  : undefined
              }
            />
          ))}
          <HintsRow
            hintActive={hintActive}
            hint={getHintCalculation}
            onSetUnSplittingAmount={handleSetUnSplittingAmount}
          />
          <CustomButton
            action={createProjectRow}
            fullWidth
            className={classes.buttonAddProject}
            title={t('splitPaymentDialog.addProject')}
            textColor={lightBlack5}
            icon={UnionIcon}
            iconClassName={classes.icon}
          />
        </>
      </Dialog>
      {isOpenErrorModal && (
        <ErrorModal
          sumDiff={getSumDiff}
          setIsOpenErrorModal={setIsOpenErrorModal}
        />
      )}
    </>
  );
}

export default React.memo(SplittingPaymentDialog);
