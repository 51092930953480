import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class UnlimintImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          importDocument = importDocument.filter(
            (raw) =>
              !raw.some(
                (cell) =>
                  cell?.startsWith('Opening balance') ||
                  cell?.startsWith('Total ') ||
                  cell?.startsWith('Closing balance'),
              ),
          );
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Currency', 1]),
            date: this.getFirstValidCellByColumn(['Value Date', 2]),
            counterparty: this.getFirstValidCellByColumn([
              'Remitter / Beneficiary',
              5,
            ]),
            debit: this.getFirstValidCellByColumn(['Debit', 6]),
            credit: this.getFirstValidCellByColumn(['Credit', 7]),
            comment: this.getFirstValidCellByColumn(['Payment Details', 4]),
          });
        },
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLineRaw = this.findString(/Customer Account/)?.raw;
          const lastBodyLineRaw = this.findString(/Total/)?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLineRaw]);
          this.setDocumentBody(
            importDocument.slice(firstHeaderLineRaw + 2, lastBodyLineRaw),
          );
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Value Date', 2]),
            debit: this.getFirstValidCellByColumn(['Debit', 6]),
            credit: this.getFirstValidCellByColumn(['Credit', 7]),
            counterparty: this.getFirstValidCellByColumn([
              'Remitter / Beneficiary',
              5,
            ]),
            comment: this.getFirstValidCellByColumn(['Payment Details', 4]),
            currency: this.getFirstValidCellByColumn(['Currency', 1]),
          });
        },
        caseOptions: { defaultCurrency: 'USD' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn([
              'Value Date',
              0,
            ]),
            debit: this.getFirstValidCellByColumn(['Debit', 3]),
            credit: this.getFirstValidCellByColumn(['Credit', 4]),
            counterparty: this.getFirstValidCellByColumn([
              'Remitter / Beneficiary',
               2,
            ]),
            comment: this.getFirstValidCellByColumn(['Payment Details', 1]),
          });
        },
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: {
            verticalAlign: 'top',
            interlineSpacingAccuracy: 1,
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Value Date') &&
                  etc?.nextWord?.includes('Payment Details'),
                (word, etc) =>
                  word?.includes('Credit') &&
                  etc?.prevWord?.includes('Debit'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Credit') && etc?.prevWord?.includes('Debit'),
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ]
  };
}
