export function declensions(n: number, textForms: string[]) {
  if (n === 1) {
    return textForms[0];
  }

  if (n > 1 && n < 5) {
    return textForms[1];
  }

  return textForms[2];
}
