import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { INTERCOM_APP_ID } from '../constants';
import { Routes } from '../constants/routes';
import { getLanguage } from '../selectors/main';
import Storages, { StorageKey } from '../services/Storages';
import { getUser } from '../store/auth/selectors';
import { getUserGeoData } from '../store/common/selectors';
import { checkAllowLanguage } from '../utils/getCurrentLanguage';

function IndexScene() {
  const history = useHistory();
  const { search } = useLocation();
  const user = useSelector(getUser);
  const language = useSelector(getLanguage);
  const userGeoData = useSelector(getUserGeoData);

  const notFirstAction = Object.keys(localStorage).some(
    (key) => key === StorageKey.user,
  );

  if (notFirstAction) {
    if (!user) {
      history.push(`${Routes.LOGIN}/${language}${search}`);

      return null;
    }
  } else {
    if (!language) {
      const lng = checkAllowLanguage(userGeoData?.country ?? '').toLowerCase();

      Storages.put(StorageKey.language, lng);

      history.push(`${Routes.REGISTER}/${lng}${search}`);
    } else {
      history.push(`${Routes.REGISTER}/${language}${search}`);
    }

    return null;
  }

  if ((window as any).Intercom) {
    (window as any).Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      // @ts-ignore
      name: user.name,
      email: user.email,
      phone: user.phone,
    });
  }

  history.push(Routes.LOG);

  return null;
}

export default React.memo(IndexScene);
