import Http from '../../services/Http';
import api from '../common/api';

const getCurrencies = async () => {
  const requests = await Promise.all([
    api.getCurrencies(),
    api.getCryptoCurrencies(),
  ]);

  return {
    currencies: requests[0].data,
    cryptoCurrencies: requests[1].data,
  };
};

const getDefaultCurrencyProvider = () =>
  Http.makeInstance().get('/common/currencyExchangeRates/currencyRateProvider');

const getCurrencyProviders = () =>
  Http.makeInstance().get(
    '/common/currencyExchangeRates/currencyRateProvider/all',
  );

const updateDefaultCurrencyProvider = (providerId: string) =>
  Http.makeInstance().patch(
    `/common/currencyExchangeRates/currencyRateProvider/${providerId}`,
  );

export default {
  getCurrencies,
  getCurrencyProviders,
  getDefaultCurrencyProvider,
  updateDefaultCurrencyProvider,
};
