import { useCallback, useState } from 'react';

import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import { IntegrationTypeId } from '../../../store/integrations/types';

function useIntegrationToNewAccount() {
  const [showOnboardingTooltips, setShowOnboardingTooltips] = useState(false);

  const { isIntegrationActiveStep } = useOnboardingV2();

  const onShowOnboardingTooltips = useCallback(() => {
    setShowOnboardingTooltips(isIntegrationActiveStep);
  }, [isIntegrationActiveStep]);

  const onCloseOnboardingTooltips = useCallback(() => {
    setShowOnboardingTooltips(false);
  }, []);

  const [integrationTypeId, setIntegrationTypeId] =
    useState<IntegrationTypeId | null>(null);

  const [showIntegrationToNewAccount, setShowIntegrationToNewAccount] =
    useState(false);

  const onOpenIntegrationToNewAccount = useCallback(() => {
    setShowIntegrationToNewAccount(true);
  }, []);

  const onCloseIntegrationToNewAccount = useCallback(() => {
    setIntegrationTypeId(null);
    setShowIntegrationToNewAccount(false);
  }, []);

  const onSetIntegrationTypeId = useCallback((id: IntegrationTypeId) => {
    setIntegrationTypeId(id);
  }, []);

  return {
    integrationTypeId,
    onSetIntegrationTypeId,
    showOnboardingTooltips,
    onShowOnboardingTooltips,
    onCloseOnboardingTooltips,
    showIntegrationToNewAccount,
    onOpenIntegrationToNewAccount,
    onCloseIntegrationToNewAccount,
  };
}

export default useIntegrationToNewAccount;
