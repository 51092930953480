export const GET_USER_PERMISSIONS_PENDING = 'GET_USER_PERMISSIONS_PENDING';
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';

export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_FULFILLED = 'LOGIN_USER_FULFILLED';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER_PENDING = 'REGISTER_USER_PENDING';
export const REGISTER_USER_FULFILLED = 'REGISTER_USER_FULFILLED';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const START_LOADING_PENDING = 'START_LOADING_PENDING';
export const START_LOADING_SUCCESS = 'START_LOADING_SUCCESS';

export const RESET_NEW_USER = 'RESET_NEW_USER';

export const USER_UPDATE_PROFILE_LNG_PENDING =
  'USER_UPDATE_PROFILE_LNG_PENDING';
export const USER_UPDATE_PROFILE_LNG_SUCCESS =
  'USER_UPDATE_PROFILE_LNG_SUCCESS';

export const USER_UPDATE_PROFILE_PHONE_PENDING =
  'USER_UPDATE_PROFILE_PHONE_PENDING';
export const USER_UPDATE_PROFILE_PHONE_SUCCESS =
  'USER_UPDATE_PROFILE_PHONE_SUCCESS';

export const USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING =
  'USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING';
export const USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS =
  'USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS';

export const USER_UPDATE_PROFILE_PENDING = 'USER_UPDATE_PROFILE_PENDING';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_TC_PENDING = 'USER_UPDATE_PROFILE_TC_PENDING';
export const USER_UPDATE_PROFILE_TC_SUCCESS = 'USER_UPDATE_PROFILE_TC_SUCCESS';
export const LOGIN_USER_BY_QR_CDDE_PENDING = 'LOGIN_USER_BY_QR_CDDE_PENDING';
export const LOGIN_USER_BY_QR_CDDE_SUCCESS = 'LOGIN_USER_BY_QR_CDDE_SUCCESS';
