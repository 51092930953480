import { MAX_TIME_BEFORE_SHOW_APPROVE_REMOVE } from '../constants';
import Storages from '../services/Storages';
import { getTimeOffset } from './dateToUTC';

function checkTime(type: string) {
  try {
    const requestTime = Number(Storages.get(type));

    if (!requestTime) {
      return true;
    }

    const showConfirm =
      (new Date().getTime() -
        new Date(requestTime).getTime() +
        getTimeOffset()) /
      1000 >
      MAX_TIME_BEFORE_SHOW_APPROVE_REMOVE;

    if (showConfirm) {
      Storages.put(type, null);
    }

    return showConfirm;
  } catch (e) {
    return true;
  }
}

export default checkTime;
