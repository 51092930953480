import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class JusanbankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: {
            simple: [[0, { in: 'Дата' }]],
          },
          preParserConfigs: {
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата' }, nextWord: { in: 'Сумма' } }],
                to: [{ word: { in: 'Детали операции' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Детали операции' } }],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY HH:mm:ss' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата', 0],
                split: { by: ' ', get: 0 },
                add: [' ', { column: ['Дата', 0], split: { by: ' ', get: 1 } }],
              },
              sum: { column: ['Сумма', 1] },
              comment: { column: ['Детали операции', 4] },
              currency: { column: ['Валюта', 2] },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: [
            [
              0,
              {
                eq: 'AO "Jusan Bank"',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            rotate: true,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Дата операции',
                    },
                    nextWord: {
                      eq: 'Дата',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'по счету',
                    },
                    prevWord: {
                      eq: 'по курсу НБ РК',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'по счету',
                      },
                      prevWord: {
                        eq: 'по курсу НБ РК',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'ИТОГО:',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: ',',
                      to: '.',
                    },
                    {
                      from: ' ',
                      to: '',
                    },
                  ],
                },
                {
                  in: '.',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата операции', 0],
                replaceOne: [
                  {
                    from: '\n',
                    to: ' ',
                  },
                ],
              },
              debit: {
                column: ['Дебет', 3],
              },
              credit: {
                column: ['Кредит', 4],
              },
              counterparty: {
                column: ['Корреспондент', 9],
              },
              comment: {
                column: ['Назначение платежа', 8],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: [[0, { in: 'Дата операции' }]],
          preParserConfigs: {
            rotate: true,
            prepareRawConfig: {
              findHeader: {
                from: [
                  { word: { in: 'Дата операции' }, nextWord: { in: 'Дата' } },
                ],
                to: [{ word: { in: 'Счет' } }],
              },
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата операции', 0],
                replaceOne: [{ from: '\n', to: ' ' }],
              },
              debit: { column: ['Дебет', 3] },
              credit: { column: ['Кредит', 4] },
              counterparty: { column: ['Корреспондент', 9] },
              comment: { column: ['Назначение платежа', 8] },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            rotate: true,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата' }, nextWord: { in: 'No док' } }],
                to: [{ word: { in: 'Счет' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Счет' } }],
                  count: 1,
                },
              ],
              define: [
                { dateFormat: 'DD.MM.YYYY HH:mm', slice: { from: 0, to: 16 } },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата', 0],
                split: { by: ' ', get: 0 },
                add: [' ', { column: ['Дата', 0], split: { by: ' ', get: 1 } }],
              },
              debit: { column: ['Дебет', 2] },
              credit: { column: ['Кредит', 3] },
              counterparty: { column: ['Корреспондент', 6] },
              comment: { column: ['Назначение платежа', 5] },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата выписки', 0],
                add: [' ', { column: ['Время операции', 1] }],
              },
              debit: { column: ['Сумма по дебету', 10] },
              credit: { column: ['Сумма по кредиту', 12] },
              comment: { column: ['Назначение платежа', 17] },
              currency: { column: ['Валюта документа', 14] },
              counterparty: { column: ['Наименование контрагента', 16] },
            },
          },
        },
      },
    ],
  };
}
