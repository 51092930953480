import { makeStyles } from '@material-ui/core/styles';

import { black } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  noBorder: {
    border: 'none',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    '&:hover': {
      background: 'rgba(238, 243, 245, 0.5)',
      borderRadius: 20,
    },
  },
  paper: {
    paddingTop: 0,
    width: 150,
    height: 300,
    overflow: 'auto',
  },
  arrowIcon: {
    color: black,
    marginLeft: 'auto',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
