import { CreateTagPayload, DeleteTagPayload, Tag } from './types';

export const UPDATE_GROUP_TAGS_PENDING = 'UPDATE_GROUP_TAGS_PENDING';

export const CREATE_OPERATION_TAGS_PENDING = 'CREATE_OPERATION_TAGS_PENDING';
export const CREATE_OPERATION_TAGS_SUCCESS = 'CREATE_OPERATION_TAGS_SUCCESS';

export const CREATE_SETTINGS_TAG_PENDING = 'CREATE_SETTINGS_TAG_PENDING';
export const CREATE_SETTINGS_TAG_SUCCESS = 'CREATE_SETTINGS_TAG_SUCCESS';

export const DELETE_TAG_PENDING = 'DELETE_TAG_PENDING';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';

export const GET_LOG_TAGS_PENDING = 'GET_LOG_TAGS_PENDING';
export const GET_LOG_TAGS_SUCCESS = 'GET_LOG_TAGS_SUCCESS';

export const GET_OPERATION_TAGS_PENDING = 'GET_OPERATION_TAGS_PENDING';
export const GET_OPERATION_TAGS_SUCCESS = 'GET_OPERATION_TAGS_SUCCESS';

export const GET_SETTINGS_TAGS_PENDING = 'GET_SETTINGS_TAGS_PENDING';
export const GET_SETTINGS_TAGS_SUCCESS = 'GET_SETTINGS_TAGS_SUCCESS';

const getLogTags = () => ({
  type: GET_LOG_TAGS_PENDING,
});

const getOperationTags = () => ({
  type: GET_OPERATION_TAGS_PENDING,
});
const updateGroupTags = (tags: Tag[]) => ({
  type: UPDATE_GROUP_TAGS_PENDING,
  payload: { tags },
});

const deleteTag = (data: DeleteTagPayload) => ({
  type: DELETE_TAG_PENDING,
  payload: data,
});

const createTag = (name: CreateTagPayload) => ({
  type: CREATE_SETTINGS_TAG_PENDING,
  payload: name,
});

const createOperationTag = (label: CreateTagPayload) => ({
  type: CREATE_OPERATION_TAGS_PENDING,
  payload: label,
});

const getSettingsTags = () => ({
  type: GET_SETTINGS_TAGS_PENDING,
});

export default {
  deleteTag,
  createTag,
  getLogTags,
  updateGroupTags,
  getSettingsTags,
  getOperationTags,
  createOperationTag,
};
