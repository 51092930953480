export enum ONBOARDING_STEPS {
  ONBOARDING_COMPLETED = 'OnboardingCompleted',
  GREETINGS = 'GREETINGS',
  START_SERVICE_FROM = 'START_SERVICE_FROM',
  SETUP_CABINET = 'SETUP_CABINET',
  PRE_LAST = 'PRE_LAST',
  FINAL = 'FINAL',
  INTEGRATION = 'INTEGRATION',
  ANALYTIC = 'ANALYTIC',
}

export enum ONBOARDING_SUB_STEPS {
  PROFIT = 'PROFIT',
  SAVE_HOURS = 'SAVE_HOURS',
  MAKE_ORDER = 'MAKE_ORDER',
  INTEGRATION = 'INTEGRATION',
  IMPORT = 'IMPORT',
  MANUAL = 'MANUAL',
  INTEGRATION_ADD_ACCOUNT = 'INTEGRATION_ADD_ACCOUNT',
  INTEGRATION_CREATE_ACCOUNT = 'INTEGRATION_CREATE_ACCOUNT',
  INTEGRATION_ADD_OPERATION = 'INTEGRATION_ADD_OPERATION',
  INTEGRATION_CREATE_OPERATION = 'INTEGRATION_CREATE_OPERATION',
  INTEGRATION_ADD_IMPORT = 'INTEGRATION_ADD_IMPORT',
  INTEGRATION_CREATE_IMPORT = 'INTEGRATION_CREATE_IMPORT',
  INTEGRATION_LOG = 'INTEGRATION_LOG',
  ANALYTIC_FILTERS = 'ANALYTIC_FILTERS',
  ANALYTIC_CATEGORIES = 'ANALYTIC_CATEGORIES',
  ANALYTIC_CHART = 'ANALYTIC_CHART',
  ANALYTIC_LOG = 'ANALYTIC_LOG',
  ANALYTIC_LOG_FILTERS = 'ANALYTIC_LOG_FILTERS',
  ANALYTIC_LOG_OPERATION = 'ANALYTIC_LOG_OPERATION',
}

export enum ONBOARDING_STATUS {
  PASSED = 'passed',
  ACTIVE = 'active',
  SKIP = 'skip',
  PENDING = 'pending',
}

export type OnboardingStepInfo = {
  stepName: ONBOARDING_STEPS;
  stepSubCategory: ONBOARDING_SUB_STEPS;
  status: ONBOARDING_STATUS;
  _id: string;
};

export type OnboardingV2State = {
  onboardingV2InProgress: boolean;
  onboardingSteps: OnboardingStepInfo[];
  showVideo: boolean;
};

export type CreateOnboardingStepPayload = {
  stepName: ONBOARDING_STEPS;
  status: ONBOARDING_STATUS;
  stepSubCategory?: ONBOARDING_SUB_STEPS;
  callback?(): void;
};

export type UpdateOnboardingStepPayload = OnboardingStepInfo;

export type OnboardingV2StepInfoProps = {
  stepName: ONBOARDING_STEPS;
  status: ONBOARDING_STATUS;
};

export type DeleteOnboardingPayload = {
  id: string;
};
