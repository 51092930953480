import {
  atlantis,
  baliHai,
  bismarkap,
  bittersweet,
  butterflyBushap,
  casablanca,
  chestnutRose,
  chetwodeBlue,
  conifer,
  conifer2,
  cornflowerBlue,
  darkBurgundyap,
  diSerria,
  dodgerBlue,
  downy,
  easternBlue,
flaxSmokeap,   fuzzyWuzzyBrownap,
gunPowderap,
  havelockBlue,
  heliotrope,
  heliotropeConsumption,
  hippieBlueap,   hitPink,
  jaffa,
  kashmirBlueap,
  lavenderMagenta,
  lavenderPink,
  lightOrchid,
  melrose,
  mineShaft,
  picasso,
  pictonBlue,
  pictonBlue2,
  roseFog,
  royalHeathap,
  salmon,
  sandalap,
  seaBuckthornap,
  shamrock,
  shamrock2,
  shuttleGrayap,
  spicyMixap,
  strikemaster,
  sunflower,
sushiap,   swampGreenap,
  tangoap,
  tapestryap,
  tickleMePink,
  toast,
  tropicalBlue,
  turmeric,
  turquoise,
  turquoise2,
  violaap,
  wildStrawberry,
  yellowOrange,
  yellowOrange2,
  yellowOrangeConsumption} from "../../../theme/colors";

export const incomeColors = [
  shamrock2,
  pictonBlue,
  cornflowerBlue,
  tickleMePink,
  conifer,
  yellowOrange,
  heliotrope,
  shamrock,
  turmeric,
  lavenderMagenta,
  yellowOrange2,
  sunflower,
  chetwodeBlue,
  wildStrawberry,
  havelockBlue,
  turquoise2,
  tropicalBlue,
  conifer2,
  picasso,
  downy,
  dodgerBlue,
  roseFog,
  lightOrchid,
  jaffa,
  lavenderPink,
  atlantis,
  easternBlue,
  melrose,
  pictonBlue2,
  hitPink,
];

export const consumptionColors = [
  mineShaft,
  bittersweet,
  casablanca,
  salmon,
  strikemaster,
  chestnutRose,
  heliotropeConsumption,
  turquoise,
  yellowOrangeConsumption,
  toast,
  baliHai,
  diSerria,
  sandalap,
  darkBurgundyap,
  royalHeathap,
  swampGreenap,
  shuttleGrayap,
  kashmirBlueap,
  fuzzyWuzzyBrownap,
  violaap,
  seaBuckthornap,
  butterflyBushap,
  bismarkap,
  tapestryap,
  tangoap,
  spicyMixap,
  hippieBlueap,
  gunPowderap,
  sushiap,
  flaxSmokeap,
];
