import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import iDokladApi from '../../../services/bankIntegrations/idoklad';
import { GetLinkResponse } from '../../../services/bankIntegrations/iDoklad.types';
import Storages from '../../../services/Storages';
import { minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { Props } from './types';

const maxIntegrationDate =
  moment()
    .subtract(1, 'day')
    .set({ hours: 0, minutes: 0, seconds: 0 })
    .valueOf() + getTimeOffset();

function IdokladDialog(props: Props) {
  const { onClose, selectedAccountId } = props;

  const { t } = useTranslation();

  const [errorDate, setErrorDate] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);

  const handleChangeDate = useCallback((value: any) => {
    setErrorDate(false);
    setSelectedDate(value);
  }, []);

  const handleGetLink = useCallback(async () => {
    if (!selectedDate) {
      setErrorDate(true);

      return;
    }

    setLoading(true);
    try {
      const { data }: AxiosResponse<GetLinkResponse> = await iDokladApi.getLink(
        window.location.origin,
      );

      Storages.put('iDoklad', {
        integrationCredentialId: data.integrationCredentialId,
        startDate: selectedDate,
        accountId: selectedAccountId,
      });

      window.location.href = data.redirectUrl;
    } catch (e) {
      setErrorRequest(t('system.error'));
    }

    setLoading(false);
  }, [t, selectedDate, selectedAccountId]);

  return (
    <Dialog isOpened onClose={onClose} title={t('bank.iDoklad.title')}>
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        // @ts-ignore
        value={selectedDate}
        fullWidth
        maxDate={new Date(maxIntegrationDate)}
        minDate={minOperationDate()}
        error={errorDate}
        fromIntegration
      />
      <CustomButton
        fullWidth
        title={t('bank.facturownia.connectToLogin')}
        action={handleGetLink}
        loading={loading}
        errorText={errorRequest}
      />
    </Dialog>
  );
}

export default React.memo(IdokladDialog);
