import { createSelector } from 'reselect';

import { AppState } from '../reducers';

export const getTags = (state: AppState) => state.tags.tags;
export const getLanguage = (state: AppState) => state.company.lng;

export const isLoadingTag = (state: AppState) => state.tags.loading;

export const selectOperationTags = createSelector(
  getTags,
  (tags) => tags.operation,
);

export const selectSettingsTags = createSelector(
  getTags,
  (tags) => tags.settings,
);

export const selectOperationTagsToAutoComplete = createSelector(
  selectOperationTags,
  (tags) =>
    tags.map((tag) => ({
      _id: tag._id,
      id: tag._id,
      name: tag.label,
      label: tag.label,
    })),
);
