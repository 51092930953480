import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import { isNotEmpty } from 'class-validator';

export class UkrsibBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Виписка за картковим рахунком клієнта' }]],
          preParserConfigs: {
            pageSeparatorsLengthPx: 60,
            separatorsDistanceModifier: 0.2,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата' }, nextWord: { in: 'Дата' } }],
                to: [{ word: { in: 'рахунку' }, prevWord: { in: 'Сума' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Операції' }, nextWord: { in: 'Дата' } }],
                  count: 1,
                },
                {
                  from: [{ word: { in: 'Поточні блокування по рахунку' } }],
                  count: 1,
                },
                {
                  from: [{ word: { in: 'Разом списання коштів' } }],
                  to: [{ prevWord: { in: 'Разом поповнень' } }],
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nрозрахунку', 1],
                split: { by: { regexp: { str: '\\s' } }, get: 0 },
              },
              comment: {
                column: ['Опис операції', 2],
                replaceOne: [
                  { from: { regexp: { str: '^[0-9]{6}' } }, to: '' },
                ],
              },
              currency: {
                column: ['Операція', 3],
                trim: true,
                split: { by: { regexp: { str: '\\s' } }, get: 0 },
              },
              sum: {
                or: [
                  { column: ['Сума в валюті', 4] },
                  {
                    column: ['Операція', 3],
                    trim: true,
                    split: { by: { regexp: { str: '\\s' } }, get: 1 },
                    add: [
                      ' ',
                      {
                        column: ['Операція', 3],
                        trim: true,
                        split: { by: { regexp: { str: '\\s' } }, get: 2 },
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          const body = [];
          let finded = false;
          importDocument.slice(1).forEach((arr) => {
            if (finded) {
              body.push(arr);
              return;
            }
            if (arr[0] === '' && arr[1] === '') {
              finded = true;
              body.push(arr);
            }
          });
          const result = [];
          let comments = [];
          let currentOp = [];
          const sumWithCurrencyAndComment = (str) => {
            if (!str) return { comment: '', sumWithCurrency: '' };
            let comment = '';
            let sumWithCurrency = '';
            let finish = false;
            const parts = str.split(' ');
            parts.forEach((part, i) => {
              if (finish) return;
              if (part.length === 3) {
                const rest = parts.slice(i + 1);
                const sum = rest.join('');
                const isNum = !isNaN(Number(sum));
                if (isNum) {
                  finish = true;
                  sumWithCurrency = `${part} ${sum}`;
                  return;
                }
              }
              comment += part + ' ';
            });
            return { comment, sumWithCurrency };
          };
          body.forEach((arr) => {
            if (moment(arr[0], 'DD.MM.YYYY', true).isValid()) {
              let lastComment = comments[comments.length - 1];
              if (currentOp.length) {
                currentOp[2] = comments.slice(0, -1).join('');
                result.push([...currentOp]);
                currentOp = [];
              }
              comments = [lastComment];
              currentOp[0] = arr[0];
              const sumWithCurrency = sumWithCurrencyAndComment(
                arr[3],
              ).sumWithCurrency;
              if (arr[1]) currentOp[1] = arr[1];
              if (sumWithCurrency) currentOp[3] = sumWithCurrency;
              if (arr[4]) currentOp[4] = arr[4].replaceAll(' ', '');
            } else {
              const sumWithCurrency = sumWithCurrencyAndComment(
                arr[3],
              ).sumWithCurrency;
              if (arr[1])
                currentOp[1] = currentOp[1] ? currentOp[1] + arr[1] : arr[1];
              if (sumWithCurrency) currentOp[3] = sumWithCurrency;
              if (arr[4])
                currentOp[4] = currentOp[4] ? currentOp[4] + arr[4] : arr[4];
            }
            let localComment = '';
            if (arr[2]) {
              localComment += arr[2] + ' ';
            }
            const comment = sumWithCurrencyAndComment(arr[3]).comment;
            if (comment) {
              localComment += comment + ' ';
            }
            if (localComment) {
              comments.push(localComment);
            }
          });
          currentOp[2] = comments.join('');
          result.push([...currentOp]);

          this.setDocumentBody(result);
          return (): ImportResultItemMask => {
            const sum = this.getFirstValidCellByColumn(['Сума в валюті', 4]);
            const [currency, ...sumArr] = (
              this.getFirstValidCellByColumn(['Операція', 3]) || ''
            )
              .trim()
              .split(/\s/);
            return {
              date: this.getFirstValidCellByColumn([
                'Дата\nоперації',
                0,
              ])?.split(/\s/)[0],
              dateOfPayment: this.getFirstValidCellByColumn([
                'Дата\nрозрахунку',
                1,
              ])?.split(/\s/)[0],
              sum: sum || sumArr.join(' '),
              comment: this.getFirstValidCellByColumn([
                'Опис операції',
                2,
              ])?.replace(/^[0-9]{6}/, ''),
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: {
            and: [
              [[[0, 1, 2, 3, 4], { eq: 'Виписказакартковимрахункомклієнта' }]],
              [
                [
                  [0, 1, 2, 3, 4, 5, 6],
                  { eq: 'Інформаціяпропоточнузаборгованістьпорахункуна' },
                ],
                [7, { dateFormat: 'DD.MM.YYYY' }],
              ],
            ],
          },
          preParserConfigs: {
            pageSeparatorsLengthPx: 60,
            separatorsDistanceModifier: 0.2,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата' }, nextWord: { in: 'Дата' } }],
                to: [{ word: { in: 'рахунку' }, prevWord: { in: 'Сума' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'рахунком' }, nextWord: { in: 'Дата' } }],
                  count: 1,
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'списання' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'поповнень' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { in: 'Поточні' }, nextWord: { in: 'блокування' } },
                  ],
                  to: [{ word: { in: 'рахунку' }, prevWord: { in: 'по' } }],
                },
                {
                  from: [{ word: { in: 'Операції' }, nextWord: { in: 'за' } }],
                  to: [
                    { word: { in: 'рахунком' }, prevWord: { in: 'картковим' } },
                  ],
                },
                {
                  from: [{ word: { in: 'Операції' }, nextWord: { in: 'за' } }],
                  to: [
                    {
                      word: { in: 'картками' },
                      prevWord: { in: 'платіжними' },
                    },
                  ],
                },
                {
                  from: [{ word: { in: 'Дата' }, nextWord: { in: 'Дата' } }],
                  to: [{ word: { in: 'рахунку' }, prevWord: { in: 'Сума' } }],
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'списання' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'картками' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'поповнень' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'картками' },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: { in: 'Вклади' },
                      nextWord: { in: 'гарантуються' },
                    },
                  ],
                  to: [{ word: { in: '.' }, prevWord: { in: 'ФОП' } }],
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: {
            and: [
              [[[0, 1, 2, 3, 4], { eq: 'Виписказакартковимрахункомклієнта' }]],
              {
                not: [
                  [
                    [0, 1, 2, 3, 4, 5, 6],
                    { eq: 'Інформаціяпропоточнузаборгованістьпорахункуна' },
                  ],
                  [7, { dateFormat: 'DD.MM.YYYY' }],
                ],
              },
            ],
          },
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            separatorsDistanceModifier: 0.2,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата' }, nextWord: { in: 'Дата' } }],
                to: [{ word: { in: 'рахунку' }, prevWord: { in: 'валюті' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'рахунком' }, nextWord: { in: 'Дата' } }],
                  count: 1,
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'списання' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'поповнень' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { in: 'Поточні' }, nextWord: { in: 'блокування' } },
                  ],
                  to: [{ word: { in: 'рахунку' }, prevWord: { in: 'по' } }],
                },
                {
                  from: [{ word: { in: 'Операції' }, nextWord: { in: 'за' } }],
                  to: [
                    { word: { in: 'рахунком' }, prevWord: { in: 'картковим' } },
                  ],
                },
                {
                  from: [{ word: { in: 'Операції' }, nextWord: { in: 'за' } }],
                  to: [
                    {
                      word: { in: 'картками' },
                      prevWord: { in: 'платіжними' },
                    },
                  ],
                },
                {
                  from: [{ word: { in: 'Дата' }, nextWord: { in: 'Дата' } }],
                  to: [{ word: { in: 'рахунку' }, prevWord: { in: 'валюті' } }],
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'списання' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'картками' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { in: 'Разом' }, nextWord: { in: 'поповнень' } },
                  ],
                  to: [
                    {
                      word: { isNum: true, replace: [{ from: ' ', to: '' }] },
                      prevWord: { in: 'картками' },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: { in: 'Вклади' },
                      nextWord: { in: 'гарантуються' },
                    },
                  ],
                  to: [{ word: { in: '.' }, prevWord: { in: 'ФОП' } }],
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nоперації', 0],
                split: { by: { regexp: { str: '\\s' } }, get: 0 },
              },
              dateOfPayment: {
                column: ['Дата\nрозрахунку', 1],
                split: { by: { regexp: { str: '\\s' } }, get: 0 },
              },
              sum: {
                or: [
                  { column: ['Сума у\nвалюті рахунку', 6] },
                  {
                    column: ['Сума у валюті\nтранзакції', 4],
                    trim: true,
                    replaceOne: [{ from: { regexp: { str: '\\s' } }, to: ' ' }],
                  },
                ],
              },
              comment: {
                column: ['Опис операції', 3],
                replaceOne: [
                  { from: { regexp: { str: '^[0-9]{6}' } }, to: '' },
                ],
              },
              currency: { column: ['Валюта\nтранзакції валюті рахунку', 5] },
            },
          },
        },
      },
      // case 8
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (moment(el[0], 'HH:mm', true).isValid()) {
              const lastIndex = body.length - 1;
              body[lastIndex][0] += `\n${el[0]}`;
              if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
              if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
              if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
              if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
              if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
            } else {
              body.push(el);
            }
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [0, { dateFormat: ': DD.MM.YYYY' }],
            [1, { eq: 'No' }],
            [2, { eq: 'Дата' }],
            [3, { eq: 'опер' }],
            [4, { eq: '.' }],
            [5, { eq: 'Дебет' }],
            [6, { eq: 'Кредит' }],
            [7, { eq: 'Реквізити' }],
          ],
          preParserConfigs: {
            separatorsDistanceModifier: 0.2,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'No' }, nextWord: { eq: 'Дата' } }],
                to: [
                  { word: { eq: 'документа' }, prevWord: { eq: 'платежу' } },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: { in: 'платежу' },
                      prevWord: { in: 'Призначення' },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [{ word: { in: 'Обороти:' } }],
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
        },
      },
      // case 4, case 9
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (moment(el[0], 'HH:mm', true).isValid()) {
              const lastIndex = body.length - 1;
              body[lastIndex][0] += `\n${el[0]}`;
              if (el[1]) body[lastIndex][1] += `\n${el[1]}`;
              if (el[2]) body[lastIndex][2] += `\n${el[2]}`;
              if (el[3]) body[lastIndex][3] += `\n${el[3]}`;
              if (el[4]) body[lastIndex][4] += `\n${el[4]}`;
              if (el[5]) body[lastIndex][5] += `\n${el[5]}`;
            } else {
              body.push(el);
            }
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            separatorsDistanceModifier: 0.7,
            rotate: true,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Дата опер.' } }],
                to: [{ word: { in: 'Призначення платежу' } }],
              },
              delete: [
                {
                  to: [
                    {
                      word: { in: 'платежу' },
                      prevWord: { in: 'Призначення' },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [{ word: { in: 'Обороти:' } }],
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'ЄДРПОУ',
              },
            ],
            [
              1,
              {
                eq: 'МФО',
              },
            ],
          ],
          proceedCaseConfig: {
            removeOnlyEmpty: true,
            fields: {
              dateAndTime: {
                column: ['Дата операцiї', 4],
                dateFormat: 'DD/MM/YYYY HH:mm',
              },
              debit: {
                column: ['Дебет', 13],
              },
              credit: {
                column: ['Кредит', 14],
              },
              comment: {
                column: ['Призначення платежу', 15],
              },
              counterparty: {
                column: ['Кореспондент', 10],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'ЄДРПОУ',
              },
            ],
            [
              1,
              {
                eq: 'МФО',
              },
            ],
          ],
          proceedCaseConfig: {
            removeOnlyEmpty: true,
            fields: {
              dateAndTime: {
                column: ['Дата операцiї', 4],
              },
              debit: {
                column: ['Дебет', 13],
              },
              credit: {
                column: ['Кредит', 14],
              },
              comment: {
                column: ['Призначення платежу', 15],
              },
              counterparty: {
                column: ['Кореспондент', 10],
              },
            },
          },
        },
      },
    ],
  };
}
