import { GET_ACTIVITIES_PENDING } from '../common/actions';
import { GET_CURRENCIES_PENDING } from '../currency/actions';
import { SetLanguagePayload } from './actions.types';
import apiCompany from './api';
import {
  ChangeCompanyPayload,
  CreateCompanyPayload,
  CreateWebhookPayload,
  UpdateWebhookPayload,
} from './sagas.types';
import { Company } from './types';

export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const CHANGE_COMPANY_PENDING = 'CHANGE_COMPANY_PENDING';

export const DELETE_COMPANY_PENDING = 'DELETE_COMPANY_PENDING';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';

export const GET_SETTINGS_CATEGORIES = 'GET_SETTINGS_CATEGORIES';

export const GET_COMPANIES_PENDING = 'GET_COMPANIES_PENDING';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';

export const CREATE_COMPANY_PENDING = 'CREATE_COMPANY_PENDING';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

export const CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING =
  'CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING';
export const CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS =
  'CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS';

export const CREATE_FIRST_COMPANY = 'CREATE_FIRST_COMPANY';
export const CREATE_FIRST_COMPANY_FULFILLED = 'CREATE_FIRST_COMPANY_FULFILLED';

export const UPDATE_GROUP_COMPANIES_PENDING = 'UPDATE_GROUP_COMPANIES_PENDING';

export const GET_COMPANIES_LIST_PENDING = 'GET_COMPANIES_LIST_PENDING';
export const GET_COMPANIES_LIST_SUCCESS = 'GET_COMPANIES_LIST_SUCCESS';

export const GET_COMPANIES_SETTINGS_LIST_PENDING =
  'GET_COMPANIES_SETTINGS_LIST_PENDING';
export const GET_COMPANIES_SETTINGS_LIST_SUCCESS =
  'GET_COMPANIES_SETTINGS_LIST_SUCCESS';

export const GET_SETTINGS_CONTEXTS = 'GET_SETTINGS_CONTEXTS';
export const GET_OPERATION_CONTEXTS = 'GET_OPERATION_CONTEXTS';

export const webHookTypes = {
  GET_WEBHOOKS_PENDING: 'GET_API_KEYS_PENDING',
  GET_WEBHOOKS_SUCCESS: 'GET_WEBHOOKS_SUCCESS',
  REMOVE_WEBHOOK_PENDING: 'REMOVE_WEBHOOK_PENDING',
  REMOVE_WEBHOOK_SUCCESS: 'REMOVE_WEBHOOK_SUCCESS',
  CREATE_WEBHOOK_PENDING: 'CREATE_WEBHOOK_PENDING',
  CREATE_WEBHOOK_SUCCESS: 'CREATE_WEBHOOK_SUCCESS',
  UPDATE_WEBHOOK_PENDING: 'UPDATE_WEBHOOK_PENDING',
  UPDATE_WEBHOOK_SUCCESS: 'UPDATE_WEBHOOK_SUCCESS',
};

export const GET_API_KEYS_PENDING = 'GET_API_KEYS_PENDING';
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';

export const REMOVE_API_KEY_PENDING = 'REMOVE_API_KEY_PENDING';
export const REMOVE_API_KEY_SUCCESS = 'REMOVE_API_KEY_SUCCESS';

export const CREATE_API_KEY_PENDING = 'CREATE_API_KEY_PENDING';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';

export const CREATE_DEMO_COMPANY = 'CREATE_DEMO_COMPANY';

const createFirstCompanyAction = (payload: CreateCompanyPayload) => ({
  type: CREATE_FIRST_COMPANY,
  payload: apiCompany.createFirstCompany(payload),
});

const getApiKeys = () => ({
  type: GET_API_KEYS_PENDING,
});

const removeApiKey = (id: string) => ({
  type: REMOVE_API_KEY_PENDING,
  payload: { id },
});

const createApiKey = (name: string) => ({
  type: CREATE_API_KEY_PENDING,
  payload: { name },
});

const getWebHooks = () => ({
  type: webHookTypes.GET_WEBHOOKS_PENDING,
});

const removeWebHook = (id: string) => ({
  type: webHookTypes.REMOVE_WEBHOOK_PENDING,
  payload: { id },
});

const createWebHook = (data: CreateWebhookPayload) => ({
  type: webHookTypes.CREATE_WEBHOOK_PENDING,
  payload: data,
});

const updateWebhook = (data: UpdateWebhookPayload) => ({
  type: webHookTypes.UPDATE_WEBHOOK_PENDING,
  payload: data,
});

const setLanguage = (data: SetLanguagePayload) => ({
  type: SET_LANGUAGE,
  payload: data,
});

const changeCompany = (data: ChangeCompanyPayload) => ({
  type: CHANGE_COMPANY_PENDING,
  payload: data,
});

const getCurrencies = () => ({ type: GET_CURRENCIES_PENDING });

const getActivities = () => ({ type: GET_ACTIVITIES_PENDING });

const getSettingsContexts = () => ({ type: GET_SETTINGS_CONTEXTS });

const getOperationContexts = () => ({ type: GET_OPERATION_CONTEXTS });

const createCompany = (data: CreateCompanyPayload) => ({
  type: CREATE_COMPANY_PENDING,
  payload: { ...data },
});

const createDemoCompany = (name: string, currencyId: string) => ({
  type: CREATE_DEMO_COMPANY,
  payload: apiCompany.createDemoCompany({ name, currencyId, isDemo: true }),
});

const deleteCompany = (id: string, history: any) => ({
  type: DELETE_COMPANY_PENDING,
  payload: { id, history },
});

const updateGroupCompanies = (companies: Company[]) => ({
  type: UPDATE_GROUP_COMPANIES_PENDING,
  payload: { companies },
});

const loadCompaniesList = () => ({ type: GET_COMPANIES_LIST_PENDING });
const loadCompaniesSettingsList = () => ({
  type: GET_COMPANIES_SETTINGS_LIST_PENDING,
});

const createCompanyOnOnboardingV2Flow = (
  name: string,
  currencyId: string,
  activityId?: string,
) => ({
  type: CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING,
  payload: { name, currencyId, activityId },
});

export default {
  getApiKeys,
  setLanguage,
  getWebHooks,
  removeApiKey,
  createApiKey,
  createCompany,
  changeCompany,
  removeWebHook,
  createWebHook,
  updateWebhook,
  getCurrencies,
  getActivities,
  deleteCompany,
  createDemoCompany,
  loadCompaniesList,
  getSettingsContexts,
  getOperationContexts,
  updateGroupCompanies,
  createFirstCompanyAction,
  createCompanyOnOnboardingV2Flow,
  loadCompaniesSettingsList,
};
