import { createSelector } from 'reselect';

import { OWNER_ROLE_ID } from '../../constants';
import {
  FuturePaymentsName,
  ReportName,
  SettingsName,
} from '../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import {
  getUserPermissions,
  selectIsOwner,
  selectUserRoleId,
} from '../../selectors/clientPermissions';
import { selectSettingsAccountsWithNoAccounts } from '../accounts/selectors';
import { AccountValue } from '../accounts/types';
import {
  selectSettingsConsumptionCategoriesWithGenericAndNoCategories,
  selectSettingsIncomeCategoriesWithGenericAndNoCategories,
} from '../categories/selectors';
import { Category } from '../categories/types';
import {
  selectSettingsBorrowers,
  selectSettingsClients,
  selectSettingsCreditors,
  selectSettingsInvestors,
  selectSettingsOwners,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsTaxOrganisations,
} from '../clients/selectors';
import { Client } from '../clients/types';
import {
  selectCompanyFastPermissions,
  selectCurrentCompany,
} from '../company/selectors';
import {
  selectDeletePermissionIds,
  selectEditPermissionIds,
  selectReadPermissionIds,
  selectResourcesLabelToId,
} from '../employees/selectors';
import {
  ContextRole,
  FastPermissions,
  Permission,
  PermissionResource,
} from '../employees/types';
import { selectSettingsProjects } from '../projects/selectors';
import { Project } from '../projects/types';
import { AppState } from '../reducers';
import { selectSettingsTags } from '../tags/selectors';
import { Tag } from '../tags/types';
import { OperationType } from '../operations/types';

export const getLanguage = (state: AppState) => state.company.lng;

export const IsPermissionsLoading = (state: AppState) =>
  state.auth.permissionsUpdating;
export const isAppLoading = (state: AppState) => state.auth.isAppLoading;

export const getUser = (state: AppState) => state.auth.user;
export const isLoading = (state: AppState) => state.auth.loading;

export const getCreatorId = createSelector(
  selectCurrentCompany,
  (company) => company?.createdBy ?? '',
);

export const selectUserCustomization = createSelector(getUser, (user) => {
  try {
    return user ? JSON.parse(user.customization) : {};
  } catch (e) {
    return {};
  }
});

export const selectUserCustomizationByCompany = (id: string) =>
  createSelector(
    selectUserCustomization,
    (customization) => customization[id] ?? {},
  );

export const selectUserLogCustomizationByCompany = (id: string) =>
  createSelector(
    selectUserCustomizationByCompany(id),
    (customization) => customization?.log,
  );

export const selectUserAnalyticCustomizationByCompany = (id: string) =>
  createSelector(
    selectUserCustomizationByCompany(id),
    (customization) => customization?.analytic,
  );

export const selectOnboardingV2Completed = createSelector(
  getUser,
  (user) => !!user?.onboardingCompleted,
);

export const selectIsCreator = createSelector(
  getUser,
  getCreatorId,
  (user, creatorId) => user?._id === creatorId,
);

export const selectFuturePaymentsTotalMonths = createSelector(getUser, (user) =>
  user && 'futurePaymentsTotalMonths' in user
    ? user.futurePaymentsTotalMonths
    : 1,
);

export const selectSettingsPermissions = createSelector(
  getUserPermissions,
  (permissions) =>
    permissions.find(
      (permission) => permission.context === ContextRole.settings,
    ),
);

export const selectCompanyTypeFastPermissions = createSelector(
  selectCompanyFastPermissions,
  selectReadPermissionIds,
  selectEditPermissionIds,
  selectDeletePermissionIds,
  selectUserRoleId,
  (
    fastPermissions,
    readPermissionIds,
    editPermissionIds,
    deletePermissionIds,
    roleId,
  ) => {
    if (!fastPermissions.length && roleId === OWNER_ROLE_ID) {
      return FastPermissions.full;
    }

    if (!fastPermissions.length) {
      return null;
    }

    let permissions: FastPermissions | null = null;

    const allowRead = readPermissionIds.every((el) =>
      fastPermissions.includes(el),
    );
    const allowEdit = editPermissionIds.every((el) =>
      fastPermissions.includes(el),
    );
    const allowDelete = deletePermissionIds.every((el) =>
      fastPermissions.includes(el),
    );

    if (allowRead && !allowEdit) {
      permissions = FastPermissions.read;
    } else if (allowEdit && !allowDelete) {
      permissions = FastPermissions.readWrite;
    } else if (allowDelete) {
      permissions = FastPermissions.delete;
    }

    return permissions;
  },
);

export const isFastPermissionsRead = createSelector(
  selectCompanyTypeFastPermissions,
  (permissions) => permissions === FastPermissions.read,
);

export const isFastPermissionsEdit = createSelector(
  selectCompanyTypeFastPermissions,
  (permissions) => permissions === FastPermissions.readWrite,
);

export const isFastPermissionsDelete = createSelector(
  selectCompanyTypeFastPermissions,
  (permissions) => permissions === FastPermissions.delete,
);

export const isFastPermissionsFull = createSelector(
  selectCompanyTypeFastPermissions,
  (permissions) => permissions === FastPermissions.full,
);

export const allowCreateAllIncomeCategories = createSelector(
  getUserPermissions,
  selectResourcesLabelToId,
  selectIsOwner,
  selectCompanyTypeFastPermissions,

  (permissions, resources, isOwner, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
      return true;
    }

    const settingsPermissions = permissions.find(
      (permission) => permission.context === ContextRole.operation,
    );

    const perm = settingsPermissions?.resources.find(
      (res) => res.resourceId === resources[SettingsName['categories/income']],
    );

    return Boolean(perm?.items?.length);
  },
);

export const allowRemoveCategory = (
  categoryId: string,
  operationType: OperationType,
) =>
  createSelector(
    getUserPermissions,
    selectResourcesLabelToId,
    selectIsOwner,
    selectCompanyTypeFastPermissions,
    selectDeletePermissionIds,

    (
      permissions,
      resources,
      isOwner,
      fastPermissionsType,
      deletePermissionIds,
    ) => {
      const settings =
        operationType === OperationType.income
          ? SettingsName['categories/income']
          : SettingsName['categories/consumption'];

      if (isOwner) {
        return true;
      }

      if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
        return true;
      }

      const settingsPermissions = permissions.find(
        (permission) => permission.context === ContextRole.operation,
      );

      const perm = settingsPermissions?.resources.find(
        (res) => res.resourceId === resources[settings],
      );

      const items = perm?.items;

      const allowRemove = items?.find((el) => el.resourceItemId === categoryId);

      return allowRemove?.typeIds.includes(deletePermissionIds[0]);
    },
  );

export const analyticItemEnable = (item: ReportName) =>
  createSelector(
    getUserPermissions,
    selectResourcesLabelToId,
    selectReadPermissionIds,
    selectIsOwner,
    selectCompanyTypeFastPermissions,
    (permissions, resources, allowIds, isOwner, fastPermissionsType) => {
      if (isOwner) {
        return true;
      }

      if (fastPermissionsType) {
        return true;
      }

      const analyticPermissions = permissions.find(
        (permission) => permission.context === ContextRole.analytic,
      );

      const perm = analyticPermissions?.resources.find(
        (res) => res.resourceId === resources[ReportName[item]],
      );

      return allowIds.every((el) => (perm?.typeIds ?? []).includes(el));
    },
  );

export const allowCreateAllConsumptionCategories = createSelector(
  getUserPermissions,
  selectResourcesLabelToId,
  selectIsOwner,
  selectCompanyTypeFastPermissions,
  (permissions, resources, isOwner, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
      return true;
    }

    const settingsPermissions = permissions.find(
      (permission) => permission.context === ContextRole.operation,
    );

    const perm = settingsPermissions?.resources.find(
      (res) =>
        res.resourceId === resources[SettingsName['categories/consumption']],
    );

    return Boolean(perm?.items?.length);
  },
);

export const getIsEditableSetting = (name: SettingsName) =>
  createSelector(
    selectIsOwner,
    getUserPermissions,
    selectResourcesLabelToId,
    selectEditPermissionIds,
    selectCompanyTypeFastPermissions,
    (
      isOwner,
      permissions,
      resources,
      editPermissionIds,
      fastPermissionsType,
    ) => {
      if (isOwner) {
        return true;
      }

      if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
        return true;
      }

      const settingsPermissions = permissions.find(
        (permission) => permission.context === ContextRole.settings,
      );

      const perm = settingsPermissions?.resources.find(
        (res) => res.resourceId === resources[name],
      );

      return editPermissionIds.every((el) => perm?.typeIds.includes(el));
    },
  );

export const isCalendarEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const calendarPermissions = permissions.find(
      (permission) => permission.context === ContextRole.calendar,
    );

    return allowIds.every((el) =>
      (calendarPermissions?.typeIds ?? []).includes(el),
    );
  },
);

export const isAccountsVisibilityEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const accountsVisibilityPermissions = permissions.find(
      (permission) => permission.context === ContextRole.accountsVisibility,
    );

    return allowIds.every((el) =>
      (accountsVisibilityPermissions?.typeIds ?? []).includes(el),
    );
  },
);

export const isFuturePaymentsEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const futurePaymentsPermissions = permissions.find(
      (permission) => permission.context === ContextRole.futurePayments,
    );

    return allowIds.every((el) =>
      (futurePaymentsPermissions?.typeIds ?? []).includes(el),
    );
  },
);

export const isLogEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const logPermissions = permissions.find(
      (permission) => permission.context === ContextRole.log,
    );

    return allowIds.every((el) => (logPermissions?.typeIds ?? []).includes(el));
  },
);

export const isAnalyticEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const logPermissions = permissions.find(
      (permission) => permission.context === ContextRole.analytic,
    );

    return allowIds.every((el) => (logPermissions?.typeIds ?? []).includes(el));
  },
);

export const isSettingsEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const logPermissions = permissions.find(
      (permission) => permission.context === ContextRole.settings,
    );

    return allowIds.every((el) => (logPermissions?.typeIds ?? []).includes(el));
  },
);

export const isOperationEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectEditPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
      return true;
    }

    const operationPermissions = permissions.find(
      (permission) => permission.context === ContextRole.operation,
    );

    return allowIds.every((el) =>
      (operationPermissions?.typeIds ?? []).includes(el),
    );
  },
);

export const isEmployeesEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const employeesPermissions = permissions.find(
      (permission) => permission.context === ContextRole.employees,
    );

    return allowIds.every((el) =>
      (employeesPermissions?.typeIds ?? []).includes(el),
    );
  },
);

export const isInvoicingEnable = createSelector(
  selectIsOwner,
  getUserPermissions,
  selectReadPermissionIds,
  selectCompanyTypeFastPermissions,
  (isOwner, permissions, allowIds, fastPermissionsType) => {
    if (isOwner) {
      return true;
    }

    if (fastPermissionsType) {
      return true;
    }

    const invoicingPermissions = permissions.find(
      (permission) => permission.context === ContextRole.invoicing,
    );

    return (
      allowIds.every((el) =>
        (invoicingPermissions?.typeIds ?? []).includes(el),
      ) || true
    );
  },
);

const selectClientsResources = createSelector(
  selectResourcesLabelToId,
  selectReadPermissionIds,
  selectEditPermissionIds,
  selectDeletePermissionIds,
  selectSettingsClients,
  selectSettingsInvestors,
  selectSettingsBorrowers,
  selectSettingsCreditors,
  selectSettingsSuppliers,
  selectSettingsStaffMembers,
  selectSettingsOwners,
  selectSettingsTaxOrganisations,
  (
    resourcesLabelToId,
    readPermissionIds,
    editPermissionIds,
    deletePermissionIds,
    clients,
    investors,
    borrowers,
    creditors,
    suppliers,
    staffMembers,
    owners,
    taxOrganisations,
  ) => {
    const createReadResource = (
      name: SettingsName,
      data: Client[],
    ): PermissionResource => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...readPermissionIds],
      })),
    });

    const createEditResource = (
      name: SettingsName,
      data: Client[],
    ): PermissionResource => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...editPermissionIds],
      })),
    });

    const createDeleteResource = (
      name: SettingsName,
      data: Client[],
    ): PermissionResource => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...deletePermissionIds],
      })),
    });

    const readResources: PermissionResource[] = [
      createReadResource(SettingsName.clients, clients),
      createReadResource(SettingsName.investors, investors),
      createReadResource(SettingsName.creditors, creditors),
      createReadResource(SettingsName.borrowers, borrowers),
      createReadResource(SettingsName.suppliers, suppliers),
      createReadResource(SettingsName.staffMembers, staffMembers),
      createReadResource(SettingsName.owners, owners),
      createReadResource(SettingsName.taxOrganisations, taxOrganisations),
    ];

    const editResources: PermissionResource[] = [
      createEditResource(SettingsName.clients, clients),
      createEditResource(SettingsName.investors, investors),
      createEditResource(SettingsName.creditors, creditors),
      createEditResource(SettingsName.borrowers, borrowers),
      createEditResource(SettingsName.suppliers, suppliers),
      createEditResource(SettingsName.staffMembers, staffMembers),
      createEditResource(SettingsName.owners, owners),
      createEditResource(SettingsName.taxOrganisations, taxOrganisations),
    ];

    const deleteResources: PermissionResource[] = [
      createDeleteResource(SettingsName.clients, clients),
      createDeleteResource(SettingsName.investors, investors),
      createDeleteResource(SettingsName.creditors, creditors),
      createDeleteResource(SettingsName.borrowers, borrowers),
      createDeleteResource(SettingsName.suppliers, suppliers),
      createDeleteResource(SettingsName.staffMembers, staffMembers),
      createDeleteResource(SettingsName.owners, owners),
      createDeleteResource(SettingsName.taxOrganisations, taxOrganisations),
    ];

    return {
      readResources,
      editResources,
      deleteResources,
    };
  },
);

export const initialOwnerPermissions = createSelector(
  selectResourcesLabelToId,
  selectReadPermissionIds,
  selectEditPermissionIds,
  selectDeletePermissionIds,
  selectSettingsAccountsWithNoAccounts,
  selectSettingsProjects,
  selectSettingsTags,
  selectSettingsIncomeCategoriesWithGenericAndNoCategories,
  selectSettingsConsumptionCategoriesWithGenericAndNoCategories,
  selectClientsResources,
  (
    resourcesLabelToId,
    readPermissionIds,
    editPermissionIds,
    deletePermissionIds,
    accounts,
    projects,
    tags,
    incomes,
    consumptions,
    clientsResources,
  ) => {
    const initialObject: Permission[] = [
      {
        context: ContextRole.settings,
        typeIds: [...readPermissionIds],
        resources: [
          {
            resourceId: resourcesLabelToId[SettingsName.accounts],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.projects],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.tags],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.clients],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.investors],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.creditors],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.borrowers],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.owners],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.suppliers],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.staffMembers],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.counterparties],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName['categories/income']],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId:
              resourcesLabelToId[SettingsName['categories/consumption']],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
          {
            resourceId: resourcesLabelToId[SettingsName.taxOrganisations],
            typeIds: [...readPermissionIds, ...editPermissionIds],
          },
        ],
      },
      {
        context: ContextRole.calendar,
        typeIds: [...readPermissionIds],
        resources: [],
      },
      {
        context: ContextRole.employees,
        typeIds: [...readPermissionIds],
        resources: [],
      },
      {
        context: ContextRole.futurePayments,
        typeIds: [...readPermissionIds],
        resources: Object.keys(FuturePaymentsName).map((el) => ({
          typeIds: [...readPermissionIds],
          resourceId: resourcesLabelToId[el as FuturePaymentsName],
        })),
      },
      {
        context: ContextRole.analytic,
        typeIds: [...readPermissionIds],
        resources: Object.keys(ReportName).map((el) => ({
          typeIds: [...readPermissionIds],
          resourceId: resourcesLabelToId[el as ReportName],
        })),
      },
    ];

    const createReadResource = (
      name: SettingsName,
      data: (AccountValue | Tag | Project | Category)[],
    ) => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...readPermissionIds],
      })),
    });

    const createEditResource = (
      name: SettingsName,
      data: (AccountValue | Tag | Project | Category)[],
    ) => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...editPermissionIds],
      })),
    });

    const createDeleteResource = (
      name: SettingsName,
      data: (AccountValue | Tag | Project | Category)[],
    ) => ({
      typeIds: [],
      resourceId: resourcesLabelToId[name],
      items: data.map((el) => ({
        resourceItemId: el._id,
        typeIds: [...deletePermissionIds],
      })),
    });

    initialObject.push({
      context: ContextRole.log,
      typeIds: [...readPermissionIds],
      resources: [
        createReadResource(SettingsName.accounts, accounts),
        createReadResource(SettingsName.projects, projects),
        createReadResource(SettingsName.tags, tags),
        createReadResource(SettingsName['categories/income'], incomes),
        createReadResource(
          SettingsName['categories/consumption'],
          consumptions,
        ),
        ...clientsResources.readResources,
      ],
    });

    initialObject.push({
      context: ContextRole.operation,
      typeIds: [...editPermissionIds],
      resources: [
        createEditResource(SettingsName.accounts, accounts),
        createEditResource(SettingsName.projects, projects),
        createEditResource(SettingsName.tags, tags),
        createEditResource(SettingsName['categories/income'], incomes),
        createEditResource(
          SettingsName['categories/consumption'],
          consumptions,
        ),
        ...clientsResources.editResources,
      ],
    });

    initialObject.push({
      context: ContextRole.log,
      typeIds: [...deletePermissionIds],
      resources: [
        createDeleteResource(SettingsName.accounts, accounts),
        createDeleteResource(SettingsName.projects, projects),
        createDeleteResource(SettingsName.tags, tags),
        createDeleteResource(SettingsName['categories/income'], incomes),
        createDeleteResource(
          SettingsName['categories/consumption'],
          consumptions,
        ),
        ...clientsResources.deleteResources,
      ],
    });

    return initialObject;
  },
);
