import { makeStyles } from '@material-ui/core/styles';

import { black } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  socialNetworkItem: {
    paddingTop: 18,
    paddingRight: 29,
    paddingBottom: 16,
    paddingLeft: 29,
    background: '#EEF3F5',
    borderRadius: 12,
    border: '1px solid #EEF3F5',
    cursor: 'pointer',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
    flexWrap: 'wrap',
  },
  socialNetworkImage: {
    display: 'block',
    margin: '0 auto',
  },
  socialNetworkTitle: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    opacity: 0.5,
  },
});
