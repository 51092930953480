import translationsCz from './cs/common.json';
import translationsEn from './en/common.json';
import translationsEs from './es/common.json';
import translationsFr from './fr/common.json';
import translationsIt from './it/common.json';
import translationsKk from './kk/common.json';
import translationsPl from './pl/common.json';
import translationsPt from './pt/common.json';
import translationsRu from './ru/common.json';
import translationsTr from './tr/common.json';
import translationsUa from './uk/common.json';
import translationsUz from './uz/common.json';
import translationsPtBr from './pt-br/common.json';

export enum LANGUAGES {
  ru = 'ru',
  en = 'en',
  uk = 'uk',
  pl = 'pl',
  es = 'es',
  fr = 'fr',
  pt = 'pt',
  it = 'it',
  cz = 'cz',
  tr = 'tr',
  kk = 'kk',
  uz = 'uz',
  'pt-BR' = 'pt-BR',
}

export class TranslationService {
  public getResources() {
    return {
      ru: { translation: translationsRu },
      en: { translation: translationsEn },
      uk: { translation: translationsUa },
      pl: { translation: translationsPl },
      es: { translation: translationsEs },
      fr: { translation: translationsFr },
      pt: { translation: translationsPt },
      it: { translation: translationsIt },
      cz: { translation: translationsCz },
      tr: { translation: translationsTr },
      kk: { translation: translationsKk },
      uz: { translation: translationsUz },
      'pt-BR': { translation: translationsPtBr },
    };
  }
}
