import { StyledProps } from '../../../../scenes/InfoBlock/Journal/JournalContainer/types';
import {
  aquaHaze,
  black,
  jade,
  persianGreen,
  regentStBlue,
  white,
} from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    marginBottom: 16,
    background: aquaHaze,
    borderRadius: 12,
    minHeight: 56,
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  container: {
    flex: 1,
    padding: '9px 16px',
  },
  selectedOptionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedOptionText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  noOptions: {
    cursor: 'pointer',
    color: jade,
  },
  labelText: {
    color: white,
    fontSize: 11,
    lineHeight: '14px',
  },
  chipRoot: {
    background: persianGreen,
    borderRadius: 6,
    padding: '6px 4px 6px 3px',
    height: 20,
    marginRight: 3,
    marginTop: 3,
    maxWidth: '100%',
  },
  closeIcon: {
    width: 10,
    height: 10,
  },
  placeholder: {
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    opacity: 0.5,
  },
  input: {
    height: 'auto',
    marginTop: 5,
    paddingLeft: 6,
  },
  popperRoot: {},
  popper: {
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    height: 216,
    marginBottom: 15,
    overflow: 'scroll',
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    top: (props: StyledProps) => props.topOffset,
  },
  noMessageText: {
    marginTop: 10,
    paddingLeft: 10,
    color: persianGreen,
  },
  paper: {
    height: 216,
    borderRadius: 12,
    marginTop: 8,
    marginBottom: 15,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
  },
  menuList: {
    overflow: 'scroll',
  },
  label: {
    top: 10,
    left: 12,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    opacity: 0.5,
  },
  disabledInput: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  compareRoot: {
    background: regentStBlue,
  },
  shortText: {
    display: 'block',
    width: '95%',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
});
