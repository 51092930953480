import { withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { StyledProps } from '../../scenes/InfoBlock/Journal/JournalContainer/types';
import { aquaHaze, white } from '../../theme/colors';

export const StyledAutocomplete = withStyles({
  popper: {
    maxHeight: 320,
    overflow: 'auto',
    borderRadius: 12,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    background: white,
    marginTop: (props: StyledProps) => (!props.disablePortal ? 16 : 0),
  },
  popperDisablePortal: {
    top: 60,
    left: 0,
    width: '100% !important',
    maxHeight: 320,
    borderRadius: 12,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    background: white,
  },
  inputRoot: {
    width: '100%',
    height: '100%',
    borderRadius: 12,
    padding: '0 16px',
  },
  option: {
    marginBottom: 14,
    padding: '14px 12px',
    marginLeft: 20,
    marginRight: 20,
    fontSize: 15,
    lineHeight: '20px',

    '&:hover': {
      borderRadius: 8,
      backgroundColor: aquaHaze,
    },
    '&[aria-selected="true"]': {
      backgroundColor: white,
      cursor: 'inherit',
    },
    '&[data-focus="true"]': {
      borderRadius: 8,
      background: aquaHaze,
    },
  },
  tag: {
    margin: 0,
  },
  paper: {
    boxShadow: 'none',
    height: '100%',
    margin: 0,
  },
  endAdornment: {
    right: 15,
  },
})(Autocomplete);
