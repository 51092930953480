import { AnyAction } from 'redux';

import { ONBOARDING_STEPS, OnboardingV2State } from '@finmap/core-constants';

import { onboardingActionTypes } from './actions';

const initialState: OnboardingV2State = {
  onboardingV2InProgress: false,
  onboardingSteps: [],
  showVideo: false,
};

const onboardingV2Reducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case onboardingActionTypes.INIT: {
      return { ...initialState };
    }

    case onboardingActionTypes.REMOVE_ONBOARDING_GREETING_STEP: {
      const onboardingSteps = state.onboardingSteps.filter(
        (el) => el.stepName !== ONBOARDING_STEPS.GREETINGS,
      );

      return { ...state, onboardingSteps };
    }

    case onboardingActionTypes.SET_SHOW_ONBOARDING_VIDEO: {
      return {
        ...state,
        showVideo: payload.showVideo,
      };
    }

    case onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS: {
      return {
        ...state,
        onboardingV2InProgress: payload.onboardingV2InProgress,
      };
    }

    case onboardingActionTypes.SET_ONBOARDING_STEP_INFO: {
      return {
        ...state,
        onboardingSteps: payload.onboardingSteps,
      };
    }

    default:
      return state;
  }
};

export default onboardingV2Reducer;
