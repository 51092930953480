import React from 'react';
import { Typography } from "@material-ui/core";
import cn from "classnames";

import { useStyles } from './styles';

export interface PooperEmptyBlockProps {
  onFooterClick?(): void;
  headerText?: string;
  bodyText?: string;
  footerText?: string;
  isActive?: boolean;
};

export function PooperEmptyBlockComponent({ 
  onFooterClick,
  bodyText,
  footerText,
  headerText,
  isActive = true,
 }: PooperEmptyBlockProps) {
  if(!isActive) return null;

  const classes = useStyles();
  return (
    <div className={cn(classes.popupRoot, classes.fullPaddingRoot)}>

      {headerText && <Typography className={classes.popupTitle}>
        {headerText.toUpperCase()}
      </Typography>}
      
      {bodyText && <Typography className={classes.popupDescription}>
        {bodyText}
      </Typography>}
      
      {footerText && <Typography className={classes.popupLink} onClick={onFooterClick}>
        {footerText}
      </Typography>}
      
    </div>
  );
}

export const PooperEmptyBlock = React.memo(PooperEmptyBlockComponent);

export default PooperEmptyBlock;
