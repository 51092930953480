import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class RevolutParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach(arr => {
            if(arr[0]) {
              const [D, MMM, YYYY] = arr[0].split(' ');
              const date = (new Date(`${D} ${MMM} ${YYYY}`)).toString();
              body.push([
                date,
                arr[1],
                arr[2] ? arr[2].substring(1) : undefined,
                arr[3] ? arr[3].substring(1) : undefined,
                arr[4]
              ]);
            } else {
              const lastIndex = body.length - 1;
              body[lastIndex][1] += arr[1];
              if(!body[lastIndex][2]) {
                body[lastIndex][2] = arr[2].substring(1);
              }
              if(!body[lastIndex][3]) {
                body[lastIndex][3] = arr[3].substring(1);
              }
              body[lastIndex][4] += arr[4];
            }
          })

          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            return {
              date: this.getFirstValidCellByColumn(['Date (UTC)', 0]),
              comment: this.getFirstValidCellByColumn(['Description', 1]),
              credit: this.getFirstValidCellByColumn(['Money in', 3]),
              debit: this.getFirstValidCellByColumn(['Money out', 2]),
            };
          };
        },
        caseOptions: {
          // for example
          defaultCurrency: 'USD',
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 10,
            verticalAlign: 'top',
            // for example
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => (word === 'Date (UTC)' && etc?.nextWord === 'Description'),
                (word, etc) => (word === 'Balance' && etc?.prevWord === 'Money in'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => (word === 'Balance' && etc?.prevWord === 'Money in'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word === 'Report lost or stolen card',
                undefined,
                1,
              );
              self.defineOperation([
                (value) => true//moment(value, 'D MMM YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
