import moment from 'moment';

import { BasePdfParser } from '@finmap/import-parsers/base-pdf-parser';
import {
  AnyObject,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

import example from './example.json';

export class EcoCenterBankPdfParser extends BasePdfParser {
  public setRawData(raw: any, account: any): void {
    super.setRawData(example, account);
  }

  protected COLUMNS_MAP = {
    date: ['Дата'],
    num: ['№ док'],
    bin: ['БИН/ИИН'],
    bic: ['БИК корр.'],
    counterpartyAccount: ['Счет корр.'],
    counterparty: ['Корреспондент'],
    debit: ['Расход'],
    credit: ['Приход'],
    comment: ['Назначение платежа'],
  };

  protected COORDINATES = [
    1.0, 4.0, 7.0, 10.0, 14.0, 20.0, 25.0, 33.0, 39.0, 100,
  ];

  protected importName = 'ecoCenter';

  protected DATE_FORMAT = 'DD.MM.YYYY';

  protected MULTY_FIELDS = ['counterparty', 'comment'];

  protected currency;

  protected doBeforeTranform() {
    let currencyRes;
    let over = false;
    const lines = Object.keys(this.sheetJson[0]).map(
      (key) => this.sheetJson[0][key],
    );
    for (let j = 0; j < lines.length; j++) {
      const line = lines[j];
      const arr = line.map(({ text }) => text);
      for (let i = 0; i < arr.length; i++) {
        const text = arr[i];
        const textIban = text.split(' ');
        const prev = lines[j - 1] ? lines[j - 1][0] : undefined;
        if (!textIban || !textIban[1] || !prev) continue;
        for (const currency of CURRENCIES) {
          if (
            textIban[1] === currency.code &&
            prev?.text?.includes('За период')
          ) {
            currencyRes = currency.code;
            over = true;
            break;
          }
        }
        if (over) break;
      }
      if (over) break;
    }
    this.currency = currencyRes;
    return super.doBeforeTranform();
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const {
      date,
      num,
      bin,
      bic,
      counterpartyAccount,
      counterparty,
      debit,
      credit,
      comment,
    } = operation;
    const errors = this.getErrors();
    const correctDate = this.dateParser(date);
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(correctDate, this.DATE_FORMAT).isAfter(
        moment().add(10, 'y'),
      );
      isBefore = moment(correctDate, this.DATE_FORMAT).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const parsedDebit = this.parseSum(debit);
    const parsedCredit = this.parseSum(credit);
    const parsedSum = parsedDebit > parsedCredit ? parsedDebit : parsedCredit;
    if (isNaN(parsedSum))
      this.throwError(
        `${parsedDebit > parsedCredit ? debit : credit} - ${
          errors.sumNotValid
        }`,
        index,
      );
    const now = moment();
    const dateResult = moment(correctDate)
      .startOf('day')
      .add(now.seconds(), 'second')
      .add(now.minutes(), 'minute')
      .add(now.hours(), 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    const cur = CURRENCIES.find((cur) => cur.code === this.currency)?.code;

    const result: any = {
      index,
      date: dateResult,
      comment: comment?.join(' '),
      sum: parsedSum,
      counterparty: counterparty?.join(' '),
    };
    if (cur) result.currency = cur;

    if (parsedDebit > parsedCredit) {
      result.type = OperationType.CONSUMPTION;
      result.subType = 'supplier';
      result.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      result.type = OperationType.INCOME;
      result.subType = 'sale';
      result.accountToId = this.mainAccount.normalizedLabel;
    }

    this.setAccountIDsByType(result, cur, cur);
    return result;
  }
}
