import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';

type Props = {
  onClose(): void;
  isOpened: boolean;
  onSuccess(number: string, name: string): void;
};

function BelvoCredsDialog(props: Props) {
  const { onClose, onSuccess, isOpened } = props;

  const [hasNumberError, setHasNumberError] = useState<boolean | null>(null);
  const [numberValue, setNumberValue] = useState<null | string>(null);
  const [nameValue, setNameValue] = useState<null | string>(null);

  const handleSetNumberValue = useCallback(
    (value: string) => {
      const isValid = !isNaN(Number(value));
      setHasNumberError(!isValid);
      if(isValid) {
        setNumberValue(value);
      }
    }, []);

  const handleSetNameValue = useCallback(
    (value: string) => {
      setNameValue(value);
  }, []);

  const handleCreateConnection = useCallback(
    () => {
      if(numberValue && nameValue) {
        onSuccess(numberValue, nameValue);
      }
  }, [onSuccess, numberValue, nameValue]);

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        isOpened={isOpened}
        onClose={onClose}
        title={t('integrations.belvo.yourCredentials')}
      >
        <TextFieldComponent
          value={numberValue}
          onChange={handleSetNumberValue}
          fullWidth
          type={'text'}
          placeholder={'Ej. 12345678901'}
          isError={hasNumberError === true}
          errorText={t('integrations.belvo.numberValidationError')}
        />

        <TextFieldComponent
          value={nameValue}
          onChange={handleSetNameValue}
          fullWidth
          type={'text'}
          placeholder={t('integrations.belvo.name')}
          isError={nameValue === ''}
          errorText={t('integrations.belvo.emptyValidationError')}
        />

        <CustomButton
          disabled={hasNumberError || numberValue === null || numberValue === '' || nameValue === '' || nameValue === null}
          title={t('bank.set')}
          action={handleCreateConnection}
          fullWidth
        />
      </Dialog>
    </>
  );
}

export default React.memo(BelvoCredsDialog);
