import { createSelector } from 'reselect';

import { OWNER_ROLE_ID } from '../constants';
import { selectCurrentCompany } from '../store/company/selectors';
import { AppState } from '../store/reducers';

export const getUserPermissions = (state: AppState) =>
  state.auth.userPermissions;

export const selectUserRoleId = createSelector(
  selectCurrentCompany,
  (company) => company?.roleId || null,
);

export const selectIsOwner = createSelector(
  selectUserRoleId,
  (roleId) => roleId === OWNER_ROLE_ID,
);
