import { makeStyles } from '@material-ui/core/styles';

import { flamingo } from '../../theme/colors';

export const useStyles = makeStyles({
  buttonTextColor: {
    color: flamingo,
  },
  buttonBackground: {
    background: 'rgba(255, 99, 51, 0.1)',
  },
});
