import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginRight: 15,
  },
  downloadText: {
    fontSize: 16,
    lineHeight: '24px',
    color: persianGreen,
  },
});
