import Http from '../../services/Http';
import { Context } from '../types';

const getProjects = (context: Context) =>
  Http.makeInstance().get(`/projects?context=${context}`);

const createProject = (label: string, context: Context) =>
  Http.makeInstance().post(`/projects?context=${context}`, {
    label,
  });

const updateProject = (id: string, label: string) =>
  Http.makeInstance().patch(`/projects/${id}`, {
    label,
  });

const updatePropProject = (id: string, data: any) =>
  Http.makeInstance().patch(`/projects/${id}?isBulkUpdate=true`, {
    ...data,
  });

const deleteProject = (id: string) =>
  Http.makeInstance().delete(`/projects/${id}`);

export default {
  getProjects,
  createProject,
  updateProject,
  deleteProject,
  updatePropProject,
};
