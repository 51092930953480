import { makeStyles } from '@material-ui/core/styles';

import {black, woodsmoke} from '../../theme/colors';

export const useStyles = makeStyles({
  button: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
  buttonText: {
    fontWeight: 700,
    color: black,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: '36px',
    color: woodsmoke,
  },
  description: {
    color: black,
    opacity: 0.5,
    marginBottom: 24,
  },
  fullWidth: {
    flex: 1,
  },
  icon: {
    marginRight: 32,
  }
});
