import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../../../../assets/images/svg/close-24-24.svg';
import DownloadIcon from '../../../../assets/images/svg/download.svg';
import RemoveDialog from '../../../../components/RemoveDialog/RemoveDialog';
import { Attachment } from '../../../../store/operations/types';
import { useStyles } from './styles';

type Props = {
  attachment?: Attachment;
  width: number;
  fileName: string;
  completed?: boolean;
  onRemove(id?: string): void;
  onDownload(attachment?: Attachment): void;
};

function RenderFileInfo(props: Props) {
  const {
    onDownload,
    onRemove,
    completed,
    fileName,
    width,
    attachment,
  } = props;

  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const classes = useStyles({ width });

  const { t } = useTranslation();

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleDownload = useCallback(() => {
    if (attachment) {
      onDownload(attachment);
    } else {
      onDownload();
    }
  }, [onDownload, attachment]);

  const handleRemove = useCallback(() => {
    if (attachment) {
      onRemove(attachment._id);

      handleCloseRemoveDialog();
    } else {
      onRemove();
    }
  }, [onRemove, attachment, handleCloseRemoveDialog]);

  return (
    <>
      <div className={classes.file}>
        <div
          className={cn(classes.fileWrapper, completed && classes.fullWidth)}
        >
          <div className={classes.fileInfo}>
            <img
              src={DownloadIcon}
              alt="download"
              className={classes.smallDownloadIcon}
              onClick={handleDownload}
            />
            <Typography className={cn(classes.smallText, classes.fileName)}>
              {fileName}
            </Typography>
          </div>
        </div>
        <div
          className={classes.closeIconContainer}
          onClick={handleShowRemoveDialog}
        >
          <img src={CloseIcon} alt="close" className={classes.smallCloseIcon} />
        </div>
      </div>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveAttachment"
          name={t('operation.removeDialogText.attachment').toLowerCase()}
          onRemove={handleRemove}
          onClose={handleCloseRemoveDialog}
        />
      )}
    </>
  );
}

export default React.memo(RenderFileInfo);
