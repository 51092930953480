import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PayoneerParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'USD',
          isCurCase: [
            [
              0,
              {
                eq: 'Transactions',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Date',
                    },
                    nexWord: {
                      eq: 'Description',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Running Balance',
                    },
                    prevWord: {
                      eq: 'Status',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Date',
                      },
                      nexWord: {
                        eq: 'Description',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD MMM, YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date', 0],
              },
              sum: {
                column: ['Amount', 2],
              },
              comment: {
                column: ['Description', 1],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'USD',
          withoutEmpty: true,
          preParserConfigs: {
            CSVDelimiter: ',',
            isCurCase: [
              [
                0,
                {
                  eq: 'Date',
                },
              ],
              [
                1,
                {
                  eq: 'Description',
                },
              ],
            ],
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date', 0],
              },
              sum: {
                column: ['Amount', 2],
              },
              comment: {
                column: ['Description', 1],
              },
            },
          },
        },
      },
    ],
  };
}
