import { Typography } from "@material-ui/core";
import cn from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from "../../../services/accounts";
import fondyApis from '../../../services/bankIntegrations/fondy';
import { IntegrationProps } from '../../../services/bankIntegrations/fondy.types';
import Storages, { StorageKey } from "../../../services/Storages";
import { selectLogAccountsWithoutNotSpecifiedAccount } from "../../../store/accounts/selectors";
import { AccountValue } from '../../../store/accounts/types';
import integrationActions from '../../../store/integrations/actions';
import { IntegrationTypeId } from '../../../store/integrations/types';
import FondyNotReadyDialog from "../FondyDialog/InfoDialogs/FondyNotReadyDialog";
import { useStyles } from './FondyAuth2SecondDialog.styles';
import { Props } from './FondyAuth2SecondDialog.types';

function FondyAuth2SecondDialog(props: Props) {
  const { onClose } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const facebookContext = useContext(FacebookPixelContext);

  const [transferAccount, setTransferAccount] = useState<AccountValue | null>(
    null,
  );
  const [fondyAccount, setFondyAccount] = useState<AccountValue | null>(null);
  const [
    loadingIntegrationCredentials,
    setLoadingIntegrationCredentials,
  ] = useState(false);

  const [errors, setErrors] = useState({
    transferAccount: false,
    fondyAccount: false,
    integrationData: false,
  });
  const [showFondyNotReadyDialog, setShowFondyNotReadyDialog] = useState(false);

  const accounts = useSelector(selectLogAccountsWithoutNotSpecifiedAccount);

  const availableTransferAccounts = useMemo(() => {
    if (!transferAccount) {
      return accounts;
    }

    return accounts.filter((el) => el._id !== transferAccount._id);
  }, [transferAccount, accounts]);

  const availableFondyAccounts = useMemo(() => {
    if (!fondyAccount) {
      return accounts;
    }

    return accounts.filter((el) => el._id !== fondyAccount._id);
  }, [fondyAccount, accounts]);

  const handleClose = useCallback(() => {
    Storages.remove(StorageKey.fondy);
    onClose();
  }, [onClose]);
  const handleCloseFondyNotReadyDialog = useCallback(() => {
    setShowFondyNotReadyDialog(false);
  }, []);

  const handleSetTransferAccount = useCallback((value) => {
    setTransferAccount(value);

    setErrors((values) => ({ ...values, transferAccount: false }));
  }, []);

  const handleSetFondyAccount = useCallback((value) => {
    setFondyAccount(value);

    setErrors((values) => ({ ...values, fondyAccount: false }));
  }, []);

  const handleCreate = useCallback(async () => {
    if (!fondyAccount) {
      setErrors((values) => ({ ...values, fondyAccount: true }));

      return;
    }

    if (transferAccount) {
      setLoadingIntegrationCredentials(true);

      try {
        const fondyIntegration = Storages.get(StorageKey.fondy);
        const { newAccountName, fondyAccountId, startDate } = fondyIntegration;

        const {
          data: integrationData,
        }: {
          data: IntegrationProps;
        } = await fondyApis.updateCredentials(
          transferAccount._id,
          fondyIntegration.code,
        );

        if (integrationData.credentialsId && fondyAccount) {
          dispatch(
            integrationActions.setFondyIntegrationId(
              integrationData.credentialsId,
            ),
          );

          let accountId = fondyAccountId;

          if (newAccountName) {
            const {
              data: { newAccount },
            } = await accountsApi.createAccount(
              'EUR',
              newAccountName,
              0,
              IntegrationTypeId.FondyQ2,
            );

            accountId = newAccount._id;
          }

          dispatch(
            integrationActions.createIntegration({
              accountId,
              startDate,
              bankName: 'Fondy',
              typeId: IntegrationTypeId.FondyQ2,
              facebookContext,
              isFondyQ2: true,
            }),
          );
        }

        setLoadingIntegrationCredentials(false);
        handleClose();
      } catch (error) {
        const status = error?.response?.status;

        if (status === 500) {
          setShowFondyNotReadyDialog(true);
        }

        setLoadingIntegrationCredentials(false);
      }
    }
  }, [
    dispatch,
    handleClose,
    fondyAccount,
    facebookContext,
    transferAccount,
  ]);

  useEffect(() => {
    const fondyIntegration = Storages.get(StorageKey.fondy);

    const selectedFondyAccount = accounts.find(
      (el) => el._id === fondyIntegration.fondyAccountId,
    );

    if (selectedFondyAccount) {
      setFondyAccount(selectedFondyAccount);
    }
  }, [accounts]);

  if (showFondyNotReadyDialog) {
    return (
      <FondyNotReadyDialog
        onClose={handleCloseFondyNotReadyDialog}
      />
    );
  }

  return (
    <Dialog onClose={handleClose} isOpened title={t('integrations.fondyAuth2.title')}>
      <AutocompleteSelector
        rootClassName={classes.selector}
        label={t('integrations.fondy.fondyAccount')}
        onChange={handleSetFondyAccount}
        // @ts-ignore
        data={availableTransferAccounts}
        // @ts-ignore
        value={fondyAccount}
        error={errors.transferAccount}
      />
      <Typography className={cn(classes.helpText, classes.commonMargin)}>
        {t('bank.fondy.accountDescription')}
      </Typography>
      <AutocompleteSelector
        rootClassName={classes.selector}
        label={t('integrations.fondy.transferAccount')}
        onChange={handleSetTransferAccount}
        // @ts-ignore
        data={availableFondyAccounts}
        // @ts-ignore
        value={transferAccount}
        error={errors.fondyAccount}
      />
      <Typography className={cn(classes.helpText, classes.lastMargin)}>
        {t('bank.fondy.transferDescription')}
      </Typography>
      <CustomButton
        title={t('integrations.fondy.connect')}
        action={handleCreate}
        loading={loadingIntegrationCredentials}
        fullWidth
        errorText={
          (errors.integrationData &&
            t('integrations.error.fondyIntegrationError')) ||
          ''
        }
      />
    </Dialog>
  );
}

export default React.memo(FondyAuth2SecondDialog);
