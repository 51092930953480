export enum RULE_ENTITIES {
  PROJECT = 'project',
  TAG = 'tag',
  CATEGORY_INCOME = 'category-income',
  CATEGORY_CONSUMPTION = 'category-consumption',
  INVESTOR = 'investor',
  CREDITOR = 'creditor',
  DEBITOR = 'debitor',
  SUPPLIER = 'supplier',
  EMPLOYEE = 'employee',
  OWNER = 'owners',
  TAX_ORGANISATION = 'tax-organisation',
  CLIENT = 'client',
}

export enum RULE_CONDITIONS {
  IN = 'IN',
  NIN = 'NIN',
  EQ = 'EQ',
  NEQ = 'NEQ',
}

export enum RULE_LOGIC {
  AND = 'AND',
  OR = 'OR',
}

export enum RULE_VARS {
  COMMENT = 'comment',
  ACCOUNT = 'account',
  //TYPE = 'type',
}

export enum RULE_VARS_EXTENDED {
  COMMENT = 'comment',
  ACCOUNT_FROM = 'accountFromId',
  ACCOUNT_TO = 'accountToId',
  TYPE = 'type',
}

export enum RULE_DISACTIVATION_REASONS {
  RIGHTS = 'There are not enough rights to work',
  ENTITY_DELETED = 'The entity has been deleted',
}
