import { Tooltip } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import { useStyles } from './styles';
import { Props } from './types';

function CustomTooltip(props: Props) {
  const {
    title,
    onOpen,
    onClose,
    children,
    disabled,
    arrowClasses,
    defaultOpened,
    disablePortal = true,
    interactive = false,
    placement = 'top-start',
    tooltipContainerClasses,
  } = props;
  const classes = useStyles();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(!!defaultOpened);

  const handleOpen = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!defaultOpened) {
      setTooltipIsOpen(true);
    }

    if (onOpen) {
      onOpen();
    }
  }, [onOpen, defaultOpened, disabled]);

  const handleClose = useCallback(() => {
    if (!defaultOpened) {
      setTooltipIsOpen(false);
    }

    if (onClose) {
      onClose();
    }
  }, [onClose, defaultOpened]);

  return (
    <Tooltip
      arrow
      open={tooltipIsOpen}
      placement={placement}
      title={title}
      onOpen={handleOpen}
      onClose={handleClose}
      interactive={interactive}
      classes={{
        tooltip: cn(classes.tooltipContainer, tooltipContainerClasses),
        arrow: cn(classes.arrow, arrowClasses),
      }}
      PopperProps={{ disablePortal }}
    >
      {children}
    </Tooltip>
  );
}

export default React.memo(CustomTooltip);
