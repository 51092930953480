import { TranslationService } from '@finmap/core-translations';
import { normalize } from '@finmap/core-utils';

import Storages, { StorageKey } from '../../../../../services/Storages';

export function findSubTypeInTranslation(name: string, type: string) {
  const translationsService = new TranslationService();
  const language = Storages.get(StorageKey.language);

  let result = false;

  // @ts-ignore
  const translations = translationsService.getResources()[language];

  const subTypes = translations?.translation?.accounts?.[type];

  for (const sybType in subTypes) {
    if (normalize(subTypes[sybType]) === normalize(name)) {
      result = true;
    }
  }

  return result;
}
