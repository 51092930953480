import UnleashContext, {
  UnleashContextProps,
} from '../Providers/UnleashProvider/UnleashContext';
import { useContext } from 'react';

export default function useUnleashGetVariant(featureToggleName: string) {
  const unleashContext: UnleashContextProps | null = useContext(UnleashContext);

  return unleashContext?.unleash?.getVariant(featureToggleName);
}
