import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  footer: {
    '@media (min-width: 768px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  buttonAccept: {
    width: 170,
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
    borderRadius: 12,
    marginRight: 20,
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  buttonClear: {
    width: 170,
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: 12,
    '@media (max-width: 768px)': {
      width: '100%',
      marginBottom: 16,
    },
  },
});
