import {
  OperationSubType,
  OperationType,
} from '../../../../store/operations/types';
import { CustomPeriodEnum } from '../../HOC/types';

export type Props = {
  type: OperationType;
  subType: OperationSubType;
  incomeDate: number;
  isCompare: boolean;
  isEdit: boolean;
  difference: boolean;
  maxDate?: Date;
  minDate?: Date;
  dateOfPayment: number;
  startTimestamp: number;
  endTimestamp: number;
  periodId: CustomPeriodEnum | null;
  isCustomCategory: boolean;

  onChangeDateOfPayment(value: number): void;
  onChangeDateOfPaymentPeriod(
    startTimestamp: number,
    endTimestamp: number,
  ): void;
  onChangePeriodId(id: CustomPeriodEnum): void;
};

export enum DayOfPaymentVariant {
  singleDay = 'singleDay',
  period = 'period',
}
