import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  lastBlock: {
    marginBottom: 18,
  },
  dialog: {
    borderRadius: 24,
  },
  separator: {
    marginBottom: 16,
  },
  informerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 0,
    marginBottom: 14,
  },
  informerIcon: {
    marginRight: 8,
    paddingTop: 2,
  },
  informerText: {
    opacity: 0.5,
    lineHeight: '20px',
  },
});
