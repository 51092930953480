import currenciesApi from './api';
import {
  CreateCustomCurrencyRatesPayload,
  RemoveCustomCurrencyRatesPayload,
} from './sagas.types';

export const RESET_RATES = 'RESET_RATES';

export const GET_CURRENCY_PROVIDERS_PENDING = 'GET_CURRENCY_PROVIDERS_PENDING';
export const GET_CURRENCY_PROVIDERS_SUCCESS = 'GET_CURRENCY_PROVIDERS_SUCCESS';
export const GET_CURRENCY_PROVIDERS_FAILED = 'GET_CURRENCY_PROVIDERS_FAILED';

export const GET_DEFAULT_CURRENCY_PROVIDER_PENDING =
  'GET_DEFAULT_CURRENCY_PROVIDER_PENDING';
export const GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS =
  'GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS';
export const GET_DEFAULT_CURRENCY_PROVIDER_FAILED =
  'GET_DEFAULT_CURRENCY_PROVIDER_FAILED';

export const UPDATE_DEFAULT_CURRENCY_PROVIDER_PENDING =
  'UPDATE_DEFAULT_CURRENCY_PROVIDER_PENDING';
export const UPDATE_DEFAULT_CURRENCY_PROVIDER_SUCCESS =
  'UPDATE_DEFAULT_CURRENCY_PROVIDER_SUCCESS';
export const UPDATE_DEFAULT_CURRENCY_PROVIDER_FAILED =
  'UPDATE_DEFAULT_CURRENCY_PROVIDER_FAILED';

export const GET_CURRENCIES_PENDING = 'GET_CURRENCIES_PENDING';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILED = 'GET_CURRENCIES_FAILED';

export const GET_EXCHANGE_RATE_FROM_TO_PENDING =
  'GET_EXCHANGE_RATE_FROM_TO_PENDING';
export const GET_EXCHANGE_RATE_FROM_TO_SUCCESS =
  'GET_EXCHANGE_RATE_FROM_TO_SUCCESS';

export const GET_EXCHANGE_RATE_PENDING = 'GET_EXCHANGE_RATE_PENDING';
export const GET_EXCHANGE_RATE_SUCCESS = 'GET_EXCHANGE_RATE_SUCCESS';

export const GET_CUSTOM_CURRENCY_RATES_PENDING =
  'GET_CUSTOM_CURRENCY_RATES_PENDING';
export const GET_CUSTOM_CURRENCY_RATES_SUCCESS =
  'GET_CUSTOM_CURRENCY_RATES_SUCCESS';

export const REMOVE_CUSTOM_CURRENCY_RATES_PENDING =
  'REMOVE_CUSTOM_CURRENCY_RATES_PENDING';
export const CREATE_CUSTOM_CURRENCY_RATES_PENDING =
  'CREATE_CUSTOM_CURRENCY_RATES_PENDING';

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_FULFILLED = 'GET_CURRENCIES_FULFILLED';
export const GET_CURRENCIES_REJECTED = 'GET_CURRENCIES_REJECTED';

const getCurrencyProviders = () => ({
  type: GET_CURRENCY_PROVIDERS_PENDING,
});

const getDefaultCurrencyProvider = () => ({
  type: GET_DEFAULT_CURRENCY_PROVIDER_PENDING,
});

const updateDefaultCurrencyProvider = (providerId: string) => ({
  type: UPDATE_DEFAULT_CURRENCY_PROVIDER_PENDING,
  payload: { providerId },
});

const getCustomCurrencyRates = () => ({
  type: GET_CUSTOM_CURRENCY_RATES_PENDING,
});

const removeCustomCurrencyRates = ({
  from,
  to,
}: RemoveCustomCurrencyRatesPayload) => ({
  type: REMOVE_CUSTOM_CURRENCY_RATES_PENDING,
  payload: { from, to },
});

const createCustomCurrencyRates = ({
  from,
  to,
  rate,
}: CreateCustomCurrencyRatesPayload) => ({
  type: CREATE_CUSTOM_CURRENCY_RATES_PENDING,
  payload: { from, to, rate },
});

const getCurrencies = () => ({
  type: GET_CURRENCIES,
  payload: currenciesApi.getCurrencies(),
});

export default {
  getCurrencies,
  getCurrencyProviders,
  getCustomCurrencyRates,
  createCustomCurrencyRates,
  removeCustomCurrencyRates,
  getDefaultCurrencyProvider,
  updateDefaultCurrencyProvider,
};
