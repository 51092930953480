import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ForteBankImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      // case 4
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isCurCase: {
            simple: [[0, { in: 'Выписка по счету' }]],
          },
          isDESCOpOrder: true,
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 3,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  { word: { in: 'Дата операции' }, nextWord: { in: 'Дата' } },
                ],
                to: [{ word: { in: '(+/-)' }, nextWord: { in: '******' } }],
              },
              delete: [
                {
                  to: [{ word: { in: '******' }, prevWord: { in: '(+/-)' } }],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: { in: 'Заблокированные/ожидающие списания суммы:' },
                      nextWord: { in: 'Дата операции' },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'YYY-MM-DD' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата операции', 0],
                replace: [{ from: '\n', to: ' ' }],
              },
              sum: {
                column: ['Сумма\nоперации в\nвалюте\nсчета\n(+/-)', 4],
                split: { by: ' ', get: 0 },
              },
              currency: {
                column: ['Сумма\nоперации в\nвалюте\nсчета\n(+/-)', 4],
                split: { by: ' ', get: 1 },
              },
              comment: { column: ['Описание операции', 2] },
            },
          },
        },
      },
      // case 1 2 3
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 3,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    or: [
                      { word: { startsWith: '№' } },
                      { word: { startsWith: 'No' } },
                    ],
                  },
                  {
                    or: [
                      { nextWord: { in: 'Дата' } },
                      { nextWord: { in: 'Күні' } },
                    ],
                  },
                ],
                to: [
                  {
                    or: [
                      { word: { in: 'Курс' }, prevWord: { in: 'Төлемнің' } },
                      {
                        word: { in: 'ИИК, БИН/ИИН)' },
                        prevWord: { in: 'ИИК, БИН/ИИН)' },
                      },
                    ],
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      or: [
                        {
                          or: [
                            {
                              word: { in: 'Purpose of payment' },
                              nextWord: { regexp: { str: '^(\\s*\\d+\\s*)$' } },
                            },
                            {
                              word: { in: 'Курс' },
                              nextWord: { regexp: { str: '^(\\s*\\d+\\s*)$' } },
                            },
                          ],
                        },
                        {
                          word: { in: 'ИИК, БИН/ИИН)' },
                          prevWord: { in: 'ИИК, БИН/ИИН)' },
                        },
                      ],
                    },
                  ],
                  count: 1,
                },
                {
                  from: [{ word: { startsWith: 'Айналым' } }],
                  count: 1,
                },
              ],
              define: [
                { regexp: { str: '[\\d ]+,[\\d]+' } },
                { regexp: { str: '[\\d ]+,[\\d]+' } },
                { regexp: { str: '[\\d ]+,[\\d]+' } },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                or: [
                  {
                    column: ['Дата', 1],
                    if: {
                      dateFormat: [
                        { column: ['Дата', 1], split: { by: '\n', get: 0 } },
                        'DD.MM.YYYY',
                      ],
                    },
                    replace: [{ from: '\n', to: ' ' }],
                  },
                  {
                    column: ['Дата', 1],
                    if: {
                      ndateFormat: [
                        { column: ['Дата', 1], split: { by: '\n', get: 0 } },
                        'DD.MM.YYYY',
                      ],
                    },
                    split: { by: '\n', get: 0 },
                    add: [
                      { column: ['Дата', 1], split: { by: '\n', get: 1 } },
                      ' ',
                      { column: ['Дата', 1], split: { by: '\n', get: 2 } },
                      { column: ['Дата', 1], split: { by: '\n', get: 3 } },
                    ],
                  },
                ],
              },
              counterpartyIncome: {
                column: ['Отправитель (Наименование,', 3],
                replaceOne: [{ from: { regexp: { str: 'БИН(.){0,}', flags: 's' } }, to: '' }],
              },
              counterpartyConsumption: {
                column: ['Получатель (Наименование,', 4],
                replaceOne: [{ from: { regexp: { str: 'БИН(.){0,}', flags: 's' } }, to: '' }],
              },
              debit: { column: ['Дебет /', 5] },
              credit: { column: ['Кредит /', 6] },
              comment: { column: ['Төлемнің', 7] },
            },
          },
        },
      },
    ],
  };
}
