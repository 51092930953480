import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    '&:nth-child(2n + 1)': {
      marginRight: 28,
    },
  },
  block: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: 12,
    width: 192,
    height: 48,
  },
  blockContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  icon: {
    marginRight: 12,
    width: 24,
    height: 24,
    objectFit: 'contain'
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  text: {
    marginTop: 28,
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 16,
  },
  subTitle: {
    fontWeight: 'bold',
    marginBottom: 21,
  },
  button: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
});
