import { localToUtc, UTCtoDate } from '../../../../utils/dateToUTC';
import { CustomPeriodEnum } from '../types';

type Props = {
  periodId: CustomPeriodEnum | null;
  incomeDate: number;
  startTimestamp: number;
  endTimestamp: number;
};

type CustomProps = {
  periodId: CustomPeriodEnum | null;
  incomeDate: number | null;
  dateOfPayment: number;
  startTimestamp: number;
  endTimestamp: number;
};

function getTaxTimeStamps(data: Props) {
  const {
    startTimestamp,
    endTimestamp,
    periodId: taxPeriodId,
    incomeDate,
  } = data;

  let taxPeriodStartTimestamp;
  let taxPeriodEndTimestamp;

  if (taxPeriodId === CustomPeriodEnum.prevMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    taxPeriodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .startOf('month')
      .valueOf();
    taxPeriodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .endOf('month')
      .valueOf();
  } else if (taxPeriodId === CustomPeriodEnum.currentMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    taxPeriodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .startOf('month')
      .valueOf();
    taxPeriodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .endOf('month')
      .valueOf();
  } else {
    taxPeriodStartTimestamp = startTimestamp;
    taxPeriodEndTimestamp = endTimestamp;
  }

  return {
    taxPeriodEndTimestamp,
    taxPeriodStartTimestamp,
  };
}

function getSalaryTimeStamps(data: Props) {
  const {
    incomeDate,
    endTimestamp,
    startTimestamp,
    periodId: salaryPeriodId,
  } = data;

  let salaryPeriodStartTimestamp;
  let salaryPeriodEndTimestamp;

  if (salaryPeriodId === CustomPeriodEnum.prevMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    salaryPeriodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .startOf('month')
      .valueOf();
    salaryPeriodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .endOf('month')
      .valueOf();
  } else if (salaryPeriodId === CustomPeriodEnum.currentMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    salaryPeriodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .startOf('month')
      .valueOf();
    salaryPeriodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .endOf('month')
      .valueOf();
  } else {
    salaryPeriodStartTimestamp = startTimestamp;
    salaryPeriodEndTimestamp = endTimestamp;
  }

  return {
    salaryPeriodEndTimestamp,
    salaryPeriodStartTimestamp,
  };
}

function getCustomTimeStamps(data: CustomProps) {
  const { dateOfPayment, startTimestamp, endTimestamp, incomeDate, periodId } =
    data;

  let periodStartTimestamp;
  let periodEndTimestamp;
  let newDateOfPayment = dateOfPayment;

  if (periodId === CustomPeriodEnum.prevMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    periodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .startOf('month')
      .valueOf();
    periodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .subtract(1, 'month')
      .endOf('month')
      .valueOf();
    newDateOfPayment = 0;
  } else if (periodId === CustomPeriodEnum.currentMonth) {
    const incomeDateWithoutUtc = UTCtoDate(incomeDate);
    periodStartTimestamp = localToUtc(incomeDateWithoutUtc)
      .startOf('month')
      .valueOf();
    periodEndTimestamp = localToUtc(incomeDateWithoutUtc)
      .endOf('month')
      .valueOf();
    newDateOfPayment = 0;
  } else if (periodId === CustomPeriodEnum.custom) {
    periodStartTimestamp = startTimestamp;
    periodEndTimestamp = endTimestamp;
    newDateOfPayment = 0;
  } else {
    periodStartTimestamp = 0;
    periodEndTimestamp = 0;
  }

  return { newDateOfPayment, periodStartTimestamp, periodEndTimestamp };
}

export default {
  getTaxTimeStamps,
  getSalaryTimeStamps,
  getCustomTimeStamps,
};
