import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ProCreditBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата операции', 4]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 10]),
            comment: this.getFirstValidCellByColumn([
              'Призначення платежу',
              15,
            ]),
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
