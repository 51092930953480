import { TextField, Typography } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { StyledAutocomplete } from '../../../../components/AutocompleteComponent/StyledAutocomplete';
import TransitionComponent from '../../../../components/TransitionComponent';
import { MAX_INPUT_LENGTH } from '../../../../constants';
import usePermissions from '../../../../hooks/usePermissions';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { AccountValue } from '../../../../store/accounts/types';
import { getOperationProps } from '../../../../store/operations/selectors';
import { persianGreen } from '../../../../theme/colors';
import { useCompareStyles } from '../compareStyles';
import { useStyles } from './styles';
import { Props } from './types';

const StyledAutocompleteComponent = StyledAutocomplete as any;
const CheckIconComponent = CheckIcon as any;

function AccountSelector(props: Props) {
  const {
    error,
    value,
    testId,
    disabled,
    accounts,
    onChange,
    isCompare,
    difference,
    placeholder,
    disablePortal = true,
    isEditableAccounts = true,
    transferFromIntegrated = false,
  } = props;

  const { botId, externalId, integrationId } = useSelector(getOperationProps);

  const { t } = useTranslation();
  const classes = useStyles({
    isError: error,
  });
  const compareClasses = useCompareStyles();
  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const handleChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: any) => {
      onChange(newValue);
    },
    [onChange],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        className={cn(isCompare && compareClasses.disabledInput)}
        label={
          value && (placeholder || t('operationDialogs.accountTo.inputLabel'))
        }
        placeholder={placeholder || t('operationDialogs.accountTo.inputLabel')}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        inputProps={{
          ...params.inputProps,
          maxLength: MAX_INPUT_LENGTH,
        }}
      />
    ),
    [value, placeholder, t, isCompare, compareClasses, classes],
  );

  const renderOption = useCallback(
    (option: AccountValue, { selected }: AutocompleteRenderOptionState) => {
      if (selected) {
        return (
          <div className={classes.selectedOptionContainer}>
            <Typography
              className={cn(classes.selectedOptionText, classes.shortText)}
              title={option.name}
            >
              {option.name}
            </Typography>
            <CheckIconComponent color={persianGreen} />
          </div>
        );
      }

      return (
        <Typography className={cn(classes.shortText)} title={option.name}>
          {option.name}
        </Typography>
      );
    },
    [classes],
  );

  const getOptionLabel = useCallback(
    (option: AccountValue) => option.label || option.name || '',
    [],
  );

  const getOptionSelected = useCallback(
    (option: AccountValue, optionValue: AccountValue) =>
      optionValue && option._id === optionValue._id,
    [],
  );

  return (
    <div
      className={cn(
        classes.root,
        difference && compareClasses.root,
        (!subscriptionActive || !operationEnable || isCompare) &&
          classes.disabledInput,
      )}
    >
      <div className={classes.container}>
        <StyledAutocompleteComponent
          id={testId || 'accounts'}
          disabled={
            disabled ||
            !isEditableAccounts ||
            !accounts?.length ||
            isCompare ||
            !subscriptionActive ||
            !operationEnable ||
            (!!externalId &&
              !!integrationId &&
              !botId &&
              !transferFromIntegrated)
          }
          value={value}
          selectOnFocus
          clearOnEscape
          disablePortal={disablePortal}
          options={accounts || []}
          // @ts-ignore
          getOptionLabel={getOptionLabel}
          // @ts-ignore
          getOptionSelected={getOptionSelected}
          renderInput={renderInput}
          onChange={handleChange}
          // @ts-ignore
          renderOption={renderOption}
        />
      </div>
      <TransitionComponent
        text={t('system.fieldMustFilled')}
        enter={Boolean(error)}
      />
    </div>
  );
}

export default React.memo(AccountSelector);
