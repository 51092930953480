import { makeStyles } from '@material-ui/core/styles';
import { black, flamingo } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  button: {
    flex: 1,
    background: 'rgba(0, 0, 0, 0.06)',
  },
  buttonText: {
    fontWeight: 'bold',
  },
  buttonTextFlamingo: {
    color: flamingo,
  },
  buttonTextBlack: {
    color: black,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    opacity: 0.5,
    marginBottom: 20,
    whiteSpace: 'pre-line',
  },
});
