import {
  BasePDFPreParser,
  searchFuncForDefiners,
} from '@finmap/import-parsers/base-import-parser-v3';

const MAX_WORDS_ADD = 50;
const MAX_PX_BETWEEN_LINES = 20;
const LINES_INTERLINE_ACCURACY = 5;
const MAX_PX_BEFORE_ADD = 20;

export class CenterCreditBankPDFPreParser extends BasePDFPreParser {
  defineOperation(definers: searchFuncForDefiners[]) {
    super.defineOperation(definers);
    const lastHeaderElement =
      this.documentHeader[this.documentHeader.length - 1];
    this.documentHeader.push({
      str: 'additionalData',
      width: 0,
      height: 0,
      x: lastHeaderElement.x,
      y: lastHeaderElement.y,
      hasEOL: false,
    });
    for (let i = 0; i < this.documentBody.length; i++) {
      const firstColValue = this.documentBody[i][0];
      const firstColValueElIndex = this.rawDocument.findIndex(
        (el) => el.x <= this.separatorsBorders[0] && el.str === firstColValue,
      );
      if (firstColValueElIndex === -1) continue;
      let divider = MAX_PX_BETWEEN_LINES;
      let lastY;
      const firstColValueEl = this.rawDocument[firstColValueElIndex];
      const arrLength = this.documentBody[i].length;
      for (let j = arrLength; j < MAX_WORDS_ADD; j++) {
        const element = this.rawDocument[firstColValueElIndex + j];
        if (j === arrLength) {
          this.documentBody[i].push('');
          if (element.y - MAX_PX_BEFORE_ADD > firstColValueEl.y) break;
          divider = element.y - firstColValueEl.y;
          lastY = element.y;
          this.documentBody[i][arrLength] += element.str;
        } else {
          if (element.y - divider - LINES_INTERLINE_ACCURACY > lastY) break;
          this.documentBody[i][arrLength] +=
            (element.y === lastY ? ' ' : '\n') + element.str;
          lastY = element.y;
        }
      }
    }
  }
}
