import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import moment from 'moment';

export class KapitalbankParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      // case 2
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          const rawDocument = (await preParser.getRawData(
            await file.arrayBuffer(),
          )) as string[][];
          if (
            rawDocument?.length < 2 ||
            !rawDocument[0][4] ||
            !rawDocument[1][4]
          )
            return false;
          const [za, from, po, to] = rawDocument[1][4].split(' ');
          return (
            rawDocument[0][4] === 'Выписка лицевых счетов' &&
            za === 'за' &&
            po === 'по' &&
            moment(from, 'DD.MM.YYYY', true).isValid() &&
            moment(to, 'DD.MM.YYYY', true).isValid()
          );
        },
        proceedCase: (importDocument) => {
          const header = importDocument[9];
          const body = importDocument
            .slice(10)
            .filter((el) => moment(el[1], 'DD.MM.YYYY', true).isValid());
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата\r\nдокумента', 1]),
            debit: this.getFirstValidCellByColumn(['Обороты по\r\nдебету', 8]),
            credit: this.getFirstValidCellByColumn([
              'Обороты по\r\nкредиту',
              9,
            ]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 10]),
          });
        },
        caseOptions: { defaultCurrency: 'UZS' },
      },
      {
        proceedCase: (importDocument) => {
          const header = [];
          const headerRows = [];
          let rowNum = -1;
          const body = [];
          for (let i = 0; i < importDocument.length; i++) {
            const row = importDocument[i];
            if (row.includes('№ пп')) {
              row.forEach((e, i) => {
                if (!e && rowNum === -1) return;
                if (e) {
                  rowNum++;
                  headerRows[rowNum] = [i];
                  header.push(e);
                } else {
                  headerRows[rowNum].push(i);
                }
              });
            } else if (header.length) {
              const arr = [];
              row.forEach((e, i) => {
                if (e) {
                  let headerCount;
                  headerRows.forEach((el, ix) => {
                    if (el.includes(i)) {
                      headerCount = ix;
                    }
                  });
                  if (headerCount !== undefined) {
                    if (arr[headerCount]) {
                      arr[headerCount] += e;
                    } else {
                      arr[headerCount] = e;
                    }
                  }
                }
              });
              if (arr.length && arr[4]) body.push(arr);
            }
          }

          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата документа', 0]),
            debit: this.getFirstValidCellByColumn(['Обороты по дебету', 6]),
            credit: this.getFirstValidCellByColumn(['Обороты по кредиту', 7]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
          });
        },
        caseOptions: { defaultCurrency: 'UZS' },
      },
    ],
  };
}
