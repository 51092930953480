import axios from 'axios';

import { GEO_LOCATION_URL } from '../../constants';
import Http, { Http as UIHttp } from '../../services/Http';
import { getTimeOffset } from '../../utils/dateToUTC';

const getCurrencies = () => Http.makeInstance().get('/common/currencies');

const getCryptoCurrencies = () =>
  Http.makeInstance().get('/common/cryptocurrencies');

const getExchangeRateFromTo = (from: string, to: string) =>
  Http.makeInstance().get(`/common/currencyExchangeRates/${from}/${to}`);

const createCustomExchangeRateFromTo = (
  from: string,
  to: string,
  rate: number,
) =>
  Http.makeInstance().post('/common/currencyExchangeRates/custom', {
    from,
    to,
    rate,
  });

const deleteCustomExchangeRateFromTo = (from: string, to: string) =>
  Http.makeInstance().delete(
    `/common/currencyExchangeRates/custom/${from}/${to}`,
  );

const getCustomExchangeRates = () =>
  Http.makeInstance().get('/common/currencyExchangeRates/custom/all');

const getSchedulePeriods = () =>
  Http.makeInstance().get('/common/schedulePeriods');

const getVersionUI = () =>
  new UIHttp(true)
    .makeInstance()
    .get(`/version.json?timestamp=${new Date().getTime() + getTimeOffset()}`);

const getVersion = () => Http.makeInstance().get('/common/version');

const getActivities = () =>
  Http.makeInstance().get('/common/companyActivities');

const getTariffs = () => Http.makeInstance().get('/tariffs');

const getCountryCodes = async (country: string) => {
  const { data } = await axios.get(
    `https://restcountries.eu/rest/v2/name/${country}?fullText=true`,
  );

  return data[0];
};

const getGeoLocationData = async () => {
  const defaultGeo = { data: { country: 'UA' } };
  try {
    const result = await axios.get(GEO_LOCATION_URL);
    return result;
  } catch (e) {
    return defaultGeo;
  }
};

const getServerTime = () => Http.makeInstance().get('common/time');

const sendDeepLinkStatus = (token: string, hasBearer: boolean) =>
  Http.makeInstance().post('/common/linkStatus', { token, hasBearer });

const requestToSetupAndImplement = () =>
  Http.makeInstance().post('/common/activity');

export default {
  getTariffs,
  getVersion,
  getVersionUI,
  getCurrencies,
  getActivities,
  getServerTime,
  getCountryCodes,
  getGeoLocationData,
  getSchedulePeriods,
  sendDeepLinkStatus,
  getCryptoCurrencies,
  getExchangeRateFromTo,
  getCustomExchangeRates,
  requestToSetupAndImplement,
  deleteCustomExchangeRateFromTo,
  createCustomExchangeRateFromTo,
};
