import InfoIcon from '../../../../assets/images/svg/info.svg';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import pumbApi from '../../../../services/bankIntegrations/pumb';
import { PumbAccount } from '../../../../store/integrations/types';
import { showError } from '../../../../utils/showError';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Ga4Errors
} from '../../../../components/TextField/TextFieldComponent.types';

type Props = {
  onClose(): void;
  onSetAccounts(accounts: PumbAccount[], integrationId: string): void;
};

function LoginDialog(props: Props) {
  const { onClose, onSetAccounts } = props;

  const classes = useStyles();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleChangeLogin = useCallback((value) => {
    setLogin(value);
    setLoginError(false);
  }, []);

  const handleChangePassword = useCallback((value) => {
    setPassword(value);
    setPasswordError(false);
  }, []);

  const handleClick = useCallback(async () => {
    setLoginErrorText('');

    if (!login) {
      setLoginError(true);
      return;
    }

    if (!password) {
      setPasswordError(true);
      return;
    }

    setLoading(true);

    try {
      const {
        data: { accounts, integrationId },
      } = await pumbApi.getAccounts({ login, password });

      onSetAccounts(accounts, integrationId);
    } catch (error) {
      setLoginErrorText(t('errors.loginPasswordError'));
      showError(error);
    }

    setLoading(false);
  }, [t, login, password, onSetAccounts]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('accounts.yourLoginAndPassword')}
    >
      <TextFieldComponent
        ga4Id={Ga4Errors.loginError}
        value={login}
        onChange={handleChangeLogin}
        fullWidth
        placeholder={t('bank.loginBank')}
        isError={loginError}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        ga4Id={Ga4Errors.loginPasswordError}
        type="password"
        value={password}
        onChange={handleChangePassword}
        fullWidth
        placeholder={t('loginPage.password')}
        isError={passwordError}
        errorText={t('errors.reqField')}
      />
      <div className={classes.footer}>
        <img src={InfoIcon} alt="info" className={classes.infoIcon} />
        <Typography className={classes.footerText}>
          {t('bank.pumb.informer')}
        </Typography>
      </div>
      <CustomButton
        action={handleClick}
        fullWidth
        title={t('bank.set')}
        loading={loading}
        errorText={loginErrorText}
      />
    </Dialog>
  );
}

export default React.memo(LoginDialog);
