import Http from '../../services/Http';

const createWebHook = (name: string, url: string) =>
  Http.makeInstance().post('/webhooks', {
    name,
    url,
  });

const updateWebHook = (id: string, name: string, url: string) =>
  Http.makeInstance().patch(`/webhooks/${id}`, {
    name,
    url,
  });

const getWebHooks = () => Http.makeInstance().get('/webhooks');

const deleteWebHook = (id: string) =>
  Http.makeInstance().delete(`/webhooks/${id}`);

export default {
  createWebHook,
  updateWebHook,
  getWebHooks,
  deleteWebHook,
};
