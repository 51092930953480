import Http from '../../services/Http';
import { LogRequestBody } from './types';

const getJournalObj = (logReqObj: LogRequestBody) => {
  return Http.makeInstance().post('operations/log', logReqObj);
};

const getJournal = (
  query: string,
  ordersQuery: string,
  customFiltersQuery: string,
  offset: number,
  limit: number,
) =>
  Http.makeInstance().post('operations/log', {
    query: `offset=${offset}&limit=${limit}&${query}&${ordersQuery}&${customFiltersQuery}`,
  });

const getJournalByQuery = (query: string) =>
  Http.makeInstance().post('operations/log', { query });

const getOperationsCount = () =>
  Http.makeInstance().get('operations/log/count');

const getPrediction = (id: string) =>
  Http.makeInstance().get(`operations/log/predictions/${id}`);

export default {
  getJournal,
  getJournalObj,
  getPrediction,
  getJournalByQuery,
  getOperationsCount,
};
