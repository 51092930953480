import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PKOBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          encoding: 'win1251',
          preParserConfigs: {
            CSVDelimiter: ',',
          },
          isCurCase: {
            useBuffer: [
              [
                0,
                {
                  in: '"Typ transakcji","Kwota"',
                },
              ],
            ],
          },
          proceedCaseConfig: {
            fields: {
              currency: {
                column: ['Waluta', 4],
              },
              date: {
                column: ['Data operacji', 0],
              },
              sum: {
                column: ['Kwota', 3],
              },
              comment: {
                or: [
                  {
                    column: ['Opis transakcji', 6],
                  },
                  {
                    column: [7],
                  },
                ],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          isCurCase: [
            [
              0,
              {
                eq: 'Data księgowania',
              },
            ],
            [
              1,
              {
                eq: 'Data waluty',
              },
            ],
            [
              2,
              {
                eq: 'Nadawca / Odbiorca',
              },
            ],
            [
              3,
              {
                eq: 'Adres nadawcy / odbiorcy',
              },
            ],
          ],
          preParserConfigs: {
            CSVDelimiter: ';',
            simpleParse: true,
          },
          proceedCaseConfig: {
            fields: {
              currency: {
                column: ['Waluta', 4],
              },
              date: {
                column: ['Data księgowania', 0],
              },
              counterparty: {
                column: ['Nadawca / Odbiorca', 2],
              },
              comment: {
                column: ['Tytułem', 6],
              },
              sum: {
                column: ['Kwota operacji', 7],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          withoutEmpty: true,
          encoding: 'win1251',
          preParserConfigs: {
            CSVDelimiter: ',',
            simpleParse: true,
            isCurCase: [
              [
                0,
                {
                  eq: 'Data operacji',
                },
              ],
              [
                1,
                {
                  eq: 'Data waluty',
                },
              ],
              [
                2,
                {
                  eq: 'Typ transakcji',
                },
              ],
              [
                3,
                {
                  eq: 'Opis transakcji',
                },
              ],
            ],
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Data operacji', 0],
                dateFormat: 'YYYY.MM.DD',
              },
              comment: {
                or: [
                  {
                    column: ['Opis transakcji', 3],
                    if: {
                      isNum: [
                        {
                          column: ['Kwota', 4],
                        },
                        true,
                      ],
                    },
                  },
                  {
                    column: [5],
                  },
                ],
              },
              sum: {
                or: [
                  {
                    column: [4],
                    if: {
                      isNum: [
                        {
                          column: [4],
                        },
                        true,
                      ],
                    },
                  },
                  {
                    column: [6],
                  },
                ],
              },
            },
          },
        },
      },
    ],
  };
}
