import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import usePermissions from '../../../hooks/usePermissions';
import { SettingsName } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { selectIsOwner } from '../../../selectors/clientPermissions';
import {
  selectCompanyTypeFastPermissions,
  selectSettingsPermissions,
} from '../../../store/auth/selectors';
import { FastPermissions } from '../../../store/employees/types';

function useGetIsEditable() {
  const isOwner = useSelector(selectIsOwner);
  const fastPermissionsType = useSelector(selectCompanyTypeFastPermissions);
  const { resources, editPermissionIds } = usePermissions();
  const settingsPermissions = useSelector(selectSettingsPermissions);

  const isEditable = useCallback(
    (label: SettingsName) => {
      if (isOwner) {
        return true;
      }

      if (fastPermissionsType && fastPermissionsType !== FastPermissions.read) {
        return true;
      }

      const setPermissions = settingsPermissions?.resources.find(
        (res) => res.resourceId === resources[label],
      );

      return editPermissionIds.every((el) =>
        setPermissions?.typeIds.includes(el),
      );
    },
    [
      settingsPermissions,
      editPermissionIds,
      isOwner,
      resources,
      fastPermissionsType,
    ],
  );

  return { isEditable };
}

export default useGetIsEditable;
