import 'moment/locale/ru';
import {
  ClickAwayListener,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarIcon from '../../../../assets/images/svg/calendar.svg';
import TransitionComponent from '../../../../components/TransitionComponent';
import { USE_CALENDAR_AIRBNB_VERSION } from '../../../../constants/featureToggles/featureToggle';
import usePermissions from '../../../../hooks/usePermissions';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import useUnleash from '../../../../hooks/useUnleash';
import Storages, { StorageKey } from '../../../../services/Storages';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import { useCompareStyles } from '../compareStyles';
import CalendarAirbnbVersion from './CalendarAirbnbVersion';
import { useStyles } from './CalendarComponent.styles';
import { Props } from './CalendarComponent.types';
import CalendarDialog from './CalendarDialog';
import utils from './utils';

const language = Storages.get(StorageKey.language);

let locale = language;

if (language === 'cz') {
  locale = 'cs';
} else if (language === 'uz') {
  locale = 'uz-latn';
}

moment.locale(locale);

function CalendarComponent(props: Props) {
  const {
    value,
    error,
    testId,
    minDate,
    maxDate,
    onChange,
    errorText,
    fullWidth,
    isCompare,
    difference,
    selectRange,
    placeholder,
    fromIntegration,
    injectedRootClasses,
  } = props;
  const classes = useStyles({
    isError: Boolean(error),
  });
  const compareClasses = useCompareStyles();

  const { t } = useTranslation();
  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const useCalendarAirbnbVersion = useUnleash(USE_CALENDAR_AIRBNB_VERSION);

  const CalendarDialogComponent = useCalendarAirbnbVersion
    ? CalendarAirbnbVersion
    : CalendarDialog;

  const [isOpen, setOpen] = useState(false);

  const handleOpenCalendar = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClickAway = useCallback(() => {
    if (!useCalendarAirbnbVersion) {
      setOpen(false);
    }
  }, [useCalendarAirbnbVersion]);

  const handleCloseCalendar = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickCalendar = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      if (!isCompare && subscriptionActive && operationEnable) {
        handleOpenCalendar();
      }
    },
    [isCompare, handleOpenCalendar, subscriptionActive, operationEnable],
  );

  const handleChangeCalendar = useCallback(
    (date: number | number[]) => {
      onChange(date);

      handleCloseCalendar();
    },
    [onChange, handleCloseCalendar],
  );

  const diff = getTimeOffset(value);

  const dateValue = value ? new Date(value - diff) : new Date();

  if (fromIntegration) {
    const time = new Date(value);
    const timeHH = time.getUTCHours();
    const timeMM = time.getUTCMinutes();

    dateValue.setHours(timeHH);
    dateValue.setMinutes(timeMM, 0, 0);
  }

  const textDateRow = value && utils.formatSingleDate(value);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        id={testId}
        className={cn(
          classes.root,
          error && classes.errorBackground,
          difference && compareClasses.root,
          (!subscriptionActive || isCompare || !operationEnable) &&
            classes.disabledInput,
          injectedRootClasses,
        )}
        onClick={handleClickCalendar}
      >
        <div className={classes.container} data-at-id="date-input">
          <TextField
            disabled={isCompare || !subscriptionActive || !operationEnable}
            fullWidth={fullWidth}
            className={cn(isCompare && compareClasses.disabledInput)}
            label={value && placeholder}
            placeholder={placeholder}
            value={textDateRow || ''}
            InputProps={{
              readOnly: true,
              style: {
                cursor: 'pointer',
                flexGrow: 1,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={cn(
                    classes.icon,
                    isCompare && classes.cursorDefault,
                  )}
                >
                  <img
                    alt="calendar"
                    id="cypress-calendar-icon"
                    src={CalendarIcon}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {isOpen && (
          <CalendarDialogComponent
            value={dateValue}
            onClose={handleCloseCalendar}
            onChange={handleChangeCalendar}
            isSelectRange={selectRange}
            maxDate={maxDate}
            minDate={minDate}
          />
        )}
        <TransitionComponent
          text={errorText ?? t('system.fieldMustFilled')}
          enter={Boolean(error)}
        />
      </div>
    </ClickAwayListener>
  );
}

export default React.memo(CalendarComponent);
