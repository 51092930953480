import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { Transition } from 'react-transition-group';

import { duration, useStyles } from './styles';
import { Props } from './types';

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function TransitionComponent({ id, enter, text, className, link }: Props) {
  const classes = useStyles();

  return (
    <Transition in={enter} timeout={duration}>
      {(state) => (
        <Typography
          id={id}
          className={cn(classes.defaultStyles, classes.defaultColor, className)}
          style={{
            // @ts-ignore
            ...transitionStyles[state],
          }}
        >
          {text}
          {!!link && link()}
        </Typography>
      )}
    </Transition>
  );
}

export default TransitionComponent;
