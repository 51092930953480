import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clientPrefixBySubType } from '../../../constants';
import { AccountValue } from '../../../store/accounts/types';
import { Category, SubCategory } from '../../../store/categories/types';
import { Client, UrlPrefix } from '../../../store/clients/types';
import { selectCryptoCurrencyCodes } from '../../../store/currency/selectors';
import { selectCustomFilterAccounts } from '../../../store/filters/selectors';
import historyOperationActions from '../../../store/historyOperationReducer/actions';
import opActions from '../../../store/operations/actions';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../store/operations/selectors';
import {
  OperationSubType,
  OperationType,
} from '../../../store/operations/types';
import { Project } from '../../../store/projects/types';
import useCreateConsumptionLoan from './createOperationHooks/useCreateConsumptionLoan';
import useCreateConsumptionLoanRepayment from './createOperationHooks/useCreateConsumptionLoanRepayment';
import useCreateDividend from './createOperationHooks/useCreateDividend';
import useCreateIncomeLoan from './createOperationHooks/useCreateIncomeLoan';
import useCreateIncomeLoanRepayment from './createOperationHooks/useCreateIncomeLoanRepayment';
import useCreateInvesment from './createOperationHooks/useCreateInvesment';
import useCreateSalary from './createOperationHooks/useCreateSalary';
import useCreateSale from './createOperationHooks/useCreateSale';
import useCreateSupplier from './createOperationHooks/useCreateSupplier';
import useCreateTax from './createOperationHooks/useCreateTax';
import useCreateTransfer from './createOperationHooks/useCreateTransfer';
import useSelectProps from './createOperationHooks/useSelectProps';
import {
  CustomPeriodEnum,
  FullPeriod,
  OperationToTransferProps,
  Props,
} from './types';
import {
  calculateSumAndCompanyCurrencySum,
  getNewItem,
  setEditAccount,
  setEditCategory,
  setEditClient,
  setEditProjects,
  setEditTags,
} from './utils';

const initialShouldCreate = {
  sale: false,
  incomeLoan: false,
  incomeLoanRepayment: false,
  investment: false,
  dividend: false,
  tax: false,
  salary: false,
  consumptionLoan: false,
  consumptionLoanRepayment: false,
  supplier: false,
  owner: false,
  transfer: false,
};

// const now = moment.utc().valueOf() + getTimeOffset();

function OperationHOC(props: Props) {
  const dispatch = useDispatch();

  const prevAllClients = useRef<Client[] | null>(null);
  const prevAllProjects = useRef<Project[] | null>(null);
  const prevAllCategories = useRef<(Category | SubCategory)[] | null>(null);
  const refCategory = useRef<(Category | SubCategory) | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [category, setCategory] = useState<(Category | SubCategory) | null>(
    null,
  );
  const [shouldCreate, setShouldCreate] = useState({ ...initialShouldCreate });
  const [scheduled, setScheduled] = useState(false);
  const [shouldCloseDialog, setShouldCloseDialog] = useState(true);

  const {
    type: initialOperationType,
    isEdit,
    editProps,
    isCompare,
    isGeneric,
    isNewState,
    reportType,
    difference,
    onCloseDialog,
    changeToTransfer,
    // @ts-ignore
    WrapperComponent,
  } = props;

  const {
    tags,
    owners,
    company,
    clients,
    accounts,
    investors,
    borrowers,
    creditors,
    suppliers,
    categories,
    allClients,
    staffMembers,
    companyCurrency,
    taxOrganisations,
    settingsProjects,
    operationProjects,
    createAndCopyState,
    consumptionCategories,
    companyCurrencySymbol,
    creatingClientInProgress,
    creatingProjectInProgress,
    creatingIncomeCategoryInProgress,
    creatingConsumptionCategoryInProgress,
  } = useSelectProps();

  const opProps = useSelector(getOperationProps);
  const historyOperationProps = useSelector(getHistoryOperationProps);

  const selectedLeftMenuAccount = useSelector(
    selectCustomFilterAccounts('journal'),
  );

  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const operationProps = isNewState ? historyOperationProps : opProps;
  const operationActions = isNewState ? historyOperationActions : opActions;

  const {
    botId,
    amount,
    account,
    repeats,
    splitPaymentProjects,
    comments,
    toAccount,
    externalId,
    incomeDate: operationIncomeDate,
    taxPeriodId,
    exchangeRate,
    selectedTags,
    integrationId,
    currencyValue,
    currencyAmount,
    salaryPeriodId,
    counterpartyId,
    editOperationId,
    isCopyComponent,
  } = operationProps;

  const projects = isCompare ? settingsProjects : operationProjects;

  const location = useLocation();

  const resetShouldCreate = useCallback(() => {
    setShouldCreate({ ...initialShouldCreate });
  }, []);

  const operationHookProps = {
    isEdit: Boolean(isEdit),
    isCopy: Boolean(isCopyComponent),
    editProps,
    scheduled,
    onCloseDialog,
    resetShouldCreate,
    shouldCloseDialog,
  };

  useCreateSale({
    client,
    category,
    shouldCreate: shouldCreate.sale,
    ...operationHookProps,
  });

  useCreateInvesment({
    client,
    shouldCreate: shouldCreate.investment,
    ...operationHookProps,
  });

  useCreateIncomeLoan({
    client,
    shouldCreate: shouldCreate.incomeLoan,
    ...operationHookProps,
  });

  useCreateIncomeLoanRepayment({
    client,
    shouldCreate: shouldCreate.incomeLoanRepayment,
    ...operationHookProps,
  });

  useCreateTransfer({
    shouldCreate: shouldCreate.transfer,
    ...operationHookProps,
  });

  useCreateTax({
    client,
    shouldCreate: shouldCreate.tax,
    ...operationHookProps,
  });

  useCreateSupplier({
    client,
    category,
    shouldCreate: shouldCreate.supplier,
    ...operationHookProps,
  });

  useCreateDividend({
    client,
    shouldCreate: shouldCreate.dividend,
    ...operationHookProps,
  });

  useCreateSalary({
    client,
    shouldCreate: shouldCreate.salary,
    ...operationHookProps,
  });

  useCreateConsumptionLoanRepayment({
    client,
    shouldCreate: shouldCreate.consumptionLoanRepayment,
    ...operationHookProps,
  });

  useCreateConsumptionLoan({
    client,
    shouldCreate: shouldCreate.consumptionLoan,
    ...operationHookProps,
  });

  const handleCloseOperationDialog = useCallback(() => {
    dispatch(operationActions.setIsCopyComponent({ isCopyComponent: false }));
    dispatch(
      operationActions.setCreateAndCopyState({ createAndCopyState: false }),
    );
    dispatch(operationActions.resetOperationProps());
    dispatch(historyOperationActions.resetOperationProps());

    onCloseDialog();
  }, [dispatch, onCloseDialog, operationActions]);

  const handleChangeToAccount = useCallback(
    (value: any) => {
      dispatch(operationActions.setToAccount({ toAccount: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeClient = useCallback((value: any) => {
    setClient(value);
  }, []);

  const handleChangeSplitPaymentProjects = useCallback(
    (value: any) => {
      dispatch(
        operationActions.setSplitPaymentProjects({
          splitPaymentProjects: value,
        }),
      );
    },
    [dispatch, operationActions],
  );

  const handleChangeAccount = useCallback(
    (value: AccountValue | null) => {
      dispatch(operationActions.setAccount({ account: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeDateOfPayment = useCallback(
    (value: number) => {
      dispatch(operationActions.setDateOfPayment(value));
      dispatch(
        operationActions.setSellDateWasChanged({
          dateOfPaymentWasChanged: true,
        }),
      );
    },
    [dispatch, operationActions],
  );

  const handleChangeComments = useCallback(
    (value: string) => {
      dispatch(operationActions.setComments({ comments: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeRepeats = useCallback(
    (value: FullPeriod) => {
      dispatch(operationActions.setRepeats({ repeats: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeIncomeDate = useCallback(
    (value: number) => {
      dispatch(operationActions.setIncomeDate({ incomeDate: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeCategory = useCallback(
    (value: any, type: OperationType | undefined) => {
      const normalizedLabel = value?.normalizedLabel ?? '';
      const systemValue = value?.systemValue ?? false;

      // @ts-ignore
      let prefix = clientPrefixBySubType[normalizedLabel];

      if (!prefix) {
        prefix =
          type === OperationType.income
            ? UrlPrefix.clients
            : UrlPrefix.suppliers;
      }

      dispatch(operationActions.getClientByPrefix(prefix));
      dispatch(operationActions.resetClients());

      if (systemValue || category?.systemValue) {
        setClient(null);
      }

      if (!value || category?.systemValue) {
        dispatch(
          opActions.setPeriodDateOfPayment({
            endTimestamp: 0,
            startTimestamp: 0,
            incomeDate: operationIncomeDate,
          }),
        );

        dispatch(opActions.setDateOfPaymentPeriodId(null));

        dispatch(opActions.setDateOfPayment(operationIncomeDate));
      }

      // if (
      //   normalizedLabel === OperationSubType.tax ||
      //   normalizedLabel === OperationSubType.salary
      // ) {
      //   dispatch(
      //     operationActions.setDateOfPayment({ dateOfPayment: operationIncomeDate }),
      //   );
      // }

      setCategory(value);

      if (value) {
        refCategory.current = value;
      }
    },
    [dispatch, operationActions, category, operationIncomeDate],
  );

  const handleChangeTags = useCallback(
    (value: any) => {
      dispatch(operationActions.setSelectedTags({ selectedTags: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeSalaryPeriod = useCallback(
    (value: CustomPeriodEnum | null) => {
      dispatch(operationActions.setSalaryPeriodId({ salaryPeriodId: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeTaxPeriod = useCallback(
    (value: CustomPeriodEnum | null) => {
      dispatch(operationActions.setTaxPeriodId({ taxPeriodId: value }));
    },
    [dispatch, operationActions],
  );

  const handleChangeDateOfPaymentPeriod = useCallback(
    (start: number, end: number) => {
      dispatch(
        operationActions.setPeriodDateOfPayment({
          incomeDate: operationIncomeDate,
          endTimestamp: end,
          startTimestamp: start,
        }),
      );
    },
    [dispatch, operationActions, operationIncomeDate],
  );

  const handleChangeDateOfPaymentPeriodId = useCallback(
    (value: CustomPeriodEnum) => {
      dispatch(operationActions.setDateOfPaymentPeriodId(value));
    },
    [dispatch, operationActions],
  );

  const handleSetOperationType = useCallback(
    (value: OperationType) => {
      dispatch(operationActions.setOperationType(value));
    },
    [dispatch, operationActions],
  );

  const handleCreateSupplier = useCallback(
    (name: string) => {
      dispatch(operationActions.createSupplier(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateStaffMember = useCallback(
    (name: string) => {
      dispatch(operationActions.createStaffMember(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateOwner = useCallback(
    (name: string) => {
      dispatch(operationActions.createOwner(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateClient = useCallback(
    (name: string) => {
      dispatch(operationActions.createClient(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateInvestor = useCallback(
    (name: string) => {
      dispatch(operationActions.createInvestor(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateCreditor = useCallback(
    (name: string) => {
      dispatch(operationActions.createCreditor(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateBorrower = useCallback(
    (name: string) => {
      dispatch(operationActions.createBorrower(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateTaxOrganisation = useCallback(
    (name: string) => {
      dispatch(operationActions.createTaxOrganisation(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateCategory = useCallback(
    (name: string) => {
      dispatch(operationActions.createCategory(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateConsumptionCategories = useCallback(
    (name: string) => {
      dispatch(operationActions.createConsumptionCategories(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateProject = useCallback(
    (name: string) => {
      dispatch(operationActions.createProject(name));
    },
    [dispatch, operationActions],
  );

  const handleCreateTag = useCallback(
    (tag: string) => {
      dispatch(operationActions.createTag(tag));
    },
    [dispatch, operationActions],
  );

  const handleCreateOperation = useCallback(
    (
      value: boolean,
      prop: OperationSubType | 'incomeLoanRepayment' | 'incomeLoan',
      closeDialog = true,
    ) => {
      setScheduled(value);
      setShouldCloseDialog(closeDialog);
      setShouldCreate((values) => ({ ...values, [prop]: true }));
    },
    [],
  );

  const handleOperationToTransfer = useCallback(
    (id: string, data: OperationToTransferProps) => {
      dispatch(operationActions.operationToTransfer({ id, data, location }));
      handleCloseOperationDialog();
    },
    [dispatch, operationActions, location, handleCloseOperationDialog],
  );

  const handleRemoveOperation = useCallback(
    (scheduledValue: boolean) => {
      if (editOperationId) {
        const payload = {
          ids: editOperationId,
          location,
          scheduled: scheduledValue,
        };
        dispatch(operationActions.removeOperation(payload));
        handleCloseOperationDialog();
      }
    },
    [
      dispatch,
      operationActions,
      editOperationId,
      location,
      handleCloseOperationDialog,
    ],
  );

  useEffect(() => {
    if (
      !isEdit &&
      selectedLeftMenuAccount?.length === 1 &&
      initialOperationType !== OperationType.transfer
    ) {
      const acc = accounts.find((el) => el._id === selectedLeftMenuAccount[0]);

      if (acc && !account) {
        handleChangeAccount(acc);
      }
    }
  }, [
    initialOperationType,
    isEdit,
    accounts,
    handleChangeAccount,
    selectedLeftMenuAccount,
    account,
  ]);

  useEffect(() => {
    if (isNewState && editProps) {
      dispatch(historyOperationActions.setOperationProps({ data: editProps }));
    }
  }, [isNewState, dispatch, editProps]);

  useEffect(() => {
    if (editProps && !isCopyComponent) {
      const { account: itemAccount, toAccount: itemToAccount } = setEditAccount(
        editProps,
        accounts,
        initialOperationType,
      );

      handleChangeAccount(itemAccount);
      handleChangeToAccount(itemToAccount);

      if (initialOperationType === OperationType.transfer && integrationId) {
        if (editProps.type === OperationType.income) {
          editProps.transferFromIntegratedIncome = true;
        } else if (editProps.type === OperationType.consumption) {
          editProps.transferFromIntegratedConsumption = true;
        }
      }
    }
  }, [
    accounts,
    editProps,
    isCopyComponent,
    handleChangeAccount,
    handleChangeToAccount,
    initialOperationType,
    integrationId,
  ]);

  useEffect(() => {
    if (editProps && !isCopyComponent) {
      const itemTags = setEditTags(editProps, tags);

      if (!selectedTags && itemTags.length) {
        handleChangeTags(itemTags);
      }
    }
  }, [editProps, selectedTags, tags, handleChangeTags, isCopyComponent]);

  useEffect(() => {
    let defaultClient = null;

    const categoryId = editProps?.categoryId;
    const editCounterpartyId = editProps?.counterpartyId || '';

    if (isCopyComponent && counterpartyId) {
      defaultClient = setEditClient(counterpartyId, allClients);
    } else if (!isCopyComponent) {
      if (category?._id === categoryId || !category?.systemValue) {
        defaultClient = setEditClient(editCounterpartyId, allClients);
      }
    }

    const newClient =
      prevAllClients.current && category?._id === categoryId
        ? getNewItem(prevAllClients.current, allClients)
        : null;

    if (newClient) {
      handleChangeClient(newClient);

      if (allClients.length) {
        prevAllClients.current = allClients;
      }
    } else if (defaultClient) {
      handleChangeClient(defaultClient);

      if (allClients.length) {
        prevAllClients.current = allClients;
      }
    }
  }, [
    category,
    allClients,
    counterpartyId,
    isCopyComponent,
    handleChangeClient,
    editProps?.categoryId,
    editProps?.counterpartyId,
  ]);

  useEffect(() => {
    if (isEdit && editProps && !isNewState) {
      const { type, subType } = editProps;

      if (!isCopyComponent) {
        dispatch(opActions.getOperationItems({ type, subType }));
        dispatch(opActions.getSettingsCategories());
        dispatch(opActions.setOperationProps({ data: editProps }));
        if (editProps.isTransformToTransfer) {
          dispatch(opActions.updateType(OperationType.transfer));
        }
      }
    }
  }, [isEdit, dispatch, editProps, isCopyComponent, isNewState]);

  useEffect(() => {
    if (editProps && !isCopyComponent) {
      const defaultProjects = setEditProjects(editProps, projects);
      const newProject = prevAllProjects.current
        ? getNewItem(prevAllProjects.current, projects)
        : null;

      if (newProject) {
        const isCrypto = cryptoCurrencyCodes.includes(
          account?.currency.code || '',
        );
        const calculateSumAndCompanyCurrencySumProps = {
          isEdit: false,
          amount,
          account,
          isCrypto: isCrypto,
          revertState: opProps.revertState,
          exchangeRate,
          currencyValue,
          currencyAmount,
          companyCurrency,
        };

        const {
          companyCurrencySum = null,
          transactionSum = null,
          sum,
        } = calculateSumAndCompanyCurrencySum(
          calculateSumAndCompanyCurrencySumProps,
        );
        handleChangeSplitPaymentProjects([
          {
            project: newProject,
            percentages: '100',
            sum: sum?.toFixed(2) ?? '0',
            transactionSum,
            companyCurrencySum,
          },
        ]);
      } else if (defaultProjects) {
        handleChangeSplitPaymentProjects(defaultProjects);
      }

      if (projects.length) {
        prevAllProjects.current = projects;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopyComponent, editProps, projects, handleChangeSplitPaymentProjects]);

  useEffect(() => {
    const type = editProps?.type;
    const categoryId = editProps?.categoryId || '';

    if (category && categoryId !== category._id) {
      return;
    }

    if (!isCopyComponent) {
      const categoriesByType =
        type === OperationType.income ? categories : consumptionCategories;

      const defaultCategory = setEditCategory(categoryId, categoriesByType);
      const newCategory = prevAllCategories.current
        ? getNewItem(prevAllCategories.current, categoriesByType)
        : null;

      if (newCategory) {
        handleChangeCategory(newCategory, type);
      } else if (defaultCategory && !refCategory.current) {
        handleChangeCategory(defaultCategory, type);
      }

      if (categoriesByType.length) {
        prevAllCategories.current = categoriesByType;
      }
    }
  }, [
    category,
    categories,
    editProps?.type,
    isCopyComponent,
    handleChangeCategory,
    consumptionCategories,
    editProps?.categoryId,
  ]);

  useEffect(() => {
    if (account?.crypto && splitPaymentProjects.length > 1) {
      handleChangeSplitPaymentProjects([]);
    }
  }, [
    account?.crypto,
    handleChangeSplitPaymentProjects,
    splitPaymentProjects.length,
  ]);

  return (
    // @ts-ignore
    <WrapperComponent
      botId={botId}
      externalId={externalId}
      integrationId={integrationId}
      reportType={reportType}
      isCopy={isCopyComponent}
      createAndCopyState={createAndCopyState}
      companyCurrency={companyCurrency}
      creatingClientInProgress={creatingClientInProgress}
      creatingConsumptionCategoryInProgress={
        creatingConsumptionCategoryInProgress
      }
      creatingProjectInProgress={creatingProjectInProgress}
      creatingIncomeCategoryInProgress={creatingIncomeCategoryInProgress}
      isGeneric={isGeneric}
      difference={difference}
      isCompare={isCompare}
      initialScheduled={!!editProps?.scheduled}
      repeats={repeats}
      editOperationId={editOperationId}
      isEdit={isEdit}
      isNewState={isNewState}
      category={category}
      consumptionCategories={consumptionCategories}
      editProps={editProps}
      amount={amount}
      currencyAmount={currencyAmount}
      exchangeRate={exchangeRate}
      accounts={accounts}
      account={account}
      toAccount={toAccount}
      company={company}
      clients={clients}
      investors={investors}
      creditors={creditors}
      borrowers={borrowers}
      taxOrganisations={taxOrganisations}
      owners={owners}
      suppliers={suppliers}
      staffMembers={staffMembers}
      client={client}
      salaryPeriodId={salaryPeriodId}
      taxPeriodId={taxPeriodId}
      projects={projects}
      tags={tags}
      selectedTags={selectedTags}
      comments={comments}
      splitPaymentProjects={splitPaymentProjects}
      categories={categories}
      companyCurrencySymbol={companyCurrencySymbol}
      incomeDate={operationIncomeDate}
      changeSalaryPeriod={handleChangeSalaryPeriod}
      changeTaxPeriod={handleChangeTaxPeriod}
      changeIncomeDate={handleChangeIncomeDate}
      changeRepeats={handleChangeRepeats}
      handleChangeSplitPaymentProjects={handleChangeSplitPaymentProjects}
      changeComments={handleChangeComments}
      changeAccount={handleChangeAccount}
      changeToAccount={handleChangeToAccount}
      changeClient={handleChangeClient}
      changeCategory={handleChangeCategory}
      createClient={handleCreateClient}
      createInvestor={handleCreateInvestor}
      createCreditor={handleCreateCreditor}
      createBorrower={handleCreateBorrower}
      createTaxOrganisation={handleCreateTaxOrganisation}
      createSupplier={handleCreateSupplier}
      createOwner={handleCreateOwner}
      createStaffMember={handleCreateStaffMember}
      createCategory={handleCreateCategory}
      createConsumptionCategories={handleCreateConsumptionCategories}
      createProject={handleCreateProject}
      createTag={handleCreateTag}
      changeTags={handleChangeTags}
      onCreateOperation={handleCreateOperation}
      onRemoveOperation={handleRemoveOperation}
      currency={currencyValue}
      changeToTransfer={changeToTransfer}
      handleOperationToTransfer={handleOperationToTransfer}
      onSetOperationType={handleSetOperationType}
      onChangeDateOfPayment={handleChangeDateOfPayment}
      onChangeDateOfPaymentPeriodId={handleChangeDateOfPaymentPeriodId}
      onChangeDateOfPaymentPeriod={handleChangeDateOfPaymentPeriod}
    />
  );
}

export default OperationHOC;
