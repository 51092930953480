import { useMemo } from 'react';

import {
  SHOW_BRAZIL_LANGUAGE,
  SHOW_CZECH_LANGUAGE,
  SHOW_FRENCH_LANGUAGE,
  SHOW_ITALIAN_LANGUAGE,
  SHOW_KAZAKH_LANGUAGE,
  SHOW_PORTUGUESE_LANGUAGE,
  SHOW_RUSSIAN_LANGUAGE,
  SHOW_SPANISH_LANGUAGE,
  SHOW_TURKISH_LANGUAGE,
  SHOW_UZBEK_LANGUAGE,
} from '../constants/featureToggles/languagesFeatureToggles';
import useUnleash from './useUnleash';

function useAdditionalLanguages() {
  const showCzechLanguage = useUnleash(SHOW_CZECH_LANGUAGE);
  const showItalianLanguage = useUnleash(SHOW_ITALIAN_LANGUAGE);
  const showFrenchLanguage = useUnleash(SHOW_FRENCH_LANGUAGE);
  const showSpanishLanguage = useUnleash(SHOW_SPANISH_LANGUAGE);
  const showPortugueseLanguage = useUnleash(SHOW_PORTUGUESE_LANGUAGE);
  const showTurkishLanguage = useUnleash(SHOW_TURKISH_LANGUAGE);
  const showKazakhLanguage = useUnleash(SHOW_KAZAKH_LANGUAGE);
  const showRussianLanguage = useUnleash(SHOW_RUSSIAN_LANGUAGE);
  const showUzbekLanguage = useUnleash(SHOW_UZBEK_LANGUAGE);
  const showBrazilLanguage = useUnleash(SHOW_BRAZIL_LANGUAGE);

  return useMemo(
    () => ({
      [SHOW_CZECH_LANGUAGE]: showCzechLanguage,
      [SHOW_ITALIAN_LANGUAGE]: showItalianLanguage,
      [SHOW_FRENCH_LANGUAGE]: showFrenchLanguage,
      [SHOW_SPANISH_LANGUAGE]: showSpanishLanguage,
      [SHOW_PORTUGUESE_LANGUAGE]: showPortugueseLanguage,
      [SHOW_TURKISH_LANGUAGE]: showTurkishLanguage,
      [SHOW_KAZAKH_LANGUAGE]: showKazakhLanguage,
      [SHOW_RUSSIAN_LANGUAGE]: showRussianLanguage,
      [SHOW_UZBEK_LANGUAGE]: showUzbekLanguage,
      [SHOW_BRAZIL_LANGUAGE]: showBrazilLanguage,
    }),
    [
      showCzechLanguage,
      showUzbekLanguage,
      showFrenchLanguage,
      showKazakhLanguage,
      showBrazilLanguage,
      showSpanishLanguage,
      showItalianLanguage,
      showTurkishLanguage,
      showRussianLanguage,
      showPortugueseLanguage,
    ],
  );
}

export default useAdditionalLanguages;
