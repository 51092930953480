import i18next from 'i18next';
import moment from 'moment';
import { createSelector } from 'reselect';

import { OWNER_ROLE_ID, SUPPORT_EMAIL } from '../../constants';
import {
  CategoryItems,
  FuturePaymentsName,
  JournalItems,
  ReportName,
  SettingsName,
} from '../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { getLanguage } from '../../selectors/main';
import { AppState } from '../reducers';
import { AutoCompleteProps } from '../types';
import {
  Employee,
  FastPermissions,
  PermissionKind,
  PermissionValue,
} from './types';

export const getEmployees = (state: AppState) => state.employees.employees;
export const getInvites = (state: AppState) => state.employees.invites;

export const selectSupportInvite = createSelector(
  getEmployees,
  (employees) =>
    Boolean(employees.find((employee) => employee.email === SUPPORT_EMAIL)),
);

export const selectSupportEmployeeId = createSelector(
  getEmployees,
  (employees) => {
    const employee = employees.find((el) => el.email === SUPPORT_EMAIL);

    return employee?._id || null;
  },
);

function sortByTime(a: Employee, b: Employee) {
  const firstDate = moment(a.createdAt);
  const secondDate = moment(b.createdAt);

  if (firstDate > secondDate) {
    return 1;
  }

  if (firstDate < secondDate) {
    return -1;
  }

  return 0;
}

export const getPermissionTypes = (state: AppState) =>
  state.employees.permissionTypes;

export const selectCountEmployees = createSelector(
  getEmployees,
  (employees) => employees.length,
);

export const selectUnion = createSelector(
  getEmployees,
  getInvites,
  getLanguage,
  (employees, invites, language) =>
    employees
      .concat(invites)
      .sort(sortByTime)
      .map((el) => {
        const { roleId } = el;
        const i18 = i18next.getFixedT(language);

        if (roleId === OWNER_ROLE_ID) {
          return {
            ...el,
            label: i18('employees.owner'),
            role: i18('employees.owner'),
          };
        }

        return el;
      }),
);
export const getRoles = (state: AppState) => state.employees.roles;

export const selectReadPermissionIds = createSelector(
  getPermissionTypes,
  (permissions) =>
    permissions
      .filter((permission) => permission.kind === PermissionKind.read)
      .map((el) => el._id),
);

export const selectEditPermissionIds = createSelector(
  getPermissionTypes,
  (permissions) =>
    permissions
      .filter((permission) => (
        permission.kind === PermissionKind.edit &&
        permission.value !== PermissionValue.delete)
      )
      .map((el) => el._id),
);

export const selectDeletePermissionIds = createSelector(
  getPermissionTypes,
  (permissions) =>
    permissions
      .filter(
        (permission) =>
          permission.kind === PermissionKind.edit &&
          permission.value === PermissionValue.delete,
      )
      .map((el) => el._id),
);

export const selectRoles = createSelector(
  getRoles,
  getLanguage,
  (roles, language) => {
    const i18 = i18next.getFixedT(language);

    return roles
      .map((el, index) => {
        const { _id } = el;

        if (_id === OWNER_ROLE_ID) {
          return {
            ...el,
            orderIndex: 6,
            label: i18('employees.permissions.fullAccess'),
            description: i18('employees.permissions.fullAccessDescription')
          }
        }

        if (el.fastPermissions?.length) {
          const { length } = el.fastPermissions;
          if (length === 1) {
            return { ...el, label: i18('employees.permissions.read'), orderIndex: 1 };
          }

          if (length === 4) {
            return { ...el, label: i18('employees.permissions.edit'), orderIndex: 4 };
          }

          if (length === 5) {
            return { ...el, label: i18('employees.permissions.full'), orderIndex: 5 };
          }
        }

        return { ...el, orderIndex: 7 + index } ;
      })
    .sort((a, b) => (
      a.orderIndex > b.orderIndex ? 1 : -1
    ));
  }
);

export const selectRolesById = (id: string) => createSelector(
  selectRoles,
  roles => roles.find(el => el._id === id) ?? null
)

export const selectEmployeeRole = (roleId: string) => createSelector(
  selectRoles,
  roles => roles.find(el => el._id === roleId) ?? null
)

export const getResources = (state: AppState) => state.employees.resources;

export const getLoadingRolePermissions = (state: AppState) =>
  state.employees.loadingRolePermissions;

export const getCurrentCreatedRole = (state: AppState) =>
  state.employees.currentCreatedRole;

export const selectResourcesLabelToId = createSelector(
  getResources,
  (resources) =>
    resources.reduce((acc, resource) => {
      const { _id, label } = resource;

      return {
        ...acc,
        [label]: _id,
      };
    }, {} as { [key in JournalItems | CategoryItems | SettingsName | FuturePaymentsName | ReportName]: string }),
);

export const selectResourcesIdToLabel = createSelector(
  getResources,
  (resources) =>
    resources.reduce((acc, resource) => {
      const { _id, label } = resource;

      return {
        ...acc,
        [_id]: label,
      };
    }, {} as { [key in JournalItems | CategoryItems | SettingsName]: string }),
);

export const selectRolesAutoComplete = createSelector(
  getRoles,
  getLanguage,
  (roles, language) => {
    const i18 = i18next.getFixedT(language);

    return (
      roles.map((role) => {
        const { _id: id, label: name, systemValue } = role;

        if (systemValue) {
          return {
            ...role,
            id,
            name: i18('employees.owner'),
            label: i18('employees.owner'),
          };
        }

        return { ...role, id, name, label: name };
      }) || []
    );
  },
);

export const getCurrentRolePermissions = (state: AppState) =>
  state.employees.currentRolePermissions;

export const selectCurrentRolePermissions = createSelector(
  getCurrentRolePermissions,
  (rolePermissions) => rolePermissions,
);

export const selectEmployeesAutocomplete = createSelector(
  getEmployees,
  (employees) =>
    employees.reduce((acc: AutoCompleteProps[], employee) => {
      if (employee.userId) {
        return [...acc, { id: employee.userId, name: employee.name }];
      }

      return acc;
    }, []),
);

export const selectRoleFastPermissions = (fastPermissions: string[]) => createSelector(
  selectReadPermissionIds,
  selectEditPermissionIds,
  selectDeletePermissionIds,
  (readIds, editIds, deleteIds) => {
    if (!fastPermissions.length) {
      return null;
    }

    let permissions: FastPermissions | null = null;

    const allowRead = readIds.every(el => fastPermissions.includes(el));
    const allowEdit = editIds.every(el => fastPermissions.includes(el));
    const allowDelete = deleteIds.every(el => fastPermissions.includes(el));

    if (allowRead && !allowEdit) {
      permissions = FastPermissions.read;
    } else if (allowEdit && !allowDelete) {
      permissions = FastPermissions.readWrite;
    } else if (allowDelete) {
      permissions = FastPermissions.full;
    }

    return permissions;
  }
);
