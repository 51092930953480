import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import { getOperationProps } from '../../../../store/operations/selectors';
import { black } from '../../../../theme/colors';
import { useStyles } from './styles';
import { ErrorModalProps } from './types';

export function ErrorModal({ sumDiff, setIsOpenErrorModal }: ErrorModalProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currencyValue } = useSelector(getOperationProps);

  const onClose = useCallback(() => {
    setIsOpenErrorModal(false);
  }, [setIsOpenErrorModal]);

  const DialogActions = useCallback(
    () => (
      <div className={classes.footer}>
        <CustomButton
          action={onClose}
          className={classes.buttonOk}
          title={t('splitPaymentDialog.okay')}
          textColor={black}
          fullWidth
          wrapperRootClassName={classes.width100}
        />
      </div>
    ),
    [classes, onClose, t],
  );

  return (
    <Dialog
      isOpened
      actions={<DialogActions />}
      onClose={onClose}
      title={t('splitPaymentDialog.modalErrorTitle', {
        postProcess: 'sprintf',
        sprintf: [sumDiff, currencyValue?.symbol],
      })}
    />
  );
}
