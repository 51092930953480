import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000000,
    background: 'rgba(0, 0, 0, 0.5)',
  },
});
