import Auth from '../../../api/Auth';
import { ChangePasswordRequest } from './types';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const changePassword = (form: ChangePasswordRequest) => ({
  type: CHANGE_PASSWORD,
  payload: Auth.changePassword(form),
});

export default {
  changePassword
}