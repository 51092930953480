import Http from '../Http';
import { SetCredentialsResponse } from './monoAcquiring.types';

const setCredentials = (token: string) =>
  Http.makeInstance().post<SetCredentialsResponse>(
    'integrations/monoAcquiringApiCredentials',
    { token },
  );

export default {
  setCredentials,
};
