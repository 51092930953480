import { SplitPaymentProjectDetailedInfo } from '../../foundation/Operations/Components/SplittingPayment/types';
import {
  CustomPeriodEnum,
  FullPeriod,
} from '../../foundation/Operations/HOC/types';
import { AccountValue } from '../accounts/types';
import { JournalData } from '../journal/types';
import { UpdateOneOperationPayload } from '../operations/sagas.types';
import { Attachment } from '../operations/types';
import { AutoCompleteProps } from '../types';
import { ActionTypes, UPDATE_ONE_OPERATION_PENDING } from './actions';

export type Projects = {
  id: string;
  stake: number;
};

export enum OperationType {
  income = 'income',
  consumption = 'consumption',
  transfer = 'transfer',
}

export enum OperationSubType {
  sale = 'sale',
  investment = 'investment',
  loanRepayment = 'loanRepayment',
  loan = 'loan',
  supplier = 'supplier',
  tax = 'tax',
  dividend = 'dividend',
  other = 'other',
  salary = 'salary',
  generic = 'generic',
}

export type Operation = {
  isCopyComponent: boolean;
  createAndCopyState: boolean;
  amount: string | null;
  currencyAmount: string | null;
  exchangeRate: string | null;
  revertedExchangeRate: string | null;
  editOperationId: string | null;
  account: AccountValue | null;
  toAccount: AccountValue | null;
  incomeDate: number;
  repeats: FullPeriod;
  splitPaymentProjects: SplitPaymentProjectDetailedInfo[];
  revertState: boolean;
  selectedTags: any[] | null;
  comments: string;
  salaryPeriodId: CustomPeriodEnum | null;
  taxPeriodId: CustomPeriodEnum | null;
  startTimestamp: number;
  endTimestamp: number;
  dateOfPayment: number;
  dateOfPaymentPeriodId: CustomPeriodEnum | null;
  dateOfPaymentWasChanged: boolean;
  currencyValue:
    | (AutoCompleteProps & { symbol: string; isCrypto?: boolean })
    | null;
  loadingRates: boolean;
  isCompare?: boolean;
  isGeneric?: boolean;
  difference?: JournalData;
  counterpartyId?: string;
  canDelete?: boolean;
  canEdit?: boolean;
  attachment: Attachment | null;
  attachments: Attachment[];
  transactionSum: number | null;
  transactionCurrency: string | null;
  transactionCurrencyRate: number | null;
  botId?: string;
  externalId?: string;
  integrationId?: string;
  type: OperationType | null;
};

export type HistoryOperationsState = Operation & {
  historyOperationState: Operation | null;
};

export type SetCurrencyValuePayload = Pick<
  HistoryOperationsState,
  'currencyValue'
>;
export type SetDateOfPaymentWasChangedPayload = Pick<
  HistoryOperationsState,
  'dateOfPaymentWasChanged'
>;
export type SetTaxPeriodIdPayload = Pick<HistoryOperationsState, 'taxPeriodId'>;
export type SetSalaryPeriodIdPayload = Pick<
  HistoryOperationsState,
  'salaryPeriodId'
>;
export type SetCommentsPayload = Pick<HistoryOperationsState, 'comments'>;
export type SetSelectedTagsPayload = Pick<
  HistoryOperationsState,
  'selectedTags'
>;
export type SetSplitPaymentProjectsPayload = Pick<
  HistoryOperationsState,
  'splitPaymentProjects'
>;
export type SetRepeatsPayload = Pick<HistoryOperationsState, 'repeats'>;
export type SetIncomeDatePayload = Pick<HistoryOperationsState, 'incomeDate'>;
export type SetToAccountPayload = Pick<HistoryOperationsState, 'toAccount'>;
export type SetAmountPayload = Pick<HistoryOperationsState, 'amount'>;
export type SetEditOperationIdPayload = Pick<
  HistoryOperationsState,
  'editOperationId'
>;
export type SetExchangeRatePayload = Pick<
  HistoryOperationsState,
  'exchangeRate'
>;
export type SetRevertExchangeRatePayload = Pick<
  HistoryOperationsState,
  'revertedExchangeRate'
>;
export type SetCurrencyAmountPayload = Pick<
  HistoryOperationsState,
  'currencyAmount'
>;
export type SetIsCopyPayload = Pick<HistoryOperationsState, 'isCopyComponent'>;
export type SetAccountPayload = Pick<HistoryOperationsState, 'account'>;
export type SetCreateAndCopyStatePayload = Pick<
  HistoryOperationsState,
  'createAndCopyState'
>;

export type SetIsCopyAction = {
  type: ActionTypes.SET_HISTORY_IS_COPY_COMPONENT;
  payload: SetIsCopyPayload;
};

export type SetExchangeRateAction = {
  type: ActionTypes.SET_HISTORY_EXCHANGE_RATE;
  payload: SetExchangeRatePayload;
};

export type SetCurrencyAmountAction = {
  type: ActionTypes.SET_HISTORY_CURRENCY_AMOUNT;
  payload: SetCurrencyAmountPayload;
};

export type SetEditOperationIdAction = {
  type: ActionTypes.SET_HISTORY_EDIT_OPERATION_ID;
  payload: SetEditOperationIdPayload;
};

export type SetToAccountAction = {
  type: ActionTypes.SET_HISTORY_TO_ACCOUNT;
  payload: SetToAccountPayload;
};

export type SetIncomeDateAction = {
  type: ActionTypes.SET_HISTORY_INCOME_DATE;
  payload: SetIncomeDatePayload;
};

export type SetRepeatsAction = {
  type: ActionTypes.SET_HISTORY_REPEATS;
  payload: SetRepeatsPayload;
};

export type SetSplitPaymentProjectsAction = {
  type: ActionTypes.SET_HISTORY_SPLIT_PAYMENT_PROJECTS;
  payload: SetSplitPaymentProjectsPayload;
};

export type SetSelectedTagsAction = {
  type: ActionTypes.SET_HISTORY_SELECTED_TAGS;
  payload: SetSelectedTagsPayload;
};

export type SetCommentsAction = {
  type: ActionTypes.SET_HISTORY_COMMENTS;
  payload: SetCommentsPayload;
};

export type SetSalaryPeriodIdAction = {
  type: ActionTypes.SET_HISTORY_SALARY_PERIOD_ID;
  payload: SetSalaryPeriodIdPayload;
};

export type SetDateOfPaymentWasChangedAction = {
  type: ActionTypes.SET_HISTORY_DATE_OF_PAYMENT_WAS_CHANGED;
  payload: SetDateOfPaymentWasChangedPayload;
};

export type SetTaxPeriodIdAction = {
  type: ActionTypes.SET_HISTORY_TAX_PERIOD_ID;
  payload: SetTaxPeriodIdPayload;
};

export type SetCurrencyValueAction = {
  type: ActionTypes.SET_HISTORY_CURRENCY_VALUE;
  payload: SetCurrencyValuePayload;
};

export type SetAmountAction = {
  type: ActionTypes.SET_HISTORY_AMOUNT;
  payload: SetAmountPayload;
};

export type SetRevertStateAction = {
  type: ActionTypes.SET_HISTORY_REVERT_STATE;
  payload: SetRevertStatePayload;
};

export type SetAccountAction = {
  type: ActionTypes.SET_HISTORY_ACCOUNT;
  payload: SetAccountPayload;
};

export type SetCreateAndCopyStateAction = {
  type: ActionTypes.SET_HISTORY_CREATE_AND_COPY_STATE;
  payload: SetCreateAndCopyStatePayload;
};

export type SetOperationPropsPayload = {
  data: JournalData;
};

export type SetRevertStatePayload = {
  revertState: boolean;
};

export type UpdateOneOperationAction = {
  type: typeof UPDATE_ONE_OPERATION_PENDING;
  payload: UpdateOneOperationPayload;
};

export type SetRevertedExchangeRateAction = {
  type: ActionTypes.SET_REVERTED_EXCHANGE_RATE;
  payload: SetRevertExchangeRatePayload;
};
