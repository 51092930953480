import { Typography } from '@material-ui/core';
import flatten from 'lodash/flatten';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AttentionIcon from '../../../assets/images/svg/attention-gray.svg';
import RemoveDialog from '../../../components/RemoveDialog/RemoveDialog';
import { SettingsName } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { selectCompanyCurrency } from '../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../store/currency/selectors';
import operationActions from '../../../store/operations/actions';
import { getOperationProps } from '../../../store/operations/selectors';
import { OperationType } from '../../../store/operations/types';
import { Value } from '../../../store/types';
import { maxOperationDate, minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import useGetIsEditable from '../../Header/Settings/useGetIsEditable';
import AccountSelector from '../Components/AccountSelector';
import Additions from '../Components/Additions/Additions';
import AmountByCurrencyTransferV2 from '../Components/Amount/AmountByCurrencyTransferV2';
import TransferAmountComponent from '../Components/Amount/TransferAmountComponent';
import CalendarComponent from '../Components/CalendarComponent/CalendarComponent';
import FooterComponent from '../Components/FooterComponent';
import operationsHOC from '../HOC';
import { Props as HOCProps } from '../HOC/types';
import { useStyles } from './styles';

function TransferScene(props: HOCProps) {
  const {
    tags,
    isEdit,
    isCopy,
    account,
    projects,
    accounts,
    comments,
    createTag,
    toAccount,
    isCompare,
    changeTags,
    incomeDate,
    isNewState,
    difference,
    selectedTags,
    changeAccount,
    createProject,
    changeComments,
    changeToAccount,
    changeIncomeDate,
    onRemoveOperation,
    onCreateOperation,
    onSetOperationType,
    creatingProjectInProgress,
    handleOperationToTransfer,
    handleChangeSplitPaymentProjects,
    editProps,
  } = props;

  const { isEditable } = useGetIsEditable();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { amount, exchangeRate, currencyAmount, splitPaymentProjects } =
    useSelector(getOperationProps);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const companyCurrency = useSelector(selectCompanyCurrency);
  const classes = useStyles();

  const [amountError, setAmountError] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [toAccountError, setToAccountError] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [filteredAccountsTo, setFilteredAccountsTo] = useState(accounts);
  const [filteredAccountsFrom, setFilteredAccountsFrom] = useState(accounts);

  const isCryptoAccountFrom = cryptoCurrencyCodes.includes(
    toAccount?.currency.code || '',
  );
  const isCryptoAccountTo = cryptoCurrencyCodes.includes(
    account?.currency.code || '',
  );

  const isDifferentCurrency = useMemo(() => {
    if (account && toAccount) {
      return account.currency._id !== toAccount.currency._id;
    }

    return false;
  }, [account, toAccount]);

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleUpdate = useCallback(
    (scheduled: boolean) => {
      onCreateOperation(scheduled, 'transfer');
    },
    [onCreateOperation],
  );

  const onOperationToTransfer = useCallback(() => {
    if (toAccount && account) {
      handleOperationToTransfer(editProps?._id ?? '', {
        date: incomeDate,
        amount: currencyAmount
          ? parseFloat(currencyAmount)
          : amount
          ? parseFloat(amount)
          : 0,
        comment: comments,
        accountToId: toAccount._id,
        accountFromId: account._id,
        amountTo: currencyAmount
          ? amount
            ? parseFloat(amount)
            : 0
          : undefined,
        projectId: splitPaymentProjects?.[0]?.project?._id,
        tagIds: (
          flatten(selectedTags ?? []).filter((elem) => !!elem) as Value[]
        ).map((tag) => tag.value),
      });
    }
  }, [
    amount,
    account,
    comments,
    toAccount,
    incomeDate,
    selectedTags,
    editProps?._id,
    currencyAmount,
    splitPaymentProjects,
    handleOperationToTransfer,
  ]);

  const handleValidation = useCallback(() => {
    if (isDifferentCurrency && (!amount || !exchangeRate || !currencyAmount)) {
      setAmountError(true);

      return false;
    } else if (!account) {
      setAccountError(true);

      return false;
    } else if (!toAccount) {
      setToAccountError(true);

      return false;
    }

    return true;
  }, [
    amount,
    account,
    toAccount,
    exchangeRate,
    currencyAmount,
    isDifferentCurrency,
  ]);

  const handleCreate = useCallback(() => {
    if (handleValidation()) {
      onCreateOperation(false, 'transfer');
    }
  }, [handleValidation, onCreateOperation]);

  const handleClickSaveButton = useCallback(() => {
    if (handleValidation()) {
      if (editProps?.isTransformToTransfer) {
        onOperationToTransfer();
      } else {
        handleUpdate(false);
      }
    }
  }, [
    handleUpdate,
    handleValidation,
    onOperationToTransfer,
    editProps?.isTransformToTransfer,
  ]);

  useEffect(() => {
    if (editProps?.isTransformFromIncome) {
      if (isDifferentCurrency) {
        if (amount && !currencyAmount) {
          dispatch(
            operationActions.setCurrencyAmount({
              currencyAmount: amount,
            }),
          );
          dispatch(
            operationActions.setAmount({
              amount: null,
            }),
          );
        }
      } else if (
        companyCurrency?._id === toAccount?.currency._id &&
        currencyAmount
      ) {
        dispatch(
          operationActions.setAmount({
            amount: currencyAmount,
          }),
        );
      }
    }
  }, [
    account,
    amount,
    companyCurrency?._id,
    currencyAmount,
    dispatch,
    editProps?.isTransformFromIncome,
    isDifferentCurrency,
    toAccount,
  ]);

  const handleChangeAccount = useCallback(
    (value: string) => {
      setAccountError(false);
      changeAccount(value);
    },
    [changeAccount],
  );

  const handleChangeToAccount = useCallback(
    (value: string) => {
      setToAccountError(false);
      changeToAccount(value);
    },
    [changeToAccount],
  );

  const filteredToAccounts = useCallback(() => {
    let filtAccounts = accounts;

    if (account) {
      filtAccounts = accounts
        ? filteredAccountsTo.filter((acc) => acc._id !== account._id)
        : [];
    }

    return filtAccounts;
  }, [account, accounts, filteredAccountsTo]);

  const filteredFromAccounts = useCallback(() => {
    let filtAccounts = accounts;

    if (toAccount) {
      filtAccounts = accounts
        ? filteredAccountsFrom.filter((acc) => acc._id !== toAccount._id)
        : [];
    }

    return filtAccounts;
  }, [accounts, toAccount, filteredAccountsFrom]);

  const handleCreateTransfer = useCallback(
    (value: boolean, shouldCloseDialog?: boolean) => {
      onCreateOperation(value, 'transfer', shouldCloseDialog);
    },
    [onCreateOperation],
  );

  useEffect(() => {
    if (accounts.length) {
      setFilteredAccountsTo(accounts);
      setFilteredAccountsFrom(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    onSetOperationType(OperationType.transfer);

    if (!isEdit) {
      changeIncomeDate(moment.utc().valueOf() + getTimeOffset());
    }
  }, [onSetOperationType, isEdit, changeIncomeDate]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <CalendarComponent
          testId="cypress-calendar"
          isCompare={isCompare}
          difference={Boolean(difference?.date)}
          fullWidth
          value={incomeDate}
          onChange={changeIncomeDate}
          minDate={minOperationDate()}
          maxDate={maxOperationDate(incomeDate)}
          placeholder={t('operationDialogs.transferDate')}
        />
        <AccountSelector
          testId="cypress-account-from-id-selector"
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.accountFromId)}
          value={account}
          onChange={handleChangeAccount}
          accounts={filteredFromAccounts()}
          placeholder={t('operationDialogs.accountFrom.inputLabel')}
          error={accountError}
          transferFromIntegrated={editProps?.transferFromIntegratedIncome}
        />
        <AccountSelector
          testId="cypress-account-to-id-selector"
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.accountToId)}
          value={toAccount}
          onChange={handleChangeToAccount}
          accounts={filteredToAccounts()}
          error={toAccountError}
          transferFromIntegrated={editProps?.transferFromIntegratedConsumption}
        />
        {!isDifferentCurrency && (
          <TransferAmountComponent
            isCompare={Boolean(isCompare)}
            difference={Boolean(difference?.sumDouble)}
            isNewState={isNewState}
          />
        )}
        {isDifferentCurrency && isCryptoAccountTo && isCryptoAccountFrom && (
          <div className={classes.cryptoAttention}>
            <img
              src={AttentionIcon}
              alt="attention"
              className={classes.attentionIcon}
            />
            <Typography className={classes.halfOpacity}>
              {t('operation.noExchangeRate')}
            </Typography>
          </div>
        )}
        {isDifferentCurrency && (
          <AmountByCurrencyTransferV2
            isEdit={editProps?.isTransformToTransfer ? false : isEdit}
            isCompare={Boolean(isCompare)}
            difference={difference}
            isNewState={isNewState}
            isError={amountError}
            isCryptoAccounts={isCryptoAccountFrom || isCryptoAccountTo}
            isTransformToTransfer={editProps?.isTransformToTransfer}
            isTransformFromIncome={editProps?.isTransformFromIncome}
          />
        )}
        <Additions
          isNewState={isNewState}
          isCompare={Boolean(isCompare)}
          difference={difference}
          isAllowedCreateProject={isEditable(SettingsName.projects)}
          isAllowedCreateTag={isEditable(SettingsName.tags)}
          projects={projects}
          tags={tags}
          selectedTags={selectedTags}
          comments={comments}
          splitPaymentProjects={splitPaymentProjects}
          isVisibleProjectDropdown={
            !(
              Array.isArray(splitPaymentProjects) &&
              splitPaymentProjects?.length > 1
            )
          }
          onChangeProject={handleChangeSplitPaymentProjects}
          onChangeComments={changeComments}
          onCreateProject={createProject}
          onCreateTag={createTag}
          onChangeTags={changeTags}
          creatingProjectInProgress={creatingProjectInProgress}
          editProps={editProps}
        />
        <FooterComponent
          type={OperationType.transfer}
          isEdit={isEdit}
          isCopy={isCopy}
          isCompare={Boolean(isCompare)}
          onCreate={handleCreate}
          onShowRemoveDialog={handleShowRemoveDialog}
          onClickSaveButton={handleClickSaveButton}
          createOperation={handleCreateTransfer}
        />
      </div>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveOperation"
          name={t('operation.removeDialogText.transfer').toLowerCase()}
          onRemove={onRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
    </div>
  );
}

export default operationsHOC(TransferScene);
