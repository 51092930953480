import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CreateOnboardingStepPayload,
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
} from '@finmap/core-constants';

import accountActions from '../store/accounts/actions';
import { selectDemoAccount } from '../store/accounts/selectors';
import authActions from '../store/auth/action';
import { getUser } from '../store/auth/selectors';
import onboardingActions from '../store/onboardingV2/actions';
import {
  getOnboardingV2InProgress,
  getShowVideo,
  selectActiveOnboardingStep,
  selectAnalyticStep,
  selectFinalStep,
  selectIntegrationStep,
  selectPreLastStep,
  selectSetupCabinetStep,
} from '../store/onboardingV2/selectors';

function useOnboardingV2() {
  const dispatch = useDispatch();

  const [showCreateAccountHandleDialog, setShowCreateAccountHandleDialog] =
    useState(false);
  const [showRemoveDemoDataDialog, setShowRemoveDemoDataDialog] =
    useState(false);

  const onboardingV2VideoInProgress = useSelector(getShowVideo);
  const user = useSelector(getUser);
  const integrationStep = useSelector(selectIntegrationStep);
  const activeStep = useSelector(selectActiveOnboardingStep);
  const preLastStep = useSelector(selectPreLastStep);
  const finalStep = useSelector(selectFinalStep);
  const setupCabinetStep = useSelector(selectSetupCabinetStep);
  const analyticStep = useSelector(selectAnalyticStep);
  const onboardingV2InProgress = useSelector(getOnboardingV2InProgress);
  const demoAccount = useSelector(selectDemoAccount);

  const onOpenCreateAccountWithoutBankDialog = useCallback(() => {
    setShowCreateAccountHandleDialog(true);
  }, []);

  const onCloseCreateAccountWithoutBankDialog = useCallback(() => {
    setShowCreateAccountHandleDialog(false);
  }, []);

  const onSetIsOnboardingInProgress = useCallback(
    (value: boolean) => {
      dispatch(onboardingActions.setOnboardingV2InProgress(value));
    },
    [dispatch],
  );

  const onCloseVideo = useCallback(() => {
    dispatch(onboardingActions.setShowVideo(false));
  }, [dispatch]);

  const onOpenRemoveDemoDataDialog = useCallback(() => {
    setShowRemoveDemoDataDialog(true);
  }, []);

  const onCloseRemoveDemoDataDialog = useCallback(() => {
    setShowRemoveDemoDataDialog(false);
  }, []);

  const onPlayOnboardingV2Video = useCallback(() => {
    dispatch(onboardingActions.setShowVideo(true));
  }, [dispatch]);

  const onCreateDemoData = useCallback(() => {
    if (activeStep) {
      dispatch(
        onboardingActions.updateOnboardingStep({
          ...activeStep,
          stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
        }),
      );
    }

    dispatch(onboardingActions.createOnboardingDemoData());
  }, [dispatch, activeStep]);

  const onCreateOnboardingStep = useCallback(
    (data: CreateOnboardingStepPayload) => {
      dispatch(onboardingActions.createOnboardingStep(data));
    },
    [dispatch],
  );

  const onSetPreLastStepActive = useCallback(() => {
    dispatch(
      onboardingActions.createOnboardingStep({
        stepName: ONBOARDING_STEPS.PRE_LAST,
        status: ONBOARDING_STATUS.ACTIVE,
      }),
    );

    if (activeStep) {
      dispatch(
        onboardingActions.updateOnboardingStep({
          ...activeStep,
          status: ONBOARDING_STATUS.PASSED,
        }),
      );
    }
  }, [dispatch, activeStep]);

  const onRemoveDemoData = useCallback(() => {
    if (preLastStep) {
      dispatch(onboardingActions.deleteOnboardingStep({ id: preLastStep._id }));
    }

    if (setupCabinetStep) {
      dispatch(
        onboardingActions.updateOnboardingStep({
          ...setupCabinetStep,
          status: ONBOARDING_STATUS.SKIP,
        }),
      );
    }

    if (demoAccount) {
      dispatch(
        accountActions.deleteAccount({ id: demoAccount._id, showReload: true }),
      );
    }
  }, [dispatch, demoAccount, preLastStep, setupCabinetStep]);

  const onDeleteIntegrationStep = useCallback(() => {
    if (integrationStep) {
      dispatch(
        onboardingActions.deleteOnboardingStep({ id: integrationStep._id }),
      );
    }
  }, [dispatch, integrationStep]);

  const isIntegrationActiveStep = useMemo(
    () => activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION,
    [activeStep],
  );

  const isAnalyticActiveStep = useMemo(
    () => activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC,
    [activeStep],
  );

  const isIntegrationLogActiveSubStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
    [activeStep],
  );

  const isAddAccountActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_ADD_ACCOUNT,
    [activeStep],
  );

  const isCreateAccountActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_ACCOUNT,
    [activeStep],
  );

  const isAddOperationActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_ADD_OPERATION,
    [activeStep],
  );

  const isCreateOperationActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_OPERATION,
    [activeStep],
  );

  const isAddImportActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_ADD_IMPORT,
    [activeStep],
  );

  const isCreateImportActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_IMPORT,
    [activeStep],
  );

  const isIntegrationLogActiveStep = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
    [activeStep],
  );

  const isAnalyticFilterSubStepActive = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_FILTERS,
    [activeStep],
  );

  const isAnalyticCategoriesSubStepActive = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_CATEGORIES,
    [activeStep],
  );

  const isAnalyticChartSubStepActive = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_CHART,
    [activeStep],
  );

  const isAnalyticLogFiltersSubStepActive = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC &&
      activeStep?.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_LOG_FILTERS,
    [activeStep],
  );

  const isAnalyticLogOperationSubStepActive = useMemo(
    () =>
      activeStep?.stepName === ONBOARDING_STEPS.ANALYTIC &&
      activeStep?.stepSubCategory ===
        ONBOARDING_SUB_STEPS.ANALYTIC_LOG_OPERATION,
    [activeStep],
  );

  const onSkipIntegrationStep = useCallback(() => {
    if (integrationStep) {
      dispatch(
        onboardingActions.updateOnboardingStep({
          ...integrationStep,
          status: ONBOARDING_STATUS.SKIP,
        }),
      );
    }
  }, [dispatch, integrationStep]);

  const setCreateAccountStepActive = useCallback(() => {
    if (integrationStep) {
      dispatch(
        onboardingActions.createOnboardingStep({
          stepName: ONBOARDING_STEPS.INTEGRATION,
          status: ONBOARDING_STATUS.ACTIVE,
          stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_ACCOUNT,
        }),
      );
    }
  }, [dispatch, integrationStep]);

  const updateIntegrationStep = useCallback(
    (stepSubCategory: ONBOARDING_SUB_STEPS) => {
      if (integrationStep) {
        dispatch(
          onboardingActions.updateOnboardingStep({
            ...integrationStep,
            stepSubCategory,
          }),
        );
      }
    },
    [integrationStep, dispatch],
  );

  const onSetAnalyticSubStepPassed = useCallback(
    (stepSubCategory: ONBOARDING_SUB_STEPS) => {
      if (activeStep) {
        dispatch(
          onboardingActions.updateOnboardingStep({
            ...activeStep,
            stepSubCategory,
            status: ONBOARDING_STATUS.PASSED,
          }),
        );
      }
    },
    [activeStep, dispatch],
  );

  const onSetAnalyticSubStepActive = useCallback(
    (stepSubCategory: ONBOARDING_SUB_STEPS) => {
      if (analyticStep) {
        dispatch(
          onboardingActions.updateOnboardingStep({
            ...analyticStep,
            stepSubCategory,
            status: ONBOARDING_STATUS.ACTIVE,
          }),
        );
      }
    },
    [analyticStep, dispatch],
  );

  const onCreateFinalStep = useCallback(() => {
    onCreateOnboardingStep({
      stepName: ONBOARDING_STEPS.FINAL,
      status: ONBOARDING_STATUS.ACTIVE,
    });
  }, [onCreateOnboardingStep]);

  const onFinalOnboarding = useCallback(() => {
    if (finalStep) {
      dispatch(
        onboardingActions.updateOnboardingStep({
          ...finalStep,
          status: ONBOARDING_STATUS.PASSED,
        }),
      );
    }

    dispatch(
      onboardingActions.createOnboardingStep({
        stepName: ONBOARDING_STEPS.ONBOARDING_COMPLETED,
        status: ONBOARDING_STATUS.PASSED,
      }),
    );

    dispatch(authActions.updateProfile());
  }, [dispatch, finalStep]);

  const onSetSetNextAnalyticStepActive = useCallback(
    (event?: SyntheticEvent) => {
      event?.stopPropagation();

      if (activeStep) {
        if (
          activeStep.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_FILTERS
        ) {
          onSetAnalyticSubStepPassed(ONBOARDING_SUB_STEPS.ANALYTIC_FILTERS);
          onCreateOnboardingStep({
            stepName: ONBOARDING_STEPS.ANALYTIC,
            status: ONBOARDING_STATUS.ACTIVE,
            stepSubCategory: ONBOARDING_SUB_STEPS.ANALYTIC_CATEGORIES,
          });
        } else if (
          activeStep.stepSubCategory ===
          ONBOARDING_SUB_STEPS.ANALYTIC_CATEGORIES
        ) {
          onSetAnalyticSubStepPassed(ONBOARDING_SUB_STEPS.ANALYTIC_CATEGORIES);
          onCreateOnboardingStep({
            stepName: ONBOARDING_STEPS.ANALYTIC,
            status: ONBOARDING_STATUS.ACTIVE,
            stepSubCategory: ONBOARDING_SUB_STEPS.ANALYTIC_CHART,
          });
        } else if (
          activeStep.stepSubCategory === ONBOARDING_SUB_STEPS.ANALYTIC_CHART
        ) {
          onSetAnalyticSubStepPassed(ONBOARDING_SUB_STEPS.ANALYTIC_CHART);
          onCreateFinalStep();
        } else if (
          activeStep.stepSubCategory ===
          ONBOARDING_SUB_STEPS.ANALYTIC_LOG_FILTERS
        ) {
          onSetAnalyticSubStepPassed(ONBOARDING_SUB_STEPS.ANALYTIC_LOG_FILTERS);
          onCreateOnboardingStep({
            stepName: ONBOARDING_STEPS.ANALYTIC,
            status: ONBOARDING_STATUS.ACTIVE,
            stepSubCategory: ONBOARDING_SUB_STEPS.ANALYTIC_LOG_OPERATION,
          });
        } else if (
          activeStep.stepSubCategory ===
          ONBOARDING_SUB_STEPS.ANALYTIC_LOG_OPERATION
        ) {
          onSetAnalyticSubStepPassed(
            ONBOARDING_SUB_STEPS.ANALYTIC_LOG_OPERATION,
          );
          onCreateFinalStep();
        }
      }
    },
    [
      activeStep,
      onCreateFinalStep,
      onCreateOnboardingStep,
      onSetAnalyticSubStepPassed,
    ],
  );

  const onboardingCompleted = !!user?.onboardingCompleted;

  return {
    activeStep,
    onCloseVideo,
    onCreateDemoData,
    onRemoveDemoData,
    onCreateFinalStep,
    onFinalOnboarding,
    onboardingCompleted,
    isAnalyticActiveStep,
    isAddImportActiveStep,
    updateIntegrationStep,
    onSkipIntegrationStep,
    onCreateOnboardingStep,
    onSetPreLastStepActive,
    isAddAccountActiveStep,
    onboardingV2InProgress,
    onPlayOnboardingV2Video,
    onDeleteIntegrationStep,
    isIntegrationActiveStep,
    showRemoveDemoDataDialog,
    isAddOperationActiveStep,
    isCreateImportActiveStep,
    isCreateAccountActiveStep,
    isIntegrationLogActiveStep,
    onOpenRemoveDemoDataDialog,
    setCreateAccountStepActive,
    onSetAnalyticSubStepPassed,
    onSetAnalyticSubStepActive,
    onSetIsOnboardingInProgress,
    onCloseRemoveDemoDataDialog,
    onboardingV2VideoInProgress,
    isCreateOperationActiveStep,
    isAnalyticChartSubStepActive,
    isIntegrationLogActiveSubStep,
    isAnalyticFilterSubStepActive,
    showCreateAccountHandleDialog,
    onSetSetNextAnalyticStepActive,
    isAnalyticLogFiltersSubStepActive,
    isAnalyticCategoriesSubStepActive,
    isAnalyticLogOperationSubStepActive,
    onOpenCreateAccountWithoutBankDialog,
    onCloseCreateAccountWithoutBankDialog,
  };
}

export default useOnboardingV2;
