import { makeStyles } from '@material-ui/core/styles';

import {
  black,
  catskillWhite,
  regentStBlue,
  white,
} from '../../../../theme/colors';

export const useStyles = makeStyles({
  sellDateContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '16px 0',
    justifyContent: 'space-between',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 8,
    },
  },
  sellDateInfoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sellDateText: {
    fontSize: 15,
    lineHeight: '20px',
  },
  sellDateDescription: {
    fontSize: 12,
    lineHeight: '16px',
    opacity: 0.5,
  },
  helpInfoIcon: {
    opacity: 0.4,
  },
  tooltipContainer: {
    width: 240,
    background: white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 25,
    color: black,
    fontSize: 13,
    lineHeight: '20px',
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
  },
  arrow: {
    color: white,
    width: 15,
  },
  sellDateCalendarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  compareRoot: {
    background: regentStBlue,
    borderRadius: 12,
    padding: '5px 0 10px 15px',
    cursor: 'inherit',
  },
  icon: {
    marginRight: 20,
  },
  variantRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin16: {
    marginBottom: 16,
  },
});
