import { black, outrageousOrange } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 24,
    maxWidth: 448,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '30px',
    maxWidth: 380,
    marginBottom: 26,
  },
  subTitle: {
    fontSize: 15,
    lineHeight: '20px',
    opacity: 0.5,
  },
  selectContainer: {
    marginLeft: 0,
    marginBottom: 26,
  },
  button: {
    width: 150,
    [theme.breakpoints.down(768)]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  buttonRemove: {
    background: 'rgba(255, 99, 51, 0.1)',
  },
  buttonCancel: {
    width: 210,
    background: 'rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.down(768)]: {
      width: '100%',
    },
  },
  buttonRemoveText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: outrageousOrange,
  },
  buttonCancelText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: black,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
    },
  },
  checkboxRoot: {
    paddingLeft: 0,
  },
  description: {
    opacity: 0.5,
    marginBottom: 24,
  },
}));
