import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '../../../../../assets/images/svg/check-circle.svg';
import SuccessIcon from '../../../../../assets/images/svg/success.svg';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import Loader from '../../../../../components/Loader/LottieLoader';
import commonApi from '../../../../../store/common/api';
import { useStyles } from './styles';

function SetupAndImplementFirstDialog({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleOrderService = useCallback(async () => {
    setLoading(true);

    try {
      await commonApi.requestToSetupAndImplement();

      setLoading(false);
      setSuccess(true);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  }, []);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      className={classes.dialogRoot}
      customRootTitleClasses={classes.dialogTitleContainer}
      title={t('setupImplementation.ourServices')}
    >
      <>
        {!loading && success && (
          <div className={classes.successContainer}>
            <img
              src={SuccessIcon}
              alt="success"
              className={classes.iconSuccess}
            />
            <Typography className={classes.successTitle}>
              {t('setupImplementation.success')}
            </Typography>
            <Typography className={classes.successDescription}>
              {t('setupImplementation.successDescription')}
            </Typography>
            <CustomButton
              fullWidth
              wrapperRootClassName={classes.buttonSuccess}
              title={t('common.ok')}
              action={onClose}
            />
          </div>
        )}
        {loading && !success && (
          <div className={classes.successContainer}>
            <Loader size="small" />
          </div>
        )}
        {!loading && !success && (
          <>
            <div className={classes.container}>
              <div className={classes.containerContent}>
                <div className={cn(classes.block, classes.blockSeparator)}>
                  <div className={classes.blockTitleContent}>
                    <span>🙂</span>
                    <Typography className={classes.blockTitle}>
                      {t('setupImplementation.subTitles.first')}
                    </Typography>
                  </div>
                  <div className={classes.subTitleContainer}>
                    <Typography className={classes.bold}>
                      {t('setupImplementation.descriptions.checkUp.title')}
                    </Typography>
                  </div>
                  <div className={classes.descriptionContainer}>
                    <div
                      className={cn(
                        classes.descriptionRow,
                        classes.descriptionRowSeparator,
                      )}
                    >
                      <img
                        src={CheckIcon}
                        alt="check"
                        className={classes.icon}
                      />
                      <Typography className={classes.description}>
                        {t('setupImplementation.descriptions.checkUp.first', {
                          audit: (
                            <Typography
                              className={cn(classes.description, classes.bold)}
                            >
                              {t(
                                'setupImplementation.descriptions.checkUp.audit',
                              )}
                            </Typography>
                          ),
                        })}
                      </Typography>
                    </div>
                    <div className={classes.descriptionRow}>
                      <img
                        src={CheckIcon}
                        alt="check"
                        className={classes.icon}
                      />
                      <Typography className={classes.description}>
                        {t('setupImplementation.descriptions.checkUp.second')}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={cn(classes.block, classes.blockSeparator)}>
                  <div className={classes.blockTitleContent}>
                    <span>🙂</span>
                    <Typography className={classes.blockTitle}>
                      {t('setupImplementation.subTitles.second')}
                    </Typography>
                  </div>
                  <div className={classes.subTitleContainer}>
                    <Typography className={classes.bold}>
                      {t('setupImplementation.descriptions.manager.title')}
                    </Typography>
                  </div>
                  <div className={classes.descriptionContainer}>
                    <div>
                      <div className={classes.descriptionRow}>
                        <img
                          src={CheckIcon}
                          alt="check"
                          className={classes.icon}
                        />
                        <Typography className={classes.description}>
                          {t('setupImplementation.descriptions.manager.first', {
                            help: (
                              <Typography
                                className={cn(
                                  classes.description,
                                  classes.bold,
                                )}
                              >
                                {t(
                                  'setupImplementation.descriptions.manager.help',
                                )}
                              </Typography>
                            ),
                          })}
                        </Typography>
                      </div>
                      <ul className={classes.listWrapper}>
                        <li className={classes.description}>
                          {t('setupImplementation.descriptions.manager.data')}
                        </li>
                        <li className={classes.description}>
                          {t(
                            'setupImplementation.descriptions.manager.reports',
                          )}
                        </li>
                        <li className={classes.description}>
                          {t(
                            'setupImplementation.descriptions.manager.recommends',
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className={classes.descriptionRow}>
                      <img
                        src={CheckIcon}
                        alt="check"
                        className={classes.icon}
                      />
                      <Typography className={classes.description}>
                        {t('setupImplementation.descriptions.manager.second')}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.block}>
                  <div className={classes.blockTitleContent}>
                    <span>🙂</span>
                    <Typography className={classes.blockTitle}>
                      {t('setupImplementation.subTitles.third')}
                    </Typography>
                  </div>
                  <div className={classes.subTitleContainer}>
                    <Typography className={classes.bold}>
                      {t('setupImplementation.descriptions.consultation.title')}
                    </Typography>
                  </div>
                  <div className={classes.descriptionContainer}>
                    <div className={classes.descriptionRow}>
                      <img
                        src={CheckIcon}
                        alt="check"
                        className={classes.icon}
                      />
                      <Typography className={classes.description}>
                        {t(
                          'setupImplementation.descriptions.consultation.first',
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <CustomButton
                title={t('setupImplementation.orderService')}
                action={handleOrderService}
                errorText={error ? t('system.error') : ''}
              />
            </div>
          </>
        )}
      </>
    </Dialog>
  );
}

export default React.memo(SetupAndImplementFirstDialog);
