import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SHOW_ADDITIONAL_TIME_PERIODS } from '../constants/featureToggles/featureToggle';
import { Routes } from '../constants/routes';
import {
  selectAdditionalPeriods,
  selectPeriods,
} from '../store/filters/selectors';
import { PeriodId } from '../store/journal/types';
import useUnleashGetVariant from './useUnleashGetVariant';

function useTimePeriods() {
  const additionalTimePeriodsProps = useUnleashGetVariant(
    SHOW_ADDITIONAL_TIME_PERIODS,
  );

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const enabled = additionalTimePeriodsProps?.enabled;
  const periodPayload = additionalTimePeriodsProps?.payload;

  const timePeriods = useSelector(selectPeriods);
  const additionalTimePeriods = useSelector(selectAdditionalPeriods);

  const periodsList = enabled
    ? [...additionalTimePeriods, ...timePeriods]
    : timePeriods;

  const defaultTimePeriodId =
    enabled && pathname !== Routes.LOG
      ? (periodPayload?.value as PeriodId)
      : PeriodId.allTime;

  const defaultTimePeriod = periodsList.find(
    (el) => el.id === defaultTimePeriodId,
  );

  return {
    periodsList,
    initialDefaultPeriod: defaultTimePeriod
      ? {
          id: defaultTimePeriod.id,
          startDate: defaultTimePeriod.value[0],
          endDate: defaultTimePeriod.value[1],
        }
      : {
          id: PeriodId.allTime,
          startDate: undefined,
          endDate: undefined,
        },
    defaultTimePeriod: defaultTimePeriod ?? {
      id: PeriodId.allTime,
      name: t('filter.allTime'),
      value: [undefined, undefined],
    },
  };
}

export default useTimePeriods;
