import 'react-calendar/dist/Calendar.css';
import './style.scss';
import { Dialog, Input, Typography } from '@material-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import ChevronLeftIcon from '../../../../assets/images/svg/arrow-left.svg';
import ChevronRightIcon from '../../../../assets/images/svg/arrow-right.svg';
import ArrowDown from '../../../../assets/images/svg/dropdown-black.svg';
import ArrowUp from '../../../../assets/images/svg/dropup-black.svg';
import TimeIcon from '../../../../assets/images/svg/time.svg';
import i18n from '../../../../i18n';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import { FuncDate } from './CalendarComponent.types';
import { useStyles } from './CalendarDialog.styles';
import { Props } from './CalendarDialog.types';

const currentDate = [new Date(), new Date()];
const defaultMaxDate = new Date('2041-01-01');
const defaultMinDate = new Date('1979-01-01');

const prev2Label = (
  <div style={{ display: 'flex' }}>
    <img src={ChevronLeftIcon} alt="left" />
    <img src={ChevronLeftIcon} alt="left" />
  </div>
);

const next2Label = (
  <div style={{ display: 'flex' }}>
    <img src={ChevronRightIcon} alt="right" />
    <img src={ChevronRightIcon} alt="right" />
  </div>
);

let localeOverride = i18n.language;

if (i18n.language === 'uz') {
  localeOverride = 'uz-latn';
}

if (i18n.language === 'cz') {
  localeOverride = 'cs';
}

function CalendarDialog(props: Props) {
  const { value, onClose, isSelectRange, onChange, maxDate, minDate } = props;
  const { t } = useTranslation();

  const [locale, setLocale] = useState(localeOverride);
  const [showTimeView, setShowTimeView] = useState(false);
  const [timeHours, setTimeHours] = useState('');
  const [timeMinutes, setTimeMinutes] = useState('');

  const classes = useStyles();

  const handleSwitchTimeView = useCallback(() => {
    setShowTimeView(!showTimeView);
  }, [showTimeView]);

  const handleChangeHours = useCallback((event: SyntheticEvent) => {
    // @ts-ignore
    const { value: amount } = event.target;
    const newValue = Number(amount);

    if (!isNaN(newValue)) {
      if (newValue >= 0 && newValue < 24) {
        if (newValue < 10) {
          setTimeHours(`0${newValue}`);
        } else {
          setTimeHours(newValue.toString());
        }
      }
    } else if (!amount) {
      setTimeHours('');
    }
  }, []);

  const handleChangeMinutes = useCallback((event: SyntheticEvent) => {
    // @ts-ignore
    const { value: amount } = event.target;
    const newValue = Number(amount);

    if (!isNaN(newValue)) {
      if (newValue >= 0 && newValue < 60) {
        if (newValue < 10) {
          setTimeMinutes(`0${newValue}`);
        } else {
          setTimeMinutes(newValue.toString());
        }
      }
    } else if (!amount) {
      setTimeMinutes('');
    }
  }, []);

  const handleIncreaseHours = useCallback(() => {
    if (timeHours) {
      const newValue = Number(timeHours) + 1;

      if (newValue >= 0 && newValue < 24) {
        if (newValue < 10) {
          setTimeHours(`0${newValue}`);
        } else {
          setTimeHours(newValue.toString());
        }
      }
    }
  }, [timeHours]);

  const handleDecreaseHours = useCallback(() => {
    if (timeHours) {
      const newValue = Number(timeHours) - 1;

      if (newValue >= 0 && newValue < 24) {
        if (newValue < 10) {
          setTimeHours(`0${newValue}`);
        } else {
          setTimeHours(newValue.toString());
        }
      }
    }
  }, [timeHours]);

  const handleIncreaseMinutes = useCallback(() => {
    if (timeMinutes) {
      const newValue = Number(timeMinutes) + 1;

      if (newValue >= 0 && newValue < 60) {
        if (newValue < 10) {
          setTimeMinutes(`0${newValue}`);
        } else {
          setTimeMinutes(newValue.toString());
        }
      }
    }
  }, [timeMinutes]);

  const handleDecreaseMinutes = useCallback(() => {
    if (timeMinutes) {
      const newValue = Number(timeMinutes) - 1;

      if (newValue >= 0 && newValue < 60) {
        if (newValue < 10) {
          setTimeMinutes(`0${newValue}`);
        } else {
          setTimeMinutes(newValue.toString());
        }
      }
    }
  }, [timeMinutes]);

  const handleChange = useCallback(
    (date: Date | Date[]) => {
      if (!Array.isArray(date)) {
        const newDate = new Date();

        let hours;
        let minutes;
        if (timeHours) {
          hours = Number(timeHours);
        } else {
          hours = newDate.getHours();
        }

        if (timeMinutes) {
          minutes = Number(timeMinutes);
        } else {
          minutes = newDate.getMinutes();
        }

        const seconds = newDate.getSeconds();

        const val =
          moment(date as Date)
            .set('hours', hours)
            .set('minutes', minutes)
            .set('seconds', seconds)
            .valueOf() + getTimeOffset(date);

        onChange(val);
      } else {
        const date1 =
          new Date(date[0]).getTime() -
          new Date(date[0]).getTimezoneOffset() * 60 * 1000;
        const date2 =
          new Date(date[1]).getTime() -
          new Date(date[1]).getTimezoneOffset() * 60 * 1000;

        onChange([date1, date2]);
      }
    },
    [onChange, timeHours, timeMinutes],
  );

  const navigationLabel = useCallback(
    ({ date }: FuncDate) => (
      <Typography className={classes.navigationTitle}>
        {moment(date).locale(locale).format('MMMM YYYY').toLocaleUpperCase()}
      </Typography>
    ),
    [classes, locale],
  );

  const onChangeByClose = useCallback(() => {
    if (value) {
      handleChange(value);
    }
  }, [handleChange, value]);

  const handleClose = useCallback(
    (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (!isSelectRange) {
        onChangeByClose();
      }

      switch (reason) {
        case 'backdropClick': {
          onClose();
          break;
        }
        case 'escapeKeyDown':
          break;
        default:
          onClose();
      }
    },
    [onClose, onChangeByClose, isSelectRange],
  );

  useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      setLocale(lng);
    });
  }, []);

  useEffect(() => {
    if (value) {
      // @ts-ignore
      const currentHours = new Date(value).getHours();
      // @ts-ignore
      const currentMinutes = new Date(value).getMinutes();

      if (currentHours >= 0 && currentHours <= 24) {
        if (currentHours >= 0 && currentHours < 10) {
          // @ts-ignore
          setTimeHours(`0${currentHours}`);
        } else {
          // @ts-ignore
          setTimeHours(currentHours);
        }
      }

      if (currentMinutes >= 0 && currentMinutes <= 60) {
        if (currentMinutes >= 0 && currentMinutes < 10) {
          // @ts-ignore
          setTimeMinutes(`0${currentMinutes}`);
        } else {
          // @ts-ignore
          setTimeMinutes(currentMinutes);
        }
      }
    }
  }, [value]);

  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{
        className: classes.calendarModalContainer,
      }}
    >
      <div
        data-at-id="date-calendar"
        className={!isSelectRange ? classes.fullHeight : classes.shortHeight}
      >
        <Calendar
          maxDate={maxDate || defaultMaxDate}
          minDate={minDate || defaultMinDate}
          value={value || currentDate}
          locale={locale}
          className={classes.daysOfWeek}
          navigationLabel={navigationLabel}
          onChange={handleChange}
          prev2Label={prev2Label}
          next2Label={next2Label}
          prevLabel={<img src={ChevronLeftIcon} alt="left" />}
          nextLabel={<img src={ChevronRightIcon} alt="right" />}
          selectRange={isSelectRange}
        />
      </div>
      {showTimeView && (
        <div className={classes.timeFullBlock}>
          <img src={TimeIcon} alt="time" className={classes.timeIcon} />
          <div className={classes.repeatsSmallContainer}>
            <Input
              autoFocus={showTimeView}
              value={timeHours}
              onChange={handleChangeHours}
            />
            <div className={classes.column}>
              <img src={ArrowUp} alt="up" onClick={handleIncreaseHours} />
              <img src={ArrowDown} alt="down" onClick={handleDecreaseHours} />
            </div>
          </div>
          <Typography className={classes.separator}>:</Typography>
          <div className={classes.repeatsSmallContainer}>
            <Input value={timeMinutes} onChange={handleChangeMinutes} />
            <div className={classes.column}>
              <img src={ArrowUp} alt="up" onClick={handleIncreaseMinutes} />
              <img src={ArrowDown} alt="down" onClick={handleDecreaseMinutes} />
            </div>
          </div>
        </div>
      )}
      {!showTimeView && !isSelectRange && (
        <div className={classes.timeBlock} onClick={handleSwitchTimeView}>
          <img src={TimeIcon} alt="time" />
          <Typography className={classes.time}>
            {t('operationDialogs.time')}
          </Typography>
        </div>
      )}
    </Dialog>
  );
}

export default React.memo(CalendarDialog);
