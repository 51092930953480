import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import lottieLoader from '../../assets/newLoader.json';

type Props = {
  w?: number;
  h?: number;
  speed?: number;
  size?: 'small' | 'medium' | 'large';
};

function LottieLoader(props: Props) {
  const { w, h, speed = 1, size } = props;

  let width;
  let height;

  switch (size) {
    case 'small':
      width = 32;
      height = 32;
      break;
    case 'medium':
      width = 64;
      height = 64;
      break;
    case 'large':
      width = 132;
      height = 132;
      break;
    default:
      width = w;
      height = h;
      break;
  }

  return (
    <div className="container">
      <Player
        src={lottieLoader}
        className="player"
        loop
        autoplay
        speed={speed}
        style={{ height, width }}
      />
    </div>
  );
}

export default React.memo(LottieLoader);
