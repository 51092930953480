import { black,white } from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  tooltipContainer: {
    width: 240,
    background: white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    paddingBottom: 25,
    color: black,
    fontSize: 13,
    lineHeight: '20px',
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
  },
  arrow: {
    color: white,
    width: 15,
  },
});
