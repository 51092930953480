import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import commonApi from '../common/api';
import { ActionTypes as OperationActionTypes } from '../operations/actions';
import {
  CREATE_CUSTOM_CURRENCY_RATES_PENDING,
  GET_CURRENCIES_PENDING,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCY_PROVIDERS_PENDING,
  GET_CURRENCY_PROVIDERS_SUCCESS,
  GET_CUSTOM_CURRENCY_RATES_PENDING,
  GET_CUSTOM_CURRENCY_RATES_SUCCESS,
  GET_DEFAULT_CURRENCY_PROVIDER_PENDING,
  GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS,
  GET_EXCHANGE_RATE_PENDING,
  GET_EXCHANGE_RATE_SUCCESS,
  REMOVE_CUSTOM_CURRENCY_RATES_PENDING,
  UPDATE_DEFAULT_CURRENCY_PROVIDER_PENDING,
} from './actions';
import currenciesApi from './api';
import {
  CreateCustomCurrencyRatesPayload,
  GetCustomCurrencyRatesPayload,
  RemoveCustomCurrencyRatesPayload,
  UpdateDefaultCurrencyProviderPayload,
} from './sagas.types';
import { GetExchangeRateFromToPayload } from './types';

function* getCurrencies(): SagaIterator {
  try {
    const requests = yield all([
      call(commonApi.getCurrencies),
      call(commonApi.getCryptoCurrencies),
    ]);

    yield put({
      type: GET_CURRENCIES_SUCCESS,
      payload: {
        currencies: requests[0].data,
        cryptoCurrencies: requests[1].data,
      },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getExchangeRateToFrom(
  action: TAction<GetExchangeRateFromToPayload>,
) {
  try {
    const { from, to } = action.payload;

    const { data } = yield call(commonApi.getExchangeRateFromTo, to, from);

    const rate = data?.rate || '';

    yield put({
      type: OperationActionTypes.GET_EXCHANGE_RATE_TO_FROM_SUCCESS,
      payload: { rate },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getExchangeRateFromTo(
  action: TAction<GetExchangeRateFromToPayload>,
) {
  try {
    const { from, to } = action.payload;

    const { data } = yield call(commonApi.getExchangeRateFromTo, from, to);

    const rate = data?.rate || '';

    yield put({
      type: OperationActionTypes.GET_EXCHANGE_RATE_FROM_TO_SUCCESS,
      payload: { rate },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getExchangeRate(
  action: TAction<GetCustomCurrencyRatesPayload>,
) {
  try {
    const { from, to } = action.payload;
    const { data } = yield call(commonApi.getExchangeRateFromTo, from, to);

    yield put({ type: GET_EXCHANGE_RATE_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getCurrencyProviders() {
  try {
    const { data: providers } = yield call(currenciesApi.getCurrencyProviders);

    yield put({ type: GET_CURRENCY_PROVIDERS_SUCCESS, payload: { providers } });
  } catch (error) {
    showError(error);
  }
}

function* getDefaultCurrencyProvider() {
  try {
    const { data: provider } = yield call(
      currenciesApi.getDefaultCurrencyProvider,
    );

    yield put({
      type: GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS,
      payload: { provider },
    });
  } catch (error) {
    showError(error);
  }
}

function* createCustomCurrencyRates(
  action: TAction<CreateCustomCurrencyRatesPayload>,
) {
  try {
    const { to, from, rate } = action.payload;

    yield call(commonApi.createCustomExchangeRateFromTo, from, to, rate);

    yield put({ type: GET_CUSTOM_CURRENCY_RATES_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* getCustomCurrencyRates() {
  try {
    const { data } = yield call(commonApi.getCustomExchangeRates);

    yield put({ type: GET_CUSTOM_CURRENCY_RATES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* updateDefaultCurrencyProvider(
  action: TAction<UpdateDefaultCurrencyProviderPayload>,
) {
  try {
    const { providerId } = action.payload;

    yield call(currenciesApi.updateDefaultCurrencyProvider, providerId);
  } catch (error) {
    showError(error);
  }
}

function* removeCustomCurrencyRates(
  action: TAction<RemoveCustomCurrencyRatesPayload>,
) {
  try {
    const { to, from } = action.payload;

    yield call(commonApi.deleteCustomExchangeRateFromTo, from, to);

    yield put({ type: GET_CUSTOM_CURRENCY_RATES_PENDING });
  } catch (error) {
    showError(error);
  }
}

export default function currenciesSagas() {
  return all([
    takeLatest(GET_CURRENCIES_PENDING, getCurrencies),
    takeLatest(GET_CURRENCY_PROVIDERS_PENDING, getCurrencyProviders),
    takeLatest(
      GET_DEFAULT_CURRENCY_PROVIDER_PENDING,
      getDefaultCurrencyProvider,
    ),
    takeLatest(
      UPDATE_DEFAULT_CURRENCY_PROVIDER_PENDING,
      updateDefaultCurrencyProvider,
    ),
    takeLatest(GET_CUSTOM_CURRENCY_RATES_PENDING, getCustomCurrencyRates),
    takeLatest(REMOVE_CUSTOM_CURRENCY_RATES_PENDING, removeCustomCurrencyRates),
    takeLatest(CREATE_CUSTOM_CURRENCY_RATES_PENDING, createCustomCurrencyRates),
    takeLatest(
      OperationActionTypes.GET_EXCHANGE_RATE_FROM_TO_PENDING,
      getExchangeRateFromTo,
    ),
    takeLatest(
      OperationActionTypes.GET_EXCHANGE_RATE_TO_FROM_PENDING,
      getExchangeRateToFrom,
    ),
    takeLatest(GET_EXCHANGE_RATE_PENDING, getExchangeRate),
  ]);
}
