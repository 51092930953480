import React from 'react';

import './close-icon.module.scss';
import CloseIconSvg from '../../assets/images/svg/close-24-24.svg';

export interface CloseIconProps {
  isActive?: boolean;
  onClick?(event: any): void;
  width?: number;
  height?: number;
}

export function CloseIcon({ 
  isActive = true, 
  onClick,
  height = 16,
  width = 16,
}: CloseIconProps) {
  if(!isActive) return null;
  return <img
  onClick={onClick}
  alt="close"
  src={CloseIconSvg}
  width={width}
  height={height}
/>
}

export default CloseIcon;