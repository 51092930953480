export const woodsmoke = '#181D1F';
export const catskillWhite = '#EDF4F7';
export const white = '#FFFFFF';
export const black = '#000000';
export const lightBlack16 = 'rgba(0,0,0,0.16)';
export const lightBlack5 = 'rgba(0,0,0,0.5)';
export const lightBlack = 'rgba(0,0,0,0.3)';
export const jade = '#00B246';
export const aquamarine = '#8AFFB9';
export const salmonapprox = '#FF8D70';
export const codGray = '#131313';
export const aquaHaze = '#EEF3F5';
export const outrageousOrange = '#FF6333';
export const persianGreen = '#00B28E';
export const lightPersianGreen = 'rgba(0,178,142,0.3)';
export const coral = '#FF7247';
export const curiousBlue = '#2580E2';
export const flamingo = '#F5473B';
export const vividTangerine = '#FF957A';
export const pictonBlue = '#39CCF8';
export const cornflowerBlue = '#7A80FF';
export const tickleMePink = '#FF7092';
export const conifer = '#85DA51';
export const yellowOrange = '#FFBF42';
export const heliotrope = '#A783FB';
export const shamrock = '#35C979';
export const turmeric = '#B2CD45';
export const lavenderMagenta = '#E573E1';
export const yellowOrange2 = '#FFAC4B';
export const sunflower = '#E5DE27';
export const mercury = '#E5E5E5';
export const blue = '#062EFF';
export const goldenTainoi = '#FFD064';
export const silver = '#C4C4C4';
export const mystic = '#E4EAED';
export const alto = '#D8D8D8';
export const blueRibbon = '#0651FF';
export const royalBlue = '#204EE9';
export const jungleGreen = '#27AE60';
export const pictonBlue3 = '#29A9EB';

export const mineShaft = '#3D3D3D';
export const bittersweet = '#FF6B6B';
export const casablanca = '#F5C04E';
export const salmon = '#FF8B6E';
export const strikemaster = '#875676';
export const chestnutRose = '#D16976';
export const redOrange012 ='rgb(255, 63, 46, 0.12)';
export const heliotropeConsumption = '#A983FB';
export const turquoise = '#3DCCCC';
export const yellowOrangeConsumption = '#FFA24D';
export const toast = '#9D7067';
export const baliHai = '#8095A8';
export const diSerria = '#DAA260';
export const regentStBlue = '#ADD8E6';
export const shamrock2 = '#35CC9F';
export const chetwodeBlue = '#7994D8';
export const wildStrawberry = '#FF4797';
export const havelockBlue = '#4E7CD6';
export const turquoise2 = '#28DDD7';
export const tropicalBlue = '#CCE5F7';
export const conifer2 = '#A7EE53';
export const picasso = '#FDF69E';
export const downy = '#60CFB0';

export const dodgerBlue = '#3FB2FD';
export const roseFog = '#E7B8B8';
export const lightOrchid = '#DD94D6';
export const jaffa = '#EE8532';
export const lavenderPink = '#FA9DBC';
export const atlantis = '#70CE31';
export const easternBlue = '#1EAABD';
export const melrose = '#B9A9FC';
export const pictonBlue2 = '#3EAFE9';
export const hitPink = '#FBA486';

export const sandalap = '#AB966F';
export const darkBurgundyap = '#731104';
export const royalHeathap = '#B73C6C';
export const swampGreenap = '#A5B691';
export const shuttleGrayap = '#585E6C';
export const kashmirBlueap = '#4A5A9C';
export const fuzzyWuzzyBrownap = '#C44B49';
export const violaap = '#CF91A7';
export const seaBuckthornap = '#FBA42B';
export const butterflyBushap = '#54508B';
export const bismarkap = '#458086';
export const tapestryap = '#A0479F';
export const tangoap = '#E85A13';
export const spicyMixap = '#895F3F';
export const hippieBlueap = '#5F8FB3';
export const gunPowderap = '#434257';
export const sushiap = '#84AE38';
export const flaxSmokeap = '#788463';

export const lightGray = 'rgba(0, 0, 0, 0.06)';
