import { IconButton, Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutoCompleteComponent from '../../../../components/AutocompleteComponent';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import { DEFAULT_UKRAINE_CURRENCY_SYMBOL } from '../../../../constants';
import { getOperationProps } from '../../../../store/operations/selectors';
import { Project } from '../../../../store/projects/types';
import useSelectProps from '../../HOC/createOperationHooks/useSelectProps';
import { useStyles } from './styles';
import { TrashIcon } from './TrashIcon';
import { ProjectRowProps } from './types';

const initialLabelText: {
  sum: string | undefined;
  percentages: string | undefined;
} = {
  sum: undefined,
  percentages: undefined,
};

function ProjectRow(props: ProjectRowProps) {
  const {
    hint,
    rowId,
    project,
    projects,
    onDeleteRow,
    errorsInRow,
    onCreateProject,
    selectedProjects,
    handleChangeAmount,
    handleChangeProject,
    currencyValueSymbol,
    isPermittedToBeDeleted,
    handleChangePercentages,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { creatingProjectInProgress } = useSelectProps();
  const { amount, currencyAmount, account } = useSelector(getOperationProps);

  const reduceByText = t('splitPaymentDialog.reduceBy');
  const sumText = `${t('common.sum')}, ${currencyValueSymbol}`;
  const percentagesText = `${t('common.percentage')}, %`;

  const [isLabelNecessary, setIsLabelNecessary] = useState(false);
  const [labelText, setLabelText] = useState(initialLabelText);

  const getHintValues = useMemo(() => {
    if (!hint || hint?.text === reduceByText) {
      return null;
    }

    return Number(hint?.sum) > 0 &&
      hint?.sum !==
        (account?.currencyId === DEFAULT_UKRAINE_CURRENCY_SYMBOL
          ? currencyAmount
          : amount) &&
      project.sum === ''
      ? { sum: hint?.sum, percentages: hint?.percentages }
      : null;
  }, [
    hint,
    amount,
    project.sum,
    reduceByText,
    currencyAmount,
    account?.currencyId,
  ]);

  useEffect(() => {
    if (project.sum === '' && getHintValues) {
      setIsLabelNecessary(true);
      setLabelText({ sum: sumText, percentages: percentagesText });
    } else {
      setIsLabelNecessary(false);
      setLabelText({ sum: undefined, percentages: undefined });
    }
  }, [
    project.sum,
    t,
    currencyValueSymbol,
    getHintValues,
    sumText,
    percentagesText,
  ]);

  const onProjectChange = useCallback(
    (val: Project): void => {
      if (errorsInRow) {
        errorsInRow.projectError = false;
      }

      handleChangeProject(rowId, val);
    },
    [errorsInRow, handleChangeProject, rowId],
  );

  const onSumChange = useCallback(
    (sum: number): void => {
      if (errorsInRow) {
        errorsInRow.sumPercentagesError = false;
      }

      handleChangeAmount(rowId, sum);
    },
    [errorsInRow, handleChangeAmount, rowId],
  );

  const onPercentagesChange = useCallback(
    (percentages: number): void => {
      if (errorsInRow) {
        errorsInRow.sumPercentagesError = false;
      }

      handleChangePercentages(rowId, percentages > 100 ? 100 : percentages);
    },
    [errorsInRow, handleChangePercentages, rowId],
  );

  return (
    <div className={cn(classes.flexRow, classes.flexGap16, classes.marginB16)}>
      <div className={cn(classes.flexCol50)}>
        <AutoCompleteComponent
          onChange={onProjectChange}
          onCreate={onCreateProject}
          data={projects}
          type="project"
          value={project.project}
          className={cn(
            classes.width100,
            classes.marginB0,
            errorsInRow?.projectError ? classes.errorBackground : '',
          )}
          allowedCreate
          loading={creatingProjectInProgress}
          getOptionDisabled={(option: Project) =>
            selectedProjects.includes(option)
          }
          disablePortal={false}
          isProjectSplitDropdown
        />
      </div>
      <div className={cn(classes.flexCol50)}>
        <TextFieldComponent
          rootClassName={cn(
            classes.amountContainer,
            errorsInRow?.sumPercentagesError ? classes.errorBackground : '',
          )}
          value={project.sum || ''}
          placeholder={getHintValues?.sum ?? sumText}
          label={labelText.sum}
          isLabelNecessary={isLabelNecessary}
          onChange={onSumChange}
        />
        <div className={classes.textFlexCol}>
          <Typography className={cn(classes.title)}>
            {`${t('splitPaymentDialog.or')}`}
          </Typography>
        </div>
        <TextFieldComponent
          rootClassName={cn(
            classes.amountContainer,
            errorsInRow?.sumPercentagesError ? classes.errorBackground : '',
          )}
          value={project.percentages || ''}
          placeholder={getHintValues?.percentages ?? percentagesText}
          label={labelText.percentages}
          isLabelNecessary={isLabelNecessary}
          onChange={onPercentagesChange}
        />
        <div className={classes.deleteBtn}>
          {isPermittedToBeDeleted && (
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => onDeleteRow(rowId)}
            >
              <TrashIcon />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProjectRow);
