import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import AttachmentIcon from '../../../assets/images/svg/attachment.svg';
import { useStyles } from '../importStyles';

type Props = {
  injectedDragRootClasses?: string;
  hideDescription?: boolean;
  customTitle?: string;

  onDrop(acceptedFiles: any): void;
}

function RenderSelectFileComponent(props: Props) {
  const { onDrop, injectedDragRootClasses, hideDescription, customTitle } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={cn(classes.dragRoot, injectedDragRootClasses)} {...getRootProps()}>
      <input {...getInputProps()} data-cy="file-input" />
      {customTitle && !hideDescription && (
        <Typography className={cn(classes.dropTitle, classes.margin32)}>
            {t('invoicing.addLogo')}
          </Typography>
      )}
      {!customTitle && !hideDescription && (
        <>
          <Typography className={classes.dropTitle}>
            {t('import.dropdown.title', {
              postProcess: 'sprintf',
              sprintf: [10],
            })}
          </Typography>
          <Typography className={cn(classes.smallText, classes.margin8)}>
            {t('import.or')}
          </Typography>
        </>
      )}

      <div className={classes.dropButton}>
        <img
          className={classes.dropIcon}
          src={AttachmentIcon}
          alt="attachment"
        />
        <Typography className={classes.smallText}>
          {t('import.dropdown.choose')}
        </Typography>
      </div>
    </div>
  )
}

export default React.memo(RenderSelectFileComponent);
