import React from 'react';
import { useTranslation } from 'react-i18next';

import AlertDialog from '../../../../../components/AlertDialog';
import { useStyles } from './styles';

function OnboardingNoAvailable({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AlertDialog
      onClose={onClose}
      title={t('onboardingV2.noAvailable')}
      description={t('onboardingV2.noAvailableDescription')}
      onAccept={onClose}
      acceptText={t('onboardingV2.accept')}
      buttonBackground={classes.buttonAlertBackground}
    />
  );
}

export default React.memo(OnboardingNoAvailable);
