import { makeStyles } from '@material-ui/core/styles';

import { globalStyles } from '../../../../styles/global-styles';
import { aquaHaze, jade } from '../../../../theme/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 56,
    marginBottom: 20,
    borderRadius: 12,
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  rootSelect: {
    marginBottom: 0,
  },
  input: {
    ...globalStyles.input,
    ...globalStyles.alignCenter,
    position: 'relative',
    padding: '12px 16px',
    color: 'rgb(84, 84, 84)',
    border: '1px solid #b6b9aa',
    pointerEvents: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    '&:hover': {
      border: '1px solid transparent',
      '-webkit-appearance': 'none',
      boxShadow: '0 0 38px rgba(85,205,161,0.41)',
      '-webkit-box-shadow': '0 0 38px rgba(85,205,161,0.41)',
    },
  },
  smallPadding: {
    padding: '12px 20px',
  },
  selectRange: {
    padding: '8px 10px',
    minWidth: 155,
    fontSize: 13,
    borderBottom: '3px solid #57de8b',
    '&:hover': {
      borderBottom: '3px solid #57de8b',
    },
  },
  inputWithError: {
    marginBottom: 10,
  },
  calendarNone: {
    display: 'none',
  },
  calendarBlock: {
    display: 'block',
  },
  daysOfWeek: {
    color: '#3e98a3',
    minHeight: 360,
  },
  icon: {
    top: '44%',
    right: '2%',
    position: 'absolute',
    opacity: 0.3,
  },
  income: {
    padding: 0,
  },
  calendarModalContainer: {
    width: 304,
    maxWidth: 'none',
    borderRadius: 10,
    overflowX: 'hidden',
    background: '#fff',
    textAlign: 'left',
    boxSizing: 'border-box',
    '-webkit-appearance': 'none',
  },
  fullHeight: {
    height: 400,
  },
  shortHeight: {
    height: 360,
  },
  navigationTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  time: {
    marginLeft: 11,
    opacity: 0.5,
    cursor: 'pointer',
  },
  timeFullBlock: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 24,
    paddingTop: 16,
    borderTop: '0.5px solid rgba(0, 0, 0, 0.2)',
    zIndex: 1000000,
  },
  timeBlock: {
    width: '89%',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000000,
    position: 'absolute',
    bottom: 0,
    borderTop: '0.5px solid rgba(0, 0, 0, 0.2)',
  },
  timeValueContainer: {
    width: 84,
    height: 48,
  },
  separator: {
    padding: '0 10px',
  },
  repeatsSmallContainer: {
    width: 52,
    height: 48,
    borderRadius: 12,
    backgroundColor: aquaHaze,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
  },
  timeIcon: {
    marginRight: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});
