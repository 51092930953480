import moment from 'moment';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class KaspiPdfParser extends BaseXlsxParser {
  protected startObj;

  protected COLUMNS_MAP = {
    date: ['Дата'],
    sum: ['Сумма'],
    operation: ['Операция'],
    details: ['Детали'],
  };

  protected SPEC_SYMBOLS = {
    '%2C': ',',
    '%3A': ':',
    '%2B': '+',
    '%2F': '/',
    '%26': '&',
    '%3B': ';',
    '&quot;': '"',
    '%23': '#',
  };

  protected EXCLUDE_FIELDS = [
    'Сумма заблокирована. Банк ожидает подтверждения от платежной системы.',
    'АО «Kaspi Bank», БИК CASPKZKA, www.kaspi.kz',
  ];

  protected importName = 'Kaspi';
  private mainAccount: Account;
  protected beforeTransformed: AnyObject[];

  public setRawData(raw, account) {
    this.mainAccount = account;
    const data = typeof raw === 'string' ? JSON.parse(raw) : raw;
    this.sheetJson = this.parseToJson(data);
    this.startObj = this.getStartY(this.sheetJson);
    if (!this.startObj) return; //error invalid file
  }

  public import(acounts: AnyObject[]) {
    this.acounts = acounts;
    this.beforeTransformed = this.doBeforeTranform();
    this.transformedData = this.beforeTransformed
      .map(this.transformOne.bind(this))
      .filter((res) => Object.keys(res).length);
    const result = this.doAfterTranform();
    return result;
  }

  protected doBeforeTranform(): AnyObject[] {
    const { positions, y, page } = this.startObj;
    const lines = this.sheetJson;
    const result = [];
    for (let i = 0; i < lines.length; i++) {
      if (i < page) continue;
      const items = lines[i];
      for (const itemKey of Object.keys(items)) {
        if (Number(itemKey) <= Number(y) && i === page) continue;
        const item = items[itemKey];
        let counter = 0;
        const obj = positions.reduce((acc, cur) => {
          if (item[counter]) acc[cur] = item[counter].text || null;
          else acc[cur] = null;
          counter++;
          return acc;
        }, {});
        let exclude = false;
        for (const excludeField of this.EXCLUDE_FIELDS) {
          if (obj[positions[0]]?.includes(excludeField)) {
            exclude = true;
            break;
          }
        }
        if (!exclude) result.push(obj);
        counter = 0;
      }
    }
    return result;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const { date, sum, operation: type, details } = operation;
    const currentDate = moment(date.trim(), 'DD.MM.YY', true);
    if (!currentDate.isValid()) {
      //in this case date it is a first field
      const previousOperation = this.beforeTransformed[index - 1];
      if (previousOperation.comment) previousOperation.comment += date.trim();
      this.beforeTransformed[index - 1].comment = date.trim();
      return {};
    }
    const errors = this.getErrors();
    const correctDate = this.dateParser(date);
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(correctDate).isAfter(moment().add(10, 'y'));
      isBefore = moment(correctDate).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const sumWithCurrency = sum.trim();
    const stringSum = (
      sumWithCurrency.slice(0, sumWithCurrency.length - 1) as any
    ).replaceAll(' ', '');
    const currencySymbol = sumWithCurrency.slice(sumWithCurrency.length - 1);
    const typeComment = type.trim();
    const detailsComment = details.trim();
    const parsedSum = this.parseSum(stringSum);
    if (isNaN(parsedSum))
      this.throwError(`${sum} - ${errors.sumNotValid}`, index);
    const now = moment();
    const dateResult = moment(correctDate)
      .startOf('day')
      .add(now.seconds(), 'second')
      .add(now.minutes(), 'minute')
      .add(now.hours(), 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    const cur = CURRENCIES.find((cur) => cur.symbol === currencySymbol)?.code;

    const result: any = {
      index,
      date: dateResult,
      comment: typeComment,
      sum: parsedSum,
      counterparty: detailsComment,
    };
    if (cur) result.currency = cur;

    if (stringSum[0] === '-') {
      result.type = OperationType.CONSUMPTION;
      result.subType = 'supplier';
      result.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      result.type = OperationType.INCOME;
      result.subType = 'sale';
      result.accountToId = this.mainAccount.normalizedLabel;
    }
    this.setAccountIDsByType(result, cur, cur);
    return result;
  }

  private getStartY(items) {
    const rawLines = Object.keys(this.COLUMNS_MAP).map((key) => ({
      column: this.COLUMNS_MAP[key].map((el) =>
        (el as any).replaceAll(' ', ''),
      ),
      key,
    }));
    for (let i = 0; i < items.length; i++) {
      //const allData of items){
      const allData = items[i];
      for (const key of Object.keys(allData)) {
        const line = allData[key].map((line) =>
          (line.text as any).replaceAll(' ', ''),
        );
        if (line.length < rawLines.length) continue;
        let finded = true;
        const positions = [];
        for (const { column, key } of rawLines) {
          let position = 0;
          if (
            !column.reduce((acc, cur) => {
              return (
                acc ||
                !!line.find((str, i) => {
                  if (str === cur) position = i;
                  return str === cur;
                })
              );
            }, false)
          ) {
            finded = false;
            break;
          }
          positions[position] = key;
        }
        if (!finded) continue;
        return { y: key, page: i, positions };
      }
    }
    return null;
  }

  private parseToJson(pages) {
    const lines = [];
    let counter = 0;
    for (const Texts of pages) {
      lines[counter] = {};
      for (const { R, x, y, w, sw, A } of Texts) {
        if (!R) continue;
        for (const { T, S, TS } of R) {
          if (!T) continue;
          if (!lines[counter][y]) lines[counter][y] = [];
          let text = decodeURI(T);
          Object.keys(this.SPEC_SYMBOLS).forEach(
            (key) =>
              (text = (text as any).replaceAll(key, this.SPEC_SYMBOLS[key])),
          );
          lines[counter][y].push({ text, x, w, sw, A, S, TS });
        }
      }
      counter++;
    }
    return lines;
  }
}
