import { useSelector } from 'react-redux';
import { UnleashClient } from 'unleash-proxy-client';

import { BASE_URL, UNLEASH_SECRET } from '../../constants';
import { getUser } from '../../store/auth/selectors';
import { getCompany } from '../../store/company/selectors';
import { isLocalhost } from '../../utils/isLocalhost';
import { UnleashProvider } from './UnleashContext';

const LOCALHOST_UNLEASH_URL = `${
  BASE_URL.includes('localhost') ? 'https://qa.finmap.online/v2/' : BASE_URL
}features/proxy`;

const unleash = new UnleashClient({
  url: isLocalhost()
    ? LOCALHOST_UNLEASH_URL
    : `${window.location.origin}/v2/features/proxy`,
  clientKey: UNLEASH_SECRET,
  appName: 'finmap',
  refreshInterval: 120,
});

function Unleash({ children }: { children: JSX.Element | JSX.Element[] }) {
  const user = useSelector(getUser);
  const company = useSelector(getCompany);

  if (unleash) {
    const properties: { [key: string]: string } = {};
    if (company?._id) properties.companyId = company._id;

    unleash.updateContext({
      userId: user?._id,
      properties: Object.values(properties).length ? properties : undefined,
    });

    unleash.stop();
    unleash.start();
  }

  return <UnleashProvider value={{ unleash }}>{children}</UnleashProvider>;
}

export default Unleash;
