import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from '../services/bot';
import {
  GENERATE_TELEGRAM_API_KEY_PENDING,
  GENERATE_TELEGRAM_API_KEY_SUCCESS,
  GET_TELEGRAM_API_KEYS_PENDING,
  GET_TELEGRAM_API_KEYS_SUCCESS,
} from '../store/integrations/actions';
import { selectTelegramApiKeys } from '../store/integrations/selectors';
import { ApiKey } from '../store/integrations/types';
import { showError } from '../utils/showError';

function* generateTelegramApiKey(): SagaIterator {
  try {
    const apiKeys = yield select(selectTelegramApiKeys);
    const filter = apiKeys.filter((el: ApiKey) => el.name === 'telegram');

    if (filter.length) {
      yield all(
        filter.map((el: ApiKey) => call(api.deleteTelegramApiKey, el._id)),
      );
    }

    const { data } = yield call(api.generateTelegramApiKey);

    yield put({ type: GENERATE_TELEGRAM_API_KEY_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getTelegramApiKeys() {
  try {
    const { data } = yield call(api.getTelegramApiKeys);

    yield put({ type: GET_TELEGRAM_API_KEYS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export default function common() {
  return all([
    takeLatest(GENERATE_TELEGRAM_API_KEY_PENDING, generateTelegramApiKey),
    takeLatest(GET_TELEGRAM_API_KEYS_PENDING, getTelegramApiKeys),
  ]);
}
