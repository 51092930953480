import { Typography } from '@material-ui/core';
import cn from "classnames";
import React from 'react';

import { useStyles } from './styles';

export interface FilterTextProps {
  text?: string;
  className?: string;
  isActive?: boolean;
}

export function FilterText(props: FilterTextProps) {
  const { text = '', className, isActive = true, } = props;
  const classes = useStyles();

  if(!isActive) {
    return null;
  }

  return (
    <Typography className={cn(classes.text, className)}>
      {text}
    </Typography>
  );
}

export default FilterText;
