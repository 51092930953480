import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
} from '@finmap/core-constants';

import MsgToast from '../../components/MsgToast';
import {
  clientPrefixBySubType,
  DELAY_UPDATE_ANALYTIC,
  DELAY_UPDATE_GROUP,
  DELAY_UPDATE_GROUP_LONG,
  UPDATE_OPERATIONS_TOAST_ID,
} from '../../constants';
import { AnalyticRoutes, Routes } from '../../constants/routes';
import { TAction } from '../../sagas/types';
import { updateOperationsToast } from '../../sagas/utils';
import { showError } from '../../utils/showError';
import {
  GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
  GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
  GET_LOG_ACCOUNTS_PENDING,
  GET_LOG_ACCOUNTS_SUCCESS,
  GET_OPERATIONS_ACCOUNTS_PENDING,
} from '../accounts/actions';
import {
  GET_ACCOUNT_STATEMENT_PENDING,
  GET_CREDIT_DATA_PENDING,
  GET_DEBIT_DATA_PENDING,
  GET_MAIN_CHART_PENDING,
  GET_PROJECTS_DATA_PENDING,
} from '../analytic/actions';
import { GET_HISTORY_JOURNAL_PENDING } from '../analytic/history.actions';
import { selectTypeFilter } from '../analytic/selectors';
import { getUser, selectFuturePaymentsTotalMonths } from '../auth/selectors';
import { User } from '../auth/types';
import { GET_CALENDAR_PENDING } from '../calendar/actions';
import { categoryActionTypes } from '../categories/actions';
import { types } from '../clients/actions';
import { UrlPrefix } from '../clients/types';
import { selectCompanyId } from '../company/selectors';
import { RESET_RATES } from '../currency/actions';
import { SET_FILTERS_PERIOD } from '../filters/actions';
import { selectFilters } from '../filters/selectors';
import {
  GET_JOURNAL_OPERATIONS_COUNT_PENDING,
  GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
  GET_JOURNAL_PENDING,
  UPDATE_GROUP_LOG_OPERATIONS,
  UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS,
  UPDATE_ONE_FUTURE_OPERATION,
  UPDATE_ONE_LOG_OPERATION,
} from '../journal/actions';
import journalApi from '../journal/api';
import {
  getOperationsExist,
  selectFutureItems,
  selectJournalItems,
} from '../journal/selectors';
import { Journal, JournalData } from '../journal/types';
import { onboardingActionTypes } from '../onboardingV2/actions';
import {
  selectActiveOnboardingStep,
  selectCreateOperationActiveStep,
} from '../onboardingV2/selectors';
import {
  GET_OPERATION_PROJECTS_PENDING,
  GET_SETTINGS_PROJECTS_PENDING,
} from '../projects/actions';
import { GET_OPERATION_TAGS_PENDING } from '../tags/actions';
import {
  ActionTypes,
  CREATE_OPERATION_PENDING,
  CREATE_TRANSFER_PENDING,
  CREATE_TRANSFER_SUCCESS,
  DELETE_PREDICTION_FUTURE_OPERATIONS,
  GET_DATA_FOR_CREATE_DEBIT_OPERATION,
  MERGE_OPERATIONS_TO_TRANSFER,
  MERGE_PREDICTIONS_FUTURE_OPERATIONS,
  OPERATION_TO_TRANSFER,
  REMOVE_OPERATION_PENDING,
  UPDATE_BY_LOCATION,
  UPDATE_GROUP_ACCOUNT,
  UPDATE_GROUP_APPROVE,
  UPDATE_GROUP_CATEGORY,
  UPDATE_GROUP_CLIENT,
  UPDATE_GROUP_COMMENT,
  UPDATE_GROUP_PROJECT,
  UPDATE_GROUP_TAG,
  UPDATE_ONE_OPERATION_PENDING,
  UPDATE_ONE_OPERATION_SUCCESS,
  UPDATE_OPERATION_PENDING,
} from './actions';
import api from './api';
import {
  CreateOperationPayload,
  DeleteFuturePredictionPayload,
  GetOperationsItemsPayload,
  MergeFuturePredictionsPayload,
  MergeOperationsToTransferPayload,
  OperationToTransferPayload,
  RemoveOperationsPayload,
  UpdateContentPayload,
  UpdateGroupAccountPayload,
  UpdateGroupApprovePayload,
  UpdateGroupCategoryPayload,
  UpdateGroupClientPayload,
  UpdateGroupCommentPayload,
  UpdateGroupProjectPayload,
  UpdateGroupTagPayload,
  UpdateOneOperationPayload,
  UpdateOperationPayload,
  UpdateOperationsPayload,
} from './sagas.types';
import { getAttachment } from './selectors';
import { OperationType } from './types';

export function* updateContent(
  action: TAction<UpdateContentPayload>,
): SagaIterator {
  try {
    const {
      location,
      updateLog,
      isCreated,
      useProjectsCharts = false,
    } = action.payload;

    const pathname = location?.pathname;

    const { data } = yield call(journalApi.getOperationsCount);

    yield put({
      type: GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
      payload: { data: { logCount: Boolean(data) } },
    });

    if (pathname === Routes.ON_BOARDING) {
      window.location.replace(Routes.LOG);

      return;
    }

    if (pathname === Routes.LOG) {
      yield put({
        type: GET_JOURNAL_PENDING,
        payload: { init: false, update: updateLog, isCreated },
      });

      return;
    }

    if (pathname === Routes.CALENDAR) {
      yield put({ type: GET_CALENDAR_PENDING });
      yield put({
        type: GET_JOURNAL_PENDING,
        payload: { selector: 'calendar' },
      });

      return;
    }

    if (pathname === AnalyticRoutes.CASH_FLOW) {
      const typeFilter = yield select(selectTypeFilter);

      yield put({
        type: GET_MAIN_CHART_PENDING,
        payload: {
          selector: 'cashFlow',
          reportType: 'cash',
          typeFilter: typeFilter.id,
        },
      });

      return;
    }

    if (pathname === AnalyticRoutes.PROFIT_AND_LOSS) {
      const typeFilter = yield select(selectTypeFilter);

      yield put({
        type: GET_MAIN_CHART_PENDING,
        payload: {
          selector: 'profitAndLoss',
          reportType: 'profitAndLoss',
          typeFilter: typeFilter.id,
        },
      });

      return;
    }

    if (pathname === AnalyticRoutes.DEBIT) {
      yield put({ type: GET_JOURNAL_PENDING, payload: { selector: 'debit' } });

      yield delay(DELAY_UPDATE_ANALYTIC);

      yield put({ type: GET_DEBIT_DATA_PENDING });

      return;
    }

    if (pathname === AnalyticRoutes.CREDIT) {
      yield put({ type: GET_JOURNAL_PENDING, payload: { selector: 'credit' } });

      yield delay(DELAY_UPDATE_ANALYTIC);

      yield put({ type: GET_CREDIT_DATA_PENDING });

      return;
    }

    if (pathname === AnalyticRoutes.STATEMENT) {
      yield put({ type: GET_ACCOUNT_STATEMENT_PENDING });
      yield put({
        type: GET_JOURNAL_PENDING,
        payload: { selector: 'statement' },
      });

      return;
    }

    if (pathname === AnalyticRoutes.HISTORY) {
      yield put({
        type: GET_HISTORY_JOURNAL_PENDING,
        payload: { update: updateLog },
      });

      return;
    }

    if (pathname === AnalyticRoutes.PROJECTS) {
      yield delay(DELAY_UPDATE_ANALYTIC);

      yield put({
        type: GET_PROJECTS_DATA_PENDING,
        payload: { useProjectsCharts },
      });

      yield put({
        type: GET_JOURNAL_PENDING,
        payload: { selector: 'logProjects' },
      });

      return;
    }
  } catch (error) {
    showError(error);
  }
}

export function* createOperation(
  action: TAction<CreateOperationPayload>,
): SagaIterator {
  try {
    const operationExists = yield select(getOperationsExist);
    const user: User = yield select(getUser);
    const activeOnboardingStep = yield select(selectActiveOnboardingStep);
    const isCreateOperationActiveStep = yield select(
      selectCreateOperationActiveStep,
    );

    const {
      payload: { props, location, facebookContext },
    } = action;
    const { date, type, subType, isCopy, ...rest } = props;

    const attachment = yield select(getAttachment);

    const { data } = yield call(
      api.createOperation,
      type,
      subType,
      {
        date,
        ...rest,
      },
      isCopy,
    );

    if (attachment) {
      const attachmentPayload = {
        type,
        subType,
        fileName: attachment.label,
        operationId: data.operation._id,
        contentId: attachment.contentId,
        previewId: attachment.previewId,
      };
      yield call(api.addAttachmentToOperation, attachmentPayload);
    }

    if (facebookContext) {
      facebookContext.sendData();
    }

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
      payload: { futurePayments: data.futurePayments },
    });

    yield put({
      type: GET_LOG_ACCOUNTS_SUCCESS,
      payload: { data: data.accounts },
    });

    toast(i18next.t('toasts.operationSuccessfullySaved'));

    if (!user?.onboardingCompleted && !operationExists) {
      if (isCreateOperationActiveStep) {
        yield put({
          type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
          payload: {
            ...activeOnboardingStep,
            status: ONBOARDING_STATUS.PASSED,
          },
        });

        yield put({
          type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
          payload: {
            stepName: ONBOARDING_STEPS.INTEGRATION,
            stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
            status: ONBOARDING_STATUS.ACTIVE,
          },
        });
      } else {
        yield put({
          type: onboardingActionTypes.SET_PRE_LAST_STEP_SKIPPED,
          payload: { active: isCreateOperationActiveStep },
        });
      }
    }

    yield delay(DELAY_UPDATE_ANALYTIC);

    yield put({ type: RESET_RATES });

    const logFutureDateFilter = yield select(
      selectFilters('journal', 'futureFilter'),
    );
    const logDateFilter = yield select(selectFilters('journal', 'filters'));

    if (logDateFilter.id === undefined) {
      yield put({
        type: SET_FILTERS_PERIOD,
        payload: {
          ...logFutureDateFilter,
          startDate: undefined,
        },
      });
    }

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true, isCreated: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createTransfer(
  action: TAction<CreateOperationPayload>,
): SagaIterator {
  try {
    const {
      payload: { props, location },
    } = action;
    const { date, ...rest } = props;

    const attachment = yield select(getAttachment);
    const operationExists = yield select(getOperationsExist);
    const user: User = yield select(getUser);
    const activeOnboardingStep = yield select(selectActiveOnboardingStep);
    const isCreateOperationActiveStep = yield select(
      selectCreateOperationActiveStep,
    );

    const { data } = yield call(api.createTransfer, {
      date,
      ...rest,
    });

    yield put({ type: CREATE_TRANSFER_SUCCESS, payload: { data } });

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
      payload: { futurePayments: data.futurePayments },
    });

    yield put({
      type: GET_LOG_ACCOUNTS_SUCCESS,
      payload: { data: data.accounts },
    });

    if (attachment) {
      const attachmentPayload = {
        type: OperationType.transfer,
        fileName: attachment.label,
        operationId: data.operation._id,
        contentId: attachment.contentId,
        previewId: attachment.previewId,
      };
      yield call(api.addAttachmentToOperation, attachmentPayload);
    }

    toast(i18next.t('toasts.operationSuccessfullySaved'));

    if (!user?.onboardingCompleted && !operationExists) {
      if (isCreateOperationActiveStep) {
        yield put({
          type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
          payload: {
            ...activeOnboardingStep,
            status: ONBOARDING_STATUS.PASSED,
          },
        });

        yield put({
          type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
          payload: {
            stepName: ONBOARDING_STEPS.INTEGRATION,
            stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
            status: ONBOARDING_STATUS.ACTIVE,
          },
        });
      } else {
        yield put({
          type: onboardingActionTypes.SET_PRE_LAST_STEP_SKIPPED,
          payload: { active: isCreateOperationActiveStep },
        });
      }
    }

    yield delay(DELAY_UPDATE_ANALYTIC);

    yield put({ type: RESET_RATES });

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true, isCreated: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateOneOperation(
  action: TAction<UpdateOneOperationPayload>,
) {
  try {
    const {
      id,
      type,
      subType,
      data = {},
      callbackAction,
      location,
    } = action.payload;

    const {
      data: { operation, futurePayments },
    } = yield call(api.updateOperation, id, type, data, subType);

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
      payload: { futurePayments },
    });

    yield put({
      type: GET_LOG_ACCOUNTS_SUCCESS,
      payload: { data: operation.accounts },
    });

    yield put({
      type: UPDATE_ONE_OPERATION_SUCCESS,
      payload: {
        id,
        subType,
        categoryId: operation.categoryId,
        categoryName: data.categoryName,
      },
    });

    yield put({ type: UPDATE_BY_LOCATION, payload: { location } });

    if (callbackAction) {
      yield put({ type: callbackAction });
    }

    toast(i18next.t('toasts.operationSuccessfullySaved'));
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupOperations(
  action: TAction<UpdateOperationsPayload>,
): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { ids, payload, location } = action.payload;
    const logItems: JournalData[] = yield select(selectJournalItems);
    const futurePaymentsTotalMonths = yield select(
      selectFuturePaymentsTotalMonths,
    );

    for (let i = 0; i <= logItems.length - 1; i += 1) {
      const {
        type,
        _id,
        taxPeriodEndTimestamp,
        taxPeriodStartTimestamp,
        taxPeriodId,
        salaryPeriodEndTimestamp,
        salaryPeriodStartTimestamp,
        salaryPeriodId,
      } = logItems[i];
      const subType = payload.subType || logItems[i].subType;
      const {
        date,
        consumptionPeriodStartTimestamp,
        consumptionPeriodEndTimestamp,
      } = payload;

      const formatPayload = date
        ? { ...payload, date: date + i }
        : { ...payload };

      if (taxPeriodEndTimestamp && consumptionPeriodEndTimestamp) {
        formatPayload.taxPeriodEndTimestamp = consumptionPeriodEndTimestamp;
      }
      if (taxPeriodStartTimestamp && consumptionPeriodStartTimestamp) {
        formatPayload.taxPeriodStartTimestamp = consumptionPeriodStartTimestamp;
      }
      if (salaryPeriodEndTimestamp && consumptionPeriodEndTimestamp) {
        formatPayload.salaryPeriodEndTimestamp = consumptionPeriodEndTimestamp;
      }

      if (salaryPeriodStartTimestamp && consumptionPeriodStartTimestamp) {
        formatPayload.salaryPeriodStartTimestamp =
          consumptionPeriodStartTimestamp;
      }
      if (
        typeof salaryPeriodId === 'number' &&
        consumptionPeriodStartTimestamp
      ) {
        formatPayload.salaryPeriodId = 3;
      }
      if (typeof taxPeriodId === 'number' && consumptionPeriodStartTimestamp) {
        formatPayload.taxPeriodId = 3;
      }
      if (consumptionPeriodEndTimestamp) {
        formatPayload.consumptionPeriodEndTimestamp = undefined;
        if (!taxPeriodEndTimestamp && !salaryPeriodEndTimestamp) {
          (formatPayload as any).periodEndTimestamp =
            consumptionPeriodEndTimestamp;
        }
      }
      if (consumptionPeriodStartTimestamp) {
        formatPayload.consumptionPeriodStartTimestamp = undefined;
        if (!taxPeriodStartTimestamp && !salaryPeriodStartTimestamp) {
          (formatPayload as any).periodStartTimestamp =
            consumptionPeriodStartTimestamp;
        }
      }

      if (ids.includes(_id)) {
        yield call(api.updateOperation, _id, type, formatPayload, subType);
      }
    }

    yield delay(DELAY_UPDATE_GROUP);

    yield put({ type: RESET_RATES });
    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      payload: { futurePaymentsTotalMonths },
    });
    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
    updateOperationsToast(ids.length);
  } catch (error) {
    showError(error);
  }
}

export function* updateOperation(
  action: TAction<UpdateOperationPayload>,
): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { id, payload, location, isLongLoad } = action.payload;

    const attachment = yield select(getAttachment);

    const companyId = yield select(selectCompanyId);
    const {
      type,
      date,
      approved,
      subType,
      scheduled,
      isApprovedChanged,
      ...rest
    } = payload;

    let newApproved = approved;
    let newDate = date;

    if (approved && isApprovedChanged) {
      newDate = moment.utc().valueOf();
    }

    if (date) {
      const diff =
        moment(date).startOf('day').diff(moment().startOf('day'), 'day') >= 0;

      if (diff) {
        newApproved = approved;
      } else {
        newApproved = true;
      }
    }

    const data = {
      ...rest,
      scheduled,
      date: newDate,
      approved: newApproved,
      companyId,
      subType,
    };

    const { data: updatedData } = yield call(
      api.updateOperation,
      id,
      type,
      data,
      subType,
    );

    yield delay(isLongLoad ? DELAY_UPDATE_GROUP_LONG : DELAY_UPDATE_GROUP);

    if (attachment) {
      const attachmentPayload = {
        type,
        subType,
        fileName: attachment.label,
        operationId: id,
        contentId: attachment.contentId,
        previewId: attachment.previewId,
      };

      yield call(api.addAttachmentToOperation, attachmentPayload);
    }

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
      payload: { futurePayments: updatedData.futurePayments },
    });

    yield put({
      type: GET_LOG_ACCOUNTS_SUCCESS,
      payload: { data: updatedData.accounts },
    });

    yield put({ type: RESET_RATES });

    const futureItems: JournalData[] = yield select(selectFutureItems);
    const futureOperationIndex = futureItems.findIndex((el) => el._id === id);

    if (futureOperationIndex === -1 || scheduled) {
      if (location.pathname === Routes.LOG) {
        const {
          data: { items },
        }: { data: Journal } = yield call(api.getOperation, id);
        yield put({
          type: UPDATE_ONE_LOG_OPERATION,
          payload: { id, operation: items[0] },
        });
      } else {
        yield put({ type: UPDATE_BY_LOCATION, payload: { location } });
      }
    } else if (!updatedData.operation.approved) {
      const { data: operation }: { data: Journal } = yield call(
        api.getOperation,
        id,
      );

      yield put({
        type: UPDATE_ONE_FUTURE_OPERATION,
        payload: { data: operation.items[0] },
      });

      if (
        location.pathname === AnalyticRoutes.DEBIT ||
        location.pathname === AnalyticRoutes.CREDIT
      ) {
        yield put({ type: UPDATE_BY_LOCATION, payload: { location } });
      }
    } else {
      yield put({
        type: UPDATE_BY_LOCATION,
        payload: { location, updateLog: true },
      });
    }

    updateOperationsToast(1);
  } catch (error) {
    showError(error);
  }
}

export function* removeOperation({
  payload: { ids, location, scheduled },
}: TAction<RemoveOperationsPayload>): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    if (typeof ids === 'string') {
      const { data } = yield call(api.deleteOperation, ids, scheduled);

      yield put({ type: GET_JOURNAL_OPERATIONS_COUNT_PENDING });

      yield put({
        type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
        payload: { futurePayments: data.futurePayments },
      });

      yield put({
        type: GET_LOG_ACCOUNTS_SUCCESS,
        payload: { data: data.accounts },
      });

      if (scheduled) {
        yield put({ type: GET_JOURNAL_PENDING });
      } else {
        yield put({
          type: UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS,
          payload: { data: [ids] },
        });
      }

      updateOperationsToast(1);
    } else {
      const filterIds = ids.filter((id) => id && id !== 'future');

      yield call(api.deleteOperations, filterIds, true);

      yield delay(DELAY_UPDATE_GROUP);

      const futurePaymentsTotalMonths = yield select(
        selectFuturePaymentsTotalMonths,
      );

      yield put({
        type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
        payload: { futurePaymentsTotalMonths },
      });

      yield put({ type: GET_LOG_ACCOUNTS_PENDING });

      if (location.pathname === Routes.LOG) {
        if (ids.length > 1) {
          yield put({ type: GET_JOURNAL_PENDING });
        } else {
          yield put({ type: GET_JOURNAL_OPERATIONS_COUNT_PENDING });
          yield put({
            type: UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS,
            payload: { data: ids },
          });
        }

        updateOperationsToast(filterIds.length);
      } else {
        yield put({ type: UPDATE_BY_LOCATION, payload: { location } });
        updateOperationsToast(1);
      }
    }
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupCategory(
  action: TAction<UpdateGroupCategoryPayload>,
): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { categoryId, ids, type, subType, location } = action.payload;

    yield call(api.updateGroupCategory, {
      categoryId,
      ids,
      type,
      subType,
    });

    yield delay(DELAY_UPDATE_GROUP);

    const futurePaymentsTotalMonths = yield select(
      selectFuturePaymentsTotalMonths,
    );

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      payload: { futurePaymentsTotalMonths },
    });

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupProject(
  action: TAction<UpdateGroupProjectPayload>,
) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { projectId, ids, location } = action.payload;

    yield call(api.updateGroupProject, { projectId, ids });

    yield delay(DELAY_UPDATE_GROUP);

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupComment(
  action: TAction<UpdateGroupCommentPayload>,
) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { comment, ids, location } = action.payload;

    yield call(api.updateGroupComment, { comment, ids });

    yield delay(DELAY_UPDATE_GROUP);

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupAccount(
  action: TAction<UpdateGroupAccountPayload>,
): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { accountId, ids, location } = action.payload;

    yield call(api.updateGroupAccount, { accountId, ids });

    yield delay(DELAY_UPDATE_GROUP);

    const futurePaymentsTotalMonths = yield select(
      selectFuturePaymentsTotalMonths,
    );

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      payload: { futurePaymentsTotalMonths, updateProfile: false },
    });

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupApprove(
  action: TAction<UpdateGroupApprovePayload>,
): SagaIterator {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { ids, date, location } = action.payload;

    yield call(api.updateGroupApprove, { ids, date });

    yield delay(DELAY_UPDATE_GROUP);

    const futurePaymentsTotalMonths = yield select(
      selectFuturePaymentsTotalMonths,
    );

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      payload: { futurePaymentsTotalMonths },
    });

    yield put({
      type: GET_LOG_ACCOUNTS_PENDING,
    });

    updateOperationsToast(ids.length);
    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupTag(action: TAction<UpdateGroupTagPayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { tags, ids, replace, location } = action.payload;

    yield call(api.updateGroupTag, { tags, ids, replace });

    yield delay(DELAY_UPDATE_GROUP);

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);
    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupClient(action: TAction<UpdateGroupClientPayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { ids, type, subType, clientId, location, clientType, clientTypeId } =
      action.payload;

    yield call(api.updateGroupClient, {
      clientType,
      clientTypeId,
      ids,
      clientId,
      type,
      subType,
    });

    yield delay(DELAY_UPDATE_GROUP);

    const {
      data: { items },
    }: AxiosResponse<Journal> = yield call(api.getOperations, ids);

    yield put({
      type: UPDATE_GROUP_LOG_OPERATIONS,
      payload: { items },
    });

    updateOperationsToast(ids.length);
    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

function* getOperationsItems(action: TAction<GetOperationsItemsPayload>) {
  try {
    const { type, subType } = action.payload;

    yield put({ type: categoryActionTypes.RESET_CATEGORIES });

    yield put({ type: GET_OPERATIONS_ACCOUNTS_PENDING });
    yield put({ type: GET_OPERATION_TAGS_PENDING });
    yield put({ type: GET_OPERATION_PROJECTS_PENDING });
    yield put({ type: GET_SETTINGS_PROJECTS_PENDING });

    if (type === OperationType.income) {
      yield put({ type: categoryActionTypes.GET_OPERATION_INCOME_PENDING });
    } else if (type === OperationType.consumption) {
      yield put({
        type: categoryActionTypes.GET_OPERATION_CONSUMPTION_PENDING,
      });
    }

    if (subType) {
      // @ts-ignore
      const prefix = clientPrefixBySubType[subType];

      yield put({
        type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
        payload: { prefix },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* getDebitOperationItems() {
  try {
    yield put({ type: GET_OPERATIONS_ACCOUNTS_PENDING });
    yield put({ type: GET_OPERATION_TAGS_PENDING });
    yield put({ type: GET_OPERATION_PROJECTS_PENDING });
    yield put({
      type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
      payload: { prefix: UrlPrefix.borrowers },
    });
    yield put({
      type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
      payload: { prefix: UrlPrefix.clients },
    });
    yield put({
      type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
      payload: { prefix: UrlPrefix.investors },
    });
  } catch (error) {
    showError(error);
  }
}

function* mergeOperationsToTransfer(
  action: TAction<MergeOperationsToTransferPayload>,
) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { ids, location, createFeeOperation } = action.payload;

    yield call(api.mergeToTransfer, ids, createFeeOperation);

    yield delay(DELAY_UPDATE_GROUP);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });

    updateOperationsToast(ids.length);
  } catch (error) {
    showError(error);
  }
}

function* mergeFuturePredictionsOperations(
  action: TAction<MergeFuturePredictionsPayload>,
) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { transferData, type, subType, location } = action.payload;

    yield call(api.updateMergeOperation, type, subType, transferData);

    yield delay(DELAY_UPDATE_GROUP);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });

    updateOperationsToast(2);
  } catch (error) {
    showError(error);
  }
}

function* deleteFuturePrediction(
  action: TAction<DeleteFuturePredictionPayload>,
) {
  try {
    const { operationId, location } = action.payload;

    yield call(api.deleteFuturePrediction, operationId);

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

function* operationToTransfer(action: TAction<OperationToTransferPayload>) {
  try {
    toast(() => <MsgToast title={i18next.t('toasts.updateOperations')} />, {
      toastId: UPDATE_OPERATIONS_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { id, data, location } = action.payload;

    yield call(api.operationToTransfer, id, data);

    yield delay(DELAY_UPDATE_GROUP);
    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });

    updateOperationsToast(1);
  } catch (error) {
    showError(error);
  }
}

export default function company() {
  return all([
    takeLatest(CREATE_OPERATION_PENDING, createOperation),
    takeLatest(CREATE_TRANSFER_PENDING, createTransfer),
    takeLatest(REMOVE_OPERATION_PENDING, removeOperation),
    takeLatest(UPDATE_OPERATION_PENDING, updateOperation),
    takeLatest(
      ActionTypes.UPDATE_GROUP_OPERATION_PENDING,
      updateGroupOperations,
    ),
    takeLatest(ActionTypes.GET_CONTEXT_OPERATIONS_ITEMS, getOperationsItems),
    takeLatest(GET_DATA_FOR_CREATE_DEBIT_OPERATION, getDebitOperationItems),
    takeLatest(UPDATE_ONE_OPERATION_PENDING, updateOneOperation),
    takeLatest(UPDATE_GROUP_CATEGORY, updateGroupCategory),
    takeLatest(UPDATE_GROUP_PROJECT, updateGroupProject),
    takeLatest(UPDATE_GROUP_TAG, updateGroupTag),
    takeLatest(UPDATE_GROUP_CLIENT, updateGroupClient),
    takeLatest(UPDATE_GROUP_COMMENT, updateGroupComment),
    takeLatest(UPDATE_GROUP_APPROVE, updateGroupApprove),
    takeLatest(UPDATE_GROUP_ACCOUNT, updateGroupAccount),
    takeLatest(MERGE_OPERATIONS_TO_TRANSFER, mergeOperationsToTransfer),
    takeLatest(
      MERGE_PREDICTIONS_FUTURE_OPERATIONS,
      mergeFuturePredictionsOperations,
    ),
    takeLatest(DELETE_PREDICTION_FUTURE_OPERATIONS, deleteFuturePrediction),
    takeLatest(UPDATE_BY_LOCATION, updateContent),
    takeLatest(OPERATION_TO_TRANSFER, operationToTransfer),
  ]);
}
