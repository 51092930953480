import React from 'react';

import TrashIconSvg from '../../assets/images/svg/trash-red.svg';

export interface TrashIconProps {
  className?: string;
  onClick?(): void;
  isActive?: boolean;
}

export function TrashIcon({
  className,
  isActive = true,
  onClick,
}: TrashIconProps) {
  if(!isActive) return null;

  return <img
  alt="trash"
  src={TrashIconSvg}
  className={className}
  onClick={onClick}
/>
}

export default TrashIcon;