import { makeStyles } from '@material-ui/core/styles';

import {
  blueRibbon,
  persianGreen,
  white,
  woodsmoke,
} from '../../../theme/colors';

export const useStyles = makeStyles({
  root: {},
  primary: {
    background: 'rgba(238, 243, 245, 0.7)',
    border: `1px solid ${persianGreen}`,
  },
  secondary: {
    background: white,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  container: {
    cursor: 'pointer',
    borderRadius: 12,
    padding: '14px 20px',
    marginBottom: 32,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    position: 'relative',
    fontSize: 12,
    opacity: 0.5,
    verticalAlign: 'middle',
    top: -8,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom2: {
    marginBottom: 2,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoText: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '32px',
  },
  infoPeriodText: {
    fontWeight: 'bold',
    fontSize: 13,
    lineHeight: '27px',
    alignSelf: 'flex-end',
  },
  text: {
    fontSize: 13,
    lineHeight: '24px',
  },
  priceBadge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: blueRibbon,
    borderRadius: 8,
    padding: '3px 14px',
  },
  priceBadgeText: {
    color: white,
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    height: 20,
    background: blueRibbon,
    borderRadius: 8,
    marginLeft: 8,
  },
  activeBadge: {
    background: persianGreen,
  },
  badgeText: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    color: white,
  },
  restricted: {
    fontSize: 13,
    lineHeight: '20px',
    opacity: 0.5,
  },
  payForYear: {
    fontSize: 13,
    lineHeight: '20px',
    color: woodsmoke,
  },
  periodMainText: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  radioIcon: {
    marginRight: 11,
  },
  periodsBlock: {
    marginTop: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  disabled: {
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
  discountRowContainer: {
    display: 'flex',
  },
  discountRow: {
    fontSize: 13,
    lineHeight: '20px',
    color: blueRibbon,
  },
  bold: {
    fontWeight: 'bold',
  },
  flex: {
    display: 'flex',
  },
});
