import './styles.scss';
import { Typography } from '@material-ui/core';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import RepeatIcon from '../../assets/images/svg/arrow_repeat.svg';
import CheckmarkIcon from '../../assets/images/svg/checkmark.svg';
import CopyIcon from '../../assets/images/svg/copy.svg';
import TelegramIcon from '../../assets/images/svg/telegram.svg';
import Dialog from '../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../components/PreviewRegisterDialog';
import { TELEGRAM_URL } from '../../constants';
import integrationActions from '../../store/integrations/actions';
import {
  selectLoadingTelegramApi,
  selectTelegramApiKeys,
} from '../../store/integrations/selectors';
import { showError } from '../../utils/showError';
import { useStyles } from './styles';

function TelegramBotDialog({ onClose }: { onClose(): void }) {
  const dispatch = useDispatch();

  const loading = useSelector(selectLoadingTelegramApi);
  const apiKeys = useSelector(selectTelegramApiKeys);

  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const { t } = useTranslation();
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  const handleUpdateLoading = useCallback(() => {
    dispatch(integrationActions.generateTelegramNewApiKey());
  }, [dispatch]);

  const handleCopied = useCallback((event: SyntheticEvent<{ id: string }>) => {
    const { id } = event.currentTarget;

    setCopied(true);

    try {
      copy(id);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (e) {
      showError(e);
    }
  }, []);

  useEffect(() => {
    if (!apiKeys) {
      dispatch(integrationActions.getTelegramApiKeys());
    } else if (!apiKeys.length) {
      dispatch(integrationActions.generateTelegramNewApiKey());
    }
  }, [apiKeys, dispatch]);

  const lastApiKey = apiKeys?.length ? apiKeys[apiKeys.length - 1] : null;

  return (
    <>
      <Dialog isOpened title={t('telegramBot.title')} onClose={onClose}>
        <Typography className={cn(classes.title, classes.margin8)}>
          {t('telegramBot.stepOne.title')}
        </Typography>
        <Typography className={cn(classes.description, classes.margin8)}>
          {t('telegramBot.stepOne.description')}
        </Typography>
        <a
          id="test-telegram-bot-link"
          href={TELEGRAM_URL}
          target="_blank"
          rel="noreferrer"
          className={cn(classes.flex, classes.margin35)}
        >
          <img src={TelegramIcon} alt="telegram" className={classes.margin20} />
          <Typography className={classes.link}>
            {t('telegramBot.stepOne.link')}
          </Typography>
        </a>
        <Typography className={cn(classes.title, classes.margin8)}>
          {t('telegramBot.stepTwo.title')}
        </Typography>
        <Typography className={cn(classes.description, classes.margin8)}>
          {t('telegramBot.stepTwo.description')}
        </Typography>
        <div
          id={lastApiKey?.value || ''}
          onClick={handleCopied}
          className={cn(classes.copyField, 'copy-field')}
          data-tip={t('telegramBot.makeCopy')}
        >
          {loading && (
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
          {!loading && lastApiKey && (
            <Typography id="test-api-key">{lastApiKey.value}</Typography>
          )}
          {copied ? (
            <img id="test-checkmark-icon" src={CheckmarkIcon} alt="checkmark" />
          ) : (
            <img id="test-copy-icon" src={CopyIcon} alt="copy" />
          )}
        </div>
        <ReactTooltip
          className={classes.tooltipRoot}
          effect="solid"
          type="light"
          place="top"
          offset={{ right: 150 }}
          delayShow={1000}
        />
        <div
          id="test-generate-new"
          className={cn(classes.flex, classes.generate)}
          onClick={handleOpenPreviewRegisterDialog}
        >
          <img src={RepeatIcon} alt="copy" className={classes.margin20} />
          <Typography>{t('telegramBot.generate')}</Typography>
        </div>
      </Dialog>
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleUpdateLoading}
        />
      )}
    </>
  );
}

export default React.memo(TelegramBotDialog);
