import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  infoDialog: {
    maxWidth: 816,
    width: '100%',
  },
  rootWrapperText: {
    marginTop: 24,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20px',
    color: '#000000',
  },
  videoWrapper: {
    marginTop: 16,
  },
  infoBtn: {
    marginTop: 24,
    maxWidth: 293,
    width: '100%',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20px',
    color: '#000000',
  },
}));
