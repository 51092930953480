import Http from '../../services/Http';
import { Context } from '../types';

const getTags = (context: Context) =>
  Http.makeInstance().get(`/tags?context=${context}`);

const createTag = (label: string, context: Context) =>
  Http.makeInstance().post(`/tags?context=${context}`, {
    label,
  });

const updateTag = (id: string, label: string) =>
  Http.makeInstance().patch(`/tags/${id}`, {
    label,
  });

const updatePropTag = (id: string, data: any) =>
  Http.makeInstance().patch(`/tags/${id}?isBulkUpdate=true`, {
    ...data,
  });

const deleteTag = (id: string) => Http.makeInstance().delete(`/tags/${id}`);

export default {
  getTags,
  createTag,
  updateTag,
  deleteTag,
  updatePropTag,
};
