import { aquaHaze, black, persianGreen } from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  selector: {
    flex: 1,
  },
  qrcode: {
    background: aquaHaze,
    padding: '32px 0',
    display: 'flex',
    borderRadius: 20,
    justifyContent: 'center',
    marginBottom: 20,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 56,
    fontWeight: 'bold',
    fontSize: 16,
    color: black,
    borderRadius: 10,
    textTransform: 'none',
    fontFamily: 'Inter, sans-serif',
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
  icon: {
    marginRight: 8,
    top: 4,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  helpContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 24,
  },
  helpText: {
    opacity: 0.5,
  },
  link: {
    fontSize: 13,
    lineHeight: '20px',
    color: persianGreen,
    cursor: 'pointer',
  },
});
