import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze, mystic, persianGreen } from '../../../../theme/colors';

export const useStyles = makeStyles({
  step: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 8,
  },
  descriptionContainer: {
    marginTop: 0,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  marginBottom16: {
    marginBottom: 16,
  },
  marginBottom8: {
    marginBottom: 8,
  },
  copyField: {
    borderRadius: 12,
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: aquaHaze,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: mystic,
    },
    height: 56,
  },
  tooltipRoot: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '0 20px !important',
    opacity: '1 !important',
    fontSize: 13,
    lineHeight: '20px',
    height: 45,
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ipTextsBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 12,
    lineHeight: '16px',
    opacity: 0.5,
  },
  link: {
    display: 'inline-block',
    color: persianGreen,
    fontSize: 13,
    lineHeight: '20px',
    marginBottom: 24,
  },
  bold: {
    fontWeight: 'bold',
  },
  wrapperInputRoot: {
    paddingRight: 40,
  },
});
