import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';

import useSubscriptionActive from '../../hooks/useSubscriptionActive';
import { useStyles } from './styles';
import { Props } from './types';

function ButtonRowWithIcons(props: Props) {
  const {
    id,
    alt,
    icon,
    label,
    onClick,
    disabled,
    children,
    anchorRef,
    labelIcon,
    fitContent,
    wrapClasses,
    textColor = '#000',
    SecondIconComponent,
    showUnderLine = true,
    injectedFlexRowClasses,
    injectedRowWrapperClasses,
  } = props;
  const classes = useStyles({
    underLine: showUnderLine,
    fitContent,
  });
  const subscriptionActive = useSubscriptionActive();

  const handleClick = useCallback(() => {
    if (subscriptionActive) {
      onClick();
    }
  }, [subscriptionActive, onClick]);

  return (
    <div
      id={id}
      ref={anchorRef}
      className={cn(
        classes.root,
        (!subscriptionActive || disabled) && classes.cursorDefault,
        !showUnderLine && classes.lastRow,
        wrapClasses,
      )}
      onClick={handleClick}
    >
      <div
        className={cn(
          classes.rowWrapper,
          injectedRowWrapperClasses,
          (!subscriptionActive || disabled) && classes.disabled,
        )}
      >
        <div className={cn(classes.flexRow, injectedFlexRowClasses)}>
          {icon && <img src={icon} alt={alt} className={classes.icon} />}
          <Typography className={classes.text} style={{ color: textColor }}>
            {label}
            {labelIcon &&
              labelIcon.map((iconElem: string) => (
                <img
                  key={iconElem}
                  src={iconElem}
                  className={classes.labelIcon}
                  alt={alt}
                />
              ))}
          </Typography>
        </div>
        {Boolean(SecondIconComponent) && <SecondIconComponent />}
      </div>
      {children}
    </div>
  );
}

export default React.memo(ButtonRowWithIcons);
