import { isNotEmpty } from 'class-validator';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

const pattern = {
  '0': '7',
  '1': '.',
  '2': '4',
  '3': '8',
  '4': '1',
  В: '5',
  '6': ',',
  '7': '9',
  '8': '2',
  '.': '0',
  ':': '3',
  у: '5',
  я: '6',
  Довідка: 'Довідка',
  'по пру кохшів та паурткр від': 'про рух коштів на рахунку від',
  'Єата ю Сас': 'Дата і час',
  'їфба ф': 'Сума у',
  Єата: 'Дата',
  одмраМюК: 'операції',
  'з жпачіО р уоКді) ї з жпачіО в АшашрАі оРікрватте єтж':
    'Операції у холді: / Операції в статусі очікування (не',
  повжджті: 'проведені',
  У: ':',
  З: '-',
  А: '-',
  ' ': ' ',
};
export class NovaPayParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      // case 1, case 8
      {
        proceedCase: (importDocument) => {
          let oldComment = [];
          let newOperation = [];

          const addFields = (arr) => {
            if (arr[0])
              newOperation[0]
                ? (newOperation[0] += ` ${arr[0]}`)
                : (newOperation[0] = arr[0]);
            if (arr[1])
              newOperation[1]
                ? (newOperation[1] += ` ${arr[1]}`)
                : (newOperation[1] = arr[1]);
            if (arr[2])
              newOperation[2]
                ? (newOperation[2] += ` ${arr[2]}`)
                : (newOperation[2] = arr[2]);
            if (arr[3])
              newOperation[3]
                ? (newOperation[3] += ` ${arr[3]}`)
                : (newOperation[3] = arr[3]);
            if (arr[4])
              newOperation[4]
                ? (newOperation[4] += ` ${arr[4]}`)
                : (newOperation[4] = arr[4]);
            if (arr[5])
              newOperation[5]
                ? (newOperation[5] += ` ${arr[5]}`)
                : (newOperation[5] = arr[5]);
            if (arr[6])
              newOperation[6]
                ? (newOperation[6] += ` ${arr[6]}`)
                : (newOperation[6] = arr[6]);
            if (arr[7])
              newOperation[7]
                ? (newOperation[7] += ` ${arr[7]}`)
                : (newOperation[7] = arr[7]);
          };
          const result = [];
          importDocument.slice(1).forEach((arr) => {
            if (
              !arr[0] &&
              !arr[1] &&
              !arr[2] &&
              !arr[4] &&
              !arr[5] &&
              !arr[6] &&
              !arr[7] &&
              arr[3]
            ) {
              oldComment.push(arr[3]);
            } else if (arr[1] && moment(arr[1], 'DD.MM.YYYY', true).isValid()) {
              if (newOperation.length) {
                if (newOperation[3])
                  newOperation[3] += ` ${oldComment.join(' ')}`;
                else newOperation[3] = oldComment.join(' ');
                oldComment = [];
                result.push(newOperation);
                newOperation = [];
                addFields(arr);
              } else {
                newOperation[3] = oldComment.join(' ');
                oldComment = [];
                addFields(arr);
              }
            } else {
              addFields(arr);
            }
          });

          if (newOperation.length) {
            if (newOperation[3]) newOperation[3] += ` ${oldComment.join(' ')}`;
            else newOperation[3] = oldComment.join(' ');
            result.push(newOperation);
          }

          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(result);
          return (): ImportResultItemMask => {
            const [date, rawTime] = this.getFirstValidCellByColumn([
              'Дата',
              1,
            ]).split(' ');
            const time = `${date} ${rawTime}:00`;
            const comment =
              this.getFirstValidCellByColumn(['Корреспондент', 5]) +
              ' ' +
              this.getFirstValidCellByColumn(['Призначення', 3]);
            const sum = this.getFirstValidCellByColumn(['Сума', 2])
              .replaceAll(' ', '')
              .replaceAll(',', '.');
            return {
              date,
              time,
              comment,
              sum,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            {
              or: [
                [0, { eq: 'Виписка по особовому рахунку з' }],
                [0, { eq: 'Виписка/особовий рахунок з' }],
              ],
            },
            [1, { dateFormat: 'DD.MM.YYYY' }],
            [2, { eq: 'по' }],
            [3, { dateFormat: 'DD.MM.YYYY' }],
            [4, { eq: 'Документ' }],
          ],
          preParserConfigs: {
            pageSeparatorsLengthPx: 10,
            maxInterlineSpacingPx: 0.2,
            verticalAlign: 'middle', // 'top' | 'middle' | 'bottom' | 'one-line';
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Документ' }, nextWord: { eq: 'Дата' } }],
                to: [
                  {
                    or: [
                      { word: { eq: 'Статус*' }, prevWord: { eq: 'IBAN' } },
                      { word: { eq: 'IBAN' }, prevWord: { eq: 'Рахунок /' } },
                    ],
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      or: [
                        { word: { eq: 'Статус*' }, prevWord: { eq: 'IBAN' } },
                        {
                          word: { eq: 'IBAN' },
                          prevWord: { eq: 'Рахунок /' },
                          nextWord: { neq: 'Статус*' },
                        },
                      ],
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: '*Можливі статуси документів: О - оброблений системою, П - проведений.',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Сформовано:',
                      },
                      nextWord: { dateFormat: 'DD.MM.YYYY HH:mm:ss' },
                    },
                  ],
                  to: [
                    {
                      word: {
                        isNum: true,
                        replace: [
                          { from: ' ', to: '' },
                          { from: ',', to: '.' },
                        ],
                      },
                      prevWord: { dateFormat: 'DD.MM.YYYY HH:mm:ss' },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: { eq: 'Вхідний залишок' },
                      nextWord: {
                        isNum: true,
                        replace: [
                          { from: ' ', to: '' },
                          { from: ',', to: '.' },
                        ],
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
      // case 2
      {
        proceedCase: (importDocument) => {
          const result = [];
          let currentOperation = [];
          let end = false;
          const headers = [
            'Дата',
            'ПРИЗНАЧЕННЯ:',
            'КОРРЕСПОНДЕНТ:',
            'Дебет',
            'Кредит',
          ];
          let appointment = [];
          let correspondent = [];
          let isAppointment = false;
          let isCorrespondent = false;
          importDocument.slice(1).forEach((arr) => {
            if (end) return;
            if (arr[0] && moment(arr[0], 'DD.MM.YY', true).isValid()) {
              isAppointment = false;
              isCorrespondent = false;
              if (currentOperation.length) {
                currentOperation[1] = appointment.join(' ');
                currentOperation[2] = correspondent.join(' ');
                result.push(currentOperation);
                currentOperation = [];
                appointment = [];
                correspondent = [];
              }
              currentOperation[0] = arr[0];
              currentOperation[3] = arr[4]
                .replaceAll(' ', '')
                .replaceAll(',', '.');
              currentOperation[4] = arr[5]
                .replaceAll(' ', '')
                .replaceAll(',', '.');
            } else if (arr[0] && !moment(arr[0], 'DD.MM.YY', true).isValid()) {
              return;
            } else if (
              arr[1] === 'Кількість платежів' &&
              !isNaN(Number(arr[2]))
            ) {
              end = true;
            } else if (arr[1] === 'ПРИЗНАЧЕННЯ:') {
              isAppointment = true;
              isCorrespondent = false;
              appointment.push(...arr.slice(2).filter(Boolean));
            } else if (arr[1] === 'КОРРЕСПОНДЕНТ:') {
              isAppointment = false;
              isCorrespondent = true;
              correspondent.push(...arr.slice(2).filter(Boolean));
            } else if (isAppointment) {
              appointment.push(...arr.slice(2).filter(Boolean));
            } else if (isCorrespondent) {
              correspondent.push(...arr.slice(2).filter(Boolean));
            }
          });
          if (currentOperation.length) {
            currentOperation[1] = appointment.join(' ');
            currentOperation[2] = correspondent.join(' ');
            result.push(currentOperation);
          }
          this.setDocumentHeader(headers);
          this.setDocumentBody(result);
          return (): ImportResultItemMask => {
            return {
              date: this.getFirstValidCellByColumn(['Дата', 0]),
              comment:
                this.getFirstValidCellByColumn(['КОРРЕСПОНДЕНТ:', 2]) +
                ' ' +
                this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ:', 1]),
              debit: this.getFirstValidCellByColumn(['Дебет', 3]),
              credit: this.getFirstValidCellByColumn(['Кредит', 4]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [0, { eq: 'Виписка/особовий рахунок з' }],
            [1, { dateFormat: 'DD.MM.YYYY' }],
            [2, { eq: 'по' }],
            [3, { dateFormat: 'DD.MM.YYYY' }],
          ],
          preParserConfigs: {
            pageSeparatorsLengthPx: 10,
            maxInterlineSpacingPx: 0.2,
            verticalAlign: 'middle', // 'top' | 'middle' | 'bottom' | 'one-line';
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' }, nextWord: { eq: 'Номер' } }],
                to: [{ word: { eq: 'Кредит' }, prevWord: { eq: 'Дебет' } }],
              },
              delete: [
                {
                  to: [{ word: { eq: 'Кредит' }, prevWord: { eq: 'Дебет' } }],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: { eq: 'Кількість платежів' },
                      nextWord: {
                        isNum: true,
                        replace: [
                          { from: ' ', to: '' },
                          { from: ',', to: '.' },
                        ],
                      },
                    },
                  ],
                  to: [{ word: { eq: 'Кредит' }, prevWord: { eq: 'Дебет' } }],
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
      // case 7, case 9
      {
        proceedCase: (importDocument) => {
          console.log('here 1');

          function decodeByPattern(
            pattern: { [name: string]: string },
            encoded: string,
          ): string {
            let result = '';
            for (let i = 0; i < encoded.length; i++) {
              const char = pattern[encoded[i]] || '5';
              if (char) {
                result += char;
              }
            }
            if (result.replaceAll('5', '') === '') return '';
            return result;
          }

          const headers = [
            'Дата і час операції',
            'Дата операції за рахунком',
            '',
            'Номер картки',
            'Деталі операції',
            'Сума у валюті рахунку',
            'Сума у валюті операції',
            'Валюта операції',
            'Курс',
            'Комісія',
            'Залишок після операції',
          ];
          const isAllFiveEmpty = !importDocument.slice(1).filter((el) => el[5])
            .length;
          const result = [];
          let tmpValue = [];
          importDocument.slice(1).forEach((arr) => {
            if (arr[0]) {
              const decoded = decodeByPattern(pattern, arr[0]);
              if (moment(decoded, 'DD.MM.YYYY', true).isValid()) {
                if (tmpValue.length) {
                  result.push([...tmpValue]);
                  tmpValue = [];
                }
                tmpValue[0] = decoded;
              } else {
                tmpValue[0] += ' ' + decoded;
              }
            }
            if (arr[isAllFiveEmpty ? 6 : 5]) {
              const decoded = decodeByPattern(
                pattern,
                arr[isAllFiveEmpty ? 6 : 5],
              );
              if (tmpValue[5]) {
                tmpValue[5] += decoded;
              } else {
                tmpValue[5] = decoded;
              }
            }
          });
          if (tmpValue.length) {
            result.push([...tmpValue]);
            tmpValue = [];
          }
          this.setDocumentHeader(headers);
          this.setDocumentBody(result.filter((el) => el[0]));
          return (): ImportResultItemMask => {
            return {
              dateAndTime: this.getFirstValidCellByColumn([
                'Дата і час операції',
                0,
              ]),
              sum: this.getFirstValidCellByColumn(['Сума у валюті рахунку', 5]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[[0, 1], { eq: 'Довідкапо пру кохшів та паурткр від' }]],
          preParserConfigs: {
            pageSeparatorsLengthPx: 10,
            maxInterlineSpacingPx: 0.5,
            verticalAlign: 'middle', // 'top' | 'middle' | 'bottom' | 'one-line';
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Єата' }, nextWord: { eq: 'їфба ф' } }],
                to: [
                  { word: { eq: 'одмраМюК' }, prevWord: { eq: 'одмраМюК' } },
                ],
              },
              delete: [
                {
                  to: [
                    { word: { eq: 'одмраМюК' }, prevWord: { eq: 'одмраМюК' } },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'з жпачіО р уоКді) ї з жпачіО в АшашрАі оРікрватте єтж',
                      },
                      nextWord: { eq: 'повжджті' },
                    },
                  ],
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
      // case10
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [0, { eq: 'Виписка за платіжним рахунком з' }],
            [
              1,
              {
                split: {
                  spliter: ' ',
                  arr: [
                    [[0, 2], { dateFormat: '«D»YYYY' }],
                    [3, { eq: 'р.' }],
                    [4, { eq: 'по' }],
                    [[5, 7], { dateFormat: '«D»YYYY' }],
                    [8, { eq: 'р.' }],
                  ],
                },
              },
            ],
          ],
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle', // 'top' | 'middle' | 'bottom' | 'one-line';
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: { eq: 'Найменування, ЄДРПОУ/РНОКПП та рахунок' },
                    nextWord: { eq: 'Дата' },
                  },
                ],
                to: [
                  {
                    word: { eq: '(МФО) / код ID НБУ)' },
                    prevWord: { eq: 'UAH' },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: { eq: '(МФО) / код ID НБУ)' },
                      prevWord: { eq: 'UAH' },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: { eq: 'Вихідний залишок на кінець дня періоду:' },
                      nextWord: {
                        isNum: true,
                        replace: [
                          { from: 'грн', to: '' },
                          { from: ' ', to: '' },
                          { from: ',', to: '.' },
                        ],
                      },
                    },
                  ],
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: { column: ['Дата/час операції', 1] },
              sum: { column: ['Сума, UAH', 4] },
              comment: { column: ['Деталі операції', 5] },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Підсумкова виписка',
              },
            ],
            [
              1,
              {
                in: 'Виписка з',
              },
            ],
          ],
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Номер',
                    },
                    nexWord: {
                      eq: 'Призначення',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'НБУ)',
                    },
                    prevWord: {
                      eq: 'ID',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Номер',
                      },
                      nexWord: {
                        eq: 'Призначення',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Обороти за період',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата', 1],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Призначення\nплатежу', 5],
              },
              debit: {
                column: ['Дебет\n(Витрати)', 6],
              },
              credit: {
                column: ['Кредит\n(Надходження)', 7],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        // case 3, case 11
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          withoutEmpty: true,
          isCurCase: [
            [0, { eq: 'Документ' }],
            [1, { eq: 'Дата' }],
            [2, { eq: 'Сума' }],
            [3, { eq: 'Призначення' }],
            [4, { eq: 'ЄДРПОУ' }],
            [5, { eq: 'Корреспондент' }],
            [6, { eq: 'Рахунок / IBAN' }],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [0, { eq: 'Документ' }],
                  [1, { eq: 'Дата' }],
                ],
                count: 1,
              },
              {
                from: [
                  {
                    or: [
                      {
                        and: [
                          [0, { eq: 'Вхідний залишок' }],
                          [2, { eq: 'Вихідний залишок' }],
                        ],
                      },
                      [
                        0,
                        {
                          eq: '*Можливі статуси документів: О - оброблений системою, П - проведений.',
                        },
                      ],
                    ],
                  },
                ],
                count: 1,
              },
            ],
            fields: {
              dateAndTime: { column: ['Дата', 1], add: [':00'] },
              sum: { column: ['Сума', 3] },
              comment: {
                column: ['Корреспондент', 5],
                add: [' ', { column: ['Призначення', 3] }],
              },
            },
          },
        },
      },
      {
        // case 4
        proceedCase: (importDocument) => {
          const withoutEmpty = importDocument
            .map((el) =>
              el.length
                ? el.filter((str, i) => {
                    if (
                      el[2] &&
                      moment(el[2], 'DD.MM.YY', true).isValid() &&
                      (i === 10 || i === 16)
                    ) {
                      return true;
                    }
                    return Boolean(str);
                  })
                : [],
            )
            .filter((el) => Boolean(el.length));
          const headers = [
            'Дата',
            'ПРИЗНАЧЕННЯ:',
            'КОРРЕСПОНДЕНТ:',
            'Дебет',
            'Кредит',
          ];
          const result = [];
          let currentOperation = [];
          withoutEmpty.forEach((arr) => {
            if (arr[0] && moment(arr[0], 'DD.MM.YY', true).isValid()) {
              if (currentOperation.length) {
                result.push(currentOperation);
                currentOperation = [];
              }
              currentOperation[0] = arr[0];
              currentOperation[3] = arr[4];
              currentOperation[4] = arr[5];
            } else if (arr[0] === 'ПРИЗНАЧЕННЯ:') {
              currentOperation[1] = arr[1];
            } else if (arr[0] === 'КОРРЕСПОНДЕНТ:') {
              currentOperation[2] = arr[1];
            }
          });
          if (currentOperation.length) {
            result.push(currentOperation);
          }

          this.setDocumentHeader(headers);
          this.setDocumentBody(result);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 0]),
            comment:
              this.getFirstValidCellByColumn(['КОРРЕСПОНДЕНТ:', 2]) +
              ' ' +
              this.getFirstValidCellByColumn(['ПРИЗНАЧЕННЯ:', 1]),
            debit: this.getFirstValidCellByColumn(['Дебет', 3]),
            credit: this.getFirstValidCellByColumn(['Кредит', 4]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          withoutEmpty: true,
          isCurCase: [[0, { in: 'Виписка/особовий рахунок з ' }]],
        },
      },
      {
        // case 5
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          withoutEmpty: true,
          isCurCase: [
            [0, { eq: 'MFO_NM' }],
            [1, { eq: 'MFO_NM_K' }],
            [2, { eq: 'CUR_ID' }],
          ],
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['DATA_S', 7],
                add: [' ', { column: ['TIME_S', 31] }],
              },
              comment: {
                column: ['KL_NM_K', 18],
                add: [' ', { column: ['N_P', 22] }],
              },
              credit: {
                column: ['SUMMA', 24],
                if: { eq: [{ column: ['DK', 3] }, '2'] },
              },
              debit: {
                column: ['SUMMA', 24],
                if: { eq: [{ column: ['DK', 3] }, '1'] },
              },
            },
          },
        },
      },
      {
        // case 6
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          isCurCase: [
            [0, { eq: 'DATA_VYP' }],
            [1, { eq: 'MFO' }],
            [2, { eq: 'AC' }],
          ],
          proceedCaseConfig: {
            fields: {
              date: { column: ['DATA_D', 6] },
              comment: {
                column: ['NAME_KOR', 11],
                add: [' ', { column: ['PURPOSE', 19] }],
              },
              credit: {
                column: ['SUM_PD_EQ', 18],
                if: { eq: [{ column: ['DK', 7] }, '1'] },
              },
              debit: {
                column: ['SUM_PD_EQ', 18],
                if: { eq: [{ column: ['DK', 7] }, '2'] },
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Підсумкова виписка',
              },
            ],
          ],
          proceedCaseConfig: {
            removeOnlyEmpty: true,
            delete: [
              {
                to: [
                  [
                    2,
                    {
                      eq: 'Номер операції',
                    },
                  ],
                  [
                    4,
                    {
                      eq: 'Дата',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    5,
                    {
                      eq: 'Рахунок (IBAN)',
                    },
                  ],
                ],
                to: [
                  [
                    5,
                    {
                      eq: 'Рахунок (IBAN)',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    2,
                    {
                      eq: 'Обороти за період',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              date: {
                column: ['Дата', 4],
                dateFormat: 'DD.MM.YYYY',
              },
              debit: {
                column: ['Дебет\n(Витрати)', 17],
              },
              credit: {
                column: ['Кредит\n(Надходження)', 19],
              },
              comment: {
                column: ['Призначення\nплатежу', 14],
              },
            },
          },
        },
      },
    ],
  };
}
