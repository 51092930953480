import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import accountActions from '../../../store/accounts/actions';
import { selectUAHCurrencyId } from '../../../store/currency/selectors';
import integrationActions from '../../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import LoginDialog from './LoginDialog';

type Props = {
  newAccountName?: string;
  selectedAccountId?: string;

  onClose(): void;
  onCloseAccountsDialog?(): void;
};

const defaultDate = moment().subtract(1, 'day').valueOf() + getTimeOffset();
const maxIntegrationDate = moment().valueOf() + getTimeOffset();

function CheckBoxDialog(props: Props) {
  const { onClose, newAccountName, selectedAccountId, onCloseAccountsDialog } =
    props;

  const uahCurrencyId = useSelector(selectUAHCurrencyId);
  const { t } = useTranslation();

  const facebookContext = useContext(FacebookPixelContext);

  const dispatch = useDispatch();

  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleClose = useCallback(() => {
    if (onCloseAccountsDialog) {
      onCloseAccountsDialog();
    } else {
      onClose();
    }
  }, [onCloseAccountsDialog, onClose]);

  const handleCloseCalendar = useCallback(() => {
    setIsFlipped(!isFlipped);
  }, [isFlipped]);

  const handleSetCredentials = useCallback(
    (integrationId: string) => {
      dispatch(integrationActions.setCheckBoxIntegrationId(integrationId));

      setIsFlipped(true);
    },
    [dispatch],
  );

  const handleCreateCredentials = useCallback(async () => {
    if (selectedAccountId) {
      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.CheckBox,
        accountId: selectedAccountId,
        startDate: selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));
    } else if (newAccountName) {
      const {
        data: { accounts, newAccount },
      } = await accountsApi.createAccount(
        uahCurrencyId,
        newAccountName,
        0,
        IntegrationTypeId.CheckBox,
      );

      dispatch(accountActions.setLogAccounts(accounts));

      const accountId = newAccount._id;

      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.CheckBox,
        accountId,
        startDate: selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));
    }

    handleClose();
  }, [
    dispatch,
    handleClose,
    selectedDate,
    uahCurrencyId,
    newAccountName,
    facebookContext,
    selectedAccountId,
  ]);

  return (
    <>
      <LoginDialog
        onClose={handleClose}
        onSetCredentials={handleSetCredentials}
      />
      <Dialog
        isOpened={isFlipped}
        onClose={handleCloseCalendar}
        title={t('integrations.title')}
      >
        <CalendarComponent
          onChange={handleChangeDate}
          placeholder={t('integrations.dateDescription')}
          value={selectedDate}
          fullWidth
          maxDate={new Date(maxIntegrationDate)}
          minDate={minOperationDate()}
          fromIntegration
        />
        <CustomButton
          title={t('bank.set')}
          action={handleCreateCredentials}
          fullWidth
        />
      </Dialog>
    </>
  );
}

export default React.memo(CheckBoxDialog);
