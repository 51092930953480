import { makeStyles } from '@material-ui/core/styles';

import {
  black,
  codGray,
  flamingo,
  lightBlack16,
  outrageousOrange,
  persianGreen,
  redOrange012,
  white,
} from '../../../../theme/colors';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '9px 0 9px 16px',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flexCol50: {
    display: 'flex',
    flex: '1 0 200px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  width100: {
    width: '100%',
  },
  marginB0: {
    marginBottom: '0',
  },
  marginB16: {
    marginBottom: 16,
  },
  flexGap16: {
    gap: 16,
  },
  amountContainer: {
    marginBottom: '0 !important',
    flex: 1,
    transition: 'width 700ms',
    minWidth: 115,
  },
  accountAmountContainer: {
    background: white,
    border: '1px solid black',
    borderColor: 'rgba(0, 0, 0, 0.12)',
  },
  textFlexCol: {
    display: 'flex',
    width: 75,
  },
  title: {
    fontSize: 15,
    lineHeight: '20px',
    margin: '0 24px',
    color: codGray,
    opacity: 0.5,
  },
  hint: {
    marginLeft: 16,
    marginRight: 0,
    opacity: 1,
  },
  hintRed: {
    color: flamingo,
  },
  hintGreen: {
    color: persianGreen,
  },
  hintGrey: {
    color: black,
    opacity: 0.5,
  },
  footer: {
    display: 'flex',
    flex: '1 0 0',
    padding: '16px',
  },
  buttonAccept: {
    width: 170,
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
    borderRadius: 12,
    marginRight: 20,
  },
  buttonOk: {
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
    borderRadius: 12,
    marginRight: 20,
  },
  buttonClear: {
    width: 170,
    background: 'rgba(0, 0, 0, 0.06)',
    borderRadius: 12,
  },
  buttonDelete: {
    width: 170,
    background: 'rgba(255, 99, 51, 0.1)',
    borderRadius: 12,
  },
  marginLAuto: {
    marginLeft: 'auto',
  },
  buttonAddProject: {
    background: white,
    borderRadius: 12,
    border: `1px dashed ${lightBlack16}`,
    '& p': {
      fontWeight: 'normal',
    },
  },
  cursorActive: {
    cursor: 'pointer',
  },
  deleteBtn: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    width: 54,
    maxWidth: 54,
    '& button': {
      marginLeft: 10,
    },
  },
  icon: {
    color: lightBlack16,
    width: 16,
    height: 16,
  },

  wide_modal: {
    width: '80vw',
    maxWidth: 800,
  },

  errorBackground: {
    backgroundColor: redOrange012,
    '& input': {
      opacity: 0.5,
    },
  },
  splitPaymentRow: {
    flexWrap: 'wrap',
    height: 'auto',
  },
  splitPaymentInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
    width: 'calc(100% - 40px)',
    flex: '1 1 100%',
    paddingLeft: 5,
    paddingRight: 5,
  },
  splitPaymentInfoCol50: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
    fontSize: 13,
    minWidth: 0,
  },
  splitPaymentInfoProjectCol: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
    overflow: 'hidden',
  },
  splitPaymentInfoProjectLabel: {
    opacity: 0.6,
  },
  splitPaymentInfoProjectSumNegative: {
    color: outrageousOrange,
  },
  splitPaymentInfoProjectSumPositive: {
    color: persianGreen,
  },
  boldText: {
    fontWeight: 'bold',
  },
  splitPaymentInfoFinalRow: {
    marginTop: 6,
    marginBottom: 21,
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  numberCol: {
    width: 36,
    minWidth: 36,
    textAlign: 'right',
  },
  noFlexWrap: {
    flexWrap: 'nowrap',
  },
}));
