import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutoCompleteComponent from '../../../components/AutocompleteComponent';
import RemoveDialog from '../../../components/RemoveDialog/RemoveDialog';
import TransitionComponent from '../../../components/TransitionComponent';
import { clientPrefixBySubType } from '../../../constants';
import { NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW } from '../../../constants/featureToggles/featureToggle';
import { SHOW_ALERT_INTEGRATION_PAYMENT } from '../../../constants/featureToggles/integrationsFeatureToggles';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import useUnleash from '../../../hooks/useUnleash';
import { SettingsName } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { selectCompanyCurrency } from '../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../store/currency/selectors';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../store/operations/selectors';
import {
  OperationSubType,
  OperationType,
} from '../../../store/operations/types';
import { maxOperationDate, minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import useGetIsEditable from '../../Header/Settings/useGetIsEditable';
import AccountSelector from '../Components/AccountSelector';
import Additions from '../Components/Additions/Additions';
import AmountComponent from '../Components/Amount';
import CalendarComponent from '../Components/CalendarComponent/CalendarComponent';
import ConfirmChangeRepeatDialog from '../Components/ConfirmChangeRepeatDialog';
import ConfirmRemoveRepeatDialog from '../Components/ConfirmRemoveRepeatDialog';
import DateOfPaymentComponent from '../Components/DateOfPaymentComponent';
import FooterComponent from '../Components/FooterComponent';
import Repeat from '../Components/Repeat/Repeat';
import { SplitPaymentProjectDetailedInfo } from '../Components/SplittingPayment/types';
import operationsHOC from '../HOC';
import { CustomPeriodEnum, Props } from '../HOC/types';
import { calculateSumAndCompanyCurrencySum } from '../HOC/utils';
import SalaryPeriodComponent from './SalaryPeriodComponent';
import { useStyles } from './styles';
import TaxPeriodComponent from './TaxPeriodComponent';

function ConsumptionComponentWithHOC(props: Props) {
  const {
    tags,
    botId,
    isEdit,
    isCopy,
    client,
    owners,
    account,
    repeats,
    projects,
    accounts,
    category,
    comments,
    currency,
    isGeneric,
    suppliers,
    creditors,
    borrowers,
    editProps,
    createTag,
    isCompare,
    reportType,
    externalId,
    difference,
    changeTags,
    incomeDate,
    isNewState,
    createOwner,
    staffMembers,
    selectedTags,
    changeClient,
    createProject,
    changeRepeats,
    integrationId,
    changeAccount,
    createSupplier,
    createCreditor,
    createBorrower,
    changeCategory,
    changeComments,
    changeTaxPeriod,
    changeToTransfer,
    taxOrganisations,
    changeIncomeDate,
    initialScheduled,
    createStaffMember,
    onRemoveOperation,
    onCreateOperation,
    changeSalaryPeriod,
    onSetOperationType,
    onChangeDateOfPayment,
    createTaxOrganisation,
    consumptionCategories,
    creatingClientInProgress,
    creatingProjectInProgress,
    createConsumptionCategories,
    onChangeDateOfPaymentPeriod,
    onChangeDateOfPaymentPeriodId,
    handleChangeSplitPaymentProjects,
    creatingConsumptionCategoryInProgress,
  } = props;

  const { isEditable } = useGetIsEditable();

  const { t } = useTranslation();

  const historyOperationProps = useSelector(getHistoryOperationProps);
  const opProps = useSelector(getOperationProps);

  const operationProps = isNewState ? historyOperationProps : opProps;

  const {
    amount,
    revertState,
    endTimestamp,
    exchangeRate,
    dateOfPayment,
    currencyValue,
    startTimestamp,
    currencyAmount,
    splitPaymentProjects,
    dateOfPaymentPeriodId,
  } = operationProps;

  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const isCrypto = cryptoCurrencyCodes.includes(account?.currency.code || '');

  const showAlertIntegrationPayment = useUnleash(
    SHOW_ALERT_INTEGRATION_PAYMENT,
  );
  const newUserForClientAndCategoriesView = useUnleash(
    NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW,
  );

  const { isCreateOperationActiveStep } = useOnboardingV2();

  const [amountError, setAmountError] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [accountError, setAccountError] = useState(false);
  const [showSplitPaymentProjectsError, setShowSplitPaymentProjectsError] =
    useState(false);
  const [showDeletedProjectError, setShowDeletedProjectError] = useState(false);
  const [showAlertChangeAmountDialog, setShowAlertChangeAmountDialog] =
    useState(false);

  const classes = useStyles({
    isError: accountError,
  });

  const isIntegrationOperation =
    showAlertIntegrationPayment && integrationId && externalId && !botId;

  let subType;

  switch (category?.normalizedLabel) {
    case OperationSubType.tax:
      subType = OperationSubType.tax;
      break;
    case OperationSubType.loan:
      subType = OperationSubType.loan;
      break;
    case OperationSubType.loanrepayment:
      subType = OperationSubType.loanrepayment;
      break;
    case OperationSubType.dividend:
      subType = OperationSubType.dividend;
      break;
    case OperationSubType.salary:
      subType = OperationSubType.salary;
      break;
    default:
      subType = OperationSubType.supplier;
  }

  const handleCreateOperation = useCallback(
    (scheduled: boolean, closeDialog = true) => {
      if (reportType === 'credit' && !account) {
        setAccountError(true);

        return;
      }

      const normalizedLabel = category?.normalizedLabel;

      if (normalizedLabel === OperationSubType.dividend) {
        onCreateOperation(scheduled, OperationSubType.dividend, closeDialog);
      } else if (normalizedLabel === OperationSubType.loan) {
        onCreateOperation(scheduled, 'consumptionLoan', closeDialog);
      } else if (normalizedLabel === OperationSubType.loanrepayment) {
        onCreateOperation(scheduled, 'consumptionLoanRepayment', closeDialog);
      } else if (normalizedLabel === OperationSubType.salary) {
        onCreateOperation(scheduled, OperationSubType.salary, closeDialog);
      } else if (normalizedLabel === OperationSubType.tax) {
        onCreateOperation(scheduled, OperationSubType.tax, closeDialog);
      } else {
        onCreateOperation(scheduled, OperationSubType.supplier, closeDialog);
      }
    },
    [account, category?.normalizedLabel, onCreateOperation, reportType],
  );

  const handleCreateClient = useCallback(
    (newClientName: string) => {
      const normalizedLabel = category?.normalizedLabel;

      if (normalizedLabel === OperationSubType.dividend) {
        createOwner(newClientName);
      } else if (normalizedLabel === OperationSubType.loan) {
        createBorrower(newClientName);
      } else if (normalizedLabel === OperationSubType.loanrepayment) {
        createCreditor(newClientName);
      } else if (normalizedLabel === OperationSubType.salary) {
        createStaffMember(newClientName);
      } else if (normalizedLabel === OperationSubType.tax) {
        createTaxOrganisation(newClientName);
      } else {
        createSupplier(newClientName);
      }
    },
    [
      category,
      createOwner,
      createBorrower,
      createCreditor,
      createSupplier,
      createStaffMember,
      createTaxOrganisation,
    ],
  );

  const clientsByCategory = useMemo(() => {
    const normalizedLabel = category?.normalizedLabel;

    if (normalizedLabel === OperationSubType.dividend) {
      return owners;
    }

    if (normalizedLabel === OperationSubType.loan) {
      return borrowers;
    }

    if (normalizedLabel === OperationSubType.loanrepayment) {
      return creditors;
    }

    if (normalizedLabel === OperationSubType.salary) {
      return staffMembers;
    }

    if (normalizedLabel === OperationSubType.tax) {
      return taxOrganisations;
    }

    return suppliers;
  }, [
    owners,
    category,
    suppliers,
    borrowers,
    creditors,
    staffMembers,
    taxOrganisations,
  ]);

  const handleShowConfirmDialog = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleCloseChangeDialog = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleCloseAlertChangeAmountDialog = useCallback(() => {
    setShowAlertChangeAmountDialog(false);
  }, []);

  const handleUpdate = useCallback(
    (scheduled: boolean) => {
      handleCreateOperation(scheduled);
    },
    [handleCreateOperation],
  );

  const handleCreate = useCallback(() => {
    if (account?.integration) {
      setShowAlertDialog(true);
    } else if (isCreateOperationActiveStep && (!amount || !Number(amount))) {
      setAmountError(true);
    } else {
      handleCreateOperation(repeats.id !== 1000);
    }
  }, [
    amount,
    repeats,
    account,
    handleCreateOperation,
    isCreateOperationActiveStep,
  ]);

  const onCreate = useCallback(() => {
    handleCreateOperation(repeats.id !== 1000);
  }, [handleCreateOperation, repeats]);

  const handleClickSaveButton = useCallback(() => {
    if (isIntegrationOperation && editProps?.sumDouble !== Number(amount)) {
      setShowAlertChangeAmountDialog(true);

      return;
    }

    if (!initialScheduled && repeats.id === 1000) {
      handleUpdate(false);
    } else if (initialScheduled) {
      handleShowConfirmDialog();
    } else {
      handleUpdate(repeats.id !== 1000);
    }
  }, [
    amount,
    repeats,
    editProps,
    handleUpdate,
    initialScheduled,
    isIntegrationOperation,
    handleShowConfirmDialog,
  ]);

  const handleRemoveOperation = useCallback(
    (scheduled = false) => {
      onRemoveOperation(scheduled);
    },
    [onRemoveOperation],
  );

  const handleCloseAlertDialog = useCallback(() => {
    setShowAlertDialog(false);
  }, []);

  const handleChangeSalaryPeriodId = useCallback(
    (id: CustomPeriodEnum) => {
      changeSalaryPeriod(id);
    },
    [changeSalaryPeriod],
  );

  const handleChangeTaxPeriodId = useCallback(
    (id: CustomPeriodEnum) => {
      changeTaxPeriod(id);
    },
    [changeTaxPeriod],
  );

  const handleChangeAccount = useCallback(
    (value: string) => {
      setAccountError(false);
      changeAccount(value);
    },
    [changeAccount],
  );

  const handleChangeCategory = useCallback(
    (value: string) => {
      changeCategory(value, OperationType.consumption);
    },
    [changeCategory],
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (splitPaymentProjects.length > 1) {
      // if currencyAmount changed check payment is spit between projects correctly
      const splitPaymentSum: number = Number(
        splitPaymentProjects
          .reduce(
            (acc: number, project: SplitPaymentProjectDetailedInfo) =>
              acc + Number(project.sum),
            0,
          )
          .toFixed(2),
      );
      const amountToCheck: number = Number(
        currencyAmount && currency?.id !== account?.currency._id
          ? currencyAmount
          : amount ?? 0,
      );
      if (amountToCheck !== splitPaymentSum) {
        setShowSplitPaymentProjectsError(true);
      } else {
        setShowSplitPaymentProjectsError(false);
      }
    } else {
      setShowSplitPaymentProjectsError(false);
      setShowDeletedProjectError(false);
    }
  }, [amount, currencyAmount, splitPaymentProjects]);

  useEffect(() => {
    if (splitPaymentProjects.length > 1 && exchangeRate) {
      handleChangeSplitPaymentProjects(
        splitPaymentProjects.map((projectInfo) => {
          const currencyAmountVal = (
            Number(exchangeRate ?? 0) * Number(projectInfo.sum)
          ).toFixed(3);
          const amountVal = projectInfo.sum;
          const calculateSumAndCompanyCurrencySumProps = {
            isEdit: false,
            currencyAmount: currencyAmountVal,
            account,
            isCrypto,
            revertState,
            exchangeRate,
            currencyValue,
            amount: amountVal,
            companyCurrency,
          };

          const {
            companyCurrencySum = null,
            transactionSum = null,
            sum,
          } = calculateSumAndCompanyCurrencySum(
            calculateSumAndCompanyCurrencySumProps,
          );
          return {
            project: projectInfo.project,
            percentages: projectInfo.percentages,
            sum: sum?.toFixed(2) ?? '',
            transactionSum,
            companyCurrencySum,
          };
        }),
      );
    }
  }, [exchangeRate]);

  useEffect(() => {
    if (amount) {
      setAmountError(false);
    }
  }, [amount]);

  useEffect(() => {
    onSetOperationType(OperationType.consumption);

    if (!isEdit) {
      changeIncomeDate(moment.utc().valueOf() + getTimeOffset());
    }
  }, [onSetOperationType, isEdit, changeIncomeDate]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.amountContainer}>
          <AccountSelector
            isCompare={Boolean(isCompare)}
            difference={Boolean(difference?.accountFromId)}
            value={account}
            onChange={handleChangeAccount}
            accounts={accounts}
            placeholder={t('operationDialogs.accountFrom.inputLabel')}
          />
          <TransitionComponent
            enter={accountError}
            text={t('system.fieldMustFilled')}
          />
        </div>
        <AmountComponent
          isNewState={isNewState}
          isCompare={Boolean(isCompare)}
          difference={difference}
          isEdit={isEdit}
          isError={amountError}
        />
        <AutoCompleteComponent
          loading={creatingConsumptionCategoryInProgress}
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.categoryId)}
          allowedCreate={isEditable(SettingsName['categories/consumption'])}
          onChange={handleChangeCategory}
          onCreate={createConsumptionCategories}
          data={consumptionCategories}
          type="category"
          value={category}
          disablePortal={false}
          operationType={OperationType.consumption}
        />
        <AutoCompleteComponent
          loading={creatingClientInProgress}
          isGeneric={Boolean(isGeneric)}
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.counterpartyId)}
          allowedCreate={isEditable(SettingsName.suppliers)}
          onChange={changeClient}
          onCreate={handleCreateClient}
          data={clientsByCategory}
          value={client}
          type={
            newUserForClientAndCategoriesView &&
            // @ts-ignore
            !clientPrefixBySubType[category?.normalizedLabel]
              ? 'myCounterparty'
              : // @ts-ignore
                clientPrefixBySubType[category?.normalizedLabel] || 'clients'
          }
        />
        <CalendarComponent
          testId="cypress-calendar"
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.date)}
          fullWidth
          value={incomeDate}
          onChange={changeIncomeDate}
          placeholder={t('operationDialogs.consumptionFirstDate')}
          minDate={minOperationDate()}
          maxDate={maxOperationDate(incomeDate)}
        />
        {category?.normalizedLabel === OperationSubType.salary && (
          <SalaryPeriodComponent
            isEdit={isEdit}
            difference={Boolean(difference?.salaryPeriodId)}
            isCompare={isCompare}
            incomeDate={incomeDate}
            onChangeDateOfPaymentPeriod={onChangeDateOfPaymentPeriod}
            isNewState={isNewState}
            onChangePeriodId={handleChangeSalaryPeriodId}
            prefix="operationDialogs.salaryPrefix"
          />
        )}

        {category?.normalizedLabel === OperationSubType.tax && (
          <TaxPeriodComponent
            isEdit={isEdit}
            difference={Boolean(difference?.taxPeriodId)}
            isCompare={isCompare}
            incomeDate={incomeDate}
            onChangeDateOfPaymentPeriod={onChangeDateOfPaymentPeriod}
            isNewState={isNewState}
            onChangePeriodId={handleChangeTaxPeriodId}
          />
        )}

        {category?.normalizedLabel !== OperationSubType.salary &&
          category?.normalizedLabel !== OperationSubType.tax && (
            <DateOfPaymentComponent
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              periodId={dateOfPaymentPeriodId}
              type={OperationType.consumption}
              isEdit={!!isEdit}
              dateOfPayment={dateOfPayment}
              subType={subType}
              isCompare={!!isCompare}
              difference={!!difference?.dateOfPayment}
              incomeDate={incomeDate}
              minDate={minOperationDate()}
              maxDate={maxOperationDate(incomeDate)}
              onChangeDateOfPayment={onChangeDateOfPayment}
              onChangeDateOfPaymentPeriod={onChangeDateOfPaymentPeriod}
              onChangePeriodId={onChangeDateOfPaymentPeriodId}
              isCustomCategory={!!category && !category.systemValue}
            />
          )}

        {!isEdit && (
          <Repeat
            isCompare={Boolean(isCompare)}
            difference={Boolean(difference?.scheduled)}
            onChangePeriod={changeRepeats}
            repeats={repeats}
            incomeDate={incomeDate}
          />
        )}
        <Additions
          isNewState={isNewState}
          isCompare={Boolean(isCompare)}
          difference={difference}
          isAllowedCreateProject={isEditable(SettingsName.projects)}
          isAllowedCreateTag={isEditable(SettingsName.tags)}
          projects={projects}
          tags={tags}
          selectedTags={selectedTags}
          comments={comments}
          splitPaymentProjects={splitPaymentProjects}
          isVisibleProjectDropdown={
            !(
              Array.isArray(splitPaymentProjects) &&
              splitPaymentProjects?.length > 1
            )
          }
          onChangeProject={handleChangeSplitPaymentProjects}
          onChangeComments={changeComments}
          onCreateProject={createProject}
          onCreateTag={createTag}
          onChangeTags={changeTags}
          creatingProjectInProgress={creatingProjectInProgress}
          setShowDeletedProjectError={setShowDeletedProjectError}
          showSplitPaymentProjectsError={showSplitPaymentProjectsError}
          isCryptoCurrency={currency?.isCrypto ?? false}
          editProps={editProps}
        />
        <FooterComponent
          type={OperationType.consumption}
          isEdit={isEdit}
          isCopy={isCopy}
          isCompare={Boolean(isCompare)}
          onCreate={handleCreate}
          onShowRemoveDialog={handleShowRemoveDialog}
          onClickSaveButton={handleClickSaveButton}
          createOperation={handleCreateOperation}
          showProjectsError={
            showSplitPaymentProjectsError || showDeletedProjectError
          }
          changeToTransfer={changeToTransfer}
        />
      </div>
      {showAlertDialog && (
        <RemoveDialog
          type="confirmAddToIntegrationAccount"
          description={t('system.sureToAddPaymentDescription')}
          title={t('system.sureToAddPayment')}
          name=""
          alertText={t('system.addAnyway')}
          onRemove={onCreate}
          onClose={handleCloseAlertDialog}
        />
      )}
      {showAlertChangeAmountDialog && (
        <RemoveDialog
          type="confirmChangeToIntegrationAccount"
          description={t('system.sureToChangePaymentDescription')}
          title={t('system.sureToChangePayment')}
          name=""
          alertText={t('system.changeAnyway')}
          onRemove={onCreate}
          onClose={handleCloseAlertChangeAmountDialog}
        />
      )}
      {showRemoveDialog && repeats.id !== 1000 && (
        <ConfirmRemoveRepeatDialog
          type={OperationType.consumption}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {!isIntegrationOperation && showRemoveDialog && repeats.id >= 1000 && (
        <RemoveDialog
          type="confirmRemoveOperation"
          name={t('operation.removeDialogText.consumption').toLowerCase()}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {isIntegrationOperation && showRemoveDialog && repeats.id >= 1000 && (
        <RemoveDialog
          type="confirmRemoveIntegrationOperation"
          description={t('system.sureToRemovePaymentDescription')}
          title={t('system.sureToRemovePayment')}
          name=""
          alertText={t('system.deleteAnyway')}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {showConfirmDialog && (
        <ConfirmChangeRepeatDialog
          type={OperationType.consumption}
          onUpdate={handleUpdate}
          onClose={handleCloseChangeDialog}
        />
      )}
    </div>
  );
}

export default operationsHOC(ConsumptionComponentWithHOC);
