import { AnyAction } from 'redux';

import { PDF_IMPORT_SUCCESS, RESET_PDF_IMPORT } from './actions';
import { ImportState } from './types';

const initialState: ImportState = {
  pdfImportBuffer: null,
};

const importReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case PDF_IMPORT_SUCCESS: {
      return {
        ...state,
        pdfImportBuffer: payload.data,
      };
    }

    case RESET_PDF_IMPORT: {
      return {
        ...state,
        pdfImportBuffer: null,
      };
    }

    default:
      return state;
  }
};

export default importReducer;
