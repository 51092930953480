import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../styles';
import { liteContentKeys } from './constants';

function LiteContent() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {liteContentKeys.map((el) => (
        <div className={classes.row} key={el}>
          <Typography className={classes.text}>
            ❌ {t(`subscription.texts.${el}`)}
          </Typography>
        </div>
      ))}
    </>
  );
}

export default React.memo(LiteContent);
