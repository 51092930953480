import { AnyAction } from 'redux';

import { EMPTY } from '../../constants';
import { UPDATE_ONE_OPERATION_SUCCESS } from '../operations/actions';
import {
  GET_FUTURE_JOURNAL_PENDING,
  GET_FUTURE_JOURNAL_SUCCESS,
  GET_FUTURE_PAYMENTS_LENGTH_SUCCESS,
  GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
  GET_JOURNAL_PENDING,
  GET_JOURNAL_SUCCESS,
  JOURNAL_INIT,
  REMOVE_AND_UPDATE_ATTACHMENTS,
  RESET_FUTURE_JOURNAL,
  RESET_JOURNAL_LOADING,
  SET_IS_APPROVED_FUTURE,
  SET_SHOW_FUTURE_PAYMENTS,
  SET_SORT_PARAMS,
  UPDATE_GROUP_LOG_OPERATIONS,
  UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS,
  UPDATE_JOURNAL_SPECIFIC_OPERATIONS,
  UPDATE_ONE_FUTURE_OPERATION,
  UPDATE_ONE_LOG_OPERATION,
} from './actions';
import { Journal, JournalData, JournalState } from './types';

const initialJournal = {
  items: [],
  total: 0,
  futureItems: [],
};

const initialState: JournalState = {
  operationsExist: false,
  loading: false,
  loadingFuture: false,
  order: 'desc',
  orderBy: 'startOfDay',
  multiOrderBy: {
    date: 'desc',
    sum: undefined,
    accountName: undefined,
    counterpartyName: undefined,
    categoryName: undefined,
  },
  journal: { ...initialJournal },
  showFuturePayments: false,
  isApprovedFuture: false,
  totalFutureAmount: 0,
};

// eslint-disable-next-line default-param-last
const journalReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case JOURNAL_INIT: {
      return {
        ...state,
        journal: { ...initialJournal },
      };
    }

    case GET_FUTURE_PAYMENTS_LENGTH_SUCCESS: {
      return {
        ...state,
        totalFutureAmount: payload.totalFutureAmount,
      };
    }

    case REMOVE_AND_UPDATE_ATTACHMENTS: {
      const { operationId, attachmentId } = payload;
      const operationIndex = state.journal?.items.findIndex(
        (el) => el._id === operationId,
      );

      if (typeof operationIndex !== 'undefined' && operationIndex !== -1) {
        const operation = state.journal?.items[operationIndex];

        if (operation) {
          operation.attachments = operation.attachments.filter(
            (el) => el._id !== attachmentId,
          );

          if (state.journal?.items) {
            state.journal.items[operationIndex] = operation;

            return {
              ...state,
              journal: {
                ...state.journal,
                items: state.journal.items,
              },
            };
          }

          return state;
        }
      }

      return state;
    }

    case SET_IS_APPROVED_FUTURE: {
      return {
        ...state,
        isApprovedFuture: payload.isApprovedFuture,
      };
    }
    case SET_SHOW_FUTURE_PAYMENTS: {
      return {
        ...state,
        showFuturePayments: payload.value,
      };
    }

    case UPDATE_ONE_FUTURE_OPERATION: {
      const { data } = payload;
      const futureItems = state.journal?.futureItems
        .map((el) => {
          if (el._id === data._id) {
            return { ...el, ...data };
          }

          return el;
        })
        .sort((a: JournalData, b: JournalData) => {
          if (a.date > b.date) {
            return -1;
          } else if (a.date < b.date) {
            return 1;
          }

          return 0;
        });

      return {
        ...state,
        journal: {
          ...state.journal,
          futureItems,
        },
      };
    }

    case UPDATE_ONE_LOG_OPERATION: {
      const { id, operation } = payload;
      const items = state.journal.items.map((el) => {
        if (el._id === id) {
          return operation;
        }

        return el;
      });

      if (items) {
        return {
          ...state,
          journal: {
            ...state.journal,
            items,
          },
        };
      }

      return state;
    }

    case UPDATE_GROUP_LOG_OPERATIONS: {
      const { items }: { items: JournalData[] } = payload;
      const updatedItems = state.journal.items.map((el) => {
        const item = items.find((itemEl) => itemEl._id === el._id);

        if (item) {
          return item;
        }

        return el;
      });

      if (items) {
        return {
          ...state,
          journal: {
            ...state.journal,
            items: [...updatedItems],
          },
        };
      }

      return state;
    }

    case UPDATE_ONE_OPERATION_SUCCESS: {
      const { id, subType, categoryId, categoryName } = payload;
      const items = state.journal
        ? state.journal.items.map((item) => {
            if (item._id === id) {
              return {
                ...item,
                subType,
                categoryId,
                categoryName,
              };
            }

            return item;
          })
        : ([] as JournalData[]);
      const journal = state.journal ? { ...state.journal, items } : null;

      return {
        ...state,
        journal,
      };
    }

    case SET_SORT_PARAMS: {
      return {
        ...state,
        multiOrderBy: payload.orders,
      };
    }

    case UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS: {
      const futureItems =
        state.journal?.futureItems.filter(
          (item) => !payload.data.includes(item._id),
        ) || [];

      const items =
        state.journal?.items.filter(
          (item) => !payload.data.includes(item._id),
        ) || [];

      return {
        ...state,
        journal: {
          ...state.journal,
          items,
          futureItems,
        },
      };
    }

    case GET_JOURNAL_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case RESET_JOURNAL_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_FUTURE_JOURNAL_PENDING: {
      if (state.journal) {
        const futureItems = [...state.journal.futureItems];

        if (futureItems.length) {
          futureItems.shift();
        }

        return {
          ...state,
          loadingFuture: true,
          journal: {
            ...state.journal,
            futureItems,
          },
        };
      }

      return state;
    }

    case RESET_FUTURE_JOURNAL: {
      return {
        ...state,
        journal: {
          ...state.journal,
          futureItems: [],
        },
        totalFutureAmount: 0,
      };
    }

    case GET_FUTURE_JOURNAL_SUCCESS: {
      const {
        data: { items, total },
      } = payload;

      const newFutureItems =
        total === state.totalFutureAmount
          ? [...items]
          : [{ tagIds: [EMPTY], _id: 'future' }, ...items];

      return {
        ...state,
        journal: {
          ...state.journal,
          futureItems: newFutureItems,
        },
        loadingFuture: false,
      };
    }

    case GET_JOURNAL_SUCCESS: {
      if (!payload.data) {
        return {
          ...state,
          loading: false,
        };
      }

      const { data, update }: { data: Journal; update: boolean } = payload;

      const newOperations = data.items.map((el) => ({
        ...el,
        sumDouble: el.sumDouble,
      }));
      const loadedItems = state.journal ? [...state.journal.items] : [];

      const items = update
        ? [...newOperations]
        : [...loadedItems, ...newOperations];

      return {
        ...state,
        journal: {
          ...state.journal,
          items,
          total: payload.data.total,
        },
        loading: false,
        dataExport: null,
      };
    }

    case GET_JOURNAL_OPERATIONS_COUNT_SUCCESS: {
      return {
        ...state,
        operationsExist: payload.data.logCount,
      };
    }

    case UPDATE_JOURNAL_SPECIFIC_OPERATIONS: {
      const ids = payload.items.map((op: JournalData) => op._id);
      return {
        ...state,
        journal: {
          ...state.journal,
          items: state.journal?.items?.map((operation: JournalData) => {
            if (ids.includes(operation._id))
              return payload.items.find((op: JournalData) => op._id);
            return operation;
          }),
          futureItems: state.journal?.futureItems?.map(
            (operation: JournalData) => {
              if (ids.includes(operation._id))
                return payload.items.find((op: JournalData) => op._id);
              return operation;
            },
          ),
        },
      };
    }

    default:
      return state;
  }
};

export default journalReducer;
