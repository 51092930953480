import { AnyAction } from 'redux';

import { initialContext } from '../constant';
import { ActionTypes } from '../operations/actions';
import {
  CREATE_OPERATION_PROJECTS_PENDING,
  CREATE_OPERATION_PROJECTS_SUCCESS,
  CREATE_SETTINGS_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  GET_LOG_PROJECTS_SUCCESS,
  GET_OPERATION_PROJECTS_SUCCESS,
  GET_SETTINGS_PROJECTS_SUCCESS,
} from './actions';
import { ProjectsState } from './types';

const initialState: ProjectsState = {
  projects: {
    ...initialContext,
    loading: false,
  },
};

const projectsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case ActionTypes.RESET_PROPS: {
      return { ...initialState };
    }

    case CREATE_OPERATION_PROJECTS_PENDING: {
      return {
        ...state,
        projects: {
          ...state.projects,
          loading: true,
        },
      };
    }

    case GET_LOG_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          log: payload.data,
        },
      };
    }

    case GET_OPERATION_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          operation: payload.data,
        },
      };
    }

    case GET_SETTINGS_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          settings: payload.data,
        },
      };
    }

    case CREATE_OPERATION_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          operation: payload.data,
          loading: false,
        },
      };
    }

    case DELETE_PROJECT_SUCCESS:
    case CREATE_SETTINGS_PROJECT_SUCCESS: {
      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        projects: {
          ...state.projects,
          settings: payload.data,
        },
      };
    }

    default:
      return state;
  }
};

export default projectsReducer;
