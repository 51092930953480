import axios from 'axios';

import { ChangePasswordRequest } from '../../scenes/Auth/ChangePassword/types';
import Http from '../../services/Http';

const loginUser = (email: string, password: string) =>
  Http.makeInstance().post('/login', {
    email,
    password,
  });

const getUserProfile = () => Http.makeInstance().get('/user/profile');

const changePassword = (data: ChangePasswordRequest) =>
  Http.makeInstance().post('/update_user_data', { ...data });

const getUserProfileByToken = (token: string) =>
  Http.makeInstance().post('/user/authByToken', { token });

const updateProfile = (companyId: string | null, lng: string) =>
  Http.makeInstance().patch('/user/profile', {
    currentCompanyId: companyId,
    currentLangIso: lng,
  });

const updateProfileTC = (agreeTandC: boolean) =>
  Http.makeInstance().patch('/user/profile', {
    agreeTandC,
  });

const updateProfilePhone = (userPhone: string, termsOfService: boolean) =>
  Http.makeInstance().patch('/user/profile', {
    userPhone,
    termsOfService,
  });

const updateProfileCustomization = (customization: string) =>
  Http.makeInstance().patch('/user/profile', {
    customization,
  });

const updateUserCountry = (country: string) =>
  Http.makeInstance().patch('/user/country', { country });

const getGeolocation = () => axios.get('https://extreme-ip-lookup.com/json/');

const isEmailExist = (email: string) =>
  Http.makeInstance().get(`/user/isEmailExists?email=${email}`);

const addInvalidEmailToPD = (email: string, phone: string) =>
  Http.makeInstance().post('/registration/invalidEmailToPD', {
    email,
    phone,
  });

export default {
  loginUser,
  isEmailExist,
  updateProfile,
  getUserProfile,
  getGeolocation,
  changePassword,
  updateProfileTC,
  updateUserCountry,
  updateProfilePhone,
  addInvalidEmailToPD,
  getUserProfileByToken,
  updateProfileCustomization,
};
