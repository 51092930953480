import Http from '../../services/Http';
import { Context } from '../types';
import { Client, UrlPrefix } from './types';

const getClients = (prefix: UrlPrefix, context: Context, query = '') =>
  Http.makeInstance().get(`/${prefix}?context=${context}${query}`);

const createClient = (prefix: UrlPrefix, label: string, context: Context) =>
  Http.makeInstance().post(`/${prefix}?context=${context}`, {
    label,
  });

const updateClient = (prefix: UrlPrefix, client: Client, isInvoice = false) => {
  if (isInvoice) {
    return Http.makeInstance().patch(`/${prefix}/${client._id}?invoice=true`, {
      ...client,
    });
  }

  return Http.makeInstance().patch(`/${prefix}/${client._id}`, {
    ...client,
  });
}


const deleteClient = (prefix: UrlPrefix, id: string) =>
  Http.makeInstance().delete(`/${prefix}/${id}`);

export default {
  getClients,
  createClient,
  updateClient,
  deleteClient,
};
