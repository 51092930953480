import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PaypalImportParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: {
            simpleParse: true,
            CSVDelimiter: ';',
          },
          isCurCase: {
            useBuffer: [
              [
                0,
                {
                  regexp: {
                    str: '[\\w]+;Time;[\\w]+',
                  },
                },
              ],
            ],
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date', 0],
                dateFormat: 'MM/DD/YYYY',
              },
              time: {
                column: ['Time', 1],
                dateFormat: 'HH:mm:ss',
              },
              sum: {
                column: ['Net', 9],
              },
              comment: {
                column: ['Name', 3],
                add: [
                  '; ',
                  {
                    column: ['Transaction ID', 12],
                  },
                  '; ',
                  {
                    column: ['Item Title', 13],
                  },
                ],
              },
              currency: {
                column: ['Currency', 6],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: {
            simpleParse: true,
          },
          proceedCaseConfig: {
            fields: {
              date: {
                or: [
                  {
                    column: ['Date', 'Дата', 0],
                    dateFormat: 'DD/MM/YYYY',
                    if: {
                      headerIn: 'TimeZone',
                    },
                  },
                  {
                    column: ['Date', 'Дата', 0],
                    dateFormat: 'MM/DD/YYYY',
                  },
                ],
              },
              time: {
                column: ['Time', 'Время', 1],
                dateFormat: 'HH:mm:ss',
              },
              sum: {
                column: ['Net', 'Чистая сумма', 7],
              },
              comment: {
                joinIfExist: {
                  arr: [
                    {
                      column: ['Description', 'Описание', 3],
                    },
                    {
                      column: ['Transaction ID', 'Код операции', 9],
                    },
                    {
                      column: ['Name', 'Название', 11],
                    },
                  ],
                  by: '; ',
                },
              },
              currency: {
                column: ['Currency', 'Валюта', 4],
              },
            },
          },
        },
      },
    ],
  };
}
