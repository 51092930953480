import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Dialog from '../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../components/PreviewRegisterDialog';
import usePreviewRegister from '../../../hooks/usePreviewRegister';
import { selectIsDemoCompany } from '../../../store/company/selectors';
import { IntegrationTypeId } from '../../../store/integrations/types';
import RenderBanksList from '../../Integrations/BanksList';
import CreateAccountDialogWithoutBank from '../CreateAccountDialogWithoutBank';
import IntegrationBankToNewAccountDialog from '../IntegrationBankToNewAccountDialog';
import { useStyles } from './styles';
import { Props } from './types';
import useIntegrationToNewAccount from './useIntegrationToNewAccount';

function CreateAccountDialog(props: Props) {
  const { onClose, isReconnect } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const isDemoCompany = useSelector(selectIsDemoCompany);

  const {
    integrationTypeId,
    showOnboardingTooltips,
    onSetIntegrationTypeId,
    onShowOnboardingTooltips,
    onCloseOnboardingTooltips,
    showIntegrationToNewAccount,
    onOpenIntegrationToNewAccount,
    onCloseIntegrationToNewAccount,
  } = useIntegrationToNewAccount();

  const {
    showPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
  } = usePreviewRegister();

  const [
    showCreateAccountDialogWithoutBank,
    setShowCreateAccountDialogWithoutBank,
  ] = useState(false);

  const handleCloseIntegrationToNewAccount = useCallback(() => {
    onCloseIntegrationToNewAccount();
    onShowOnboardingTooltips();
  }, [onCloseIntegrationToNewAccount, onShowOnboardingTooltips]);

  const handleCloseCreateAccountDialogWithoutBank = useCallback(
    (closeRoot: boolean) => {
      setShowCreateAccountDialogWithoutBank(false);

      onShowOnboardingTooltips();

      if (closeRoot && onClose) {
        onClose();
      }
    },
    [onClose, onShowOnboardingTooltips],
  );

  const handleOpenCreateAccountDialogWithoutBank = useCallback(() => {
    if (isDemoCompany) {
      onOpenPreviewRegisterDialog();

      return;
    }

    setShowCreateAccountDialogWithoutBank(true);

    onCloseOnboardingTooltips();
  }, [onCloseOnboardingTooltips, onOpenPreviewRegisterDialog, isDemoCompany]);

  const handleSetupBank = useCallback(
    (id: IntegrationTypeId) => {
      if (isDemoCompany) {
        onOpenPreviewRegisterDialog();

        return;
      }

      onSetIntegrationTypeId(id);

      onOpenIntegrationToNewAccount();
    },
    [
      isDemoCompany,
      onSetIntegrationTypeId,
      onOpenPreviewRegisterDialog,
      onOpenIntegrationToNewAccount,
    ],
  );

  return (
    <>
      <Dialog
        isOpened
        onClose={onClose}
        titleClassName={classes.title}
        title={t('accounts.newAccount.title')}
      >
        <>
          {!isReconnect && (
            <>
              <Typography className={classes.description}>
                {t('accounts.newAccount.description')}
              </Typography>
              <div
                id="create_my_accounts"
                className={classes.createContainer}
                onClick={handleOpenCreateAccountDialogWithoutBank}
              >
                <Typography
                  id="create_my_accounts_text"
                  className={classes.text}
                >
                  {t('accounts.newAccount.create')}
                </Typography>
              </div>
            </>
          )}
        </>
        <RenderBanksList
          description={isReconnect ? '' : t('accounts.newAccount.useBank')}
          onSetupBank={handleSetupBank}
          showFondy={false}
          onClose={onClose}
          showOnboardingTooltips={showOnboardingTooltips}
          onCloseOnboardingTooltips={onCloseOnboardingTooltips}
          onShowOnboardingTooltips={onShowOnboardingTooltips}
        />
      </Dialog>
      {showCreateAccountDialogWithoutBank && (
        <CreateAccountDialogWithoutBank
          onClose={handleCloseCreateAccountDialogWithoutBank}
        />
      )}
      {showIntegrationToNewAccount && integrationTypeId && (
        <IntegrationBankToNewAccountDialog
          integrationTypeId={integrationTypeId}
          onClose={handleCloseIntegrationToNewAccount}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog onClose={onClosePreviewRegisterDialog} />
      )}
    </>
  );
}

export default React.memo(CreateAccountDialog);
