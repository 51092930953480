export const STRIPE_BANK_NAME = 'Stripe';

export const nordigenBanks = [
  'ING',
  'Ebury',
  'Inteligo',
  'Bank Millennium',
  'Nest Bank Corporate',
  'Nest Bank Private',
  'Santander Bank',
  'Santander Bank Corporate',
  STRIPE_BANK_NAME,
];
