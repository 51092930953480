import { Tooltip } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import Fade from '@mui/material/Fade';
import cn from 'classnames';
import React, { ReactElement } from 'react';

import { useStyles } from './styles';

export type Props = {
  open: boolean;
  children: ReactElement;
  title: string | JSX.Element;
  placement: TooltipProps['placement'];
  arrowClasses?: string;
  tooltipClasses?: string;
};

function TooltipWrapper(props: Props) {
  const { open, children, title, placement, tooltipClasses, arrowClasses } =
    props;

  const classes = useStyles();

  return (
    <Tooltip
      title={title}
      open={open}
      placement={placement}
      arrow
      interactive
      classes={{
        tooltip: cn(classes.tooltip, tooltipClasses),
        arrow: cn(classes.tooltipArrow, arrowClasses),
      }}
      // @ts-ignore
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
    >
      {children}
    </Tooltip>
  );
}

export default React.memo(TooltipWrapper);
