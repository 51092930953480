import { StyledProps } from "../../../scenes/InfoBlock/Journal/JournalContainer/types";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  amountContainer: {
    position: 'relative',
    marginBottom: (props: StyledProps) => (props.isError ? 32 : 0),
  },
});
