import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import CustomButton from '../../components/Button';
import useOnboardingV2 from '../../hooks/useOnboardingV2';
import OnboardingNoAvailable from '../../scenes/InfoBlock/OnBoardingV2/Components/OnboardingNoAvailable';
import { useStyles } from './styles';

function RenderWriteUsButton({ onClose }: { onClose(): void }) {
  const classes = useStyles();

  const [showNoAvailableDialog, setShowNoAvailableDialog] = useState(false);

  const { t } = useTranslation();
  const { showNewMessages } = useIntercom();
  const { isCreateImportActiveStep } = useOnboardingV2();

  const handleCloseNoAvailableDialog = useCallback(() => {
    setShowNoAvailableDialog(false);
  }, []);

  const handleClickButton = useCallback(() => {
    if (isCreateImportActiveStep) {
      setShowNoAvailableDialog(true);
    } else {
      showNewMessages(t('import.writeUs'));

      onClose();
    }
  }, [t, onClose, showNewMessages, isCreateImportActiveStep]);

  return (
    <>
      <CustomButton
        fullWidth
        className={classes.button}
        action={handleClickButton}
        title={t('import.wantToImportFrom')}
      />
      {showNoAvailableDialog && (
        <OnboardingNoAvailable onClose={handleCloseNoAvailableDialog} />
      )}
    </>
  );
}

export default React.memo(RenderWriteUsButton);
