import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  filteredIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: persianGreen,
  },
});
