import { black } from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 13,
    lineHeight: '16px',
    color: black,
    paddingLeft: 16,
  },
});
