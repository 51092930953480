import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MonobankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          "defaultCurrency": "UAH",
          "preParserConfigs": {
            "interlineSpacingAccuracy": 10,
            "verticalAlign": "middle",
            "prepareRawConfig": {
              "findHeader": {
                "from": [
                  {
                    "word": {
                      "eq": "Сума у"
                    },
                    "nexWord": {
                      "eq": "валюті"
                    }
                  }
                ],
                "to": [
                  {
                    "word": {
                      "eq": "зарахувань)"
                    },
                    "prevWord": {
                      "eq": "(для"
                    }
                  }
                ]
              },
              "delete": [
                {
                  "to": [
                    {
                      "word": {
                        "eq": "Сума у"
                      },
                      "nexWord": {
                        "eq": "валюті"
                      }
                    }
                  ],
                  "count": 1
                },
                {
                  "from": [
                    {
                      "word": {
                        "eq": "Операційний директор"
                      }
                    }
                  ],
                  "count": 1
                }
              ],
              "define": [
                {
                  "dateFormat": "DD.MM.YYYY"
                }
              ]
            }
          },
          "proceedCaseConfig": {
            "fields": {
              "dateAndTime": {
                "column": [
                  "Дата та час\nоперації",
                  0
                ]
              },
              "comment": {
                "column": [
                  "Деталі операції",
                  1
                ]
              },
              "sum": {
                "column": [
                  "Сума\nоперації",
                  3
                ],
                "replace": [
                  {
                    "from": " ",
                    "to": ""
                  }
                ]
              }
            }
          }
        }
      }
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
    {
      caseOptions: {
        "defaultCurrency": "UAH",
        "withoutEmpty": true,
        "isCurCase": [
            [
                0,
                {
                    "in": "Клієнт:"
                }
            ]
        ],
        "proceedCaseConfig": {
            "withoutEmpty": true,
            "delete": [
                {
                    "to": [
                        [
                            0,
                            {
                                "eq": "Дата i час операції"
                            }
                        ],
                        [
                            1,
                            {
                                "eq": "Деталі операції"
                            }
                        ]
                    ],
                    "count": 1
                }
            ],
            "fields": {
                "sum": {
                    "column": [
                        "Сума в валюті картки (UAH)",
                        3
                    ]
                },
                "dateAndTime": {
                    "column": [
                        "Дата i час операції",
                        0
                    ]
                },
                "comment": {
                    "column": [
                        "Деталі операції",
                        1
                    ]
                }
            }
        }
      }
    }],
  };
}
