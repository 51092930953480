import Selected from '../../../../assets/images/svg/selected.svg';
import Unselected from '../../../../assets/images/svg/unselected.svg';
import Dialog from '../../../../components/Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

function RemoveAccountDialog(props: Props) {
  const { onClose, onRemove } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [selected, setSelected] = useState(false);

  const handleChange = useCallback(() => {
    setSelected(!selected);
  }, [selected]);

  const handleRemove = useCallback(() => {
    onRemove(selected);
  }, [onRemove, selected]);

  return (
    <Dialog isOpened onClose={onClose} title={t('integrations.remove.title')}>
      <div>
        <Typography className={classes.description}>
          {t('integrations.remove.description')}
        </Typography>
        <FormControlLabel
          className={classes.selectContainer}
          control={
            <Checkbox
              disableRipple
              className={classes.checkboxRoot}
              checked={selected}
              onChange={handleChange}
              checkedIcon={<img alt="" src={Selected} />}
              icon={<img alt="" src={Unselected} />}
            />
          }
          label={
            <span className={classes.subTitle}>
              {t('integrations.remove.all')}
            </span>
          }
        />
        <div className={classes.footer}>
          <Button
            className={cn(classes.button, classes.buttonRemove)}
            onClick={handleRemove}
          >
            <Typography className={classes.buttonRemoveText}>
              {t('integrations.switchOff')}
            </Typography>
          </Button>
          <Button
            className={cn(classes.button, classes.buttonCancel)}
            onClick={onClose}
          >
            <Typography className={classes.buttonCancelText}>
              <span role="img">{t('system.changeMind')} 🌝</span>
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default React.memo(RemoveAccountDialog);
