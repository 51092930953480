import { Button, MenuItem, MenuList, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from 'classnames';
import React, {
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PopperComponent from '../../../components/PopperComponent';
import { Seller } from '../../../services/bankIntegrations/facturownia.types';
import accountActions from '../../../store/accounts/actions';
import { selectOperationAccounts } from '../../../store/accounts/selectors';
import { useStyles } from './styles';
import { SelectedAccounts } from './types';

type Props = {
  item: Seller;
  selected: string[];
  selectedAccounts: SelectedAccounts;
  accountsErrors: Seller['sellerBankAccount'][];

  onSetSelectedAccounts(values: SelectedAccounts): void;
  onOpenCreateAccountDialog(id: Seller['sellerBankAccount']): void;
  onUpdateAccountsError(id: Seller['sellerBankAccount']): void;
};

function RenderAccountSelector(props: Props) {
  const {
    item,
    selected,
    accountsErrors,
    selectedAccounts,
    onUpdateAccountsError,
    onSetSelectedAccounts,
    onOpenCreateAccountDialog,
  } = props;

  const anchorRefPeriod = useRef<HTMLButtonElement>(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showAccountsList, setShowAccountsList] = useState(false);

  const operationAccounts = useSelector(selectOperationAccounts);

  const accounts = useMemo(() => {
    const selectedIds = Object.keys(selectedAccounts).map(
      (el) => selectedAccounts[el]._id,
    );

    return operationAccounts.filter((el) => !selectedIds.includes(el._id));
  }, [operationAccounts, selectedAccounts]);

  const handleOpenCreateAccountDialog = useCallback(() => {
    onOpenCreateAccountDialog(item.sellerBankAccount);
  }, [onOpenCreateAccountDialog, item]);

  const handleToggleAccountsList = useCallback(() => {
    if (!showAccountsList) {
      dispatch(accountActions.getOperationAccounts());
    }

    setShowAccountsList(!showAccountsList);
  }, [dispatch, showAccountsList]);

  const handleCloseAccountsList = useCallback(() => {
    setShowAccountsList(false);
  }, []);

  const handleSetAccount = useCallback(
    (event: SyntheticEvent) => {
      // @ts-ignore
      const { id } = event.target;
      const currentAccount = accounts.find((el) => el._id === id);

      if (currentAccount) {
        onSetSelectedAccounts({
          ...selectedAccounts,
          [item.sellerBankAccount]: currentAccount,
        });

        onUpdateAccountsError(item.sellerBankAccount);
      }

      handleToggleAccountsList();
    },
    [
      item,
      accounts,
      selectedAccounts,
      onUpdateAccountsError,
      onSetSelectedAccounts,
      handleToggleAccountsList,
    ],
  );

  return (
    <>
      <Button
        disabled={!selected.includes(item.sellerBankAccount)}
        ref={anchorRefPeriod}
        className={classes.accountsSelector}
        onClick={handleToggleAccountsList}
      >
        <Typography className={classes.accountName}>
          {selectedAccounts[item.sellerBankAccount]
            ? selectedAccounts[item.sellerBankAccount].name
            : t('operationDialogs.account.placeholder')}
        </Typography>
        {showAccountsList ? (
          <ArrowDropUpIcon className={classes.arrowIcon} />
        ) : (
          <ArrowDropDownIcon className={classes.arrowIcon} />
        )}
        {accountsErrors.includes(item.sellerBankAccount) && (
          <Typography className={classes.error}>
            {t('system.fieldMustFilled')}
          </Typography>
        )}
      </Button>

      {showAccountsList && (
        <PopperComponent
          anchorRef={anchorRefPeriod}
          onClose={handleCloseAccountsList}
          placement="bottom-start"
          paperClasses={classes.paper}
        >
          <MenuList className={cn(classes.list, 'scrollable-list')}>
            {accounts.map((el) => (
              <MenuItem id={el._id} key={el._id} onClick={handleSetAccount}>
                {el.name || el.label}
              </MenuItem>
            ))}
            <MenuItem onClick={handleOpenCreateAccountDialog}>
              <Typography className={classes.newAccount}>
                {t('bank.facturownia.createNewAccount')}
              </Typography>
            </MenuItem>
          </MenuList>
        </PopperComponent>
      )}
    </>
  );
}

export default React.memo(RenderAccountSelector);
