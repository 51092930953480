import { osloGray } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 12,
    borderWidth: 1,
    border: `1px solid ${osloGray}`,
    padding: '18px 16px',
    marginTop: 16,
    marginBottom: 16,
  },
  footerText: {
    fontSize: 13,
    lineHeight: '18px',
    color: osloGray,
    width: '80%'
  },
  infoIcon: {
    marginRight: 12,
  },
});
