import parse from 'csv-parse/lib/sync';
import moment from 'moment';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class SebBankLatviaCsvParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    date: [['DATUMS'], null],
    currency: [['MAKSĀJUMA VALŪTA'], null],
    sum: [['SUMMA KONTA VALŪTĀ'], null],
    counterparty: [['PARTNERA NOSAUKUMS'], null],
    operation: [['DEBETS/ KREDĪTS'], null],
    comment: [['MAKSĀJUMA MĒRĶIS'], null],
  };

  protected importName = 'SebBankUkraine';
  protected beforeTransformed: AnyObject[];
  protected skipNext: boolean = false;
  private mainAccount: Account;

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.values(this.headersJson);
    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames[0] === h)
          return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
      }
    });

    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const currentColumns = this.getAllColumns(operation, index);

    try {
      const isAfter = moment(currentColumns.date).isAfter(
        moment().add(10, 'y'),
      );
      const isBefore = moment(currentColumns.date).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      if (isAfter || isBefore) return {};
    } catch (e) {
      console.log(e);
    }

    const { type, subType, accountFromId, accountToId, sum } =
      this.getTypeAndSum(currentColumns);

    const resultObject = {
      index,
    };
    this.addIfNotFalsy(resultObject, {
      date: this.dateToFormat2(this.dateParser(currentColumns.date)),
      accountFromId,
      accountToId,
      counterparty: currentColumns.counterparty,
      sum,
      type,
      subType,
      comment: `${currentColumns.comment}`,
    });

    const currency = currentColumns.currency.toUpperCase();
    const currencyCode = CURRENCIES.find((cur) =>
      [cur.code, cur.native].includes(currency),
    )?.code;

    this.setAccountIDsByType(resultObject, currencyCode, currencyCode);

    return resultObject;
  }

  private getTypeAndSum(currentColumns: AnyObject) {
    let obj = {
      sum: undefined,
      type: undefined,
      subType: undefined,
      accountFromId: undefined,
      accountToId: undefined,
    };

    if (currentColumns.operation === 'D') {
      obj.sum = Number(currentColumns.sum);
      obj.type = OperationType.CONSUMPTION;
      obj.subType = 'supplier';
      obj.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      obj.sum = Number(currentColumns.sum);
      obj.type = OperationType.INCOME;
      obj.subType = 'sale';
      obj.accountToId = this.mainAccount.normalizedLabel;
    }

    return obj;
  }

  private deleteHeaders(sheet, title) {
    function isTitle(raw) {
      const { docInfo, headers } = title;
      if (raw.A) {
        const firstCheck = docInfo.B;
        const secondCheck = headers.B;
        const rawCheckColumn = raw.B;

        return firstCheck !== rawCheckColumn && secondCheck !== rawCheckColumn;
      }
      return true;
    }

    return sheet.filter((raw) => isTitle(raw));
  }

  public setRawData(raw: Buffer, account: any) {
    this.mainAccount = account;
    const buffer = Buffer.from(raw);

    const parseOptions = {
      from_line: 1,
      delimiter: ';',
      quote: '',
      relax_column_count: true,
      onRecord: (record: any) => {
        for (const [key, val] of Object.entries(record)) {
          record[key] = val.toString().replace(/"/g, '');
        }
        return record;
      },
      columns: UPPER_ALPHABET,
    };

    const allSheet = parse(buffer, parseOptions);
    const [docInfo, headers] = allSheet;

    this.headersJson = headers;
    this.sheetJson = this.deleteHeaders(allSheet, { docInfo, headers });
  }
}
