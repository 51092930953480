import React from 'react';

import EditIconSvg from '../../assets/images/svg/edit.svg';

export interface EditIconProps {
  className?: string;
  onClick?(): void;
  isActive?: boolean;
}

export function EditIcon({
  className,
  onClick,
  isActive = true,
}: EditIconProps) {
  if(!isActive) return null;
  
  return <img
  src={EditIconSvg}
  alt="edit"
  onClick={onClick}
  className={className}
/>
}

export default EditIcon;