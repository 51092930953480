import React from 'react';

import { USE_CALENDAR_AIRBNB_VERSION } from '../../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../../hooks/useUnleash';
import CalendarAirbnbVersion from './CalendarAirbnbVersion';
import CalendarDialog from './CalendarDialog';
import { Props } from './CalendarDialog.types';

function CalendarContainer(props: Props) {
  const useCalendarAirbnbVersion = useUnleash(USE_CALENDAR_AIRBNB_VERSION);

  return useCalendarAirbnbVersion ? (
    <CalendarAirbnbVersion {...props} />
  ) : (
    <CalendarDialog {...props} />
  );
}

export default React.memo(CalendarContainer);
