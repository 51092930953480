import { DEF_PER_TYPES_IDS } from "../interfaces/permissions.interface";

export const DEFAULT_PERMISSION_TYPES: {
  _id: string;
  value: string;
  kind: string;
}[] = [
  { _id: DEF_PER_TYPES_IDS.PATCH, value: 'patch', kind: 'edit' },
  { _id: DEF_PER_TYPES_IDS.PUT, value: 'put', kind: 'edit' },
  { _id: DEF_PER_TYPES_IDS.DELETE, value: 'delete', kind: 'edit' },
  { _id: DEF_PER_TYPES_IDS.POST, value: 'post', kind: 'edit' },
  { _id: DEF_PER_TYPES_IDS.GET, value: 'get', kind: 'read' },
];
