import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import TransitionComponent from '../../../../components/TransitionComponent';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { selectCompanyCurrencySymbol } from '../../../../store/company/selectors';
import operationActions from '../../../../store/operations/actions';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../../store/operations/selectors';
import { formatStringPrice } from '../../../../utils/parseStringToNumber';
import { useCompareStyles } from '../compareStyles';
import { useStyles } from './TransferAmountComponent.styles';

type Props = {
  isCompare?: boolean;
  difference?: boolean;
  isNewState?: boolean;
};

function TransferAmountComponent(props: Props) {
  const { isCompare, difference, isNewState } = props;

  const opProps = useSelector(getOperationProps);
  const nextOperationProps = useSelector(getHistoryOperationProps);

  const operationProps = isNewState ? nextOperationProps : opProps;

  const { amount, account, toAccount, createAndCopyState } = operationProps;

  const companyCurrencySymbol = useSelector(selectCompanyCurrencySymbol);
  const subscriptionActive = useSubscriptionActive();

  const classes = useStyles();
  const compareClasses = useCompareStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const placeholder = useCallback(
    () =>
      `${t('common.sum')}, ${
        account?.currency.symbol ??
        toAccount?.currency.symbol ??
        companyCurrencySymbol
      }`,
    [
      account?.currency.symbol,
      companyCurrencySymbol,
      t,
      toAccount?.currency.symbol,
    ],
  );

  const isCrypto = account?.crypto || toAccount?.crypto;

  const balanceDigits = isCrypto ? 9 : 3;

  const handleResetCreateAndCopyState = useCallback(() => {
    dispatch(
      operationActions.setCreateAndCopyState({ createAndCopyState: false }),
    );
  }, [dispatch]);

  const handleChange = useCallback(
    (value: number | string) => {
      dispatch(
        operationActions.setAmount({
          amount: formatStringPrice(value, false, balanceDigits),
        }),
      );
    },
    [dispatch, balanceDigits],
  );

  useEffect(() => {
    dispatch(operationActions.resetRates());
    dispatch(operationActions.setCurrencyAmount({ currencyAmount: null }));
    dispatch(operationActions.setExchangeRate({ exchangeRate: null }));
  }, [dispatch, account]);

  return (
    <div
      onFocus={handleResetCreateAndCopyState}
      className={cn(
        classes.amountFlex,
        createAndCopyState && classes.marginBottom32,
      )}
    >
      <TextFieldComponent
        id="cypress-operation-amount"
        disabled={isCompare || !subscriptionActive}
        injectClassName={cn(isCompare && compareClasses.disabledInput)}
        rootClassName={cn(
          classes.textRoot,
          difference && compareClasses.root,
          createAndCopyState && classes.blueBackground,
        )}
        onChange={handleChange}
        value={amount || ''}
        label={(Boolean(amount) && placeholder()) || ''}
        placeholder={placeholder()}
      />
      <TransitionComponent
        className={classes.blueColor}
        enter={createAndCopyState}
        text={t('operationDialogs.setPaymentAmount')}
      />
    </div>
  );
}

export default React.memo(TransferAmountComponent);
