import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import companyActions from '../../../../../../store/company/actions';
import { getApiKeys } from '../../../../../../store/company/selectors';

export type Props = {
  onClose(): void;
};

function CreateApiDialog(props: Props) {
  const { onClose } = props;
  const apiKeys = useSelector(getApiKeys);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [apiKeyName, setApiKeyName] = useState<string>(
    t('api.new', {
      postProcess: 'sprintf',
      sprintf: [apiKeys.length + 1],
    }),
  );

  const handleChangeName = useCallback((val: string) => {
    setApiKeyName(val);
  }, []);

  const handleCreate = useCallback(async () => {
    dispatch(companyActions.createApiKey(apiKeyName));

    onClose();
  }, [apiKeyName, onClose, dispatch]);

  return (
    <Dialog title={t('api.title')} onClose={onClose} isOpened>
      <TextFieldComponent
        value={apiKeyName}
        onChange={handleChangeName}
        placeholder={t('common.title')}
      />
      <CustomButton
        fullWidth
        action={handleCreate}
        title={t('api.generateKey')}
      />
    </Dialog>
  );
}

export default React.memo(CreateApiDialog);
