import './styles.scss';
import { Typography } from '@material-ui/core';
import cn from 'classnames';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import { MAX_IMPORT_OPERATION_FILE_SIZE } from '../../../../constants';
import usePermissions from '../../../../hooks/usePermissions';
import Http from '../../../../services/Http';
import journalActions from '../../../../store/journal/actions';
import operationActions from '../../../../store/operations/actions';
import operationApi from '../../../../store/operations/api';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../../store/operations/selectors';
import {
  Attachment,
  OperationSubType,
  OperationType,
} from '../../../../store/operations/types';
import RenderSelectFileComponent from '../../../ImportDialog/Components/RenderSelectFileComponent';
import RenderFileInfo from './RenderFileInfo';
import { useStyles } from './styles';
import { Props } from './types';

function AttachmentComponent(props: Props) {
  const { isCompare, isNewState, diffAttachments } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { attachments: oldAttachments, editOperationId } =
    useSelector(getOperationProps);
  const { attachments: nextAttachments } = useSelector(
    getHistoryOperationProps,
  );

  const { operationEnable } = usePermissions();

  const attachments = isNewState ? nextAttachments : oldAttachments;

  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [dropdownZoneActive, setDropdownZoneActive] = useState(!isCompare);
  const [progressBarValue, updateProgressBarValue] = useState<number | null>(
    null,
  );

  const classes = useStyles({
    width: progressBarValue || 0,
  });

  const readerResultRef = useRef<string | ArrayBuffer | null>();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFileError('');

      acceptedFiles.forEach((currentFile: File) => {
        if (currentFile.size > MAX_IMPORT_OPERATION_FILE_SIZE) {
          setFileError(
            t('import.errors.tooBig', {
              postProcess: 'sprintf',
              sprintf: [16],
            }),
          );

          return;
        }

        const reader = new FileReader();

        reader.onload = async () => {
          setFileName(currentFile.name);

          readerResultRef.current = reader.result;

          const formData = new FormData();

          formData.append('content', currentFile, currentFile.name);

          const { data } = await Http.makeInstance().post(
            '/storage/upload',
            formData,
            {
              onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.event.lengthComputable
                  ? progressEvent.total
                  : progressEvent.event.target.getResponseHeader(
                      'content-length',
                    ) ||
                    progressEvent.event.target.getResponseHeader(
                      'x-decompressed-content-length',
                    );

                if (totalLength !== null) {
                  updateProgressBarValue(
                    Math.round((progressEvent.loaded * 100) / totalLength),
                  );
                }
              },
            },
          );

          dispatch(
            operationActions.setAttachment({
              contentId: data.contentId,
              label: currentFile.name,
              _id: '',
            }),
          );

          dispatch(operationActions.setRepeats({ repeats: { id: 1000 } }));
          dispatch(operationActions.setScheduled({ scheduled: false }));
        };

        reader.readAsArrayBuffer(currentFile);
      });
    },
    [t, dispatch],
  );

  const handleClear = useCallback(() => {
    setFileName('');
    setFileError('');
    updateProgressBarValue(null);
    setDropdownZoneActive(true);
    dispatch(operationActions.setAttachment(null));
  }, [dispatch]);

  const handleRemoveAttachment = useCallback(
    async (attachmentId: string) => {
      if (editOperationId) {
        await operationApi.removeAttachment({
          type: OperationType.income,
          subType: OperationSubType.loan,
          operationId: editOperationId,
          attachmentId,
        });

        const filteredAttachments = attachments.filter(
          (el) => el._id !== attachmentId,
        );

        dispatch(operationActions.setAttachments(filteredAttachments));

        dispatch(
          journalActions.removeAndUpdateAttachments(
            editOperationId,
            attachmentId,
          ),
        );
      }
    },
    [dispatch, editOperationId, attachments],
  );

  const handleDownloadNewAttachment = useCallback(() => {
    if (readerResultRef.current && fileName) {
      fileDownload(readerResultRef.current, fileName);
    }
  }, [fileName]);

  const handleDownload = useCallback(
    async (attachmentData: Attachment) => {
      toast(t('toasts.downloadWasStarted'));

      const { contentId, label } = attachmentData;

      const { data } = await operationApi.downloadAttachment(contentId);

      fileDownload(data, label);
    },
    [t],
  );

  useEffect(() => {
    if (fileError) {
      setFileName('');
    }
  }, [fileError]);

  useEffect(() => {
    if (!isCompare && operationEnable) {
      setDropdownZoneActive(true);
    }
  }, [isCompare, operationEnable]);

  if (isCompare) {
    const values = attachments?.map((el) => el.label) ?? [];

    return (
      <TextFieldComponent
        isCompare
        difference={diffAttachments}
        disabled
        readableOnly
        label={t('operation.removeDialogText.attachment')}
        placeholder={t('operation.removeDialogText.attachment')}
        value={values.join(',')}
      />
    );
  }

  return (
    <>
      {!fileName && dropdownZoneActive && (
        <RenderSelectFileComponent onDrop={onDrop} />
      )}
      {!!fileError && (
        <Typography className={cn(classes.smallText, classes.errorText)}>
          {fileError}
        </Typography>
      )}
      {fileName && (
        <RenderFileInfo
          width={progressBarValue || 0}
          fileName={fileName}
          onRemove={handleClear}
          onDownload={handleDownloadNewAttachment}
        />
      )}
      {attachments?.map((attachment) => (
        <RenderFileInfo
          key={attachment._id}
          completed
          width={0}
          fileName={attachment.label}
          attachment={attachment}
          onDownload={handleDownload}
          onRemove={handleRemoveAttachment}
        />
      ))}
    </>
  );
}

export default React.memo(AttachmentComponent);
