import { isEmptyImportField } from '@finmap/core-utils';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

const CATEGORIES_TO_EXCLUDE = [
  'Расходы',
  'Внеоборотные активы',
  'Обязательства',
  'Доходы',
  'Капитал',
  'Оборотные активы',
];

export class PlanfactImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const comment = [
              this.getFirstValidCellByColumn(['Комментарий']),
              this.getFirstValidCellByColumn(['Назначение платежа']),
            ]
              .filter((s) => !isEmptyImportField(s))
              .join('; ');

            const category = this.getFirstValidCellByColumn([
              'Родительские статьи',
            ])
              ?.split(' - ')
              ?.filter((s) => !CATEGORIES_TO_EXCLUDE.includes(s))
              ?.join('; ');

            const subCategory = this.getFirstValidCellByColumn(['Статья']);
            return {
              date: this.getFirstValidCellByColumn(['Дата оплаты']),
              dateOfPayment: this.getFirstValidCellByColumn([
                'Дата начисления',
              ]),
              sum: this.getFirstValidCellByColumn(['Сумма']),
              project: this.getFirstValidCellByColumn(['Проекты']),
              counterparty: this.getFirstValidCellByColumn(['Контрагент']),
              currency: this.getFirstValidCellByColumn(['Валюта']),
              accountName: this.getFirstValidCellByColumn(['Счет'])?.replace(
                /^\[.*\]$/,
                '',
              ),
              comment,
              category: !isEmptyImportField(category) ? category : subCategory,
              subCategory: !isEmptyImportField(category) ? subCategory : '',
            };
          };
        },
        caseOptions: { defaultCurrency: 'RUB' },
      },
    ],
  };
}
