import { Button, FormControlLabel, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Selected from '../../assets/images/svg/selected.svg';
import Unselected from '../../assets/images/svg/unselected.svg';
import Storages from '../../services/Storages';
import checkTime from '../../utils/checkTime';
import { getTimeOffset } from '../../utils/dateToUTC';
import Dialog from '../Dialog/Dialog';
import { useStyles } from './RemoveDialog.styles';
import { Props } from './RemoveDialog.types';

function RemoveDialog(props: Props) {
  const {
    name,
    type,
    title,
    onClose,
    onRemove,
    alertText,
    description,
    subDescription,
    showCheckbox = true,
    dialogType = 'confirm',
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [selected, setSelected] = useState(false);

  const handleChange = useCallback(() => {
    setSelected(!selected);
  }, [selected]);

  const handleRemove = useCallback(
    (event: any) => {
      if (selected) {
        // @ts-ignore
        Storages.put(type, new Date().getTime() + getTimeOffset());
      }

      setSelected(false);
      onRemove(event);
    },
    [type, onRemove, selected],
  );

  const showConfirm = checkTime(type);

  if (!showConfirm) {
    onRemove();

    return null;
  }

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={title || `${t('system.sureToRemove')} ${name}?`}
    >
      <>
        {Boolean(description) && (
          <Typography className={classes.description}>{description}</Typography>
        )}
      </>
      <>
        {Boolean(subDescription) && (
          <Typography className={classes.description}>
            {subDescription}
          </Typography>
        )}
      </>
      <>
        {showCheckbox && (
          <FormControlLabel
            className={classes.selectContainer}
            control={
              <Checkbox
                disableRipple
                className={classes.checkboxRoot}
                checked={selected}
                onChange={handleChange}
                checkedIcon={<img alt="" src={Selected} />}
                icon={<img alt="" src={Unselected} />}
              />
            }
            label={
              <span className={classes.subTitle}>{t('common.notAsk')}</span>
            }
          />
        )}
      </>

      <div className={classes.footer}>
        <Button
          className={cn(
            classes.buttonRemove,
            dialogType === 'alert' ? classes.fullWidth : classes.halfWidth,
          )}
          onClick={handleRemove}
          id="cypress-confirm-remove-button"
        >
          <Typography className={classes.buttonRemoveText}>
            {alertText || t('common.remove')}
          </Typography>
        </Button>
        {dialogType === 'confirm' && (
          <Button className={classes.buttonCancel} onClick={onClose}>
            <Typography className={classes.buttonCancelText}>
              <span role="img">{t('system.changeMind')} 🌝</span>
            </Typography>
          </Button>
        )}
      </div>
    </Dialog>
  );
}

export default React.memo(RemoveDialog);
