import ErrorLogo from '../../assets/images/svg/error-cat.svg';
import CustomButton from '../../components/Button';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        <img src={ErrorLogo} alt="error" />
      </div>
      <Typography className={classes.title}>
        {`${t('system.youCanSeeMe')}`}
      </Typography>
      <Typography className={classes.description}>
        {t('system.weAlreadyWorking')}
      </Typography>
      <CustomButton
        title={t('system.reloadPage')}
        action={handleReload}
        className={classes.button}
      />
    </div>
  );
}

export default React.memo(ErrorPage);
