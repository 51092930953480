import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import moment from 'moment';

export class TaskombankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const [date, time] = this.getFirstValidCellByColumn(['Дата й час\nоперації', 0])?.split('\n');
            return {
              dateAndTime: `${date} ${time}`,
              comment: this.getFirstValidCellByColumn(['Деталі операції', 1]),
              sum: this.getFirstValidCellByColumn(['Сума у\nвалюті\nоперації', 4]),
            }
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          preParserConfigs: {
            interlineSpacingAccuracy: 10,
            separatorsDistanceModifier: 1.25,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата й час') && etc?.nextWord?.includes('Деталі операції'),
                (word, etc) =>
                  word?.includes('операції') && etc?.prevWord?.includes('після'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('операції') && etc?.prevWord?.includes('після'),
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('*') && etc?.nextWord?.includes('- Відображуються останні 4 цифри платіжної карти'),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => /^-*([\s\d]+\.\d+)$/.test(value), // '-22 200.00'
                (value) =>
                  moment(value, 'DD.MM.YYYY', true).isValid()
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
            date: this.getFirstValidCellByColumn(['Дата операції', 4]),
            counterparty: this.getFirstValidCellByColumn(['Кореспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn([
              'Призначення платежу',
              15,
            ]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/^Дата ОДБ/)?.raw;
          this.setDocumentHeader(importDocument[firstHeaderLine]);
          this.setDocumentBody(importDocument.slice(firstHeaderLine + 1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
            date: this.getFirstValidCellByColumn(['Дата ОДБ', 1]),
            counterparty: this.getFirstValidCellByColumn([
              'Найменування контрагента',
              6,
            ]),
            sum: this.getFirstValidCellByColumn(['Сумма', 2]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 4]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
