import { GET_PAYMENT_HISTORY } from './actions';
import { PaymentHistoryAction, PaymentHistoryState } from './types';

const initialState: PaymentHistoryState = {
  data: [],
};

const paymentHistoryReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: PaymentHistoryAction,
) => {
  switch (action.type) {
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentHistoryReducer;
