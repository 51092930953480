import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import categoryActions from '../store/categories/actions';
import { TreeDataItemProps } from '../store/categories/types';
import { CategoriesType, OperationType } from '../store/operations/types';

type Props = {
  item: TreeDataItemProps | null;
  type: CategoriesType;

  setError(value: string): void;
  onCloseEditRow?(): void;
};

function useRemoveCategory(props: Props) {
  const { setError, onCloseEditRow, item, type } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const onOpenRemoveDialog = useCallback(() => {
    // @ts-ignore
    if (item?.subcategories?.length) {
      setError(t('errors.haveSubCategories'));

      setTimeout(() => {
        setError('');
      }, 3000);

      return;
    }

    setShowRemoveDialog(true);
  }, [item, setError, t]);

  const onCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const onRemoveCategory = useCallback(() => {
    if (item) {
      const operationType =
        type === CategoriesType.myIncome
          ? OperationType.income
          : OperationType.consumption;

      dispatch(categoryActions.deleteCategory(item._id, operationType));
    }

    onCloseRemoveDialog();

    if (onCloseEditRow) {
      onCloseEditRow();
    }
  }, [dispatch, item, onCloseRemoveDialog, type, onCloseEditRow]);

  return {
    showRemoveDialog,
    onRemoveCategory,
    onOpenRemoveDialog,
    onCloseRemoveDialog,
  };
}

export default useRemoveCategory;
