import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import usePermissions from '../../../hooks/usePermissions';
import { SettingsName } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { selectIsOwner } from '../../../selectors/clientPermissions';
import {
  selectCompanyTypeFastPermissions,
  selectSettingsPermissions,
} from '../../../store/auth/selectors';

function usePermissionItemWrapper() {
  const isOwner = useSelector(selectIsOwner);
  const fastPermissionsType = useSelector(selectCompanyTypeFastPermissions);
  const settingsPermissions = useSelector(selectSettingsPermissions);
  const { resources } = usePermissions();

  const PermissionItemWrapper = useCallback(
    ({ label, children }: { label: SettingsName; children: JSX.Element }) => {
      if (isOwner) {
        return children;
      }

      if (fastPermissionsType) {
        return children;
      }

      const item = settingsPermissions?.resources.find(
        (res) => res.resourceId === resources[label],
      )?.typeIds.length;

      if (item) {
        return children;
      }

      return null;
    },
    [settingsPermissions, isOwner, resources, fastPermissionsType],
  );

  return { PermissionItemWrapper };
}

export default usePermissionItemWrapper;
