import { AnyAction } from 'redux';

import {
  CREATE_INTEGRATIONS_SUCCESS,
  CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING,
  CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS,
  CREATE_PRIVATE_24_CREDENTIALS_PENDING,
  CREATE_PRIVATE_24_CREDENTIALS_SUCCESS,
  GENERATE_TELEGRAM_API_KEY_PENDING,
  GENERATE_TELEGRAM_API_KEY_SUCCESS,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_TYPES_SUCCESS,
  GET_MONO_ACCOUNTS_PENDING,
  GET_MONO_ACCOUNTS_SUCCESS,
  GET_MONO_INITIAL_CREDENTIALS_PENDING,
  GET_MONO_INITIAL_CREDENTIALS_SUCCESS,
  GET_MONO_IS_GRANTED_SUCCESS,
  GET_SERVER_IP_SUCCESS,
  GET_TELEGRAM_API_KEYS_PENDING,
  GET_TELEGRAM_API_KEYS_SUCCESS,
  PRIVATE_24_CREATE_CREDENTIALS_ERROR,
  RESET_INTEGRATION_ACCOUNT,
  RESET_INTEGRATION_IN_PROGRESS,
  SET_CHECK_BOX_INTEGRATION_ID,
  SET_FAKTUROWNIA_INTEGRATION_ID,
  SET_FONDY_INTEGRATION_ID,
  SET_IDOKLAD_INTEGRATION_ID,
  SET_INTEGRATION_ACCOUNT,
  SET_INTEGRATION_IN_PROGRESS,
  SET_MONO_ACQUIRING_CREDENTIALS_ID,
  SET_MONO_CARD_ID_SUCCESS,
  SET_PAYONEER_CREDENTIALS_ID,
  SET_POSTER_INTEGRATION_ID,
  SET_UKRSIB_CODE,
  SET_UKRSIB_INTEGRATION_ID,
  SET_WESTERN_BID_INTEGRATION_ID,
  UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS,
} from './actions';
import { SET_NORDIGEN_INTEGRATION_ID } from './nordigenActions';
import { SET_BELVO_INTEGRATION_ID } from './belvoActions';
import { pumbActionTypes } from './pumbActions';
import { SET_SALTEDGE_INTEGRATION_ID } from './saltedgeActions';
import { IntegrationsState } from './types';

const initialState: IntegrationsState = {
  monoIsGranted: false,
  monoAccounts: [],
  integrations: [],
  integrationTypes: [],
  integrationCredentials: [],
  integrationPrivat24BusinessCredentials: null,
  initialMonoCredentials: null,
  loadingIntegrationCredentials: false,
  loadingMonoIntegration: false,
  integrationInProgress: false,
  integrationAccounts: [],
  telegramApi: {
    apiKeys: null,
    loading: false,
  },
  serverIp: '',
  privateCreateCredentialsError: false,
  integrationSaltedgeCredentials: [],
  saltedgeIntegrationId: null,
  pumbIntegrationId: null,
  fondyIntegrationId: null,
  westernBidIntegrationId: null,
  checkBoxIntegrationId: null,
  fakturowniaIntegrationId: null,
  iDokladIntegrationId: null,
  PosterIntegrationId: null,
  ukrsibIntegrationId: null,
  belvoIntegrationId: null,
  ukrsibCode: '',
  integrationNordigenCredentials: [],
  nordigenIntegrationId: null,
  payoneerCredentialsId: null,
  monoAcquiringCredentialsId: null,
};

const integrationsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case SET_NORDIGEN_INTEGRATION_ID: {
      return {
        ...state,
        nordigenIntegrationId: payload.id,
      };
    }

    case SET_BELVO_INTEGRATION_ID: {
      return {
        ...state,
        belvoIntegrationId: payload.id,
      };
    }

    case SET_PAYONEER_CREDENTIALS_ID: {
      return {
        ...state,
        payoneerCredentialsId: payload.id,
      };
    }

    case SET_MONO_ACQUIRING_CREDENTIALS_ID: {
      return {
        ...state,
        monoAcquiringCredentialsId: payload.id,
      };
    }

    case SET_UKRSIB_CODE: {
      return {
        ...state,
        ukrsibCode: payload.code,
      };
    }

    case SET_FAKTUROWNIA_INTEGRATION_ID: {
      return {
        ...state,
        fakturowniaIntegrationId: payload.id,
      };
    }

    case SET_UKRSIB_INTEGRATION_ID: {
      return {
        ...state,
        ukrsibIntegrationId: payload.id,
      };
    }

    case SET_IDOKLAD_INTEGRATION_ID: {
      return {
        ...state,
        iDokladIntegrationId: payload.id,
      };
    }

    case SET_POSTER_INTEGRATION_ID: {
      return {
        ...state,
        PosterIntegrationId: payload.id,
      };
    }

    case SET_SALTEDGE_INTEGRATION_ID: {
      return {
        ...state,
        saltedgeIntegrationId: payload.id,
      };
    }

    case SET_FONDY_INTEGRATION_ID: {
      return {
        ...state,
        fondyIntegrationId: payload.id,
      };
    }

    case SET_WESTERN_BID_INTEGRATION_ID: {
      return {
        ...state,
        westernBidIntegrationId: payload.id,
      };
    }

    case SET_CHECK_BOX_INTEGRATION_ID: {
      return {
        ...state,
        checkBoxIntegrationId: payload.id,
      };
    }

    case pumbActionTypes.SET_PUMB_INTEGRATION_ID: {
      return {
        ...state,
        pumbIntegrationId: payload.id,
      };
    }

    case GET_SERVER_IP_SUCCESS: {
      return {
        ...state,
        serverIp: payload.data.url,
      };
    }

    case GET_TELEGRAM_API_KEYS_PENDING:
    case GENERATE_TELEGRAM_API_KEY_PENDING: {
      return {
        ...state,
        telegramApi: {
          ...state.telegramApi,
          loading: true,
        },
      };
    }

    case GET_TELEGRAM_API_KEYS_SUCCESS:
    case GENERATE_TELEGRAM_API_KEY_SUCCESS: {
      return {
        ...state,
        telegramApi: {
          apiKeys: payload.data,
          loading: false,
        },
      };
    }

    case SET_INTEGRATION_ACCOUNT: {
      return {
        ...state,
        integrationAccounts: [...state.integrationAccounts, payload.data],
      };
    }

    case RESET_INTEGRATION_ACCOUNT: {
      const { id } = payload;
      const integrationAccounts = state.integrationAccounts.filter(
        (el) => el.id !== id,
      );

      return {
        ...state,
        integrationAccounts,
      };
    }

    case SET_INTEGRATION_IN_PROGRESS: {
      return {
        ...state,
        integrationInProgress: true,
      };
    }

    case RESET_INTEGRATION_IN_PROGRESS: {
      return {
        ...state,
        integrationInProgress: false,
      };
    }

    case CREATE_INTEGRATIONS_SUCCESS:
    case GET_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        integrations: payload.data,
        integrationCredentials: [],
      };
    }

    case GET_INTEGRATIONS_TYPES_SUCCESS: {
      return {
        ...state,
        integrationTypes: payload.data,
      };
    }

    case PRIVATE_24_CREATE_CREDENTIALS_ERROR: {
      return {
        ...state,
        loadingIntegrationCredentials: false,
        privateCreateCredentialsError: payload.error,
      };
    }

    case CREATE_PRIVATE_24_CREDENTIALS_PENDING:
    case CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_PENDING: {
      return {
        ...state,
        loadingIntegrationCredentials: true,
        privateCreateCredentialsError: false,
      };
    }

    case CREATE_PRIVATE_24_CREDENTIALS_SUCCESS:
    case UPDATE_PRIVATE24_BUSINESS_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        integrationCredentials: payload.data,
        loadingIntegrationCredentials: false,
      };
    }

    case CREATE_PRIVATE_24_BUSINESS_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        integrationPrivat24BusinessCredentials: payload.data,
        loadingIntegrationCredentials: false,
      };
    }

    case GET_MONO_IS_GRANTED_SUCCESS: {
      return {
        ...state,
        monoIsGranted: payload.data.isGranted,
      };
    }

    case GET_MONO_INITIAL_CREDENTIALS_PENDING: {
      return {
        ...state,
        loadingMonoIntegration: true,
      };
    }

    case GET_MONO_INITIAL_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        initialMonoCredentials: payload.data,
        loadingMonoIntegration: false,
      };
    }

    case GET_MONO_ACCOUNTS_PENDING: {
      return {
        ...state,
        loadingMonoIntegration: true,
      };
    }

    case GET_MONO_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        monoAccounts: payload.data,
        loadingMonoIntegration: false,
      };
    }

    case SET_MONO_CARD_ID_SUCCESS: {
      return {
        ...state,
        integrationCredentials: [payload.data],
      };
    }

    default:
      return state;
  }
};

export default integrationsReducer;
