import moment from 'moment';
import xlsx from 'xlsx';

import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class FintabloXlsxParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    date: [['Дата'], null],
    sum: [['Сумма'], null],
    account: [['Счет'], null],
    currency: [['Валюта'], null],
    counterparty: [['Контрагент'], null],
    article: [['Статья'], null],
    description: [['Описание'], null],
  };

  protected transferArticleNames: string[] = ['Перевод между счетами'];

  protected importName = 'Fintablo';
  protected beforeTransformed: AnyObject[];
  protected skipNext: boolean = false;

  public import(acounts: AnyObject[]) {
    this.acounts = acounts;
    this.beforeTransformed = this.doBeforeTranform();
    this.transformedData = this.beforeTransformed
      .map(this.transformOne.bind(this))
      .filter((res) => Object.keys(res).length);
    return this.doAfterTranform();
  }

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.values(this.headersJson);

    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(h))
          return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
      }
    });

    return this.sheetJson;
  }

  protected doAfterTranform(): AnyObject[] {
    return this.transformedData.filter((row) => Object.keys(row).length);
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const currentColumns = this.getAllColumns(operation, index);
    if (this.skipNext) {
      this.skipNext = false;
      return {};
    }

    try {
      const isAfter = moment(currentColumns.date).isAfter(
        moment().add(10, 'y'),
      );
      const isBefore = moment(currentColumns.date).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      if (isAfter || isBefore) return {};
    } catch (e) {
      console.log(e);
    }

    const { type, accountFromId, accountToId, subType } =
      this.getTypeAndAccountsId(currentColumns, index);

    const resultObject = {
      index,
      type,
    };
    this.addIfNotFalsy(resultObject, {
      date: this.dateToFormat2(this.dateParser(currentColumns.date)),
      accountFromId,
      accountToId,
      counterparty: currentColumns.counterparty,
      sum: Math.abs(this.customParseSum(currentColumns.sum, index)),
      subType,
    });

    const currency = currentColumns.currency.toUpperCase();
    const currencyCode = CURRENCIES.find((cur) =>
      [cur.code, cur.native].includes(currency),
    )?.code;

    this.setAccountIDsByType(resultObject, currencyCode, currencyCode);
    this.setComments(resultObject, [
      currentColumns.article,
      currentColumns.description,
    ]);

    return resultObject;
  }

  private customParseSum(rawSum: string, index: number): number {
    const result = Number(
      rawSum
        .toString()
        .replace(/\(|\)|' '/g, '')
        .split(',')
        .join('.'),
    );
    if (isNaN(result)) return this.throwError('Incorrect sum', index + 2);
    return result;
  }

  private getOperationType(
    index: number,
    currentColumns: AnyObject,
    anotherColumns: AnyObject = undefined,
  ): OperationType {
    const currentSum = this.customParseSum(currentColumns.sum, index);
    if (
      anotherColumns &&
      currentSum + this.customParseSum(anotherColumns.sum, index) === 0 &&
      this.transferArticleNames.includes(currentColumns.article) &&
      this.transferArticleNames.includes(anotherColumns.article)
    ) {
      this.skipNext = true;
      return OperationType.TRANSFER;
    }
    return currentSum < 0 ? OperationType.CONSUMPTION : OperationType.INCOME;
  }

  private getAccoutData(
    columns,
    type,
    nextColumns: AnyObject = undefined,
  ): { accountFromId?: string; accountToId?: string; subType?: string } {
    switch (type) {
      case OperationType.TRANSFER:
        return {
          accountFromId: columns.account,
          accountToId: nextColumns?.account,
        };
      case OperationType.CONSUMPTION:
        return {
          accountFromId: columns.account,
          subType: 'supplier',
        };
      case OperationType.INCOME:
        return {
          accountToId: columns.account,
          subType: 'sale',
        };
    }
  }

  private getTypeAndAccountsId(
    currentColumns,
    index,
  ): {
    type: OperationType;
    accountFromId: string;
    accountToId: string;
    subType?: string;
  } {
    let nextColumns;
    if (index !== this.beforeTransformed.length - 1)
      nextColumns = this.getAllColumns(
        this.beforeTransformed[index + 1],
        index + 1,
      );
    const type = this.getOperationType(index, currentColumns, nextColumns);
    const { accountFromId, accountToId, subType } = this.getAccoutData(
      currentColumns,
      type,
      nextColumns,
    );
    return {
      subType,
      accountFromId,
      accountToId,
      type,
    };
  }

  public setRawData(raw) {
    const wb = xlsx.read(raw, { type: 'buffer', cellDates: true });
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const [headersJson, ...sheetJson] = xlsx.utils.sheet_to_json(sheet, {
      header: 'A',
    });

    this.headersJson = headersJson;
    this.sheetJson = sheetJson;
  }
}
