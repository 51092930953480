import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GET_SERVER_TIME_INTERVAL } from '../../constants';
import { getUser } from '../../store/auth/selectors';
import commonActions from '../../store/common/actions';
import commonApis from '../../store/common/api';
import { getTimeOffset } from '../../utils/dateToUTC';

let interval: NodeJS.Timer;

function GetServerTime() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  if (user) {
    setTimeout(async () => {
      const {
        data: { time },
      } = await commonApis.getServerTime();

      dispatch(
        commonActions.setServerTime(new Date(time).getTime() + getTimeOffset()),
      );
    }, 0);
  }

  useEffect(() => {
    if (user) {
      interval = setInterval(async () => {
        const {
          data: { time },
        } = await commonApis.getServerTime();

        dispatch(
          commonActions.setServerTime(
            new Date(time).getTime() + getTimeOffset(),
          ),
        );
      }, GET_SERVER_TIME_INTERVAL);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, user]);

  return null;
}

export default GetServerTime;
