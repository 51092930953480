import FinmapLogo from '../../../assets/images/svg/logo-finmap.svg';
import { SaltedgeBankType } from '../../../store/integrations/types';
import { minOperationDate } from "../../../utils/dateFormat";

export const testSaltedgeBanks: SaltedgeBankType[] = [
  {
    code: "fake_oauth_client_xf",
    country_code: "XX",
    id: "FakeOauthClient",
    logo_url: FinmapLogo,
    max_consent_days: minOperationDate().getTime(),
    name: "FakeOauthClient",
    status: "active",
  },
  {
    code: "fake_client_xf",
    country_code: "XX",
    id: "FakeClient",
    logo_url: FinmapLogo,
    max_consent_days: minOperationDate().getTime(),
    name: "FakeClient",
    status: "active",
}
];
