import { Button } from '@material-ui/core';
import { ReactEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DialogComponent from '../Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';
import Video from './VideoComponent';

function VideoDialog(props: Props) {
  const { onCLose, isOpened, videoLink, title } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const onClick: ReactEventHandler<HTMLDivElement> = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);

  if (!isOpened) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <DialogComponent
        className={classes.infoDialog}
        onClose={onCLose}
        isOpened
        title={title}
      >
        <div className={classes.videoWrapper}>
          <Video url={videoLink} height={422} autostart={false} />
        </div>
        <Button className={classes.infoBtn} onClick={onCLose}>
          {t('tutorial.allClear')}
        </Button>
      </DialogComponent>
    </div>
  );
}

export default VideoDialog;
