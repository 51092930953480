import { Typography } from '@material-ui/core';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AttentionIcon from '../../../assets/images/svg/attention-gray.svg';
import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import { Routes } from '../../../constants/routes';
import integrationActions from '../../../store/integrations/actions';
import {
  getInitialMonoCredentials,
  getMonoIsGranted,
  selectMonoAccountsAutocomplete,
} from '../../../store/integrations/selectors';
import { MonoAccount } from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { useStyles } from './styles';
import { Props } from './types';

let interval: NodeJS.Timeout;

function MonoIntegrationDialog(props: Props) {
  const {
    onClose,
    onChangeDate,
    selectedDate,
    onCreateIntegration,
    onCloseAccountsDialog,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialMonoCredentials = useSelector(getInitialMonoCredentials);
  const monoIsGranted = useSelector(getMonoIsGranted);
  const monoAccounts = useSelector(selectMonoAccountsAutocomplete);

  const [currentCard, setCurrentCard] = useState<MonoAccount | null>(null);
  const [errors, setErrors] = useState({
    date: false,
    card: false,
  });

  const handleClickNewEmployee = useCallback(() => {
    onClose();

    if (onCloseAccountsDialog) {
      onCloseAccountsDialog();
    }

    history.replace(`${Routes.EMPLOYEE}?is_new=true`);
  }, [history, onClose, onCloseAccountsDialog]);

  const handleCreateIntegration = useCallback(() => {
    if (!selectedDate) {
      setErrors((values) => ({ ...values, date: true }));

      return;
    }

    if (!currentCard) {
      setErrors((values) => ({ ...values, card: true }));

      return;
    }

    onCreateIntegration(currentCard.currencyId);
  }, [selectedDate, currentCard, onCreateIntegration]);

  const handleClose = useCallback(() => {
    if (interval) {
      clearInterval(interval);
    }

    onClose();
  }, [onClose]);

  const handleSelectCard = useCallback(
    (value: any) => {
      setCurrentCard(value);

      dispatch(integrationActions.setMonoCardId(value.id));
    },
    [dispatch],
  );

  const handleChangeDate = useCallback(
    (value: any) => {
      onChangeDate(value);
    },
    [onChangeDate],
  );

  useEffect(() => {
    if (monoIsGranted) {
      if (interval) {
        clearInterval(interval);
      }

      dispatch(integrationActions.getMonoAccounts());
    }
  }, [monoIsGranted, dispatch]);

  useEffect(() => {
    dispatch(integrationActions.getInitialMonoCredentials());
  }, [dispatch]);

  useEffect(() => {
    if (initialMonoCredentials?.url && !monoIsGranted) {
      interval = setInterval(() => {
        dispatch(integrationActions.getMonoIsGranted());
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [initialMonoCredentials, dispatch, monoIsGranted]);

  useEffect(() => {
    if (selectedDate) {
      setErrors((values) => ({ ...values, date: false }));
    }

    if (currentCard) {
      setErrors((values) => ({ ...values, card: false }));
    }
  }, [selectedDate, currentCard]);

  return (
    <div className={classes.root}>
      {Boolean(initialMonoCredentials?.url) &&
        !monoAccounts.length &&
        !monoIsGranted && (
          <Dialog
            onClose={handleClose}
            title={isMobile ? t('bank.openMonoBank') : t('bank.scanQRCode')}
            isOpened
          >
            <>
              {isMobile && Boolean(initialMonoCredentials) && (
                <a
                  href={initialMonoCredentials?.url}
                  title={t('bank.goToMonoBank')}
                  className={classes.button}
                >
                  {t('bank.goToMonoBank')}
                </a>
              )}
              {!isMobile && (
                <div className={classes.container}>
                  <div className={classes.qrcode}>
                    <QRCode value={initialMonoCredentials?.url ?? ''} />
                  </div>
                  <div className={classes.helpContainer}>
                    <img
                      src={AttentionIcon}
                      alt="attention"
                      className={classes.icon}
                    />
                    <Typography className={classes.helpText}>
                      {t('bank.firstHelpMessageMonobank')}
                    </Typography>
                  </div>
                </div>
              )}
            </>
          </Dialog>
        )}
      {Boolean(monoAccounts.length) && (
        <Dialog onClose={handleClose} isOpened title={t('integrations.title')}>
          <div className={classes.flexColumn}>
            <CalendarComponent
              onChange={handleChangeDate}
              placeholder={t('integrations.dateDescription')}
              // @ts-ignore
              value={selectedDate}
              fullWidth
              maxDate={new Date()}
              minDate={minOperationDate()}
              error={errors.date}
              fromIntegration
            />
            <AutocompleteSelector
              rootClassName={classes.selector}
              label={t('integrations.yourCards')}
              onChange={handleSelectCard}
              data={monoAccounts}
              // @ts-ignore
              value={currentCard}
              error={errors.card}
            />
            <div className={classes.helpContainer}>
              <img
                src={AttentionIcon}
                alt="attention"
                className={classes.icon}
              />
              <div>
                <Typography className={classes.helpText}>
                  {t('bank.secondHelpMessageMonobank')}
                </Typography>
                <Typography
                  className={classes.link}
                  onClick={handleClickNewEmployee}
                >
                  {t('bank.connectNewEmployee')}
                </Typography>
              </div>
            </div>
            <CustomButton
              title={t('integrations.setCard')}
              action={handleCreateIntegration}
              loading={false}
              fullWidth
            />
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default React.memo(MonoIntegrationDialog);
