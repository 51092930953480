import { CustomPeriodEnum } from '../../foundation/Operations/HOC/types';
import { categoryActionTypes } from '../categories/actions';
import { types } from '../clients/actions';
import { UrlPrefix } from '../clients/types';
import { RESET_RATES } from '../currency/actions';
import { GetExchangeRateFromToPayload } from '../currency/types';
import {
  CreateOperationPayload,
  GetOperationsItemsPayload,
  OperationToTransferPayload,
  RemoveOperationsPayload,
  UpdateGroupCategoryPayload,
  UpdateGroupClientPayload,
  UpdateGroupCommentPayload,
  UpdateGroupProjectPayload,
  UpdateGroupTagPayload,
  UpdateOneOperationPayload,
  UpdateOperationPayload,
} from '../operations/sagas.types';
import {
  OperationType,
  PeriodDateOfPaymentPayload,
  SetRevertExchangeRatePayload,
} from '../operations/types';
import { CREATE_OPERATION_PROJECTS_PENDING } from '../projects/actions';
import { CREATE_OPERATION_TAGS_PENDING } from '../tags/actions';
import {
  SetAccountAction,
  SetAccountPayload,
  SetAmountAction,
  SetAmountPayload,
  SetCommentsAction,
  SetCommentsPayload,
  SetCreateAndCopyStateAction,
  SetCreateAndCopyStatePayload,
  SetCurrencyAmountAction,
  SetCurrencyAmountPayload,
  SetCurrencyValueAction,
  SetCurrencyValuePayload,
  SetDateOfPaymentWasChangedAction,
  SetDateOfPaymentWasChangedPayload,
  SetEditOperationIdAction,
  SetEditOperationIdPayload,
  SetExchangeRateAction,
  SetExchangeRatePayload,
  SetIncomeDateAction,
  SetIncomeDatePayload,
  SetIsCopyAction,
  SetIsCopyPayload,
  SetOperationPropsPayload,
  SetRepeatsAction,
  SetRepeatsPayload,
  SetRevertedExchangeRateAction,
  SetRevertStateAction,
  SetSalaryPeriodIdAction,
  SetSalaryPeriodIdPayload,
  SetSelectedTagsAction,
  SetSelectedTagsPayload,
  SetSplitPaymentProjectsAction,
  SetSplitPaymentProjectsPayload,
  SetTaxPeriodIdAction,
  SetTaxPeriodIdPayload,
  SetToAccountAction,
  SetToAccountPayload,
  UpdateOneOperationAction,
} from './types';

export const REMOVE_OPERATION_PENDING = 'REMOVE_OPERATION_PENDING';
export const REMOVE_OPERATION_SUCCESS = 'REMOVE_OPERATION_SUCCESS';

export const UPDATE_ONE_OPERATION_PENDING = 'UPDATE_ONE_OPERATION_PENDING';
export const UPDATE_ONE_OPERATION_SUCCESS = 'UPDATE_ONE_OPERATION_SUCCESS';

export const UPDATE_OPERATION_PENDING = 'UPDATE_OPERATION_PENDING';

export const CREATE_OPERATION_PENDING = 'CREATE_OPERATION_PENDING';
export const CREATE_OPERATION_SUCCESS = 'CREATE_OPERATION_SUCCESS';

export const CREATE_TRANSFER_PENDING = 'CREATE_TRANSFER_PENDING';
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS';
export const CREATE_TRANSFER_FAILED = 'CREATE_TRANSFER_FAILED';

export const UPDATE_GROUP_CATEGORY = 'UPDATE_GROUP_CATEGORY';
export const UPDATE_GROUP_PROJECT = 'UPDATE_GROUP_PROJECT';
export const UPDATE_GROUP_COMMENT = 'UPDATE_GROUP_COMMENT';
export const UPDATE_GROUP_TAG = 'UPDATE_GROUP_TAG';
export const UPDATE_GROUP_CLIENT = 'UPDATE_GROUP_CLIENT';

export enum ActionTypes {
  'SET_HISTORY_IS_COPY_COMPONENT' = 'SET_HISTORY_IS_COPY_COMPONENT',
  'SET_HISTORY_CREATE_AND_COPY_STATE' = 'SET_HISTORY_CREATE_AND_COPY_STATE',
  GET_HISTORY_CONTEXT_OPERATIONS_ITEMS = 'GET_HISTORY_CONTEXT_OPERATIONS_ITEMS',
  GET_HISTORY_SETTINGS_CATEGORIES = 'GET_HISTORY_SETTINGS_CATEGORIES',
  SET_HISTORY_AMOUNT = 'SET_HISTORY_AMOUNT',
  SET_HISTORY_ACCOUNT = 'SET_HISTORY_ACCOUNT',
  SET_HISTORY_EXCHANGE_RATE = 'SET_HISTORY_EXCHANGE_RATE',
  SET_HISTORY_TO_ACCOUNT = 'SET_HISTORY_TO_ACCOUNT',
  SET_HISTORY_CURRENCY_AMOUNT = 'SET_HISTORY_CURRENCY_AMOUNT',
  SET_HISTORY_EDIT_OPERATION_ID = 'SET_HISTORY_EDIT_OPERATION_ID',
  SET_HISTORY_INCOME_DATE = 'SET_HISTORY_INCOME_DATE',
  SET_HISTORY_DATE_OF_PAYMENT = 'SET_HISTORY_DATE_OF_PAYMENT',
  SET_HISTORY_REPEATS = 'SET_HISTORY_REPEATS',
  SET_HISTORY_SPLIT_PAYMENT_PROJECTS = 'SET_HISTORY_SPLIT_PAYMENT_PROJECTS',
  SET_HISTORY_SELECTED_TAGS = 'SET_HISTORY_SELECTED_TAGS',
  SET_HISTORY_SALARY_PERIOD_ID = 'SET_HISTORY_SALARY_PERIOD_ID',
  SET_HISTORY_TAX_PERIOD_ID = 'SET_HISTORY_TAX_PERIOD_ID',
  SET_HISTORY_COMMENTS = 'SET_HISTORY_COMMENTS',
  SET_HISTORY_START_TIME_STAMP = 'SET_HISTORY_START_TIME_STAMP',
  SET_HISTORY_END_TIME_STAMP = 'SET_HISTORY_END_TIME_STAMP',
  SET_HISTORY_DATE_OF_PAYMENT_WAS_CHANGED = 'SET_HISTORY_DATE_OF_PAYMENT_WAS_CHANGED',
  SET_HISTORY_CURRENCY_VALUE = 'SET_HISTORY_CURRENCY_VALUE',
  RESET_HISTORY_PROPS = 'RESET_HISTORY_PROPS',
  SET_HISTORY_OPERATION_PROPS = 'SET_HISTORY_OPERATION_PROPS',
  SET_HISTORY_REVERT_STATE = 'SET_HISTORY_REVERT_STATE',
  SET_REVERTED_EXCHANGE_RATE = 'SET_REVERTED_EXCHANGE_RATE',
  SET_HISTORY_OPERATION_TYPE = 'SET_HISTORY_OPERATION_TYPE',
  SET_HISTORY_PERIOD_DATE_OF_PAYMENT = 'SET_HISTORY_PERIOD_DATE_OF_PAYMENT',
  GET_HISTORY_EXCHANGE_RATE_FROM_TO_PENDING = 'GET_HISTORY_EXCHANGE_RATE_FROM_TO_PENDING',
  GET_HISTORY_EXCHANGE_RATE_FROM_TO_SUCCESS = 'GET_HISTORY_EXCHANGE_RATE_FROM_TO_SUCCESS',
  GET_HISTORY_EXCHANGE_RATE_TO_FROM_PENDING = 'GET_HISTORY_EXCHANGE_RATE_TO_FROM_PENDING',
  GET_HISTORY_EXCHANGE_RATE_TO_FROM_SUCCESS = 'GET_HISTORY_EXCHANGE_RATE_TO_FROM_SUCCESS',
  SET_HISTORY_DATE_OF_PAYMENT_PERIOD_ID = 'SET_HISTORY_DATE_OF_PAYMENT_PERIOD_ID',
}

const setDateOfPaymentPeriodId = (dateOfPaymentPeriodId: CustomPeriodEnum) => ({
  type: ActionTypes.SET_HISTORY_DATE_OF_PAYMENT_PERIOD_ID,
  payload: { dateOfPaymentPeriodId },
});

const setPeriodDateOfPayment = (data: PeriodDateOfPaymentPayload) => ({
  type: ActionTypes.SET_HISTORY_PERIOD_DATE_OF_PAYMENT,
  payload: data,
});

const setOperationType = (value: OperationType) => ({
  type: ActionTypes.SET_HISTORY_OPERATION_TYPE,
  payload: { value },
});

const resetOperationProps = () => ({
  type: ActionTypes.RESET_HISTORY_PROPS,
});

const setRevertState = (revertState: boolean): SetRevertStateAction => ({
  type: ActionTypes.SET_HISTORY_REVERT_STATE,
  payload: { revertState },
});

const setRevertedExchangeRate = (
  payload: SetRevertExchangeRatePayload,
): SetRevertedExchangeRateAction => ({
  type: ActionTypes.SET_REVERTED_EXCHANGE_RATE,
  payload,
});

const setAmount = (payload: SetAmountPayload): SetAmountAction => ({
  type: ActionTypes.SET_HISTORY_AMOUNT,
  payload,
});

const setIsCopyComponent = (payload: SetIsCopyPayload): SetIsCopyAction => ({
  type: ActionTypes.SET_HISTORY_IS_COPY_COMPONENT,
  payload,
});

const setSelectedTags = (
  payload: SetSelectedTagsPayload,
): SetSelectedTagsAction => ({
  type: ActionTypes.SET_HISTORY_SELECTED_TAGS,
  payload,
});

const setCurrencyValue = (
  payload: SetCurrencyValuePayload,
): SetCurrencyValueAction => ({
  type: ActionTypes.SET_HISTORY_CURRENCY_VALUE,
  payload,
});

const setAccount = (payload: SetAccountPayload): SetAccountAction => ({
  type: ActionTypes.SET_HISTORY_ACCOUNT,
  payload,
});

const setExchangeRate = (
  payload: SetExchangeRatePayload,
): SetExchangeRateAction => ({
  type: ActionTypes.SET_HISTORY_EXCHANGE_RATE,
  payload,
});

const setSplitPaymentProjects = (
  payload: SetSplitPaymentProjectsPayload,
): SetSplitPaymentProjectsAction => ({
  type: ActionTypes.SET_HISTORY_SPLIT_PAYMENT_PROJECTS,
  payload,
});

const setDateOfPayment = (dateOfPayment: number) => ({
  type: ActionTypes.SET_HISTORY_DATE_OF_PAYMENT,
  payload: { dateOfPayment },
});

const setComments = (payload: SetCommentsPayload): SetCommentsAction => ({
  type: ActionTypes.SET_HISTORY_COMMENTS,
  payload,
});

const setRepeats = (payload: SetRepeatsPayload): SetRepeatsAction => ({
  type: ActionTypes.SET_HISTORY_REPEATS,
  payload,
});

const setIncomeDate = (payload: SetIncomeDatePayload): SetIncomeDateAction => ({
  type: ActionTypes.SET_HISTORY_INCOME_DATE,
  payload,
});

const setSellDateWasChanged = (
  payload: SetDateOfPaymentWasChangedPayload,
): SetDateOfPaymentWasChangedAction => ({
  type: ActionTypes.SET_HISTORY_DATE_OF_PAYMENT_WAS_CHANGED,
  payload,
});

const setEditOperationId = (
  payload: SetEditOperationIdPayload,
): SetEditOperationIdAction => ({
  type: ActionTypes.SET_HISTORY_EDIT_OPERATION_ID,
  payload,
});

const setToAccount = (payload: SetToAccountPayload): SetToAccountAction => ({
  type: ActionTypes.SET_HISTORY_TO_ACCOUNT,
  payload,
});

const setCurrencyAmount = (
  payload: SetCurrencyAmountPayload,
): SetCurrencyAmountAction => ({
  type: ActionTypes.SET_HISTORY_CURRENCY_AMOUNT,
  payload,
});

const setSalaryPeriodId = (
  payload: SetSalaryPeriodIdPayload,
): SetSalaryPeriodIdAction => ({
  type: ActionTypes.SET_HISTORY_SALARY_PERIOD_ID,
  payload,
});

const setTaxPeriodId = (
  payload: SetTaxPeriodIdPayload,
): SetTaxPeriodIdAction => ({
  type: ActionTypes.SET_HISTORY_TAX_PERIOD_ID,
  payload,
});

const setCreateAndCopyState = (
  payload: SetCreateAndCopyStatePayload,
): SetCreateAndCopyStateAction => ({
  type: ActionTypes.SET_HISTORY_CREATE_AND_COPY_STATE,
  payload,
});

const getOperationItems = (payload: GetOperationsItemsPayload) => ({
  type: ActionTypes.GET_HISTORY_CONTEXT_OPERATIONS_ITEMS,
  payload,
});

const getSettingsCategories = () => ({
  type: ActionTypes.GET_HISTORY_SETTINGS_CATEGORIES,
});

const setOperationProps = (operationProps: SetOperationPropsPayload) => ({
  type: ActionTypes.SET_HISTORY_OPERATION_PROPS,
  payload: operationProps,
});

const updateOneOperation = (
  data: UpdateOneOperationPayload,
): UpdateOneOperationAction => ({
  type: UPDATE_ONE_OPERATION_PENDING,
  payload: data,
});

const createOperation = (data: CreateOperationPayload) => ({
  type: CREATE_OPERATION_PENDING,
  payload: data,
});

const getExchangeRateFromTo = (data: GetExchangeRateFromToPayload) => ({
  type: ActionTypes.GET_HISTORY_EXCHANGE_RATE_FROM_TO_PENDING,
  payload: data,
});

const getExchangeRateToFrom = (data: GetExchangeRateFromToPayload) => ({
  type: ActionTypes.GET_HISTORY_EXCHANGE_RATE_TO_FROM_PENDING,
  payload: data,
});

const resetRates = () => ({
  type: RESET_RATES,
});

const updateOperation = (data: UpdateOperationPayload) => ({
  type: UPDATE_OPERATION_PENDING,
  payload: data,
});

const createTransferOperation = (data: CreateOperationPayload) => ({
  type: CREATE_TRANSFER_PENDING,
  payload: data,
});

const createClient = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.clients,
  },
});

const createInvestor = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.investors,
  },
});

const createCreditor = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.creditors,
  },
});

const createBorrower = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.borrowers,
  },
});

const createTaxOrganisation = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.taxOrganisations,
  },
});

const createOwner = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.owners,
  },
});

const createSupplier = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.suppliers,
  },
});

const createStaffMember = (name: string) => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.staffMembers,
  },
});

const createCategory = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_INCOME_PENDING,
  payload: { name },
});

const createConsumptionCategories = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_CONSUMPTION_PENDING,
  payload: { name },
});

const createProject = (name: string) => ({
  type: CREATE_OPERATION_PROJECTS_PENDING,
  payload: { name },
});

const createTag = (label: string) => ({
  type: CREATE_OPERATION_TAGS_PENDING,
  payload: { label },
});

const removeOperation = (data: RemoveOperationsPayload) => ({
  type: REMOVE_OPERATION_PENDING,
  payload: data,
});

const updateGroupCategory = (data: UpdateGroupCategoryPayload) => ({
  type: UPDATE_GROUP_CATEGORY,
  payload: data,
});

const updateGroupProject = (data: UpdateGroupProjectPayload) => ({
  type: UPDATE_GROUP_PROJECT,
  payload: data,
});

const updateGroupComment = (data: UpdateGroupCommentPayload) => ({
  type: UPDATE_GROUP_COMMENT,
  payload: data,
});

const updateGroupTag = (data: UpdateGroupTagPayload) => ({
  type: UPDATE_GROUP_TAG,
  payload: data,
});

const updateGroupClient = (data: UpdateGroupClientPayload) => ({
  type: UPDATE_GROUP_CLIENT,
  payload: data,
});

const setNextOperationProps = (operationProps: SetOperationPropsPayload) => ({
  type: ActionTypes.SET_HISTORY_OPERATION_PROPS,
  payload: operationProps,
});

const getClientByPrefix = (prefix: UrlPrefix) => ({
  type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
  payload: { prefix },
});

const resetClients = () => ({
  type: types.RESET_CLIENTS,
});

const operationToTransfer = (data: OperationToTransferPayload) => ({
  type: types.OPERATION_TO_TRANSFER,
  payload: data,
});

export default {
  setRevertedExchangeRate,
  resetClients,
  getClientByPrefix,
  createSupplier,
  createConsumptionCategories,
  createTag,
  createOwner,
  createProject,
  createInvestor,
  createStaffMember,
  createBorrower,
  createCreditor,
  createTaxOrganisation,
  updateOneOperation,
  createOperation,
  getSettingsCategories,
  setExchangeRate,
  setRevertState,
  setToAccount,
  setAmount,
  setIsCopyComponent,
  setSelectedTags,
  setCurrencyValue,
  setAccount,
  setSplitPaymentProjects,
  setDateOfPayment,
  setComments,
  setRepeats,
  setIncomeDate,
  setSalaryPeriodId,
  setSellDateWasChanged,
  setEditOperationId,
  resetOperationProps,
  getOperationItems,
  setOperationProps,
  setTaxPeriodId,
  setCreateAndCopyState,
  setCurrencyAmount,
  getExchangeRateFromTo,
  resetRates,
  updateOperation,
  createTransferOperation,
  createCategory,
  createClient,
  removeOperation,
  updateGroupCategory,
  updateGroupProject,
  updateGroupComment,
  updateGroupTag,
  updateGroupClient,
  setNextOperationProps,
  getExchangeRateToFrom,
  operationToTransfer,
  setOperationType,
  setPeriodDateOfPayment,
  setDateOfPaymentPeriodId,
};
