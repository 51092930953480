import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {},
  cardText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.5,
  },
  marginTop24: {
    marginTop: 24,
  },
  cardButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  cardButton: {
    maxWidth: 180,
    width: '100%',
    textAlign: 'center',
    padding: '16px 24px',
    borderRadius: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '20px',
  },
  cardUnLinkButton: {
    background: 'rgba(255, 99, 51, 0.1)',
    color: '#FF6333',
  },
  cancelButton: {
    background: 'rgba(0, 0, 0, 0.06)',
    color: '#000000',
  },
  addButton: {
    color: '#000000',
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
  okButton: {
    color: '#000000',
    background: 'rgba(0, 0, 0, 0.06)',
  },
  successUnlinkedTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: 16,
    color: '#000000',
  },
});
