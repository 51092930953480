import React from 'react';

import CheckIconSvg from '../../assets/images/svg/checkmark.svg';

export interface CheckIconProps {
  className?: string;
  onClick?(): void;
  isActive?: boolean;
}

export function CheckIcon({
  className,
  isActive = true,
  onClick,
}: CheckIconProps) {
  if(!isActive) return null;

  return <img
  alt="check"
  src={CheckIconSvg}
  className={className}
  onClick={onClick}
/>
}

export default CheckIcon;