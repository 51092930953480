import { useTranslation } from 'react-i18next';

import { Client } from '../../../../../store/clients/types';
import { Project } from '../../../../../store/projects/types';
import { Tag } from '../../../../../store/tags/types';
import EditDialog from '../EditDialog';
import connector from './connector';
import { ComponentProps, GeneralComponentProps, Props } from './types';
import useOwnComponentProps from './useOwnComponentProps';

function EditDialogHOC(props: Props) {
  const {
    type,
    tags,
    prefix,
    isError,
    onClose,
    projects,
    isLoading,
    createTag,
    deleteTag,
    isEditable,
    deleteClient,
    createClient,
    deleteProject,
    createProject,
    setCreateError,
    updateGroupTags,
    resetCreateError,
    updateGroupClients,
    updateGroupProjects,
  } = props;
  const { t } = useTranslation();

  const { ownComponentProps } = useOwnComponentProps(prefix, isEditable);

  if (type === 'tags') {
    const componentProps: ComponentProps<Tag> = {
      type: 'tag',
      isError,
      onClose,
      data: tags,
      isEditable,
      createButtonText: t('tags.add'),
      createTitle: t('tags.title'),
      createItem: createTag,
      deleteItem: deleteTag,
      updateGroupItems: updateGroupTags,
      setCreateError,
      resetCreateError,
      isLoading,
    };

    return <EditDialog {...componentProps} />;
  }

  if (type === 'projects') {
    const componentProps: ComponentProps<Project> = {
      type: 'project',
      isError,
      onClose,
      isEditable,
      data: projects,
      createButtonText: t('projects.add'),
      createTitle: t('projects.title'),
      createItem: createProject,
      deleteItem: deleteProject,
      updateGroupItems: updateGroupProjects,
      setCreateError,
      resetCreateError,
      isLoading,
    };

    return <EditDialog {...componentProps} />;
  }

  const baseComponentProps: GeneralComponentProps<Client> = {
    onClose,
    createItem: createClient,
    deleteItem: deleteClient,
    updateGroupItems: updateGroupClients,
    setCreateError,
    resetCreateError,
    isLoading,
  };

  return (
    <EditDialog
      {...baseComponentProps}
      {...ownComponentProps}
      isError={isError}
    />
  );
}

export default connector(EditDialogHOC);
