import React, { useCallback, useContext, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useDispatch, useSelector } from 'react-redux';

import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import pumbApi from '../../../services/bankIntegrations/pumb';
import { SetPumbAccountPayload } from '../../../services/bankIntegrations/pumb.types';
import integrationActions from '../../../store/integrations/actions';
import pumbActions from '../../../store/integrations/pumbActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { getPumbIntegrationId } from '../../../store/integrations/selectors';
import {
  IntegrationTypeId,
  PumbAccount,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { showError } from '../../../utils/showError';
import AccountsDialog from './AccountsDialog';
import { SetAccountProps } from './AccountsDialog/types';
import LoginDialog from './LoginDialog';

type Props = {
  newAccountName?: string;
  selectedAccountId?: string;

  onClose(): void;
  onCloseAccountsDialog?(): void;
};

function PumbBusinessDialog(props: Props) {
  const { onClose, selectedAccountId, newAccountName, onCloseAccountsDialog } =
    props;

  const facebookContext = useContext(FacebookPixelContext);
  const integrationId = useSelector(getPumbIntegrationId);

  const dispatch = useDispatch();

  const [accounts, setAccounts] = useState<PumbAccount[]>([]);
  const [isFlipped, setIsFlipped] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = useCallback(() => {
    if (onCloseAccountsDialog) {
      onCloseAccountsDialog();
    } else {
      onClose();
    }
  }, [onCloseAccountsDialog, onClose]);

  const onSetAccounts = useCallback(
    (data: any, id: string) => {
      setAccounts(data);

      dispatch(pumbActions.setPumbIntegrationId(id));

      setIsFlipped(true);
    },
    [dispatch],
  );

  const handleCreateCredentials = useCallback(
    async (data: SetAccountProps) => {
      if (integrationId) {
        const { account, toDate, fromDate } = data;
        const setAccountPayload: SetPumbAccountPayload = {
          accountId: account.id,
          // @ts-ignore
          fromDate,
          // @ts-ignore
          toDate,
          integrationId,
        };

        let accountId = selectedAccountId;

        if (!accountId && newAccountName) {
          try {
            setLoading(true);
            setError(false);

            const {
              data: { newAccount },
            } = await accountsApi.createAccount(
              account.currency,
              newAccountName,
              account.balance,
              IntegrationTypeId.Pumb,
            );

            accountId = newAccount._id;
          } catch (e) {
            showError(e);
            setLoading(false);
            setError(true);
          }
        }

        if (accountId) {
          setLoading(true);

          const integrationPayload: CreateIntegrationPayload = {
            facebookContext,
            typeId: IntegrationTypeId.Pumb,
            accountId,
            startDate:
              new Date(setAccountPayload.fromDate).getTime() + getTimeOffset(),
          };

          await pumbApi.setAccount(setAccountPayload);

          dispatch(integrationActions.createIntegration(integrationPayload));

          setLoading(false);
          handleClose();
        }
      }
    },
    [
      dispatch,
      handleClose,
      integrationId,
      newAccountName,
      facebookContext,
      selectedAccountId,
    ],
  );

  return (
    <ReactCardFlip
      isFlipped={!isFlipped}
      flipDirection="vertical"
      flipSpeedBackToFront={2}
      flipSpeedFrontToBack={2}
    >
      <LoginDialog onClose={handleClose} onSetAccounts={onSetAccounts} />
      {Boolean(accounts.length) && (
        <AccountsDialog
          pumbAccounts={accounts}
          onClose={handleClose}
          onSetAccount={handleCreateCredentials}
          loading={loading}
          error={error}
        />
      )}
    </ReactCardFlip>
  );
}

export default React.memo(PumbBusinessDialog);
