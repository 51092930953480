import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AltBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isDESCOpOrder: true,
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['DATE', 2],
                add: [' ', { column: ['TIME', 3] }, ':00'],
              },
              sum: { column: ['SUM', 6] },
              comment: { column: ['COMMENT', 14] },
            },
          },
        },
      },
    ],
  };
}
