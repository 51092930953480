import i18next from 'i18next';
import { createSelector } from 'reselect';

import MastercardIcon from '../../assets/images/svg/mastercard.svg';
import PaypalIcon from '../../assets/images/svg/payments/paypal.jpg';
import { AppState } from '../reducers';
import { CurrencyProvider, CurrencySymbolToId } from './types';

export const getCurrencies = (state: AppState) => state.currency.currencies;

export const getCurrencyProviders = (state: AppState) =>
  state.currency.providers;

export const getDefaultCurrencyProviders = (state: AppState) =>
  state.currency.defaultProvider;

export const getCustomCurrencies = (state: AppState) =>
  state.currency.customCurrencyRates;

export const getCryptoCurrencies = (state: AppState) =>
  state.currency.cryptoCurrencies;

export const getLanguage = (state: AppState) => state.company.lng;

const getExchangeRateFromTo = (state: AppState) =>
  state.currency.exchangeRateFromTo;

export const selectAutocompleteFormatCurrencyProviders = createSelector(
  getCurrencyProviders,
  getLanguage,
  (providers, lng) => {
    const CustomProviderId = '61976bfed972c9f9b69b2bbd';
    const CryptoCurrencyProviderId = '645b735cad42aaa5523e1a93';

    const i18 = i18next.getFixedT(lng);
    const providerIdToLng: { [key in CurrencyProvider['_id']]: string } = {
      '61976bd9679643bdf4c0570e': i18('userMenu.fixedCurrencies.provider.nbu'),
      '61976bee9e09d0d567ff136a': i18(
        'userMenu.fixedCurrencies.provider.paypal',
      ),
      '61b9bb1ba5a2cb5652a874f5': i18(
        'userMenu.fixedCurrencies.provider.poland',
      ),
      '61ba14b9a9f34014898ba39d': i18(
        'userMenu.fixedCurrencies.provider.mastercard',
      ),
      '61c461054c4496bf0951c6cd': i18(
        'userMenu.fixedCurrencies.provider.czech',
      ),
      '61c468e48994558dff663500': i18(
        'userMenu.fixedCurrencies.provider.kazakhstan',
      ),
    };

    const providerIdToFlag: { [key in CurrencyProvider['_id']]: string } = {
      '61976bd9679643bdf4c0570e': '🇺🇦',
      '61b9bb1ba5a2cb5652a874f5': '🇵🇱',
      '61c461054c4496bf0951c6cd': '🇨🇿',
      '61c468e48994558dff663500': '🇰🇿',
    };

    const providerIdToIcon: { [key in CurrencyProvider['_id']]: string } = {
      '61ba14b9a9f34014898ba39d': MastercardIcon,
      '61976bee9e09d0d567ff136a': PaypalIcon,
    };

    return providers
      .filter(
        (el) =>
          el._id !== CustomProviderId && el._id !== CryptoCurrencyProviderId,
      )
      .map((provider) => ({
        id: provider._id,
        label: `${providerIdToFlag[provider._id] ?? ''}  ${
          providerIdToLng[provider._id]
        }`,
        name: `${providerIdToFlag[provider._id] ?? ''}  ${
          providerIdToLng[provider._id]
        }`,
        icon: providerIdToIcon[provider._id] ?? '',
      }));
  },
);

export const selectCryptoCurrencyCodes = createSelector(
  getCryptoCurrencies,
  (currencies) => currencies.map((el) => el.code),
);

export const selectUSDCurrency = createSelector(
  getCurrencies,
  (currencies) => currencies.find((cur) => cur._id === 'USD')!,
);

export const selectUSDCurrencyId = createSelector(
  getCurrencies,
  (currencies) => currencies.find((cur) => cur._id === 'USD')!._id,
);

export const selectUAHCurrencyId = createSelector(
  getCurrencies,
  (currencies) => currencies.find((cur) => cur._id === 'UAH')!._id,
);

export const selectExchangeRateFromTo = createSelector(
  getExchangeRateFromTo,
  (rate) => rate?.rate || 0,
);

export const selectCurrenciesAutoComplete = createSelector(
  getCurrencies,
  getLanguage,
  (currencies, lng) => {
    const i18 = i18next.getFixedT(lng);

    return (
      currencies?.map((currency) => {
        const { symbol, code } = currency;
        const name = i18(`currency.${currency._id}`);

        return {
          id: currency._id,
          name: `${name} - ${code}, ${symbol}`,
          label: `${name} - ${code}, ${symbol}`,
          symbol,
        };
      }) || []
    );
  },
);

export const selectCryptoCurrenciesAutoComplete = createSelector(
  getCryptoCurrencies,
  getLanguage,
  (currencies, lng) => {
    const i18 = i18next.getFixedT(lng);

    return (
      currencies?.map((currency) => ({
        id: currency._id,
        name: currency.symbol,
        label: currency.symbol,
        isCrypto: i18('currency.crypto').toUpperCase(),
      })) || []
    );
  },
);

export const selectCurrenciesForOperationAutoComplete = createSelector(
  getCurrencies,
  (currencies) =>
    currencies?.map((currency) => ({
      id: currency._id,
      name: `${currency._id} (${currency.symbol})`,
      label: `${currency._id} (${currency.symbol})`,
      symbol: currency.symbol,
    })) || [],
);

export const selectCryptoCurrenciesForOperationAutoComplete = createSelector(
  getCryptoCurrencies,
  (currencies) =>
    currencies?.map((currency) => ({
      id: currency._id,
      name: currency.symbol,
      label: currency.symbol,
      symbol: currency.symbol,
      isCrypto: true,
    })) || [],
);

export const selectMapCurrencyIdToSymbol = createSelector(
  getCurrencies,
  getCryptoCurrencies,
  (currencies, cryptoCurrencies) =>
    [...currencies, ...cryptoCurrencies].reduce(
      (acc: CurrencySymbolToId, el) => ({
        ...acc,
        [el._id]: el.symbol,
      }),
      {},
    ),
);
