import moment from 'moment';

export enum OPERATION_TYPES {
  INC = 'income',
  CON = 'consumption',
  TRN = 'transfer',
}

export const MIN_OPERATION_DATE: Date = moment('2015-01-01').toDate();
export const MAX_OPERATION_DATE: Date = moment().add(10, 'years').toDate();

export const EMPTY_ACCOUNT_ID = '616b437ecaef522217409e07';
export const EMPTY_INC_CATEGORY_ID = '6161e71afc3a27eb00428f93';
export const EMPTY_CON_CATEGORY_ID = '6161e71afc3a27eb00428f94';
export const EMPTY_ID = '000000000000000000000000';
