import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import FacebookPixelContext from '../../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import operationActions from '../../../../store/operations/actions';
import { getOperationProps } from '../../../../store/operations/selectors';
import {
  CreateIncomeInvestmentResponseData,
  OperationSubType,
  OperationType,
} from '../../../../store/operations/types';
import { Props } from './types';
import useMainPayload from './useMainPayload';
import useUnleash from '../../../../hooks/useUnleash';
import { LONG_UPDATE_OPERATION } from '../../../../constants/featureToggles/featureToggle';

function useCreateInvestment(props: Props) {
  const {
    client,
    isEdit,
    isCopy,
    editProps,
    scheduled,
    shouldCreate,
    onCloseDialog,
    resetShouldCreate,
    shouldCloseDialog,
    fixOffsetBug,
  } = props;
  const facebookContext = useContext(FacebookPixelContext);

  const { account, isCopyComponent, editOperationId } =
    useSelector(getOperationProps);

  const dispatch = useDispatch();
  const location = useLocation();
  const isLongLoad = useUnleash(LONG_UPDATE_OPERATION);

  const mainPayloadProps = useMainPayload({ editProps, isEdit });

  useEffect(() => {
    if (shouldCreate) {
      // @ts-ignore
      const payload: Omit<CreateIncomeInvestmentResponseData, 'companyId'> = {
        ...mainPayloadProps,
        scheduled,
        accountToId: account ? account._id : undefined,
        investorId: client ? client._id : null,
      };

      if (isEdit && editProps && editOperationId && !isCopyComponent) {
        dispatch(
          operationActions.updateOperation({
            id: editOperationId,
            // @ts-ignore
            payload: {
              type: OperationType.income,
              subType: OperationSubType.investment,
              ...payload,
            },
            location,
            fixOffsetBug,
            isLongLoad,
          }),
        );
      } else {
        const createPayload = {
          props: {
            ...payload,
            type: OperationType.income,
            subType: OperationSubType.investment,
            isCopy,
          },
          location,
          facebookContext,
          fixOffsetBug,
        };
        dispatch(operationActions.createOperation(createPayload));
      }

      if (shouldCloseDialog) {
        onCloseDialog();
      } else {
        resetShouldCreate();
      }
    }
  }, [
    props,
    isEdit,
    isCopy,
    client,
    account,
    dispatch,
    location,
    scheduled,
    editProps,
    shouldCreate,
    onCloseDialog,
    editOperationId,
    facebookContext,
    isCopyComponent,
    mainPayloadProps,
    resetShouldCreate,
    shouldCloseDialog,
    fixOffsetBug,
    isLongLoad,
  ]);

  return null;
}

export default useCreateInvestment;
