import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RevertIcon from '../../../../../../assets/images/svg/revert-course.svg';
import CustomButton from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import commonApi from '../../../../../../store/common/api';
import { selectCompanyCurrency } from '../../../../../../store/company/selectors';
import currencyActions from '../../../../../../store/currency/actions';
import { selectCryptoCurrenciesForOperationAutoComplete } from '../../../../../../store/currency/selectors';
import { AutoCompleteProps } from '../../../../../../store/types';
import { formatStringPrice } from '../../../../../../utils/parseStringToNumber';
import CurrenciesSelector from '../CurrenciesSelector';
import { useStyles } from './styles';

function CreateDialog({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencies = useSelector(
    selectCryptoCurrenciesForOperationAutoComplete,
  );

  const classes = useStyles();

  const [firstCurrency, setFirstCurrency] = useState<
    AutoCompleteProps & { symbol: string }
  >({
    id: companyCurrency?._id || '',
    label: companyCurrency?.label || '',
    name: companyCurrency?.name || '',
    symbol: companyCurrency?.symbol || '',
  });

  const [secondCurrency, setSecondCurrency] = useState<
    AutoCompleteProps & { symbol: string }
  >({
    id: companyCurrency?._id || '',
    label: companyCurrency?.label || '',
    name: companyCurrency?.name || '',
    symbol: companyCurrency?.symbol || '',
  });

  const isCrypto = useMemo(
    () =>
      cryptoCurrencies.find(
        (el) =>
          el.symbol === firstCurrency.symbol ||
          el.symbol === secondCurrency.symbol,
      ),
    [cryptoCurrencies, firstCurrency, secondCurrency],
  );

  const exchangeRageDigits = isCrypto ? 9 : 6;

  const [loadingRates, setLoadingRates] = useState(false);
  const [exchangeRate, setExchangeRate] = useState('');

  const handleChangeExchangeRate = useCallback(
    (value: any) => {
      const rate = formatStringPrice(value, false, exchangeRageDigits);

      setExchangeRate(rate);
    },
    [exchangeRageDigits],
  );

  const updateRates = useCallback(async () => {
    setLoadingRates(true);

    const { data } = await commonApi.getExchangeRateFromTo(
      firstCurrency.id,
      secondCurrency.id,
    );

    setLoadingRates(false);
    handleChangeExchangeRate(data.rate);
  }, [firstCurrency, secondCurrency, handleChangeExchangeRate]);

  const handleChangeFirstCurrency = useCallback((value: any) => {
    setFirstCurrency(value);
  }, []);

  const handleChangeSecondCurrency = useCallback((value: any) => {
    setSecondCurrency(value);
  }, []);

  const handleClickRevert = useCallback(() => {
    setFirstCurrency(secondCurrency);
    setSecondCurrency(firstCurrency);
  }, [firstCurrency, secondCurrency]);

  const handleCreate = useCallback(async () => {
    dispatch(
      currencyActions.createCustomCurrencyRates({
        rate: Number(exchangeRate),
        from: firstCurrency.id,
        to: secondCurrency.id,
      }),
    );

    onClose();
  }, [dispatch, firstCurrency, secondCurrency, exchangeRate, onClose]);

  const exchangeRateTitle = `${firstCurrency.symbol} / ${secondCurrency.symbol}`;

  useEffect(() => {
    if (firstCurrency || secondCurrency) {
      updateRates();
    }
  }, [firstCurrency, secondCurrency, updateRates]);

  return (
    <Dialog
      onClose={onClose}
      isOpened
      title={t('userMenu.fixedCurrencies.yourCourse')}
    >
      <div className={classes.row}>
        <CurrenciesSelector
          // @ts-ignore
          currency={firstCurrency}
          onChange={handleChangeFirstCurrency}
        />
        <img
          src={RevertIcon}
          alt="revert"
          className={classes.revertIcon}
          onClick={handleClickRevert}
        />
        <CurrenciesSelector
          // @ts-ignore
          currency={secondCurrency}
          onChange={handleChangeSecondCurrency}
        />
      </div>
      <TextFieldComponent
        value={exchangeRate}
        placeholder={`${t('common.rate')} ${exchangeRateTitle}`}
        onChange={handleChangeExchangeRate}
        loading={loadingRates}
      />
      <CustomButton title={t('common.add')} fullWidth action={handleCreate} />
    </Dialog>
  );
}

export default React.memo(CreateDialog);
