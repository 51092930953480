import { StyledProps } from '../../../scenes/InfoBlock/Journal/JournalContainer/types';
import {
  aquaHaze,
  aquamarine,
  black,
  catskillWhite,
  coral,
  persianGreen,
  white,
} from '../../../theme/colors';
import { MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 40,
    height: 40,
    background: 'rgba(255, 255, 255, 0.2)',
    [theme.breakpoints.down(1280)]: {
      background: 'none',
      width: 'unset',
      height: 'unset',
    },
    cursor: 'pointer',
  },
  activeSettings: {
    background: 'rgba(255, 255, 255, 0.3)',
  },
  disabledIcon: {
    display: 'none',
  },
  dropdownContainer: {
    boxShadow: 'none',
  },
  switchLabel: {
    marginLeft: 10,
  },
  tooltipIcon: {
    width: 15,
    height: 15,
    marginLeft: 10,
  },
  tooltipBlock: {
    width: 350,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '20px !important',
    opacity: '1 !important',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '25px',
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    height: 48,
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    cursor: 'pointer',
    marginLeft: 0,
    '&:hover': {
      paddingTop: 14,
      paddingBottom: 14,
      borderRadius: 8,
      background: aquaHaze,
    },
  },
  settingIcon: {
    opacity: 0.8,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: catskillWhite,
    padding: '16px 20px',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    marginBottom: 24,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  email: {
    fontSize: 13,
    lineHeight: '20px',
    opacity: 0.5,
  },
  logout: {
    fontSize: 13,
    lineHeight: '20px',
    color: coral,
    cursor: 'pointer',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight: 370,
    maxHeight: 460,
    paddingLeft: 8,
    // paddingRight: 8,
    overflowY: 'auto',
    overflowX: 'hidden',
    // marginRight: 4,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginRight: 4,
    },
  },
  contentContainer: {
    paddingTop: 0,
    height: '100%',
  },
  rightBlock: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  minLeftWidth: {
    minWidth: 275,
  },
  separator: {
    borderRight: '0.5px solid rgba(0, 0, 0, 0.2)',
    marginRight: 8,
    [theme.breakpoints.down(600)]: {
      flex: 0,
      borderLeft: 'none',
      borderBottom: '0.5px solid #000000',
      marginBottom: 16,
    },
  },
  contentText: {
    fontSize: 15,
    lineHeight: '20px',
  },
  listItemTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    opacity: 0.3,
    paddingLeft: 12,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  popper: {
    zIndex: 100,
    overflow: 'hidden',
    background: white,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    paddingBottom: 20,
    [theme.breakpoints.down(600)]: {
      width: 290,
      maxHeight: (props: StyledProps) => props.maxHeight,
    },
  },
  active: {
    position: 'absolute',
    top: 2,
    right: 0,
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: aquamarine,
  },
  dropDownListItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  collapse: {
    paddingLeft: 10,
  },
  disabled: {
    opacity: 0.3,
  },
  tooltipRoot: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: '12px !important',
    padding: '0 20px !important',
    opacity: '1 !important',
    width: 212,
    height: 116,
    fontSize: 13,
    lineHeight: '20px',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editCompany: {
    fontSize: 12,
    color: persianGreen,
  },
  dotter: {
    cursor: 'pointer',
    marginRight: 20,
    borderBottom: `1px solid ${persianGreen}`,
    borderBottomStyle: 'dashed',
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginRight: 15,
  },
  downloadText: {
    fontSize: 16,
    lineHeight: '24px',
    color: persianGreen,
  },
}));

export const MenuItemWrapper = withStyles((theme) => ({
  root: {
    paddingLeft: 12,
    marginLeft: 0,
    height: 48,
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    cursor: 'pointer',
    '&:hover': {
      paddingTop: 14,
      paddingBottom: 14,
      borderRadius: 8,
      background: aquaHaze,
    },
    [theme.breakpoints.down(600)]: {
      whiteSpace: 'break-spaces',
    },
  },
}))(MenuItem);
