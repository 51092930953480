import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MoreIcon from '../../../../assets/images/svg/more.svg';
import AutoCompleteComponent from '../../../../components/AutocompleteComponent';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import { selectCompanyCurrency } from '../../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../../store/currency/selectors';
import { getOperationProps } from '../../../../store/operations/selectors';
import { OperationType } from '../../../../store/operations/types';
import { Project } from '../../../../store/projects/types';
import { calculateSumAndCompanyCurrencySum } from '../../HOC/utils';
import InputComponent from '../Amount/InputComponent';
import Attachment from '../Attachment';
import SplittingPayment from '../SplittingPayment/SplittingPayment';
import { SplitPaymentProjectDetailedInfo } from '../SplittingPayment/types';
import Tags from '../Tags';
import { useStyles } from './Additions.styles';
import { Props } from './Additions.types';

function Additions(props: Props) {
  const {
    tags,
    projects,
    comments,
    editProps,
    isCompare,
    isNewState,
    difference,
    onCreateTag,
    selectedTags,
    onChangeTags,
    onChangeProject,
    onCreateProject,
    onChangeComments,
    isAllowedCreateTag,
    isAllowedCreateProject,
    creatingProjectInProgress,
    isVisibleProjectDropdown = true,
    setShowDeletedProjectError,
    showSplitPaymentProjectsError,
    splitPaymentProjects,
    isCryptoCurrency,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    account,
    amount,
    revertState,
    exchangeRate,
    currencyValue,
    currencyAmount,
    attachments,
    type,
  } = useSelector(getOperationProps);
  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const isCrypto = cryptoCurrencyCodes.includes(account?.currency.code || '');

  const handleSetOpen = useCallback(() => {
    if (!isCompare) {
      setIsOpen(true);
    }
  }, [isCompare]);

  const diffProjects = Boolean(difference?.projects);
  const diffTags = Boolean(difference?.tags);
  const diffComment = Boolean(difference?.comment);
  const diffAttachments = Boolean(difference?.attachments);
  const project = useCallback(() => {
    if (splitPaymentProjects?.length > 0) {
      return splitPaymentProjects[0];
    }
    if (Array.isArray(splitPaymentProjects)) {
      return null;
    }
    return splitPaymentProjects;
  }, [splitPaymentProjects]);

  useEffect(() => {
    if (
      comments ||
      selectedTags?.length ||
      project() ||
      diffProjects ||
      diffTags ||
      diffComment ||
      attachments?.length
    ) {
      setIsOpen(true);
    }
  }, [
    project,
    comments,
    diffTags,
    diffComment,
    selectedTags,
    diffProjects,
    attachments,
  ]);

  const getProject = useCallback(
    (pr: Project): SplitPaymentProjectDetailedInfo[] => {
      if (pr === null) {
        return [];
      }

      const calculateSumAndCompanyCurrencySumProps = {
        isEdit: false,
        type,
        amount,
        account,
        isCrypto,
        revertState,
        exchangeRate,
        currencyValue,
        currencyAmount,
        companyCurrency,
      };

      const {
        companyCurrencySum = null,
        transactionSum = null,
        sum,
      } = calculateSumAndCompanyCurrencySum(
        calculateSumAndCompanyCurrencySumProps,
      );
      return [
        {
          project: pr,
          percentages: '100',
          sum: sum?.toFixed(2) ?? '0',
          transactionSum,
          companyCurrencySum,
        },
      ];
    },
    [
      type,
      amount,
      account,
      isCrypto,
      revertState,
      exchangeRate,
      currencyValue,
      currencyAmount,
      companyCurrency,
    ],
  );

  const handleChangeProject = useCallback(
    (pr: Project) => {
      onChangeProject(getProject(pr));
    },
    [onChangeProject, getProject],
  );

  const isCryptoSplitShown = useCallback(() => {
    if (isCryptoCurrency || account?.crypto) {
      return splitPaymentProjects?.length > 1;
    }
    return true;
  }, [isCryptoCurrency, account?.crypto, splitPaymentProjects?.length]);

  return (
    <div className={classes.root}>
      {!isOpen && (
        <ButtonRowWithIcons
          id="cypress-open-additional-id"
          onClick={handleSetOpen}
          icon={MoreIcon}
          disabled={isCompare}
          alt="description"
          label={t('operationDialogs.comment.description')}
        />
      )}
      {isOpen && (
        <>
          {!!(
            amount &&
            !isCompare &&
            Number(amount) > 0 &&
            type !== OperationType.transfer &&
            isCryptoSplitShown()
          ) && (
            <SplittingPayment
              projects={projects ?? []}
              onChangeSplitPaymentProjects={onChangeProject}
              onCreateProject={onCreateProject}
              // @ts-ignore
              operationType={type}
              showSplitPaymentProjectsError={!!showSplitPaymentProjectsError}
              setShowDeletedProjectError={setShowDeletedProjectError}
              isCompare={Boolean(isCompare)}
            />
          )}
          {isCompare && (
            <TextFieldComponent
              isCompare
              difference={!!difference?.projects}
              disabled
              readableOnly
              label={t('projects.title')}
              placeholder={t('projects.title')}
              value={editProps?.projects ?? ''}
            />
          )}
          {!isCompare && (
            <div className={classes.container}>
              {isVisibleProjectDropdown && (
                <AutoCompleteComponent
                  isCompare={isCompare}
                  difference={diffProjects}
                  onChange={handleChangeProject}
                  onCreate={onCreateProject}
                  data={projects}
                  type="project"
                  value={project()?.project}
                  allowedCreate={isAllowedCreateProject}
                  loading={creatingProjectInProgress}
                />
              )}
            </div>
          )}
          <div className={classes.container}>
            <Tags
              isCompare={isCompare}
              difference={diffTags}
              allowedCreate={isAllowedCreateTag}
              values={tags}
              selectedTags={selectedTags}
              onChange={onChangeTags}
              onCreate={onCreateTag}
            />
            <InputComponent
              id="cypress-comment-id"
              isComment
              isCompare={isCompare}
              difference={diffComment}
              multiline
              value={comments}
              onChange={onChangeComments}
              placeholder={t('operationDialogs.comment.inputLabel')}
            />
            <Attachment
              isCompare={isCompare}
              isNewState={isNewState}
              diffAttachments={diffAttachments}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default React.memo(Additions);
