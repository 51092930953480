import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';

import CloseIcon from '../../../assets/images/svg/close-24-24.svg';
import DownloadIcon from '../../../assets/images/svg/download.svg';
import useDownload from '../../../hooks/useDownload';
import { useStyles } from '../importStyles';

type Props = {
  fileName: string;
  reader: string | ArrayBuffer | null;

  onRemoveFile(): void;
}

function RenderUploadedFile(props: Props) {
  const { fileName, reader, onRemoveFile } = props;

  const classes = useStyles();

  const { onDownload } = useDownload();

  const handleDownload = useCallback(() => {
    onDownload(fileName, reader);
  }, [onDownload, fileName, reader]);

  return (
    <div className={cn(classes.file, classes.progress)}>
      <div className={classes.fileInfo}>
        <img
          src={DownloadIcon}
          alt="download"
          className={classes.smallDownloadIcon}
          onClick={handleDownload}
        />
        <Typography
          className={cn(classes.smallText, classes.fileName)}
        >
          {fileName}
        </Typography>
      </div>
      <img
        src={CloseIcon}
        alt="close"
        onClick={onRemoveFile}
        className={classes.smallCloseIcon}
      />
    </div>
  );
}

export default React.memo(RenderUploadedFile);
