import { AxiosResponse } from 'axios';
import i18n from 'i18next';
import { isEqual } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  DELAY_UPDATE_EMPLOYEES_PERMISSIONS,
  OWNER_ROLE_ID,
} from '../../constants';
import { TAction } from '../../sagas/types';
import { selectUserRoleId } from '../../selectors/clientPermissions';
import { showError } from '../../utils/showError';
import { GET_USER_PERMISSIONS_PENDING } from '../auth/actions';
import { initialOwnerPermissions, isEmployeesEnable } from '../auth/selectors';
import {
  CREATE_INVITE_PENDING,
  CREATE_INVITE_SUCCESS,
  CREATE_ROLE_PENDING,
  CREATE_ROLE_PERMISSIONS_PENDING,
  CREATE_ROLE_PERMISSIONS_SUCCESS,
  CREATE_ROLE_SUCCESS,
  DELETE_EMPLOYEE_PENDING,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_INVITE_PENDING,
  DELETE_INVITE_SUCCESS,
  GET_INVITES_PENDING,
  GET_INVITES_SUCCESS,
  GET_PERMISSION_TYPES_PENDING,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_RESOURCES_PENDING,
  GET_RESOURCES_SUCCESS,
  GET_ROLE_PERMISSIONS_PENDING,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLES_PENDING,
  GET_ROLES_SUCCESS,
  RESET_CURRENT_ROLE_PERMISSIONS,
  UPDATE_EMPLOYEE_PENDING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_ROLE_PENDING,
  UPDATE_ROLE_SUCCESS,
} from './actions';
import employeeApi from './api';
import {
  CreateInvitePayload,
  CreateInviteResponse,
  CreateRolePermissionsPayload,
  GetRolePermissionsPayload,
  UpdateRolePayload,
} from './sagas.types';
import {
  selectDeletePermissionIds,
  selectEditPermissionIds,
  selectReadPermissionIds,
} from './selectors';
import { FastPermissions, Role } from './types';

export function* loadInvites(): SagaIterator {
  try {
    const employeesEnable = yield select(isEmployeesEnable);

    if (!employeesEnable) {
      return;
    }

    const { data } = yield call(employeeApi.getInvites);

    yield put({ type: GET_INVITES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* loadRoles(): SagaIterator {
  try {
    const { data }: AxiosResponse<Role[]> = yield call(employeeApi.getRoles);

    const readPermissionIds = yield select(selectReadPermissionIds);
    const editPermissionIds = yield select(selectEditPermissionIds);
    const deletePermissionIds = yield select(selectDeletePermissionIds);

    const rolesWithFastPermissions = data.filter(
      (el) => !!el.fastPermissions?.length && el._id !== OWNER_ROLE_ID,
    );

    const onlyReadRole = rolesWithFastPermissions.find((el) =>
      isEqual(el.fastPermissions, readPermissionIds),
    );

    const readEditRole = rolesWithFastPermissions.find((el) =>
      isEqual(el.fastPermissions, [...readPermissionIds, ...editPermissionIds]),
    );

    const readEditDeleteRole = rolesWithFastPermissions.find((el) =>
      isEqual(el.fastPermissions, [
        ...readPermissionIds,
        ...editPermissionIds,
        ...deletePermissionIds,
      ]),
    );

    if (!onlyReadRole) {
      const label = i18n.t(`employees.permissions.${FastPermissions.read}`);
      const { data: readRole }: AxiosResponse<{ role: Role; roles: Role[] }> =
        yield call(employeeApi.createRole, label);

      yield call(
        employeeApi.addFastPermissions,
        readRole.role._id,
        readPermissionIds,
      );
    }

    if (!readEditRole) {
      const label = i18n.t(
        `employees.permissions.${FastPermissions.readWrite}`,
      );
      const { data: EditRole }: AxiosResponse<{ role: Role; roles: Role[] }> =
        yield employeeApi.createRole(label) as any;

      yield call(employeeApi.updateFastPermissions, EditRole.role._id, [
        ...readPermissionIds,
        ...editPermissionIds,
      ]);
    }

    if (!readEditDeleteRole) {
      const label = i18n.t(`employees.permissions.${FastPermissions.full}`);
      const { data: FullRole }: AxiosResponse<{ role: Role; roles: Role[] }> =
        yield employeeApi.createRole(label) as any;

      yield call(employeeApi.updateFastPermissions, FullRole.role._id, [
        ...readPermissionIds,
        ...editPermissionIds,
        ...deletePermissionIds,
      ]);
    }

    yield delay(DELAY_UPDATE_EMPLOYEES_PERMISSIONS);

    const { data: roles }: AxiosResponse<Role[]> = yield call(
      employeeApi.getRoles,
    );

    yield put({ type: GET_ROLES_SUCCESS, payload: { data: roles } });
  } catch (error) {
    showError(error);
  }
}

export function* createRole({ payload: { label } }: any): SagaIterator {
  try {
    const initialPermissions = yield select(initialOwnerPermissions);

    const { data } = yield call(employeeApi.createRole, label);

    yield put({ type: CREATE_ROLE_SUCCESS, payload: { data } });

    yield put({
      type: CREATE_ROLE_PERMISSIONS_PENDING,
      payload: {
        roleId: data.role._id,
        permissions: initialPermissions,
      },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createInvite(action: TAction<CreateInvitePayload>) {
  try {
    const { roleId, name, email, facebookContext } = action.payload;

    const { data }: { data: CreateInviteResponse } = yield call(
      employeeApi.createInvite,
      roleId,
      name,
      email,
    );

    if (facebookContext) {
      facebookContext.sendData();
    }

    yield put({ type: CREATE_INVITE_SUCCESS, payload: { data } });

    yield delay(DELAY_UPDATE_EMPLOYEES_PERMISSIONS);

    yield put({ type: GET_ROLES_PENDING });
  } catch (error) {
    showError(error);
  }
}

export function* updateEmployee({ payload: { id, roleId, name } }: any) {
  try {
    const { data } = yield call(employeeApi.updateEmployee, id, roleId, name);

    yield put({ type: UPDATE_EMPLOYEE_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* deleteInvite({ payload: { id } }: any) {
  try {
    const { data } = yield call(employeeApi.deleteInvite, id);

    yield put({ type: DELETE_INVITE_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* deleteEmployee({ payload: { id } }: any) {
  try {
    const { data } = yield call(employeeApi.deleteEmployee, id);

    yield put({ type: DELETE_EMPLOYEE_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* updateRole({
  payload: { id, name },
}: TAction<UpdateRolePayload>) {
  try {
    const { data } = yield call(employeeApi.updateRole, id, name);

    yield put({ type: UPDATE_ROLE_SUCCESS, payload: { data } });
    yield put({ type: RESET_CURRENT_ROLE_PERMISSIONS });
  } catch (error) {
    showError(error);
  }
}

export function* getPermissionTypes() {
  try {
    const { data } = yield call(employeeApi.getPermissionTypes);

    yield put({ type: GET_PERMISSION_TYPES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getResources() {
  try {
    const { data } = yield call(employeeApi.getResources);

    yield put({ type: GET_RESOURCES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createRolePermissions({
  payload: { roleId, permissions },
}: TAction<CreateRolePermissionsPayload>): SagaIterator {
  try {
    const userRoleId = yield select(selectUserRoleId);
    const { data } = yield call(
      employeeApi.createPermissions,
      roleId,
      permissions,
    );

    yield put({ type: CREATE_ROLE_PERMISSIONS_SUCCESS, payload: { data } });

    if (roleId === userRoleId) {
      yield put({
        type: GET_USER_PERMISSIONS_PENDING,
        payload: { onlyUpdate: true },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* getRolePermissions(
  action: TAction<GetRolePermissionsPayload>,
): SagaIterator {
  try {
    const { roleId } = action.payload;
    const initialPermissions = yield select(initialOwnerPermissions);

    if (roleId === OWNER_ROLE_ID) {
      yield put({
        type: GET_ROLE_PERMISSIONS_SUCCESS,
        payload: {
          data: initialPermissions,
        },
      });
    } else {
      const { data } = yield call(employeeApi.getPermissions, roleId);

      yield put({ type: GET_ROLE_PERMISSIONS_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export default function employeesSagas() {
  return all([
    takeLatest(GET_INVITES_PENDING, loadInvites),
    takeLatest(CREATE_INVITE_PENDING, createInvite),
    takeLatest(UPDATE_EMPLOYEE_PENDING, updateEmployee),
    takeLatest(DELETE_INVITE_PENDING, deleteInvite),
    takeLatest(DELETE_EMPLOYEE_PENDING, deleteEmployee),
    takeLatest(GET_ROLES_PENDING, loadRoles),
    takeLatest(CREATE_ROLE_PENDING, createRole),
    takeLatest(UPDATE_ROLE_PENDING, updateRole),
    takeLatest(GET_PERMISSION_TYPES_PENDING, getPermissionTypes),
    takeLatest(CREATE_ROLE_PERMISSIONS_PENDING, createRolePermissions),
    takeLatest(GET_RESOURCES_PENDING, getResources),
    takeLatest(GET_ROLE_PERMISSIONS_PENDING, getRolePermissions),
  ]);
}
