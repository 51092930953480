import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  loader: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: 50,
    bottom: 50,
    textAlign: 'center',
  },
});
