import Http from '../../services/Http';
import { ApiKey } from '../company/types';

const createApiKey = (name: string) =>
  Http.makeInstance().post('integration/companyApiKeys', {
    name,
  });

const updateApiKey = (key: ApiKey) =>
  Http.makeInstance().patch('integration/companyApiKeys', {
    ...key,
  });

const getApiKeys = () => Http.makeInstance().get('integration/companyApiKeys');

const deleteApiKey = (id: string) =>
  Http.makeInstance().delete(`integration/companyApiKeys/${id}`);

export default {
  createApiKey,
  getApiKeys,
  deleteApiKey,
  updateApiKey,
};
