import moment from 'moment';

import { OPERATION_TYPES } from '@finmap/core-constants';
import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PUMBPersonalImportParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      // case 8
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                in: 'Ре єстр опе рацій за пе ріод:',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Дата',
                    },
                    nexWord: {
                      eq: 'No док.',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Отримувача',
                    },
                    prevWord: {
                      eq: 'Платника/',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Дата',
                      },
                      nexWord: {
                        eq: 'No док.',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'За пiдсумком:',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nопе рації', 0],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Кор. рахунок', 6],
              },
              debit: {
                column: ['Де бе т', 2],
              },
              credit: {
                column: ['Кре дит', 3],
              },
              counterparty: {
                column: ['Платник/\nОтримувач', 7],
              },
            },
          },
        },
      },
      // case 2
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            (rawDocument[0].str.includes('АКЦІОНЕРНЕ ТОВАРИСТВО') ||
              rawDocument[0].str.includes('Перший'))
          );
        },
        proceedCase: (importDocument) => {
          const filteredDocument = importDocument.filter((row) =>
            row.some((elem) =>
              [
                'Надходження',
                'Покупка',
                'Списання',
                'Оплата',
                'Отриманняготівки',
                'Поверненнякоштів',
              ].includes(elem.replace(/\s/, '')),
            ),
          );

          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(filteredDocument);
          return (): ImportResultItemMask => {
            let sum, currency;
            const sumAndCurrencyArr = this.getFirstValidCellByColumn([
              'Сума списання',
              3,
            ])?.split(' ');
            if (sumAndCurrencyArr?.length) {
              sum = sumAndCurrencyArr.slice(0, -1).join('');
              currency = sumAndCurrencyArr[sumAndCurrencyArr.length - 1];
            }
            let type;
            let externalType = this.getFirstValidCellByColumn([
              'Опис операції ',
              'Опис операції',
              7,
            ])?.replace(/\s/, '');
            if (
              externalType.includes('****') &&
              ![
                'Надходження',
                'Покупка',
                'Списання',
                'Оплата',
                'Отриманняготівки',
                'Поверненнякоштів',
              ].includes(externalType)
            ) {
              externalType = this.getFirstValidCellByColumn([
                'Опис операції ',
                8,
              ])?.replace(/\s/, '');
            }
            if (['Надходження', 'Поверненнякоштів'].includes(externalType)) {
              type = OPERATION_TYPES.INC;
            }

            if (
              ['Покупка', 'Списання', 'Оплата', 'Отриманняготівки'].includes(
                externalType,
              )
            ) {
              type = OPERATION_TYPES.CON;
            }

            return {
              dateAndTime: this.getFirstValidCellByColumn([
                'Дата та час\nоперації',
                0,
              ])?.replace('\n', ' '),
              sum,
              type,
              currency,
              comment: this.getFirstValidCellByColumn(['Деталі операції', 6]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            interlineSpacingAccuracy: 3,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Дата та час'),
                (word) => word?.includes('(Дата'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('(Дата'),
                1,
              );
              self.deleteFromTo((word) =>
                word?.includes('Баланс рахунку на початок періоду'),
              );
              self.defineOperation([
                (value) => /^(\d+\.\d{2})/.test(value), // '25636.88'
                (value) => /^(\d+\.\d{2})/.test(value), // '25636.88'
                // (value) => moment(value, 'YYYY-MM-DD', true).isValid(),
                // (value) => moment(value, 'HH:mm:SS', true).isValid(),
              ]);
            },
          },
        },
      },
      // case 6
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[0].str.includes('Виписка по Кредитній карті')
          );
        },
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((arr) => {
            let comment, date;
            const [el1, el2] = arr[0].split('\n');
            const [dateRaw, strRaw] = el1.split(' ');
            if (moment(dateRaw, 'DD.MM.YYYY', true).isValid()) {
              date = dateRaw;
              if (strRaw) {
                comment = strRaw;
              }
            } else {
              const [dateRaw, strRaw] = el2.split(' ');
              date = dateRaw;
              if (strRaw) {
                comment = strRaw;
              }
            }
            body.push([
              date,
              comment ? comment + ' ' + arr[1] : arr[1],
              arr[2],
              arr[3],
              arr[4],
            ]);
          });

          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            let type;
            let externalType = this.getFirstValidCellByColumn([
              'Тип транзакції',
              2,
            ])?.replace(/\s/, '');
            if (['Надходження', 'Поверненнякоштів'].includes(externalType)) {
              type = OPERATION_TYPES.INC;
            }

            if (
              ['Покупка', 'Списання', 'Оплата', 'Отриманняготівки'].includes(
                externalType,
              )
            ) {
              type = OPERATION_TYPES.CON;
            }
            return {
              date: this.getFirstValidCellByColumn(['Дата операції', 0]),
              sum: this.getFirstValidCellByColumn([
                'Сума в валюті\nтранзакції',
                3,
              ]),
              type,
              comment: this.getFirstValidCellByColumn(['Опис операції', 1]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            interlineSpacingAccuracy: 0.3,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Сума в валюті') &&
                  etc?.prevWord?.includes('Операції по кредитній карті'),
                (word, etc) =>
                  word?.includes('транзакції') &&
                  etc?.nextWord?.includes('Картка'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('******') && etc?.prevWord?.includes('Картка'),
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.includes('Всього списано:') &&
                  !isNaN(Number(etc?.nextWord?.replaceAll(' ', ''))),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            let sum, currency;
            const sumAndCurrencyArr = this.getFirstValidCellByColumn([
              'Сума операції',
              5,
            ])?.split(' ');
            if (sumAndCurrencyArr?.length) {
              sum = sumAndCurrencyArr.slice(0, -1).join('');
              currency = sumAndCurrencyArr[sumAndCurrencyArr.length - 1];
            }
            return {
              dateAndTime: this.getFirstValidCellByColumn([
                'Дата операції',
                0,
              ])?.replace('\n', ' '),
              sum,
              currency,
              comment: this.getFirstValidCellByColumn(['Деталі операції', 3]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 3,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 5,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  (word?.includes('Дата операції') ||
                    word?.includes('Сума у валюті')) &&
                  etc?.prevWord?.includes('рух коштів'),
                (_, etc) => moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid(),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('комісії') &&
                  moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid(),
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.startsWith('ВСЬОГО') &&
                  /^(-?[\s\d]+,\d+)$/.test(etc?.nextWord), // '-16 900,00'
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d+ [A-Z]{3})$/.test(value), // '-16 900,00 UAH'
                (value) => /^(-?[\s\d]+,\d+ [A-Z]{3})$/.test(value), // '-16 900,00 UAH'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      // case 7
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Реєстр операцій за період:',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      in: 'Дата',
                    },
                  ],
                  [
                    1,
                    {
                      eq: '№ платіжного доручення',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    0,
                    {
                      eq: 'Всього за оборотами:',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              date: {
                column: ['Дата', 0],
                dateFormat: 'DD.MM.YYYY',
              },
              debit: {
                column: ['Списано', 9],
              },
              credit: {
                column: ['Зараховано', 8],
              },
              comment: {
                column: ['Призначення платежу', 7],
              },
              counterparty: {
                column: ['Платник/Одержувач', 2],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      // case 9
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                eq: 'Виписка по операціям',
              },
            ],
          ],
          proceedCaseConfig: {
            removeOnlyEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата операції',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Тип операції',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    0,
                    {
                      eq: 'ВСЬОГО',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              dateAndTime: {
                column: ['Дата операції', 0],
                add: [':00'],
              },
              sum: {
                column: ['Сума операції', 5],
              },
              comment: {
                column: ['Опис', 3],
              },
            },
          },
        },
      },
    ],
  };
}
