import React, { useCallback } from 'react';
import { Field, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import formik from './formik';
import { useStyles } from './styles';
import TextFieldComponent from '../text-field';

function ChangeUserPassword() {
  const { t } = useTranslation();
  const classes = useStyles();

  const TextField = useCallback(
    ({ form, field, placeholder, type, id }) => {
      const isFormTouched = form.submitCount > 0;
      const error = get(form.errors, field.name);

      const handleChange = (val: string) => {
        form.setFieldValue(field.name, val);
      };

      return (
        <TextFieldComponent
          id={id}
          rootClassName={classes.textRoot}
          type={type}
          label={placeholder}
          value={field.value}
          onChange={handleChange}
          placeholder={placeholder}
          errorText={t(error)}
          isError={isFormTouched && !!error}
        />
      );
    },
    [t, classes],
  );

  return (
    <Form className={classes.root}>
      <Field
        fullWidth
        id="test-old-password"
        type="password"
        name="oldPassword"
        component={TextField}
        placeholder={t('changePasswordPage.oldPassword')}
      />
      <Field
        fullWidth
        id="test-new-password"
        type="password"
        name="newPassword"
        component={TextField}
        placeholder={t('changePasswordPage.newPassword')}
      />
      <Button type="submit" className={classes.button}>
        <Typography className={classes.text}>{t('common.save')}</Typography>
      </Button>
    </Form>
  );
}

export const ChangeUserPasswordForm = formik(ChangeUserPassword);

export default ChangeUserPasswordForm;
