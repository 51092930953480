import { AnyAction } from 'redux';

import {
  GET_CALENDAR_PENDING,
  GET_CALENDAR_SUCCESS,
  SET_CALENDAR_PERIOD_FILTER,
  SET_CALENDAR_PERIOD_SUCCESS,
  SET_CALENDAR_SELECTED_DAY,
} from './actions';
import { CalendarData, CalendarState } from './types';

const initialState: CalendarState = {
  data: [],
  selectedDay: null,
  period: {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  },
  loading: false,
  journalPeriodFilter: null,
};

export const initialCalendarData: (CalendarData & {
  allDay: boolean;
  start: Date;
  end: Date;
})[] = [];

const calendarReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case SET_CALENDAR_PERIOD_FILTER: {
      return {
        ...state,
        journalPeriodFilter: payload.periodFilter,
      };
    }

    case GET_CALENDAR_PENDING: {
      return {
        ...state,
        loading: true,
        data: [],
      };
    }

    case GET_CALENDAR_SUCCESS: {
      return {
        ...state,
        data: payload.data,
        loading: false,
      };
    }

    case SET_CALENDAR_SELECTED_DAY: {
      return {
        ...state,
        selectedDay: payload.date,
      };
    }

    case SET_CALENDAR_PERIOD_SUCCESS: {
      return {
        ...state,
        period: payload.period,
      };
    }

    default:
      return state;
  }
};

export default calendarReducer;
