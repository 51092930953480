import './dialog-molecule.module.scss';
import { Dialog as DialogMaterial, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import cn from 'classnames';
import React, { createContext,useCallback, useRef, useState } from 'react';

import CloseIcon from '../../assets/images/svg/close-24-24.svg';
import { useRootStyles, usescrollClasses, useStyles } from './styles';

export interface DialogProps {
  isOpened: boolean;
  actions?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
  title: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  wrapperContainerClasses?: string;
  wrapContentStyle?: any;
  disableEscapeKeyDown?: boolean;
  isScrollableRoot?: boolean;
  isScrollableContent?: boolean;

  onEscapeKeyDown?(event: any): void;
  onClose(): void;
}

export interface DialogContextProps {
  hide(): void;
  show(): void;
}
const DialogContext = createContext<DialogContextProps | null>(null);

export const DialogContextProvider = DialogContext.Provider;

export function DialogComponent(props: DialogProps) {
  const {
    title,
    actions,
    onClose,
    children,
    isOpened,
    className,
    titleClassName,
    onEscapeKeyDown,
    wrapContentStyle,
    disableEscapeKeyDown,
    wrapperContainerClasses,
    isScrollableRoot = false,
    isScrollableContent = true,
  } = props;
  const targetRef = useRef<HTMLDivElement | null>(null);

  const dialogRef = useRef<HTMLDivElement | null>(null);

  const [show, setShowContainer] = useState(true);

  const hideContainer = useCallback(() => {
    setShowContainer(false);
  }, []);

  const showContainer = useCallback(() => {
    setShowContainer(true);
  }, []);

  const handleClose = useCallback(
    (event, reason) => {
      switch (reason) {
        case 'backdropClick':
          break;
        case 'escapeKeyDown':
          // eslint-disable-next-line no-lone-blocks
          {
            if (onEscapeKeyDown) {
              onEscapeKeyDown(event);
            }
          }
          break;
        default:
          onClose();
      }
    },
    [onClose, onEscapeKeyDown],
  );

  const classes = useStyles({
    // @ts-ignore
    height: '100%',
  });
  const rootClasses = useRootStyles();
  const scrollClasses = usescrollClasses();

  const RenderTitle = useCallback(() => {
    if (typeof title === 'string') {
      return (
        <Typography className={cn(classes.title, titleClassName)}>
          {title}
        </Typography>
      );
    }

    return title;
  }, [classes, titleClassName, title]);

  if (!isOpened) {
    return null;
  }

  return (
    <DialogContextProvider value={{ hide: hideContainer, show: showContainer }}>
      <DialogMaterial
        ref={dialogRef}
        open={isOpened}
        onClose={handleClose}
        PaperProps={{
          className: cn(classes.root, className),
        }}
        classes={{
          container: cn(
            classes.container,
            wrapperContainerClasses,
            !show && classes.hide,
            show && classes.show,
          ),
          root: isScrollableRoot? '' : rootClasses.root
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title" classes={{root: classes.relative}}>
          <RenderTitle />
          <img
            alt=""
            id="cypress-close-dialog"
            src={CloseIcon}
            className={classes.closeIcon}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent
          classes={{
            root: isScrollableContent ? scrollClasses.root : ''
          }}
        >
          <div ref={targetRef} style={wrapContentStyle}>
            {children}
          </div>
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </DialogMaterial>
    </DialogContextProvider>
  );
}

export const Dialog = React.memo(DialogComponent);

export default Dialog;
