import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CheckBoxIcon from '../../../assets/images/svg/payments/checkbox.svg';
import FondyIcon from '../../../assets/images/svg/payments/fondy-main-light.svg';
import PayoneerIcon from '../../../assets/images/svg/payments/payoneer.svg';
import PosterIcon from '../../../assets/images/svg/payments/poster.png';
import WesternBidIcon from '../../../assets/images/svg/payments/western-bid.svg';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { useStyles } from './styles';

type Props = {
  showFondy?: boolean;
  showCheckBox?: boolean;
  showPoster?: boolean;
  onSetupBank(event: any): void;
};

function AdditionalBanks(props: Props) {
  const {
    onSetupBank,
    showFondy = true,
    showCheckBox = true,
    showPoster = true,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {showFondy && (
        <div
          id="Fondy_my_accounts"
          className={classes.button}
          data-bankid={IntegrationTypeId.FondyQ2}
          onClick={onSetupBank}
        >
          <div className={classes.flex}>
            <img
              id="Fondy_my_accounts_text"
              alt="fondy"
              src={FondyIcon}
              className={classes.fondyIcon}
            />
          </div>
        </div>
      )}
      <div
        id="WesternBid_my_accounts"
        className={classes.button}
        data-bankid={IntegrationTypeId.WesternBid}
        onClick={onSetupBank}
      >
        <div className={classes.flex}>
          <img
            alt="western-bid"
            src={WesternBidIcon}
            className={classes.westernBidIcon}
          />
          <Typography
            id="WesternBid_my_accounts_text"
            className={classes.bankText}
          >
            {t('bank.westernBid')}
          </Typography>
        </div>
      </div>
      {showCheckBox && (
        <div
          id="CheckBox_my_accounts"
          className={classes.button}
          data-bankid={IntegrationTypeId.CheckBox}
          onClick={onSetupBank}
        >
          <div className={classes.flex}>
            <img alt="check-box" src={CheckBoxIcon} className={classes.icon} />
            <Typography
              id="CheckBox_my_accounts_text"
              className={classes.bankText}
            >
              Checkbox
            </Typography>
          </div>
        </div>
      )}
      {showPoster && (
        <div
          id="Poster_my_accounts"
          className={classes.button}
          data-bankid={IntegrationTypeId.Poster}
          onClick={onSetupBank}
        >
          <div className={classes.flex}>
            <img alt="poster" src={PosterIcon} className={classes.icon} />
            <Typography
              id="Poster_my_accounts_text"
              className={classes.bankText}
            >
              Poster
            </Typography>
          </div>
        </div>
      )}
      <div
        id="Payoneer_my_accounts"
        className={classes.button}
        data-bankid={IntegrationTypeId.Payoneer}
        onClick={onSetupBank}
      >
        <div className={classes.flex}>
          <img alt="payoneer" src={PayoneerIcon} className={classes.icon} />
          <Typography
            id="Payoneer_my_accounts_text"
            className={classes.bankText}
          >
            Payoneer
          </Typography>
        </div>
      </div>
    </>
  );
}

export default React.memo(AdditionalBanks);
