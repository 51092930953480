import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import { createBaseQuery } from '../journal/selectors';
import {
  GET_CALENDAR_PENDING,
  GET_CALENDAR_SUCCESS,
  SET_CALENDAR_PERIOD_PENDING,
  SET_CALENDAR_PERIOD_SUCCESS,
} from './actions';
import api from './api';
import { SetCalendarPeriodPayload } from './sagas.types';
import { getPeriod } from './selectors';

export function* getCalendar() {
  try {
    const { year, month } = yield select(getPeriod);
    const { ordersQuery, customFiltersQuery } = yield select(
      createBaseQuery('calendar'),
    );
    const { data } = yield call(
      api.getCalendar,
      year,
      month,
      ordersQuery,
      customFiltersQuery,
      0,
      0,
    );

    yield put({ type: GET_CALENDAR_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* setCalendarPeriod(action: TAction<SetCalendarPeriodPayload>) {
  try {
    const { year, month } = action.payload.period;

    yield put({
      type: SET_CALENDAR_PERIOD_SUCCESS,
      payload: { period: { year, month: month + 1 } },
    });
    yield put({ type: GET_CALENDAR_PENDING });
  } catch (error) {
    showError(error);
  }
}

export default function clients() {
  return all([
    takeLatest(GET_CALENDAR_PENDING, getCalendar),
    takeLatest(SET_CALENDAR_PERIOD_PENDING, setCalendarPeriod),
  ]);
}
