import { aquaHaze, mystic, persianGreen } from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  commonMargin: {
    marginBottom: 8,
  },
  lastMargin: {
    marginBottom: 24,
  },
  placeholder: {
    opacity: 0.5,
  },
  link: {
    fontSize: 13,
    lineHeight: '20px',
    color: persianGreen,
    cursor: 'pointer',
  },
  copyField: {
    borderRadius: 12,
    padding: '18px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: aquaHaze,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: mystic,
    },
  },
});
