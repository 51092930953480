import moment from 'moment';
import { createSelector } from 'reselect';

import { getDiffBetweenToday } from '../../utils/dateToUTC';
import { isLite, isPro } from '../../utils/subscriptions';
import { getServerTime } from '../common/selectors';
import { getCurrencies } from '../currency/selectors';
import { AppState } from '../reducers';

export const getCompanies = (state: AppState) => state.company.companies;
export const getCompaniesSettings = (state: AppState) =>
  state.company.companiesSettings;
export const getCompany = (state: AppState) => state.company.currentCompany;
export const getLoadingCompany = (state: AppState) => state.company.loading;

export const getApiKeys = (state: AppState) => state.company.apiKeys;

export const getWebHooks = (state: AppState) => state.company.webhooks;

export const selectCompanyId = createSelector(
  getCompany,
  (company) => company?._id,
);

export const selectCurrentCompany = createSelector(
  getCompany,
  (company) => company,
);

export const selectPayedCompany = createSelector(getCompanies, (companies) =>
  companies.some((el) => isLite(el) || isPro(el)),
);

export const selectCompanyFastPermissions = createSelector(
  selectCurrentCompany,
  (company) => company?.fastPermissionIds ?? [],
);

export const selectIsDemoCompany = createSelector(
  selectCurrentCompany,
  (company) => company?.demo || false,
);

export const subscriptionDaysLeft = createSelector(
  selectCurrentCompany,
  getServerTime,
  (company, serverTime) => {
    const tariffEndDate = moment(company?.tariffEndDate).startOf('day');

    return getDiffBetweenToday(tariffEndDate, 'days', 'YYYY-MM-DD', serverTime);
  },
);

export const selectCompanyCurrency = createSelector(
  getCompany,
  getCurrencies,
  (company, currencies) =>
    currencies?.find((currency) => currency._id === company?.currencyId),
);

export const selectCompanyCurrencyId = createSelector(
  selectCompanyCurrency,
  (currency) => currency?._id || '',
);

export const selectCompanyCurrencySymbol = createSelector(
  selectCompanyCurrency,
  (currency) => currency?.symbol || '',
);

export const selectCompanyCreator = createSelector(
  selectCurrentCompany,
  (company) => company?.createdBy || null,
);

export const selectDemoCompany = createSelector(getCompanies, (companies) =>
  companies.find((company) => company.demo),
);
