import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  text: {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '20px',
    position: 'relative',
    opacity: 0.4,
  },
});
