import moment from 'moment';

import {
  MAX_OPERATION_DATE,
  MIN_OPERATION_DATE,
  OPERATION_TYPES,
} from '@finmap/core-constants';
import { isEmptyImportField } from '@finmap/core-utils';

import {
  AccountObject,
  CaseOptions,
  JustPropertyKeys,
} from './base-import-parser-v3.dto';

const PRIVATE_FIELDS: string[] = ['OPTIONS', 'documentLength'];

export class ImportOperation {
  index: number = undefined;
  date?: string = undefined; // be careful! Here "date" is "dateAndTime" in "ImportOperationMask"
  dateOfPayment?: string = undefined; // be careful! Here "dateOfPayment" is "dateAndTimeOfPayment" in "ImportOperationMask"
  project?: string = undefined;
  // tags?: string; // ???
  counterparty?: string = undefined;
  comment?: string = undefined;
  sum: number = undefined;
  // companyCurrencySum?: number; // ???
  type: OPERATION_TYPES = undefined;
  subType: string = undefined;
  category?: string = undefined;
  subCategory?: string = undefined;
  accountFromId?: string = undefined;
  accountToId?: string = undefined;
  accountFromObject?: AccountObject = undefined;
  accountToObject?: AccountObject = undefined;
  // periodStartDate?: number; // ???
  // periodEndDate?: number; // ???

  protected readonly OPTIONS: CaseOptions = {};
  protected readonly documentLength: number = 0;

  constructor(operation, OPTIONS: CaseOptions, documentLength: number) {
    this.OPTIONS = OPTIONS;
    this.documentLength = documentLength;
    for (const key of Object.keys(this)) {
      if (PRIVATE_FIELDS.includes(key)) continue;
      if (!isEmptyImportField(operation[key])) this[key] = operation[key];
      else delete this[key];
    }
  }

  protected throwError(message: string, line = this.index): never {
    throw new Error(
      JSON.stringify({
        message,
        line: this.OPTIONS.isDESCOpOrder
          ? this.documentLength - line
          : line + 1,
      }),
    );
  }

  toObject(errorTranslations) {
    this.checkMinMaxDates(errorTranslations);
    this.checkRequiredFields(errorTranslations);

    for (const key of Object.keys(this))
      if (PRIVATE_FIELDS.includes(key)) delete this[key];

    return { ...this };
  }

  protected checkMinMaxDates(errorTranslations) {
    const isDateOverMax = moment(this.date).isAfter(MAX_OPERATION_DATE);
    const isDateLessMin = moment(this.date).isBefore(MIN_OPERATION_DATE);
    const isDateOfPaymentOverMax = moment(this.dateOfPayment).isAfter(
      MAX_OPERATION_DATE,
    );
    const isDateOfPaymentLessMin = moment(this.dateOfPayment).isBefore(
      MIN_OPERATION_DATE,
    );
    if (isDateOverMax || isDateOfPaymentOverMax)
      this.throwError(errorTranslations?.maxDate);
    if (isDateLessMin || isDateOfPaymentLessMin)
      this.throwError(errorTranslations?.minDate);
  }

  protected checkRequiredFields(errorTranslations) {
    if (isEmptyImportField(this.date) && isEmptyImportField(this.dateOfPayment))
      this.throwError(errorTranslations?.dateIsRequired);
    if (isEmptyImportField(this.sum))
      this.throwError(errorTranslations?.sumIsRequired);
    if (isEmptyImportField(this.type))
      this.throwError(errorTranslations?.typeIsRequired);
  }
}

export type ImportResultItem = Pick<
  ImportOperation,
  JustPropertyKeys<ImportOperation>
>;
