import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import QuestionIcon from '../../../../../../assets/images/svg/question.svg';
import TrashIcon from '../../../../../../assets/images/svg/trash-red-20-20.svg';
import CustomButton from '../../../../../../components/Button';
import ButtonRowWithIcons from '../../../../../../components/ButtonRowWithIcons';
import Dialog from '../../../../../../components/Dialog/Dialog';
import RemoveDialog from '../../../../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import companyActions from '../../../../../../store/company/actions';
import { Webhook } from '../../../../../../store/company/types';
import { outrageousOrange } from '../../../../../../theme/colors';
import { useStyles } from './styles';

export type Props = {
  webhook: Webhook | null;

  onClose(): void;
};

const validationSchema = Yup.object().shape({
  webHookUrl: Yup.string().url('errors.wrongUrl').required('errors.reqField'),
  webHookName: Yup.string().required('errors.reqField'),
});

function InfoWebhook(props: Props) {
  const { onClose, webhook } = props;
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [webHookName, setWebHookName] = useState('');
  const [webHookUrl, setWebHookUrl] = useState('');

  const [nameError, setNameError] = useState('');
  const [urlError, setUrlError] = useState('');

  const handleChangeName = useCallback((val: string) => {
    setWebHookName(val);
    setNameError('');
  }, []);

  const handleChangeUrl = useCallback((val: string) => {
    setWebHookUrl(val);
    setUrlError('');
  }, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    if (webhook) {
      dispatch(companyActions.removeWebHook(webhook._id));
    }

    setShowRemoveDialog(false);
  }, [dispatch, webhook]);

  const handleRemoveWebhook = useCallback(() => {
    handleCloseRemoveDialog();
    onClose();
  }, [handleCloseRemoveDialog, onClose]);

  const validateFields = useCallback(
    async () =>
      validationSchema
        .validate({ webHookName, webHookUrl })
        .then(() => true)
        .catch((err) => {
          if (err.path === 'webHookUrl') {
            setUrlError(t(err.errors));
          }

          if (err.path === 'webHookName') {
            setNameError(t(err.errors));
          }

          return false;
        }),
    [webHookName, webHookUrl, t],
  );

  const handleUpdate = useCallback(async () => {
    if (webhook && (await validateFields())) {
      dispatch(
        companyActions.updateWebhook({
          id: webhook._id,
          name: webHookName,
          url: webHookUrl,
        }),
      );

      onClose();
    }
  }, [dispatch, onClose, validateFields, webHookName, webHookUrl, webhook]);

  useEffect(() => {
    if (webhook) {
      setWebHookName(webhook.name);
      setWebHookUrl(webhook.url);
    }
  }, [webhook]);

  return (
    <>
      <Dialog title={t('webhook.info')} onClose={onClose} isOpened>
        <TextFieldComponent
          value={webHookUrl}
          onChange={handleChangeUrl}
          placeholder={t('webhook.url')}
          isError={Boolean(urlError)}
          errorText={urlError}
        />
        <TextFieldComponent
          value={webHookName}
          onChange={handleChangeName}
          placeholder={t('webhook.name')}
          isError={Boolean(nameError)}
          errorText={nameError}
        />
        <div className={classes.row}>
          <a
            className={classes.rowWrapper}
            href="https://api.finmap.online"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.questionIcon}
              src={QuestionIcon}
              alt="question"
            />
            <Typography className={classes.text}>
              {t('webhook.docTitle')}
            </Typography>
          </a>
        </div>
        <ButtonRowWithIcons
          textColor={outrageousOrange}
          onClick={handleShowRemoveDialog}
          icon={TrashIcon}
          alt="remove"
          label={t('webhook.remove')}
          wrapClasses={classes.margin24}
          showUnderLine={false}
        />
        <CustomButton
          fullWidth
          action={handleUpdate}
          title={t('saveChanges')}
        />
      </Dialog>
      {showRemoveDialog && (
        <RemoveDialog
          title={t('webhook.removeTitle', {
            postProcess: 'sprintf',
            sprintf: [webHookName],
          })}
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemoveWebhook}
          type="confirmRemoveWebhook"
          name={webHookName}
        />
      )}
    </>
  );
}

export default React.memo(InfoWebhook);
