import { makeStyles } from '@material-ui/core/styles';

import { catskillWhite, white } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  dialogRoot: {
    minWidth: 1067,
    background: catskillWhite,
    padding: 0,
  },
  dialogTitleContainer: {
    background: white,
  },
  container: {
    display: 'flex',
    background: catskillWhite,
    paddingLeft: 20,
    paddingRight: 20,
  },
  containerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 0',
  },
  block: {
    paddingTop: 43,
    paddingLeft: 22,
    paddingRight: 22,
    flex: 0.33,
    background: white,
    borderRadius: 16,
    height: 535,
  },
  blockTitleContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 35,
    textAlign: 'center',
    padding: '0 40px',
  },
  blockTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '32px',
    background: white,
  },
  blockSeparator: {
    marginRight: 17,
  },
  listWrapper: {
    margin: '0 0 16px 0',
  },
  subTitleContainer: {
    height: 76,
    borderRadius: 36,
    border: '4px solid transparent',
    background:
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #7FDEF5, #7EF5AD) border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 33,
    width: '100%',
    padding: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionRow: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  descriptionRowSeparator: {
    marginBottom: 16,
  },
  description: {
    fontSize: 12,
    lineHeight: '20px',
  },
  icon: {
    marginRight: 11,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  successContainer: {
    height: 576,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successTitle: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '40px',
    marginBottom: 22,
    width: 350,
    textAlign: 'center',
  },
  successDescription: {
    fontWeight: 'bold',
    marginBottom: 32,
    width: 350,
    textAlign: 'center',
  },
  buttonSuccess: {
    width: 270,
  },
  iconSuccess: {
    marginBottom: 32,
  },
});
