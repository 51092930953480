import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class UniversalBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn(['Валюта', 3]),
            dateAndTime: this.getFirstValidCellByColumn(['Дата операции', 4]),
            counterparty: this.getFirstValidCellByColumn(['Корреспондент', 10]),
            debit: this.getFirstValidCellByColumn(['Дебет', 13]),
            credit: this.getFirstValidCellByColumn(['Кредит', 14]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 15]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата опер.',
              0,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 1]),
            credit: this.getFirstValidCellByColumn(['Кредит', 2]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 4]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            // spaceLengthPx: 4,
            // pageSeparatorsLengthPx: 3,
            // maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 7,
            // verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word) => word?.includes('Дата опер.'),
                (word) => word?.includes('Призначення платежу'),
              );
              self.deleteFromTo(
                undefined,
                (word) => word?.includes('Призначення платежу'),
                1,
              );
              self.deleteFromTo(
                (word, etc) =>
                  word?.startsWith('Документів:') && /\d/.test(etc?.nextWord),
              );
              self.defineOperation([
                (value) => /^([\s\d]+.\d{2})$/.test(value), // '1 000.00'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
