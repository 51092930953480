import i18next from 'i18next';
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { ONBOARDING_STATUS, ONBOARDING_STEPS } from '@finmap/core-constants';

import { TAction } from '../../sagas/types';
import api from '../../services/company';
import Storages, { StorageKey } from '../../services/Storages';
import { showError } from '../../utils/showError';
import { ACCOUNTS_INIT, GET_LOG_ACCOUNTS_PENDING } from '../accounts/actions';
import apiKeysApi from '../accounts/api';
import {
  START_LOADING_PENDING,
  USER_UPDATE_PROFILE_SUCCESS,
} from '../auth/actions';
import authApi from '../auth/api';
import { getUser } from '../auth/selectors';
import { User } from '../auth/types';
import { categoryActionTypes } from '../categories/actions';
import { onboardingActionTypes } from '../onboardingV2/actions';
import {
  CHANGE_COMPANY_PENDING,
  CREATE_API_KEY_PENDING,
  CREATE_API_KEY_SUCCESS,
  CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING,
  CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS,
  CREATE_COMPANY_PENDING,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY_PENDING,
  DELETE_COMPANY_SUCCESS,
  GET_API_KEYS_PENDING,
  GET_API_KEYS_SUCCESS,
  GET_COMPANIES_LIST_PENDING,
  GET_COMPANIES_LIST_SUCCESS,
  GET_COMPANIES_PENDING,
  GET_COMPANIES_SETTINGS_LIST_PENDING,
  GET_COMPANIES_SETTINGS_LIST_SUCCESS,
  GET_COMPANIES_SUCCESS,
  REMOVE_API_KEY_PENDING,
  REMOVE_API_KEY_SUCCESS,
  SET_CURRENT_COMPANY,
  UPDATE_GROUP_COMPANIES_PENDING,
  webHookTypes,
} from './actions';
import {
  ChangeCompanyPayload,
  CreateApiKeyPayload,
  CreateCompanyPayload,
  CreateWebhookPayload,
  DeleteCompany,
  RemoveApiKeyPayload,
  UpdateGroupCompaniesPayload,
  UpdateWebhookPayload,
} from './sagas.types';
import { selectCompanyId } from './selectors';
import { Company } from './types';
import webhookApi from './webhookApi';

export function* getCompanies() {
  try {
    const { data } = yield call(api.getCompanies);

    yield put({ type: GET_COMPANIES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getCompaniesList() {
  try {
    const { data } = yield call(api.getCompanies);

    yield put({ type: GET_COMPANIES_LIST_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getCompaniesSettingsList() {
  try {
    const { data } = yield call(api.getCompaniesSettings);

    yield put({ type: GET_COMPANIES_SETTINGS_LIST_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* changeCompany({
  payload: { id },
}: TAction<ChangeCompanyPayload>) {
  try {
    Storages.put('currentCompanyId', id);

    yield put({ type: SET_CURRENT_COMPANY, payload: { id } });

    yield put({ type: categoryActionTypes.RESET_CATEGORIES });

    yield put({ type: ACCOUNTS_INIT });

    yield put({ type: START_LOADING_PENDING });
  } catch (error) {
    showError(error);
  }
}

export function* createCompany({
  payload,
}: TAction<CreateCompanyPayload>): SagaIterator {
  try {
    const user: User = yield select(getUser);
    const grsf = Storages.get(StorageKey.grsf);

    const { data } = yield call(api.createCompany, payload);

    yield put({ type: CREATE_COMPANY_SUCCESS, payload: { data } });

    yield put({ type: SET_CURRENT_COMPANY, payload: { id: data.company._id } });

    if (!user.onboardingCompleted) {
      const { data: updatedUserProfileData } = yield call(
        authApi.updateProfile,
        data.company._id,
        user.currentLangIso,
      );

      const updatedUserPayload = {
        token: updatedUserProfileData.token,
        data: updatedUserProfileData.user,
      };

      yield put({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: updatedUserPayload,
      });

      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.GREETINGS,
          status: ONBOARDING_STATUS.PASSED,
        },
      });

      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.START_SERVICE_FROM,
          status: ONBOARDING_STATUS.SKIP,
        },
      });

      Storages.remove(StorageKey.isFirstEntry);
    }

    if (window.growsurf && user && grsf) {
      const growsurfData = yield window.growsurf.getParticipantByEmail(
        user.email || '',
      );

      if (!growsurfData) {
        yield window.growsurf.addParticipant({
          email: user.email,
          company: payload.name,
        });
      }

      Storages.remove(StorageKey.grsf);
    }

    yield put({ type: START_LOADING_PENDING });
  } catch (error) {
    showError(error);
  }
}

export function* createCompanyByOnboardingFlow({
  payload,
}: TAction<CreateCompanyPayload>): SagaIterator {
  try {
    const user = yield select(getUser);
    const grsf = Storages.get(StorageKey.grsf);

    const { data } = yield call(api.createCompany, payload);

    yield put({ type: CREATE_COMPANY_SUCCESS, payload: { data } });

    yield put({ type: SET_CURRENT_COMPANY, payload: { id: data.company._id } });

    const { data: userProfileData } = yield call(
      authApi.updateProfile,
      data.company._id,
      user.currentLangIso,
    );

    const userPayload = {
      token: userProfileData.token,
      data: userProfileData.user,
    };

    yield put({ type: USER_UPDATE_PROFILE_SUCCESS, payload: userPayload });

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    yield put({ type: onboardingActionTypes.REMOVE_ONBOARDING_GREETING_STEP });

    yield put({
      type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
      payload: {
        stepName: ONBOARDING_STEPS.GREETINGS,
        status: ONBOARDING_STATUS.PASSED,
      },
    });

    yield put({
      type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
      payload: {
        stepName: ONBOARDING_STEPS.START_SERVICE_FROM,
        status: ONBOARDING_STATUS.ACTIVE,
      },
    });

    if (window.growsurf && user && grsf) {
      const growsurfData = yield window.growsurf.getParticipantByEmail(
        user.email || '',
      );

      if (!growsurfData) {
        yield window.growsurf.addParticipant({
          email: user.email,
          company: payload.name,
        });
      }

      Storages.remove(StorageKey.grsf);
    }

    yield put({ type: CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS });
  } catch (error) {
    showError(error);
  }
}

export function* deleteCompany({
  payload: { id, history },
}: TAction<DeleteCompany>): SagaIterator {
  try {
    const currentCompanyId = yield select(selectCompanyId);
    const { data } = yield call(api.deleteCompany, id);

    if (!data.length) {
      yield put({ type: START_LOADING_PENDING, payload: { history } });
    } else if (currentCompanyId === id) {
      yield put({ type: SET_CURRENT_COMPANY, payload: { id: data[0]._id } });
      yield put({ type: START_LOADING_PENDING, payload: { history } });
    } else {
      yield put({ type: DELETE_COMPANY_SUCCESS, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupCompanies(
  action: TAction<UpdateGroupCompaniesPayload>,
) {
  try {
    const { companies } = action.payload;

    yield all(
      companies.map((item: Company) => {
        const { _id, ...rest } = item;

        return call(api.updatePropCompany, _id, {
          ...rest,
        });
      }),
    );

    yield put({ type: GET_COMPANIES_LIST_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* getApiKeys() {
  try {
    const { data } = yield call(apiKeysApi.getApiKeys);

    yield put({
      type: GET_API_KEYS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* removeApiKey(action: TAction<RemoveApiKeyPayload>) {
  try {
    const { id } = action.payload;
    const { data } = yield call(apiKeysApi.deleteApiKey, id);

    yield put({
      type: REMOVE_API_KEY_SUCCESS,
      payload: { data },
    });

    toast(i18next.t('api.removeSuccessful'));
  } catch (error) {
    showError(error);
  }
}

function* createApiKey(action: TAction<CreateApiKeyPayload>) {
  try {
    const { name } = action.payload;
    const { data } = yield call(apiKeysApi.createApiKey, name);

    yield put({
      type: CREATE_API_KEY_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* getWebHooks() {
  try {
    const { data } = yield call(webhookApi.getWebHooks);

    yield put({
      type: webHookTypes.GET_WEBHOOKS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* removeWebHook(action: TAction<RemoveApiKeyPayload>) {
  try {
    const { id } = action.payload;
    const { data } = yield call(webhookApi.deleteWebHook, id);

    yield put({
      type: webHookTypes.REMOVE_WEBHOOK_SUCCESS,
      payload: { data },
    });

    toast(i18next.t('api.removeSuccessful'));
  } catch (error) {
    showError(error);
  }
}

function* createWebHook(action: TAction<CreateWebhookPayload>) {
  try {
    const { name, url } = action.payload;
    const { data } = yield call(webhookApi.createWebHook, name, url);

    yield put({
      type: webHookTypes.CREATE_WEBHOOK_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateWebHook(action: TAction<UpdateWebhookPayload>) {
  try {
    const { name, url, id } = action.payload;

    const { data } = yield call(webhookApi.updateWebHook, id, name, url);

    yield put({
      type: webHookTypes.UPDATE_WEBHOOK_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export default function company() {
  return all([
    takeLatest(GET_COMPANIES_PENDING, getCompanies),
    takeLatest(CREATE_COMPANY_PENDING, createCompany),
    takeLatest(
      CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING,
      createCompanyByOnboardingFlow,
    ),
    takeLatest(CHANGE_COMPANY_PENDING, changeCompany),
    takeLatest(UPDATE_GROUP_COMPANIES_PENDING, updateGroupCompanies),
    takeLatest(GET_COMPANIES_LIST_PENDING, getCompaniesList),
    takeLatest(GET_COMPANIES_SETTINGS_LIST_PENDING, getCompaniesSettingsList),
    takeLatest(DELETE_COMPANY_PENDING, deleteCompany),

    takeLatest(GET_API_KEYS_PENDING, getApiKeys),
    takeLatest(REMOVE_API_KEY_PENDING, removeApiKey),
    takeLatest(CREATE_API_KEY_PENDING, createApiKey),

    takeLatest(webHookTypes.GET_WEBHOOKS_PENDING, getWebHooks),
    takeLatest(webHookTypes.REMOVE_WEBHOOK_PENDING, removeWebHook),
    takeLatest(webHookTypes.CREATE_WEBHOOK_PENDING, createWebHook),
    takeLatest(webHookTypes.UPDATE_WEBHOOK_PENDING, updateWebHook),
  ]);
}
