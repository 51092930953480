import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../../../../components/TextField/TextFieldComponent.types';
import { ENTER_KEY_CODE } from '../../../../../../constants';
import { capitalizeFirstLetter } from '../../../../../../utils/stringUtils';
import { useStyles } from './styles';
import { Props } from './types';

function CreateDialog(props: Props) {
  const {
    type,
    title,
    isError,
    onClose,
    onCreate,
    isLoading,
    buttonText,
    resetCreateError,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const handleChangeName = useCallback(
    (value: string) => {
      setName(value);
      setNameError(false);

      if (isError) {
        resetCreateError();
      }
    },
    [resetCreateError, isError],
  );

  const handleCreate = useCallback(() => {
    if (name) {
      onCreate(name);
    } else {
      setNameError(true);
    }
  }, [name, onCreate]);

  const handleClose = useCallback(() => {
    setName('');
    onClose();
  }, [onClose]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent & { keyCode: number }) => {
      if (event.keyCode === ENTER_KEY_CODE) {
        event.preventDefault();

        handleCreate();
      }
    },
    [handleCreate],
  );

  let errorText = t(`errors.create.${type}.exist`);

  if (nameError) {
    errorText = t('system.fieldMustFilled');
  }

  return (
    <Dialog
      isOpened
      onClose={handleClose}
      title={title}
      className={classes.dialog}
    >
      <div className={classes.lastBlock}>
        <TextFieldComponent
          // @ts-ignore
          ga4Id={Ga4Errors[`create${capitalizeFirstLetter(type)}NameError`]}
          active
          onChange={handleChangeName}
          value={name}
          placeholder={t('common.title')}
          isError={isError || nameError}
          errorText={errorText}
          onKeyDown={handleKeyDown}
        />
      </div>
      <CustomButton
        fullWidth
        action={handleCreate}
        title={buttonText}
        loading={isLoading}
      />
    </Dialog>
  );
}

export default React.memo(CreateDialog);
