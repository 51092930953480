import { withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import { StyleProps } from '../../foundation/Operations/Components/ConfirmChangeRepeatDialog/types';
import { StyledProps } from '../../scenes/InfoBlock/Journal/JournalContainer/types';
import { AutoCompleteProps } from '../../store/types';
import { aquaHaze, jade, persianGreen, white } from '../../theme/colors';

export const useAutocompleteStyles = makeStyles({
  popper: {
    width: (props: StyledProps) => props.width,
    marginTop: 13,
    height: 216,
    overflow: 'scroll',
    borderRadius: 12,
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    backgroundColor: white,
  },
  noOptions: {
    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    color: jade,
  },
  option: {
    marginBottom: 14,
    padding: '14px 12px',
    marginLeft: 20,
    marginRight: 20,
    fontSize: 15,
    lineHeight: '20px',
    '&:hover': {
      borderRadius: 8,
      backgroundColor: aquaHaze,
    },
    '&[aria-selected="true"]': {
      backgroundColor: white,
      cursor: 'inherit',
    },
    '&[data-focus="true"]': {
      borderRadius: 8,
      background: aquaHaze,
    },
  },
  endAdornment: {
    paddingRight: 0,
  },
  paper: {
    height: '100%',
    boxShadow: 'none',
  },
  listbox: {
    height: '100%',
  },
});

export const useStyles = makeStyles({
  persianGreen: {
    color: persianGreen,
  },
  inputRoot: {
    width: '100%',
    height: '100%',
    borderRadius: 12,
    paddingLeft: 16,
  },
  dialogRoot: {
    padding: 32,
    borderRadius: 24,
    maxWidth: 480,
  },
  root: {
    display: 'flex',
    position: 'relative',
    background: aquaHaze,
    borderRadius: 12,
    marginBottom: (props: StyleProps) => (props.isError ? 28 : 16),
    transition: 'margin 700ms',
    height: 56,
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  error: {
    background: 'rgba(255, 63, 46, 0.12)',
  },
  container: {
    flex: 1,
    padding: '9px 0',
  },
  selectedOptionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedOptionText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  labelRoot: {
    marginLeft: 16,
  },
  formControl: {
    display: 'flex',
    flex: 1,
  },
  disabledInput: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  shortText: {
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  optionIcon: {
    marginRight: 5,
  },
});

export const StyledAutocomplete = withStyles({
  groupLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    padding: '10px 0 10px 44px',
  },
})(Autocomplete<AutoCompleteProps, boolean, boolean>);
