import {
  CreateIntegrationPayload,
  CreatePrivate24BusinessPayload,
  CreatePrivate24Payload,
} from '../../store/integrations/sagas.types';
import { Privat24BusinessAccount } from '../../store/integrations/types';
import Http from '../Http';

const getIntegrations = () => Http.makeInstance().get('/integrations');

const getIntegrationsTypes = () => Http.makeInstance().get('/integrationTypes');

const createIntegration = (
  payload: Omit<
    CreateIntegrationPayload & { integrationCredentialsId: string },
    'facebookContext'
  >,
) =>
  Http.makeInstance().post('/integrations', {
    ...payload,
  });

const private24CreateCredentials = (payload: CreatePrivate24Payload) =>
  Http.makeInstance().post('/integrations/private24PersonalCredentials', {
    ...payload,
  });

const private24BusinessCreateCredentials = (
  payload: CreatePrivate24BusinessPayload,
) =>
  Http.makeInstance().post('/integrations/privatForBusinessCredentials', {
    ...payload,
  });

const updatePrivate24BusinessCredentials = (
  autoClientId: string,
  token: string,
  id: string,
  acc: Privat24BusinessAccount['acc'],
) =>
  Http.makeInstance().patch(
    `/integrations/privatForBusinessCredentials/${id}`,
    {
      autoClientId,
      token,
      acc,
    },
  );

const getMonoInitialCredentials = () =>
  Http.makeInstance().post('/integrations/monoPersonalCredentials');

const getMonoCredentialsById = (id: string) =>
  Http.makeInstance().get(`/integrations/monoPersonalCredentials/${id}`);

const updateMonoCredentialsById = (id: string, cardId: string) =>
  Http.makeInstance().patch(`/integrations/monoPersonalCredentials/${id}`, {
    cardId,
  });

const getMonoAccounts = (id: string) =>
  Http.makeInstance().get(
    `/integrations/monoPersonalCredentials/${id}/accounts`,
  );

const removeIntegration = (id: string, removeAll: boolean) =>
  Http.makeInstance().delete(
    `/integrations/${id}?deleteExistingOperations=${removeAll}`,
  );

const getServerIp = () => Http.makeInstance().get('/integrations/getServerIp');

export default {
  getIntegrations,
  getIntegrationsTypes,
  createIntegration,
  getMonoInitialCredentials,
  getMonoCredentialsById,
  updateMonoCredentialsById,
  getMonoAccounts,
  removeIntegration,
  private24CreateCredentials,
  private24BusinessCreateCredentials,
  updatePrivate24BusinessCredentials,
  getServerIp,
};
