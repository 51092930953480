import moment from 'moment';

import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class BAS1CxlsParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    date: [['Дата'], null],
    sum: [['Сума', 'Сумма', 'Сумма документа'], null],
    currency: [['Валюта'], null],
    counterparty: [['Контрагент'], null],
    comment: [['Коментар', 'Комментарий'], null],
    operationType: [['Вид операції', 'Вид операции'], null],
    organization: [['Організація', 'Организация'], null],
    appointment: [['Призначення платежу', 'Назначение платежа'], null],
    receipts: [['Надходження', 'Поступило'], null],
    writeOff: [['Списання', 'Списано'], null],
    // final: [
    //   null,
    //   null,
    //   (op) => {
    //     const dateIsPresent =
    //       op.dateOfAccrual instanceof Date || op.date instanceof Date;
    //     if (!dateIsPresent) return (errors) => `${errors.dateIsRequired}`;
    //   },
    // ],
  };

  protected importName = '1C BAS';

  protected doBeforeTranform(): AnyObject[] {
    const errors = this.getErrors();
    const HEADERS = Object.entries(this.headersJson);

    HEADERS.forEach(([hKey, hValue], index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(hValue))
          return (this.COLUMNS_MAP[key] = [hValue, hKey]);
      }
    });

    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const allColumns = this.getAllColumns(operation, index);
    const { date, currency, counterparty, comment, appointment } = allColumns;

    let isAfter, isBefore;
    try {
      isAfter = moment(date).isAfter(moment().add(10, 'y'));
      isBefore = moment(date).isBefore(moment('2015-01-01', 'YYYY/MM/DD'));
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const [type, subType, accountFromIdName, accountToIdName, sum] =
      this.getSumAndType(allColumns, index);

    const resO: AnyObject = {
      index,
      date: this.dateToFormat2(this.dateParser(date)),
      sum,
      type,
      subType,
    };

    this.addIfNotFalsy(resO, {
      counterparty,
      accountFromId: accountFromIdName,
      accountToId: accountToIdName,
    });

    const cur = currency
      ? CURRENCIES.find(
          (cur) =>
            cur.code === currency.toUpperCase() ||
            cur.native === currency.toUpperCase(),
        )?.code
      : 'UAH';

    this.setAccountIDsByType(resO, cur, cur);

    this.setComments(resO, [comment, appointment]);

    return resO;
  }

  private getSumAndType(
    { operationType, organization, sum, receipts, writeOff }: any,
    index: number,
  ) {
    let type;
    let subType;
    let accountFromIdName;
    let accountToIdName;
    let resultSum = Math.abs(this.parseSum(sum));

    if (isNaN(resultSum)) {
      resultSum = Math.abs(this.parseSum(receipts));
      type = isNaN(resultSum)
        ? OperationType.CONSUMPTION
        : OperationType.INCOME;
      subType = type === OperationType.INCOME ? 'sale' : 'supplier';
      if (isNaN(resultSum)) resultSum = Math.abs(this.parseSum(writeOff));
      if (isNaN(resultSum))
        this.throwError(`${sum} - ${this.getErrors().sumNotValid}`, index);
    } else {
      if (
        [
          'Продаж, комісія',
          'продажа, комиссия',
          'Прочее поступление безналичных денежных средств',
          'Оплата от покупателя',
          'Возврат денежных средств поставщиком',
          'Оплата від покупця',
          'Інше надходження',
          'Надходження',
          'Расчеты по кредитам и займам с контрагентами',
          'Возврат денежных средств поставщиком',
        ].some((opType) => opType === operationType)
      ) {
        type = OperationType.INCOME;
        subType = 'sale';
      }
      if (
        [
          'Прочее списание безналичных денежных средств',
          'Оплата поставщику',
          'Возврат денежных средств покупателю',
          'Перечисление налога',
          'Перевод на другой счет',
          'Устаткування',
          'Покупка, комісія',
          'Списання',
          'Оплата постачальнику',
          'Перерахування податків/внесків по заробітній платі',
          'Перечисление налогов/взносов по заработной плате',
          'Інше списання',
          'Перечисление заработной платы',
          'Перечисление денежных средств подотчетнику',
        ].some((opType) => opType === operationType)
      ) {
        type = OperationType.CONSUMPTION;
        subType = 'supplier';
      }
    }

    if (type === OperationType.INCOME) accountToIdName = organization;
    if (type === OperationType.CONSUMPTION) accountFromIdName = organization;

    return [type, subType, accountFromIdName, accountToIdName, resultSum];
  }

  private newAccounts = {};

  protected setAccountIDsByType(
    operation: AnyObject,
    currencyFrom,
    currencyTo,
  ): void {
    const to = operation.accountToId;
    const from = operation.accountFromId;
    delete operation.accountToId;
    delete operation.accountFromId;
    if (!operation.type)
      throw new Error('Call getAccountByType before get type');
    if (operation.type !== OperationType.CONSUMPTION && to) {
      const account = this.getAccountIdByName(
        this.acounts,
        to,
        currencyTo || currencyFrom,
      );
      if (typeof account !== 'string') {
        const sameNameExistingAccount = Object.keys(this.newAccounts).find(
          (acc) => acc === account.name,
        );
        if (
          sameNameExistingAccount &&
          this.newAccounts[sameNameExistingAccount].currencyId !==
            account.currencyId
        ) {
          operation.externalCurrencyCode = account.currencyId;
          // currencyDate...
          operation.accountToObject = this.newAccounts[sameNameExistingAccount];
        } else {
          this.newAccounts[account.name] = account;
          operation.accountToObject = account;
        }
      } else operation.accountToId = account;
    }
    if (operation.type !== OperationType.INCOME && from) {
      const account = this.getAccountIdByName(
        this.acounts,
        from,
        currencyFrom || currencyTo,
      );
      if (typeof account !== 'string') {
        const sameNameExistingAccount = Object.keys(this.newAccounts).find(
          (acc) => acc === account.name,
        );
        if (
          sameNameExistingAccount &&
          this.newAccounts[sameNameExistingAccount].currencyId !==
            account.currencyId
        ) {
          operation.externalCurrencyCode = account.currencyId;
          // currencyDate...
          operation.accountFromObject =
            this.newAccounts[sameNameExistingAccount];
        } else {
          this.newAccounts[account.name] = account;
          operation.accountFromObject = account;
        }
      } else operation.accountFromId = account;
    }
  }
}
