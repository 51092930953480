import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MercuryBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: moment(
              this.getFirstValidCellByColumn(['Date', 0]),
              'MM-DD-YYYY',
            ).toISOString(),
            counterparty: this.getFirstValidCellByColumn(['Description', 1]),
            sum: this.getFirstValidCellByColumn(['Amount', 2]),
            comment: this.getFirstValidCellByColumn(['Bank Description', 4]),
          });
        },
        caseOptions: { defaultCurrency: 'USD' },
      },
    ],
  };
}
