import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import facturowniaApi from '../../../../services/bankIntegrations/facturownia';
import { Seller } from '../../../../services/bankIntegrations/facturownia.types';
import { minOperationDate } from '../../../../utils/dateFormat';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import { showError } from '../../../../utils/showError';
import CalendarComponent from '../../../Operations/Components/CalendarComponent/CalendarComponent';
import { LoginProps } from '../types';

type Props = {
  loginData: LoginProps;

  onClose(): void;
  onSetSellers(value: Seller[]): void;
  onSetLoginData(field: keyof LoginProps, value: string | number): void;
};

const maxIntegrationDate =
  moment()
    .subtract(1, 'day')
    .set({ hours: 0, minutes: 0, seconds: 0 })
    .valueOf() + getTimeOffset();

function LoginDialog(props: Props) {
  const { loginData, onClose, onSetSellers, onSetLoginData } = props;

  const [userDomainError, setUserDomainError] = useState(false);
  const [userDomainErrorText, setUserDomainErrorText] = useState('');
  const [apiTokenError, setApiTokenError] = useState(false);
  const [apiTokenErrorText, setApiTokenErrorText] = useState('');
  const [commonError, setCommonError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleChangeDate = useCallback(
    (value: any) => {
      onSetLoginData('selectedDate', value);
    },
    [onSetLoginData],
  );

  const handleChangeUserDomain = useCallback(
    (value: string) => {
      onSetLoginData('userDomain', value);
      setUserDomainError(false);
    },
    [onSetLoginData],
  );

  const handleChangeApiToken = useCallback(
    (value: string) => {
      onSetLoginData('token', value);
      setApiTokenError(false);
    },
    [onSetLoginData],
  );

  const handleClick = useCallback(async () => {
    setCommonError(false);

    if (!loginData.userDomain) {
      setUserDomainError(true);
      setUserDomainErrorText(t('errors.reqField'));

      return;
    }

    if (!loginData.token) {
      setApiTokenError(true);
      setApiTokenErrorText(t('errors.reqField'));
      return;
    }

    setLoading(true);

    try {
      const { data: sellers }: AxiosResponse<{ sellers: Seller[] }> =
        await facturowniaApi.getSellers(
          loginData.token,
          loginData.userDomain
            .replace('http://', '')
            .replace('https://', '')
            .split('.')[0],
        );

      onSetSellers(sellers.sellers);
    } catch (error) {
      // @ts-ignore
      const message = error?.response?.data?.response?.error;
      // @ts-ignore
      const externalError = error?.response?.data?.response?.externalError;

      if (message === 'Bad userDomain') {
        setUserDomainError(true);
        setUserDomainErrorText(externalError);
      } else if (message === 'Bad token') {
        setApiTokenError(true);
        setApiTokenErrorText(t('bank.facturownia.badtoken'));
      } else {
        setCommonError(true);
      }

      showError(error);
    }

    setLoading(false);
  }, [t, loginData, onSetSellers]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.facturownia.authorization')}
    >
      <TextFieldComponent
        value={loginData.userDomain}
        onChange={handleChangeUserDomain}
        fullWidth
        placeholder={t('bank.facturownia.domen')}
        isError={userDomainError}
        errorText={userDomainErrorText}
      />
      <TextFieldComponent
        value={loginData.token}
        onChange={handleChangeApiToken}
        fullWidth
        placeholder={t('bank.facturownia.apiToken')}
        isError={apiTokenError}
        errorText={apiTokenErrorText}
      />
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        value={loginData.selectedDate}
        fullWidth
        maxDate={new Date(maxIntegrationDate)}
        minDate={minOperationDate()}
        fromIntegration
      />
      <CustomButton
        action={handleClick}
        fullWidth
        title={t('bank.facturownia.connectToLogin')}
        loading={loading}
        errorText={commonError ? t('system.error') : ''}
      />
    </Dialog>
  );
}

export default React.memo(LoginDialog);
