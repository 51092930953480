import { makeStyles } from '@material-ui/core/styles';

import {
  catskillWhite,
  persianGreen,
  white,
} from '../../../../../theme/colors';

export const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    top: -38,
    background: white,
    zIndex: 1001,
  },
  title: {
    marginBottom: 0,
  },
  categoryText: {
    fontWeight: 'bold',
    marginLeft: 12,
  },
  button: {
    width: 210,
  },
  row: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    cursor: 'pointer',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 8,
    },
  },
  subRow: {
    paddingLeft: 20,
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    width: `calc(100% - 25px)`,
  },
  rightBlock: {
    flex: 1,
  },
  opacity: {
    opacity: 0.5,
  },
  margin10: {
    marginRight: 10,
  },
  marginLeft35: {
    marginLeft: 35,
  },
  dialog: {
    height: 'calc(100% - 80px)',
    position: 'relative',
    borderRadius: 24,
  },
  openCategoriesText: {
    color: persianGreen,
    marginBottom: 18,
    cursor: 'pointer',
  },
  noSubCategoriesWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
    paddingTop: 18,
    paddingRight: 18,
    paddingBottom: 18,
    paddingLeft: 4,
    borderRadius: '12px',
    background: '#EDF4F7',
  },
  noSubCategoriesTitle: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20px',
    color: '#000000',
    marginLeft: 12,
    maxWidth: 322,
    width: '100%',
  },
  noSubCategoriesClose: {
    position: 'absolute',
    right: 19,
    top: 18,
    cursor: 'pointer',
  },
  shortNames: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    display: 'block',
    maxWidth: '200',
    width: `calc(100% - 13px)`,
  },
  subShortNames: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width: '78%',
  },
  subShortLength: {
    width: '78%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  disabled: {
    cursor: 'default !important',
  },
});
