import { all, put, takeLatest } from 'redux-saga/effects';

import { GET_SETTINGS_ACCOUNTS_PENDING } from '../store/accounts/actions';
import { categoryActionTypes } from '../store/categories/actions';
import { getClientsByTypeSettings } from '../store/clients/sagas';
import { UrlPrefix } from '../store/clients/types';
import {
  GET_SETTINGS_CATEGORIES,
  GET_SETTINGS_CONTEXTS,
} from '../store/company/actions';
import { GET_INVITES_PENDING } from '../store/employees/actions';
import { GET_SETTINGS_PROJECTS_PENDING } from '../store/projects/actions';
import { GET_SETTINGS_TAGS_PENDING } from '../store/tags/actions';
import { showError } from '../utils/showError';

function* getSettingsCategories() {
  try {
    yield put({ type: categoryActionTypes.GET_SETTINGS_INCOME_PENDING });
    yield put({ type: categoryActionTypes.GET_SETTINGS_CONSUMPTION_PENDING });
  } catch (error) {
    showError(error);
  }
}

export function* getSettingsContexts() {
  try {
    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });
    yield put({ type: GET_SETTINGS_TAGS_PENDING });
    yield put({ type: GET_SETTINGS_PROJECTS_PENDING });
    yield put({ type: GET_INVITES_PENDING });
    yield put({ type: categoryActionTypes.GET_SETTINGS_INCOME_PENDING });
    yield put({ type: categoryActionTypes.GET_SETTINGS_CONSUMPTION_PENDING });

    yield all(
      Object.keys(UrlPrefix).map((prefix) =>
        // @ts-ignore
        getClientsByTypeSettings({ payload: { prefix } }),
      ),
    );
  } catch (error) {
    showError(error);
  }
}

export default function settings() {
  return all([
    takeLatest(GET_SETTINGS_CONTEXTS, getSettingsContexts),
    takeLatest(GET_SETTINGS_CATEGORIES, getSettingsCategories),
  ]);
}
