import { makeStyles } from '@material-ui/core/styles';

import { black, white } from '../../../../theme/colors';

export const useStyles = makeStyles({
  dialog: {
    height: 'fit-content',
  },
  disableScroll: {
    overflow: 'hidden',
  },
  opacityTitle: {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: 'bold',
    opacity: 0.3,
    marginBottom: 16,
  },
  block: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 12,
    padding: '23px 0 20px 0',
    marginBottom: 24,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  margin15: {
    marginBottom: 15,
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paddingRow: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  selector: {
    display: 'flex',
    flex: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  titlePermissionsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 27,
  },
  titlePermissionsText: {
    fontSize: 11,
    lineHeight: '16px',
    opacity: 0.5,
    marginLeft: 40,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  subTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    flexGrow: 1,
  },
  subTitlePermissionsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemName: {
    fontSize: 15,
    lineHeight: '20px',
  },
  itemSubName: {
    fontSize: 13,
    lineHeight: '20px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  loaded: {
    transition: 'opacity 1s ease-in-out',
  },
  disabled: {
    opacity: 0.3,
  },
  wrapper: {
    position: 'absolute',
    zIndex: 100,
    background: white,
    height: '100%',
    width: '86%',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '70%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    marginBottom: 16,
  },
  grayButton: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
});

const pxToRem = (px: number, oneRemPx = 17) => `${px / oneRemPx}rem`;
const width = pxToRem(48);
const height = pxToRem(30);
const size = pxToRem(22);
const gap = (30 - 22) / 2;

export const useStylesSwitch = makeStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: 0,
    overflow: 'unset',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: 30,
    marginLeft: 12,
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: black,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: white,
    width: size,
    height: size,
  },
  checked: {},
}));
