import { Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectCryptoCurrenciesForOperationAutoComplete,
  selectCurrenciesForOperationAutoComplete,
} from '../../../../../../store/currency/selectors';
import PopperCurrencySelector from '../../../../../Operations/Components/PopperCurrencySelector';
import { useStyles } from './styles';
import { Props } from './types';

function CurrenciesSelector(props: Props) {
  const { onChange, currency } = props;

  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const [showList, setShowList] = useState(false);

  const currencies = useSelector(selectCurrenciesForOperationAutoComplete);
  const cryptoCurrencies = useSelector(
    selectCryptoCurrenciesForOperationAutoComplete,
  );

  const handleToggleCurrenciesList = useCallback(() => {
    setShowList(!showList);
  }, [showList]);

  const handleChangeCurrency = useCallback(
    (id) => {
      const value = [...currencies, ...cryptoCurrencies].find(
        (el) => el.id === id,
      );

      if (!value) {
        return;
      }

      onChange(value);
      handleToggleCurrenciesList();
    },
    [onChange, currencies, cryptoCurrencies, handleToggleCurrenciesList],
  );

  return (
    <>
      <div
        className={classes.currenciesContainer}
        ref={anchorRef}
        onClick={handleToggleCurrenciesList}
      >
        <div className={classes.wrapper}>
          <Typography>{`${currency.id} (${currency.symbol})`}</Typography>
          {showList && <ArrowDropUpIcon />}
          {!showList && <ArrowDropDownIcon />}
        </div>
      </div>
      {showList && (
        <PopperCurrencySelector
          onChangeCurrency={handleChangeCurrency}
          currencies={currencies}
          selectedCurrency={currency}
          cryptoCurrenciesList={cryptoCurrencies}
          onClose={handleToggleCurrenciesList}
          anchorRef={anchorRef}
        />
      )}
    </>
  );
}

export default React.memo(CurrenciesSelector);
