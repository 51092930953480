import { makeStyles } from '@material-ui/core/styles';

import { StyledProps } from '../../scenes/InfoBlock/Journal/JournalContainer/types';
import { black, catskillWhite } from '../../theme/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    cursor: 'pointer',
    borderBottom: (props: StyledProps) =>
      props.underLine ? '1px solid rgba(0, 0, 0, 0.08)' : 'none',
  },
  cursorDefault: {
    cursor: 'default',
  },
  icon: {
    marginRight: 20,
    width: 20,
    height: 20,
  },
  text: {
    fontSize: 15,
    lineHeight: '20px',
    color: black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: (props: StyledProps) => (props.fitContent ? 'fit-content' : '85%'),
  },
  flexRow: {
    display: 'flex',
    flex: 1,
  },
  rowWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingRight: 5,
    paddingLeft: 5,
    justifyContent: 'space-between',
    '&:hover': {
      background: catskillWhite,
      cursor: 'pointer',
      borderRadius: 8,
    },
  },
  disabled: {
    cursor: 'default',
    '&:hover': {
      background: 'unset',
      borderRadius: 0,
      cursor: 'default',
    },
  },
  labelIcon: {
    verticalAlign: 'middle',
    margin: '0 5px',
  },
  lastRow: {
    marginBottom: 16,
  },
});
