import { Typography } from '@material-ui/core';
import valid from 'card-validator';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import * as Yup from 'yup';

import CheckmarkIcon from '../../../../assets/images/svg/checkmark.svg';
import CopyIcon from '../../../../assets/images/svg/copy.svg';
import InformerIcon from '../../../../assets/images/svg/info.svg';
import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import {
  PRIVAT_24_MERCHANT_HELP_LINK_RU,
  PRIVAT_24_MERCHANT_HELP_LINK_UA,
} from '../../../../constants';
import { getLanguage } from '../../../../selectors/main';
import integrationActions from '../../../../store/integrations/actions';
import {
  getLoadingIntegrationCredentials,
  getPrivateCreateCredentialsError,
  selectLastIntegrationCredentials,
  selectServerIp,
} from '../../../../store/integrations/selectors';
import { minOperationDate } from '../../../../utils/dateFormat';
import { showError } from '../../../../utils/showError';
import CalendarComponent from '../../../Operations/Components/CalendarComponent/CalendarComponent';
import { useStyles } from './styles';
import { Props } from './types';

const validationSchema = Yup.object().shape({
  merchantId: Yup.string().required('errors.reqField'),
  password: Yup.string().required('errors.reqField'),
  selectedDate: Yup.string().required('errors.reqField'),
  cardNumber: Yup.string()
    .test(
      'test-number',
      'integrations.error.cardNumber',
      (value) => valid.number(value).isValid,
    )
    .required(),
});

function Private24CredentialsDialog(props: Props) {
  const { onClose, selectedDate, onChangeDate, onSetCurrencyId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [merchantId, setMerchantId] = useState('');
  const [password, setPassword] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [copied, setCopied] = useState(false);

  const [errorMerchantId, setErrorMerchantId] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorCardNumber, setErrorCardNumber] = useState('');
  const [errorDate, setErrorDate] = useState('');

  const language = useSelector(getLanguage);
  const serverIp = useSelector(selectServerIp);
  const privateCreateCredentialsError = useSelector(
    getPrivateCreateCredentialsError,
  );
  const integrationCredentials = useSelector(selectLastIntegrationCredentials);
  const loadingIntegrationCredentials = useSelector(
    getLoadingIntegrationCredentials,
  );

  const validateFields = useCallback(
    async () =>
      validationSchema
        .validate({ merchantId, password, cardNumber, selectedDate })
        .then(() => true)
        .catch((err) => {
          if (err.path === 'merchantId') {
            setErrorMerchantId(t(err.errors));
          }

          if (err.path === 'password') {
            setErrorPassword(t(err.errors));
          }

          if (err.path === 'cardNumber') {
            setErrorCardNumber(t(err.errors));
          }

          if (err.path === 'selectedDate') {
            setErrorDate(t(err.errors));
          }

          return false;
        }),
    [selectedDate, merchantId, password, cardNumber, t],
  );

  const handleChangeMerchantId = useCallback(
    (value: string) => {
      setMerchantId(value);
      setErrorMerchantId('');

      dispatch(integrationActions.resetIntegrationError());
    },
    [dispatch],
  );

  const handleChangePassword = useCallback(
    (value: string) => {
      setPassword(value);
      setErrorPassword('');

      dispatch(integrationActions.resetIntegrationError());
    },
    [dispatch],
  );

  const handleChangeCardNumber = useCallback(
    (value: string) => {
      setCardNumber(value);
      setErrorCardNumber('');

      dispatch(integrationActions.resetIntegrationError());
    },
    [dispatch],
  );

  const handleCreate = useCallback(async () => {
    if (await validateFields()) {
      dispatch(
        integrationActions.createPrivate24Credentials({
          merchantId,
          password,
          cardNumber,
        }),
      );
    }
  }, [dispatch, password, merchantId, cardNumber, validateFields]);

  const handleChangeDate = useCallback(
    (value: any) => {
      onChangeDate(value);
      setErrorDate('');

      dispatch(integrationActions.resetIntegrationError());
    },
    [onChangeDate, dispatch],
  );

  const handleCopied = useCallback((event: SyntheticEvent<{ id: string }>) => {
    const { id } = event.currentTarget;

    setCopied(true);

    try {
      copy(id);

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (e) {
      showError(e);
    }
  }, []);

  const handleClose = useCallback(() => {
    dispatch(integrationActions.resetIntegrationError());

    onClose();
  }, [onClose, dispatch]);

  useEffect(() => {
    if (integrationCredentials._id) {
      onSetCurrencyId(integrationCredentials.currencyId);
      onClose();
    }
  }, [integrationCredentials, onSetCurrencyId, onClose]);

  useEffect(() => {
    dispatch(integrationActions.getServerIp());
  }, [dispatch]);

  return (
    <Dialog isOpened onClose={handleClose} title={t('privat24.title')}>
      <Typography className={classes.step}>
        {`${t('privat24.step')} 1.`}
      </Typography>

      <Typography className={classes.text}>
        {`${t('privat24.description.createIn')} `}
        <a
          href="https://www.privat24.ua/"
          target="_blank"
          rel="noreferrer"
          className={classes.bold}
        >
          {t('bank.privat24')}
        </a>
        {` ${t('privat24.description.ofMerchant')}:`}
      </Typography>
      <ol className={cn(classes.descriptionContainer, classes.marginBottom16)}>
        <li className={classes.text}>{t('privat24.description.first')}</li>
        <li className={classes.text}>{t('privat24.description.second')}</li>
      </ol>

      <div
        id={serverIp}
        onClick={handleCopied}
        className={cn(classes.copyField, 'copy-field')}
        data-tip={t('telegramBot.makeCopy')}
      >
        <div className={classes.ipTextsBlock}>
          <Typography className={classes.label}>{t('common.ip')}</Typography>
          <Typography>{serverIp}</Typography>
        </div>
        {copied ? (
          <img src={CheckmarkIcon} alt="checkmark" />
        ) : (
          <img src={CopyIcon} alt="copy" />
        )}
      </div>
      <ReactTooltip
        className={classes.tooltipRoot}
        effect="solid"
        type="light"
        place="top"
        offset={{ right: 150 }}
        delayShow={1000}
      />

      <a
        href={
          language === 'ru'
            ? PRIVAT_24_MERCHANT_HELP_LINK_RU
            : PRIVAT_24_MERCHANT_HELP_LINK_UA
        }
        className={classes.link}
        target="_blank"
        rel="noreferrer"
      >
        {` ${t('privat24.description.instruction')}`}
      </a>

      <Typography className={classes.step}>
        {`${t('privat24.step')} 2.`}
      </Typography>

      <Typography className={cn(classes.text, classes.marginBottom16)}>
        {t('privat24.description.third')}
      </Typography>

      <TextFieldComponent
        value={merchantId}
        onChange={handleChangeMerchantId}
        label={t('integrations.merchantId')}
        placeholder={t('integrations.merchantId')}
        isError={Boolean(errorMerchantId)}
        errorText={errorMerchantId}
      />
      <TextFieldComponent
        value={cardNumber}
        onChange={handleChangeCardNumber}
        label={t('integrations.cardNumber')}
        placeholder={t('integrations.cardNumber')}
        isError={Boolean(errorCardNumber)}
        errorText={errorCardNumber}
      />
      <div className={classes.informerBlock}>
        <img
          src={InformerIcon}
          alt="informer"
          className={classes.informerIcon}
        />
        <Typography className={classes.informer}>
          {t('privat24.informer')}
        </Typography>
      </div>
      <TextFieldComponent
        value={password}
        type="password"
        onChange={handleChangePassword}
        label={t('integrations.password')}
        placeholder={t('integrations.password')}
        isError={Boolean(errorPassword)}
        errorText={errorPassword}
      />
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        // @ts-ignore
        value={selectedDate}
        fullWidth
        maxDate={new Date()}
        minDate={minOperationDate()}
        error={Boolean(errorDate)}
        fromIntegration
      />
      <CustomButton
        title={t('privat24.connect')}
        action={handleCreate}
        loading={loadingIntegrationCredentials}
        fullWidth
        errorText={
          (privateCreateCredentialsError &&
            t('integrations.error.privateCreateCredentialsError')) ||
          ''
        }
      />
    </Dialog>
  );
}

export default React.memo(Private24CredentialsDialog);
