export type Value = {
  value: string;
  label: string;
};

export type AutoCompleteProps<T = {}> = T & {
  id: string;
  name: string;
  label?: string;
  icon?: any;
};

export enum Context {
  log = 'log',
  settings = 'settings',
  operation = 'operation',
}

export type ValueByContext<T> = {
  log: T[];
  fullLog: T[];
  operation: T[];
  settings: T[];
};

export type SelectedProps = {
  [key in string]: string[];
};

export enum SubscriptionType {
  Trial = 'Trial',
  Lite = 'Lite',
  Pro = 'Pro',
}

export enum SubscriptionPeriods {
  price1m = 'price1m',
  price3m = 'price3m',
  price6m = 'price6m',
  price12m = 'price12m',
}

export type SubscriptionPeriod = {
  id: SubscriptionPeriods;
  value: number;
  title: string;
};

export type SubscriptionTariff = {
  code: string;
  createdAt: string;
  label: SubscriptionType;
  orderIndex: number;
  price1m: number;
  price3m: number;
  price6m: number;
  price12m: number;
  price7mPerM: number;
  price8mPerM: number;
  price13mPerM: number;
  price14mPerM: number;
  updatedAt: string;
  visible: boolean;
  currencySymbol?: string;
  __v: number;
  _id: string;
};

export type TariffsToId = {
  [key in SubscriptionType]: {
    id: string;
    [SubscriptionPeriods.price1m]: number;
    [SubscriptionPeriods.price3m]: number;
    [SubscriptionPeriods.price6m]: number;
    [SubscriptionPeriods.price12m]: number;
  };
};
