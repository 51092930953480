import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import moment from 'moment';

export class OTPHungaryImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentBody(importDocument);
          return (): ImportResultItemMask => ({
            currency: this.getFirstValidCellByColumn([3]),
            date: this.getFirstValidCellByColumn([5]),
            comment: [
              this.getFirstValidCellByColumn([8]),
              this.getFirstValidCellByColumn([9]),
            ].join(' '),
            sum: this.getFirstValidCellByColumn([2]),
          });
        },
        caseOptions: {
          defaultCurrency: 'HUF',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const dateAndCounterpartyArray = this.getFirstValidCellByColumn([
              'Date of transaction\nOpposite name',
              1,
            ])?.split('\n');
            const ammountAndNoticeArr = this.getFirstValidCellByColumn([
              'Amount\nNotice',
              3,
            ])?.split('\n');
            const dateAndTime = dateAndCounterpartyArray.slice(0, 2).join(' ');
            const sum = ammountAndNoticeArr[0].replace('.', '');
            const comment = dateAndCounterpartyArray
              .slice(2)
              .concat(ammountAndNoticeArr.slice(1))
              .join(' ');
            return {
              dateAndTime,
              sum,
              comment,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'HUF',
          preParserConfigs: {
            interlineSpacingAccuracy: 40,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Type of transaction') &&
                  etc?.nextWord?.includes('Date of transaction'),
                (word, etc) =>
                  word?.includes('Notice') &&
                  etc?.prevWord?.includes('Opposite name'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('Notice') &&
                  etc?.prevWord?.includes('Opposite name'),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word?.startsWith('https://www.otpbankdirekt'),
                (word, etc) =>
                  word?.includes('Notice') &&
                  etc?.prevWord?.includes('Opposite name'),
              );
              self.deleteFromTo(
                (word, etc) => word?.startsWith('https://www.otpbankdirekt'),
                undefined,
              );
              self.defineOperation([
                (value) => moment(value, 'YYYY.MM.DD.', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
