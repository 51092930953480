import { Client, CreateClientResponse, UrlPrefix } from './types';

export enum types {
  GET_CLIENTS_BY_TYPE_PENDING_SETTINGS = 'GET_CLIENTS_BY_TYPE_PENDING_SETTINGS',
  GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS = 'GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS',
  GET_CLIENTS_BY_TYPE_PENDING_LOG = 'GET_CLIENTS_BY_TYPE_PENDING_LOG',
  CREATE_CLIENT_BY_TYPE_SETTINGS_PENDING = 'CREATE_CLIENT_BY_TYPE_SETTINGS_PENDING',
  CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING = 'CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING',
  UPDATE_CLIENT_BY_TYPE_PENDING = 'UPDATE_CLIENT_BY_TYPE_PENDING',
  DELETE_CLIENT_BY_TYPE_PENDING = 'DELETE_CLIENT_BY_TYPE_PENDING',
  RESET_CLIENTS = 'RESET_CLIENTS',
  OPERATION_TO_TRANSFER = 'OPERATION_TO_TRANSFER',
  GET_SETTINGS_CLIENTS = 'GET_SETTINGS_CLIENTS',
}

const createClient = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.clients,
  },
});

const createInvestor = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.investors,
  },
});
const createCreditor = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.creditors,
  },
});
const createBorrower = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.borrowers,
  },
});
const createOwner = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.owners,
  },
});
const createSupplier = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.suppliers,
  },
});

const createStaffMember = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.staffMembers,
  },
});

const createTaxOrganisation = (name: string): CreateClientResponse => ({
  type: types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
  payload: {
    name,
    prefix: UrlPrefix.taxOrganisations,
  },
});

const getSettingsClients = () => ({
  type: types.GET_SETTINGS_CLIENTS,
});

const getOperationClients = (prefix: UrlPrefix) => ({
  type: types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
  payload: { prefix },
});

const updateGroupClients = (clients: Client[], prefix: UrlPrefix) => ({
  type: types.UPDATE_CLIENT_BY_TYPE_PENDING,
  payload: { clients, prefix },
});

const createClientByPrefix = (name: string, prefix: UrlPrefix) => ({
  type: types.CREATE_CLIENT_BY_TYPE_SETTINGS_PENDING,
  payload: { name, prefix },
});

const deleteClient = (id: string, prefix: UrlPrefix) => ({
  type: types.DELETE_CLIENT_BY_TYPE_PENDING,
  payload: { id, prefix },
});

export default {
  createOwner,
  createClient,
  deleteClient,
  createSupplier,
  createCreditor,
  createBorrower,
  createInvestor,
  createStaffMember,
  getSettingsClients,
  updateGroupClients,
  getOperationClients,
  createClientByPrefix,
  createTaxOrganisation,
};
