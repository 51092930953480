import { FormikBag, withFormik } from 'formik';
import * as Yup from 'yup';

import { ChangePasswordFormValues } from './types';
import { User } from '../../types/auth';

type Props = {
  user: User | null;
  onSubmit: (data: ChangePasswordFormValues) => void;
};

const formik = withFormik<Props, ChangePasswordFormValues>({
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string().required('errors.reqField'),
    newPassword: Yup.string().required('errors.reqField'),
  }),

  mapPropsToValues: () => ({
    oldPassword: '',
    newPassword: '',
  }),

  handleSubmit: async (
    form: ChangePasswordFormValues,
    {
      props: { onSubmit },
      setErrors,
      setSubmitting,
    }: FormikBag<Props, ChangePasswordFormValues>,
  ) => {
    try {
      await onSubmit(form);
    } catch (error: any) {
      setSubmitting(false);
      setErrors({
        oldPassword: !error.auth ? 'errors.wrongPassword' : undefined,
      });
    }
    setSubmitting(false);
  },
  displayName: 'ForgotForm',
});

export default formik;
