import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { EMPTY } from '../../constants';
import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import {
  DeleteCategory,
  UpdateCategoriesPayload,
} from '../company/sagas.types';
import { SET_CUSTOM_FILTER } from '../filters/actions';
import {
  isCustomFiltersActive,
  selectCustomFilters,
} from '../filters/selectors';
import { FiltersState } from '../filters/types';
import { OperationType } from '../operations/types';
import { Context } from '../types';
import { categoryActionTypes } from './actions';
import categoriesApi from './api';
import { GetLogCategoriesPayload } from './sagas.types';
import {
  selectFullLogConsumptionCategories,
  selectFullLogIncomeCategories,
  selectPLConsumptionCategoriesIds,
  selectPLIncomeCategoriesIds,
} from './selectors';
import { Category } from './types';

const concatCategoriesIds = (data: Category[]): string[] =>
  data.reduce((acc: string[], el) => {
    if (!el.subcategories?.length) {
      if (el._id !== EMPTY) {
        return [...acc, el._id];
      }

      return acc;
    }

    acc.push(el._id);

    el.subcategories.forEach((subEl) => {
      acc.push(subEl._id);
    });

    return acc;
  }, []);

function* setLogCategories(
  action: TAction<{ selector: keyof FiltersState }>,
): SagaIterator {
  const { selector } = action.payload;

  const isCustomFilterActive = yield select(isCustomFiltersActive(selector));
  const plIncomeCatsIds = yield select(selectPLIncomeCategoriesIds);
  const plConsumptionCatsIds = yield select(selectPLConsumptionCategoriesIds);

  const incomeCats = yield select(selectFullLogIncomeCategories);
  const consumptionCats = yield select(selectFullLogConsumptionCategories);

  const incomeCategoryIds =
    selector === 'profitAndLoss' || selector === 'tableProfitAndLoss'
      ? plIncomeCatsIds
      : concatCategoriesIds(incomeCats);

  const consumptionCategoryIds =
    selector === 'profitAndLoss' || selector === 'tableProfitAndLoss'
      ? plConsumptionCatsIds
      : concatCategoriesIds(consumptionCats);

  const categoryIds: string[] = incomeCategoryIds.concat(
    consumptionCategoryIds,
  );

  const { categoriesByIds, categoriesByType } = yield select(
    selectCustomFilters(selector),
  );

  const unitedIds = categoryIds.reduce((acc: string[], el) => {
    if (!categoriesByIds.includes(el)) {
      return [...acc, el];
    }

    return acc;
  }, []);

  if (isCustomFilterActive) {
    const ids: string[] = [...categoriesByIds];

    if (categoriesByType.includes(OperationType.income)) {
      ids.push(...incomeCategoryIds);
    }

    if (categoriesByType.includes(OperationType.consumption)) {
      ids.push(...consumptionCategoryIds);
    }

    yield put({
      type: SET_CUSTOM_FILTER,
      payload: { data: ids, filterType: 'categoriesByIds', selector },
    });
  } else {
    const ids = categoriesByIds.concat(unitedIds);

    yield put({
      type: SET_CUSTOM_FILTER,
      payload: { data: ids, filterType: 'categoriesByIds', selector },
    });
  }
}

function* getLogCategories(
  action: TAction<{ selector: keyof FiltersState }>,
): SagaIterator {
  try {
    const selector = action.payload?.selector;

    const requests = yield all([
      call(categoriesApi.getIncomeCategories, Context.log),
      call(categoriesApi.getConsumptionCategories, Context.log),
    ]);

    const income = requests[0].data;
    const consumption = requests[1].data;

    yield put({
      type: categoryActionTypes.GET_LOG_INCOME_SUCCESS,
      payload: { data: income },
    });

    yield put({
      type: categoryActionTypes.GET_LOG_CONSUMPTION_SUCCESS,
      payload: { data: consumption },
    });

    if (selector === 'cashFlow') {
      yield put({
        type: categoryActionTypes.SET_LOG_CATEGORIES,
        payload: { selector: 'tableCashFlow' },
      });
    } else if (selector === 'profitAndLoss') {
      yield put({
        type: categoryActionTypes.SET_LOG_CATEGORIES,
        payload: { selector: 'tableProfitAndLoss' },
      });
    } else {
      yield put({
        type: categoryActionTypes.SET_LOG_CATEGORIES,
        payload: { selector },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* getLogCategoriesByType(action: TAction<GetLogCategoriesPayload>) {
  const { type } = action.payload;
  try {
    const { data } = yield call(
      type === OperationType.income
        ? categoriesApi.getIncomeCategories
        : categoriesApi.getConsumptionCategories,
      Context.log,
    );

    yield put({
      type:
        type === OperationType.income
          ? categoryActionTypes.GET_LOG_INCOME_SUCCESS
          : categoryActionTypes.GET_LOG_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getOperationIncomes() {
  try {
    const { data } = yield call(
      categoriesApi.getIncomeCategories,
      Context.operation,
    );

    yield put({
      type: categoryActionTypes.GET_OPERATION_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getSettingsIncomes() {
  try {
    const { data } = yield call(
      categoriesApi.getIncomeCategories,
      Context.settings,
    );

    yield put({
      type: categoryActionTypes.GET_SETTINGS_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getOperationConsumptions() {
  try {
    const { data } = yield call(
      categoriesApi.getConsumptionCategories,
      Context.operation,
    );

    yield put({
      type: categoryActionTypes.GET_OPERATION_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* getSettingsConsumptions() {
  try {
    const { data } = yield call(
      categoriesApi.getConsumptionCategories,
      Context.settings,
    );

    yield put({
      type: categoryActionTypes.GET_SETTINGS_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createLogIncome({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createIncomeCategory,
      name,
      Context.log,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_LOG_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createOperationIncome({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createIncomeCategory,
      name,
      Context.operation,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_OPERATION_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createSettingsIncome({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createIncomeCategory,
      name,
      Context.settings,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_SETTINGS_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createLogConsumption({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createConsumptionCategory,
      name,
      Context.log,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_LOG_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createOperationConsumption({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createConsumptionCategory,
      name,
      Context.operation,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_OPERATION_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* createSettingsConsumption({
  payload: { name, parentId = undefined },
}: any) {
  try {
    const { data } = yield call(
      categoriesApi.createConsumptionCategory,
      name,
      Context.settings,
      parentId,
    );

    yield put({
      type: categoryActionTypes.CREATE_SETTINGS_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* deleteIncome({ payload: { id } }: TAction<DeleteCategory>) {
  try {
    const { data } = yield call(categoriesApi.deleteIncomeCategory, id);

    yield put({
      type: categoryActionTypes.DELETE_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* deleteConsumption({
  payload: { id },
}: TAction<DeleteCategory>) {
  try {
    const { data } = yield call(categoriesApi.deleteConsumptionCategory, id);

    yield put({
      type: categoryActionTypes.DELETE_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateIncome({
  payload: { categories },
}: TAction<UpdateCategoriesPayload>) {
  try {
    yield all(
      categories.map((category) =>
        call(categoriesApi.updateIncomeCategory, category),
      ),
    );

    const { data } = yield call(
      categoriesApi.getIncomeCategories,
      Context.settings,
    );

    yield put({
      type: categoryActionTypes.GET_SETTINGS_INCOME_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateConsumption({
  payload: { categories },
}: TAction<UpdateCategoriesPayload>) {
  try {
    yield all(
      categories.map((category) =>
        call(categoriesApi.updateConsumptionCategory, category),
      ),
    );

    const { data } = yield call(
      categoriesApi.getConsumptionCategories,
      Context.settings,
    );

    yield put({
      type: categoryActionTypes.GET_SETTINGS_CONSUMPTION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export default function categoriesSaga() {
  return all([
    takeLatest(
      categoryActionTypes.GET_LOG_CATEGORIES_PENDING,
      getLogCategories,
    ),
    takeLatest(
      categoryActionTypes.GET_LOG_CATEGORIES_BY_TYPE,
      getLogCategoriesByType,
    ),
    takeLatest(
      categoryActionTypes.GET_OPERATION_INCOME_PENDING,
      getOperationIncomes,
    ),
    takeLatest(
      categoryActionTypes.GET_SETTINGS_INCOME_PENDING,
      getSettingsIncomes,
    ),
    takeLatest(
      categoryActionTypes.GET_OPERATION_CONSUMPTION_PENDING,
      getOperationConsumptions,
    ),
    takeLatest(
      categoryActionTypes.GET_SETTINGS_CONSUMPTION_PENDING,
      getSettingsConsumptions,
    ),
    takeLatest(categoryActionTypes.CREATE_LOG_INCOME_PENDING, createLogIncome),
    takeLatest(
      categoryActionTypes.CREATE_OPERATION_INCOME_PENDING,
      createOperationIncome,
    ),
    takeLatest(
      categoryActionTypes.CREATE_SETTINGS_INCOME_PENDING,
      createSettingsIncome,
    ),
    takeLatest(
      categoryActionTypes.CREATE_LOG_CONSUMPTION_PENDING,
      createLogConsumption,
    ),
    takeLatest(
      categoryActionTypes.CREATE_OPERATION_CONSUMPTION_PENDING,
      createOperationConsumption,
    ),
    takeLatest(
      categoryActionTypes.CREATE_SETTINGS_CONSUMPTION_PENDING,
      createSettingsConsumption,
    ),
    takeLatest(categoryActionTypes.DELETE_INCOME_PENDING, deleteIncome),
    takeLatest(
      categoryActionTypes.DELETE_CONSUMPTION_PENDING,
      deleteConsumption,
    ),
    takeLatest(categoryActionTypes.UPDATE_INCOME_PENDING, updateIncome),
    takeLatest(
      categoryActionTypes.UPDATE_CONSUMPTION_PENDING,
      updateConsumption,
    ),
    takeLatest(categoryActionTypes.SET_LOG_CATEGORIES, setLogCategories),
  ]);
}
