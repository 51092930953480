import { useMemo } from 'react';

import {
  SHOW_BANK_EBURY_INTEGRATION,
  SHOW_BANK_ING_INTEGRATION,
  SHOW_BANK_INTELIGO_INTEGRATION,
  SHOW_BANK_MILLENNIUM_INTEGRATION,
  SHOW_ING_SALTEDGE,
  SHOW_NEST_BANK_CORPORATE_INTEGRATION,
  SHOW_NEST_BANK_PRIVATE_INTEGRATION,
  SHOW_SANTANDER_BANK_CORPORATE_INTEGRATION,
  SHOW_SANTANDER_BANK_INTEGRATION,
} from '../constants/featureToggles/integrationsFeatureToggles';
import useUnleash from './useUnleash';

function useNordigenIntegration() {
  const showBankMillenniumIntegration = useUnleash(
    SHOW_BANK_MILLENNIUM_INTEGRATION,
  );
  const showBankEburyIntegration = useUnleash(SHOW_BANK_EBURY_INTEGRATION);
  const showBankINGIntegration = useUnleash(SHOW_BANK_ING_INTEGRATION);
  const showBankInteligoIntegration = useUnleash(
    SHOW_BANK_INTELIGO_INTEGRATION,
  );
  const showNestBankCorporateIntegration = useUnleash(
    SHOW_NEST_BANK_CORPORATE_INTEGRATION,
  );
  const showNestBankPrivateIntegration = useUnleash(
    SHOW_NEST_BANK_PRIVATE_INTEGRATION,
  );
  const showSantanderBankIntegration = useUnleash(
    SHOW_SANTANDER_BANK_INTEGRATION,
  );
  const showSantanderBankCorporateIntegration = useUnleash(
    SHOW_SANTANDER_BANK_CORPORATE_INTEGRATION,
  );
  const showINGsaltedge = useUnleash(SHOW_ING_SALTEDGE);

  return useMemo(
    () => [
      {
        name: 'Bank Millennium',
        enabled: showBankMillenniumIntegration,
      },
      {
        name: 'Ebury',
        enabled: showBankEburyIntegration,
      },
      {
        name: 'ING',
        enabled: showBankINGIntegration && !showINGsaltedge,
      },
      {
        name: 'Inteligo',
        enabled: showBankInteligoIntegration,
      },
      {
        name: 'Nest Bank Corporate',
        enabled: showNestBankCorporateIntegration,
      },
      {
        name: 'Nest Bank Private',
        enabled: showNestBankPrivateIntegration,
      },
      {
        name: 'Santander Bank',
        enabled: showSantanderBankIntegration,
      },
      {
        name: 'Santander Bank Corporate',
        enabled: showSantanderBankCorporateIntegration,
      },
    ],
    [
      showINGsaltedge,
      showBankINGIntegration,
      showBankEburyIntegration,
      showBankInteligoIntegration,
      showSantanderBankIntegration,
      showBankMillenniumIntegration,
      showNestBankPrivateIntegration,
      showNestBankCorporateIntegration,
      showSantanderBankCorporateIntegration,
    ],
  );
}

export default useNordigenIntegration;
