export enum StorageKey {
  language = 'finmap-language',
  user = 'finmap-user',
  version = 'finmap-version',
  isFirstEntry = 'isFirstEntry',
  saltedge = 'finmap-saltedge',
  nordigen = 'finmap-nordigen',
  ukrsib = 'finmap-ukrsib',
  fondy = 'finmap-fondy',
  poster = 'finmap-poster',
  payoneer = 'finmap-payoneer',
  belvo = 'finmap-belvo',
  ukrsibSecondStep = 'finmap-ukrsibSecondStep',
  currenUserCustomFilter = 'finmap-currenUserCustomFilter',
  openDemoLink = 'finmap-openDemoLink',
  settingsDeepLinks = 'finmap-settingsDeepLinks',
  grsf = 'finmap-grsf',
  leftMenuStatus = 'finmap-leftMenuStatus',
  onboardingLastCompanyId = 'finmap-onboardingLastCompanyId',
  onboardingLastStepNumber = 'finmap-onboardingLastStepNumber',
  onboardingCompaniesIds = 'finmap-onboardingCompaniesIds',
  amountEmoji = 'finmap-amountEmoji',
  paymentEmoji = 'finmap-paymentEmoji',
  giftTooltip = 'finmap-giftTooltip',
}

type ArrayStore = {
  [key: string]: string | number | Record<string, unknown> | [];
};

class Storages {
  get = (name: string) => {
    if (typeof window === 'undefined') return null;

    try {
      return JSON.parse(localStorage.getItem(name) as string);
    } catch (e) {
      return localStorage.getItem(name);
    }
  };

  put = (
    name: string,
    value: null | Record<string, unknown> | string | [ArrayStore] | [],
  ) => {
    if (typeof window === 'undefined') return;

    localStorage.setItem(name, JSON.stringify(value));
  };

  remove = (name: string) => {
    if (typeof window === 'undefined') return;

    localStorage.removeItem(name);
  };
}

export default new Storages();
