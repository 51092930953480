import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';

import { types } from '../../../../../store/clients/actions';
import {
  selectSettingsBorrowers,
  selectSettingsClients,
  selectSettingsCreditors,
  selectSettingsInvestors,
  selectSettingsOwners,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsTaxOrganisations,
} from '../../../../../store/clients/selectors';
import { UrlPrefix } from '../../../../../store/clients/types';
import {
  RESET_CREATE_ELEMENT_ERROR,
  SET_CREATE_ELEMENT_ERROR,
} from '../../../../../store/common/actions';
import {
  getCreateElementError,
  getCreateElementLoading,
} from '../../../../../store/common/selectors';
import {
  CREATE_SETTINGS_PROJECT_PENDING,
  DELETE_PROJECT_PENDING,
  UPDATE_GROUP_PROJECTS_PENDING,
} from '../../../../../store/projects/actions';
import { selectSettingsProjects } from '../../../../../store/projects/selectors';
import { AppState } from '../../../../../store/reducers';
import {
  CREATE_SETTINGS_TAG_PENDING,
  DELETE_TAG_PENDING,
  UPDATE_GROUP_TAGS_PENDING,
} from '../../../../../store/tags/actions';
import { selectSettingsTags } from '../../../../../store/tags/selectors';
import { DispatchedProps, MappedProps } from './types';

const mapStateToProps = (state: AppState): MappedProps => ({
  tags: selectSettingsTags(state),
  projects: selectSettingsProjects(state),
  clients: selectSettingsClients(state),
  investors: selectSettingsInvestors(state),
  borrowers: selectSettingsBorrowers(state),
  creditors: selectSettingsCreditors(state),
  owners: selectSettingsOwners(state),
  staffMembers: selectSettingsStaffMembers(state),
  suppliers: selectSettingsSuppliers(state),
  taxOrganisations: selectSettingsTaxOrganisations(state),
  isError: getCreateElementError(state),
  isLoading: getCreateElementLoading(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { prefix }: { prefix?: UrlPrefix },
): DispatchedProps => ({
  setCreateError: () => dispatch({ type: SET_CREATE_ELEMENT_ERROR }),
  resetCreateError: () => dispatch({ type: RESET_CREATE_ELEMENT_ERROR }),
  createTag: (label) =>
    dispatch({ type: CREATE_SETTINGS_TAG_PENDING, payload: { label } }),
  updateGroupTags: (tags) =>
    dispatch({ type: UPDATE_GROUP_TAGS_PENDING, payload: { tags } }),
  deleteTag: (id) => dispatch({ type: DELETE_TAG_PENDING, payload: { id } }),

  createProject: (name) =>
    dispatch({ type: CREATE_SETTINGS_PROJECT_PENDING, payload: { name } }),
  updateGroupProjects: (projects) =>
    dispatch({ type: UPDATE_GROUP_PROJECTS_PENDING, payload: { projects } }),
  deleteProject: (id) =>
    dispatch({ type: DELETE_PROJECT_PENDING, payload: { id } }),

  createClient: (name) =>
    dispatch({
      type: types.CREATE_CLIENT_BY_TYPE_SETTINGS_PENDING,
      payload: {
        name,
        prefix,
      },
    }),
  deleteClient: (id) =>
    dispatch({
      type: types.DELETE_CLIENT_BY_TYPE_PENDING,
      payload: {
        id,
        prefix,
      },
    }),
  updateGroupClients: (clients) =>
    dispatch({
      type: types.UPDATE_CLIENT_BY_TYPE_PENDING,
      payload: {
        clients,
        prefix,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps);
