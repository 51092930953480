import moment from 'moment';
import { CURRENCIES } from './datasets/currencies';

export const isEmptyImportField = (value: unknown): boolean => {
  return (
    value === '' ||
    value === null ||
    value === undefined ||
    (typeof value === 'number' && isNaN(value)) ||
    value === 'Invalid Date' ||
    value === 'Invalid date'
  );
};

export const parseDate = (inputStr: unknown): Date => {
  let parsedStr = inputStr;
  if (typeof inputStr !== 'string') {
    try {
      parsedStr = String(inputStr).trim();
    } catch (error) {
      console.log(error);
    }
  }
  parsedStr = (parsedStr as string).replace(/ {2,}/g, ' ');

  const customFormats = [
    'D-M-YYYY',
    'D-M-YYYY H:m:s',
    'D-M-YYYY H/m/s',
    'D-M-YYYY H-m-s',
    'D-M-YYYY H:m',
    'D-M-YYYY H/m',
    'D-M-YYYY H-m',
    'D/M/YYYY',
    'D/M/YYYY H:m:s',
    'D/M/YYYY H/m/s',
    'D/M/YYYY H-m-s',
    'D/M/YYYY H:m',
    'D/M/YYYY H/m',
    'D/M/YYYY H-m',
    'D.M.YYYY',
    'D.M.YYYY H:m:s',
    'D.M.YYYYY H/m/s',
    'D.M.YYYY H-m-s',
    'D.M.YYYY H:m',
    'D.M.YYYYY H/m',
    'D.M.YYYY H-m',

    'D-M-YY',
    'D-M-YY H:m:s',
    'D-M-YY H/m/s',
    'D-M-YY H-m-s',
    'D-M-YY H:m',
    'D-M-YY H/m',
    'D-M-YY H-m',
    'D/M/YY',
    'D/M/YY H:m:s',
    'D/M/YY H/m/s',
    'D/M/YY H-m-s',
    'D/M/YY H:m',
    'D/M/YY H/m',
    'D/M/YY H-m',
    'D.M.YY',
    'D.M.YY H:m:s',
    'D.M.YY H/m/s',
    'D.M.YY H-m-s',
    'D.M.YY H:m',
    'D.M.YY H/m',
    'D.M.YY H-m',
  ];
  if (moment(parsedStr, customFormats, true).isValid())
    return moment(parsedStr, customFormats, true).toDate();

  return moment(parsedStr).toDate();
};

export const parseSum = (rawSum: unknown): number => {
  let strArray = (JSON.stringify(rawSum) + '').split('');

  // for such case: '28,800.00'
  const dotAndCommaPresent =
    strArray.find((s) => s === ',') && strArray.find((s) => s === '.');
  if (dotAndCommaPresent) {
    const commaPresentFirst =
      strArray.find((s) => s === ',' || s === '.') === ',';
    if (commaPresentFirst) strArray = strArray.filter((s) => s !== ',');
    else strArray = strArray.filter((s) => s !== '.');
  }

  const result = strArray.reduce((acc, cur) => {
    if (acc === '' && cur === '-') return (acc += '-');
    if (cur === ',' || cur === '.') {
      acc += '.';
      return acc;
    }
    const num = Number(cur);
    const curCode = cur.charCodeAt(0);
    if (!isNaN(num) && curCode !== 160 && cur !== ' ') acc += num;
    return acc;
  }, '');
  if (result.split('.').length > 2) return NaN;
  return parseFloat(result);
};

export const parseCurrency = (currency: unknown): string => {
  return currency
    ? CURRENCIES.find(
        (cur) =>
          cur.code === currency.toString().toUpperCase() ||
          cur.native === currency.toString().toUpperCase() ||
          cur.symbol === currency.toString().toUpperCase(),
      )?.code
    : '';
};

export const findFirstMatch = (
  input: string,
  regex: RegExp,
): string | undefined => {
  const matches = input.match(regex);
  return matches ? matches[0] : undefined;
};
