import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../components/TextField/TextFieldComponent.types';
import countriesData from '../../../constants/countriesV2.json';
import {
  getUserGeoData,
  selectActivitiesAutoComplete,
} from '../../../store/common/selectors';
import {
  selectCurrenciesAutoComplete,
  selectUSDCurrency,
} from '../../../store/currency/selectors';
import { AutoCompleteProps } from '../../../store/types';
import { useStyles } from './styles';
import { Props } from './types';

function CreateDialog(props: Props) {
  const { onClose, onCreate, blurBackground } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const currencies = useSelector(selectCurrenciesAutoComplete);
  const activities = useSelector(selectActivitiesAutoComplete);
  const userGeoData = useSelector(getUserGeoData);
  const defaultCurrency = useSelector(selectUSDCurrency);

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [activity, setActivity] = useState<AutoCompleteProps | null>(null);
  const [currency, setCurrency] = useState<
    (AutoCompleteProps & { symbol: string }) | null
  >(null);
  const [loadingCreateCompany, setLoadingCreateCompany] = useState(false);

  const handleChangeName = useCallback((val: string) => {
    setErrorName(false);
    setName(val);
  }, []);

  const handleChangeCurrency = useCallback(
    (val: AutoCompleteProps & { symbol: string }) => {
      setCurrency(val);
    },
    [],
  );

  const handleChangeActivity = useCallback((val: AutoCompleteProps) => {
    setActivity(val);
  }, []);

  const handleCreate = useCallback(() => {
    if (!name) {
      setErrorName(true);

      return;
    }

    setLoadingCreateCompany(true);

    if (currency) {
      onCreate(name, currency.id, activity?.id);
    }
  }, [onCreate, name, currency, activity]);

  useEffect(() => {
    if (userGeoData) {
      const countryCode = userGeoData.country;
      const country = countriesData.find((el) => el.alpha2Code === countryCode);

      if (!country) {
        setCurrency({ ...defaultCurrency, id: defaultCurrency._id });

        return;
      }

      if (country.currencies.length === 1) {
        const currencySymbol = country.currencies[0].code;

        const userDefaultCurrencyByLocation =
          currencies?.find((el) => el.id === currencySymbol) ||
          (defaultCurrency && { ...defaultCurrency, id: defaultCurrency._id });

        setCurrency(userDefaultCurrencyByLocation);
      } else {
        for (let i = 0; i < country.currencies.length; i += 1) {
          const currencySymbol = country.currencies[i].code;

          const userDefaultCurrencyByLocation = currencies.find(
            (cur) => cur.id === currencySymbol,
          );

          if (userDefaultCurrencyByLocation) {
            setCurrency(userDefaultCurrencyByLocation);

            break;
          }
        }
      }
    }
  }, [currencies, defaultCurrency, userGeoData]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('company.new')}
      wrapperContainerClasses={cn(blurBackground && classes.blurBackground)}
    >
      <TextFieldComponent
        ga4Id={Ga4Errors.createCompanyNameError}
        id="cypress-company-name"
        onChange={handleChangeName}
        value={name}
        placeholder={t('common.title')}
        isError={errorName}
        errorText={t('system.fieldMustFilled')}
      />
      <AutocompleteSelector
        id="cypress-company-currency-selector"
        label={t('currency.mainCurrency')}
        value={currency}
        onChange={handleChangeCurrency}
        data={currencies}
      />
      <>
        <AutocompleteSelector
          id="cypress-company-activities-selector"
          label={t('demoCompany.activity.title')}
          value={activity}
          onChange={handleChangeActivity}
          data={activities}
        />
        <Typography
          className={cn(classes.helperText, classes.helperActivityText)}
        >
          {t('createCompany.activityTitle')}
        </Typography>
      </>
      <CustomButton
        id="create_campaign_menu"
        ga4TextId="create_campaign_menu_text"
        action={handleCreate}
        title={t('company.create')}
        fullWidth
        loading={loadingCreateCompany}
      />
    </Dialog>
  );
}

export default React.memo(CreateDialog);
