import CustomButton from "../../../../components/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import React from 'react';
import { useTranslation } from "react-i18next";

function ApprovedDialog({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.ukrsib.getRequest')}
    >
      <CustomButton
        action={onClose}
        fullWidth
        title={t('common.close')}
      />
    </Dialog>
  )
}

export default React.memo(ApprovedDialog);
