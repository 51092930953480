import { isNotEmpty } from 'class-validator';
import { indexOf } from 'lodash';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class KaspiImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          let rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          rawDocument = rawDocument
            .map((el) => (el.length ? el.filter(Boolean) : []))
            .filter((el) => Boolean(el.length));
          return rawDocument?.length && rawDocument[0]?.includes('ВЫПИСКА');
        },
        proceedCase: (importDocument) => {
          const firstHeaderLineRaw = this.findString(/^Детали$/)?.raw;
          const lastBodyLineRaw = this.findString(
            /Сумма заблокирована. Банк ожидает подтверждения от платежной системы./,
          )?.raw;

          this.setDocumentHeader(
            importDocument[firstHeaderLineRaw].filter(Boolean),
          );
          this.setDocumentBody(
            importDocument
              .slice(firstHeaderLineRaw + 2, lastBodyLineRaw)
              .map((el) => (el.length ? el.filter(Boolean) : []))
              .filter((el) => Boolean(el.length)),
          );
          return (): ImportResultItemMask => {
            const sumAndCurrency = this.getFirstValidCellByColumn([
              'Сумма',
            ])?.split('\n')[0];
            const sum = sumAndCurrency.slice(0, -1);
            const currency = sumAndCurrency.slice(-1);
            return {
              date: this.getFirstValidCellByColumn(['Дата']),
              sum,
              comment: [
                this.getFirstValidCellByColumn(['Операция']),
                this.getFirstValidCellByColumn(['Детали']),
              ]
                .filter(Boolean)
                .join(' '),
              currency,
            };
          };
        },
        caseOptions: { defaultCurrency: 'KZT' },
      },
      {
        proceedCase: (importDocument) => {
          const currencyObj = this.findString(/^Валюта счета:/);
          let currency = '';
          if (importDocument[currencyObj?.raw][currencyObj?.column + 1])
            currency =
              importDocument[currencyObj?.raw][currencyObj?.column + 1];
          else
            currency =
              importDocument[currencyObj?.raw][currencyObj?.column + 2];

          const firstHeaderLineRaw = this.findString(/^№/)?.raw;
          const lastBodyLineRaw = this.findString(
            /^(Итого обороты в валюте счета)|(Итого обороты в национальной валюте)/,
          )?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLineRaw]);
          this.setDocumentBody(
            importDocument.slice(firstHeaderLineRaw + 2, lastBodyLineRaw),
          );
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn(['Дата операции', 1]),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            counterparty: this.getFirstValidCellByColumn([
              'Наименование бенефициара / отправителя денег',
              4,
            ]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
            currency,
          });
        },
        caseOptions: { defaultCurrency: 'KZT' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            isNotEmpty(
              rawDocument.find(
                (value) =>
                  moment(value.str, 'DD.MM.YYYY', true).isValid() &&
                  /^([+-][\s\d]+,\d+ \D)$/.test(
                    // check if there is +-sum after date
                    rawDocument[indexOf(rawDocument, value) + 1].str,
                  ),
              ),
            )
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const matchedSum = this.getFirstValidCellByColumn([
              'Сумма',
              1,
            ])?.match(/([+-][\s\d]+,\d+ \S+)/);
            const sumArr = matchedSum ? matchedSum[0].split(' ') : [''];
            const sum = sumArr.slice(0, -1).join('');
            const currency = sumArr[sumArr.length - 1];
            return {
              date: this.getFirstValidCellByColumn(['Дата', 0]),
              comment: this.getFirstValidCellByColumn(['Детали', 3]),
              sum,
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 10,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc?.nextWord?.includes('Сумма'),
                (word, etc) =>
                  word?.includes('Детали') ||
                  (word?.includes('На Депозите') &&
                    moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid()),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  (word?.includes('Детали') || word?.includes('На Депозите')) &&
                  moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid(),
                1,
              );
              self.defineOperation([
                (value) => /^([+-][\s\d]+,\d+ \D)$/.test(value), // '- 5 685,00 ₸' || '+ 300 000,00 ₸'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return rawDocument?.length && rawDocument[0].str.includes('ВЫПИСКА');
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const matchedSum = this.getFirstValidCellByColumn([
              'Сумма',
              1,
            ])?.match(/([+-][\s\d]+,\d+ \S+)/);
            const sumArr = matchedSum ? matchedSum[0].split(' ') : [''];
            const sum = sumArr.slice(0, -1).join('');
            const currency = sumArr[sumArr.length - 1];
            return {
              date: this.getFirstValidCellByColumn(['Дата', 0]),
              comment: this.getFirstValidCellByColumn(['Детали', 3]),
              sum,
              currency,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            spaceLengthPx: 4,
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 20,
            interlineSpacingAccuracy: 1,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc?.nextWord?.includes('Сумма'),
                (word, etc) =>
                  (word?.includes('Детали') || word?.includes('На Депозите')) &&
                  moment(etc?.nextWord, 'DD.MM.YY', true).isValid(),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  (word?.includes('Детали') || word?.includes('На Депозите')) &&
                  moment(etc?.nextWord, 'DD.MM.YY', true).isValid(),
                1,
              );
              self.defineOperation([
                (value) => /^([+-][\s\d]+,\d+ \D)$/.test(value), // '- 5 685,00 ₸' || '+ 300 000,00 ₸'
                (value) => moment(value, 'DD.MM.YY', true).isValid(),
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата операции',
              1,
            ])?.replace('\n', ' '),
            debit: this.getFirstValidCellByColumn(['Дебет', 2]),
            credit: this.getFirstValidCellByColumn(['Кредит', 3]),
            counterparty: this.getFirstValidCellByColumn([
              'Наименование получателя \n(отправителя денег) в случае если \nклиент является отправителем\nденег (получателем)',
              4,
            ]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 8]),
          });
        },
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            verticalAlign: 'top',
            rotate: true,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Номер') &&
                  etc?.nextWord?.includes('Дата операции'),
                (word, etc) =>
                  word?.includes('денег)') &&
                  etc?.prevWord?.includes('(бенеф)'),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('9') && etc?.prevWord?.includes('8'),
                1,
              );
              self.defineOperation([
                (value) => /^([\s\d]+(,\d{2})?)$/.test(value), // '5 685,00' || '300 000'
                (value) => moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
