import './styles.scss';
import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
} from '@finmap/import-parsers/base-import-parser-v3';
import { CentercreditPdfParser } from '@finmap/import-parsers/centercredit-pdf-parser';
import { EcoCenterBankPdfParser } from '@finmap/import-parsers/eco-center-pdf-parser';
import {
  ForteBankImportParser,
  ForteBankPdfParser,
} from '@finmap/import-parsers/fortebank-pdf-parser';
import { KaspiPdfParser } from '@finmap/import-parsers/kaspi-pdf-parser';
import { MBankVATPdfParser } from '@finmap/import-parsers/mbank-vat-pdf-parser';
import { PlanfactImportParser } from '@finmap/import-parsers/planfact-import-parser';

// @ts-ignore
import templateCz from '../../assets/files/CZ.xlsx';
// @ts-ignore
import templateEn from '../../assets/files/EN.xlsx';
// @ts-ignore
import templateEs from '../../assets/files/ES.xlsx';
// @ts-ignore
import templatePl from '../../assets/files/PL.xlsx';
// @ts-ignore
import templatePt from '../../assets/files/PT.xlsx';
// @ts-ignore
import templateRu from '../../assets/files/RU.xlsx';
// @ts-ignore
import templateTk from '../../assets/files/TR.xlsx';
// @ts-ignore
import templateUa from '../../assets/files/UA.xlsx';
// @ts-ignore
import templatePtBr from '../../assets/files/PTBR.xlsx';
// @ts-ignore
import templateUz from '../../assets/files/UZ.xlsx';
import DownloadIcon from '../../assets/images/svg/download.svg';
import CSVIcon from '../../assets/images/svg/payments/csv_icon.svg';
import PDFIcon from '../../assets/images/svg/payments/pdf_icon.svg';
import XSLXIcon from '../../assets/images/svg/payments/xsl_icon.svg';
import PlayIcon from '../../assets/images/svg/play_circle.svg';
import QuestionIcon from '../../assets/images/svg/question.svg';
import CustomButton from '../../components/Button';
import Dialog from '../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../components/PreviewRegisterDialog';
import { lngToUrlHowToImport, MAX_IMPORT_FILE_SIZE } from '../../constants';
import { SHOW_ADDITIONAL_INFO_ON_IMPORT_DIALOG } from '../../constants/featureToggles/featureToggle';
import { HelperPages } from '../../hooks/useHelpVideoLinks';
import useUnleash from '../../hooks/useUnleash';
import FacebookPixelContext from '../../Providers/FacebookFixelProvider/FacebookPixelContext';
import { getLanguage } from '../../selectors/main';
import accountActions from '../../store/accounts/actions';
import { selectSettingsAccounts } from '../../store/accounts/selectors';
import { AccountValue } from '../../store/accounts/types';
import importActions from '../../store/import/actions';
import { getPDFBuffer } from '../../store/import/selectors';
import { ImportType } from '../../store/import/types';
import { declensions } from '../../utils/declensions';
import AccountSelector from '../Operations/Components/AccountSelector';
import VideoHelper from '../VideoHelper';
import RenderSelectFileComponent from './Components/RenderSelectFileComponent';
import RenderUploadedFile from './Components/RenderUploadedFile';
import { getParser } from './getParser';
import { Props } from './importFile.types';
import {
  listAvailableCSVImports,
  listAvailablePFDImports,
  listAvailableXSLImports,
  listPFDImports,
} from './importLists';
import { useStyles } from './importStyles';

const template: { [key in string]: string } = {
  ru: templateRu,
  en: templateEn,
  es: templateEs,
  uk: templateUa,
  pl: templatePl,
  pt: templatePt,
  cz: templateCz,
  tr: templateTk,
  uz: templateUz,
  'pt-BR': templatePtBr,
};

const listImportsWithAccount = [
  ImportType.raiffeisenbank,
  ImportType.alfabank,
  ImportType.kredoBank,
  ImportType.kaspiBusiness,
  ImportType.santanderPoland,
  ImportType.mBankVATPoland,
  ImportType.kaspiPrivate,
  ImportType.otpBank,
  ImportType.sebBankLatvia,
  ImportType['3sMoney'],
  ImportType.halykbank,
  ImportType.oschadbank,
  ImportType.ingBank,
  ImportType.forteBank,
  ImportType.jusanBank,
  ImportType.proCreditBank,
  ImportType.vostokBank,
  ImportType.mercuryBank,
  ImportType.centerCreditBank,
  ImportType.ecoCenterBank,
  ImportType.upwork,
  ImportType.bankRBK,
  ImportType.privatBank,
  ImportType.pumbPersonal,
  ImportType.kaspiBank,
  ImportType.ukrGazBank,
  ImportType.universalBank,
  ImportType.taskombank,
  ImportType.rwsBank,
  ImportType.bankPivdenny,
  ImportType.bankPekao,
  ImportType.ukrsibbank,
  ImportType.pkoBank,
  ImportType.wiseBusiness,
  ImportType.creditAgricole,
  ImportType.unlimint,
  ImportType.paysera,
  ImportType.abank,
  ImportType.paypal,
  ImportType.senseBank,
  ImportType.sportbank,
  ImportType.bsiBank,
  ImportType.silverbirdBank,
  ImportType.otpHungary,
  ImportType.kapitalbank,
  ImportType.ipalYuliBank,
  ImportType.NBUz,
  ImportType.orintFinansBank,
  ImportType.ukreximbank,
  ImportType.payoneer,
  ImportType.mtbBank,
  ImportType.trustee,
  ImportType.bvr,
  ImportType.monobankJar,
  ImportType.monobank,
  ImportType.bankOfamerica,
  ImportType.milleniumBank,
  ImportType.chaseBank,
  ImportType.postFinance,
  ImportType.revolut,
  ImportType.altBank,
  ImportType.iziBank,
  ImportType.novaPay,
  ImportType.sqb,
  ImportType.asiaAllianceBank,
];

// @ts-ignore
const pdfImportsToParser: { [key in ImportType]: any } = {
  [ImportType.kaspiPrivate]: KaspiPdfParser,
  [ImportType.forteBank]: ForteBankPdfParser,
  [ImportType.mBankVATPoland]: MBankVATPdfParser,
  [ImportType.centerCreditBank]: CentercreditPdfParser,
  [ImportType.ecoCenterBank]: EcoCenterBankPdfParser,
};

const pdfMimeType = /application\/(pdf)/i;

function ImportFileDialog(props: Props) {
  const { onClose, currentImportType, onCloseRootDialog } = props;

  const accounts = useSelector(selectSettingsAccounts);
  const language = useSelector(getLanguage);
  const pdfBuffer = useSelector(getPDFBuffer);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showAdditionInfoOnImportDialog = useUnleash(
    SHOW_ADDITIONAL_INFO_ON_IMPORT_DIALOG,
  );

  const facebookContext = useContext(FacebookPixelContext);

  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [importResult, setImportResult] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);
  const [account, setAccount] = useState<AccountValue | null>(null);
  const [accountError, setAccountError] = useState(false);

  const handleChangeAccount = useCallback((val: any) => {
    setAccountError(false);
    setAccount(val);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(importActions.resetPDF());

    onClose();
  }, [onClose, dispatch]);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const readerResultRef = useRef<string | ArrayBuffer | null>();

  const setImport = useCallback(
    async (parser: any, reader: any, currentFile: any) => {
      setTimeout(async () => {
        try {
          let result;
          if (parser instanceof BaseImportParserV3) {
            result = await parser.parse(
              currentFile,
              language,
              (account as any) || accounts,
            );
          } else {
            parser.setRawData(reader?.result ?? reader, account);
            result = parser.import(accounts);
          }

          setImportResult(result);
        } catch (e) {
          try {
            const { line, message } = JSON.parse((e as any).message);

            setFileError(`${t('import.errors.dataError')} ${line} ${message}`);
          } catch (err) {
            console.log(err);
            setFileError(`${t('import.errors.notFinished')}`); // Cannot convert undefined or null to object
          }
        }

        setLoading(false);
      }, 0);
    },
    [account, accounts, t, language],
  );

  const parseForteBank = useCallback(
    async (file: File) => {
      const parser = new ForteBankImportParser();
      await setImport(parser, readerResultRef.current, file);
    },
    [setImport],
  );

  const parsePDF = useCallback(
    async (file?: File) => {
      const parser = new pdfImportsToParser[currentImportType](language);

      await setImport(parser, pdfBuffer, file?.name ?? fileName);
    },
    [language, fileName, setImport, currentImportType, pdfBuffer],
  );

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setFileError('');

      acceptedFiles.forEach((currentFile: File) => {
        if (listImportsWithAccount.includes(currentImportType) && !account) {
          setFileError(t('import.errors.setAccountFirst'));
          return;
        }

        if (currentFile.size > MAX_IMPORT_FILE_SIZE) {
          setFileError(
            t('import.errors.tooBig', {
              postProcess: 'sprintf',
              sprintf: [10],
            }),
          );
          return;
        }
        if (
          listPFDImports.includes(currentImportType) &&
          !currentFile.type.match(pdfMimeType)
        ) {
          setFileError(t('import.errors.wrongExtension'));
          return;
        }

        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          setLoading(true);
          setFileName(currentFile.name);

          readerResultRef.current = reader.result;

          if (currentImportType === ImportType.forteBank) {
            parseForteBank(currentFile);
            return;
          }

          let parser = getParser(currentImportType, language);
          if (currentImportType === ImportType.planfact)
            parser = new PlanfactImportParser() as any;

          await setImport(parser, reader, currentFile);
        };

        reader.readAsArrayBuffer(currentFile);
      });
    },
    [t, account, language, setImport, currentImportType, parseForteBank],
  );

  const handleRemoveFile = useCallback(() => {
    setFileName('');
    setImportResult(null);
    setFileError('');
  }, []);

  const { isDragActive } = useDropzone({ onDrop });

  const handleImport = useCallback(() => {
    handleClosePreviewRegisterDialog();

    if (listImportsWithAccount.includes(currentImportType) && !account) {
      setAccountError(true);

      return;
    }

    if (!fileName) {
      setFileError(t('import.errors.empty'));
    } else if (!importResult?.length) {
      setFileError(t('import.errors.fileIsEmpty'));
    } else {
      let type;
      const match = /\.[^.]+$/g.exec(fileName);
      if (match?.length) type = match[0].slice(1);
      dispatch(
        importActions.importOperations({
          operations: importResult,
          fileName,
          facebookContext,
          type,
          importParserName: currentImportType,
        }),
      );

      onClose();
      onCloseRootDialog();
    }
  }, [
    t,
    onClose,
    account,
    fileName,
    dispatch,
    importResult,
    facebookContext,
    onCloseRootDialog,
    currentImportType,
    handleClosePreviewRegisterDialog,
  ]);

  useEffect(() => {
    dispatch(accountActions.getSettingsAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (fileError) {
      // setFileName('');
      setImportResult(null);
    }
  }, [fileError]);

  useEffect(() => {
    if (
      listPFDImports.includes(currentImportType) &&
      fileName &&
      !(currentImportType === ImportType.forteBank)
    ) {
      dispatch(
        importActions.importPDF({
          file: readerResultRef.current,
        }),
      );
    }
  }, [currentImportType, dispatch, fileName]);

  useEffect(() => {
    if (pdfBuffer) {
      parsePDF();
    }
  }, [pdfBuffer, parsePDF]);

  const countOperationsText = importResult?.length
    ? `(${importResult.length} ${declensions(importResult.length, [
        t('import.declensions.first'),
        t('import.declensions.second'),
        t('import.declensions.third'),
      ])})`
    : '';

  const downloadFileName = t(`import.templateTitleByLanguage.${[language]}`);

  console.log({ language, downloadFileName });
  const classes = useStyles();

  const AvailableImports = useCallback(() => {
    if (
      currentImportType === ImportType.finmap1 ||
      currentImportType === ImportType.excel
    ) {
      return null;
    }

    const parser = getParser(currentImportType, language);
    let availableImportType: AVAILABLE_IMPORT_TYPES[] = [];
    if (parser instanceof BaseImportParserV3)
      availableImportType = parser.listAvailableImportTypes();

    return (
      <div className={classes.availableImportsContainer}>
        <Typography className={classes.availableImportsTitle}>
          {t('import.availableFormats')}
        </Typography>
        <div className={classes.availableImportsRow}>
          {(availableImportType.includes(AVAILABLE_IMPORT_TYPES.PDF) ||
            listAvailablePFDImports.includes(currentImportType)) && (
            <div className={classes.availableImport}>
              <img src={PDFIcon} alt="pdf" className={classes.formatIcon} />
              <Typography className={classes.bold}>PDF</Typography>
            </div>
          )}
          {(availableImportType.includes(AVAILABLE_IMPORT_TYPES.XLS) ||
            availableImportType.includes(AVAILABLE_IMPORT_TYPES.XLSX) ||
            listAvailableXSLImports.includes(currentImportType)) && (
            <div className={classes.availableImport}>
              <img src={XSLXIcon} alt="xslx" className={classes.formatIcon} />
              <Typography className={classes.bold}>XSLX</Typography>
            </div>
          )}
          {(availableImportType.includes(AVAILABLE_IMPORT_TYPES.CSV) ||
            listAvailableCSVImports.includes(currentImportType)) && (
            <div className={classes.availableImport}>
              <img src={CSVIcon} alt="csv" className={classes.formatIcon} />
              <Typography className={classes.bold}>CSV</Typography>
            </div>
          )}
        </div>
      </div>
    );
  }, [classes, currentImportType, t, language]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        isOpened
        title={t(`import.from.${currentImportType}`)}
      >
        <>
          {currentImportType === ImportType.excel && (
            <VideoHelper page={HelperPages.importExcel} />
          )}
        </>
        <div className={classes.separator} />
        <>
          {listImportsWithAccount.includes(currentImportType) && (
            <AccountSelector
              value={account}
              accounts={accounts}
              onChange={handleChangeAccount}
              error={accountError}
            />
          )}
        </>
        <AvailableImports />
        <>
          {!fileName && <RenderSelectFileComponent onDrop={onDrop} />}
          {fileName && readerResultRef.current && (
            <RenderUploadedFile
              fileName={fileName}
              reader={readerResultRef.current}
              onRemoveFile={handleRemoveFile}
            />
          )}
          {!!fileError && (
            <Typography className={cn(classes.smallText, classes.errorText)}>
              {fileError}
            </Typography>
          )}
        </>
        <>
          {currentImportType === 'excel' && (
            <a
              className={classes.row}
              href={template[language]}
              download={`${downloadFileName}.xlsx`}
              target="_blank"
              rel="noreferrer"
            >
              <img className={classes.icon} src={DownloadIcon} alt="download" />
              <Typography className={classes.text}>
                {t('import.downloadTemplate')}
              </Typography>
            </a>
          )}
        </>
        <>
          {showAdditionInfoOnImportDialog && (
            <>
              <a
                className={classes.row}
                href={lngToUrlHowToImport[language]}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.questionIcon}
                  src={QuestionIcon}
                  alt="question"
                />
                <Typography className={classes.text}>
                  {t('import.read')}
                </Typography>
              </a>
              <a
                className={classes.row}
                href={lngToUrlHowToImport[language]}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.questionIcon}
                  src={PlayIcon}
                  alt="playVideo"
                />
                <Typography className={classes.text}>
                  {t('import.watch')}
                </Typography>
              </a>
            </>
          )}
        </>

        <CustomButton
          id="cypress-confirm-import-button"
          disabled={isDragActive || !!fileError}
          className={classes.button}
          title={`${t('import.button')} ${countOperationsText}`}
          fullWidth
          action={handleOpenPreviewRegisterDialog}
          loading={loading}
        />
      </Dialog>
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleImport}
        />
      )}
    </>
  );
}

export default React.memo(ImportFileDialog);
