import { aquaHaze, black } from '../theme/colors';
import { createTheme } from '@material-ui/core/styles';

const colors = {
  palette: {
    primary: {
      light: '#57de8b',
      main: '#00b384',
      dark: '#55d498',
    },
    secondary: {
      light: '#f0f0f0',
      main: '#fff',
      dark: '#313131',
    },
    error: {
      main: '#ff0033',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
      woodsmoke: '#181D1F',
      catskillWhite: '#EDF4F7',
    },
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 2550,
    },
  },
  typography: {
    fontSize: 15,
    // @ts-ignore
    lineHeight: '20px',
    fontFamily: 'Inter, sans-serif',
    subtitle1: {
      fontSize: 16,
      color: colors.palette.secondary.dark,
    },
    subtitle2: {
      fontSize: 26,
      fontWeight: 600,
      color: colors.palette.secondary.dark,
    },
    caption: {
      fontSize: 11.5,
      color: colors.palette.secondary.light,
    },
    body1: {
      fontSize: 15,
      color: '#000000',
      lineHeight: 1.47,
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  overrides: {
    MuiCard: {
      root: {
        borderRadius: 10,
        '&:hover': {
          cursor: 'pointer',
          '-webkit-appearance': 'none',
          boxShadow: '0 0 38px rgba(81,174,203,0.41)',
          '-webkit-box-shadow': '0 0 38px rgba(81,174,203,0.41)',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    // @ts-ignore
    MuiAutocomplete: {
      endAdornment: {
        right: 15,
      },
      listbox: {
        height: '100%',
        marginRight: 4,
      },
    },
    MuiInput: {
      root: {
        marginTop: 5,
      },
      multiline: {
        padding: 0,
      },
      formControl: {
        label: {
          fontSize: 12,
        },
        'label + &': {
          marginTop: 10,
        },
        '&:focus:': {
          top: 0,
        },
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none',
          transition: 'none',
        },
        '&:after': {
          borderBottom: 'none',
          transition: 'none',
        },
        '&:before': {
          content: '',
          borderBottom: 'none',
          transition: 'none',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 12,
        '&$focused': {
          color: black,
        },
      },

      focused: {},
    },
    MuiInputLabel: {
      root: {
        opacity: 0.5,
      },
    },
    MuiButton: {
      root: {
        border: 0,
        height: 55,
        fontSize: 16,
        color: 'white',
        borderRadius: 10,
        fontWeight: 'bold',
        textTransform: 'none',
        fontFamily: 'Inter, sans-serif',
        transition: 'box-shadow .3s ease, transform .4s ease',
        background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
        '&$disabled': {
          color: 'white',
        },
      },
      outlined: {
        border: 0,
        height: 50,
        borderRadius: 0,
        color: '#313131',
        background: 'transparent',
        '&:hover': {
          transform: 'none',
          background: 'transparent',
          '-webkit-appearance': 'none',
          boxShadow: '0 10px 20px rgba(86, 213, 150, 0.38)',
          '-webkit-box-shadow': '0 10px 20px rgba(86, 213, 150, 0.38)',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: 'inherit',
      },
    },
    MuiTableCell: {
      body: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'center',
        color: colors.palette.secondary.dark,
      },
      root: {
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: 'center',
        color: colors.palette.secondary.dark,
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 48,
        fontSize: 15,
        lineHeight: '20px',
        color: black,
        marginLeft: 8,
        marginRight: 8,
        '&:hover': {
          marginRight: 8,
          paddingTop: 14,
          paddingBottom: 14,
          borderRadius: 8,
          background: aquaHaze,
        },
      },
    },
    MuiDialog: {
      root: {
        overflow: 'auto',
      },
    },
  },

  ...colors,
});

export default theme;
