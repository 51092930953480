import moment from 'moment';
import { AnyAction } from 'redux';

import { JOURNAL_INIT } from '../journal/actions';
import {
  COPY_FILTERS,
  COPY_PERIOD_FILTERS,
  GET_USER_FILTERS_SUCCESS,
  INIT_FILTERS,
  RESET_CUSTOM_FILTERS,
  RESET_FILTERS,
  RESET_FUTURE_JOURNAL_FILTER,
  SET_CUSTOM_FILTER,
  SET_FILTERS_PERIOD,
  SET_FUTURE_JOURNAL_FILTER,
  SET_SEARCH_FILTER,
  SET_TAGS_FILTER,
  UPDATE_FUTURE_DATE_FILTER,
  UPDATE_USER_CUSTOM_FILTER_SUCCESS,
} from './actions';
import {
  initialCalendarFilters,
  initialCashAndFlowCustomFilters,
  initialCashFlowAndPLFilters,
  initialCustomFilters,
  initialDebitAndCreditCustomFilters,
  initialDebitAndCreditFilters,
  initialFutureJournalFilter,
  initialHistoryFilters,
  initialJournalFilters,
  initialPLCustomFilters,
  initialProjectCustomFilters,
  initialProjectsFilters,
  initialStatementFilters,
} from './initial';
import { FiltersState } from './types';

export const initialFiltersState: FiltersState = {
  journal: {
    filters: initialJournalFilters,
    customFilters: initialCustomFilters,
    futureFilter: initialFutureJournalFilter,
    search: '',
    tags: [],
  },
  calendar: {
    filters: initialCalendarFilters,
    customFilters: initialCustomFilters,
  },
  cashFlow: {
    filters: initialCashFlowAndPLFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: initialCashAndFlowCustomFilters,
  },
  profitAndLoss: {
    filters: initialCashFlowAndPLFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: initialPLCustomFilters,
  },
  tableCashFlow: {
    filters: initialCashFlowAndPLFilters,
    customFilters: initialCashAndFlowCustomFilters,
  },
  tableProfitAndLoss: {
    filters: initialCashFlowAndPLFilters,
    customFilters: initialCashAndFlowCustomFilters,
  },
  logTableProfitAndLoss: {
    filters: initialCashFlowAndPLFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: initialCashAndFlowCustomFilters,
  },
  logTableCashFlow: {
    filters: initialCashFlowAndPLFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: initialCashAndFlowCustomFilters,
  },
  statement: {
    filters: initialStatementFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: initialCustomFilters,
  },
  history: {
    filters: initialHistoryFilters,
    customFilters: initialCustomFilters,
  },
  debit: {
    futureFilter: initialFutureJournalFilter,
    filters: initialDebitAndCreditFilters,
    customFilters: initialDebitAndCreditCustomFilters,
  },
  credit: {
    futureFilter: initialFutureJournalFilter,
    filters: initialDebitAndCreditFilters,
    customFilters: initialDebitAndCreditCustomFilters,
  },
  projects: {
    filters: initialProjectsFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: {
      ...initialProjectCustomFilters,
      isDateOfPayment: true,
      projectsJournal: null,
      categoriesByTypeJournal: [],
    },
  },
  logProjects: {
    filters: initialProjectsFilters,
    futureFilter: initialFutureJournalFilter,
    customFilters: {
      ...initialProjectCustomFilters,
      isDateOfPayment: true,
      projectsJournal: null,
      categoriesByTypeJournal: [],
    },
  },
  invoicing: {
    filters: initialProjectsFilters,
    customFilters: {
      status: false,
      clients: [],
    },
  },
  userFilters: [],
};

export const filtersReducer = (
  // eslint-disable-next-line default-param-last
  state = initialFiltersState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case JOURNAL_INIT: {
      return {
        ...state,
        journal: {
          ...state.journal,
          futureFilter: { ...initialFutureJournalFilter },
        },
        cashFlow: {
          ...state.cashFlow,
          futureFilter: { ...initialFutureJournalFilter },
        },
        profitAndLoss: {
          ...state.profitAndLoss,
          futureFilter: { ...initialFutureJournalFilter },
        },
        logProjects: {
          ...state.logProjects,
          futureFilter: { ...initialFutureJournalFilter },
        },
        statement: {
          ...state.statement,
          futureFilter: { ...initialFutureJournalFilter },
        },
      };
    }

    case UPDATE_USER_CUSTOM_FILTER_SUCCESS:
    case GET_USER_FILTERS_SUCCESS: {
      return {
        ...state,
        userFilters: payload.data,
      };
    }

    case COPY_FILTERS: {
      const { selectorFrom, selectorTo } = payload;
      const from = {
        // @ts-ignore
        ...state[selectorFrom as keyof FiltersState].customFilters,
      };

      return {
        ...state,
        [selectorTo]: {
          ...state[selectorTo as keyof FiltersState],
          customFilters: from,
        },
      };
    }

    case COPY_PERIOD_FILTERS: {
      const { selectorFrom, selectorTo } = payload;
      const from = {
        // @ts-ignore
        ...state[selectorFrom as keyof FiltersState].filters,
      };

      return {
        ...state,
        [selectorTo]: {
          ...state[selectorTo as keyof FiltersState],
          filters: from,
        },
      };
    }

    case SET_CUSTOM_FILTER: {
      const { data, selector, filterType } = payload;

      let copySelector: keyof FiltersState | null = null;

      if (selector === 'cashFlow') {
        copySelector = 'tableCashFlow';
      } else if (selector === 'tableCashFlow') {
        copySelector = 'cashFlow';
      } else if (selector === 'profitAndLoss') {
        copySelector = 'tableProfitAndLoss';
      } else if (selector === 'tableProfitAndLoss') {
        copySelector = 'profitAndLoss';
      }

      if (copySelector) {
        return {
          ...state,
          [selector]: {
            ...state[selector as keyof FiltersState],
            customFilters: {
              // @ts-ignore
              ...state[selector as keyof FiltersState].customFilters,
              [filterType]: data,
            },
          },
          [copySelector]: {
            ...state[copySelector as keyof FiltersState],
            customFilters: {
              // @ts-ignore
              ...state[copySelector as keyof FiltersState].customFilters,
              [filterType]: data,
            },
          },
        };
      }

      return {
        ...state,
        [selector]: {
          ...state[selector as keyof FiltersState],
          customFilters: {
            // @ts-ignore
            ...state[selector as keyof FiltersState].customFilters,
            [filterType]: data,
          },
        },
      };
    }

    case INIT_FILTERS: {
      return {
        ...state,
        ...initialFiltersState,
      };
    }

    case SET_FUTURE_JOURNAL_FILTER: {
      return {
        ...state,
        [payload.selector]: {
          // @ts-ignore
          ...state[payload.selector],
          futureFilter: payload.data,
        },
      };
    }

    case RESET_FUTURE_JOURNAL_FILTER: {
      return {
        ...state,
        journal: {
          ...state.journal,
          futureFilter: { ...initialFutureJournalFilter },
        },
      };
    }

    case RESET_FILTERS: {
      const { selector } = payload;

      return {
        ...state,
        [selector]: {
          ...initialFiltersState[selector as keyof FiltersState],
        },
      };
    }

    case RESET_CUSTOM_FILTERS: {
      const { selector } = payload;

      return {
        ...state,
        [selector]: {
          ...state[selector as keyof FiltersState],
          customFilters: {
            // @ts-ignore
            ...// @ts-ignore
            initialFiltersState[selector as keyof FiltersState].customFilters,
          },
        },
      };
    }

    case UPDATE_FUTURE_DATE_FILTER: {
      const { state: buttonState } = payload;

      // @ts-ignore
      const { startDate } = state[payload.selector].futureFilter;

      const newEndDate =
        buttonState === 'month'
          ? moment.utc(startDate).add(1, 'months').endOf('day').valueOf()
          : undefined;

      return {
        ...state,
        [payload.selector]: {
          // @ts-ignore
          ...state[payload.selector],
          futureFilter: {
            startDate,
            endDate: newEndDate,
          },
        },
      };
    }

    case SET_SEARCH_FILTER: {
      return {
        ...state,
        journal: {
          ...state.journal,
          search: payload.search,
        },
      };
    }

    case SET_TAGS_FILTER: {
      return {
        ...state,
        journal: {
          ...state.journal,
          tags: payload.tags,
        },
      };
    }

    case SET_FILTERS_PERIOD: {
      const { selector } = payload;
      const filters = {
        startDate: payload.startDate,
        endDate: payload.endDate,
        id: payload.id,
      };

      if (selector === 'journal') {
        return {
          ...state,
          journal: {
            ...state[selector as keyof FiltersState],
            filters,
            futureFilter: {
              startDate: payload.startDate,
              endDate: payload.endDate,
            },
          },
        };
      }

      return {
        ...state,
        [selector]: {
          ...state[selector as keyof FiltersState],
          filters,
        },
      };
    }

    default:
      return state;
  }
};

export default filtersReducer;
