import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import CloseIcon from '../../assets/images/svg/close-24-24.svg';
import { DialogContextProvider } from './DialogRefProvider';
import { useRootStyles, usescrollClasses, useStyles } from './styles';
import { Props } from './types';

function DialogComponent(props: Props) {
  const {
    title,
    actions,
    onClose,
    children,
    isOpened,
    className,
    fullScreen,
    rootClassName,
    dialogActionsStyle,
    titleClassName,
    wrapContentStyle,
    customRootTitleClasses,
    wrapperContainerClasses,
    isScrollableRoot = false,
    isScrollableContent = true,
    showCloseIcon = true,
    contentRootClassName,
  } = props;
  const targetRef = useRef<HTMLDivElement | null>(null);

  const dialogRef = useRef<HTMLDivElement | null>(null);

  const [show, setShowContainer] = useState(true);

  const hideContainer = useCallback(() => {
    setShowContainer(false);
  }, []);

  const showContainer = useCallback(() => {
    setShowContainer(true);
  }, []);

  const handleClose = useCallback(
    (_event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') {
        return;
      }

      if (reason === 'escapeKeyDown') {
        if (onClose) {
          onClose();
        }
      }

      if (onClose) {
        onClose();
      }
    },
    [onClose],
  );

  const handleCloseByUser = useCallback(
    (_event: any) => {
      if (onClose) {
        onClose(true);
      }
    },
    [onClose],
  );

  const classes = useStyles({
    // @ts-ignore
    height: '100%',
  });
  const rootClasses = useRootStyles();
  const scrollClasses = usescrollClasses();

  const RenderTitle = useCallback(() => {
    if (typeof title === 'string') {
      return (
        <Typography className={cn(classes.title, titleClassName)}>
          {title}
        </Typography>
      );
    }

    return title ?? null;
  }, [classes, titleClassName, title]);

  if (!isOpened) {
    return null;
  }

  return (
    <DialogContextProvider value={{ hide: hideContainer, show: showContainer }}>
      <Dialog
        disableEnforceFocus
        ref={dialogRef}
        open={isOpened}
        onClose={handleClose}
        PaperProps={{
          className: cn(
            classes.root,
            className,
            fullScreen && classes.fullscreen,
          ),
        }}
        classes={{
          container: cn(
            classes.container,
            wrapperContainerClasses,
            !show && classes.hide,
            show && classes.show,
          ),
          root: cn(rootClassName, isScrollableRoot ? '' : rootClasses.root),
        }}
        disableEscapeKeyDown={false}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle
          id="scroll-dialog-title"
          classes={{
            root: cn(classes.relative, customRootTitleClasses),
          }}
        >
          {!!title && <RenderTitle />}
          {showCloseIcon && (
            <img
              alt=""
              id="cypress-close-dialog"
              src={CloseIcon}
              className={classes.closeIcon}
              onClick={handleCloseByUser}
            />
          )}
        </DialogTitle>
        <DialogContent
          classes={{
            root: isScrollableContent
              ? `${contentRootClassName} ${scrollClasses.root}`
              : contentRootClassName,
          }}
        >
          <div ref={targetRef} style={wrapContentStyle}>
            {children}
          </div>
        </DialogContent>
        {actions && (
          <DialogActions classes={{ root: dialogActionsStyle }}>
            {actions}
          </DialogActions>
        )}
      </Dialog>
    </DialogContextProvider>
  );
}

export default React.memo(DialogComponent);
