import { makeStyles } from '@material-ui/core/styles';

import {
  catskillWhite,
  flamingo,
  outrageousOrange,
  persianGreen,
} from '../../theme/colors';

export const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 160,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 0',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  rowHover: {
    background: catskillWhite,
    borderRadius: 8,
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    width: '80%',
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  opacity: {
    opacity: 0.5,
  },
  margin16: {
    marginRight: 8,
  },
  hideText: {
    fontSize: 13,
  },
  dialog: {
    borderRadius: '24px !important',
  },
  bankSetButton: {
    height: 25,
    background: 'rgba(0, 178, 142, 0.3)',
    '&:hover': {
      background: 'rgba(0, 178, 142, 0.3)',
    },
  },
  bankText: {
    fontSize: 13,
    color: persianGreen,
  },
  bankOffButton: {
    height: 25,
    marginRight: 15,
    background: 'rgba(255, 99, 51, 0.2)',
    '&:hover': {
      background: 'rgba(255, 99, 51, 0.2)',
    },
  },
  bankOffText: {
    fontSize: 13,
    color: outrageousOrange,
  },
  loaderState: {
    fontSize: 13,
    lineHeight: '16px',
  },
  loaderStateNumber: {
    fontSize: 13,
    lineHeight: '16px',
    marginLeft: 17,
    marginRight: 5,
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hide: {
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
  bankIcon: {
    width: 32,
    height: 32,
  },
  textEclipse: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textEclipseShort: {
    // maxWidth: '45%',
    flex: 1,
  },
  width60: {
    display: 'flex',
    flex: 0.65,
  },
  marginRight12: {
    marginRight: 12,
  },
  margin8: {
    marginRight: 8,
  },
  warningContainer: {
    fontSize: 13,
    lineHeight: '20px',
    color: outrageousOrange,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    background: 'rgba(255, 99, 51, 0.14)',
    padding: '4px 16px',
    display: 'flex',
    placeItems: 'center',
    borderRadius: 8,
  },
  alertContainer: {
    display: 'flex',
    placeItems: 'center',
    background: 'rgba(255, 99, 51, 0.14)',
    borderRadius: 8,
    padding: '4px 16px',
    fontSize: 13,
    color: flamingo,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  marginLeft10: {
    marginLeft: 10,
  },
  marginRight8: {
    marginRight: 8,
  },
  tooltipText: {
    fontSize: 13,
    lineHeight: '20px',
    marginBottom: 5,
  },
  tooltip: {
    pointerEvents: 'auto',
    zIndex: 10000,
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  reconnectButtonContainer: {
    background: 'rgba(255, 99, 51, 0.14)',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    padding: '0 16px',
    cursor: 'pointer',
  },
  reconnectButtonText: {
    color: outrageousOrange,
    fontSize: 13,
    lineHeight: 20,
  },
}));
