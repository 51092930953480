import cn from 'classnames';
import React, { useCallback } from 'react';

import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import { MAX_COMMENT_LENGTH, MAX_INPUT_LENGTH } from '../../../../constants';
import usePermissions from '../../../../hooks/usePermissions';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { useCompareStyles } from '../compareStyles';
import { useStyles } from './InputComponent.styles';
import { Props } from './InputComponent.types';

function InputComponent(props: Props) {
  const {
    id,
    value,
    isError,
    onChange,
    errorText,
    isComment,
    multiline,
    isCompare,
    difference,
    placeholder,
    inputPropsClasses,
  } = props;
  const classes = useStyles();
  const compareClasses = useCompareStyles();
  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const handleChange = useCallback(
    (val: string | number) => {
      onChange(val);
    },
    [onChange],
  );

  return (
    <TextFieldComponent
      id={id}
      disabled={isCompare || !subscriptionActive || !operationEnable}
      injectClassName={cn(isCompare && compareClasses.disabledInput)}
      rootClassName={cn(
        classes.textRoot,
        isComment && classes.textRootComment,
        difference && compareClasses.root,
        (!subscriptionActive || isCompare || !operationEnable) &&
          classes.disabledInput,
      )}
      wrapperInputRoot={cn(isComment && classes.inputRoot)}
      multiline={multiline}
      onChange={handleChange}
      value={value || ''}
      label={(Boolean(value) && placeholder) || ''}
      placeholder={placeholder}
      maxLength={isComment ? MAX_COMMENT_LENGTH : MAX_INPUT_LENGTH}
      inputPropsClasses={inputPropsClasses}
      isError={!!isError}
      errorText={errorText}
    />
  );
}

export default React.memo(InputComponent);
