import { routerReducer } from 'react-router-redux';
import { combineReducers, Reducer } from 'redux';

import accounts from './accounts/reducer';
import analytic from './analytic/reducer';
import auth from './auth/reducer';
import autoRules from './autoRules/reducer';
import calendar from './calendar/reducer';
import categories from './categories/reducer';
import clients from './clients/reducer';
import common from './common/reducer';
import company from './company/reducer';
import currency from './currency/reducer';
import employees from './employees/reducer';
import filters from './filters/reducer';
import historyOperation from './historyOperationReducer/reducer';
import imports from './import/reducer';
import integrations from './integrations/reducer';
import invoicing from './invoicing/reducer';
import journal from './journal/reducer';
import onboardingV2 from './onboardingV2/reducer';
import operations from './operations/reducer';
import payment from './paymentHistory/reducer';
import projects from './projects/reducer';
import { ApplicationState } from './store.types';
import tags from './tags/reducer';

// @ts-ignore
const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  auth,
  company,
  currency,
  journal,
  clients,
  employees,
  calendar,
  integrations,
  analytic,
  common,
  filters,
  operations,
  historyOperation,
  projects,
  categories,
  tags,
  accounts,
  payment,
  imports,
  invoicing,
  onboardingV2,
  autoRules,
  routerReducer,
});

export type AppState = ReturnType<typeof reducers>;

export default reducers;
