import { AnyAction } from 'redux';

import { EMPTY } from '../../constants';
import { initialContext } from '../constant';
import { categoryActionTypes } from './actions';
import { CategoriesState, Category } from './types';

const initialState: CategoriesState = {
  incomeCategories: {
    ...initialContext,
    loading: false,
  },
  consumptionCategories: {
    ...initialContext,
    loading: false,
  },
};

const categoryReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case categoryActionTypes.RESET_CATEGORIES: {
      return {
        ...state,
        incomeCategories: {
          ...initialState.incomeCategories,
        },
        consumptionCategories: {
          ...initialState.consumptionCategories,
        },
      };
    }

    case categoryActionTypes.CREATE_OPERATION_INCOME_PENDING: {
      return {
        ...state,
        incomeCategories: {
          ...state.incomeCategories,
          loading: true,
        },
      };
    }

    case categoryActionTypes.CREATE_OPERATION_CONSUMPTION_PENDING: {
      return {
        ...state,
        consumptionCategories: {
          ...state.consumptionCategories,
          loading: true,
        },
      };
    }

    case categoryActionTypes.GET_SETTINGS_INCOME_SUCCESS:
    case categoryActionTypes.DELETE_INCOME_SUCCESS:
    case categoryActionTypes.UPDATE_INCOME_SUCCESS:
    case categoryActionTypes.CREATE_SETTINGS_INCOME_SUCCESS: {
      return {
        ...state,
        incomeCategories: {
          ...state.incomeCategories,
          settings: payload.data,
        },
      };
    }

    case categoryActionTypes.GET_OPERATION_INCOME_SUCCESS:
    case categoryActionTypes.CREATE_OPERATION_INCOME_SUCCESS: {
      return {
        ...state,
        incomeCategories: {
          ...state.incomeCategories,
          operation: payload.data,
          loading: false,
        },
      };
    }

    case categoryActionTypes.GET_LOG_INCOME_SUCCESS: {
      const items = (payload.data as Category[]).filter(
        (el) => el._id !== EMPTY && !el.systemValue,
      );

      return {
        ...state,
        incomeCategories: {
          ...state.incomeCategories,
          log: items,
          fullLog: payload.data,
          loading: false,
        },
      };
    }

    case categoryActionTypes.GET_SETTINGS_CONSUMPTION_SUCCESS:
    case categoryActionTypes.DELETE_CONSUMPTION_SUCCESS:
    case categoryActionTypes.UPDATE_CONSUMPTION_SUCCESS:
    case categoryActionTypes.CREATE_SETTINGS_CONSUMPTION_SUCCESS: {
      return {
        ...state,
        consumptionCategories: {
          ...state.consumptionCategories,
          settings: payload.data,
        },
      };
    }

    case categoryActionTypes.GET_OPERATION_CONSUMPTION_SUCCESS:
    case categoryActionTypes.CREATE_OPERATION_CONSUMPTION_SUCCESS: {
      return {
        ...state,
        consumptionCategories: {
          ...state.consumptionCategories,
          operation: payload.data,
          loading: false,
        },
      };
    }

    case categoryActionTypes.GET_LOG_CONSUMPTION_SUCCESS: {
      const items = (payload.data as Category[]).filter(
        (el) => el._id !== EMPTY && !el.systemValue,
      );

      return {
        ...state,
        consumptionCategories: {
          ...state.consumptionCategories,
          log: items,
          fullLog: payload.data,
        },
      };
    }

    default:
      return state;
  }
};

export default categoryReducer;
