import FondyIcon from '../../../assets/images/svg/payments/fondy-main-light.svg';
import { PAYPAL_UNIVERSAL_NAME, WISE_UNIVERSAL_NAME } from '../../../constants';
import { SaltedgeBankType } from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';

export const universalBanks: { [key in string]: SaltedgeBankType } = {
  [PAYPAL_UNIVERSAL_NAME]: {
    code: 'paypal_xo',
    country_code: 'XX',
    id: '6039',
    logo_url:
      'https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg',
    max_consent_days: 90,
    name: 'PayPal',
    status: 'active',
  },
  Revolut: {
    code: 'revolut_oauth_client_si',
    country_code: 'XX',
    id: '4467',
    logo_url:
      'https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg',
    max_consent_days: 90,
    name: 'Revolut',
    status: 'active',
  },
  [WISE_UNIVERSAL_NAME]: {
    code: 'transferwise_oauth_client_xo',
    country_code: 'XX',
    id: '7340',
    logo_url:
      'https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg',
    max_consent_days: 90,
    name: WISE_UNIVERSAL_NAME,
    status: 'active',
  },
  Fondy: {
    code: '',
    country_code: 'XX',
    id: 'Fondy',
    logo_url: FondyIcon,
    max_consent_days: minOperationDate().getTime(),
    name: 'Fondy',
    status: 'active',
  },
};
