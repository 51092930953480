import moment from 'moment';
import xlsx from 'xlsx';

import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export class FinlogXlsxParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    id: ['ID', 'A'],
    date: ['Дата', 'B', this.dateNotValidErrorer],
    fact: ['Факт', null],
    description: ['Описание', null],
    article: ['Статья', null],
    project: ['Проект', null],
    contractor: ['Контрагент', null],
    contractorRequisite: ['Реквизит контрагента', null],
    company: ['Компания', null],
    dateOfAccrual: ['Дата начисления', null, this.dateNotValidErrorer],
    ppNumber: ['№  п.п.', null],
    order: ['Заказ', null],
    final: [
      null,
      null,
      (op) => {
        const dateIsPresent =
          op.dateOfAccrual instanceof Date || op.date instanceof Date;
        if (!dateIsPresent) return (errors) => `${errors.dateIsRequired}`;
      },
    ],
  };
  private banksAmount: number;
  private wb;
  private banksCurrencies = {};
  private rowCount: number;
  protected importName = 'Finolog';

  private calculateLetter(i: number) {
    //only for XX
    const lettersLength = UPPER_ALPHABET.length;
    const allPath = Math.floor(i / lettersLength);
    const ost = i - lettersLength * allPath;
    if (allPath === 0) return UPPER_ALPHABET[ost];
    return UPPER_ALPHABET[allPath - 1] + UPPER_ALPHABET[ost];
  }

  public setRawData(raw: any) {
    const wb = xlsx.read(raw, {
      type: 'buffer',
      cellDates: true,
      cellNF: true,
    });
    this.wb = wb;

    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const allSheet = xlsx.utils.sheet_to_json(sheet, { header: 'A' });

    this.headersJson = allSheet[0];
    this.sheetJson = allSheet.slice(1);
    this.rowCount = this.sheetJson.length;
  }

  protected doBeforeTranform(): AnyObject[] {
    const errors = this.getErrors();
    const keys = Object.keys(this.headersJson);
    const values = Object.values(this.headersJson);
    const COLUMNS_MAPkeys = Object.keys(this.COLUMNS_MAP);

    const [firstSheet]: any = Object.values(this.wb.Sheets);
    const INDENT = firstSheet.B1.v === 'Дата' ? 2 : 1; // A + B

    let banksAmount = 0;
    if (values.includes('Факт')) {
      while (values[banksAmount + INDENT] !== 'Факт') banksAmount++;
    }

    for (let i = 0; i < banksAmount; i++) {
      const bankName = values[i + INDENT];
      this.COLUMNS_MAP['someBank' + i] = [bankName, keys[i + INDENT]];
      const letter = this.calculateLetter(i + INDENT);
      for (let j = 2; j <= this.rowCount + 1; j++) {
        const stringObj = firstSheet[letter + j];
        if (stringObj && stringObj.v && typeof stringObj.v === 'number') {
          const valueString = stringObj.w.split(' ');
          this.banksCurrencies[bankName] = valueString[valueString.length - 1];
          break;
        }
      }
    }

    for (let i = INDENT; i < values.length - banksAmount; i++)
      this.COLUMNS_MAP[COLUMNS_MAPkeys[i]][1] = keys[i + banksAmount];

    if (banksAmount < 1) this.throwError(errors.notEnoughAccounts, 1);

    this.COLUMNS_MAP.date = [
      'Дата',
      UPPER_ALPHABET[INDENT - 1],
      this.dateNotValidErrorer,
    ];

    this.banksAmount = banksAmount;

    return this.sheetJson;
  }

  private getSumAndType(allColumns: AnyObject, index: number) {
    const errors = this.getErrors();
    const bills = [];
    const bankNames = [];
    for (let i = 0; i < this.banksAmount; i++) {
      const bill = allColumns['someBank' + i];
      if (bill && bill !== ' ') {
        bills.push(bill);
        bankNames.push(this.COLUMNS_MAP['someBank' + i][0]);
      }
    }

    let accountFromId = '';
    let accountToId = '';
    let type = OperationType.TRANSFER;
    let sum = 0;
    let currencyFrom;
    let currencyTo;

    // console.log(this.banksCurrencies);

    if (bills.length === 2) {
      const bankFromName = bills[0] > 0 ? bankNames[1] : bankNames[0];
      const bankToName = bills[0] < 0 ? bankNames[1] : bankNames[0];
      accountFromId = bankFromName;
      currencyFrom = this.banksCurrencies[bankFromName];
      accountToId = bankToName;
      currencyTo = this.banksCurrencies[bankToName];
      // console.log('1: ', bankFromName, bankToName);
    } else if (bills.length === 1) {
      type = bills[0] > 0 ? OperationType.INCOME : OperationType.CONSUMPTION;
      if (type === OperationType.INCOME) {
        accountToId = bankNames[0];
        currencyTo = this.banksCurrencies[bankNames[0]];
      } else {
        accountFromId = bankNames[0];
        currencyFrom = this.banksCurrencies[bankNames[0]];
      }
      // console.log('2: ', bankNames);
    } else this.throwError(errors.tooMuchOrLittleOpInOneTransactions, index);

    sum = Math.abs(bills[0]);

    return [sum, type, accountFromId, accountToId, currencyFrom, currencyTo];
  }

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const allColumns = this.getAllColumns(operation, index);
    const {
      article,
      contractor,
      project,
      date,
      dateOfAccrual,
      description,
      ppNumber,
      order,
    } = allColumns;

    let isAfterAccrual, isBeforeAccrual, isAfter, isBefore;
    try {
      isAfterAccrual = moment(dateOfAccrual).isAfter(moment().add(10, 'y'));
      isBeforeAccrual = moment(dateOfAccrual).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      isAfter = moment(date).isAfter(moment().add(10, 'y'));
      isBefore = moment(date).isBefore(moment('2015-01-01', 'YYYY/MM/DD'));
    } catch (e) {
      console.log(e);
    }
    if (isAfterAccrual || isAfter) this.throwError(errors.maxDate, index);
    if (isBeforeAccrual || isBefore) this.throwError(errors.minDate, index);

    const [
      sum,
      type,
      accountFromIdName,
      accountToIdName,
      currencyFrom,
      currencyTo,
    ] = this.getSumAndType(allColumns, index);

    const resO: AnyObject = {
      index,
      date: this.dateToFormat2(date || dateOfAccrual),
      dateOfPayment: this.dateToFormat2(dateOfAccrual || date),
      sum,
      type,
    };

    this.addIfNotFalsy(resO, {
      category: article,
      counterparty: String(contractor),
      project,
      accountFromId: accountFromIdName,
      accountToId: accountToIdName,
      subType: this.getSubType(type as OperationType, contractor),
    });

    const curFrom = CURRENCIES.find((cur) => cur.symbol === currencyFrom)?.code;
    const curTo = CURRENCIES.find((cur) => cur.symbol === currencyTo)?.code;

    this.setAccountIDsByType(resO, curFrom, curTo);

    this.setComments(resO, [
      description,
      ppNumber,
      order ? 'заказ №' + order : null,
    ]);

    if (resO.type === 'transfer') {
      const anotherBanksSum = Object.keys(allColumns)
        .filter(
          (key) =>
            key.includes('someBank') && typeof allColumns[key] === 'number',
        )
        .map((key) => allColumns[key]);
      if (anotherBanksSum.length === 2) {
        const sum =
          anotherBanksSum[0] >= 0 ? anotherBanksSum[0] : anotherBanksSum[1];
        const companyCurrencySum =
          anotherBanksSum[0] <= 0 ? anotherBanksSum[0] : anotherBanksSum[1];
        if (sum >= 0 && companyCurrencySum <= 0) {
          resO.sum = sum;
          resO.companyCurrencySum = Math.abs(companyCurrencySum);
        }
      }
    }
    return resO;
  }
}
