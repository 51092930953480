import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';

import Card from '../../../assets/images/svg/bank-card.svg';
import UnVisibleIcon from '../../../assets/images/svg/eye-hide.svg';
import VisibleIcon from '../../../assets/images/svg/eye-open.svg';
import TrashIcon from '../../../assets/images/svg/trash-red.svg';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../../components/PreviewRegisterDialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../components/TextField/TextFieldComponent.types';
import useRestricted from '../../../hooks/useRestricted';
import accountActions from '../../../store/accounts/actions';
import { getCryptoCurrencies } from '../../../store/currency/selectors';
import { formatStringPrice } from '../../../utils/parseStringToNumber';
import IntegrationDialog from '../IntegrationDialog';
import RemoveAccountDialog from './RemoveAccountDialog';
import RemoveIntegrationDialog from './RemoveIntegrationDialog';
import { useStyles } from './styles';
import { Props } from './types';

function EditAccountDialog(props: Props) {
  const {
    onClose,
    account,
    loading,
    onUpdate,
    onDelete,
    accounts,
    removeIntegration,
    onCloseAccountsDialog,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cryptoCurrencies = useSelector(getCryptoCurrencies);

  const isCrypto = cryptoCurrencies.find(
    (el) => el.code === account?.currency.code,
  );

  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [balance, setBalance] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showIntegrationOffDialog, setShowIntegrationOffDialog] =
    useState(false);
  const [showIntegrationsDialog, setShowIntegrationsDialog] = useState(false);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);
  const {
    fullAccess,
    deleteAccess,
    fastPermissionsType,
    showRestrictedDialog,
  } = useRestricted();

  const [callback, setCallback] = useState<any | null>(null);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
    setCallback(null);
  }, []);

  const handleApplyCallback = useCallback(() => {
    setShowPreviewRegisterDialog(false);

    if (callback) {
      callback();
    }
  }, [callback]);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleCloseIntegrationsDialog = useCallback(() => {
    setShowIntegrationsDialog(false);
  }, []);

  const handleOpenIntegrationOffDialog = useCallback(() => {
    setShowIntegrationOffDialog(true);
  }, []);

  const handleCloseIntegrationOffDialog = useCallback(() => {
    setShowIntegrationOffDialog(false);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleChangeBalance = useCallback(
    (value: string | number) => {
      const balanceDigits = isCrypto ? 9 : 3;

      const price = formatStringPrice(value, true, balanceDigits);

      setBalance(price);
    },
    [isCrypto],
  );

  const handleChangeName = useCallback((value: string) => {
    setName(value);
    setError('');
  }, []);

  const handleChangeVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleUpdate = useCallback(() => {
    if (!name) {
      setError(t('system.fieldMustFilled'));

      return;
    }

    const isAccountExist = accounts.some(
      (el) => el.normalizedLabel === normalize(name) && el._id !== account?._id,
    );

    if (isAccountExist) {
      setError(t('errors.create.account.exist'));

      return;
    }

    onUpdate(name, Number(balance) || 0, visible);
  }, [onUpdate, name, balance, visible, t, accounts, account]);

  const handleRemove = useCallback(() => {
    if (account) {
      onDelete(account._id);
    }
    handleCloseRemoveDialog();
  }, [onDelete, account, handleCloseRemoveDialog]);

  const handleRemoveIntegration = useCallback(
    (removeAll: boolean) => {
      if (account && account.integration) {
        removeIntegration(account.integration._id, removeAll);
      }

      handleCloseIntegrationOffDialog();
    },
    [removeIntegration, account, handleCloseIntegrationOffDialog],
  );

  const handleOpenRemoveDialog = useCallback(() => {
    if (
      account &&
      (account.hasOperations ||
        !Object.prototype.hasOwnProperty.call(account, 'hasOperations'))
    ) {
      setShowRemoveDialog(true);
    } else {
      handleRemove();
    }
  }, [account, handleRemove]);

  const handleOpenIntegrationsDialog = useCallback(() => {
    setShowIntegrationsDialog(true);
  }, []);

  const handleClick = useCallback(
    (fn: any) => {
      setCallback(() => fn);

      handleOpenPreviewRegisterDialog();
    },
    [handleOpenPreviewRegisterDialog],
  );

  const handleHide = useCallback(() => {
    if (account) {
      account.visible = false;

      dispatch(accountActions.updateGroupAccounts([account]));
    }
  }, [dispatch, account]);

  const checkRemovePermissions = useCallback(() => {
    if (fastPermissionsType && (deleteAccess || fullAccess)) {
      handleOpenRemoveDialog();
    } else {
      showRestrictedDialog(handleOpenRemoveDialog);
    }
  }, [
    fullAccess,
    deleteAccess,
    fastPermissionsType,
    showRestrictedDialog,
    handleOpenRemoveDialog,
  ]);

  useEffect(() => {
    if (account) {
      const { name: accName, startingBalance, visible: accVisible } = account;

      setName(accName);
      setVisible(accVisible);
      setBalance(String(startingBalance));
    }
  }, [account]);

  return (
    <>
      <Dialog title={t('accounts.editTitle')} isOpened onClose={onClose}>
        <div className={classes.fieldWrapper}>
          <TextFieldComponent
            ga4Id={Ga4Errors.editAccountNameError}
            onChange={handleChangeName}
            value={name}
            placeholder={t('common.title')}
            isError={Boolean(error)}
            errorText={error}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <TextFieldComponent
            onChange={handleChangeBalance}
            value={balance}
            placeholder={`${t('settingsPage.startBalance')}, ${
              account?.currency.symbol
            }`}
          />
        </div>
        <>
          {Boolean(account?.integration) && (
            <div
              onClick={handleOpenIntegrationOffDialog}
              className={cn(classes.row, classes.link)}
            >
              <img src={Card} alt="card" className={classes.icon} />
              <Typography className={classes.defaultText}>
                {t('bank.off')}
              </Typography>
            </div>
          )}
          {!account?.integration && !isCrypto && (
            <div
              onClick={() => handleClick(handleOpenIntegrationsDialog)}
              className={cn(classes.row, classes.link)}
            >
              <img src={Card} alt="card" className={classes.icon} />
              <Typography className={classes.defaultText}>
                {t('bank.set')}
              </Typography>
            </div>
          )}
        </>
        <div
          onClick={() => handleClick(handleChangeVisible)}
          className={cn(classes.row, classes.link)}
        >
          {visible ? (
            <img alt="" src={VisibleIcon} className={classes.icon} />
          ) : (
            <img alt="" src={UnVisibleIcon} className={classes.icon} />
          )}
          <Typography className={classes.defaultText}>
            {visible ? t('common.hide') : t('common.show')}
          </Typography>
        </div>
        <div
          onClick={() => handleClick(checkRemovePermissions)}
          className={cn(classes.row, classes.link, classes.lastBlock)}
        >
          <img alt="" src={TrashIcon} className={classes.icon} />
          <Typography className={cn(classes.defaultText, classes.deleteText)}>
            {t('common.remove')}
          </Typography>
        </div>
        <CustomButton
          action={() => handleClick(handleUpdate)}
          title={t('saveChanges')}
          fullWidth
          loading={loading}
        />
      </Dialog>
      {showRemoveDialog && (
        <RemoveAccountDialog
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemove}
          onHide={handleHide}
          name={account?.name || ''}
        />
      )}
      {showIntegrationOffDialog && (
        <RemoveIntegrationDialog
          onClose={handleCloseIntegrationOffDialog}
          onRemove={handleRemoveIntegration}
        />
      )}
      {showIntegrationsDialog && (
        <IntegrationDialog
          onClose={handleCloseIntegrationsDialog}
          onCloseAccountsDialog={onCloseAccountsDialog}
          account={account!}
        />
      )}
      {showPreviewRegisterDialog && callback && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleApplyCallback}
        />
      )}
    </>
  );
}

export default React.memo(EditAccountDialog);
