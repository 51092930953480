import axios from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLeading } from 'redux-saga/effects';

import { ENV_BASE_URL } from '../../constants/env';
import { GET_PAYMENT_HISTORY } from './actions';

function* paymentHistorySagaWorker(): SagaIterator {
  const { token } = JSON.parse(localStorage.getItem('finmap-user') as string);

  const data = yield call(axios.get, `${ENV_BASE_URL}/transactions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  yield put({ type: GET_PAYMENT_HISTORY, payload: data.data });
}

export default function paymentHistorySagaWatcher() {
  return all([takeLeading(GET_PAYMENT_HISTORY, paymentHistorySagaWorker)]);
}
