import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectCurrentCompany } from '../../../store/company/selectors';
import nordigenActions from '../../../store/integrations/nordigenActions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import {
  IntegrationTypeId,
  NordigenAccount,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { getBalance } from '../../../utils/getBalance';
import ErrorDialog from '../../Integrations/ErrorDialog';
import AccountSelector from '../../Operations/Components/AccountSelector';
import { useStyles } from './styles';

function NordigenAccountsDialog({
  onCloseAccountsListDialog,
}: {
  onCloseAccountsListDialog(): void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facebookContext = useContext(FacebookPixelContext);

  const [accounts, setAccounts] = useState<NordigenAccount[] | null>(null);
  const [currentAccount, setCurrentAccount] = useState<NordigenAccount | null>(
    null,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const company = useSelector(selectCurrentCompany);

  const handleCloseErrorDialog = useCallback(() => {
    Storages.remove(StorageKey.nordigen);

    setAccounts(null);
    setShowErrorDialog(false);

    onCloseAccountsListDialog();
  }, [onCloseAccountsListDialog]);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);

    Storages.remove(StorageKey.nordigen);
  }, []);

  const handleChangeAccount = useCallback((val: any) => {
    setCurrentAccount(val);
  }, []);

  const handleIntegrationStart = useCallback(async () => {
    const nordigenIntegration = Storages.get(StorageKey.nordigen);

    setIntegrationLoading(true);

    if (nordigenIntegration && currentAccount) {
      const {
        integrationId,
        accountName,
        accountId: storedAccountId,
        startDate,
        bankName,
      } = nordigenIntegration;

      let accountId = storedAccountId;

      if (!accountId) {
        const {
          data: { newAccount },
        } = await accountsApi.createAccount(
          currentAccount.currency,
          accountName,
          currentAccount.amount,
          IntegrationTypeId.Nordigen,
        );

        accountId = newAccount._id;
      }

      dispatch(nordigenActions.setNordigenIntegrationId(integrationId));

      const payload: CreateIntegrationPayload = {
        typeId: IntegrationTypeId.Nordigen,
        accountId,
        startDate: new Date(startDate).getTime() + getTimeOffset(),
        bankName,
        facebookContext,
      };

      await nordigenApi.updateCredentials({
        integrationId,
        accountId: currentAccount.accountId,
        externalAccountCurrency: currentAccount.externalAccountCurrency,
      });

      dispatch(nordigenActions.createNordigenIntegration(payload));

      setIntegrationLoading(false);
      handleCloseDialog();

      onCloseAccountsListDialog();
    }
  }, [
    dispatch,
    currentAccount,
    handleCloseDialog,
    facebookContext,
    onCloseAccountsListDialog,
  ]);

  useEffect(() => {
    const nordigenIntegration = Storages.get(StorageKey.nordigen);

    if (!nordigenIntegration) {
      return;
    }

    const getNordigenAccounts = async () => {
      try {
        setLoading(true);

        const { data: nordigenAccounts } =
          await nordigenApi.getIntegrationAccounts(
            nordigenIntegration.integrationId,
          );

        setLoading(false);

        if (nordigenAccounts.status) {
          Storages.remove(StorageKey.nordigen);
        } else {
          const formatAccounts = nordigenAccounts.map(
            (el: NordigenAccount) => ({
              ...el,
              name: `${el.name || ''} ${getBalance(el.amount)} ${el.currency}`,
            }),
          );

          setAccounts(formatAccounts);
          setShowDialog(true);
        }
      } catch (e) {
        setLoading(false);
        setShowErrorDialog(true);
      }
    };

    if (nordigenIntegration?.integrationId && !showErrorDialog) {
      getNordigenAccounts();
    }
  }, [company, showErrorDialog]);

  if (showErrorDialog) {
    return <ErrorDialog onClose={handleCloseErrorDialog} />;
  }

  if (loading) {
    return (
      <div className={classes.overlay}>
        <Loader size="medium" />
      </div>
    );
  }

  const nordigenIntegration = Storages.get(StorageKey.nordigen);

  if (!nordigenIntegration) {
    return null;
  }

  return (
    <Dialog
      isOpened={showDialog}
      onClose={handleCloseDialog}
      title={t('operationDialogs.account.placeholder')}
    >
      <AccountSelector
        // @ts-ignore
        value={currentAccount}
        // @ts-ignore
        accounts={accounts}
        onChange={handleChangeAccount}
        disablePortal={false}
      />
      <CustomButton
        fullWidth
        action={handleIntegrationStart}
        loading={integrationLoading}
        title={t('bank.connectAccount')}
      />
    </Dialog>
  );
}

export default React.memo(NordigenAccountsDialog);
