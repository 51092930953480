import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import PosterApi from '../../../services/bankIntegrations/poster';
import { GetLinkResponse } from '../../../services/bankIntegrations/poster.types';
import Storages, { StorageKey } from '../../../services/Storages';
import { minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { Props } from './types';

const maxIntegrationDate =
  moment()
    .subtract(1, 'day')
    .set({ hours: 0, minutes: 0, seconds: 0 })
    .valueOf() + getTimeOffset();

function PosterDialog(props: Props) {
  const { onClose, selectedAccountId, newAccountName } = props;

  const { t } = useTranslation();

  const [errorDate, setErrorDate] = useState(false);
  const [errorRequest, setErrorRequest] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);

  const handleChangeDate = useCallback((value: any) => {
    setErrorDate(false);
    setSelectedDate(value);
  }, []);

  const handleGetLink = useCallback(async () => {
    if (!selectedDate) {
      return setErrorDate(true);
    }

    setLoading(true);

    try {
      const { data }: AxiosResponse<GetLinkResponse> =
        await PosterApi.getLink();

      Storages.put(StorageKey.poster, {
        startDate: selectedDate,
        accountId: selectedAccountId,
        newAccountName,
      });

      window.location.href = data.url;
    } catch (e) {
      setErrorRequest(t('system.error'));
    }

    return setLoading(false);
  }, [t, selectedDate, selectedAccountId, newAccountName]);

  return (
    <Dialog isOpened onClose={onClose} title={t('bank.Poster.title')}>
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        // @ts-ignore
        value={selectedDate}
        fullWidth
        maxDate={new Date(maxIntegrationDate)}
        minDate={minOperationDate()}
        error={errorDate}
        fromIntegration
      />
      <CustomButton
        fullWidth
        title={t('bank.facturownia.connectToLogin')}
        action={handleGetLink}
        loading={loading}
        errorText={errorRequest}
      />
    </Dialog>
  );
}

export default React.memo(PosterDialog);
