import { createSelector } from 'reselect';

import { AppState } from '../reducers';

export const getProjects = (state: AppState) => state.projects.projects;
export const getLanguage = (state: AppState) => state.company.lng;

export const selectLoadingProjects = createSelector(
  getProjects,
  (projects) => projects.loading,
);

export const selectOperationProjects = createSelector(
  getProjects,
  (projects) => projects.operation,
);

export const selectSettingsProjects = createSelector(
  getProjects,
  (projects) => projects.settings,
);

export const selectOperationProjectsToAutoComplete = createSelector(
  selectOperationProjects,
  (projects) =>
    projects.map((project) => ({
      _id: project._id,
      id: project._id,
      name: project.label,
      label: project.label,
    })),
);
