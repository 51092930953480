import { makeStyles } from '@material-ui/core/styles';

import { jade } from '../../../theme/colors';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18,
  },
  paymentMethodRoot: {
    display: 'flex',
    marginTop: 16,
  },
  leftText: {
    opacity: 0.5,
    width: '50%',
  },
  paymentMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentMethodCardWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  paymentMethodWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
  },
  paymentMethodText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
    color: '#00B28E',
    cursor: 'pointer',
  },
  marginLeft8: {
    marginLeft: 8,
  },
  nextPaymentRoot: {
    display: 'flex',
    marginTop: 18,
  },
  nextPaymentBtnsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  nextPaymentBtn: {
    padding: '4px 16px',
    background: 'rgba(0, 178, 142, 0.16)',
    borderRadius: 8,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '20px',
    color: '#00B28E',
    cursor: 'pointer',
    height: 28,
    maxWidth: 141,
  },
  infoTitle: {
    marginLeft: 8,
    width: '100%',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 16,
  },
  bold: {
    fontWeight: 'bold',
  },
  active: {
    color: jade,
  },
});
