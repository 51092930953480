import { AnyAction } from 'redux';

import {
  CREATE_INVITE_SUCCESS,
  CREATE_ROLE_SUCCESS,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_INVITE_SUCCESS,
  DELETE_ROLE_SUCCESS,
  EMPLOYEES_INIT,
  GET_INVITES_SUCCESS,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_RESOURCES_SUCCESS,
  GET_ROLE_PERMISSIONS_PENDING,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLES_SUCCESS,
  RESET_CURRENT_ROLE_PERMISSIONS,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_LIST_ROLES,
  UPDATE_ROLE_SUCCESS,
} from './actions';
import { EmployeesState } from './types';

const initialState: EmployeesState = {
  employees: [],
  invites: [],
  roles: [],
  permissionTypes: [],
  resources: [],
  currentRolePermissions: null,
  loadingRolePermissions: false,
  currentCreatedRole: null,
};

// eslint-disable-next-line default-param-last
const companyReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case EMPLOYEES_INIT: {
      return {
        ...initialState,
      };
    }

    case UPDATE_LIST_ROLES: {
      return {
        ...state,
        roles: payload.roles,
      };
    }

    case CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        roles: payload.data.roles,
        currentCreatedRole: payload.data.role,
      };
    }

    case GET_ROLES_SUCCESS:
    case UPDATE_ROLE_SUCCESS:
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: payload.data,
      };

    case UPDATE_EMPLOYEE_SUCCESS:
    case DELETE_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employees: payload.data,
      };
    }

    case DELETE_INVITE_SUCCESS: {
      return {
        ...state,
        invites: payload.data,
      };
    }

    case GET_INVITES_SUCCESS:
    case CREATE_INVITE_SUCCESS: {
      return {
        ...state,
        employees: payload.data.employees,
        invites: payload.data.invites,
      };
    }

    case GET_PERMISSION_TYPES_SUCCESS:
      return {
        ...state,
        permissionTypes: payload.data,
      };

    case GET_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: payload.data,
      };

    case GET_ROLE_PERMISSIONS_PENDING: {
      return {
        ...state,
        loadingRolePermissions: true,
      };
    }

    case GET_ROLE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        currentRolePermissions: payload.data,
        loadingRolePermissions: false,
      };
    }

    case RESET_CURRENT_ROLE_PERMISSIONS: {
      return {
        ...state,
        currentRolePermissions: null,
        currentCreatedRole: null,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
