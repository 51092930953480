import { catskillWhite, persianGreen, royalBlue } from "../../../../theme/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  imagesContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  link: {
    display: 'inline-block',
  },
  linkText: {
    color: persianGreen,
    cursor: 'pointer',
  },
  descriptionContainer: {
    marginBottom: 25,
  },
  imagesRootContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
  },
  imageContainer: {
    height: 108,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '0 38px',
  },
  firstImageContainer: {
    position: 'relative',
    background: royalBlue,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  secondImageContainer: {
    background: catskillWhite,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  andIcon: {
    position: 'absolute',
    right: -18,
    top: 36,
  },
  bankName: {
    fontWeight: "bold",
  },
  separator: {
    marginBottom: 25,
  },
  icon: {
    width: 25,
    height: 25,
  },
  separatorText: {
    marginRight: 5,
    display: 'inline',
  }
})
