import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showError } from '../../utils/showError';
import {
  GET_ACTIVITIES_PENDING,
  GET_ACTIVITIES_SUCCESS,
  GET_SCHEDULE_PERIODS_PENDING,
  GET_SCHEDULE_PERIODS_SUCCESS,
  GET_VERSION_PENDING,
  GET_VERSION_SUCCESS,
  GET_VERSION_UI_PENDING,
  GET_VERSION_UI_SUCCESS,
} from './actions';
import api from './api';

export function* getActivities() {
  try {
    const { data } = yield call(api.getActivities);

    yield put({ type: GET_ACTIVITIES_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getSchedulePeriods() {
  try {
    const { data } = yield call(api.getSchedulePeriods);

    yield put({ type: GET_SCHEDULE_PERIODS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getVersion() {
  try {
    const { data } = yield call(api.getVersion);

    yield put({ type: GET_VERSION_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getVersionUI() {
  try {
    const { data } = yield call(api.getVersionUI);

    yield put({ type: GET_VERSION_UI_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export default function commonSagas() {
  return all([
    takeLatest(GET_SCHEDULE_PERIODS_PENDING, getSchedulePeriods),
    takeLatest(GET_VERSION_PENDING, getVersion),
    takeLatest(GET_VERSION_UI_PENDING, getVersionUI),
    takeLatest(GET_ACTIVITIES_PENDING, getActivities),
  ]);
}
