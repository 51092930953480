import { DEFAULT_LANGUAGE, languagesData } from '../constants';
import Storages, { StorageKey } from '../services/Storages';

export const getCurrentLanguage = () =>
  Storages.get(StorageKey.language)?.toUpperCase() ?? '';

export const checkAllowLanguage = (lng: string) => {
  const allowLanguages = languagesData(true).find(
    (el) => el.shortName.toLowerCase() === lng.toLowerCase(),
  );

  if (allowLanguages) {
    return lng;
  }

  const language = languagesData(true).find(
    (el) => lng.toLowerCase().indexOf(el.shortName.toLowerCase()) !== -1,
  );

  if (language) {
    return language.shortName;
  }

  return DEFAULT_LANGUAGE;
};
