import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SHOW_SUGGEST_TARIFF_LABEL } from '../../../../constants/featureToggles/featureToggle';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import useUnleash from '../../../../hooks/useUnleash';
import { useStyles } from '../styles';

type Props = {
  active: boolean;
  shouldBePro?: boolean;
  isSuitYou?: boolean;
};
function Badge({ active, shouldBePro, isSuitYou }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const subscriptionActive = useSubscriptionActive();

  const showSuggestTariffLabel = useUnleash(SHOW_SUGGEST_TARIFF_LABEL);

  if (active) {
    return (
      <div className={cn(classes.badge, classes.activeBadge)}>
        <Typography className={classes.badgeText}>
          {subscriptionActive
            ? t('subscription.active')
            : t('subscription.current')}
        </Typography>
      </div>
    );
  }

  if (isSuitYou && showSuggestTariffLabel) {
    return (
      <div className={classes.badge}>
        <Typography className={classes.badgeText}>
          {t('subscription.suitYou')}
        </Typography>
      </div>
    );
  }

  if (shouldBePro) {
    return (
      <div className={classes.badge}>
        <Typography className={classes.badgeText}>
          {t('subscription.recommend')}
        </Typography>
      </div>
    );
  }

  return null;
}

export default React.memo(Badge);
