export type Props = {
  data: OtherPayloadProps | null;
  incomeDate: number;

  onClose(): void;
  onSave(payload: OtherPayloadProps): void;
};

export type Period = {
  id: number;
  name: string;
};

export type OtherPayloadProps = {
  scheduleEndDate: number;
  scheduleMaxCount: number;
  scheduleInterval: number;
  scheduleIntervalType: number;
  scheduleDays: string;
};

export enum FinishProps {
  never = 'never',
  end = 'end',
  date = 'date',
  after = 'after',
}
