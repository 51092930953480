import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import IntegrationTGIcon from '../../../assets/images/svg/integration-telegram.svg';
import IntegrationApiIcon from '../../../assets/images/svg/intergation-api.svg';
import IntegrationIcon from "../../../assets/images/svg/satellite.svg";
import { JournalData } from "../../../store/journal/types";
import { blueRibbon, jungleGreen, pictonBlue3 } from "../../../theme/colors";
import { useStyles } from "./styles";

export type Props = {
  editProps: JournalData;
}

function RenderBadge(props: Props) {
  const { editProps } = props;
  const { integrationId, botId, externalId, importId } = editProps;

  const classes = useStyles();

  const { t } = useTranslation();

  if (importId) {
    return null
  }

  if (!externalId) {
    return null
  }

  let Icon = IntegrationIcon;
  let title = t('bank.integrationBadge');
  let background = blueRibbon;

  if (botId) {
    Icon = IntegrationTGIcon;
    title = t('bank.integrationTGBadge');
    background = pictonBlue3;
  } else if (!integrationId) {
    Icon = IntegrationApiIcon;
    title = t('bank.integrationAPIBadge');
    background = jungleGreen;
  }

  return (
    <div className={classes.integrationBadge} style={{ background }}>
      <img
        className={classes.icon}
        src={Icon}
        alt="integration"
      />
      <Typography className={classes.integrationBadgeText}>
        {title}
      </Typography>
    </div>
  )
}

export default React.memo(RenderBadge);
