import Http from '../Http';

const getLink = (redirectUrl: string) =>
  Http.makeInstance().post('/integrations/idokladApiCredentials/getLink', {
    redirectUrl,
  });

const setTokens = (code: string, integrationCredentialId: string) =>
  Http.makeInstance().post('/integrations/idokladApiCredentials/setTokens', {
    code,
    integrationCredentialId,
  });

export default {
  getLink,
  setTokens,
};
