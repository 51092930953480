import React, { useCallback, useState } from 'react';
import ReactPlayer, { Config } from 'react-player';

import Loader from '../../Loader/LottieLoader';
import { useStyles } from './styles';
import { Props } from './types';

function Video(props: Props) {
  const { url, onEnded, autostart, height } = props;

  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const config: Config = {
    vimeo: {
      playerOptions: {
        controls: true,
        autoplay: autostart,
      },
    },
  };

  const onReady = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <Loader size="small" />
        </div>
      )}
      <ReactPlayer
        url={url}
        onEnded={onEnded}
        config={config}
        onReady={onReady}
        width="unset"
        height={height}
      />
    </>
  );
}

export default React.memo(Video);
