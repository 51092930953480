import { AnyAction } from 'redux';

import Storages, { StorageKey } from '../../services/Storages';
import { START_LOADING_PENDING } from '../auth/actions';
import {
  CREATE_API_KEY_SUCCESS,
  CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING,
  CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS,
  CREATE_COMPANY_FAILED,
  CREATE_COMPANY_PENDING,
  CREATE_COMPANY_SUCCESS,
  CREATE_FIRST_COMPANY_FULFILLED,
  DELETE_COMPANY_PENDING,
  DELETE_COMPANY_SUCCESS,
  GET_API_KEYS_SUCCESS,
  GET_COMPANIES_FAILED,
  GET_COMPANIES_LIST_SUCCESS,
  GET_COMPANIES_PENDING,
  GET_COMPANIES_SETTINGS_LIST_SUCCESS,
  GET_COMPANIES_SUCCESS,
  REMOVE_API_KEY_SUCCESS,
  SET_CURRENT_COMPANY,
  SET_LANGUAGE,
  webHookTypes,
} from './actions';
import { Company, CompanyState } from './types';

export const getDefaultLanguage = () => {
  let language;

  try {
    language = Storages.get(StorageKey.language);
  } catch (e) {
    language = 'ru';
  }

  return language;
};
const initialState: CompanyState = {
  errors: {},
  isError: false,
  loading: false,
  companies: [],
  isButtonVisible: false,
  currentCompany: null,
  lng: getDefaultLanguage(),
  apiKeys: [],
  webhooks: [],
  companiesSettings: [],
};

// eslint-disable-next-line default-param-last
const companyReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case webHookTypes.UPDATE_WEBHOOK_SUCCESS:
    case webHookTypes.CREATE_WEBHOOK_SUCCESS:
    case webHookTypes.REMOVE_WEBHOOK_SUCCESS:
    case webHookTypes.GET_WEBHOOKS_SUCCESS: {
      return {
        ...state,
        webhooks: payload.data,
      };
    }

    case CREATE_API_KEY_SUCCESS:
    case REMOVE_API_KEY_SUCCESS:
    case GET_API_KEYS_SUCCESS: {
      return {
        ...state,
        apiKeys: payload.data,
      };
    }

    case SET_LANGUAGE: {
      Storages.put(StorageKey.language, payload.lng);

      return {
        ...state,
        lng: payload.lng,
      };
    }

    case START_LOADING_PENDING: {
      return {
        ...state,
        loadingAccounts: true,
        loadingFuturePayments: true,
      };
    }

    case GET_COMPANIES_PENDING:
    case CREATE_COMPANY_PENDING:
    case CREATE_COMPANY_BY_ONBOARDING_FLOW_PENDING:
    case DELETE_COMPANY_PENDING:
      return {
        ...state,
        errors: {},
        isError: false,
        loading: true,
      };

    case GET_COMPANIES_FAILED:
    case CREATE_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        errors: payload,
      };

    case CREATE_COMPANY_BY_ONBOARDING_FLOW_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_COMPANY_SUCCESS:
    case CREATE_FIRST_COMPANY_FULFILLED: {
      const newCompany =
        payload.data.companies.length > 1
          ? payload.data[payload.data.companies.length - 1]
          : payload.data.companies[0];

      return {
        ...state,
        companies: payload.data.companies,
        currentCompany: newCompany,
        errors: {},
        isError: false,
        loading: false,
      };
    }

    case SET_CURRENT_COMPANY: {
      const { id } = payload;

      const currentCompany = id
        ? state.companies?.find((c) => c._id === id)
        : null;

      return {
        ...state,
        currentCompany: currentCompany || null,
      };
    }

    case GET_COMPANIES_LIST_SUCCESS: {
      return {
        ...state,
        companies: payload.data,
      };
    }
    case GET_COMPANIES_SETTINGS_LIST_SUCCESS: {
      return {
        ...state,
        companiesSettings: payload.data,
      };
    }

    case DELETE_COMPANY_SUCCESS:
    case GET_COMPANIES_SUCCESS: {
      const companies: Company[] = payload.data;

      if (!companies.length) {
        return {
          ...state,
          ...initialState,
        };
      }

      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        companies,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
