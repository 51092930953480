import { AppState } from '../reducers';
import moment from 'moment';
import { createSelector } from 'reselect';

export const getCalendar = (state: AppState) => state.calendar.data;

export const selectCalendarData = createSelector(getCalendar, (data) =>
  data.map((item) => ({
    ...item,
    start: new Date(item.date),
    end: new Date(item.date),
    allDay: true,
  })),
);

export const getSelectedDay = (state: AppState) => state.calendar.selectedDay;
export const getPeriod = (state: AppState) => state.calendar.period;

export const getLoading = (state: AppState) => state.calendar.loading;

export const getJournalPeriodFilter = (state: AppState) =>
  state.calendar.journalPeriodFilter;

export const selectCalendarDayPropsByDate = (date: number) =>
  createSelector(getCalendar, (calendar) =>
    calendar.find(
      (el) =>
        moment(el.date).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD'),
    ),
  );
