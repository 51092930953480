import Http from '../../services/Http';
import { OperationSubType } from "../operations/types";
import {
  GetChartByTypeResponse,
  HistoryActionOperationPayload,
  HistoryUpdateOperationPayload
} from "./api.types";
import { AnalyticTypeReport } from './sagas.types';

const getQueryRow = (query: string) => (query ? `?${query}` : '');

const getHistoryJournal = (
  query: string,
  customQuery: string,
  offset: number,
  limit: number,
) =>
  Http.makeInstance().get(
    `/operations/history?offset=${offset}&${customQuery}&limit=${limit}&${query}`,
  );

const getAccountStatement = (id: string, query: string) =>
  Http.makeInstance().get(`/accounts/${id}/statement${getQueryRow(query)}`);

const getChartByType = (payload: GetChartByTypeResponse) => {
  const { type, query, chartType, typeFilter, operationType } = payload;

  return Http.makeInstance().post(
    `/analytics/${type}/${typeFilter}/${chartType}/${operationType}`,
    { query },
  );
};

const getMainChart = (type: AnalyticTypeReport, query: string) =>
  Http.makeInstance().post(`/analytics/${type}/main`, { query });

const getTableData = (type: AnalyticTypeReport, query: string) =>
  Http.makeInstance().post(`/analytics/${type}/table`, { query });

const getDebitData = (query: string) => {
  if (query) {
    return Http.makeInstance().get(`/analytics/debit?${query}`);
  }

  return Http.makeInstance().get('/analytics/debit');
}

const getCreditData = (query: string) => {
  if (query) {
    return Http.makeInstance().get(`/analytics/credit?${query}`);
  }

  return Http.makeInstance().get('/analytics/credit');
}

const getProjectsData = (query: string) => {
  if (query) {
    return Http.makeInstance().post(`/analytics/projects`, { query });
  }

  return Http.makeInstance().post('/analytics/projects');
};

const getDebitHistoryData = (date: number | null) => {
  if (date) {
    return Http.makeInstance().get(`/analytics/debit/history?date=${date}`);
  }

  return Http.makeInstance().get('/analytics/debit');
};

const getCreditHistoryData = (date: number | null) => {
  if (date) {
    return Http.makeInstance().get(`/analytics/credit/history?date=${date}`);
  }

  return Http.makeInstance().get('/analytics/credit');
};

const historyDeleteOperation = (payload: Omit<HistoryActionOperationPayload, 'location'>) => {
  const { operationId, historyId, type, subType } = payload;

  if (subType) {
    return Http.makeInstance().delete(`/operations/${type}/${subType}/${operationId}?undoHistoryId=${historyId}`);
  }

  return Http.makeInstance().delete(`/operations/${type}/${operationId}?undoHistoryId=${historyId}`);
}

const historyRestoreOperation = (payload: Omit<HistoryActionOperationPayload, 'location'>) => {
  const { operationId, historyId, type } = payload;
  let { subType } = payload;

  if (subType) {
    if(subType === 'loanrepayment')
      subType = OperationSubType.loanRepayment;
    return Http.makeInstance().patch(`/operations/${type}/${subType}/restore/${operationId}?undoHistoryId=${historyId}`);
  }

  return Http.makeInstance().patch(`/operations/${type}/restore/${operationId}?undoHistoryId=${historyId}`);
}

const historyUpdateOperation = (payload: Omit<HistoryUpdateOperationPayload, 'location'>) => {
  const { data, operationId, type, subType } = payload;

  if (subType) {
    return Http.makeInstance().patch(`/operations/${type}/${subType}/${operationId}`, { ...data });
  }

  return Http.makeInstance().patch(`/operations/${type}/${operationId}`, { ...data });
}

export default {
  getHistoryJournal,
  getAccountStatement,
  getDebitData,
  getCreditData,
  getProjectsData,
  getChartByType,
  getMainChart,
  getTableData,
  getDebitHistoryData,
  getCreditHistoryData,
  historyDeleteOperation,
  historyUpdateOperation,
  historyRestoreOperation
};
