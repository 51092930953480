import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class PostFinanceParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          const header = [
            'Date',
            'Texte de notification',
            'Crédit en CHF',
            'Débit en CHF',
            'Label',
            'Catégorie',
            'Valeur',
            'Solde en CHF'
          ];
          const headerIndex = importDocument.findIndex(arr => arr.every((el, i) => el === header[i]));
          const body = importDocument.slice(headerIndex + 1).filter(arr => moment(arr[0], 'DD.MM.YYYY', true).isValid());
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Date', 0]),
            comment: this.getFirstValidCellByColumn(['Texte de notification', 1]),
            credit: this.getFirstValidCellByColumn(['Crédit en CHF', 2]),
            debit: this.getFirstValidCellByColumn(['Débit en CHF', 3]),
          });
        },
        caseOptions: {
          defaultCurrency: 'CHF',
          preParserConfigs: { simpleParse: true, CSVDelimiter: ';' },
        },
      },
    ],
  };
}
