import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MonobankJarParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
            "defaultCurrency": "UAH",
            "preParserConfigs": {
              "interlineSpacingAccuracy": 10,
              "verticalAlign": "top",
              "prepareRawConfig": {
                "findHeader": {
                  "from": [
                    {
                      "word": {
                        "eq": "Дата операції"
                      },
                      "nextWord": {
                        "eq": "Найменування операції"
                      }
                    }
                  ],
                  "to": [
                    {
                      "word": {
                        "eq": "Підсумкова сума"
                      },
                      "prevWord": {
                        "eq": "Сума операції"
                      }
                    }
                  ]
                },
                "delete": [
                  {
                    "to": [
                      {
                        "word": {
                          "in": "% річних"
                        },
                        "prevWord": {
                          "eq": "Ставка за Депозитом:"
                        }
                      }
                    ],
                    "count": 1
                  },
                  {
                    "from": [
                      {
                        "word": {
                          "eq": "Дата операції"
                        },
                        "nextWord": {
                          "eq": "Найменування операції"
                        }
                      }
                    ],
                    "to": [
                      {
                        "word": {
                          "eq": "Підсумкова сума"
                        },
                        "prevWord": {
                          "eq": "Сума операції"
                        }
                      }
                    ]
                  },
                  {
                    "from": [
                      {
                        "word": {
                          "eq": "Операційний директор"
                        }
                      }
                    ],
                    "count": 1
                  }
                ],
                "define": [
                  {
                    "dateFormat": "DD.MM.YYYY HH:mm:ss"
                  }
                ]
              }
            },
            "proceedCaseConfig": {
              "fields": {
                "dateAndTime": {
                  "column": [
                    "Дата операції",
                    0
                  ]
                },
                "comment": {
                  "column": [
                    "Найменування операції",
                    1
                  ]
                },
                "sum": {
                  "column": [
                    "Сума операції",
                    2
                  ],
                  "split": {
                    "by": " ",
                    "get": 0
                  }
                }
              }
            }
          }
      }
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        "caseOptions": {
          "defaultCurrency": "UAH",
          "isDESCOpOrder": true,
          "withoutEmpty": true,
          "isCurCase": [
            [
              0,
              {
                "eq": "Дата та час"
              }
            ],
            [
              1,
              {
                "eq": "Категорія"
              }
            ],
            [
              2,
              {
                "eq": "Опис"
              }
            ]
          ],
          "proceedCaseConfig": {
            "fields": {
              "dateAndTime": {
                "column": [
                  "Дата та час",
                  0
                ]
              },
              "sum": {
                "column": [
                  "Сума",
                  3
                ]
              },
              "comment": {
                "column": [
                  "Категорія",
                  1
                ],
                "add": [
                  " ",
                  {
                    "column": [
                      "Опис",
                      2
                    ]
                  }
                ]
              }
            }
          }
        }
      }
    ],
  };
}
