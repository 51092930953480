import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 12,
    background: 'rgba(0, 0, 0, 0.06)',
    minHeight: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 192,
    cursor: 'pointer',
    marginBottom: 24,
    [theme.breakpoints.down(768)]: {
      width: '100%',
      marginBottom: 16,
    },
  },
  fullWidthButton: {
    width: '100%',
  },
  title: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 12px 10px 12px',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 20,
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
      '&:not(:last-child)': {
        marginBottom: 0,
      },
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  fondyIcon: {
    height: 24,
    marginRight: 12,
  },
  westernBidIcon: {
    marginRight: 12,
  },
  bankText: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  description: {
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 16,
  },
  customBankContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  writeUsText: {
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 16,
  },
  marginBottom48: {
    marginBottom: 48,
  },
  searchBlock: {
    display: 'flex',
  },
  searchBlockInput: {
    width: '100%',
  },
  safeInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 83,
    borderRadius: 18,
    background: 'rgba(235, 242, 245, 0.5)',
    marginBottom: 16,
    paddingLeft: 30,
    paddingRight: 30,
  },
  safeIcon: {
    marginRight: 16,
  },
}));
