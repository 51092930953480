import { aquaHaze, jade } from '../../../../theme/colors';
import { StyleProps } from '../ConfirmChangeRepeatDialog/types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: (props: StyleProps) => (props.isError ? 32 : 16),
    background: aquaHaze,
    borderRadius: 12,
    height: 56,
    boxSizing: 'border-box',
    transition: 'margin 700ms',
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
  },
  container: {
    flex: 1,
    padding: '9px 0',
  },
  selectedOptionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedOptionText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  label: {
    paddingLeft: '20px !important',
  },
  disabledInput: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  shortText: {
    display: 'block',
    width: '95%',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
});
