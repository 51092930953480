import { MenuItem, MenuList, Typography } from '@material-ui/core';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HistoryIcon from '../../../../assets/images/svg/history-gray.svg';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import PopperComponent from '../../../../components/PopperComponent';
import { getLanguage } from '../../../../selectors/main';
import { persianGreen } from '../../../../theme/colors';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import { useCompareStyles } from '../compareStyles';
import OthersDialog from './OthersDialog';
import { OtherPayloadProps } from './OthersDialog.types';
import { useStyles } from './Repeat.styles';
import { Props } from './Repeat.types';

function Repeat({
  onChangePeriod,
  repeats,
  incomeDate,
  isCompare,
  difference,
}: Props) {
  const classes = useStyles();
  const compareClasses = useCompareStyles();

  const { t } = useTranslation();
  const language = useSelector(getLanguage);

  const listPeriods = useCallback(
    () => [
      {
        id: 1000,
        name: t('operationDialogs.repeatDialog.notRepeat'),
      },
      {
        id: 2000,
        name: t('operationDialogs.repeatDialog.notRepeat'),
      },
      {
        id: 0,
        name: t('operationDialogs.repeatDialog.everyDay'),
      },
      {
        id: 1,
        name: `${t('operationDialogs.repeatDialog.everyWeek')} - ${moment(
          incomeDate - getTimeOffset(),
        )
          .locale(language)
          .format('dddd')
          .toLowerCase()}`,
      },
      {
        id: 2,
        name: `${t('operationDialogs.repeatDialog.everyMonth')} - ${moment(
          incomeDate - getTimeOffset(),
        )
          .locale(language)
          .format('DD')}-${t('customPeriod.go')} ${t('customPeriod.numbers')}`,
      },
      {
        id: 4,
        name: `${t('operationDialogs.repeatDialog.everyYear')} ${moment(
          incomeDate - getTimeOffset(),
        )
          .locale(language)
          .format('DD MMMM')}`,
      },
      {
        id: 3,
        name: t('operationDialogs.repeatDialog.other'),
      },
    ],
    [t, incomeDate, language],
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOthers, setIsOpenOthers] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpenOthersDialog = useCallback(() => {
    setIsOpenOthers(true);
  }, []);

  const handleCloseOthersDialog = useCallback(() => {
    setIsOpenOthers(false);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    if (!isCompare) {
      setIsOpen(!isOpen);
    }
  }, [isOpen, isCompare]);

  const renderOption = useCallback(
    (value: { id: number; name: string }) => {
      if (repeats.id === value.id) {
        return (
          <div className={classes.selectedOptionContainer}>
            <Typography className={classes.selectedOptionText}>
              {value.name}
            </Typography>
            <CheckIcon color={persianGreen} />
          </div>
        );
      }

      return (
        <div className={classes.selectedOptionContainer}>{value.name}</div>
      );
    },
    [repeats, classes],
  );

  const handleSaveOtherProps = useCallback(
    (payload: OtherPayloadProps) => {
      onChangePeriod({
        id: 3,
        payload,
      });
    },
    [onChangePeriod],
  );

  const currentRepeat = useMemo(
    () => listPeriods().find((pr) => pr.id === repeats.id),
    [repeats, listPeriods],
  );

  const RenderElement = useCallback(
    ({ item }: { item: { id: number; name: string } }) => {
      const handleSetPeriod = () => {
        if (item.id === 3) {
          handleOpenOthersDialog();
        } else {
          onChangePeriod(item);
          handleClose();
        }
      };

      return (
        <MenuItem id={String(item.id)} onClick={handleSetPeriod}>
          {renderOption(item)}
        </MenuItem>
      );
    },
    [handleClose, renderOption, onChangePeriod, handleOpenOthersDialog],
  );

  return (
    <>
      <ButtonRowWithIcons
        id="cypress-repeat-id"
        anchorRef={anchorRef}
        icon={HistoryIcon}
        onClick={handleToggle}
        label={
          currentRepeat?.id === 1000 || currentRepeat?.id === 2000
            ? t('operationDialogs.repeatDialog.makeRepeatable')
            : currentRepeat!.name
        }
        alt="history"
        disabled
        wrapClasses={cn(
          difference && compareClasses.root,
          isCompare && compareClasses.disabledInput,
        )}
      />
      {isOpen && (
        <PopperComponent
          anchorRef={anchorRef}
          onClose={handleClose}
          placement="top-start"
          width={anchorRef.current?.clientWidth}
        >
          <MenuList
            autoFocusItem={isOpen}
            id="menu-list-grow"
            className={classes.listRoot}
          >
            {listPeriods().map((el) => (
              <RenderElement key={String(el.id)} item={el} />
            ))}
          </MenuList>
        </PopperComponent>
      )}
      {isOpenOthers && (
        <OthersDialog
          data={repeats.payload || null}
          onSave={handleSaveOtherProps}
          onClose={handleCloseOthersDialog}
          incomeDate={incomeDate}
        />
      )}
    </>
  );
}

export default React.memo(Repeat);
