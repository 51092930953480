import Http from '../Http';
import { GetCredentialsPayload, UpdateCredentialsPayload } from "./ukrsib.types";

const getCredentials = (data: GetCredentialsPayload) =>
  Http.makeInstance().post('/integrations/ukrsibApiCredentials', {
    ...data,
  });

const updateCredentials = (data: UpdateCredentialsPayload) =>
  Http.makeInstance().patch('/integrations/ukrsibApiCredentials', {
    ...data,
  });

export default {
  getCredentials,
  updateCredentials,
};
