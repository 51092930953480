import { AnyAction } from 'redux';

import { ActionAutoRulesTypes } from './actions';
import { GetAutoRulesResponse } from './sagas.types';
import { AutoRulesState } from './types';

const initialState: AutoRulesState = {
  rules: [],
};

const autoRulesReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case ActionAutoRulesTypes.GET_AUTO_RULES_SUCCESS: {
      return {
        ...state,
        rules: (payload as GetAutoRulesResponse).rules,
      };
    }

    case ActionAutoRulesTypes.CREATE_AUTO_RULE_SUCCESS: {
      return {
        ...state,
        rules: (payload as GetAutoRulesResponse).rules,
      };
    }

    case ActionAutoRulesTypes.UPDATE_AUTO_RULE_ORDER_SUCCESS: {
      return {
        ...state,
        rules: (payload as GetAutoRulesResponse).rules,
      };
    }

    case ActionAutoRulesTypes.UPDATE_AUTO_RULE_SUCCESS: {
      return {
        ...state,
        rules: (payload as GetAutoRulesResponse).rules,
      };
    }

    case ActionAutoRulesTypes.DELETE_AUTO_RULE_SUCCESS: {
      return {
        ...state,
        rules: (payload as GetAutoRulesResponse).rules,
      };
    }

    default: {
      return state;
    }
  }
};

export default autoRulesReducer;
