import {
  PAYPAL_UNIVERSAL_CODE,
  PAYPAL_UNIVERSAL_NAME,
  WISE_UNIVERSAL_CODE,
  WISE_UNIVERSAL_NAME
} from "../../../constants";
import { SaltedgeBankType } from '../../../store/integrations/types';

export const defaultSaltedgeBanks: SaltedgeBankType[] = [
  {
    code: PAYPAL_UNIVERSAL_CODE,
    country_code: "XX",
    id: "6039",
    logo_url: "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/paypal_csv_xo.svg",
    max_consent_days: 90,
    name: PAYPAL_UNIVERSAL_NAME,
    status: "active"
  },
  {
    code: WISE_UNIVERSAL_CODE,
    country_code: "XX",
    id: "7335",
    logo_url: "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/xo/transferwise_xo.svg",
    max_consent_days: 90,
    name: WISE_UNIVERSAL_NAME,
    status: "active",
  },
  {
    code: "revolut_oauth_client_si",
    country_code: "XX",
    id: "4467",
    logo_url: "https://d1uuj3mi6rzwpm.cloudfront.net/logos/providers/gb/revolut_gb.svg",
    max_consent_days: 90,
    name: "Revolut",
    status: "active",
  },
];
