import { Button, Typography } from '@material-ui/core';
import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch,useSelector} from "react-redux";

import RestrictedIcon from '../../assets/images/svg/restricted-access.svg';
import Dialog from '../../components/Dialog/Dialog';
import commonActions from "../../store/common/actions";
import {getShowRestrictedDialog} from "../../store/common/selectors";
import { useStyles } from './styles';

function RestrictedAccessDialog() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showRestrictedDialog = useSelector(getShowRestrictedDialog);

  const Title = useCallback(() => (
    <div className={classes.titleContainer}>
      <img src={RestrictedIcon} alt="icon" className={classes.icon} />
      <Typography className={classes.title}>
        {t('employees.permissions.accessRestrictedTitle')}
      </Typography>
    </div>
  ), [t, classes]);

  const handleCloseRestrictedDialog = useCallback(() => {
    dispatch(commonActions.setShowRestrictedDialog(false));
  }, [dispatch]);

  if (!showRestrictedDialog) {
    return null;
  }

  return (
    <Dialog
      isOpened
      onClose={handleCloseRestrictedDialog}
      title={<Title />}
    >
      <Typography className={classes.description}>
        {t('employees.permissions.accessRestrictedDescription')}
      </Typography>

      <Button
        fullWidth
        className={classes.button}
        onClick={handleCloseRestrictedDialog}
        id="cypress-confirm-close-button"
      >
        <Typography className={classes.buttonText}>
          {t('employees.permissions.understand')}
        </Typography>
      </Button>
    </Dialog>
  );
}

export default React.memo(RestrictedAccessDialog);
