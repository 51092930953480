import moment from 'moment';

import { BasePdfParser } from '@finmap/import-parsers/base-pdf-parser';
import {
  AnyObject,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

// deprecated
export class ForteBankPdfParser extends BasePdfParser {
  protected COLUMNS_MAP = {
    index: ['№'],
    date: ['Дата'],
    num: ['Номер'],
    from: ['Отправитель (Наименование,'],
    to: ['Получатель (Наименование,'],
    debit: ['Дебет /'],
    credit: ['Кредит /'],
    comment: ['Төлемнің'],
    rate: ['Курс'],
  };

  protected COORDINATES = [
    2.0, 5.0, 7.0, 11.2, 19.6, 27.6, 33.1, 38.4, 47.5, 100,
  ];

  protected importName = 'ForteBank';

  protected DATE_FORMAT = 'DD.MM.YYYY';

  protected MULTY_FIELDS = ['date', 'num', 'from', 'to', 'comment'];

  protected currencyCode;

  protected doBeforeTranform() {
    let currencyRes;
    let over = false;
    for (const key in this.sheetJson[0]) {
      const arr = this.sheetJson[0][key].map(({ text }) => text);
      for (let i = 0; i < arr.length; i++) {
        const text = arr[i];
        for (const currency of CURRENCIES) {
          if (
            text.toString() === currency.code &&
            arr[i - 1].toString() === 'Валюта:'
          ) {
            currencyRes = currency.code;
            over = true;
            break;
          }
        }
        if (over) break;
      }
      if (over) break;
    }
    this.currencyCode = currencyRes;
    return super.doBeforeTranform();
  }

  protected beforeTransformFilter(operation, result) {
    const isValidDate = moment(
      operation.date[0],
      this.DATE_FORMAT,
      true,
    ).isValid();
    if (!isValidDate) {
      for (const key of this.MULTY_FIELDS) {
        if (operation[key] && result[result.length - 1]) {
          if (!result[result.length - 1][key])
            result[result.length - 1][key] = [];
          result[result.length - 1][key].push(...operation[key]);
        }
      }
    }
    return isValidDate;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const { date, from, to, debit, credit, comment, rate } = operation;
    const errors = this.getErrors();
    const correctDate = this.dateParser(
      `${date.length ? `${date[0]} ${date[1]}` : date}`,
    );
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(correctDate, this.DATE_FORMAT).isAfter(
        moment().add(10, 'y'),
      );
      isBefore = moment(correctDate, this.DATE_FORMAT).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const parsedDebit = this.parseSum(debit);
    const parsedCredit = this.parseSum(credit);
    const currencySymbol = this.currencyCode;
    const parsedSum = parsedDebit > parsedCredit ? parsedDebit : parsedCredit;
    if (isNaN(parsedSum))
      this.throwError(
        `${parsedDebit > parsedCredit ? debit : credit} - ${
          errors.sumNotValid
        }`,
        index,
      );
    const dateResult = moment(correctDate).format('YYYY-MM-DD HH:mm:ss');

    const cur = CURRENCIES.find((cur) => cur.code === currencySymbol)?.code;

    const result: any = {
      index,
      date: dateResult,
      comment: comment?.join(' '),
      sum: parsedSum,
      counterparty:
        parsedDebit > parsedCredit
          ? to?.join(' ')?.split('БИН:')[0]
          : from?.join(' ')?.split('БИН:')[0],
    };
    if (cur) result.currency = cur;

    if (parsedDebit > parsedCredit) {
      result.type = OperationType.CONSUMPTION;
      result.subType = 'supplier';
      result.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      result.type = OperationType.INCOME;
      result.subType = 'sale';
      result.accountToId = this.mainAccount.normalizedLabel;
    }

    this.setAccountIDsByType(result, cur, cur);
    return result;
  }
}
