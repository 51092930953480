import Http from '../../services/Http';
import {
  CreateOnboardingStepPayload,
  UpdateOnboardingStepPayload,
} from '@finmap/core-constants';

const getOnboardingInfo = () => Http.makeInstance().get('/onboarding');

const createOnboardingDemoData = () =>
  Http.makeInstance().post('/onboarding/demoData');

const removeOnboardingDemoData = () =>
  Http.makeInstance().delete('/onboarding/demoData');

const createCompanyOnboardingStep = (payload: CreateOnboardingStepPayload) =>
  Http.makeInstance().post('/onboarding', { ...payload });

const updateCompanyOnboardingStep = (payload: UpdateOnboardingStepPayload) => {
  const { _id, ...rest } = payload;

  return Http.makeInstance().patch(`/onboarding/${_id}`, { ...rest });
};

const deleteOnboardingStep = (id: string) =>
  Http.makeInstance().delete(`/onboarding/${id}`);

export default {
  getOnboardingInfo,
  deleteOnboardingStep,
  createOnboardingDemoData,
  removeOnboardingDemoData,
  createCompanyOnboardingStep,
  updateCompanyOnboardingStep,
};
