import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze, blueRibbon, white } from '../../../../../theme/colors';
import { StyleProps } from '../../ConfirmChangeRepeatDialog/types';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    marginBottom: (props: StyleProps) => (props.isError ? 32 : 16),
    height: 56,
    boxSizing: 'border-box',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
      height: 'unset',
    },
  },
  amountContainer: {
    borderRadius: 12,
    background: aquaHaze,
    minWidth: 130,
    [theme.breakpoints.down(768)]: {
      minWidth: 'unset',
      marginRight: 0,
      marginBottom: 16,
    },
  },
  amountContainerInput: {
    padding: '0px 16px'
  },
  marginRight10: {
    marginRight: 10,
  },
  rateContainer: {
    display: 'flex',
    flex: 0.5,
    flexGrow: 0.5,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 12,
    marginBottom: 0,
    background: white,
  },
  padding: {
    padding: '0 16px',
  },
  blueBackground: {
    background: 'rgba(6, 91, 255, 0.12)',
    marginBottom: '0 !important',
  },
  blueColor: {
    color: blueRibbon,
    border: 'none',
  },
  amountFlex: {
    flex: 1,
    position: 'relative',
    marginBottom: 0,
  },
  marginBottom32: {
    marginBottom: 32,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  revertIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down(768)]: {
      marginBottom: 16,
      marginRight: 0,
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
