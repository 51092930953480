import { ImportPDFPayload, StartImportPayload } from './sagas.types';

export const START_IMPORT_PENDING = 'START_IMPORT_PENDING';
export const START_IMPORT_SUCCESS = 'START_IMPORT_SUCCESS';

export const PDF_IMPORT_PENDING = 'PDF_IMPORT_PENDING';
export const PDF_IMPORT_SUCCESS = 'PDF_IMPORT_SUCCESS';
export const PDF_IMPORT_FAILED = 'PDF_IMPORT_FAILED';

export const RESET_PDF_IMPORT = 'RESET_PDF_IMPORT';

const importOperations = (payload: StartImportPayload) => ({
  type: START_IMPORT_PENDING,
  payload,
});

const importPDF = (data: ImportPDFPayload) => ({
  type: PDF_IMPORT_PENDING,
  payload: data,
});

const resetPDF = () => ({
  type: RESET_PDF_IMPORT,
});

export default {
  resetPDF,
  importPDF,
  importOperations,
};
