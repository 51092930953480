import { makeStyles } from '@material-ui/core/styles';

import { catskillWhite, persianGreen } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  filtersRoot: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    background: catskillWhite,
    borderRadius: 12,
    marginRight: 8,
    paddingLeft: 8,
    paddingRight: 8,
    height: 40,
  },
  additionalPaddings: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  clearText: {
    color: persianGreen,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: '20px',
    cursor: 'pointer',
  },

});
