import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsOwner } from '../selectors/clientPermissions';
import {
  isFastPermissionsDelete,
  isFastPermissionsEdit,
  isFastPermissionsFull,
  isFastPermissionsRead,
  selectCompanyTypeFastPermissions,
  selectIsCreator,
} from '../store/auth/selectors';
import commonActions from '../store/common/actions';
import { selectCurrentRolePermissions } from '../store/employees/selectors';

function useRestricted() {
  const dispatch = useDispatch();

  const readAccess = useSelector(isFastPermissionsRead);
  const editAccess = useSelector(isFastPermissionsEdit);
  const deleteAccess = useSelector(isFastPermissionsDelete);
  const fullAccess = useSelector(isFastPermissionsFull);
  const fastPermissions = useSelector(selectCurrentRolePermissions);
  const fastPermissionsType = useSelector(selectCompanyTypeFastPermissions);

  const isOwner = useSelector(selectIsOwner);
  const isCreator = useSelector(selectIsCreator);

  const openRestrictedDialog = useCallback(() => {
    dispatch(commonActions.setShowRestrictedDialog(true));
  }, [dispatch]);

  const showRestrictedDialog = useCallback(
    (callback?: () => void) => {
      if (isOwner) {
        if (callback) {
          callback();
        }

        return;
      }

      openRestrictedDialog();
    },
    [openRestrictedDialog, isOwner],
  );

  return {
    readAccess: readAccess && !isOwner,
    editAccess: editAccess && !isOwner,
    deleteAccess: deleteAccess && !isOwner,
    fullAccess: fullAccess || isOwner,
    isOwner,
    isCreator,
    fastPermissions,
    fastPermissionsType,
    showRestrictedDialog,
    openRestrictedDialog,
  };
}

export default useRestricted;
