export enum AVAILABLE_IMPORT_TYPES {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
  PDF = '.pdf'
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_AND_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
