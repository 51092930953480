import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesOptions, StylesProvider } from '@material-ui/styles/';
import { ReactElement } from 'react';

import theme from '../styles/theme';
import { isTestEnvironment } from './isLocalhost';

const generateClassName: StylesOptions['generateClassName'] = (
  rule,
  sheet,
): string => `${sheet!.options.classNamePrefix}-${rule.key}`;

function ThemeProvider({ children }: { children: ReactElement }): ReactElement {
  if (!isTestEnvironment()) {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </StylesProvider>
    );
  }

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

export default ThemeProvider;
