import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  CreateOnboardingStepPayload,
  DeleteOnboardingPayload,
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
  UpdateOnboardingStepPayload,
} from '@finmap/core-constants';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import { START_LOADING_PENDING } from '../auth/actions';
import { onboardingActionTypes } from './actions';
import api from './api';
import {
  selectGreetingOnboardingStep,
  selectPendingOnboardingStep,
  selectPreLastStep,
  selectSetupCabinetStep,
  selectSkipOnboardingStep,
  selectStartServiceFromStep,
} from './selectors';

function* getOnboardingInfo() {
  try {
    const { data: onboardingSteps } = yield call(api.getOnboardingInfo);

    yield put({
      type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
      payload: { onboardingSteps },
    });
  } catch (error) {
    showError(error);
  }
}

function* createOnboardingDemoData() {
  try {
    yield call(api.createOnboardingDemoData);

    yield put({ type: START_LOADING_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* removeOnboardingDemoData() {
  try {
    yield call(api.removeOnboardingDemoData);

    yield put({
      type: START_LOADING_PENDING,
    });
  } catch (error) {
    showError(error);
  }
}

function* deleteOnboardingStep(action: TAction<DeleteOnboardingPayload>) {
  try {
    const { id } = action.payload;

    const { data: onboardingSteps } = yield call(api.deleteOnboardingStep, id);

    yield put({
      type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
      payload: { onboardingSteps },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateOnboardingStep(action: TAction<UpdateOnboardingStepPayload>) {
  try {
    const { data: onboardingSteps } = yield call(
      api.updateCompanyOnboardingStep,
      action.payload,
    );

    yield put({
      type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
      payload: { onboardingSteps },
    });
  } catch (error) {
    showError(error);
  }
}

function* createOnboardingStep(action: TAction<CreateOnboardingStepPayload>) {
  try {
    const { callback, ...rest } = action.payload;

    const { data: onboardingSteps } = yield call(
      api.createCompanyOnboardingStep,
      rest,
    );

    yield put({
      type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
      payload: { onboardingSteps },
    });

    if (callback) {
      callback();
    }
  } catch (error) {
    showError(error);
  }
}

function* setPreLastStepSkipped(action: TAction<any>): SagaIterator {
  try {
    const active = action.payload?.active;

    const greetingStep = yield select(selectGreetingOnboardingStep);
    const startServiceFromStep = yield select(selectStartServiceFromStep);
    const setupCabinetStep = yield select(selectSetupCabinetStep);
    const preLastStep = yield select(selectPreLastStep);

    if (greetingStep) {
      yield put({
        type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
        payload: { ...greetingStep, status: ONBOARDING_STATUS.PASSED },
      });
    } else {
      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.GREETINGS,
          status: ONBOARDING_STATUS.PASSED,
        },
      });
    }

    if (startServiceFromStep) {
      yield put({
        type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
        payload: {
          ...startServiceFromStep,
          status: ONBOARDING_STATUS.PASSED,
          stepSubCategory: ONBOARDING_SUB_STEPS.MAKE_ORDER,
        },
      });
    } else {
      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.START_SERVICE_FROM,
          status: ONBOARDING_STATUS.PASSED,
          stepSubCategory: ONBOARDING_SUB_STEPS.MAKE_ORDER,
        },
      });
    }

    if (setupCabinetStep) {
      yield put({
        type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
        payload: { ...setupCabinetStep, status: ONBOARDING_STATUS.PASSED },
      });
    } else {
      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.SETUP_CABINET,
          status: ONBOARDING_STATUS.PASSED,
          stepSubCategory: ONBOARDING_SUB_STEPS.MANUAL,
        },
      });
    }

    const status = active ? ONBOARDING_STATUS.ACTIVE : ONBOARDING_STATUS.SKIP;

    if (!preLastStep) {
      yield put({
        type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
        payload: {
          stepName: ONBOARDING_STEPS.PRE_LAST,
          status,
        },
      });
    }
  } catch (error) {
    showError(error);
  }
}

function* restoreLastState(): SagaIterator {
  try {
    const skippedOnboardingStep = yield select(selectSkipOnboardingStep);
    const pendingOnboardingStep = yield select(selectPendingOnboardingStep);
    const onboardingStep = skippedOnboardingStep ?? pendingOnboardingStep;

    if (onboardingStep?._id) {
      const { data: onboardingSteps } = yield call(
        api.updateCompanyOnboardingStep,
        {
          ...onboardingStep,
          status: ONBOARDING_STATUS.ACTIVE,
        },
      );

      yield put({
        type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
        payload: { onboardingSteps },
      });

      yield put({
        type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
        payload: { onboardingV2InProgress: true },
      });
    } else {
      yield put({
        type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
        payload: {
          onboardingSteps: [
            {
              status: ONBOARDING_STATUS.ACTIVE,
              stepName: ONBOARDING_STEPS.GREETINGS,
            },
          ],
        },
      });

      yield put({
        type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
        payload: { onboardingV2InProgress: true },
      });
    }
  } catch (error) {
    showError(error);
  }
}

export default function onboardingV2() {
  return all([
    takeLatest(
      onboardingActionTypes.DELETE_ONBOARDING_STEP,
      deleteOnboardingStep,
    ),
    takeLatest(onboardingActionTypes.GET_ONBOARDING_INFO, getOnboardingInfo),
    takeLatest(onboardingActionTypes.RESTORE_LAST_STATE, restoreLastState),
    takeLatest(
      onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
      createOnboardingStep,
    ),
    takeLatest(
      onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
      updateOnboardingStep,
    ),
    takeLatest(
      onboardingActionTypes.SET_PRE_LAST_STEP_SKIPPED,
      setPreLastStepSkipped,
    ),
    takeLatest(
      onboardingActionTypes.CREATE_ONBOARDING_DEMO_DATA,
      createOnboardingDemoData,
    ),
    takeLatest(
      onboardingActionTypes.REMOVE_ONBOARDING_DEMO_DATA,
      removeOnboardingDemoData,
    ),
  ]);
}
