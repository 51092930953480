import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  historyContainer: {
    overflow: 'scroll',
    maxHeight: '510px',
  },
  row: {
    marginTop: 24,
    marginBottom: 16,
    border: '1px solid #E0E0E0',
  },
  titleWrapper: {
    padding: '11px 16px',
    backgroundColor: 'rgba(224, 224, 224, 0.2)',
  },
  title: {
    margin: 0,
  },
  leftText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.5,
  },
  historyContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 19,
    marginTop: 24,
  },
  historyTextWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  cardTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
  historyValue: {
    marginLeft: 12,
    opacity: 1,
  },
  downloadText: {
    color: '#00B28E',
    cursor: 'pointer',
    marginLeft: 12,
    opacity: 1,
  },
});
