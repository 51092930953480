import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checked from '../../../../../assets/images/svg/radio-selected.svg';
import UnChecked from '../../../../../assets/images/svg/radio-unselected.svg';
import { subscriptionV2Periods } from '../../../../../selectors/subscriptionV2Discounts';
import {
  SubscriptionPeriods,
  SubscriptionType,
} from '../../../../../store/types';
import { useStyles } from '../../styles';
import { Props } from './types';

function SwitcherV2Discounts(props: Props) {
  const {
    currentPeriod,
    tariffsToId,
    type,
    symbol,
    onSetPeriod,
    loadingTariffs,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const periods = useSelector(
    subscriptionV2Periods(type === SubscriptionType.Pro),
  );

  const handleSetPeriod = useCallback(
    (event: SyntheticEvent) => {
      const { id } = event.currentTarget;
      const chosenPeriod = periods.find((el) => el.id === id);

      if (chosenPeriod) {
        onSetPeriod(chosenPeriod);
      }
    },
    [periods, onSetPeriod],
  );

  const getDiscount = useCallback(
    (id: SubscriptionPeriods) => {
      if (!tariffsToId || id === SubscriptionPeriods.price1m) {
        return '';
      }

      const price1m = tariffsToId[type][SubscriptionPeriods.price1m];
      const price6m = tariffsToId[type][SubscriptionPeriods.price6m];
      const price12 = tariffsToId[type][SubscriptionPeriods.price12m];

      const discountYearValue = price1m * 12 - price12;
      const discountHalfValue = price1m * 6 - price6m;

      if (id === SubscriptionPeriods.price6m) {
        return discountHalfValue;
      }

      return discountYearValue;
    },
    [tariffsToId, type],
  );

  return (
    <div className={classes.periodsBlock}>
      {periods.map((el) => (
        <div
          onClick={handleSetPeriod}
          className={cn(classes.row, classes.marginBottom20)}
          key={el.id}
          id={el.id}
        >
          {currentPeriod.id === el.id ? (
            <img src={Checked} alt="checked" className={classes.radioIcon} />
          ) : (
            <img
              src={UnChecked}
              alt="unChecked"
              className={classes.radioIcon}
            />
          )}
          <div className={classes.column}>
            <div className={classes.discountRowContainer}>
              <Typography className={classes.periodMainText}>
                {el.title.toLowerCase()}
              </Typography>
              {el.id !== SubscriptionPeriods.price1m && !loadingTariffs && (
                <Typography className={classes.discountRow}>
                  {symbol}
                  <span className={classes.bold}>{getDiscount(el.id)}</span>
                  &nbsp;
                  {t('subscription.economy').toLowerCase()}
                </Typography>
              )}
            </div>
            {el.id === SubscriptionPeriods.price6m &&
              tariffsToId &&
              currentPeriod.id === SubscriptionPeriods.price6m && (
                <>
                  <Typography className={classes.payForYear}>
                    {t('subscription.payForHalfYear')} - {symbol}
                    {tariffsToId[type][SubscriptionPeriods.price6m]}
                  </Typography>
                </>
              )}
            {el.id === SubscriptionPeriods.price12m &&
              tariffsToId &&
              currentPeriod.id === SubscriptionPeriods.price12m && (
                <Typography className={classes.payForYear}>
                  {t('subscription.payForYear')} - {symbol}
                  {tariffsToId[type][SubscriptionPeriods.price12m]}
                </Typography>
              )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default React.memo(SwitcherV2Discounts);
