import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import payoneerApis from '../../../services/bankIntegrations/payoneer';
import Storages, { StorageKey } from '../../../services/Storages';
import { selectLogAccountsWithoutNotSpecifiedAccount } from '../../../store/accounts/selectors';
import { minOperationDate } from '../../../utils/dateFormat';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { Props } from './PayoneerFirstDialog.types';

function PayoneerFirstDialog(props: Props) {
  const { onClose, selectedAccountId, newAccountName } = props;

  const { t } = useTranslation();

  const accounts = useSelector(selectLogAccountsWithoutNotSpecifiedAccount);

  const [selectedDate, setSelectedDate] = useState<number>(
    moment.utc().startOf('month').subtract(1, 'month').valueOf(),
  );

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleCreate = useCallback(async () => {
    const accountName =
      accounts.find((el) => el._id === selectedAccountId)?.name || '';

    Storages.put(StorageKey.payoneer, {
      startDate: selectedDate,
      selectedAccountId,
      accountName,
      newAccountName,
    });

    const redirectUrl = `${window.location.origin}/`;
    const { data } = await payoneerApis.getCredentials(redirectUrl);

    window.location.href = data.url;
  }, [selectedAccountId, selectedDate, newAccountName, accounts]);

  return (
    <Dialog onClose={onClose} isOpened title={t('integrations.payoneer.title')}>
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        value={selectedDate}
        fullWidth
        maxDate={new Date()}
        minDate={minOperationDate()}
        fromIntegration
      />
      <CustomButton
        title={t('integrations.payoneer.connect')}
        action={handleCreate}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(PayoneerFirstDialog);
