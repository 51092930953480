import {
    AVAILABLE_IMPORT_TYPES, BaseImportParserV3, Config, ImportResultItemMask
} from '@finmap/import-parsers/base-import-parser-v3';
import { isEmpty } from 'lodash';

export class UpworkImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Date', 0]),
            sum: this.getFirstValidCellByColumn([
              'Amount',
              'Amount in local currency',
              9,
              10,
            ]),
            currency: this.getFirstValidCellByColumn(['Currency', 11]),
            project: this.getFirstValidCellByColumn(['Team', 6]),
            counterparty: this.getFirstValidCellByColumn(['Freelancer', 5]),
            comment: this.getFirstValidCellByColumn(['Description', 3]),
          });
        },
        caseOptions: { defaultCurrency: 'USD' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.splice(1));
          return (): ImportResultItemMask => {
            const transactionType = this.getFirstValidCellByColumn(['Type', 2]);
            const transactionDescription = this.getFirstValidCellByColumn(['Description', 3]);
            const transactionAgency = this.getFirstValidCellByColumn(['Agency', 4]);
            const transactionFreelancer = this.getFirstValidCellByColumn(['Freelancer', 5]);
            const transactionTeam = this.getFirstValidCellByColumn(['Team', 6]);
            const comment = [];
            if(transactionDescription) {
              comment.push(transactionDescription);
            }
            if(transactionType) {
              comment.push(`Type: ${transactionType}`);
            }
            if(transactionAgency) {
              comment.push(`Agency: ${transactionAgency}`);
            }
            if(transactionFreelancer) {
              comment.push(`Freelancer: ${transactionFreelancer}`);
            }
            if(transactionTeam) {
              comment.push(`Team: ${transactionTeam}`);
            }

            return {
              date: this.getFirstValidCellByColumn(['Date', 0]),
              sum: this.getFirstValidCellByColumn(['Amount', 8]),
              comment: comment.join('\n'),
            }
          }
        },
        caseOptions: { defaultCurrency: 'USD', isDESCOpOrder: true },
      },
    ],
  };
}
