import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../store/operations/selectors';
import { maxOperationDate, minOperationDate } from '../../../utils/dateFormat';
import CustomPeriod from '../Components/CustomPeriod';
import timestampHelpers from '../HOC/createOperationHooks/helpers';
import { CustomPeriodEnum } from '../HOC/types';

type Props = {
  isNewState: boolean | undefined;
  incomeDate: number;
  isEdit: boolean | undefined;
  isCompare: boolean | undefined;
  difference: boolean | undefined;
  prefix?: string;

  onChangePeriodId(id: CustomPeriodEnum): void;
  onChangeDateOfPaymentPeriod(
    startTimestamp: number,
    endTimestamp: number,
  ): void;
};

function SalaryPeriodComponent(props: Props) {
  const {
    isEdit,
    prefix,
    isCompare,
    incomeDate,
    isNewState,
    difference,
    onChangePeriodId,
    onChangeDateOfPaymentPeriod,
  } = props;

  const opProps = useSelector(getOperationProps);
  const nextOperationProps = useSelector(getHistoryOperationProps);

  const operationProps = isNewState ? nextOperationProps : opProps;

  const { salaryPeriodId, startTimestamp, endTimestamp } = operationProps;

  const { salaryPeriodEndTimestamp, salaryPeriodStartTimestamp } =
    timestampHelpers.getSalaryTimeStamps({
      incomeDate,
      startTimestamp,
      endTimestamp,
      periodId: salaryPeriodId,
    });

  const handleChangeSalaryPeriodId = useCallback(
    (id: CustomPeriodEnum) => {
      onChangePeriodId(id);
    },
    [onChangePeriodId],
  );

  const handleChangeDateOfPaymentPeriod = useCallback(
    (data: number[]) => {
      onChangeDateOfPaymentPeriod(data[0], data[1]);
    },
    [onChangeDateOfPaymentPeriod],
  );

  useEffect(() => {
    if (!isEdit) {
      const currentDate = new Date().getUTCDate();

      const periodId =
        currentDate < 15
          ? CustomPeriodEnum.prevMonth
          : CustomPeriodEnum.currentMonth;

      handleChangeSalaryPeriodId(periodId);
    }
  }, [isEdit, handleChangeSalaryPeriodId]);

  return (
    <CustomPeriod
      startTimestamp={salaryPeriodStartTimestamp}
      endTimestamp={salaryPeriodEndTimestamp}
      isEdit={!!isEdit}
      isCompare={!!isCompare}
      difference={!!difference}
      prefix={prefix}
      periodId={salaryPeriodId}
      onChangePeriodId={handleChangeSalaryPeriodId}
      date={incomeDate}
      onChange={handleChangeDateOfPaymentPeriod}
      minDate={minOperationDate()}
      maxDate={maxOperationDate(incomeDate)}
    />
  );
}

export default React.memo(SalaryPeriodComponent);
