import { ValueByContext } from '../types';
import { types } from './actions';

export type Client = {
  archived: boolean;
  companyId: string;
  createdBy: string;
  label: string;
  systemValue: boolean;
  _id: string;
  orderIndex: number;
  visible: boolean;
  normalizedLabel: string;
};

export type ClientsState = {
  clients: ValueByContext<Client>;
  investors: ValueByContext<Client>;
  creditors: ValueByContext<Client>;
  borrowers: ValueByContext<Client>;
  owners: ValueByContext<Client>;
  staffMembers: ValueByContext<Client>;
  suppliers: ValueByContext<Client>;
  taxOrganisations: ValueByContext<Client>;
  loading: boolean;
};

export enum UserType {
  clients = 'clients',
  investors = 'investors',
  creditors = 'creditors',
  borrowers = 'borrowers',
  suppliers = 'suppliers',
  staffMembers = 'staffMembers',
  owners = 'owners',
  taxOrganisations = 'taxOrganisations',
}

export type CreateClientResponse = {
  type: keyof typeof types;
  payload: {
    name: string;
    prefix: UrlPrefix;
  };
};

export enum UrlPrefix {
  clients = 'clients',
  investors = 'investors',
  creditors = 'creditors',
  borrowers = 'borrowers',
  staffMembers = 'staffMembers',
  owners = 'owners',
  suppliers = 'suppliers',
  taxOrganisations = 'taxOrganisations',
}
