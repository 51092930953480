import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import moment from 'moment';

export class BankOfamericaParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          "defaultCurrency": "UAH",
          "isDESCOpOrder": true,
          "withoutEmpty": true,
          "isCurCase": [
            [
              0,
              {
                "eq": "DATE"
              }
            ],
            [
              1,
              {
                "eq": "DESCRIPTION"
              }
            ]
          ],
          "proceedCaseConfig": {
            "fields": {
              "date": {
                "column": [
                  "DATE",
                  0
                ],
                "dateFormat": "MM/DD/YYYY"
              },
              "sum": {
                "column": [
                  "AMOUNT",
                  2
                ],
                "replace": [
                  {
                    "from": "$",
                    "to": ""
                  }
                ]
              },
              "comment": {
                "column": [
                  "DESCRIPTION",
                  1
                ]
              }
            }
          }
        },
      }
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = importDocument.slice(1).map(arr => {
            const isLastNum = !isNaN(Number(arr[2].replaceAll(',', '')));
            if(isLastNum) return arr;
            const data = arr[2].split(' ');
            const sum = data.pop();
            return [arr[0], arr[1] + ' ' + data.join(' '), sum];
          })
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: moment(this.getFirstValidCellByColumn(['Date', 0]), 'MM/DD/YY').toISOString(),
            comment: this.getFirstValidCellByColumn(['Description', 1]),
            sum: this.getFirstValidCellByColumn(['Amount', 2]).replace(',', ''),
          });
        },
        caseOptions: {
          "defaultCurrency": "USD",
          "preParserConfigs": {
           "interlineSpacingAccuracy": 10,
            "verticalAlign": "top",
            "prepareRawConfig":{
              "findHeader":{
                "from":[
                  {
                    "word": {
                          "eq": "Date"
                        },
                        "nextWord": {
                          "eq": "Description"
                        }
                  }
                ],
                "to":[
                  {
                    "word": {
                          "eq": "Amount"
                        },
                        "prevWord": {
                          "eq": "Description"
                        }
                  }
                ]
              },
              "delete":[
                {
                   "to":[
                  {
                    "word": {
                          "eq": "Date"
                        },
                        "nextWord": {
                          "eq": "Description"
                        }
                  }
                ],
                  "count": 1
                },
                {
                  "from":[
                    {
                      "word": {
                            "in": "Subtotal for card account"
                          }
                    }
                  ]
                },
                {
                  from: [{ word: { eq: 'continued on the next page' } }],
                  to: [{ word: { eq: 'Amount' }, prevWord: { eq: 'Description' } }]
                },
                {
                  from: [{ word: { eq: 'Total deposits and other credits' } }],
                  to: [{ word: { eq: 'Amount' }, prevWord: { eq: 'Description' } }]
                }
              ],
              "define": [
                {
                  "dateFormat": "MM/DD/YY"
                }
              ]
            }
          }
        }
      }
    ]
  };
}
