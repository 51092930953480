import api from '../../services/company';
import Storages, { StorageKey } from '../../services/Storages';
import authApi from '../auth/api';
import { CreateCompanyPayload } from './sagas.types';

const createDemoCompany = async (payload: CreateCompanyPayload) => {
  const {
    data: { company, companies },
  } = await api.createCompany(payload);

  return { data: { company, companies } };
};

const createFirstCompany = async (payload: CreateCompanyPayload) => {
  const lng = Storages.get(StorageKey.language);

  const {
    data: { company, companies },
  } = await api.createCompany(payload);

  await authApi.updateProfile(company._id, lng);

  if (companies.length === 1) {
    return {
      data: {
        companies,
        company: {
          ...company,
          roleId: companies[0].roleId,
        },
      },
    };
  }

  return { data: { company, companies } };
};

export default {
  createDemoCompany,
  createFirstCompany,
};
