import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import Storages, { StorageKey } from '../../../services/Storages';
import { outrageousOrange } from '../../../theme/colors';
import { useStyles } from './styles';

type ReconnectFailedDialogProps = {
  onClose(): void;
  handleReconnectAuth(integrationCredentialsId: string): void;
};

function ReconnectFailedDialog({
  onClose,
  handleReconnectAuth,
}: ReconnectFailedDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const integrationCredentialsId = Storages.get(
    StorageKey.saltedge,
  )?.integrationCredentialsId;

  const onReconnect = useCallback(() => {
    handleReconnectAuth(integrationCredentialsId);
  }, [handleReconnectAuth, integrationCredentialsId]);

  return (
    <Dialog isOpened onClose={onClose} title={t('bank.validationError')}>
      <Typography className={classes.description}>
        {t('bank.saltedge.authFailed')}
      </Typography>
      <CustomButton
        fullWidth
        action={onReconnect}
        className={classes.button}
        title={t('bank.repeatAuth')}
        textColor={outrageousOrange}
      />
    </Dialog>
  );
}

export default React.memo(ReconnectFailedDialog);
