import './styles.scss';
import { InputAdornment, TextField } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import CloseEye from '../../assets/images/svg/eye_gray_hide.svg';
import OpenEye from '../../assets/images/svg/eye_gray_open.svg';
import { MAX_INPUT_LENGTH } from '../../constants';
import { useCompareStyles } from '../../foundation/Operations/Components/compareStyles';
import TransitionComponent from '../TransitionComponent';
import { useStyles } from './TextFieldComponent.styles';
import { Props } from './TextFieldComponent.types';

function TextFieldComponent(props: Props) {
  const {
    id,
    type,
    name,
    link,
    value,
    label,
    ga4Id = '',
    onBlur,
    active,
    isError,
    onFocus,
    loading,
    onChange,
    disabled,
    multiline,
    onKeyDown,
    maxLength,
    errorText,
    unlimited,
    difference,
    placeholder,
    readableOnly,
    textEllipsis,
    rootClassName,
    injectClassName,
    inputProps = {},
    StartAdornment,
    wrapperInputRoot,
    containerClassName,
    AdornmentComponent,
    wrapErrorClassName,
    fullWidth = true,
    inputPropsClasses = {},
    isLabelNecessary = false,
  } = props;
  const classes = useStyles({
    isError,
    multiline: Boolean(multiline),
    height: multiline ? 90 : 'unset',
  });
  const compareClasses = useCompareStyles();

  const [showPW, setShowPW] = useState(false);

  const handleShowPW = useCallback(() => {
    setShowPW(true);
  }, []);

  const handleClosePW = useCallback(() => {
    setShowPW(false);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const currentType = type === 'password' && showPW ? 'text' : type;
  const textFieldId = isError && ga4Id ? ga4Id : id;

  const textInputProps = useMemo(() => {
    if (unlimited) {
      return {
        className: cn(textEllipsis && classes.textEllipsis),
      };
    }
    return {
      ...inputProps,
      maxLength: maxLength || MAX_INPUT_LENGTH,
      className: cn(textEllipsis && classes.textEllipsis),
    };
  }, [unlimited, maxLength, textEllipsis, classes.textEllipsis, inputProps]);

  const StartAdornmentComponent = useCallback(() => {
    if (loading) {
      return (
        <InputAdornment position="start" className="text-field">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </InputAdornment>
      );
    }

    if (StartAdornment) {
      return <InputAdornment position="start">{StartAdornment}</InputAdornment>;
    }

    return null;
  }, [StartAdornment, loading]);

  return (
    <div
      className={cn(
        classes.root,
        isError && classes.error,
        difference && compareClasses.root,
        rootClassName,
      )}
    >
      <div className={cn(classes.container, containerClassName)}>
        <TextField
          id={textFieldId}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={active}
          autoComplete="new-password"
          name={name}
          fullWidth={fullWidth}
          type={currentType}
          disabled={disabled || readableOnly}
          className={cn(classes.textRoot, injectClassName)}
          multiline={multiline}
          onChange={handleChange}
          value={loading ? '' : value}
          label={
            isLabelNecessary
              ? label
              : Boolean(value || value === 0) && (label || placeholder)
          }
          placeholder={loading ? '' : placeholder}
          onKeyDown={onKeyDown}
          // @ts-ignore
          inputProps={textInputProps}
          InputProps={{
            classes: {
              root: cn(classes.inputRoot, wrapperInputRoot),
              disabled: cn(readableOnly && classes.fullOpacity),
              ...inputPropsClasses,
            },
            endAdornment: Boolean(AdornmentComponent) && !loading && (
              <InputAdornment position="end">
                {AdornmentComponent}
              </InputAdornment>
            ),
            startAdornment: <StartAdornmentComponent />,
          }}
          InputLabelProps={{
            classes: {
              disabled: cn(readableOnly && classes.fullOpacity),
            },
            shrink: true,
          }}
        />
        {type === 'password' && (
          <img
            src={OpenEye}
            alt="open"
            className={classes.hint}
            onClick={handleShowPW}
          />
        )}
        {type === 'password' && showPW && (
          <img
            src={CloseEye}
            alt="open"
            className={classes.hint}
            onClick={handleClosePW}
          />
        )}
      </div>
      <TransitionComponent
        className={cn('error', wrapErrorClassName)}
        text={errorText || ''}
        enter={Boolean(isError)}
        link={link}
      />
    </div>
  );
}

export default React.memo(TextFieldComponent);
