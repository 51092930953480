export class BaseImportPreParser {
  public store = {};

  async getRawData(buffer: ArrayBuffer, options): Promise<unknown> {
    console.log(buffer, options);
    return [];
  }

  async preParse(buffer: ArrayBuffer, options): Promise<unknown[][]> {
    return this.getRawData(buffer, options) as Promise<unknown[][]>;
  }
}
