import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';

import DragAndDropIcon from '../../assets/images/svg/drug_drop_small.svg';
import CustomButton from '../../components/Button';
import Dialog from '../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../components/PreviewRegisterDialog';
import Storages, { StorageKey } from '../../services/Storages';
import companyActions from '../../store/company/actions';
import { getCompanies } from '../../store/company/selectors';
import { Company } from '../../store/company/types';
import { selectMapCurrencyIdToSymbol } from '../../store/currency/selectors';
import CreateDialog from './CreateDialog';
import EditCompanyDialog from './EditCompanyDialog';
import { useStyles } from './styles';
import { Props } from './types';

const ReactSortableComponent = ReactSortable as React.ElementType;

function CompaniesDialog(props: Props) {
  const { onClose, showInitialCreateDialog = false } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const companies = useSelector(getCompanies);
  const currencySymbolToId = useSelector(selectMapCurrencyIdToSymbol);

  const [items, setItems] = useState<Company[]>([]);
  const [showCreateDialog, setShowCreateDialog] = useState(
    showInitialCreateDialog,
  );
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const handleCloseEditDialog = useCallback(() => {
    setShowEditDialog(false);
  }, []);

  const handleOpenCreateDialog = useCallback(() => {
    handleClosePreviewRegisterDialog();

    setShowCreateDialog(true);
  }, [handleClosePreviewRegisterDialog]);

  const handleCloseCreateDialog = useCallback(() => {
    setShowCreateDialog(false);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleEditClick = useCallback(
    (event: any) => {
      event.stopPropagation();

      const { id } = event.currentTarget;
      const company = companies.find((c) => c._id === id);

      if (company) {
        setCurrentCompany(company);
        handleOpenEditDialog();
      }
    },
    [companies, handleOpenEditDialog],
  );

  const handleUpdate = useCallback(
    (name: string, currencyId: string) => {
      if (currentCompany) {
        dispatch(
          companyActions.updateGroupCompanies([
            {
              ...currentCompany,
              name,
              currencyId,
            },
          ]),
        );

        handleCloseEditDialog();
      }
    },
    [currentCompany, dispatch, handleCloseEditDialog],
  );

  const handleCreate = useCallback(
    (name: string, id: string, activityId?: string) => {
      Storages.remove(StorageKey.isFirstEntry);

      dispatch(
        companyActions.createCompany({ name, currencyId: id, activityId }),
      );

      onClose();
    },
    [dispatch, onClose],
  );

  const handleDelete = useCallback(() => {
    if (currentCompany) {
      dispatch(companyActions.deleteCompany(currentCompany._id, history));

      handleCloseEditDialog();
    }
  }, [currentCompany, dispatch, handleCloseEditDialog, history]);

  const onSortEnd = useCallback(() => {
    const newArray = items.map((item, index) => ({
      ...item,
      orderIndex: index,
    }));

    dispatch(companyActions.updateGroupCompanies(newArray));
  }, [items, dispatch]);

  useEffect(() => {
    setItems(companies);

    if (currentCompany) {
      const acc = companies.find((el) => el._id === currentCompany._id);

      if (acc) {
        setCurrentCompany(acc);
      }
    }
  }, [companies, currentCompany]);

  useEffect(() => {
    dispatch(companyActions.loadCompaniesList());
  }, [dispatch]);

  return (
    <>
      <Dialog
        title={t('company.title')}
        isOpened
        onClose={handleClose}
        className={classes.dialog}
      >
        <>
          <CustomButton
            fullWidth
            className={classes.button}
            action={handleOpenPreviewRegisterDialog}
            title={t('company.add')}
            id="add_campaign_menu"
            ga4TextId="add_campaign_menu_text"
          />
          <ReactSortableComponent
            // @ts-ignore
            list={items}
            // @ts-ignore
            setList={setItems}
            onEnd={onSortEnd}
          >
            {items.map((item) => (
              <div
                onClick={handleEditClick}
                className={classes.row}
                id={item._id}
                key={item._id}
                data-name="company"
              >
                <div className={classes.leftBlock}>
                  <img
                    src={DragAndDropIcon}
                    alt="DragAndDropIcon"
                    className={cn(classes.opacity, classes.margin16)}
                  />
                  <Typography className={cn(classes.opacity, classes.margin16)}>
                    {currencySymbolToId[item.currencyId]}
                  </Typography>
                  <Typography>{item.name}</Typography>
                </div>
              </div>
            ))}
          </ReactSortableComponent>
        </>
      </Dialog>
      {showCreateDialog && (
        <CreateDialog
          onClose={handleCloseCreateDialog}
          onCreate={handleCreate}
        />
      )}
      {!!currentCompany && showEditDialog && (
        <EditCompanyDialog
          onClose={handleCloseEditDialog}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          currentCompany={currentCompany}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleOpenCreateDialog}
          shouldShowDemoAlertDialog={false}
        />
      )}
    </>
  );
}

export default React.memo(CompaniesDialog);
