import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
  OnboardingStepInfo,
} from '@finmap/core-constants';

import { GA_MEASUREMENT_ID } from '../../constants';
import { ENV_UI_URL } from '../../constants/env';
import { AnalyticRoutes, Routes } from '../../constants/routes';
import { TAction } from '../../sagas/types';
import {
  selectIsOwner,
  selectUserRoleId,
} from '../../selectors/clientPermissions';
import apiCompanies from '../../services/company';
import Storages, { StorageKey } from '../../services/Storages';
import { sendG4Analytic } from '../../utils/googleAnalytic';
import { showError } from '../../utils/showError';
import { isLite, isPro } from '../../utils/subscriptions';
import {
  GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
  GET_LOG_ACCOUNTS_PENDING,
} from '../accounts/actions';
import { ANALYTIC_INIT } from '../analytic/actions';
import {
  GET_ACTIVITIES_PENDING,
  GET_SCHEDULE_PERIODS_PENDING,
  GET_VERSION_PENDING,
  GET_VERSION_UI_PENDING,
} from '../common/actions';
import commonServices from '../common/api';
import { UserGeoLocationProps } from '../common/api.types';
import { getUserGeoData } from '../common/selectors';
import { GET_COMPANIES_SUCCESS, SET_CURRENT_COMPANY } from '../company/actions';
import {
  getCompanies,
  selectCompanyId,
  selectCurrentCompany,
} from '../company/selectors';
import { Company } from '../company/types';
import { GET_CURRENCIES_PENDING } from '../currency/actions';
import {
  EMPLOYEES_INIT,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_RESOURCES_PENDING,
  GET_ROLES_PENDING,
} from '../employees/actions';
import employeeApi from '../employees/api';
import { INIT_FILTERS } from '../filters/actions';
import {
  GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
  JOURNAL_INIT,
} from '../journal/actions';
import journalApi from '../journal/api';
import { getOperationsExist } from '../journal/selectors';
import { onboardingActionTypes } from '../onboardingV2/actions';
import apiOnboarding from '../onboardingV2/api';
import {
  GET_USER_PERMISSIONS_PENDING,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_SUCCESS,
  LOGIN_USER_FULFILLED,
  LOGOUT_USER,
  START_LOADING_PENDING,
  START_LOADING_SUCCESS,
  USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING,
  USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS,
  USER_UPDATE_PROFILE_LNG_PENDING,
  USER_UPDATE_PROFILE_LNG_SUCCESS,
  USER_UPDATE_PROFILE_PENDING,
  USER_UPDATE_PROFILE_PHONE_PENDING,
  USER_UPDATE_PROFILE_PHONE_SUCCESS,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_TC_PENDING,
  USER_UPDATE_PROFILE_TC_SUCCESS,
} from './actions';
import api from './api';
import {
  GetUserPermissionsPayload,
  StartLoadingPayload,
  UpdateProfileCustomizationPayload,
  UpdateProfileLngPayload,
  UpdateProfilePhonePayload,
} from './sagas.types';
import {
  getLanguage,
  isAnalyticEnable,
  isCalendarEnable,
  isEmployeesEnable,
  isInvoicingEnable,
  isLogEnable,
} from './selectors';
import { UpdateProfileTCPayload, User } from './types';

function logOutUser() {
  Storages.put(StorageKey.user, null);
  Storages.put('currentCompanyId', null);
  Storages.put(StorageKey.saltedge, null);
  Storages.remove(StorageKey.isFirstEntry);

  window.location.href = '/';

  return null;
}

function userLoggedIn() {
  const utmParams: { [k: string]: any } = {};

  for (const param in window.sessionStorage) {
    if (
      Object.hasOwnProperty.call(window.sessionStorage, param) &&
      /^utm_.+/.test(param)
    ) {
      utmParams[param] = window.sessionStorage[param];
    }
  }

  return null;
}

function addUserStorage({ payload }: AnyAction) {
  const user = {
    ...payload.data,
    id: payload.data._id,
    token: payload.token,
  };

  Storages.put(StorageKey.user, user);

  const params = new URLSearchParams(window.location.href);
  if (params.get('google-auth')) {
    window.location.href = ENV_UI_URL;
  }

  userLoggedIn();

  return null;
}

function* init() {
  yield put({ type: JOURNAL_INIT });
  yield put({ type: EMPLOYEES_INIT });
  yield put({ type: ANALYTIC_INIT });
  yield put({ type: ANALYTIC_INIT });
}

export function* startLoading(
  action: TAction<StartLoadingPayload>,
): SagaIterator {
  try {
    // @ts-ignore
    yield init();

    const history = action?.payload?.history;

    const { data }: { data: User } = yield call(api.getUserProfile);

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('config', GA_MEASUREMENT_ID, {
        user_id: data._id,
      });
      // @ts-ignore
      window.gtag('config', 'G-1PPSGBH92F', {
        user_id: data._id,
      });
    }

    yield put({ type: GET_VERSION_PENDING });
    yield put({ type: GET_VERSION_UI_PENDING });
    yield put({ type: GET_USER_SUCCESS, payload: { data } });

    // need to Apple devices
    yield delay(500);

    if (!data.phone) {
      history.replace(`${Routes.CREATE_PHONE}/${data.currentLangIso}`);

      return;
    }

    const { data: companies }: { data: Company[] } = yield call(
      apiCompanies.getCompanies,
    );

    yield put({ type: GET_COMPANIES_SUCCESS, payload: { data: companies } });

    if (!companies.length) {
      const { data: newUserProfileData } = yield call(
        api.updateProfile,
        null,
        data.currentLangIso,
      );

      const payload = {
        token: newUserProfileData.token,
        data: newUserProfileData.user,
      };

      yield put({ type: USER_UPDATE_PROFILE_SUCCESS, payload });

      history.push(`/auth/create-company/${data.currentLangIso}`);

      return;
    }

    const companyAllowed = companies.find(
      (el) => el._id === data.currentCompanyId,
    );

    const storedCurrentCompanyId: string = yield select(selectCompanyId);

    const currentCompanyId =
      storedCurrentCompanyId ||
      (companyAllowed ? data.currentCompanyId : companies[0]._id);

    const { data: userProfileData } = yield call(
      api.updateProfile,
      currentCompanyId,
      data.currentLangIso,
    );

    let payload = {
      token: userProfileData.token,
      data: userProfileData.user,
    };

    const { user } = userProfileData;

    const isPreviewDemoCompany = window.location.hostname.includes('preview');

    if (!user?.currentCompanyId && !isPreviewDemoCompany) {
      const { data: updatedUserProfileData } = yield call(
        api.updateProfile,
        companies[0]._id,
        data.currentLangIso,
      );

      if (
        !updatedUserProfileData.user ||
        !updatedUserProfileData.user?.currentCompanyId
      ) {
        logOutUser();
      }

      payload = {
        token: updatedUserProfileData.token,
        data: updatedUserProfileData.user,
      };
    }

    yield put({ type: USER_UPDATE_PROFILE_SUCCESS, payload });

    const { data: logCount } = yield call(journalApi.getOperationsCount);

    const currentUserCompany = companies.find(
      (el) => el._id === payload.data.currentCompanyId,
    );

    if (!data.onboardingCompleted) {
      Storages.remove(StorageKey.isFirstEntry);

      if (companies.length === 1 && !!currentUserCompany?.demo) {
        yield put({
          type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
          payload: {
            onboardingSteps: [
              {
                stepName: ONBOARDING_STEPS.GREETINGS,
                status: ONBOARDING_STATUS.ACTIVE,
              },
            ],
          },
        });

        yield put({
          type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
          payload: { onboardingV2InProgress: true },
        });
      }

      if (companies.length > 1) {
        if (!currentUserCompany?.demo) {
          try {
            const { data: onboardingSteps }: { data: OnboardingStepInfo[] } =
              yield call(apiOnboarding.getOnboardingInfo);

            if (!onboardingSteps.length) {
              yield put({
                type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
                payload: {
                  onboardingSteps: [
                    {
                      stepName: ONBOARDING_STEPS.GREETINGS,
                      status: ONBOARDING_STATUS.ACTIVE,
                    },
                  ],
                },
              });

              yield put({
                type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
                payload: { onboardingV2InProgress: true },
              });
            } else {
              yield put({
                type: onboardingActionTypes.SET_ONBOARDING_STEP_INFO,
                payload: { onboardingSteps },
              });

              const activeStep = onboardingSteps.find(
                (el) =>
                  el.status === ONBOARDING_STATUS.ACTIVE ||
                  el.status === ONBOARDING_STATUS.PENDING,
              );

              const integrationStep = onboardingSteps.find(
                (el) => el.stepName === ONBOARDING_STEPS.INTEGRATION,
              );

              if (logCount) {
                if (!integrationStep) {
                  yield put({
                    type: onboardingActionTypes.SET_PRE_LAST_STEP_SKIPPED,
                  });
                } else {
                  yield put({
                    type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
                    payload: {
                      ...integrationStep,
                      stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
                    },
                  });
                }
              } else if (integrationStep) {
                const nordigenIntegration = Storages.get(StorageKey.nordigen);
                const belvoIntegration = Storages.get(StorageKey.belvo);
                const saltedgeIntegration = Storages.get(StorageKey.saltedge);
                const ukrsibIntegration = Storages.get(StorageKey.ukrsib);
                const fondyIntegration = Storages.get(StorageKey.fondy);
                const payoneerIntegration = Storages.get(StorageKey.payoneer);
                const posterIntegration = Storages.get(StorageKey.poster);

                if (
                  !nordigenIntegration &&
                  !saltedgeIntegration &&
                  !ukrsibIntegration &&
                  !fondyIntegration &&
                  !payoneerIntegration &&
                  !posterIntegration &&
                  !belvoIntegration
                ) {
                  yield put({
                    type: onboardingActionTypes.DELETE_ONBOARDING_STEP,
                    payload: { id: integrationStep._id },
                  });
                } else {
                  yield put({
                    type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
                    payload: {
                      ...integrationStep,
                      status: ONBOARDING_STATUS.PASSED,
                    },
                  });
                }
              }

              if (activeStep) {
                yield put({
                  type: onboardingActionTypes.SET_ONBOARDING_V2_IN_PROGRESS,
                  payload: { onboardingV2InProgress: true },
                });
              }
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          yield put({ type: onboardingActionTypes.INIT });
        }
      }
    }

    const userGeoData = yield select(getUserGeoData);

    if (userGeoData) {
      yield call(api.updateUserCountry, userGeoData?.country ?? 'undefined');
    } else {
      try {
        const { data: geoLocation }: AxiosResponse<UserGeoLocationProps> =
          yield call(commonServices.getGeoLocationData);

        yield call(api.updateUserCountry, geoLocation.country);
      } catch (e) {
        yield call(api.updateUserCountry, 'undefined');
      }
    }

    yield put({
      type: GET_JOURNAL_OPERATIONS_COUNT_SUCCESS,
      payload: { data: { logCount: !!logCount } },
    });

    yield put({
      type: SET_CURRENT_COMPANY,
      payload: { id: payload.data.currentCompanyId },
    });

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });
    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      payload: {
        futurePaymentsTotalMonths: payload.data.futurePaymentsTotalMonths,
      },
    });

    const { data: permissionTypes } = yield call(
      employeeApi.getPermissionTypes,
    );

    yield put({
      type: GET_PERMISSION_TYPES_SUCCESS,
      payload: { data: permissionTypes },
    });

    yield put({ type: GET_ROLES_PENDING });
    yield put({ type: INIT_FILTERS });
    yield put({ type: GET_RESOURCES_PENDING });
    yield put({ type: GET_CURRENCIES_PENDING });
    yield put({ type: GET_SCHEDULE_PERIODS_PENDING });
    yield put({ type: GET_ACTIVITIES_PENDING });
    yield put({
      type: GET_USER_PERMISSIONS_PENDING,
      payload: { history },
    });

    const currentCompany: Company = yield select(selectCurrentCompany);

    let tariff_type = 'Trial';

    if (isLite(currentCompany)) {
      tariff_type = 'Lite';
    } else if (isPro(currentCompany)) {
      tariff_type = 'Pro';
    }

    sendG4Analytic({
      UID: user._id,
      tariff_type,
    });
  } catch (error) {
    showError(error);

    logOutUser();
  }
}

function* updateProfileLng(action: TAction<UpdateProfileLngPayload>) {
  try {
    const { facebookContext } = action.payload;

    const companyId: string = yield select(selectCompanyId);
    const language: string = yield select(getLanguage);

    const { data } = yield call(api.updateProfile, companyId, language);
    const payload = {
      token: data.token,
      data: data.user,
    };

    if (facebookContext) {
      facebookContext.sendData();
    }

    yield put({ type: USER_UPDATE_PROFILE_LNG_SUCCESS, payload });
  } catch (error) {
    showError(error);
    logOutUser();
  }
}

function* updateProfilePhone(action: TAction<UpdateProfilePhonePayload>) {
  try {
    const { phone, termsOfService } = action.payload;

    const { data } = yield call(api.updateProfilePhone, phone, termsOfService);
    const payload = {
      token: data.token,
      data: data.user,
    };

    yield put({ type: USER_UPDATE_PROFILE_PHONE_SUCCESS, payload });
  } catch (error) {
    showError(error);
    logOutUser();
  }
}

function* updateProfileCustomization(
  action: TAction<UpdateProfileCustomizationPayload>,
) {
  try {
    const { customization } = action.payload;

    const { data } = yield call(api.updateProfileCustomization, customization);
    const payload = {
      token: data.token,
      data: data.user,
    };

    yield put({ type: USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS, payload });
  } catch (error) {
    showError(error);
    logOutUser();
  }
}

function* updateProfile() {
  try {
    const companyId: string = yield select(selectCompanyId);
    const language: string = yield select(getLanguage);

    const { data } = yield call(api.updateProfile, companyId, language);
    const payload = {
      token: data.token,
      data: data.user,
    };

    yield put({ type: USER_UPDATE_PROFILE_SUCCESS, payload });
  } catch (error) {
    showError(error);
    logOutUser();
  }
}

function* updateProfileTC(action: TAction<UpdateProfileTCPayload>) {
  try {
    const { agreeTandC } = action.payload;
    const { data } = yield call(api.updateProfileTC, agreeTandC);
    const payload = {
      token: data.token,
      data: data.user,
    };

    yield put({ type: USER_UPDATE_PROFILE_TC_SUCCESS, payload: payload });
  } catch (error) {
    showError(error);
    logOutUser();
  }
}

function* getUserPermissions(
  action: TAction<GetUserPermissionsPayload>,
): SagaIterator {
  try {
    const { history } = action.payload;

    const roleId: string = yield select(selectUserRoleId);
    const isOwner: boolean = yield select(selectIsOwner);
    const currentCompany: Company | null = yield select(selectCurrentCompany);

    if (!isOwner) {
      if (currentCompany?.fastPermissionIds.length) {
        yield put({
          type: GET_USER_PERMISSIONS_SUCCESS,
          payload: { data: currentCompany.fastPermissionIds },
        });
      } else if (roleId) {
        const { data } = yield call(employeeApi.getPermissions, roleId);

        yield put({ type: GET_USER_PERMISSIONS_SUCCESS, payload: { data } });
      } else {
        const companies: Company[] = yield select(getCompanies);

        yield put({
          type: SET_CURRENT_COMPANY,
          payload: { id: companies[0]._id },
        });

        const { data } = yield call(
          employeeApi.getPermissions,
          companies[0].roleId,
        );

        yield put({ type: GET_USER_PERMISSIONS_SUCCESS, payload: { data } });
      }
    } else {
      yield put({ type: GET_USER_PERMISSIONS_SUCCESS, payload: { data: [] } });
    }

    const logEnable: boolean = yield select(isLogEnable);
    const analyticEnable: boolean = yield select(isAnalyticEnable);
    const calendarEnable: boolean = yield select(isCalendarEnable);
    const employeesEnable: boolean = yield select(isEmployeesEnable);
    const invoicingEnable: boolean = yield select(isInvoicingEnable);

    if (
      !logEnable &&
      !analyticEnable &&
      !calendarEnable &&
      !employeesEnable &&
      !invoicingEnable
    ) {
      history?.replace(Routes.LOG);

      yield put({ type: START_LOADING_SUCCESS });

      return;
    }

    if (logEnable && window.location.pathname === Routes.LOG) {
      history?.replace(Routes.LOG);
    } else if (
      analyticEnable &&
      window.location.pathname.includes(AnalyticRoutes.ANALYTIC)
    ) {
      history?.replace(window.location.pathname);
    } else if (calendarEnable && window.location.pathname === Routes.CALENDAR) {
      history?.replace(Routes.CALENDAR);
    } else if (
      employeesEnable &&
      window.location.pathname === Routes.EMPLOYEE
    ) {
      history?.replace(Routes.EMPLOYEE);
    } else if (
      invoicingEnable &&
      window.location.pathname === Routes.INVOICING
    ) {
      history?.replace(Routes.INVOICING);
    }

    yield put({ type: START_LOADING_SUCCESS });

    if (window.location.pathname === Routes.ON_BOARDING) {
      const logCount = yield select(getOperationsExist);

      if (logCount) {
        window.location.href = Routes.LOG;
      }
    }
  } catch (error) {
    showError(error);
  }
}

export default function auth() {
  return all([
    takeLatest(LOGOUT_USER, logOutUser),
    takeLatest(LOGIN_USER_FULFILLED, addUserStorage),
    takeLatest(START_LOADING_PENDING, startLoading),
    takeLatest(USER_UPDATE_PROFILE_PENDING, updateProfile),
    takeLatest(GET_USER_PERMISSIONS_PENDING, getUserPermissions),
    takeLatest(USER_UPDATE_PROFILE_LNG_PENDING, updateProfileLng),
    takeLatest(USER_UPDATE_PROFILE_PHONE_PENDING, updateProfilePhone),
    takeLatest(
      USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING,
      updateProfileCustomization,
    ),
    takeLatest(USER_UPDATE_PROFILE_TC_PENDING, updateProfileTC),
  ]);
}
