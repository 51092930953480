import { makeStyles } from '@material-ui/core/styles';

import { lightGray } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '100%',
    '&:hover': {
      background: lightGray,
    },
  },
});
