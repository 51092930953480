import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  marginBottom: {
    display: 'block',
    marginBottom: 15,
  },
  logo: {
    textAlign: 'center',
    width: '100%',
    borderRadius: 12,
  },
  link: {
    color: persianGreen,
    cursor: 'pointer',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 18,
  },
  list: {
    paddingInlineStart: 20,
  },
});
