import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';

export enum Ga4Errors {
  loginError = 'login_error',
  loginPasswordError = 'login_password_error',
  registerEmailError = 'register_email_error',
  registerPhoneError = 'register_phone_error',
  registerPasswordError = 'register_password_error',
  createCompanyNameError = 'create_company_name_error',
  createAccountNameError = 'create_account_name_error',
  editAccountNameError = 'edit_account_name_error',
  integrationCreateAccountNameError = 'integration_create_account_name_error',
  createCategoryNameError = 'create_category_name_error',
  editCategoryNameError = 'edit_category_name_error',
  createTagNameError = 'create_tag_name_error',
  createProjectNameError = 'create_project_name_error',
  createClientsNameError = 'create_client_name_error',
  createInvestorsNameError = 'create_investor_name_error',
  createCreditorsNameError = 'create_creditor_name_error',
  createBorrowersNameError = 'create_borrower_name_error',
  createSuppliersNameError = 'create_supplier_name_error',
  createStaffMembersNameError = 'create_staffMember_name_error',
  createOwnersNameError = 'create_owner_name_error',
  createTaxOrganisationsNameError = 'create_taxOrganisations_name_error',
}

export type Props = {
  id?: string;
  value: number | string | null;
  placeholder: string;
  label?: string;
  multiline?: boolean;
  disabled?: boolean;
  isError?: boolean;
  errorText?: string;
  rootClassName?: string;
  type?: string;
  fullWidth?: boolean;
  name?: string;
  injectClassName?: string;
  active?: boolean;
  maxLength?: number;
  isCompare?: boolean;
  difference?: boolean;
  wrapperInputRoot?: string;
  containerClassName?: string;
  readableOnly?: boolean;
  AdornmentComponent?: any;
  StartAdornment?: any;
  textEllipsis?: boolean;
  loading?: boolean;
  unlimited?: boolean;
  wrapErrorClassName?: string;
  isLabelNecessary?: boolean;
  ga4Id?: string;
  inputPropsClasses?: any;
  inputProps?: StandardInputProps['inputProps'];

  onKeyDown?(event: any): void;
  onChange?(value: number | string): void;
  onFocus?(): void;
  onBlur?(): void;
  link?(): void;
};
