import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../theme/colors';

export const useStyles = makeStyles({
  fullRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  helpText: {
    color: persianGreen,
    fontSize: 13,
    lineHeight: '20px',
  },
  helpRow: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  playIcon: {
    marginRight: 10,
  },
});
