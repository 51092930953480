import Auth from '../../api/Auth';
import { ChangePasswordRequest } from '../../scenes/Auth/ChangePassword/types';
import { ForgotFormValues } from '../../scenes/Auth/Forgot/types';
import { LoginFormValues } from '../../scenes/Auth/Login/types';
import { MainFormValues } from '../../scenes/Auth/Register/types';
import { RestorePasswordRequest } from '../../scenes/Auth/RestorePassword/types';
import {
  GET_USER_PERMISSIONS_PENDING,
  LOGIN_USER_BY_QR_CDDE_PENDING,
  LOGIN_USER_BY_QR_CDDE_SUCCESS,
  LOGOUT_USER,
  START_LOADING_PENDING,
  USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING,
  USER_UPDATE_PROFILE_LNG_PENDING,
  USER_UPDATE_PROFILE_PENDING,
  USER_UPDATE_PROFILE_PHONE_PENDING,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_TC_PENDING,
} from './actions';
import { UpdateProfileCustomizationPayload } from './sagas.types';
import {
  StartLoadingDataPayload,
  UpdateDemoProfilePayload,
  UpdateProfileTCPayload,
  User,
  UserUpdateProfileLngPayload,
} from './types';

const LOGIN_USER = 'LOGIN_USER';
const REGISTER_USER = 'REGISTER_USER';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

const startLoadingData = (data: StartLoadingDataPayload) => ({
  type: START_LOADING_PENDING,
  payload: data,
});

const updateUserProfile = (
  body: { [key: string]: string | number | boolean } = {},
  headers: { [key: string]: string } = {},
  authPath = '',
) => ({
  type: LOGIN_USER,
  payload: Auth.updateByToken(body, headers, authPath),
});

const login = (form: LoginFormValues) => ({
  type: LOGIN_USER,
  payload: Auth.login(form),
});

const register = (form: MainFormValues) => ({
  type: REGISTER_USER,
  payload: Auth.register(form),
});

const forgot = (form: ForgotFormValues) => ({
  type: FORGOT_PASSWORD,
  payload: Auth.forgot(form),
});

const restorePassword = (form: RestorePasswordRequest) => ({
  type: RESTORE_PASSWORD,
  payload: Auth.restorePassword(form),
});

const changePassword = (form: ChangePasswordRequest) => ({
  type: CHANGE_PASSWORD,
  payload: Auth.changePassword(form),
});

const updateDemoProfile = (data: UpdateDemoProfilePayload) => ({
  type: USER_UPDATE_PROFILE_SUCCESS,
  payload: data,
});

const userUpdateProfileLng = (data: UserUpdateProfileLngPayload) => ({
  type: USER_UPDATE_PROFILE_LNG_PENDING,
  payload: data,
});

const userUpdateProfilePhone = (phone: string, termsOfService: boolean) => ({
  type: USER_UPDATE_PROFILE_PHONE_PENDING,
  payload: { phone, termsOfService },
});

const getUserPermissions = () => ({
  type: GET_USER_PERMISSIONS_PENDING,
  payload: { onlyUpdate: true },
});

const updateProfileTC = (data: UpdateProfileTCPayload) => ({
  type: USER_UPDATE_PROFILE_TC_PENDING,
  payload: data,
});

const updateProfileCustomization = ({
  customization,
}: UpdateProfileCustomizationPayload) => ({
  type: USER_UPDATE_PROFILE_CUSTOMIZATION_PENDING,
  payload: { customization },
});

const updateProfile = () => ({
  type: USER_UPDATE_PROFILE_PENDING,
});

const loginUserByQRCode = (data: User, token: string) => ({
  type: LOGIN_USER_BY_QR_CDDE_SUCCESS,
  payload: { data, token },
});

const loginUserByQRCodePending = () => ({
  type: LOGIN_USER_BY_QR_CDDE_PENDING,
});

const logout = () => ({ type: LOGOUT_USER });

export default {
  login,
  logout,
  forgot,
  register,
  updateProfile,
  changePassword,
  restorePassword,
  updateProfileTC,
  startLoadingData,
  updateUserProfile,
  loginUserByQRCode,
  updateDemoProfile,
  getUserPermissions,
  userUpdateProfileLng,
  userUpdateProfilePhone,
  loginUserByQRCodePending,
  updateProfileCustomization,
};
