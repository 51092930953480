import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import cn from "classnames";
import React from 'react';

import { ActivityIdentifier, CloseIcon,DropdownButton, FilterIcon, FilterText } from '../../atoms';
import { useStyles } from './styles';
import { Props } from './types';

export function FilterButton(props: Props) {
  const {
    userFilter,
    closeClick,
    translation,
    dropdownClick,
    customMediaQuery,
    customFiltersActive,
    disableCreateCustomFilter
  } = props;

  const classes = useStyles();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down(customMediaQuery ?? 768));

  return (
    <>
      <FilterIcon className={classes.icon} />
      <div className={classes.relative}>
        <FilterText
          isActive={!userFilter}
          className={cn((customFiltersActive || disableCreateCustomFilter) && classes.activeFilter)}
          text={mobileView ? '' : translation('common.filter')}
        />
        <ActivityIdentifier isActive={customFiltersActive && !userFilter} />

        <FilterText
          isActive={!!userFilter}
          className={cn(classes.link)}
          text={userFilter?.name}
        />
      </div>
      {!disableCreateCustomFilter && (
        <>
          <div className={classes.separator} />
          <DropdownButton onClick={dropdownClick} isActive={!userFilter} />
          <CloseIcon onClick={closeClick} isActive={!!userFilter} />
        </>
      )}
    </>
  );
}

export default FilterButton;
