import { CREATE_INTEGRATIONS_PENDING } from './actions';
import { CreateIntegrationPayload } from './sagas.types';

export const SET_SALTEDGE_INTEGRATION_ID = 'SET_SALTEDGE_INTEGRATION_ID';

const setSaltedgeIntegrationId = (id: string) => ({
  type: SET_SALTEDGE_INTEGRATION_ID,
  payload: { id },
});

const createSaltedgeIntegration = (data: CreateIntegrationPayload) => ({
  type: CREATE_INTEGRATIONS_PENDING,
  payload: data,
});

export default {
  setSaltedgeIntegrationId,
  createSaltedgeIntegration,
};
