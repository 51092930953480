import { NordigenCredentials, NordigenCredentialsPayload } from "../../store/integrations/types";
import Http from '../Http';

const getAllBanks = (countryCode: string) =>
  Http.makeInstance().get(`integrations/nordigenApiCredentials/banks/${countryCode}`);

const getIntegrationAccounts = (integrationId: string) =>
  Http.makeInstance().get(`integrations/nordigenApiCredentials/accounts/${integrationId}`);

const getCredentials = (data: NordigenCredentials) =>
  Http.makeInstance().post('integrations/nordigenApiCredentials', { ...data });

const updateCredentials = (data: NordigenCredentialsPayload) =>
  Http.makeInstance().patch('integrations/nordigenApiCredentials', { ...data });

export default {
  getAllBanks,
  getCredentials,
  updateCredentials,
  getIntegrationAccounts
}
