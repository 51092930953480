import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteSelector from '../../../components/AutocompleteSelector';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import payoneerApis from '../../../services/bankIntegrations/payoneer';
import {
  PayoneerAccount,
  UpdateCredentialsResponse,
} from '../../../services/bankIntegrations/payoneer.types';
import Storages, { StorageKey } from '../../../services/Storages';
import integrationActions from '../../../store/integrations/actions';
import { getPayoneerCredentialsId } from '../../../store/integrations/selectors';
import { IntegrationTypeId } from '../../../store/integrations/types';
import PayoneerErrorDialog from './PayoneerErrorDialog';
import { useStyles } from './PayoneerSecondDialog.styles';
import { Props } from './PayoneerSecondDialog.types';

function PayoneerSecondDialog(props: Props) {
  const { onClose } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const facebookContext = useContext(FacebookPixelContext);

  const payoneerIntegration = Storages.get(StorageKey.payoneer);

  const [selectedPayoneerAccount, setSelectedPayoneerAccount] =
    useState<PayoneerAccount | null>(null);
  const [payoneerAccounts, setPayoneerAccounts] = useState<PayoneerAccount[]>(
    [],
  );
  const [loadingIntegrationCredentials, setLoadingIntegrationCredentials] =
    useState(false);

  const [errors, setErrors] = useState({
    payoneerAccount: false,
    integrationData: false,
  });
  const [showPayoneerErrorDialog, setShowPayoneerErrorDialog] = useState(false);

  const credentialsId = useSelector(getPayoneerCredentialsId);

  const handleClose = useCallback(() => {
    Storages.remove(StorageKey.payoneer);
    onClose();
  }, [onClose]);

  const handleClosePayoneerErrorDialog = useCallback(() => {
    setShowPayoneerErrorDialog(false);
  }, []);

  const handleSetPayoneerAccount = useCallback((value: any) => {
    setSelectedPayoneerAccount(value);

    setErrors((values) => ({ ...values, payoneerAccount: false }));
  }, []);

  const handleCreateIntegration = useCallback(async () => {
    if (!selectedPayoneerAccount) {
      setErrors((values) => ({
        ...values,
        payoneerAccount: true,
      }));

      return;
    }

    setLoadingIntegrationCredentials(true);

    const { newAccountName, selectedAccountId, startDate } =
      payoneerIntegration;

    let accountId = selectedAccountId;

    if (newAccountName && selectedPayoneerAccount) {
      const {
        data: { newAccount },
      } = await accountsApi.createAccount(
        selectedPayoneerAccount.currency,
        newAccountName,
        0,
        IntegrationTypeId.Payoneer,
      );

      accountId = newAccount._id;
    }

    if (credentialsId) {
      await payoneerApis.setCredentials({
        credentialsId,
        payoneerAccountCurrency: selectedPayoneerAccount.currency,
        payoneerAccountId: selectedPayoneerAccount.id,
      });

      dispatch(
        integrationActions.createIntegration({
          accountId,
          startDate,
          bankName: 'Payoneer',
          typeId: IntegrationTypeId.Payoneer,
          facebookContext,
        }),
      );
    }

    setLoadingIntegrationCredentials(false);

    handleClose();
  }, [
    dispatch,
    handleClose,
    credentialsId,
    facebookContext,
    payoneerIntegration,
    selectedPayoneerAccount,
  ]);

  const handleUpdateIntegration = useCallback(async () => {
    try {
      const { selectedAccountId, code } = payoneerIntegration;
      const redirectUrl = `${window.location.origin}/`;

      const { data: integrationData }: { data: UpdateCredentialsResponse } =
        await payoneerApis.updateCredentials({
          code,
          accountId: selectedAccountId,
          redirectUrl,
        });

      if (integrationData.credentialsId) {
        dispatch(
          integrationActions.setPayoneerCredentialsId(
            integrationData.credentialsId,
          ),
        );

        const formattedAccounts = integrationData.accounts.map((el) => ({
          id: el.id,
          name: `${el.status_name} - ${el.available_balance}, ${el.currency}`,
          currency: el.currency,
        }));

        // @ts-ignore
        setPayoneerAccounts(formattedAccounts);
      }
    } catch (error) {
      // @ts-ignore
      const status = error?.response?.status;

      if (status === 500) {
        setShowPayoneerErrorDialog(true);
      }
    }
  }, [dispatch, payoneerIntegration]);

  useEffect(() => {
    handleUpdateIntegration();
  }, []); // eslint-disable-line

  return (
    <>
      <Dialog
        onClose={handleClose}
        isOpened
        title={t('integrations.payoneer.secondTitle', {
          postProcess: 'sprintf',
          sprintf: [payoneerIntegration.accountName],
        })}
      >
        <AutocompleteSelector
          rootClassName={classes.selector}
          label={t('integrations.yourCards')}
          onChange={handleSetPayoneerAccount}
          // @ts-ignore
          data={payoneerAccounts}
          // @ts-ignore
          value={selectedPayoneerAccount}
          error={errors.payoneerAccount}
        />

        <CustomButton
          title={t('integrations.setCard')}
          action={handleCreateIntegration}
          loading={loadingIntegrationCredentials}
          fullWidth
          errorText={
            (errors.integrationData && t('integrations.error.createError')) ||
            ''
          }
        />
      </Dialog>
      {showPayoneerErrorDialog && (
        <PayoneerErrorDialog onClose={handleClosePayoneerErrorDialog} />
      )}
    </>
  );
}

export default React.memo(PayoneerSecondDialog);
