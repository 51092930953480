import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { normalize } from '@finmap/core-utils';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import {
  RESET_CREATE_ELEMENT_LOADING,
  SET_CREATE_ELEMENT_ERROR,
  SET_CREATE_ELEMENT_LOADING,
} from '../common/actions';
import { Context } from '../types';
import {
  CREATE_OPERATION_PROJECTS_PENDING,
  CREATE_OPERATION_PROJECTS_SUCCESS,
  CREATE_SETTINGS_PROJECT_PENDING,
  CREATE_SETTINGS_PROJECT_SUCCESS,
  DELETE_PROJECT_PENDING,
  DELETE_PROJECT_SUCCESS,
  GET_LOG_PROJECTS_PENDING,
  GET_LOG_PROJECTS_SUCCESS,
  GET_OPERATION_PROJECTS_PENDING,
  GET_OPERATION_PROJECTS_SUCCESS,
  GET_SETTINGS_PROJECTS_PENDING,
  GET_SETTINGS_PROJECTS_SUCCESS,
  UPDATE_GROUP_PROJECTS_PENDING,
} from './actions';
import projectsApi from './api';
import { UpdateGroupProjectsPayload } from './sagas.types';
import { selectSettingsProjects } from './selectors';
import { CreateProjectPayload, Project } from './types';

export function* getLogProjects() {
  try {
    const { data } = yield call(projectsApi.getProjects, Context.log);

    yield put({ type: GET_LOG_PROJECTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getOperationProjects() {
  try {
    const { data } = yield call(projectsApi.getProjects, Context.operation);

    yield put({ type: GET_OPERATION_PROJECTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getSettingsProjects() {
  try {
    const { data } = yield call(projectsApi.getProjects, Context.settings);

    yield put({ type: GET_SETTINGS_PROJECTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createOperationProject({
  payload: { name },
}: TAction<CreateProjectPayload>) {
  try {
    const { data } = yield call(
      projectsApi.createProject,
      name,
      Context.operation,
    );

    yield put({ type: CREATE_OPERATION_PROJECTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createSettingsProject({ payload: { name } }: any) {
  try {
    yield put({ type: SET_CREATE_ELEMENT_LOADING });

    const allSettingsProjects: Project[] = yield select(selectSettingsProjects);

    const isExistProject = allSettingsProjects.some(
      (el) => el.normalizedLabel === normalize(name),
    );

    if (isExistProject) {
      yield put({ type: SET_CREATE_ELEMENT_ERROR });
    } else {
      const { data } = yield call(
        projectsApi.createProject,
        name,
        Context.settings,
      );

      yield put({ type: CREATE_SETTINGS_PROJECT_SUCCESS, payload: { data } });
    }

    yield put({ type: RESET_CREATE_ELEMENT_LOADING });
  } catch (error) {
    showError(error);
  }
}

export function* deleteProject({ payload: { id } }: any) {
  try {
    const { data } = yield call(projectsApi.deleteProject, id);

    yield put({ type: DELETE_PROJECT_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* updateGroupProjects(
  action: TAction<UpdateGroupProjectsPayload>,
) {
  try {
    const { projects: payloadProjects } = action.payload;

    if (payloadProjects.length === 1) {
      yield put({ type: SET_CREATE_ELEMENT_LOADING });

      const allSettingsProjects: Project[] = yield select(
        selectSettingsProjects,
      );

      const isExistProject = allSettingsProjects.some(
        (el) =>
          el.normalizedLabel === normalize(payloadProjects[0].label) &&
          el._id !== payloadProjects[0]._id,
      );

      if (isExistProject) {
        yield put({ type: SET_CREATE_ELEMENT_ERROR });
      } else {
        yield all(
          payloadProjects.map((project) => {
            const { _id, ...rest } = project;

            return call(projectsApi.updatePropProject, _id, {
              ...rest,
            });
          }),
        );
        yield put({ type: GET_SETTINGS_PROJECTS_PENDING });
      }

      yield put({ type: RESET_CREATE_ELEMENT_LOADING });
    } else {
      yield all(
        payloadProjects.map((project) => {
          const { _id, ...rest } = project;

          return call(projectsApi.updatePropProject, _id, {
            ...rest,
          });
        }),
      );
      yield put({ type: GET_SETTINGS_PROJECTS_PENDING });
    }
  } catch (error) {
    showError(error);
  }
}

export default function projects() {
  return all([
    takeLatest(GET_LOG_PROJECTS_PENDING, getLogProjects),
    takeLatest(GET_OPERATION_PROJECTS_PENDING, getOperationProjects),
    takeLatest(GET_SETTINGS_PROJECTS_PENDING, getSettingsProjects),
    takeLatest(CREATE_OPERATION_PROJECTS_PENDING, createOperationProject),
    takeLatest(CREATE_SETTINGS_PROJECT_PENDING, createSettingsProject),
    takeLatest(DELETE_PROJECT_PENDING, deleteProject),
    takeLatest(UPDATE_GROUP_PROJECTS_PENDING, updateGroupProjects),
  ]);
}
