import { Company, TariffsId } from '../store/company/types';

export const isTrial = (company: Company | null) =>
  company?.tariffId === TariffsId.Trial ||
  company?.tariffId === TariffsId.TrialWithoutRecurrent;

export const isLite = (company: Company | null) =>
  company?.tariffId === TariffsId.Lite;

export const isPro = (company: Company | null) =>
  company?.tariffId === TariffsId.Pro;
