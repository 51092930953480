import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  westernBidDescription: {
    marginTop: 24,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
  },
  westernBidStepOneWrapper: {
    marginTop: 8,
  },
  westernBidStep: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
    marginBottom: 8,
  },
  westernBidText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: '#000000',
  },
  westernBidStepOneList: {
    paddingLeft: 21,
    marginBottom: 16,
  },
  westernBidStepTwoWrapper: {
    marginTop: 24,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  westernBidInfoWrapper: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 24,
  },
  westernBidInfoText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.5,
    marginLeft: 8,
  },
});
