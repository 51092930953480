import { makeStyles } from '@material-ui/core/styles';

import { aquaHaze } from '../../../../../theme/colors';

export const useStyles = makeStyles({
  header: {
    padding: '10px',
    textAlign: 'right',
    cursor: 'pointer',
    opacity: 0.5,
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 366,
  },
  calendarModalContainer: {
    maxWidth: 'none',
    borderRadius: 10,
    overflowX: 'hidden',
    background: '#fff',
    textAlign: 'left',
    boxSizing: 'border-box',
    '-webkit-appearance': 'none',
  },
  time: {
    marginLeft: 11,
    opacity: 0.5,
    cursor: 'pointer',
  },
  buttonContainer: {
    width: 160,
    marginLeft: 16,
  },
  buttonSingeContainer: {
    width: 160,
    marginLeft: 16,
  },
  timeBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 48,
    marginTop: 24,
  },
  fullTimeBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 24,
  },
  timeValueContainer: {
    width: 84,
    height: 48,
  },
  separator: {
    padding: '0 10px',
  },
  repeatsSmallContainer: {
    width: 52,
    height: 48,
    borderRadius: 12,
    backgroundColor: aquaHaze,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
  },
  timeIcon: {
    marginRight: 18,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});
