import UnleashContext, {
  UnleashContextProps,
} from '../Providers/UnleashProvider/UnleashContext';
import { useContext } from 'react';

export default function useUnleash(featureToggleName: string) {
  const unleashContext: UnleashContextProps | null = useContext(UnleashContext);

  return Boolean(unleashContext?.unleash?.isEnabled(featureToggleName));
}
