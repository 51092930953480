import { all } from 'redux-saga/effects';

import accounts from '../store/accounts/sagas';
import history from '../store/analytic/history.sagas';
import analytic from '../store/analytic/sagas';
import auth from '../store/auth/sagas';
import autoRules from '../store/autoRules/sagas';
import calendar from '../store/calendar/sagas';
import categories from '../store/categories/sagas';
import clients from '../store/clients/sagas';
import common from '../store/common/sagas';
import company from '../store/company/sagas';
import currencies from '../store/currency/sagas';
import employees from '../store/employees/sagas';
import filters from '../store/filters/sagas';
import imports from '../store/import/sagas';
import integrations from '../store/integrations/sagas';
import invoicing from '../store/invoicing/sagas';
import journal from '../store/journal/sagas';
import onboardingV2 from '../store/onboardingV2/sagas';
import operations from '../store/operations/sagas';
import paymentHistorySagaWatcher from '../store/paymentHistory/sagas';
import projects from '../store/projects/sagas';
import tags from '../store/tags/sagas';
import bot from './bot';
import operationContexts from './operationContexts';
import settings from './settings';

export default function* rootSaga() {
  yield all([
    auth(),
    journal(),
    operations(),
    paymentHistorySagaWatcher(),
    company(),
    common(),
    clients(),
    employees(),
    calendar(),
    tags(),
    projects(),
    categories(),
    settings(),
    integrations(),
    analytic(),
    bot(),
    imports(),
    accounts(),
    currencies(),
    filters(),
    history(),
    invoicing(),
    onboardingV2(),
    operationContexts(),
    autoRules(),
  ]);
}
