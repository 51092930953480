import { FiltersState } from '../filters/types';
import { OperationType } from '../operations/types';
import { Category, SubCategory } from './types';

export const categoryActionTypes = {
  GET_LOG_INCOME_PENDING: 'GET_LOG_INCOME_PENDING',
  GET_LOG_INCOME_SUCCESS: 'GET_LOG_INCOME_SUCCESS',
  GET_LOG_CATEGORIES_PENDING: 'GET_LOG_CATEGORIES_PENDING',
  GET_LOG_CATEGORIES_SUCCESS: 'GET_LOG_CATEGORIES_SUCCESS',
  GET_OPERATION_INCOME_PENDING: 'GET_OPERATION_INCOME_PENDING',
  GET_OPERATION_INCOME_SUCCESS: 'GET_OPERATION_INCOME_SUCCESS',
  GET_SETTINGS_INCOME_PENDING: 'GET_SETTINGS_INCOME_PENDING',
  GET_SETTINGS_INCOME_SUCCESS: 'GET_SETTINGS_INCOME_SUCCESS',
  CREATE_LOG_INCOME_PENDING: 'CREATE_LOG_INCOME_PENDING',
  CREATE_LOG_INCOME_SUCCESS: 'CREATE_LOG_INCOME_SUCCESS',
  CREATE_OPERATION_INCOME_PENDING: 'CREATE_OPERATION_INCOME_PENDING',
  CREATE_OPERATION_INCOME_SUCCESS: 'CREATE_OPERATION_INCOME_SUCCESS',
  CREATE_SETTINGS_INCOME_PENDING: 'CREATE_SETTINGS_INCOME_PENDING',
  CREATE_SETTINGS_INCOME_SUCCESS: 'CREATE_SETTINGS_INCOME_SUCCESS',
  UPDATE_INCOME_PENDING: 'UPDATE_INCOME_PENDING',
  UPDATE_INCOME_SUCCESS: 'UPDATE_INCOME_SUCCESS',
  DELETE_INCOME_PENDING: 'DELETE_INCOME_PENDING',
  DELETE_INCOME_SUCCESS: 'DELETE_INCOME_SUCCESS',

  GET_LOG_CONSUMPTION_PENDING: 'GET_LOG_CONSUMPTION_PENDING',
  GET_LOG_CONSUMPTION_SUCCESS: 'GET_LOG_CONSUMPTION_SUCCESS',
  GET_OPERATION_CONSUMPTION_PENDING: 'GET_OPERATION_CONSUMPTION_PENDING',
  GET_OPERATION_CONSUMPTION_SUCCESS: 'GET_OPERATION_CONSUMPTION_SUCCESS',
  GET_SETTINGS_CONSUMPTION_PENDING: 'GET_SETTINGS_CONSUMPTION_PENDING',
  GET_SETTINGS_CONSUMPTION_SUCCESS: 'GET_SETTINGS_CONSUMPTION_SUCCESS',
  CREATE_LOG_CONSUMPTION_PENDING: 'CREATE_LOG_CONSUMPTION_PENDING',
  CREATE_LOG_CONSUMPTION_SUCCESS: 'CREATE_LOG_CONSUMPTION_SUCCESS',
  CREATE_OPERATION_CONSUMPTION_PENDING: 'CREATE_OPERATION_CONSUMPTION_PENDING',
  CREATE_OPERATION_CONSUMPTION_SUCCESS: 'CREATE_OPERATION_CONSUMPTION_SUCCESS',
  CREATE_SETTINGS_CONSUMPTION_PENDING: 'CREATE_SETTINGS_CONSUMPTION_PENDING',
  CREATE_SETTINGS_CONSUMPTION_SUCCESS: 'CREATE_SETTINGS_CONSUMPTION_SUCCESS',
  UPDATE_CONSUMPTION_PENDING: 'UPDATE_CONSUMPTION_PENDING',
  UPDATE_CONSUMPTION_SUCCESS: 'UPDATE_CONSUMPTION_SUCCESS',
  DELETE_CONSUMPTION_PENDING: 'DELETE_CONSUMPTION_PENDING',
  DELETE_CONSUMPTION_SUCCESS: 'DELETE_CONSUMPTION_SUCCESS',
  RESET_CATEGORIES: 'RESET_CATEGORIES',
  GET_LOG_CATEGORIES_BY_TYPE: 'GET_LOG_CATEGORIES_BY_TYPE',
  SET_LOG_CATEGORIES: 'SET_LOG_CATEGORIES',
};

const setLogCategories = (selector: keyof FiltersState) => ({
  type: categoryActionTypes.SET_LOG_CATEGORIES,
  payload: { selector },
});

const getLogCategoriesByType = (type: OperationType) => ({
  type: categoryActionTypes.GET_LOG_CATEGORIES_BY_TYPE,
  payload: { type },
});

const getSettingsIncomeCategories = () => ({
  type: categoryActionTypes.GET_SETTINGS_INCOME_PENDING,
});

const getSettingsConsumptionCategories = () => ({
  type: categoryActionTypes.GET_SETTINGS_CONSUMPTION_PENDING,
});

const getLogIncomeCategories = (selector: keyof FiltersState) => ({
  type: categoryActionTypes.GET_LOG_INCOME_PENDING,
  payload: { selector },
});

const getLogConsumptionCategories = (selector: keyof FiltersState) => ({
  type: categoryActionTypes.GET_LOG_CONSUMPTION_PENDING,
  payload: { selector },
});

const getLogCategories = (selector: keyof FiltersState) => ({
  type: categoryActionTypes.GET_LOG_CATEGORIES_PENDING,
  payload: { selector },
});

const getOperationIncomeCategories = () => ({
  type: categoryActionTypes.GET_OPERATION_INCOME_PENDING,
});

const getOperationConsumptionCategories = () => ({
  type: categoryActionTypes.GET_OPERATION_CONSUMPTION_PENDING,
});

const createIncomeCategory = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_INCOME_PENDING,
  payload: { name },
});

const createConsumptionCategories = (name: string) => ({
  type: categoryActionTypes.CREATE_OPERATION_CONSUMPTION_PENDING,
  payload: { name },
});

const createSettingsCategory = (
  name: string,
  type: OperationType,
  parentId?: string,
) => ({
  type:
    type === 'income'
      ? categoryActionTypes.CREATE_SETTINGS_INCOME_PENDING
      : categoryActionTypes.CREATE_SETTINGS_CONSUMPTION_PENDING,
  payload: { name, parentId },
});
const deleteCategory = (id: string, type: OperationType) => ({
  type:
    type === 'income'
      ? categoryActionTypes.DELETE_INCOME_PENDING
      : categoryActionTypes.DELETE_CONSUMPTION_PENDING,
  payload: { id },
});

const updateSettingsCategories = (
  categories: (Category | SubCategory)[],
  type: OperationType,
) => ({
  type:
    type === 'income'
      ? categoryActionTypes.UPDATE_INCOME_PENDING
      : categoryActionTypes.UPDATE_CONSUMPTION_PENDING,
  payload: { categories },
});

export default {
  deleteCategory,
  setLogCategories,
  getLogCategories,
  createIncomeCategory,
  getLogIncomeCategories,
  createSettingsCategory,
  getLogCategoriesByType,
  updateSettingsCategories,
  createConsumptionCategories,
  getLogConsumptionCategories,
  getSettingsIncomeCategories,
  getOperationIncomeCategories,
  getSettingsConsumptionCategories,
  getOperationConsumptionCategories,
};
