export function formatPrice(price: string) {
  return price.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$& ');
}

export function formatStringPrice(
  value: number | string,
  allowNegative = false,
  count = 3,
) {
  const valueToString = value?.toString() || '';
  const price =
    valueToString.indexOf('.') >= 0
      ? valueToString.substr(0, valueToString.indexOf('.')) +
        valueToString.substr(valueToString.indexOf('.'), count)
      : valueToString;

  const formPrice = price
    .replace(',', '.')
    .replace(/[^.\d]+/g, '')
    .replace(/^([^.]*\.)|\./g, '$1');

  if (valueToString.charAt(0) === '-' && allowNegative) {
    return `-${formPrice}`;
  }

  return formPrice;
}
