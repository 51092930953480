import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class UkrGazBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          proceedCaseConfig: {
            fields: {
              date: { column: ['DATA_D', 6] },
              currency: { column: ['CUR_TAG', 12] },
              credit: { column: ['SUM_PD_E', 18], if: { eq: [{column: ['DK', 7]}, '1'] } },
              debit: { column: ['SUM_PD_E', 18], if: { neq: [{column: ['DK', 7]}, '1'] } },
              comment: { column: ['PURPOSE', 19] },
              counterparty: { column: ['NAME_KOR', 11] },
            }
          }
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          proceedCaseConfig: {
            fields: {
              date: { column: ['DATA_D', 6] },
              currency: { column: ['CUR_TAG', 12] },
              credit: { column: ['SUM_PD_E', 18], if: { eq: [{column: ['DK', 7]}, '1'] } },
              debit: { column: ['SUM_PD_E', 18], if: { neq: [{column: ['DK', 7]}, '1'] } },
              comment: { column: ['PURPOSE', 19] },
              counterparty: { column: ['NAME_KOR', 11] },
            }
          }
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          encoding: 'win1251',
          preParserConfigs: { CSVDelimiter: ';' },
          proceedCaseConfig: {
            fields: {
              date: { column: ['DATA_D', 6] },
              currency: { column: ['CUR_TAG', 12] },
              credit: { column: ['SUM_PD_E', 18], if: { eq: [{column: ['DK', 7]}, '1'] }, div: 100 },
              debit: { column: ['SUM_PD_E', 18], if: { neq: [{column: ['DK', 7]}, '1'] }, div: 100 },
              comment: { column: ['PURPOSE', 19] },
            }
          }
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            maxInterlineSpacingPx: 10,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'middle',
            rotate: true,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { in: 'Номер документу' }, nextWord: { in: 'КОРЕСПОНДЕНТ' } }],
                to: [{ word: { in: 'Код банку' }, prevWord: { in: 'Назва банку' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Номер документу' }, nextWord: { in: 'КОРЕСПОНДЕНТ' } }],
                  count: 1,
                },
                {
                  from: [{ word: { startsWith: 'Підсумок оборотів' } }],
                  count: 1,
                }
              ],
              define: [{ regexp: { str: '^([\\s\\d]+,\\d+)$' } }]
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: { column: ['Номер документу\nДата',0], split: { by: '\n', get: 1 } },
              comment: { column: ['ПРИЗНАЧЕННЯ', 5] },
              debit: { column: ['СУМА ДЕБЕТ\nСписано', 3] },
              credit: { column: ['СУМА КРЕДИТ\nЗараховано',4] },
            }
          }
        },
      },
    ],
  };
}
