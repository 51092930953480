import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';
import moment from 'moment';

export class UbuzParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        proceedCase: (importDocument) => {
          let lastBodyLineRaw = importDocument.length;
          importDocument.slice(4).forEach((e, i) => {
            if(!moment(e[0], 'DD.MM.YYYY hh:mm:ss').isValid()) {
              lastBodyLineRaw = i - 1;
            }
          });

          const header = importDocument[3];
          const body = importDocument.slice(4, lastBodyLineRaw).map(el => {
            el[0] = moment(el[0], 'DD.MM.YYYY hh:mm:ss').toDate().toString();
            return el
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата \nпроводки', 0]),
            debit: this.getFirstValidCellByColumn(['Дебет', 7]),
            credit: this.getFirstValidCellByColumn(['Кредит', 8]),
            // counterparty: this.getFirstValidCellByColumn([
            //   'Наименование      корресп.',
            //   4,
            // ]),
            comment: this.getFirstValidCellByColumn(['Детали', 6]),
          });
        },
        caseOptions: { defaultCurrency: 'UZS' },
      },
    ],
  };
}
