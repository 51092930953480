import { makeStyles, Radio, RadioProps } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginRight: 15,
  },
  icon: {
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    border: 'none',
    boxShadow: 'none',
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: 'radial-gradient(#000,#000 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

function StyledRadio(props: RadioProps & { rootOverrideClasses?: string }) {
  const { rootOverrideClasses, ...restProps } = props;
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: rootOverrideClasses,
      }}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={cn(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...restProps}
    />
  );
}

export default React.memo(StyledRadio);
