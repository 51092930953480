import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EditIcon from '../../../../../assets/images/svg/edit.svg';
import InformerIcon from '../../../../../assets/images/svg/info.svg';
import AutocompleteSelector from '../../../../../components/AutocompleteSelector';
import CustomButton from '../../../../../components/Button';
import ButtonRowWithIcons from '../../../../../components/ButtonRowWithIcons';
import Dialog from '../../../../../components/Dialog/Dialog';
import { USE_CURRENCY_PROVIDER } from '../../../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../../../hooks/useUnleash';
import currencyActions from '../../../../../store/currency/actions';
import {
  getCurrencies,
  getCustomCurrencies,
  getDefaultCurrencyProviders,
  selectAutocompleteFormatCurrencyProviders,
} from '../../../../../store/currency/selectors';
import { AutoCompleteProps } from '../../../../../store/types';
import CreateAndEditDialog from './CreateDialog';
import EditDialog from './EditDialog';
import { useStyles } from './styles';

export type Props = {
  showInDialog?: boolean;

  onClose?(): void;
};

function CustomCourses(props: Props) {
  const { onClose, showInDialog = true } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const useCurrencyProvider = useUnleash(USE_CURRENCY_PROVIDER);

  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [provider, setProvider] = useState<AutoCompleteProps | null>(null);

  const data = useSelector(getCustomCurrencies);
  const currencies = useSelector(getCurrencies);
  const dataProviders = useSelector(selectAutocompleteFormatCurrencyProviders);
  const defaultProvider = useSelector(getDefaultCurrencyProviders);

  const onUpdateDefaultProvider = useCallback(
    (providerId: string) => {
      dispatch(currencyActions.updateDefaultCurrencyProvider(providerId));
    },
    [dispatch],
  );

  const handleChangeProvider = useCallback(
    (value: AutoCompleteProps) => {
      setProvider(value);

      onUpdateDefaultProvider(value.id);
    },
    [onUpdateDefaultProvider],
  );

  const handleOpenEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const handleCloseEditDialog = useCallback(() => {
    setShowEditDialog(false);
  }, []);

  const handleEdit = useCallback((el: any) => {
    setSelectedRow(el);
  }, []);

  const handleShowCreateDialog = useCallback(() => {
    setShowCreateDialog(true);
  }, []);

  const handleCloseCreateDialog = useCallback(() => {
    setShowCreateDialog(false);
    setSelectedRow(null);
  }, []);

  const SecondIconComponent = useCallback(() => {
    if (showInDialog) {
      return null;
    }

    return <img src={EditIcon} alt="edit" />;
  }, [showInDialog]);

  const getSymbol = useCallback(
    (rate: string) => currencies.find((el) => el.code === rate)?.symbol ?? '',
    [currencies],
  );

  const Component = useCallback(
    () => (
      <div className={classes.withoutDialogContainer}>
        <CustomButton
          title={t('userMenu.fixedCurrencies.addOwn')}
          action={handleShowCreateDialog}
          className={classes.marginBottom24}
        />
        {useCurrencyProvider && (
          <>
            <AutocompleteSelector
              label={t('userMenu.fixedCurrencies.provider.label')}
              value={provider}
              onChange={handleChangeProvider}
              data={dataProviders}
              rootClassName={classes.autocompleteSelector}
            />
            <div className={classes.descriptionRow}>
              <img src={InformerIcon} alt="inform" />
              <Typography className={classes.descriptionText}>
                {t('userMenu.fixedCurrencies.customCurrencyDescription')}
              </Typography>
            </div>
            <div className={classes.separatorRow}>
              <div className={classes.separator} />
              <Typography className={classes.separatorText}>
                {t('userMenu.fixedCurrencies.title')}
              </Typography>
              <div className={classes.separator} />
            </div>
          </>
        )}
        <>
          {data.map((el) => (
            <ButtonRowWithIcons
              key={el._id}
              // @ts-ignore
              icon={null}
              alt="trash"
              label={`${el.from} (${getSymbol(el.from)}) - ${
                el.to
              } (${getSymbol(el.to)}) ${el.rate}`}
              onClick={() => handleEdit(el)}
              showUnderLine={data.length > 1}
              SecondIconComponent={SecondIconComponent}
            />
          ))}
        </>
      </div>
    ),
    [
      t,
      data,
      classes,
      provider,
      getSymbol,
      handleEdit,
      dataProviders,
      SecondIconComponent,
      useCurrencyProvider,
      handleChangeProvider,
      handleShowCreateDialog,
    ],
  );

  useEffect(() => {
    dispatch(currencyActions.getDefaultCurrencyProvider());
    dispatch(currencyActions.getCurrencyProviders());
    dispatch(currencyActions.getCustomCurrencyRates());
  }, [dispatch]);

  useEffect(() => {
    if (defaultProvider) {
      const element = dataProviders.find((el) => el.id === defaultProvider._id);

      if (element) {
        setProvider(element);
      }
    }
  }, [dataProviders, defaultProvider]);

  useEffect(() => {
    if (selectedRow) {
      handleOpenEditDialog();
    }
  }, [selectedRow, handleOpenEditDialog]);

  return (
    <>
      {showInDialog && (
        <Dialog
          onClose={onClose}
          isOpened
          title={t('userMenu.fixedCurrencies.title')}
        >
          <Component />
        </Dialog>
      )}
      {!showInDialog && (
        <div className={classes.column}>
          <Typography className={classes.title}>
            {t('userMenu.fixedCurrencies.title')}
          </Typography>
          <Component />
        </div>
      )}
      {showCreateDialog && (
        <CreateAndEditDialog onClose={handleCloseCreateDialog} />
      )}
      {showEditDialog && selectedRow && (
        <EditDialog item={selectedRow} onClose={handleCloseEditDialog} />
      )}
    </>
  );
}

export default React.memo(CustomCourses);
