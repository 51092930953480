import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class SportbankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const dateAndTimeRaw = this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.split('\n');
            const dateIndex = dateAndTimeRaw
              .findIndex(el => moment(el, 'DD.MM.YYYY', true).isValid());
            const dateAndTime = `${dateAndTimeRaw[dateIndex]} ${dateAndTimeRaw[dateIndex+1]}`;
            const comment = this.getFirstValidCellByColumn(['Деталі операції', 3]);
            const sum = this.getFirstValidCellByColumn([
              'Сума\nоперації\nза рахунком',
              7,
            ]);
            return {
              dateAndTime,
              comment,
              sum,
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            interlineSpacingAccuracy: 4,
            separatorsDistanceModifier: 1,
            verticalAlign: 'middle',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Дата') && etc?.nextWord?.includes('Сума'),
                (word, etc) =>
                  word?.includes('рахунком') &&
                  (etc?.prevWord?.includes('за рахунком') || etc?.prevWord?.includes('рахунком')) &&
                  (moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid() ||
                    /^[А-Я,A-Z].+/.test(etc?.nextWord)),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  word?.includes('рахунком') &&
                  (etc?.prevWord?.includes('за рахунком') || etc?.prevWord?.includes('рахунком')) &&
                  (moment(etc?.nextWord, 'DD.MM.YYYY', true).isValid() ||
                    /^[А-Я,A-Z].+/.test(etc?.nextWord)),
                1,
              );
              self.deleteFromTo(
                (word, etc) => word?.startsWith('Всього надходжень'),
                undefined,
              );
              self.defineOperation([
                (value) => /^(-?[\d]+,\d+)$/.test(value), // '-16900,00'
              ]);
            },
          },
        },
      },
    ],
  };
}
