import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';

import { findSubTypeInTranslation } from '../foundation/Header/Settings/Components/CategoriesDialog/utils';
import categoryActions from '../store/categories/actions';
import {
  selectSettingsConsumptionCategories,
  selectSettingsIncomeCategories,
} from '../store/categories/selectors';
import { CategoriesType, OperationType } from '../store/operations/types';

function useCreateSettingsCategory(type: CategoriesType) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [categoryName, setCategoryName] = useState('');
  const [nameError, setNameError] = useState('');

  const incomeCategories = useSelector(selectSettingsIncomeCategories);
  const consumptionCategories = useSelector(
    selectSettingsConsumptionCategories,
  );

  const categories =
    type === (CategoriesType.systemIncome || CategoriesType.myIncome)
      ? incomeCategories
      : consumptionCategories;

  const onSetCategoryLabel = useCallback((value: string) => {
    setNameError('');
    setCategoryName(value);
  }, []);

  const onCreateCategory = useCallback(
    (name: string) => {
      const operationType =
        type === CategoriesType.myIncome
          ? OperationType.income
          : OperationType.consumption;

      dispatch(categoryActions.createSettingsCategory(name, operationType));
    },
    [dispatch, type],
  );

  const onPrevCreate = useCallback(
    (callback?: () => void) => {
      if (!categoryName) {
        setNameError(t('system.fieldMustFilled'));

        return;
      }

      const isExistInDefaultCategories = findSubTypeInTranslation(
        categoryName,
        type,
      );
      const isExistCategory = categories.some((el) => {
        if (el.subcategories.length) {
          const isExistSubCategory = el.subcategories.some(
            (subEl) => subEl.normalizedLabel === normalize(categoryName),
          );

          if (isExistSubCategory) {
            return true;
          }
        }

        return el.normalizedLabel === normalize(categoryName);
      });

      if (isExistCategory || isExistInDefaultCategories) {
        setNameError(t('errors.create.category.exist'));

        return;
      }

      setNameError('');

      onCreateCategory(categoryName);

      setCategoryName('');

      if (callback) {
        callback();
      }
    },
    [categories, onCreateCategory, t, type, categoryName],
  );

  return {
    nameError,
    categoryName,
    onPrevCreate,
    onCreateCategory,
    onSetCategoryLabel,
  };
}

export default useCreateSettingsCategory;
