import {
  blueRibbon,
  catskillWhite,
  outrageousOrange,
  persianGreen,
  white,
} from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  button: {
    width: 224,
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '18px 0',
    cursor: 'pointer',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': {
      background: catskillWhite,
      borderRadius: 8,
    },
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  opacity: {
    opacity: 0.5,
  },
  margin16: {
    marginRight: 16,
  },
  hideText: {
    fontSize: 13,
  },
  dialog: {
    borderRadius: '24px !important',
  },
  bankSetButton: {
    height: 25,
    background: 'rgba(0, 178, 142, 0.3)',
    '&:hover': {
      background: 'rgba(0, 178, 142, 0.3)',
    },
  },
  bankText: {
    fontSize: 13,
    color: persianGreen,
  },
  bankOffButton: {
    height: 25,
    marginRight: 15,
    background: 'rgba(255, 99, 51, 0.2)',
    '&:hover': {
      background: 'rgba(255, 99, 51, 0.2)',
    },
  },
  bankOffText: {
    fontSize: 13,
    color: outrageousOrange,
  },
  demo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: blueRibbon,
    height: 18,
    width: 33,
    fontSize: 9,
    lineHeight: '10px',
    color: white,
    borderRadius: 12,
    marginLeft: 4,
  },
});
