import { createContext } from 'react';
import { UnleashClient } from 'unleash-proxy-client';

export type UnleashContextProps = {
  unleash: UnleashClient | null;
};

const UnleashContext = createContext<UnleashContextProps | null>(null);

export const UnleashProvider = UnleashContext.Provider;
export default UnleashContext;
