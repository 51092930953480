import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  paperContainer: {
    maxHeight: 260,
    marginTop: 5,
    overflow: 'auto',
    paddingTop: 5,
    paddingBottom: 10,
  },
  disabledInput: {
    cursor: 'unset',
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  crypto: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '16px',
    paddingLeft: 25,
    opacity: 0.3,
  },
  listRoot: {
    overflow: 'auto',
    marginRight: 4,
    maxHeight: 260,
    position: 'relative',
  },
});
