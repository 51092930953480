import React from 'react';

import { useStyles } from './styles';
import ArrowIcon from '../../assets/images/svg/dropdown-black.svg';

export interface DropdownButtonProps {
  className?: string;
  isActive?: boolean;
  onClick?(event: any): void;
}

export function DropdownButton({
  className,
  isActive = true,
  onClick,
}: DropdownButtonProps) {
  const classes = useStyles();

  if (!isActive) return null;

  return (
    <div
      className={`${className || ''} ${classes.arrowContainer}`}
      onClick={onClick}
    >
      <img alt="filter" src={ArrowIcon} />
    </div>
  );
}

export default DropdownButton;
