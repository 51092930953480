import { outrageousOrange, persianGreen } from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
  },
  lastBlock: {
    marginBottom: 24,
    boxShadow: 'none',
  },
  fieldWrapper: {
    marginBottom: 14,
  },
  saveText: {
    color: persianGreen,
  },
  deleteText: {
    color: outrageousOrange,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  link: {
    cursor: 'pointer',
  },
  defaultText: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 400,
  },
  icon: {
    marginRight: 19,
  },
  disabled: {
    opacity: 0.5,
  },
});
