import { black, blue } from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useCompareStyles = makeStyles({
  root: {
    background: 'lightblue !important',
  },
  genericRoot: {
    background: 'rgba(6, 91, 255, 0.18) !important',
  },
  genericText: {
    color: blue,
    position: 'absolute',
    top: 54,
    fontSize: 12,
    left: 5,
  },
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: black,
      opacity: 1,
    },
    '& .MuiButton-root.Mui-disabled': {
      color: black,
      opacity: 1,
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: black,
      opacity: 1,
    },
  },
});
