import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { normalize } from '@finmap/core-utils';

import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import {
  RESET_CREATE_ELEMENT_LOADING,
  SET_CREATE_ELEMENT_ERROR,
  SET_CREATE_ELEMENT_LOADING,
} from '../common/actions';
import { Context } from '../types';
import { types } from './actions';
import api from './api';
import {
  CreateClientsByTypePayload,
  DeleteClientsByTypePayload,
  GetClientsByTypePayload,
  UpdateClientsByTypePayload,
} from './sagas.types';
import {
  selectSettingsBorrowers,
  selectSettingsClients,
  selectSettingsCreditors,
  selectSettingsInvestors,
  selectSettingsOwners,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsTaxOrganisations,
} from './selectors';
import { Client, UrlPrefix } from './types';

export function* getClientsByTypeSettings({
  payload: { prefix },
}: TAction<GetClientsByTypePayload>) {
  try {
    const { data } = yield call(api.getClients, prefix, Context.settings);

    yield put({ type: `GET_SETTINGS_${prefix}_SUCCESS`, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getClientsByTypeOperations({
  payload: { prefix },
}: TAction<GetClientsByTypePayload>) {
  try {
    const { data } = yield call(api.getClients, prefix, Context.operation);

    yield put({ type: `GET_OPERATIONS_${prefix}_SUCCESS`, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getClientsByTypeLog({
  payload: { prefix },
}: TAction<GetClientsByTypePayload>) {
  try {
    const { data } = yield call(api.getClients, prefix, Context.log);

    yield put({ type: `GET_LOG_${prefix}_SUCCESS`, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getAllSettingsClientsByPrefix(prefix: UrlPrefix) {
  let allClientsByType: Client[];

  switch (prefix) {
    case UrlPrefix.clients:
      allClientsByType = yield select(selectSettingsClients);
      break;
    case UrlPrefix.investors:
      allClientsByType = yield select(selectSettingsInvestors);
      break;
    case UrlPrefix.borrowers:
      allClientsByType = yield select(selectSettingsBorrowers);
      break;
    case UrlPrefix.owners:
      allClientsByType = yield select(selectSettingsOwners);
      break;
    case UrlPrefix.creditors:
      allClientsByType = yield select(selectSettingsCreditors);
      break;
    case UrlPrefix.suppliers:
      allClientsByType = yield select(selectSettingsSuppliers);
      break;
    case UrlPrefix.staffMembers:
      allClientsByType = yield select(selectSettingsStaffMembers);
      break;
    case UrlPrefix.taxOrganisations:
      allClientsByType = yield select(selectSettingsTaxOrganisations);
      break;
    default:
      allClientsByType = [];
  }

  return allClientsByType;
}

export function* createClientByTypeSettings({
  payload: { name, prefix },
}: TAction<CreateClientsByTypePayload>) {
  try {
    yield put({ type: SET_CREATE_ELEMENT_LOADING });

    const allClientsByType: Client[] = yield getAllSettingsClientsByPrefix(
      prefix,
    );

    const isExistClient = allClientsByType.some(
      (el) => el.normalizedLabel === normalize(name),
    );

    if (isExistClient) {
      yield put({ type: SET_CREATE_ELEMENT_ERROR });
    } else {
      const { data } = yield call(
        api.createClient,
        prefix,
        name,
        Context.settings,
      );

      yield put({
        type: `CREATE_SETTINGS_${prefix}_SUCCESS`,
        payload: { data },
      });
    }

    yield put({ type: RESET_CREATE_ELEMENT_LOADING });
  } catch (error) {
    showError(error);
  }
}

export function* createClientByTypeOperations({
  payload: { name, prefix },
}: TAction<CreateClientsByTypePayload>) {
  try {
    const { data } = yield call(
      api.createClient,
      prefix,
      name,
      Context.operation,
    );

    yield put({
      type: `CREATE_OPERATIONS_${prefix}_SUCCESS`,
      payload: { data },
    });
  } catch (error) {
    showError(error);
  }
}

export function* updateClientByType(
  action: TAction<UpdateClientsByTypePayload>,
) {
  try {
    const { clients, prefix } = action.payload;

    if (clients.length === 1) {
      yield put({ type: SET_CREATE_ELEMENT_LOADING });

      const allClientsByType: Client[] = yield getAllSettingsClientsByPrefix(
        prefix,
      );

      const isExistClient = allClientsByType.some(
        (el) =>
          el.normalizedLabel === normalize(clients[0].label) &&
          el._id !== clients[0]._id,
      );

      if (isExistClient) {
        yield put({ type: SET_CREATE_ELEMENT_ERROR });
      } else {
        yield all(
          clients.map((client) => call(api.updateClient, prefix, client)),
        );

        const { data } = yield call(api.getClients, prefix, Context.settings);

        yield put({
          type: `GET_SETTINGS_${prefix}_SUCCESS`,
          payload: { data },
        });
      }

      yield put({ type: RESET_CREATE_ELEMENT_LOADING });
    } else {
      yield all(
        clients.map((client) => call(api.updateClient, prefix, client)),
      );

      const { data } = yield call(api.getClients, prefix, Context.settings);

      yield put({ type: `GET_SETTINGS_${prefix}_SUCCESS`, payload: { data } });
    }
  } catch (error) {
    showError(error);
  }
}

export function* deleteClientByType({
  payload: { id, prefix },
}: TAction<DeleteClientsByTypePayload>) {
  try {
    const { data } = yield call(api.deleteClient, prefix, id);

    yield put({ type: `DELETE_SETTINGS_${prefix}_SUCCESS`, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getSettingsClients() {
  try {
    yield all(
      Object.keys(UrlPrefix).map((prefix) =>
        // @ts-ignore
        getClientsByTypeSettings({ payload: { prefix } }),
      ),
    );
  } catch (error) {
    showError(error);
  }
}

export default function clientsSaga() {
  return all([
    takeLatest(
      types.GET_CLIENTS_BY_TYPE_PENDING_SETTINGS,
      getClientsByTypeSettings,
    ),
    takeLatest(
      types.GET_CLIENTS_BY_TYPE_PENDING_OPERATIONS,
      getClientsByTypeOperations,
    ),
    takeLatest(types.GET_CLIENTS_BY_TYPE_PENDING_LOG, getClientsByTypeLog),
    takeLatest(
      types.CREATE_CLIENT_BY_TYPE_SETTINGS_PENDING,
      createClientByTypeSettings,
    ),
    takeLatest(
      types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING,
      createClientByTypeOperations,
    ),
    takeLatest(types.UPDATE_CLIENT_BY_TYPE_PENDING, updateClientByType),
    takeLatest(types.DELETE_CLIENT_BY_TYPE_PENDING, deleteClientByType),
    takeLatest(types.GET_SETTINGS_CLIENTS, getSettingsClients),
  ]);
}
