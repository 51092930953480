import { INTERCOM_APP_ID } from '../constants';
import { ChangePasswordRequest } from '../scenes/Auth/ChangePassword/types';
import { ForgotFormValues } from '../scenes/Auth/Forgot/types';
import { LoginFormValues } from '../scenes/Auth/Login/types';
import { MainFormValues } from '../scenes/Auth/Register/types';
import { RestorePasswordRequest } from '../scenes/Auth/RestorePassword/types';
import Http from '../services/Http';
import { User } from '../store/auth/types';

export type UpdateByTokenResponse = {
  data: {
    user: User;
  };
  token: string;
};

class Auth {
  static async authorize(
    path: string,
    body: Record<string, unknown>,
    headers: Record<string, unknown> = {},
  ): Promise<void> {
    try {
      const response = await Http.makeInstance().post(path, body, {
        // @ts-ignore
        headers,
      });
      return response.data;
    } catch (err) {
      // @ts-ignore
      Http.handleError(err);
    }
  }

  register = async (form: MainFormValues) => {
    const response = await Auth.authorize('/registration', form);

    return response;
  };

  login = async ({ email, password }: LoginFormValues) => {
    const response = await Auth.authorize('/login', { email, password });

    const { name, phone } = (response as any).data;
    if ((window as any).Intercom) {
      (window as any).Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        name,
        email,
        phone,
      });
    }

    return response;
  };

  forgot = ({ email }: ForgotFormValues) =>
    Auth.authorize('/password/recovery/forgot_password', { email });

  restorePassword = (data: RestorePasswordRequest) =>
    Auth.authorize('/password/recovery/restore_password', data);

  changePassword = (form: ChangePasswordRequest) =>
    Auth.authorize('/update_user_data', form);

  updateByToken = async (
    body: { [key: string]: string | number | boolean },
    headers: { [key: string]: string },
    authPath = '/user/authByToken',
  ) => {
    const response = await Auth.authorize(authPath, body, headers);
    // @ts-ignore
    const { data, token: responseToken }: UpdateByTokenResponse = response;

    return {
      data: data.user,
      token: responseToken,
    };
  };
}

export default new Auth();
