import Spinner from '../Spinner';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import React from 'react';

function MsgToast({ title }: { title: string }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Spinner />
      <Typography className={classes.text}>{title}</Typography>
    </div>
  );
}

export default React.memo(MsgToast);
