import { AnyAction } from 'redux';

import Storages, { StorageKey } from '../../services/Storages';
import {
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_FULFILLED,
  FORGOT_PASSWORD_PENDING,
  GET_USER_PERMISSIONS_PENDING,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_SUCCESS,
  LOGIN_USER_BY_QR_CDDE_PENDING,
  LOGIN_USER_BY_QR_CDDE_SUCCESS,
  LOGIN_USER_FAILED,
  LOGIN_USER_FULFILLED,
  LOGIN_USER_PENDING,
  LOGOUT_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_FULFILLED,
  REGISTER_USER_PENDING,
  RESET_NEW_USER,
  SET_USER_TOKEN,
  START_LOADING_PENDING,
  START_LOADING_SUCCESS,
  USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS,
  USER_UPDATE_PROFILE_LNG_SUCCESS,
  USER_UPDATE_PROFILE_PENDING,
  USER_UPDATE_PROFILE_PHONE_SUCCESS,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_TC_SUCCESS,
} from './actions';
import { AuthState, User } from './types';

const initialState: AuthState = {
  errors: {},
  isError: false,
  loading: false,
  user: (Storages.get(StorageKey.user) as User) || null,
  isNew: false,
  userPermissions: [],
  profileUpdating: false,
  permissionsUpdating: false,
  isAppLoading: true,
  token: null,
};

// eslint-disable-next-line default-param-last
const authReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case START_LOADING_PENDING: {
      return {
        ...state,
        isAppLoading: true,
      };
    }

    case START_LOADING_SUCCESS: {
      Storages.remove(StorageKey.currenUserCustomFilter);

      return {
        ...state,
        isAppLoading: false,
      };
    }

    case SET_USER_TOKEN: {
      return {
        ...state,
        token: payload.token,
      };
    }

    case GET_USER_PERMISSIONS_PENDING: {
      return {
        ...state,
        permissionsUpdating: true,
      };
    }

    case USER_UPDATE_PROFILE_PENDING: {
      return {
        ...state,
        profileUpdating: true,
      };
    }

    case RESET_NEW_USER: {
      return {
        ...state,
        isNew: false,
      };
    }
    case GET_USER_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        userPermissions: payload.data || [],
        permissionsUpdating: false,
      };
    }

    case LOGIN_USER_PENDING:
    case REGISTER_USER_PENDING:
    case FORGOT_PASSWORD_PENDING:
    case CHANGE_PASSWORD_PENDING:
    case LOGIN_USER_BY_QR_CDDE_PENDING:
      return {
        ...state,
        errors: {},
        isError: false,
        loading: true,
      };

    case LOGIN_USER_FAILED:
    case REGISTER_USER_FAILED:
    case FORGOT_PASSWORD_FAILED:
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        errors: payload,
      };

    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
      };
    }
    case USER_UPDATE_PROFILE_SUCCESS:
    case USER_UPDATE_PROFILE_LNG_SUCCESS:
    case USER_UPDATE_PROFILE_PHONE_SUCCESS:
    case USER_UPDATE_PROFILE_TC_SUCCESS:
    case USER_UPDATE_PROFILE_CUSTOMIZATION_SUCCESS:
    case LOGIN_USER_FULFILLED:
    case LOGIN_USER_BY_QR_CDDE_SUCCESS:
    case REGISTER_USER_FULFILLED: {
      if (!payload.data?._id) {
        Storages.put(StorageKey.user, null);

        return {
          ...state,
          user: null,
        };
      }

      const user = {
        ...payload.data,
        id: payload.data._id,
        token: payload.token,
      };

      Storages.put(StorageKey.user, user);

      return {
        ...state,
        user,
        errors: {},
        isError: false,
        loading: false,
        profileUpdating: false,
        isNew: type === REGISTER_USER_FULFILLED,
      };
    }

    case FORGOT_PASSWORD_FULFILLED:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
      };

    case LOGOUT_USER:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

export default authReducer;
