import xlsx, { ParsingOptions } from 'xlsx';

import { DATE_AND_TIME_FORMAT } from '../base-import-parser-v3.const';
import { BaseImportPreParser } from './base-import-preparser';

export class BaseXLSXPreParser extends BaseImportPreParser {
  protected workBook: xlsx.WorkBook;
  protected sheetName: string;
  protected sheet: xlsx.WorkSheet;
  protected jsonSheet: unknown[][];
  protected importDocument: unknown[][] = [];

  async getRawData(
    buffer: ArrayBuffer,
    {
      sheetNumber = 0,
      sheetName = undefined,
    }: {
      sheetNumber?: number;
      sheetName?: string;
    } = {},
  ): Promise<unknown[][]> {
    const OPTIONS: ParsingOptions = {
      cellDates: true,
      type: 'buffer',
      raw: false,
      dateNF: DATE_AND_TIME_FORMAT,
      header: 1,
      defval: '',
    } as ParsingOptions;

    this.workBook = xlsx.read(buffer, OPTIONS);
    this.sheetName = sheetName
      ? sheetName
      : this.workBook.SheetNames[sheetNumber];
    this.sheet = this.workBook.Sheets[this.sheetName];

    this.jsonSheet = xlsx.utils.sheet_to_json(this.sheet, OPTIONS);

    return this.jsonSheet;
  }

  async preParse(
    buffer: ArrayBuffer,
    {
      sheetNumber = 0,
      sheetName = undefined,
    }: {
      sheetNumber?: number;
      sheetName?: string;
    } = {},
  ): Promise<unknown[][]> {
    this.importDocument = await this.getRawData(buffer, {
      sheetNumber,
      sheetName,
    });
    return this.importDocument;
  }
}
