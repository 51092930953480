import { makeStyles } from '@material-ui/core/styles';

import { persianGreen, white } from '../../constants/theme-colors';

export const useStyles = makeStyles({
  popupRoot: {
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
  },
  fullPaddingRoot: {
    padding: '16px 20px',
  },
  popupTitle: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '16px',
    marginBottom: 12,
    opacity: 0.3,
  },
  popupDescription: {
    fontSize: 13,
    lineHeight: '20px',
    opacity: 0.5,
    marginBottom: 22,
  },
  popupLink: {
    color: persianGreen,
    cursor: 'pointer',
  },
});
