import moment from 'moment';

import { BasePdfParser } from '@finmap/import-parsers/base-pdf-parser';
import {
  AnyObject,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class MBankVATPdfParser extends BasePdfParser {
  protected COLUMNS_MAP = {
    date: ['Data'],
    sum: ['Kwota'],
    comment: ['Opis operacji'],
  };

  protected COORDINATES = [1.5, 4.8, 7.8, 27.1, 30.1, 100];

  protected POSITIONS = ['date', 'anotherDate', 'comment', 'sum', 'saldo'];

  protected importName = 'MBankVAT';

  protected DATE_FORMAT = 'YYYY-MM-DD';

  protected MULTY_FIELDS = ['comment'];

  protected currencyCode;

  protected doBeforeTranform() {
    let currencyRes;
    let over = false;
    for (const key in this.sheetJson[0]) {
      const arr = this.sheetJson[0][key].map(({ text }) => text);
      for (let i = 0; i < arr.length; i++) {
        const text = arr[i];
        for (const currency of CURRENCIES) {
          if (
            text.toString() === currency.code &&
            arr[i - 1].toString() === 'Waluta'
          ) {
            currencyRes = currency.code;
            over = true;
            break;
          }
        }
        if (over) break;
      }
      if (over) break;
    }
    this.currencyCode = currencyRes;
    return super.doBeforeTranform();
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const { date, sum, comment } = operation;
    const errors = this.getErrors();
    const correctDate = this.dateParser(date);
    if (correctDate.toString() === 'Invalid Date')
      this.throwError(`${date} - ${errors.dateNotValid}`, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(correctDate, this.DATE_FORMAT).isAfter(
        moment().add(10, 'y'),
      );
      isBefore = moment(correctDate, this.DATE_FORMAT).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const currencySymbol = this.currencyCode;
    const parsedSum = this.parseSum(sum);
    if (isNaN(parsedSum))
      this.throwError(`${sum} - ${errors.sumNotValid}`, index);
    const now = moment();
    const dateResult = moment(correctDate)
      .startOf('day')
      .add(now.seconds(), 'second')
      .add(now.minutes(), 'minute')
      .add(now.hours(), 'hour')
      .format('YYYY-MM-DD HH:mm:ss');

    const cur = CURRENCIES.find((cur) => cur.code === currencySymbol)?.code;

    const result: any = {
      index,
      date: dateResult,
      comment: comment?.join(' '),
      sum: parsedSum,
    };
    if (cur) result.currency = cur;

    if (sum.toString().includes('-')) {
      result.type = OperationType.CONSUMPTION;
      result.subType = 'supplier';
      result.accountFromId = this.mainAccount.normalizedLabel;
    } else {
      result.type = OperationType.INCOME;
      result.subType = 'sale';
      result.accountToId = this.mainAccount.normalizedLabel;
    }

    this.setAccountIDsByType(result, cur, cur);
    return result;
  }
}
