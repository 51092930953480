import { makeStyles } from '@material-ui/core/styles';

import { persianGreen } from '../../constants/theme-colors';

export const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: 9,
    [theme.breakpoints.down(768)]: {
      marginRight: 0,
    }
  },
  relative: {
    position: 'relative',
    minHeight: 20,
  },
  activeFilter: {
    paddingRight: 16,
  },
  link: {
    color: persianGreen,
    opacity: 1,
  },
  separator: {
    height: 20,
    marginLeft: 8,
    marginRight: 4,
    opacity: 0.1,
    border: '1px solid #181D1F',
  },
}));
