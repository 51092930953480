import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  lastBlock: {
    marginBottom: 18,
  },
  dialog: {
    borderRadius: 24,
  },
});
