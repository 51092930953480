import { getBalance } from './getBalance';

export const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export function ucFirst(str: string) {
  if (!str) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
}

/* eslint-disable */
export function convertExpToString(n: number) {
  const sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  const [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign +
        '0.' +
        '0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
}

export const formatDigitsToLowCase = (
  val: number,
  symbol: string,
  title = false,
  isCrypto?: boolean,
) => {
  if (isCrypto) {
    return <span>{`${symbol} ${convertExpToString(val)}`}</span>;
  }

  let first = '0';
  let fraction = '0';

  const fractionTitleStyles = {
    fontSize: 16,
  };

  const fractionStyles = {
    fontSize: 10,
  };

  const balance = getBalance(val);

  if (balance) {
    [first, fraction] = balance.split('.');
  }

  return (
    <>
      {`${symbol} ${first}`}
      {!!fraction && (
        <span style={title ? fractionTitleStyles : fractionStyles}>
          {`.${fraction}`}
        </span>
      )}
    </>
  );
};
