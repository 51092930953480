import OperationHOC from './OperationHOC';
import { Props } from './types';
import React from 'react';

function OperationsHOC(Component: React.ComponentType<Props>) {
  // @ts-ignore
  return function(props: any) {
  return <OperationHOC WrapperComponent={Component} {...props} />
};
}

export default OperationsHOC;
