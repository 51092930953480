import {
    AVAILABLE_IMPORT_TYPES, BaseImportParserV3, Config, ImportResultItemMask
} from '@finmap/import-parsers/base-import-parser-v3';

export class RBKKzBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          const currency = (this.findString(/^Валюта: /)?.data as string)
            ?.replace(/^Валюта: /, '')
            ?.trim();

          const firstHeaderLine = this.findString(
            /^Отправитель \(Наименование, БИК, ИИК, БИН\/ИИН\)/,
          )?.raw;

          this.setDocumentHeader(importDocument[firstHeaderLine]);
          this.setDocumentBody(importDocument.slice(firstHeaderLine + 1));

          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 1]),
            counterpartyIncome: this.getFirstValidCellByColumn([
              'Отправитель (Наименование, БИК, ИИК, БИН/ИИН)',
              3,
            ])?.replace(/БИН(.){0,}/s, ''),
            counterpartyConsumption: this.getFirstValidCellByColumn([
              'Получатель (Наименование, БИК, ИИК, БИН/ИИН)',
              4,
            ])?.replace(/БИН(.){0,}/s, ''),
            credit: this.getFirstValidCellByColumn(['Кредит', 6]),
            debit: this.getFirstValidCellByColumn(['Дебет', 5]),
            comment: this.getFirstValidCellByColumn(['Назначение платежа', 7]),
            currency,
          });
        },
        caseOptions: { defaultCurrency: 'KZT', isDESCOpOrder: true },
      },
    ],
  };
}
