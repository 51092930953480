import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  button: {
    paddingTop: 18,
    paddingBottom: 18,
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    marginRight: 10,
    paddingLeft: 14,
    paddingRight: 4,
  },
});
