import './styles.scss';
import { TextField, Typography } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteProps } from '../../store/types';
import { persianGreen } from '../../theme/colors';
import TransitionComponent from '../TransitionComponent';
import { StyledAutocomplete, useAutocompleteStyles, useStyles } from './styles';
import { Props } from './types';

function AutocompleteSelector(props: Props) {
  const {
    id,
    data,
    label,
    value,
    error,
    lastRow,
    loading,
    onChange,
    disabled,
    popperWidth,
    lastRowAction,
    rootClassName,
    clearOnEscape = true,
    disableClearable = false,
    optionSelectByKey = 'id',
    subscriptionActive = true,
  } = props;
  const { t } = useTranslation();
  const filter = createFilterOptions();

  const filterOptions = useCallback(
    (
      options: AutoCompleteProps[],
      params: FilterOptionsState<AutoCompleteProps>,
    ): AutoCompleteProps[] => {
      // @ts-ignore
      const filtered: AutoCompleteProps[] = filter(options, params);

      if (lastRow) {
        const additionFilterRow = {
          id: lastRow,
          name: t(lastRow),
          label: t(lastRow),
        };

        return [...filtered, additionFilterRow];
      }

      return filtered;
    },
    [filter, lastRow, t],
  );

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const classes = useStyles({
    isError: error,
  });

  const autocompleteStyles = useAutocompleteStyles({
    // @ts-ignore
    width: popperWidth || anchorEl?.current?.clientWidth,
  });

  const handleChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: any) => {
      if (!newValue) {
        onChange(null);

        return;
      }

      if (newValue.id === lastRow && lastRowAction) {
        lastRowAction();
      } else {
        onChange(newValue);
      }
    },
    [onChange, lastRow, lastRowAction],
  );

  const renderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField
        {...params}
        label={value?.id && label}
        placeholder={!value?.id ? label : ''}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
          },
        }}
        InputProps={{
          ...params.InputProps,
          classes: {
            root: classes.inputRoot,
          },
        }}
        classes={{
          root: classes.formControl,
        }}
      />
    ),
    [value, classes, label],
  );

  const getOptionLabel = useCallback(
    (option: AutoCompleteProps) => option.label || option.name || '',
    [],
  );

  const renderOption = useCallback(
    (
      option: AutoCompleteProps,
      { selected }: AutocompleteRenderOptionState,
    ) => {
      if (option.id === lastRow) {
        return (
          <Typography
            className={cn(classes.persianGreen, classes.shortText)}
            title={getOptionLabel(option)}
          >
            {!!option.icon && (
              <img
                src={option.icon}
                alt="icon"
                width={20}
                height={20}
                className={classes.optionIcon}
              />
            )}
            &nbsp;&nbsp;
            {getOptionLabel(option)}
          </Typography>
        );
      }

      if (selected) {
        return (
          <div className={classes.selectedOptionContainer}>
            <Typography
              className={cn(classes.selectedOptionText, classes.shortText)}
              title={getOptionLabel(option)}
            >
              {!!option.icon && (
                <img
                  src={option.icon}
                  alt="icon"
                  width={20}
                  height={20}
                  className={classes.optionIcon}
                />
              )}
              {getOptionLabel(option)}
            </Typography>
            {/* @ts-ignore */}
            <CheckIcon color={persianGreen} />
          </div>
        );
      }

      return (
        <Typography
          className={classes.shortText}
          title={getOptionLabel(option)}
        >
          {!!option.icon && (
            <img
              src={option.icon}
              alt="icon"
              width={20}
              height={20}
              className={classes.optionIcon}
            />
          )}
          {getOptionLabel(option)}
        </Typography>
      );
    },
    [classes, getOptionLabel, lastRow],
  );

  const getOptionSelected = useCallback(
    (option: AutoCompleteProps, optionValue: AutoCompleteProps) =>
      optionValue &&
      // @ts-ignore
      option[optionSelectByKey] === optionValue[optionSelectByKey],
    [optionSelectByKey],
  );

  const renderLoading = useCallback(
    () => (
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    ),
    [],
  );

  return (
    <div
      className={cn(
        classes.root,
        rootClassName,
        error && classes.error,
        !subscriptionActive && classes.disabledInput,
        'root-autocomplete',
      )}
      ref={anchorEl}
    >
      <div className={classes.container}>
        {/* @ts-ignore */}
        <StyledAutocomplete
          id={id}
          disabled={disabled || !subscriptionActive}
          value={value || null}
          defaultValue={value}
          fullWidth
          blurOnSelect
          multiple={false}
          selectOnFocus
          disableClearable={disableClearable}
          clearOnEscape={clearOnEscape}
          // @ts-ignore
          classes={autocompleteStyles}
          options={data || []}
          // @ts-ignore
          getOptionLabel={getOptionLabel}
          renderInput={loading ? renderLoading : renderInput}
          onChange={handleChange}
          renderOption={renderOption}
          // @ts-ignore
          getOptionSelected={getOptionSelected}
          // @ts-ignore
          groupBy={(option) => option.isCrypto}
          // @ts-ignore
          filterOptions={filterOptions}
        />
      </div>
      <TransitionComponent text={t('system.fieldMustFilled')} enter={!!error} />
    </div>
  );
}

export default React.memo(AutocompleteSelector);
