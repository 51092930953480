import React from 'react';
import { Typography } from '@material-ui/core';
import cn from "classnames";

import { useStyles } from './styles';

export interface FilterResetButtonProps {
  text: string;
  onClick?(event: any): void;
  isActive?: boolean;
}

export function FilterResetButton({
  text,
  onClick,
  isActive = true,
}: FilterResetButtonProps) {
  if(!isActive) return null;

  const classes = useStyles();

  return (
    <div className={cn(classes.filtersRoot, classes.additionalPaddings)}>
      <Typography className={classes.clearText} onClick={onClick}>
        {text}
      </Typography>
    </div>
  );
}

export default FilterResetButton;