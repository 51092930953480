import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TrashIcon from '../../../../../../assets/images/svg/trash-red-20-20.svg';
import CustomButton from '../../../../../../components/Button';
import ButtonRowWithIcons from '../../../../../../components/ButtonRowWithIcons';
import Dialog from '../../../../../../components/Dialog/Dialog';
import RemoveDialog from '../../../../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import currencyActions from '../../../../../../store/currency/actions';
import {
  getCurrencies,
  selectCryptoCurrencyCodes,
} from '../../../../../../store/currency/selectors';
import { outrageousOrange } from '../../../../../../theme/colors';
import { formatStringPrice } from '../../../../../../utils/parseStringToNumber';
import { useStyles } from './styles';
import { Props } from './types';

function EditDialog(props: Props) {
  const { item, onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currencies = useSelector(getCurrencies);

  const [exchangeRate, setExchangeRate] = useState<string | number>(item.rate);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);

  const cryptoCurrencyIds = useSelector(selectCryptoCurrencyCodes);
  const isCrypto = cryptoCurrencyIds.find(
    (el) => el === item.from || el === item.to,
  );

  const exchangeRageDigits = isCrypto ? 9 : 6;

  const exchangeRateTitle = useMemo(() => {
    const from = currencies.find((el) => el._id === item.from);
    const to = currencies.find((el) => el._id === item.to);

    if (from && to) {
      return `${from.symbol} / ${to.symbol}`;
    }

    return '';
  }, [currencies, item]);

  const fromSymbol = useMemo(() => {
    const from = currencies.find((el) => el._id === item.from);

    return from?.symbol || '';
  }, [currencies, item]);

  const toSymbol = useMemo(() => {
    const to = currencies.find((el) => el._id === item.to);

    return to?.symbol || '';
  }, [currencies, item]);

  const title = `${t('userMenu.fixedCurrencies.yourCourse')} ${
    item.from
  } ${fromSymbol} - ${item.to} ${toSymbol}`;

  const handleRemove = useCallback(() => {
    dispatch(
      currencyActions.removeCustomCurrencyRates({
        from: item.from,
        to: item.to,
      }),
    );

    onClose();
  }, [dispatch, item, onClose]);

  const handleUpdate = useCallback(() => {
    dispatch(
      currencyActions.createCustomCurrencyRates({
        from: item.from,
        to: item.to,
        rate: Number(exchangeRate),
      }),
    );

    onClose();
  }, [dispatch, item, exchangeRate, onClose]);

  const handleOpenRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleChangeExchangeRate = useCallback(
    (data: any) => {
      const rate = formatStringPrice(data, false, exchangeRageDigits);

      setExchangeRate(rate);
    },
    [exchangeRageDigits],
  );

  return (
    <>
      <Dialog isOpened title={title} onClose={onClose}>
        <TextFieldComponent
          value={exchangeRate}
          placeholder={`${t('common.rate')} ${exchangeRateTitle}`}
          onChange={handleChangeExchangeRate}
        />
        <ButtonRowWithIcons
          wrapClasses={classes.removeRow}
          textColor={outrageousOrange}
          alt="trash"
          onClick={handleOpenRemoveDialog}
          icon={TrashIcon}
          label={t('common.remove')}
          showUnderLine={false}
        />
        <CustomButton
          title={t('common.save')}
          fullWidth
          action={handleUpdate}
        />
      </Dialog>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveCustomCourse"
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemove}
          name={t('common.rate').toLowerCase()}
        />
      )}
    </>
  );
}

export default React.memo(EditDialog);
