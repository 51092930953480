import { createSelector } from 'reselect';

import { AppState } from '../reducers';
import {
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
} from '@finmap/core-constants';

export const getOnboardingSteps = (state: AppState) =>
  state.onboardingV2.onboardingSteps;

export const getShowVideo = (state: AppState) => state.onboardingV2.showVideo;

export const getOnboardingV2InProgress = (state: AppState) =>
  state.onboardingV2.onboardingV2InProgress;

export const selectActiveOnboardingStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.status === ONBOARDING_STATUS.ACTIVE),
);

export const selectSkipOnboardingStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.status === ONBOARDING_STATUS.SKIP),
);

export const selectPendingOnboardingStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.status === ONBOARDING_STATUS.PENDING),
);

export const selectGreetingOnboardingStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.stepName === ONBOARDING_STEPS.GREETINGS),
);

export const selectStartServiceFromStep = createSelector(
  getOnboardingSteps,
  (steps) =>
    steps.find((el) => el.stepName === ONBOARDING_STEPS.START_SERVICE_FROM),
);

export const selectSetupCabinetStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.stepName === ONBOARDING_STEPS.SETUP_CABINET),
);

export const selectAnalyticStep = createSelector(getOnboardingSteps, (steps) =>
  steps.find((el) => el.stepName === ONBOARDING_STEPS.ANALYTIC),
);

export const selectFinalStep = createSelector(getOnboardingSteps, (steps) =>
  steps.find((el) => el.stepName === ONBOARDING_STEPS.FINAL),
);

export const selectIntegrationStep = createSelector(
  getOnboardingSteps,
  (steps) => steps.find((el) => el.stepName === ONBOARDING_STEPS.INTEGRATION),
);

export const selectPreLastStep = createSelector(getOnboardingSteps, (steps) =>
  steps.find((el) => el.stepName === ONBOARDING_STEPS.PRE_LAST),
);

export const selectSetupCabinetStepStatus = createSelector(
  selectSetupCabinetStep,
  (step) => step?.status ?? null,
);

export const selectSetupCabinetSubCategory = createSelector(
  selectSetupCabinetStep,
  (step) => step?.stepSubCategory ?? null,
);

export const onboardingV2IntegrationActive = createSelector(
  selectSetupCabinetStepStatus,
  selectSetupCabinetSubCategory,
  (status, step) =>
    status === ONBOARDING_STATUS.PASSED &&
    step === ONBOARDING_SUB_STEPS.INTEGRATION,
);

export const selectCreateOperationActiveStep = createSelector(
  selectActiveOnboardingStep,
  (activeStep) =>
    activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
    activeStep?.stepSubCategory ===
      ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_OPERATION,
);

export const selectCreateImportActiveStep = createSelector(
  selectActiveOnboardingStep,
  (activeStep) =>
    activeStep?.stepName === ONBOARDING_STEPS.INTEGRATION &&
    activeStep?.stepSubCategory ===
      ONBOARDING_SUB_STEPS.INTEGRATION_CREATE_IMPORT,
);
