import React from 'react';
import { ClickAwayListener, Grow, Paper, Popper as PopperMaterial } from '@material-ui/core';
import cn from 'classnames';
import { PopperPlacementType } from '@material-ui/core/Popper/Popper';

import { useStyles } from './styles';

export interface PopperProps {
  anchorRef: React.RefObject<any> | null;
  children: JSX.Element;
  placement?: PopperPlacementType;
  width?: string | number;
  paperClasses?: string;
  disablePortal?: boolean;
  id?: string;
  disabled?: boolean;

  onClose(): void;
};

export function PopperComponent(props: PopperProps) {
  const {
    id,
    width,
    onClose,
    children,
    anchorRef,
    paperClasses,
    disablePortal = true,
    placement: injectPlacement,
  } = props;

  const classes = useStyles({
    width,
  });

  return (
    <PopperMaterial
      id={id}
      open
      anchorEl={anchorRef?.current}
      role={undefined}
      transition
      disablePortal={disablePortal}
      placement={injectPlacement}
      className={classes.popper}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper className={cn(classes.ownDropdownContainer, paperClasses)}>
            <ClickAwayListener onClickAway={onClose}>
              <div>{children}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </PopperMaterial>
  );
}

export const Pooper = React.memo(PopperComponent);

export default Pooper;
