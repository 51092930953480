import { AxiosRequestConfig } from 'axios';

import { SetPeriodPayload } from '../analytic/history.actions.types';
import { Client } from '../clients/types';
import { SetCustomFilterPayload } from './actions.types';
import api from './api';
import {
  CreateInvoicePayload,
  CreateInvoicingClientPayload,
  CreateMyInvoicingPropsPayload,
  GetInvoicesLogPayload,
  InvoicingProps,
  UpdateGroupApprovePayload,
  UpdateInvoiceByIdPayload,
} from './sagas.types';
import { InvoiceClient, InvoiceGoodProps } from './types';

export const ActionTypes = {
  CREATE_INVOICE_PENDING: 'CREATE_INVOICE_PENDING',
  CREATE_INVOICE_SUCCESS: 'CREATE_INVOICE_SUCCESS',
  UPDATE_INVOICE_PENDING: 'UPDATE_INVOICE_PENDING',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  GET_INVOICES_PENDING: 'GET_INVOICES_PENDING',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  GET_INVOICES_FAILED: 'GET_INVOICES_FAILED',
  GET_MORE_INVOICES_PENDING: 'GET_MORE_INVOICES_PENDING',
  GET_MORE_INVOICES_SUCCESS: 'GET_MORE_INVOICES_SUCCESS',
  APPROVE_INVOICE_PENDING: 'APPROVE_INVOICE_PENDING',
  RESET_INVOICE_CUSTOM_FILTERS: 'RESET_INVOICE_CUSTOM_FILTERS',
  SET_CUSTOM_INVOICING_FILTER: 'SET_CUSTOM_INVOICING_FILTER',
  SET_INVOICING_FILTER_PERIOD: 'SET_INVOICING_FILTER_PERIOD',
  UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
  GET_MY_INVOICING_PROPS_PENDING: 'GET_MY_INVOICING_PROPS_PENDING',
  GET_MY_INVOICING_PROPS_SUCCESS: 'GET_MY_INVOICING_PROPS_SUCCESS',
  GET_INVOICING_CLIENTS_PENDING: 'GET_INVOICING_CLIENTS_PENDING',
  GET_INVOICING_CLIENTS_SUCCESS: 'GET_INVOICING_CLIENTS_SUCCESS',
  CREATE_INVOICING_CLIENT_PENDING: 'CREATE_INVOICING_CLIENT_PENDING',
  CREATE_INVOICING_CLIENT_SUCCESS: 'CREATE_INVOICING_CLIENT_SUCCESS',
  CREATE_INVOICING_PROPS: 'CREATE_INVOICING_PROPS',
  CREATE_INVOICING_MY_PROPS_SUCCESS: 'CREATE_INVOICING_PROPS_SUCCESS',
  CREATE_INVOICING_COUNTERPARTY_PROPS_SUCCESS:
    'CREATE_INVOICING_COUNTERPARTY_PROPS_SUCCESS',
  CREATE_GOOD_PENDING: 'CREATE_GOOD_PENDING',
  CREATE_GOOD_SUCCESS: 'CREATE_GOOD_SUCCESS',
  GET_INVOICING_GOODS_PENDING: 'GET_INVOICING_GOODS_PENDING',
  GET_INVOICING_GOODS_SUCCESS: 'GET_INVOICING_GOODS_SUCCESS',
  SET_APPROVED: 'SET_APPROVED',
  REMOVE_INVOICE_PENDING: 'REMOVE_INVOICE_PENDING',
  UPDATE_INVOICE_GOODS_GROUP_PENDING: 'UPDATE_INVOICE_GOODS_GROUP_PENDING',
  UPDATE_INVOICE_GOODS_GROUP_SUCCESS: 'UPDATE_INVOICE_GOODS_GROUP_SUCCESS',
  UPDATE_INVOICE_MY_GROUP_PENDING: 'UPDATE_INVOICE_MY_GROUP_PENDING',
  UPDATE_INVOICE_MY_GROUP_SUCCESS: 'UPDATE_INVOICE_MY_GROUP_SUCCESS',
  REMOVE_GOOD_PENDING: 'REMOVE_GOOD_PENDING',
  REMOVE_GOOD_SUCCESS: 'REMOVE_GOOD_SUCCESS',
  REMOVE_MY_PROPS_PENDING: 'REMOVE_MY_PROPS_PENDING',
  REMOVE_MY_PROPS_SUCCESS: 'REMOVE_MY_PROPS_SUCCESS',
  SET_SORT_PARAMS: 'SET_SORT_PARAMS',
  REMOVE_SELECTED_INVOICES: 'REMOVE_SELECTED_INVOICES',
  APPROVE_SELECTED_INVOICES: 'APPROVE_SELECTED_INVOICES',
  GET_COMPANY_INVOICING_DATA_PENDING: 'GET_COMPANY_INVOICING_DATA_PENDING',
  GET_COMPANY_INVOICING_DATA_SUCCESS: 'GET_COMPANY_INVOICING_DATA_SUCCESS',
  SET_LOADING_COMPANY_INVOICING_DATA: 'SET_LOADING_COMPANY_INVOICING_DATA',
  UPDATE_INVOICE_CLIENTS: 'UPDATE_INVOICE_CLIENTS',
  REMOVE_INVOICE_CLIENT_PENDING: 'REMOVE_INVOICE_CLIENT_PENDING',
  REMOVE_INVOICE_CLIENT_SUCCESS: 'REMOVE_INVOICE_CLIENT_SUCCESS',
};

const getCompanyInvoicingData = () => ({
  type: ActionTypes.GET_COMPANY_INVOICING_DATA_PENDING,
});

const createMyInvoicingProps = (data: CreateMyInvoicingPropsPayload) => ({
  type: ActionTypes.CREATE_INVOICING_PROPS,
  payload: data,
});

const createInvoicingClient = (data: CreateInvoicingClientPayload) => ({
  type: ActionTypes.CREATE_INVOICING_CLIENT_PENDING,
  payload: data,
});

const createGood = (label: string, price?: number) => ({
  type: ActionTypes.CREATE_GOOD_PENDING,
  payload: { label, price },
});

const getInvoices = (data?: GetInvoicesLogPayload) => ({
  type: ActionTypes.GET_INVOICES_PENDING,
  payload: data ?? {},
});

const getInvoicesGoods = () => ({
  type: ActionTypes.GET_INVOICING_GOODS_PENDING,
});

const getInvoicingProps = () => ({
  type: ActionTypes.GET_MY_INVOICING_PROPS_PENDING,
});

const getInvoicingClients = () => ({
  type: ActionTypes.GET_INVOICING_CLIENTS_PENDING,
});

const resetCustomFilters = () => ({
  type: ActionTypes.RESET_INVOICE_CUSTOM_FILTERS,
});

const setCustomFilter = (payload: SetCustomFilterPayload) => ({
  type: ActionTypes.SET_CUSTOM_INVOICING_FILTER,
  payload: {
    data: payload.data,
    filterType: payload.filterType,
  },
});

const setPeriod = (data: SetPeriodPayload) => ({
  type: ActionTypes.SET_INVOICING_FILTER_PERIOD,
  payload: {
    startDate: data.startDate,
    endDate: data.endDate,
    id: data.id,
  },
});

const uploadAttachment = (formData: FormData, config: AxiosRequestConfig) => ({
  type: ActionTypes.UPLOAD_ATTACHMENT,
  payload: api.uploadAttachment(formData, config),
});

const createInvoice = (data: CreateInvoicePayload) => ({
  type: ActionTypes.CREATE_INVOICE_PENDING,
  payload: data,
});

const updateInvoiceById = (data: UpdateInvoiceByIdPayload) => ({
  type: ActionTypes.UPDATE_INVOICE_PENDING,
  payload: data,
});

const setApproved = (data: UpdateGroupApprovePayload) => ({
  type: ActionTypes.SET_APPROVED,
  payload: data,
});

const removeInvoice = (id: string) => ({
  type: ActionTypes.REMOVE_INVOICE_PENDING,
  payload: { id },
});

const setSortParams = (orders: any) => ({
  type: ActionTypes.SET_SORT_PARAMS,
  payload: { orders },
});

const removeSelected = (ids: string[]) => ({
  type: ActionTypes.REMOVE_SELECTED_INVOICES,
  payload: { ids },
});

const updateInvoiceGoodsGroup = (goods: InvoiceGoodProps[]) => ({
  type: ActionTypes.UPDATE_INVOICE_GOODS_GROUP_PENDING,
  payload: { goods },
});

const updateInvoiceMyGroup = (myProps: (Client & InvoicingProps)[]) => ({
  type: ActionTypes.UPDATE_INVOICE_MY_GROUP_PENDING,
  payload: { myProps },
});

const removeGood = (id: string) => ({
  type: ActionTypes.REMOVE_GOOD_PENDING,
  payload: { id },
});

const removeMyProps = (id: string) => ({
  type: ActionTypes.REMOVE_MY_PROPS_PENDING,
  payload: { id },
});

const updateClients = (clients: InvoiceClient[]) => ({
  type: ActionTypes.UPDATE_INVOICE_CLIENTS,
  payload: { clients },
});

const removeClient = (id: string) => ({
  type: ActionTypes.REMOVE_INVOICE_CLIENT_PENDING,
  payload: { id },
});

export default {
  setPeriod,
  createGood,
  removeGood,
  setApproved,
  getInvoices,
  removeClient,
  updateClients,
  removeMyProps,
  removeInvoice,
  setSortParams,
  createInvoice,
  removeSelected,
  setCustomFilter,
  getInvoicesGoods,
  uploadAttachment,
  updateInvoiceById,
  getInvoicingProps,
  resetCustomFilters,
  getInvoicingClients,
  updateInvoiceMyGroup,
  createInvoicingClient,
  createMyInvoicingProps,
  getCompanyInvoicingData,
  updateInvoiceGoodsGroup,
};
