import pumbApi from '../../services/bankIntegrations/pumb';
import { SetPumbAccountPayload } from '../../services/bankIntegrations/pumb.types';

const createCredentials = async (data: SetPumbAccountPayload) => {
  const { data: credentials } = await pumbApi.setAccount(data);

  return credentials;
};

export default {
  createCredentials,
};
