import { CreateCompanyPayload } from '../store/company/sagas.types';
import Http from './Http';

const getCompanies = () => Http.makeInstance().get('/companies');

const getCompaniesSettings = () =>
  Http.makeInstance().get('/advancedSettings/companies');

const createCompany = (data: CreateCompanyPayload) =>
  Http.makeInstance().post('/companies', data);

const updateCompany = (id: string, name: string, currencyId: string) =>
  Http.makeInstance().patch(`/companies/${id}`, {
    name,
    currencyId,
  });

const deleteCompany = (id: string) =>
  Http.makeInstance().delete(`/companies/${id}`);

const updatePropCompany = (id: string, data: any) =>
  Http.makeInstance().patch(`/companies/${id}?isBulkUpdate=true`, {
    ...data,
  });

export default {
  getCompanies,
  createCompany,
  updateCompany,
  deleteCompany,
  updatePropCompany,
  getCompaniesSettings,
};
