import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: '9px 16px',
  },
  textRoot: {
    flex: 1,
    maxHeight: 56,
  },
  textRootComment: {
    minHeight: 112,
    maxHeight: 'none',
    alignItems: 'flex-start',
  },
  filterComment: {
    padding: '10px 20px 10px 45px',
  },
  inputWithError: {
    marginBottom: 10,
  },
  icon: {
    top: '30%',
    left: '2%',
    position: 'absolute',
  },
  inputRoot: {
    paddingRight: 0,
  },
  disabledInput: {
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
});
