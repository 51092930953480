import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import fondyApis from '../../../services/bankIntegrations/fondy';
import Storages, { StorageKey } from '../../../services/Storages';
import { minOperationDate } from '../../../utils/dateFormat';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { Props } from './FondyAuth2FirstDialog.types';

function FondyAuth2FirstDialog(props: Props) {
  const { onClose, fondyAccountId, newAccountName } = props;

  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<number>(
    moment.utc().startOf('month').subtract(1, 'month').valueOf(),
  );

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleCreate = useCallback(async () => {
    Storages.put(StorageKey.fondy, {
      startDate: selectedDate,
      fondyAccountId,
      newAccountName,
    });

    const redirectUrl = `${window.location.origin}/fondyCode`;
    const { data } = await fondyApis.getCredentials(redirectUrl);

    window.location.href = data.url;
  }, [fondyAccountId, selectedDate, newAccountName]);

  return (
    <Dialog
      onClose={onClose}
      isOpened
      title={t('integrations.fondyAuth2.title')}
    >
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        value={selectedDate}
        fullWidth
        maxDate={new Date()}
        minDate={minOperationDate()}
        fromIntegration
      />
      <CustomButton
        title={t('integrations.fondyAuth2.connect')}
        action={handleCreate}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(FondyAuth2FirstDialog);
