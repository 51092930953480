import { Period } from './OthersDialog.types';
import i18next from 'i18next';

export const periods: Period[] = [
  {
    id: 1,
    name: i18next.t('customPeriod.day'),
  },
  {
    id: 2,
    name: i18next.t('customPeriod.week'),
  },
  {
    id: 3,
    name: i18next.t('customPeriod.month'),
  },
  {
    id: 4,
    name: i18next.t('customPeriod.year'),
  },
];

export const smallDaysArray = [
  { id: '1', value: i18next.t('shortWeek.mo') },
  { id: '2', value: i18next.t('shortWeek.tu') },
  { id: '3', value: i18next.t('shortWeek.wd') },
  { id: '4', value: i18next.t('shortWeek.th') },
  { id: '5', value: i18next.t('shortWeek.fr') },
  { id: '6', value: i18next.t('shortWeek.sa') },
  { id: '7', value: i18next.t('shortWeek.su') },
];
