import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import checkBoxApi from '../../../../services/bankIntegrations/checkBox';
import { showError } from '../../../../utils/showError';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose(): void;
  onSetCredentials(integrationId: string): void;
};

function LoginDialog(props: Props) {
  const { onClose, onSetCredentials } = props;

  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');

  const [licenseKey, setLicenseKey] = useState('');
  const [licenseKeyError, setLicenseKeyError] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleChangeLogin = useCallback((value) => {
    setLogin(value);
    setLoginError(false);
  }, []);

  const handleChangeLicenseKey = useCallback((value) => {
    setLicenseKey(value);
    setLicenseKeyError(false);
  }, []);

  const handleChangePassword = useCallback((value) => {
    setPassword(value);
    setPasswordError(false);
  }, []);

  const handleClick = useCallback(async () => {
    setLoginErrorText('');

    if (!login) {
      setLoginError(true);
      return;
    }

    if (!password) {
      setPasswordError(true);
      return;
    }

    if (!licenseKey) {
      setLicenseKeyError(true);
      return;
    }

    setLoading(true);

    try {
      const {
        data: { integrationId },
      } = await checkBoxApi.getCredentials(login, password, licenseKey);

      onSetCredentials(integrationId);
    } catch (error) {
      setLoginErrorText(t('bank.validationError'));
      showError(error);
    }

    setLoading(false);
  }, [t, login, password, licenseKey, onSetCredentials]);

  return (
    <Dialog isOpened onClose={onClose} title={t('bank.authorizationCheckBox')}>
      <TextFieldComponent
        value={login}
        onChange={handleChangeLogin}
        fullWidth
        placeholder={t('bank.loginCheckBox')}
        isError={loginError}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        value={password}
        onChange={handleChangePassword}
        fullWidth
        type="password"
        placeholder={t('bank.passwordCheckBox')}
        isError={passwordError}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        value={licenseKey}
        onChange={handleChangeLicenseKey}
        fullWidth
        placeholder={t('bank.licenseKeyCheckBox')}
        isError={licenseKeyError}
        errorText={t('errors.reqField')}
      />
      <CustomButton
        action={handleClick}
        fullWidth
        title={t('bank.makeAuthorization')}
        loading={loading}
        errorText={loginErrorText}
      />
    </Dialog>
  );
}

export default React.memo(LoginDialog);
