import i18n from 'i18next';
// @ts-ignore
import ReactPostprocessor from 'i18next-react-postprocessor';
import sprintf from 'i18next-sprintf-postprocessor';
import { initReactI18next } from 'react-i18next';

import { TranslationService } from '@finmap/core-translations';

import { DEFAULT_LANGUAGE } from './constants';
import Storages, { StorageKey } from './services/Storages';

let language;

try {
  language = Storages.get(StorageKey.language);
} catch (e) {
  language = DEFAULT_LANGUAGE;
}

const translationsService = new TranslationService();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(sprintf)
  .use(new ReactPostprocessor())
  .init({
    postProcess: ['reactPostprocessor'],
    overloadTranslationOptionHandler: sprintf.overloadTranslationOptionHandler,
    resources: translationsService.getResources(),
    lng: language,
    fallbackLng: {
      en: ['en'],
      default: ['en'],
    },
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    returnEmptyString: false,
  });

export default i18n;
