import { makeStyles } from '@material-ui/core/styles';
import { flamingo } from '../../constants/theme-colors';

export const duration = 300;

export const useStyles = makeStyles({
  defaultColor: {
    color: flamingo,
  },
  defaultStyles: {
    position: 'absolute',
    fontSize: 12,
    lineHeight: '16px',
    marginTop: 2,
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    bottom: -16,
    left: 16,
  },
});
