import { makeStyles } from '@material-ui/core';

import { persianGreen } from '../../constants/theme-colors';
import { StyleProps } from '../../types/style-props';


export const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    maxWidth: 'none',
    borderRadius: 24,
    overflowX: 'hidden',
    background: '#fff',
    textAlign: 'left',
    boxSizing: 'border-box',
    position: 'relative',
    '-webkit-appearance': 'none',
    [theme.breakpoints.up(768)]: {
      width: 480,
      padding: '8px',
    },
    [theme.breakpoints.down(768)]: {
      width: 343,
      padding: '8px 0 0 0',
      margin: 16,
    },
    [theme.breakpoints.down(375)]: {
      width: 312,
      padding: '8px 0 0 0',
      margin: 0,
    },
  },
  container: {
    height: (props: StyleProps) => props.height,
    marginTop: 56,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  item: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  closeIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 20,
    height: 25,
    cursor: 'pointer',
    [theme.breakpoints.down(768)]: {
      top: 16,
      right: 16,
      width: 16,
      height: 16,
    },
  },
  hide: {
    opacity: '0 !important',
  },
  show: {
    opacity: 1,
  },
  relative: {
    position: 'relative',
  },
}));

export const useRootStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
}));

export const usescrollClasses = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      background: 'rgba(24, 29, 31, 0.15)',
      borderRadius: 8,
    },
  },
}));
