import Http from './Http';

const getTelegramApiKeys = () =>
  Http.makeInstance().get('/integration/apiKeys');

const generateTelegramApiKey = () =>
  Http.makeInstance().post('/integration/apiKeys', {
    name: 'telegram',
  });

const deleteTelegramApiKey = (id: string) =>
  Http.makeInstance().delete(`/integration/apiKeys/${id}`);

export default {
  getTelegramApiKeys,
  deleteTelegramApiKey,
  generateTelegramApiKey,
};
