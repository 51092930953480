import { applyMiddleware, compose, createStore } from 'redux';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../sagas';
import reducers from '../store/reducers';

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(promise, sagaMiddleware)),
);

export default store;

sagaMiddleware.run(rootSaga);
