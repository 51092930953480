import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import { flamingo } from '../../../theme/colors';
import { useStyles } from './PayoneerErrorDialog.styles';

type Props = {
  onClose(): void;
};

function PayoneerErrorDialog(props: Props) {
  const { onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog isOpened onClose={onClose} title={t('common.error')}>
      <Typography className={cn(classes.lastMargin, classes.placeholder)}>
        {t('integrations.payoneer.error')}
      </Typography>

      <CustomButton
        textColor={flamingo}
        className={classes.button}
        title={t('bank.repeatAuth')}
        action={onClose}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(PayoneerErrorDialog);
