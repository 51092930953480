import { AnyAction } from 'redux';

import { EMPTY } from '../../constants';
import { initialContext } from '../constant';
import { ActionTypes } from '../operations/actions';
import { types } from './actions';
import { Client, ClientsState, UrlPrefix } from './types';

const initialState: ClientsState = {
  clients: {
    ...initialContext,
  },
  investors: {
    ...initialContext,
  },
  creditors: {
    ...initialContext,
  },
  borrowers: {
    ...initialContext,
  },
  staffMembers: {
    ...initialContext,
  },
  owners: {
    ...initialContext,
  },
  suppliers: {
    ...initialContext,
  },
  taxOrganisations: {
    ...initialContext,
  },
  loading: false,
};

// eslint-disable-next-line default-param-last
const clientsReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case types.RESET_CLIENTS: {
      return { ...initialState };
    }

    case ActionTypes.RESET_PROPS: {
      return { ...initialState };
    }

    case types.CREATE_CLIENT_BY_TYPE_OPERATIONS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.owners}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.owners}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.owners}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.owners}_SUCCESS`: {
      return {
        ...state,
        owners: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.taxOrganisations}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.taxOrganisations}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.taxOrganisations}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.taxOrganisations}_SUCCESS`: {
      return {
        ...state,
        taxOrganisations: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.owners}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.owners}_SUCCESS`: {
      return {
        ...state,
        owners: {
          ...state.owners,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.taxOrganisations}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.taxOrganisations}_SUCCESS`: {
      return {
        ...state,
        taxOrganisations: {
          ...state.taxOrganisations,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.staffMembers}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.staffMembers}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.staffMembers}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.staffMembers}_SUCCESS`: {
      return {
        ...state,
        staffMembers: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.staffMembers}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.staffMembers}_SUCCESS`: {
      return {
        ...state,
        staffMembers: {
          ...state.staffMembers,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.suppliers}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.suppliers}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.suppliers}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.suppliers}_SUCCESS`: {
      return {
        ...state,
        suppliers: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.suppliers}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.suppliers}_SUCCESS`: {
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.clients}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.clients}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.clients}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.clients}_SUCCESS`: {
      return {
        ...state,
        clients: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.clients}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.clients}_SUCCESS`: {
      return {
        ...state,
        clients: {
          ...state.clients,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.investors}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.investors}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.investors}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.investors}_SUCCESS`: {
      return {
        ...state,
        investors: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.investors}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.investors}_SUCCESS`: {
      return {
        ...state,
        investors: {
          ...state.investors,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.creditors}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.creditors}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.creditors}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.creditors}_SUCCESS`: {
      return {
        ...state,
        creditors: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.creditors}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.creditors}_SUCCESS`: {
      return {
        ...state,
        creditors: {
          ...state.creditors,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `CREATE_SETTINGS_${UrlPrefix.borrowers}_SUCCESS`:
    case `UPDATE_SETTINGS_${UrlPrefix.borrowers}_SUCCESS`:
    case `DELETE_SETTINGS_${UrlPrefix.borrowers}_SUCCESS`:
    case `GET_SETTINGS_${UrlPrefix.borrowers}_SUCCESS`: {
      return {
        ...state,
        borrowers: {
          ...initialContext,
          settings: payload.data,
        },
      };
    }

    case `CREATE_OPERATIONS_${UrlPrefix.borrowers}_SUCCESS`:
    case `GET_OPERATIONS_${UrlPrefix.borrowers}_SUCCESS`: {
      return {
        ...state,
        borrowers: {
          ...state.borrowers,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case `GET_LOG_${UrlPrefix.taxOrganisations}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        taxOrganisations: {
          ...state.taxOrganisations,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.clients}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        clients: {
          ...state.clients,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.investors}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        investors: {
          ...state.investors,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.creditors}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        creditors: {
          ...state.creditors,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.borrowers}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        borrowers: {
          ...state.borrowers,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.suppliers}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.staffMembers}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        staffMembers: {
          ...state.staffMembers,
          log: items,
        },
      };
    }

    case `GET_LOG_${UrlPrefix.owners}_SUCCESS`: {
      const items = (payload.data as Client[]).filter((el) => el._id !== EMPTY);

      return {
        ...state,
        owners: {
          ...state.owners,
          log: items,
        },
      };
    }

    default:
      return state;
  }
};

export default clientsReducer;
