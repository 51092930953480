import moment from 'moment/moment';
import { useSelector } from 'react-redux';

import { DAYS_AFTER_BLOCK } from '../constants';
import { getServerTime } from '../store/common/selectors';
import {
  selectIsDemoCompany,
  subscriptionDaysLeft,
} from '../store/company/selectors';
import { CompanySettings } from '../store/company/types';
import { getDiffBetweenToday } from '../utils/dateToUTC';

function useSubscriptionActive(company?: CompanySettings) {
  let trialDaysLeft;
  let isDemoCompany;
  if (company) {
    const serverTime = useSelector(getServerTime);
    const tariffEndDate = moment(company?.tariffEndDate).startOf('day');
    trialDaysLeft = getDiffBetweenToday(
      tariffEndDate,
      'days',
      'YYYY-MM-DD',
      serverTime,
    );
    isDemoCompany = company?.demo;
  } else {
    trialDaysLeft = useSelector(subscriptionDaysLeft);
    isDemoCompany = useSelector(selectIsDemoCompany);
  }
  if (isDemoCompany) {
    return true;
  }

  return trialDaysLeft > 0 || trialDaysLeft + DAYS_AFTER_BLOCK > 0;
}

export default useSubscriptionActive;
