import { makeStyles } from '@material-ui/core/styles';

import { blueRibbon, jungleGreen, persianGreen, pictonBlue3, white } from "../../../theme/colors";

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  item: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    [theme.breakpoints.down(768)]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginRight12: {
    marginRight: 12,
  },
  integrationBadge: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: 12,
    height: 20,
    marginTop: 4,
  },
  integrationBackground: {
    background: blueRibbon,
  },
  integrationApiBackground: {
    background: jungleGreen,
  },
  integrationTGBackground: {
    background: pictonBlue3,
  },
  integrationBadgeText: {
    color: white,
    fontSize: 9,
    fontWeight: 700,
    lineHeight: '10px'
  },
  icon: {
    marginRight: 5,
  }
}));
