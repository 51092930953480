import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionPeriods } from '../../../../store/types';
import { useStyles } from '../styles';
import { Props } from './RenderPeriodTitle.types';

function RenderPeriodTitle(props: Props) {
  const { period, tariffCurrencySymbol, tariffsToId, type } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.flex}>
      <div
        className={cn(
          classes.row,
          period.id !== SubscriptionPeriods.price1m && classes.priceBadge,
        )}
      >
        <Typography
          className={cn(
            classes.infoText,
            period.id !== SubscriptionPeriods.price1m && classes.priceBadgeText,
          )}
        >
          {`${tariffCurrencySymbol} ${Math.floor(
            tariffsToId[type][period.id] / period.value,
          )}`}
        </Typography>
        <Typography
          className={cn(
            classes.infoPeriodText,
            period.id !== SubscriptionPeriods.price1m && classes.priceBadgeText,
          )}
        >
          {`/${t('subscription.shortMonth')}`}
        </Typography>
      </div>
    </div>
  );
}

export default React.memo(RenderPeriodTitle);
