import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  BaseXLSXPreParser,
  Config,
  ImportResultItemMask,
  TIME_FORMAT,
} from '@finmap/import-parsers/base-import-parser-v3';
import { isNotEmpty } from 'class-validator';

export class PrivatBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(
            rawDocument.find((value) =>
              moment(value.str, 'HH:mm', true).isValid(),
            ),
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const dates = this.getFirstValidCellByColumn(['Дата\nоперації', 0]);
            const dateMatch = dates?.match(/\d{2}\.\d{2}\.\d{4}/);
            const date = dateMatch ? dateMatch[0] : undefined;
            const datesArr = dates
              ?.trim()
              ?.split('\n')
              ?.filter((v) => /\d{2}:\d{2}/.test(v));
            let time = datesArr[datesArr.length - 1];
            if (time) time = moment(time, 'HH:mm').toISOString();
            return {
              date,
              time,
              sum: this.getFirstValidCellByColumn([
                'Сума у\nвалюті\nкартки',
                4,
              ]),
              comment: this.getFirstValidCellByColumn(['Деталі операції', 2]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            verticalAlign: 'middle',
            interlineSpacingAccuracy: 7,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Сума у') ||
                  (word?.includes('Сума') &&
                    etc?.nextWord?.includes('Сума') &&
                    !word?.includes('знижок')),
                (word, etc) =>
                  etc?.prevWord?.includes('картки') ||
                  (etc?.prevWord?.includes('знижок') &&
                    word?.includes('операції')),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  etc?.prevWord?.includes('операції') &&
                  word?.includes('картки'),
                1,
              );
              self.deleteFromTo((word) =>
                word?.startsWith(
                  "Довідка видана для пред'явлення за місцем вимоги",
                ),
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
              ]);
            },
          },
        },
      },
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => {
            const dates = this.getFirstValidCellByColumn(['Дата\nоперації', 0]);
            const dateMatch = dates?.match(/\d{2}\.\d{2}\.\d{4}/);
            const date = dateMatch ? dateMatch[0] : undefined;
            return {
              date,
              sum: this.getFirstValidCellByColumn([
                'Сума у\nвалюті\nкартки',
                4,
              ]),
              comment: this.getFirstValidCellByColumn(['Деталі операції', 2]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            verticalAlign: 'middle',
            interlineSpacingAccuracy: 9,
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) =>
                  word?.includes('Сума у') ||
                  (word?.includes('Сума') &&
                    etc?.nextWord?.includes('Сума') &&
                    !word?.includes('знижок')),
                (word, etc) =>
                  etc?.prevWord?.includes('картки') ||
                  (etc?.prevWord?.includes('знижок') &&
                    word?.includes('операції')),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) =>
                  etc?.prevWord?.includes('операції') &&
                  word?.includes('картки'),
                1,
              );
              self.deleteFromTo((word) =>
                word?.startsWith(
                  "Довідка видана для пред'явлення за місцем вимоги",
                ),
              );
              self.defineOperation([
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
                (value) => /^(-?[\s\d]+,\d{2})$/.test(value), // '1 000,00'
              ]);
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return (
            rawDocument?.length &&
            rawDocument[1].includes('Сума в валюті картки')
          );
        },
        proceedCase: (importDocument) => {
          const filteredDocument = importDocument.filter(
            (line) =>
              line.some((word) => moment(word, 'DD.MM.YYYY', true).isValid()) ||
              (line.some((word) => word.includes('Дата')) &&
                line.some((word) => word.includes('Сума в валюті картки'))),
          );
          this.setDocumentHeader(filteredDocument[0]);
          this.setDocumentBody(filteredDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата', 0]),
            time: moment(
              this.getFirstValidCellByColumn(['Час', 1]),
              TIME_FORMAT,
            ).toISOString(),
            currency: this.getFirstValidCellByColumn(['Валюта картки', 6]),
            sum: this.getFirstValidCellByColumn(['Сума в валюті картки', 5]),
            comment: this.getFirstValidCellByColumn(['Опис операції', 4]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
      {
        proceedCase: (importDocument) => {
          const filteredDocument = importDocument.filter(
            (line) =>
              line.some((word) => moment(word, 'DD.MM.YYYY', true).isValid()) ||
              (line.some((word) => word.includes('Дата проводки')) &&
                line.some((word) => word.includes('Сума'))),
          );
          this.setDocumentHeader(filteredDocument[0]);
          this.setDocumentBody(filteredDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата проводки', 1]),
            time: moment(
              this.getFirstValidCellByColumn(['Час проводки', 2]),
              TIME_FORMAT,
            ).toISOString(),
            currency: this.getFirstValidCellByColumn(['Валюта', 4]),
            sum: this.getFirstValidCellByColumn(['Сума', 3]),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 5]),
            counterparty: this.getFirstValidCellByColumn([
              'Назва контрагента',
              7,
            ]),
          });
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      // case 11
      {
        async isCurCase(file: File, preParser: BaseXLSXPreParser) {
          const rawDocument = (await preParser.getRawData(
            await file.arrayBuffer(),
          )) as string[][];
          return (
            rawDocument?.length &&
            rawDocument[0][0] &&
            rawDocument[0][0].includes('Виписка з Ваших карток за період ')
          );
        },
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[1]);
          this.setDocumentBody(importDocument.slice(2));
          return (): ImportResultItemMask => {
            return {
              dateAndTime: this.getFirstValidCellByColumn(['Дата', 0]),
              category: this.getFirstValidCellByColumn(['Категорія', 1]),
              currency: this.getFirstValidCellByColumn(['Валюта картки', 5]),
              sum: this.getFirstValidCellByColumn(['Сума в валюті картки', 4]),
              comment: this.getFirstValidCellByColumn(['Опис операції', 3]),
            };
          };
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
      {
        proceedCase: (importDocument) => {
          const filteredDocument = importDocument.filter(
            (line) =>
              line.some((word) => moment(word, 'DD.MM.YYYY', true).isValid()) ||
              (line.some((word) => word.includes('Дата проводки')) &&
                line.some((word) => word.includes('Сума'))),
          );
          this.setDocumentHeader(filteredDocument[0]);
          this.setDocumentBody(filteredDocument.slice(1));
          return (): ImportResultItemMask => {
            const description = this.getFirstValidCellByColumn([
              'Призначення платежу',
              5,
            ]);
            const code = this.getFirstValidCellByColumn(['ЄДРПОУ', 6]);
            const counterparty = this.getFirstValidCellByColumn([
              'Назва контрагента',
              7,
            ]);
            const comment = [description, code, counterparty].join(' ');
            return {
              date: this.getFirstValidCellByColumn(['Дата проводки', 1]),
              time: moment(
                this.getFirstValidCellByColumn(['Час проводки', 2]),
                TIME_FORMAT,
              ).toISOString(),
              currency: this.getFirstValidCellByColumn(['Валюта', 4]),
              sum: this.getFirstValidCellByColumn(['Сума', 3]),
              comment,
            };
          };
        },
        caseOptions: { defaultCurrency: 'UAH' },
      },
    ],
  };
}
