import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  selector: {
    flex: 1,
    marginBottom: 8,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 16,
  },
  helpText: {
    fontSize: 12,
    lineHeight: '16px',
    opacity: 0.5,
  },
  commonMargin: {
    marginBottom: 16,
  },
  lastMargin: {
    marginBottom: 24,
  },
});
