export const CURRENCIES = [
  {
    code: 'UAH',
    symbol: '₴',
    value: 'Гривна',
    native: 'ГРН',
  },
  {
    code: 'USD',
    symbol: '＄',
    value: 'Доллар',
  },
  {
    code: 'USD',
    symbol: '$',
    value: 'Доллар',
  },
  {
    code: 'KZT',
    symbol: '₸',
    value: 'Казахстанский тенге',
  },
  {
    code: 'BYN',
    symbol: 'Br',
    value: 'Белорусский рубль',
  },
  {
    code: 'EUR',
    symbol: '€',
    value: 'Евро',
  },
  {
    code: 'GBP',
    symbol: '£',
    value: 'Фунт стерлингов',
  },
  {
    code: 'RUB',
    symbol: '₽',
    value: 'Рубль',
  },
  {
    code: 'CNY',
    symbol: '¥',
    value: 'Юань',
  },
  {
    code: 'MDL',
    symbol: 'L',
    value: 'Молдавский лей',
  },
  {
    code: 'GEL',
    symbol: '₾',
    value: 'Грузинский лари',
  },
  {
    code: 'AZN',
    symbol: '₼',
    value: 'Манат',
  },
  {
    code: 'AMD',
    symbol: '֏',
    value: 'Драм',
  },
  {
    code: 'KGS',
    symbol: 'K.S.',
    value: 'Сом',
  },
  {
    code: 'TJS',
    symbol: 'TJS',
    value: 'Сомони',
  },
  {
    code: 'PLN',
    symbol: 'zł',
    value: 'Польский злотый',
  },
  {
    code: 'UZS',
    symbol: 'сўм',
    value: 'Узбекский сум',
  },
  {
    code: 'CZK',
    symbol: 'Kč',
    value: 'Чешская крона',
  },
  {
    code: 'TRY',
    symbol: '₺',
    value: 'Турецкая лира',
  },
  {
    code: 'HUF',
    symbol: 'ƒ',
    value: 'Венгерский форинт',
  },
  {
    code: 'THB',
    symbol: '฿',
    value: 'Тайский бат',
  },
  {
    code: 'ILS',
    symbol: '₪',
    value: 'Израильский шекель',
  },
  {
    code: 'AUD',
    symbol: '$AU',
    value: 'Австралийский доллар',
  },
  {
    code: 'NGN',
    symbol: '₦',
    value: 'Нигерийская найра',
  },
  {
    code: 'DKK',
    symbol: 'kr.',
    value: 'Датская крона',
  },
  {
    code: 'RON',
    symbol: 'L',
    value: 'Румынский лей',
  },
  {
    code: 'MAD',
    symbol: 'DH',
    value: 'Марокканский дирхам',
  },
  // custom - for Finlog
  {
    code: 'BYN',
    symbol: 'Бр',
    value: 'Белорусский рубль',
  },
];
