import React from 'react';

import './filter-icon.module.scss';
import Filter from '../../assets/images/svg/new-filter.svg';

/* eslint-disable-next-line */
export interface FilterIconProps {
  className?: string;
  isActive?: boolean;
}

export function FilterIcon({
  className,
  isActive = true,
}: FilterIconProps) {
  if(!isActive) return null;
  
  return <img alt="filter" src={Filter} className={className} />
}

export default FilterIcon;