import { StyleProps } from './types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    padding: 32,
    borderRadius: 24,
    maxWidth: 480,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '30px',
    maxWidth: 380,
    marginBottom: 26,
  },
  subTitle: {
    fontSize: 15,
    lineHeight: '20px',
    opacity: 0.5,
  },
  selectContainer: {
    marginLeft: 0,
    marginBottom: 26,
  },
  closeIcon: {
    position: 'absolute',
    top: 25,
    right: 25,
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  button: {
    width: 166,
    background: (props: StyleProps) => props.background,
    '&:hover': {
      background: (props: StyleProps) => props.background,
    },
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: (props: StyleProps) => props.color,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxRoot: {
    paddingLeft: 0,
  },
});
