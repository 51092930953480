import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '../../../../../../assets/images/svg/checkmark.svg';
import UnVisibleIcon from '../../../../../../assets/images/svg/eye-hide.svg';
import VisibleIcon from '../../../../../../assets/images/svg/eye-open.svg';
import TrashIcon from '../../../../../../assets/images/svg/trash-red.svg';
import PreviewRegisterDialog from '../../../../../../components/PreviewRegisterDialog';
import RemoveDialog from '../../../../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../../../../../constants';
import useRestricted from '../../../../../../hooks/useRestricted';
import { ItemBase } from '../../EditDialogHOC/types';
import { useStyles } from './styles';
import { Props } from './types';

function EditRow<T extends ItemBase>(props: Props<T>) {
  const { type, item, isError, onClose, onUpdate, onDelete, resetCreateError } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { showRestrictedDialog, fastPermissionsType, deleteAccess } =
    useRestricted();

  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [visible, setVisible] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [callback, setCallback] = useState<any | null>(null);
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleChangeName = useCallback(
    (value) => {
      setName(value);
      setError('');

      if (isError) {
        resetCreateError();
      }
    },
    [isError, resetCreateError],
  );

  const handleChangeVisible = useCallback(() => {
    if (name) {
      onUpdate(name, !visible);
    }
  }, [name, onUpdate, visible]);

  const handleUpdate = useCallback(() => {
    if (name === item?.label) {
      onClose();

      return;
    }

    if (name) {
      onUpdate(name, visible);
    } else {
      setError(t('system.fieldMustFilled'));
    }
  }, [name, item?.label, onClose, onUpdate, visible, t]);

  const handleRemove = useCallback(() => {
    if (item) {
      onDelete(item._id);
    }

    handleCloseRemoveDialog();
  }, [onDelete, item, handleCloseRemoveDialog]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        event.stopPropagation();

        onClose();
      }

      if (event.keyCode === ENTER_KEY_CODE) {
        handleUpdate();
      }
    },
    [onClose, handleUpdate],
  );

  const handleSetVisibleCallback = useCallback(() => {
    setCallback(() => handleChangeVisible);

    handleOpenPreviewRegisterDialog();
  }, [handleChangeVisible, handleOpenPreviewRegisterDialog]);

  const handleSetUpdateCallback = useCallback(() => {
    setCallback(() => handleUpdate);

    handleOpenPreviewRegisterDialog();
  }, [handleUpdate, handleOpenPreviewRegisterDialog]);

  const handleSetRemoveCallback = useCallback(() => {
    setCallback(() => handleOpenRemoveDialog);

    handleOpenPreviewRegisterDialog();
  }, [handleOpenRemoveDialog, handleOpenPreviewRegisterDialog]);

  const checkRemovePermissions = useCallback(() => {
    if (fastPermissionsType && !deleteAccess) {
      showRestrictedDialog(handleSetRemoveCallback);
    } else {
      handleSetRemoveCallback();
    }
  }, [
    deleteAccess,
    fastPermissionsType,
    handleSetRemoveCallback,
    showRestrictedDialog,
  ]);

  useEffect(() => {
    if (item) {
      setName(item.label);
      setVisible(item.visible);
    }
  }, [item]);

  useEffect(() => {
    if (isError) {
      setError(t(`errors.create.${type}.exist`));
    }
  }, [isError, type, t]);

  return (
    <>
      <div className={classes.container}>
        <TextFieldComponent
          active
          onChange={handleChangeName}
          value={name}
          placeholder={t('common.title')}
          rootClassName={classes.textFieldRoot}
          errorText={error}
          isError={Boolean(error)}
          onKeyDown={handleKeyDown}
        />
        <div className={classes.row}>
          <img
            alt=""
            src={CheckIcon}
            className={classes.icon}
            onClick={handleSetUpdateCallback}
          />
          {visible ? (
            <img
              alt=""
              src={VisibleIcon}
              className={classes.icon}
              onClick={handleSetVisibleCallback}
            />
          ) : (
            <img alt="" src={UnVisibleIcon} className={classes.icon} />
          )}
          <img
            alt=""
            src={TrashIcon}
            className={classes.icon}
            onClick={checkRemovePermissions}
          />
        </div>
      </div>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveFromDialog"
          onClose={handleCloseRemoveDialog}
          onRemove={handleRemove}
          name={item?.label || ''}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={callback}
        />
      )}
    </>
  );
}

export default React.memo(EditRow);
