import { PositionProperty } from 'csstype';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Intercom from '../../assets/images/svg/intercom.svg';
import { SettingsRoutes } from '../../constants/routes';
import { getUser } from '../../store/auth/selectors';
import { selectCurrentCompany } from '../../store/company/selectors';
import { isPreviewPage } from '../../utils/isLocalhost';

function IntercomButton() {
  const user = useSelector(getUser);
  const location = useLocation();
  const company = useSelector(selectCurrentCompany);

  const styles = {
    position: 'fixed' as PositionProperty,
    right: location.pathname.includes(SettingsRoutes.SETTINGS) ? 70 : 48,
    bottom: 24,
    zIndex: 1400,
    cursor: 'pointer',
    width: location.pathname.includes(SettingsRoutes.SETTINGS) ? 'unset' : 60,
    height: location.pathname.includes(SettingsRoutes.SETTINGS) ? 'unset' : 60,
  };

  return (
    <>
      {!isPreviewPage() && !!user && !!company && (
        <div id="intercom-chat" style={{ ...styles }}>
          <img src={Intercom} alt="intercom" />
        </div>
      )}
    </>
  );
}

export default React.memo(IntercomButton);
