import { AnyAction } from 'redux';

import { initialContext } from '../constant';
import {
  CREATE_OPERATION_TAGS_PENDING,
  CREATE_OPERATION_TAGS_SUCCESS,
  CREATE_SETTINGS_TAG_SUCCESS,
  DELETE_TAG_SUCCESS,
  GET_LOG_TAGS_SUCCESS,
  GET_OPERATION_TAGS_SUCCESS,
  GET_SETTINGS_TAGS_SUCCESS,
} from './actions';
import { TagsState } from './types';

const initialState: TagsState = {
  tags: {
    ...initialContext,
  },
  loading: false,
};

// eslint-disable-next-line default-param-last
const tagReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case CREATE_OPERATION_TAGS_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_LOG_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          log: payload.data,
        },
      };
    }

    case GET_OPERATION_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          operation: payload.data,
        },
      };
    }

    case GET_SETTINGS_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          settings: payload.data,
        },
      };
    }

    case CREATE_OPERATION_TAGS_SUCCESS: {
      return {
        ...state,
        tags: {
          ...state.tags,
          operation: payload.data,
        },
        loading: false,
      };
    }

    case DELETE_TAG_SUCCESS:
    case CREATE_SETTINGS_TAG_SUCCESS: {
      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        tags: {
          ...state.tags,
          settings: payload.data,
        },
      };
    }

    default:
      return state;
  }
};

export default tagReducer;
