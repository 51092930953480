import i18next from 'i18next';
import { toast } from 'react-toastify';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  ONBOARDING_STATUS,
  ONBOARDING_STEPS,
  ONBOARDING_SUB_STEPS,
} from '@finmap/core-constants';

import MsgToast from '../../components/MsgToast';
import { IMPORT_CHUNK_SIZE, IMPORT_TOAST_ID } from '../../constants';
import { Routes } from '../../constants/routes';
import { TAction } from '../../sagas/types';
import { declensions } from '../../utils/declensions';
import { showError } from '../../utils/showError';
import {
  GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
  GET_LOG_ACCOUNTS_PENDING,
} from '../accounts/actions';
import { getUser, selectFuturePaymentsTotalMonths } from '../auth/selectors';
import { User } from '../auth/types';
import { GET_JOURNAL_PENDING } from '../journal/actions';
import { onboardingActionTypes } from '../onboardingV2/actions';
import {
  selectActiveOnboardingStep,
  selectCreateImportActiveStep,
} from '../onboardingV2/selectors';
import {
  PDF_IMPORT_FAILED,
  PDF_IMPORT_PENDING,
  PDF_IMPORT_SUCCESS,
  START_IMPORT_PENDING,
} from './actions';
import api from './api';
import { ImportPDFPayload, StartImportPayload } from './sagas.types';

const DELAY_UPDATE_LOG = 2000;

function* importPDF(action: TAction<ImportPDFPayload>) {
  try {
    const { file } = action.payload;

    const { data } = yield call(api.importKaspiPrivate, file);

    yield put({
      type: PDF_IMPORT_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({ type: PDF_IMPORT_FAILED });

    showError(error);
  }
}

function* startImport(action: TAction<StartImportPayload>): SagaIterator {
  try {
    const msgText = i18next.t('toasts.importInProgress');

    const user: User = yield select(getUser);
    const activeOnboardingStep = yield select(selectActiveOnboardingStep);
    const isCreateImportActiveStep = yield select(selectCreateImportActiveStep);

    toast(() => <MsgToast title={msgText} />, {
      toastId: IMPORT_TOAST_ID,
      autoClose: false,
      hideProgressBar: true,
    });

    const { operations, fileName, facebookContext, type, importParserName } =
      action.payload;
    const size = IMPORT_CHUNK_SIZE;
    const subarray = [];

    for (let i = 0; i < Math.ceil(operations.length / size); i += 1) {
      subarray[i] = operations.slice(i * size, i * size + size);
    }

    yield all(
      subarray.map((el) =>
        call(api.importOperationsXLSX, el, fileName, type, importParserName),
      ),
    );

    if (facebookContext) {
      facebookContext.sendData();
    }

    const futurePaymentsTotalMonths = yield select(
      selectFuturePaymentsTotalMonths,
    );

    const text = declensions(operations.length, [
      i18next.t('toasts.import.declensions.first'),
      i18next.t('toasts.import.declensions.second'),
      i18next.t('toasts.import.declensions.third'),
    ]);

    toast.update(IMPORT_TOAST_ID, {
      render: `${operations.length} ${text} ${i18next.t(
        'toasts.importSuccessful',
      )}`,
      autoClose: 5000,
      hideProgressBar: false,
    });

    yield delay(DELAY_UPDATE_LOG);

    if (!user?.onboardingCompleted) {
      if (isCreateImportActiveStep) {
        yield put({
          type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
          payload: {
            ...activeOnboardingStep,
            status: ONBOARDING_STATUS.PASSED,
          },
        });

        yield put({
          type: onboardingActionTypes.CREATE_ONBOARDING_V2_STEP,
          payload: {
            stepName: ONBOARDING_STEPS.INTEGRATION,
            stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_LOG,
            status: ONBOARDING_STATUS.ACTIVE,
          },
        });
      }
    }

    if (window.location.pathname === Routes.ON_BOARDING) {
      window.location.href = Routes.LOG;
    } else {
      yield put({ type: GET_LOG_ACCOUNTS_PENDING });
      yield put({
        type: GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
        payload: { futurePaymentsTotalMonths },
      });
      yield put({ type: GET_JOURNAL_PENDING });
    }
  } catch (error) {
    toast.update(IMPORT_TOAST_ID, {
      render: i18next.t('system.error'),
      autoClose: 5000,
      hideProgressBar: false,
    });

    showError(error);
  }
}

export default function common() {
  return all([
    takeLatest(START_IMPORT_PENDING, startImport),
    takeLatest(PDF_IMPORT_PENDING, importPDF),
  ]);
}
