import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';

import { useStyles } from './styles';
import { HintsRowProps } from './types';

function HintsRow(props: HintsRowProps) {
  const { hint, hintActive, onSetUnSplittingAmount } = props;

  const classes = useStyles();

  const handleSetUnSplittingAmount = useCallback(() => {
    onSetUnSplittingAmount(hint);
  }, [hint, onSetUnSplittingAmount]);

  return (
    <div
      className={cn(
        classes.flexRow,
        classes.flexGap16,
        classes.marginB16,
        hintActive && classes.cursorActive,
      )}
      onClick={handleSetUnSplittingAmount}
    >
      <div className={cn(classes.flexCol50, classes.justifyEnd)}>
        <Typography className={cn(classes.title, classes.hint, hint.className)}>
          {hint.text}
        </Typography>
      </div>
      <div className={cn(classes.flexCol50)}>
        <div className={cn(classes.amountContainer)}>
          <Typography
            className={cn(classes.title, classes.hint, hint.className)}
          >
            {hint.sum}
          </Typography>
        </div>
        <div className={cn(classes.textFlexCol)} />
        <div className={cn(classes.amountContainer)}>
          <Typography
            className={cn(classes.title, classes.hint, hint.className)}
          >
            {hint.percentages}
          </Typography>
        </div>
        <div className={cn(classes.deleteBtn)} />
      </div>
    </div>
  );
}

export default React.memo(HintsRow);
