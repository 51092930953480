import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
    borderRadius: 12,
    height: 56,
    paddingTop: 17,
    paddingBottom: 17,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
  },
  textRoot: {
    minHeight: 0,
    height: 56,
  },
});
