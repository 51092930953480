import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import { Routes } from '../../../constants/routes';
import { selectCountIntegrations } from '../../../store/accounts/selectors';
import { selectUnion } from '../../../store/employees/selectors';
import AccountDialog from '../../AccountsDialog';
import { useStyles } from './styles';

function LiteNotSuiteYou({
  onClose,
  onCloseParent,
}: {
  onClose(): void;
  onCloseParent?(): void;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const countIntegrations = useSelector(selectCountIntegrations);
  const countEmployeesAndInvites = useSelector(selectUnion).length;
  const history = useHistory();

  const [showAccountsDialog, setShowAccountsDialog] = useState(false);

  const handleOpenAccountsDialog = useCallback(() => {
    setShowAccountsDialog(true);
  }, []);

  const handleCloseAccountsDialog = useCallback(() => {
    setShowAccountsDialog(false);
  }, []);

  const handleOpenEmployeesPage = useCallback(() => {
    onClose();
    if (onCloseParent) onCloseParent();

    history.push(Routes.EMPLOYEE);
  }, [history, onCloseParent, onClose]);

  return (
    <>
      <Dialog
        isOpened
        onClose={onClose}
        title={t('subscription.switchLiteOff.title')}
      >
        <Typography>{t('subscription.switchLiteOff.description')}</Typography>
        <>
          {countIntegrations > 2 && (
            <div className={classes.row}>
              <Typography className={cn(classes.text, classes.marginBottom8)}>
                {t('subscription.switchLiteOff.switchOffIntegrations', {
                  postProcess: 'sprintf',
                  sprintf: [countIntegrations - 2, countIntegrations, '%'],
                })}
              </Typography>
              <Typography
                className={classes.link}
                onClick={handleOpenAccountsDialog}
              >
                {t('subscription.switchLiteOff.editAccounts')}
              </Typography>
            </div>
          )}
        </>
        <>
          {countEmployeesAndInvites > 1 && (
            <div className={classes.row}>
              <Typography className={cn(classes.text, classes.marginBottom8)}>
                {t(
                  `subscription.switchLiteOff.${
                    countEmployeesAndInvites === 2
                      ? 'switchOffEmployee'
                      : 'switchOffEmployees'
                  }`,
                  {
                    postProcess: 'sprintf',
                    sprintf: [countEmployeesAndInvites - 1, '%'],
                  },
                )}
              </Typography>
              <Typography
                className={classes.link}
                onClick={handleOpenEmployeesPage}
              >
                {t('subscription.switchLiteOff.editEmployees')}
              </Typography>
            </div>
          )}
        </>
        <CustomButton
          title={t('subscription.switchLiteOff.ok')}
          action={onClose}
          fullWidth
          className={classes.button}
        />
      </Dialog>
      {showAccountsDialog && (
        <AccountDialog onClose={handleCloseAccountsDialog} />
      )}
    </>
  );
}

export default React.memo(LiteNotSuiteYou);
