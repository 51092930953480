import { PeriodCalendarPayload } from './sagas.types';

export const GET_CALENDAR_PENDING = 'GET_CALENDAR_PENDING';
export const GET_CALENDAR_SUCCESS = 'GET_CALENDAR_SUCCESS';

export const SET_CALENDAR_SELECTED_DAY = 'SET_CALENDAR_SELECTED_DAY';
export const SET_CALENDAR_PERIOD_PENDING = 'SET_CALENDAR_PERIOD_PENDING';
export const SET_CALENDAR_PERIOD_SUCCESS = 'SET_CALENDAR_PERIOD_SUCCESS';

export const SET_CALENDAR_PERIOD_FILTER = 'SET_CALENDAR_PERIOD_FILTER';

const getCalendar = () => ({ type: GET_CALENDAR_PENDING });

const setSelectedDay = (date: number | null) => ({
  type: SET_CALENDAR_SELECTED_DAY,
  payload: { date },
});

const setHeaderPeriod = (period: PeriodCalendarPayload) => ({
  type: SET_CALENDAR_PERIOD_PENDING,
  payload: { period },
});

export default {
  getCalendar,
  setSelectedDay,
  setHeaderPeriod,
};
