import { catskillWhite,white } from '../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: white,
  },
  circle: {
    width: 160,
    height: 160,
    borderRadius: '50%',
    background: catskillWhite,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    marginBottom: 20,
    maxWidth: 360,
    textAlign: 'center',
  },
  description: {
    color: 'rgba(0, 0, 0, 0.5)',
    marginBottom: 40,
    maxWidth: 400,
    textAlign: 'center',
  },
  button: {
    padding: '18px 24px',
    borderRadius: 12,
    textTransform: 'none',
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
  buttonText: {
    fontWeight: 'bold',
  },
});
