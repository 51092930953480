export const SUBTYPE_MAP: any = {
  consumption: {
    dividend: ['Дивиденды'],
    loan: ['Выдача займа'],
    loanRepayment: [
      'Погашение кредита',
      'Тело кредита',
      'Кредит',
      'Кредит банку',
    ],
    salary: [
      'ЗП',
      'Премия',
      'Зарплата, премия',
      'зарплата СЕО',
      'Зарплаты',
      'ФОТ',
      'Заработная плата',
      'Зарплаты Подрядчиков',
      'Зарплаты станки',
      'Зарплата',
      'Зарплаты штатка',
      'Зарплата Сделка',
      'Бонус',
      'Бонусы',
    ],
    tax: [
      'Налоги',
      'Налоги, сборы (кроме НДФЛ)',
      'налоги ФОП',
      'налоги на менеджеров',
      'Налоги и обязательные платежи',
      'Налоги по ТОВ',
      'Налоги по ФОП 2',
      'Налоги по ФОП',
      'Бухгалтерия и налоги',
      'Налог 17%',
      'Налог 5%',
      'Налог',
      'Налоги по зп',
    ],
  },
  income: {
    investment: ['Инвестиции', 'Инвестиция'],
    loan: ['Возврат займа', 'Возврат денег'],
    loanRepayment: [
      'Получение кредита',
      'Кредит',
      'В долг',
      'Займ',
      'Займ +',
      'Получен займ',
      'Тело кредита',
      'Кредит банку',
      'Кредит банка',
    ],
  },
};
