import MonoIcon from '../../assets/images/svg/mono.svg';
import ABankIcon from '../../assets/images/svg/payments/abank.svg';
import CheckboxIcon from '../../assets/images/svg/payments/checkbox.svg';
import FacturowniaIcon from '../../assets/images/svg/payments/facturownia.svg';
import FondyIcon from '../../assets/images/svg/payments/fondy.svg';
import iDokladIcon from '../../assets/images/svg/payments/iDoklad.svg';
import PayoneerIcon from '../../assets/images/svg/payments/payoneer.svg';
import PosterIcon from '../../assets/images/svg/payments/poster.png';
import PumbIcon from '../../assets/images/svg/payments/pumb.svg';
import UkrSibBankIcon from '../../assets/images/svg/payments/ukrsibbank.svg';
import WesternBidIcon from '../../assets/images/svg/payments/western-bid.svg';
import Privat24BusinessIcon from '../../assets/images/svg/privat24.svg';
import Privat24Icon from '../../assets/images/svg/privat24-own.svg';
import { IntegrationTypeId } from '../../store/integrations/types';

function getIntegrationIcon(typeId?: IntegrationTypeId, logoLink?: string) {
  if (logoLink) {
    return logoLink;
  }

  switch (typeId) {
    case IntegrationTypeId.ABank:
      return ABankIcon;
    case IntegrationTypeId.CheckBox:
      return CheckboxIcon;
    case IntegrationTypeId.Facturownia:
      return FacturowniaIcon;
    case IntegrationTypeId.Fondy:
      return FondyIcon;
    case IntegrationTypeId.FondyQ2:
      return FondyIcon;
    case IntegrationTypeId.iDoklad:
      return iDokladIcon;
    case IntegrationTypeId.MonoPersonal:
      return MonoIcon;
    case IntegrationTypeId.Poster:
      return PosterIcon;
    case IntegrationTypeId.Payoneer:
      return PayoneerIcon;
    case IntegrationTypeId.Pumb:
      return PumbIcon;
    case IntegrationTypeId.WesternBid:
      return WesternBidIcon;
    case IntegrationTypeId.UkrSib:
      return UkrSibBankIcon;
    case IntegrationTypeId.Private24Business:
      return Privat24BusinessIcon;
    case IntegrationTypeId.Private24Personal:
      return Privat24Icon;
    default:
      return '';
  }
}

export default {
  getIntegrationIcon,
};
