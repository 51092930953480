import { isEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class BankPivdennyImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        proceedCase: (importDocument) => {
          const firstHeaderLine = this.findString(/^Деталі платежу/)?.raw;
          const endBodyLine = this.findString(/^Обороти:/)?.raw;
          const currencyLine = this.findString(/^Валюта:/)?.raw;
          const currency = importDocument[currencyLine]
            ?.find(
              (cell) => /^(\s*[A-Z]{3}\s*)$/.test(cell), // "UAH "
            )
            ?.trim();
          const debitColumn = importDocument[firstHeaderLine].findIndex(
            (cell) => /^Дебет/.test(cell),
          );
          const credirColumn = importDocument[firstHeaderLine].findIndex(
            (cell) => /^Кредит/.test(cell),
          );

          importDocument = importDocument.map((line) => {
            return line.filter(
              (cell, index) =>
                !isEmpty(cell) ||
                index === debitColumn ||
                index === credirColumn,
            );
          });

          this.setDocumentHeader(importDocument[firstHeaderLine]);

          importDocument = importDocument.filter(
            (line, index) =>
              line.some((cell) => !isEmpty(cell)) &&
              index < endBodyLine &&
              index > firstHeaderLine + 1 &&
              !line.some((cell) => /^Деталі платежу/.test(cell)),
          );

          this.setDocumentBody(importDocument);
          return (): ImportResultItemMask => ({
            dateAndTime: moment(
              this.getFirstValidCellByColumn(['Дата проводки']),
              'DD.MM.YYYY HH:mm:ss',
            ).toISOString(),
            debit: this.getFirstValidCellByColumn(['Дебет']),
            credit: this.getFirstValidCellByColumn(['Кредит']),
            counterparty: this.getFirstValidCellByColumn(['Кореспондент']),
            comment: this.getFirstValidCellByColumn(['Деталі платежу']),
            currency,
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { inRaw: 'Надруковано:' }]],
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          proceedCaseConfig: {
            fields: {
              currency: { column: ['Валюта', 12] },
              credit: {
                column: ['Сума', 11],
                if: { eq: [{ column: ['Операція', 2] }, 'Кредит'] },
              },
              debit: {
                column: ['Сума', 11],
                if: { neq: [{ column: ['Операція', 2] }, 'Кредит'] },
              },
              dateAndTime: {
                column: ['Дата проведення', 9],
                add: [' ', { column: ['Час проведення', 13] }],
              },
              comment: {
                column: ['Найменування контрагента', 6],
                add: ['; ', { column: ['Призначення платежу', 8] }],
              },
            },
          },
        },
      },
    ],
  };
}
