import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import PlayIcon from '../../assets/images/svg/play_circle_green.svg';
import VideoDialog from '../../components/VideoDialog';
import useHelpVideoLinks, { HelperPages } from '../../hooks/useHelpVideoLinks';
import { useStyles } from './styles';

function VideoHelper({ page }: { page: HelperPages }) {
  const { getHelperProps, showVideoLinks } = useHelpVideoLinks();
  const { link, enabled, label, title } = getHelperProps(page);

  const classes = useStyles();

  const [showVideoDialog, setShowVideoDialog] = useState(false);

  const handleOpenVideoDialog = useCallback(() => {
    setShowVideoDialog(true);
  }, []);

  const handleCloseVideoDialog = useCallback(() => {
    setShowVideoDialog(false);
  }, []);

  return (
    <div>
      {showVideoLinks && enabled && (
        <div className={classes.helpRow} onClick={handleOpenVideoDialog}>
          <img src={PlayIcon} alt="play" className={classes.playIcon} />
          <Typography className={classes.helpText}>{label}</Typography>
        </div>
      )}
      <VideoDialog
        title={title}
        isOpened={showVideoDialog}
        videoLink={link}
        onCLose={handleCloseVideoDialog}
      />
    </div>
  );
}

export default React.memo(VideoHelper);
