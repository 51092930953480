import { useSelector } from 'react-redux';

import { selectOperationAccounts } from '../../../../store/accounts/selectors';
import {
  selectConsumptionCatsAndSubCatsToList,
  selectIncomeCatsAndSubCatsToList,
  selectLoadingConsumptionCategories,
  selectLoadingIncomeCategories,
} from '../../../../store/categories/selectors';
import {
  isLoading,
  selectAllOperationsClients,
  selectOperationsBorrowers,
  selectOperationsClients,
  selectOperationsCreditors,
  selectOperationsInvestors,
  selectOperationsOwners,
  selectOperationsStaffMembers,
  selectOperationsSuppliers,
  selectOperationsTaxOrganisations,
} from '../../../../store/clients/selectors';
import {
  getCompany,
  selectCompanyCurrency,
  selectCompanyCurrencySymbol,
} from '../../../../store/company/selectors';
import { selectExchangeRateFromTo } from '../../../../store/currency/selectors';
import {
  getCreateAndCopyState,
  getIsCopyComponent,
} from '../../../../store/operations/selectors';
import {
  selectLoadingProjects,
  selectOperationProjects,
  selectSettingsProjects,
} from '../../../../store/projects/selectors';
import {
  isLoadingTag,
  selectOperationTags,
} from '../../../../store/tags/selectors';

function useSelectProps() {
  return {
    isCopyComponent: useSelector(getIsCopyComponent),
    createAndCopyState: useSelector(getCreateAndCopyState),
    creatingClientInProgress: useSelector(isLoading),
    createTagInProgress: useSelector(isLoadingTag),
    creatingProjectInProgress: useSelector(selectLoadingProjects),
    creatingIncomeCategoryInProgress: useSelector(
      selectLoadingIncomeCategories,
    ),
    creatingConsumptionCategoryInProgress: useSelector(
      selectLoadingConsumptionCategories,
    ),
    company: useSelector(getCompany),
    accounts: useSelector(selectOperationAccounts),
    clients: useSelector(selectOperationsClients),
    investors: useSelector(selectOperationsInvestors),
    creditors: useSelector(selectOperationsCreditors),
    borrowers: useSelector(selectOperationsBorrowers),
    taxOrganisations: useSelector(selectOperationsTaxOrganisations),
    owners: useSelector(selectOperationsOwners),
    suppliers: useSelector(selectOperationsSuppliers),
    staffMembers: useSelector(selectOperationsStaffMembers),
    categories: useSelector(selectIncomeCatsAndSubCatsToList),
    consumptionCategories: useSelector(selectConsumptionCatsAndSubCatsToList),
    operationProjects: useSelector(selectOperationProjects),
    settingsProjects: useSelector(selectSettingsProjects),
    tags: useSelector(selectOperationTags),
    companyCurrencySymbol: useSelector(selectCompanyCurrencySymbol),
    exchangeRateFromTo: useSelector(selectExchangeRateFromTo),
    allClients: useSelector(selectAllOperationsClients),
    companyCurrency: useSelector(selectCompanyCurrency),
  };
}

export default useSelectProps;
