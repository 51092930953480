import {
  Collapse,
  FormControlLabel,
  MenuList,
  Switch,
  Typography,
} from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import SystemIcon from '../../../assets/images/svg/bank.svg';
import DropDown from '../../../assets/images/svg/dropdown-black.svg';
import DropUp from '../../../assets/images/svg/dropup-black.svg';
import QuestionIcon from '../../../assets/images/svg/question.svg';
import SettingsIcon from '../../../assets/images/svg/setting.svg';
import PopperComponent from '../../../components/PopperComponent';
import PreviewRegisterDialog from '../../../components/PreviewRegisterDialog';
import Tooltip from '../../../components/Tooltip';
import { OWNER_ROLE_ID } from '../../../constants';
import {
  GLOBAL_COUNTERPARTY_LIST,
  SHOW_DELETE_ACCOUNT,
  SHOW_GROW_SURF_REFERRAL,
  SHOW_SETUP_AND_IMPLEMENT,
} from '../../../constants/featureToggles/featureToggle';
import usePreviewRegister from '../../../hooks/usePreviewRegister';
import useRestricted from '../../../hooks/useRestricted';
import useSubscriptionActive from '../../../hooks/useSubscriptionActive';
import useUnleash from '../../../hooks/useUnleash';
import ChangePWDialog from '../../../organisms/settings/change-user-password';
import { useStylesSwitch } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/styles';
import {
  ReportName,
  SettingsName,
} from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { useCategoriesStyles } from '../../../scenes/InfoBlock/Settings/components/Categories/styles';
import {
  selectIsOwner,
  selectUserRoleId,
} from '../../../selectors/clientPermissions';
import Storages, { StorageKey } from '../../../services/Storages';
import {
  analyticItemEnable,
  getUser,
  selectCompanyTypeFastPermissions,
  selectSettingsPermissions,
} from '../../../store/auth/selectors';
import { selectSettingsClients } from '../../../store/clients/selectors';
import { UrlPrefix } from '../../../store/clients/types';
import commonActions from '../../../store/common/actions';
import { selectCurrentCompany } from '../../../store/company/selectors';
import { selectSupportInvite } from '../../../store/employees/selectors';
import { CategoriesType } from '../../../store/operations/types';
import { getCurrentLanguage } from '../../../utils/getCurrentLanguage';
import { isLite, isTrial } from '../../../utils/subscriptions';
import AccountDialog from '../../AccountsDialog';
import CompaniesDialog from '../../CompaniesDialog';
import ImportDialog from '../../ImportDialog';
import SubscriptionDialog from '../../Subscriptions/SubscriptionChooseDialog';
import SubscriptionDetailsDialog from '../../Subscriptions/SubscriptionDetailsDialog';
import TelegramBotDialog from '../../TelegramBot';
import ApiDialog from './Components/Api';
import CategoriesDialog from './Components/CategoriesDialog';
import FixedCurrencies from './Components/CustomCourses';
import EditDialogHOC from './Components/EditDialogHOC';
import LangsModal from './Components/LangDialog';
import ReferralDialog from './Components/ReferralDialog';
import SetupAndImplementFirstDialog from './Components/SetupAndImplementDialogs';
import WebhookDialog from './Components/Webhooks';
import { MenuItemWrapper, useStyles } from './styles';
import { Props } from './types';
import useGetIsEditable from './useGetIsEditable';
import usePermissionItemWrapper from './usePermissionItemWrapper';
import useSettingsActions from './useSettingsActions';

const MenuItemWrapperComponent = MenuItemWrapper as any;
const EditDialogHOCComponent = EditDialogHOC as any;
const ReactTooltipComponent = ReactTooltip as any;

function Settings(props: Props) {
  const { disableIcon } = props;

  const settingsDeepLinks = Storages.get(StorageKey.settingsDeepLinks);

  const user = useSelector(getUser);
  const isOwner = useSelector(selectIsOwner);
  const currentCompany = useSelector(selectCurrentCompany);
  const currentRoleId = useSelector(selectUserRoleId);
  const supportHasAccess = useSelector(selectSupportInvite);
  const historyEnable = useSelector(
    analyticItemEnable(ReportName['operations/history']),
  );
  const settingsPermissions = useSelector(selectSettingsPermissions);
  const fastPermissionsType = useSelector(selectCompanyTypeFastPermissions);
  const clients = useSelector(selectSettingsClients);

  const {
    onLogout,
    onSaveLng,
    showLngModal,
    onListKeyDown,
    onOpenLngDialog,
    onCloseLngDialog,
    showImportDialog,
    showSettingsPopup,
    onOpenImportDialog,
    onCloseImportDialog,
    onGiveSupportAccess,
    onCloseSettingsPopup,
    showTelegramBotDialog,
    onToggleSettingsPopup,
    showSubscriptionButton,
    showSubscriptionDialog,
    onOpenTelegramBotDialog,
    onCloseTelegramBotDialog,
    onOpenSubscriptionDialog,
    onCloseSubscriptionDialog,
  } = useSettingsActions(settingsDeepLinks);

  const {
    showPreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
  } = usePreviewRegister();

  const { PermissionItemWrapper } = usePermissionItemWrapper();
  const { isEditable } = useGetIsEditable();

  const classes = useStyles({
    // @ts-ignore
    maxHeight: isMobile ? window.innerHeight - 60 : 'unset',
  });
  const classesSwitch = useStylesSwitch();
  const categoriesClasses = useCategoriesStyles();

  const showDeleteAccount = useUnleash(SHOW_DELETE_ACCOUNT);
  const showGrowsurfReferral = useUnleash(SHOW_GROW_SURF_REFERRAL);
  const isGlobalCounterpartyListFeatureEnable = useUnleash(
    GLOBAL_COUNTERPARTY_LIST,
  );
  const showSetupAndImplementFeature = useUnleash(SHOW_SETUP_AND_IMPLEMENT);

  const subscriptionActive = useSubscriptionActive();

  const dispatch = useDispatch();

  const { showRestrictedDialog } = useRestricted();

  const language = Storages.get(StorageKey.language);

  const [openList, setOpenList] = useState(false);
  const [menageAccountList, setMenageAccountList] = useState(false);
  const [openDeveloperList, setOpenDeveloperList] = useState(false);
  const [showFixedCurrenciesDialog, setShowFixedCurrenciesDialog] =
    useState(false);
  const [showApiDialog, setShowApiDialog] = useState(false);
  const [showWebHookDialog, setShowWebHookDialog] = useState(false);
  const [showChangePWDialog, setShowChangePWDialog] = useState(
    settingsDeepLinks?.openChangePWLink,
  );
  const [showAccountsDialog, setShowAccoutnsDialog] = useState(false);
  const [showProjectsDialog, setShowProjectsDialog] = useState(
    settingsDeepLinks?.openProjectsLink,
  );
  const [showTagsDialog, setShowTagsDialog] = useState(
    settingsDeepLinks?.openTagsLink,
  );
  const [showClientsDialog, setShowClientsDialog] = useState(false);
  const [showCompaniesDialog, setShowCompaniesDialog] = useState(
    settingsDeepLinks?.openCreateCompanyLink,
  );
  const [showReferralDialog, setShowReferralDialog] = useState(
    settingsDeepLinks?.openReferralLink,
  );

  const [showIncomeCategoryDialog, setShowIncomeCategoryDialog] = useState(
    settingsDeepLinks?.openIncomeCategoriesLink,
  );
  const [showConsumptionCategoryDialog, setShowConsumptionCategoryDialog] =
    useState(settingsDeepLinks?.openConsumptionCategoriesLink);
  const [currentClientType, setCurrentClientType] = useState<UrlPrefix>(
    UrlPrefix.clients,
  );
  const [showSetupAndImplementDialog, setShowSetupAndImplementDialog] =
    useState(false);

  const [currentCategoryType, setCurrentCategoryType] =
    useState<CategoriesType>(
      settingsDeepLinks?.openIncomeCategoriesLink
        ? CategoriesType.systemIncome
        : CategoriesType.systemConsumption,
    );

  Storages.remove(StorageKey.settingsDeepLinks);

  const handleCloseReferralDialog = useCallback(() => {
    setShowReferralDialog(false);
  }, []);

  const handleOpenReferralDialog = useCallback(() => {
    setShowReferralDialog(true);
  }, []);

  const handleOpenSetupAndImplementDialog = useCallback(() => {
    setShowSetupAndImplementDialog(true);
  }, []);

  const handleCloseSetupAndImplementDialog = useCallback(() => {
    setShowSetupAndImplementDialog(false);
  }, []);

  const handleOpenApiDialog = useCallback(() => {
    setShowApiDialog(true);
  }, []);

  const handleOpenFixedCurrenciesDialog = useCallback(() => {
    if (currentRoleId !== OWNER_ROLE_ID) {
      showRestrictedDialog();

      return;
    }

    setShowFixedCurrenciesDialog(true);
  }, [currentRoleId, showRestrictedDialog]);

  const handleCloseFixedCurrenciesDialog = useCallback(() => {
    setShowFixedCurrenciesDialog(false);
  }, []);

  const handleOpenWebHookDialog = useCallback(() => {
    setShowWebHookDialog(true);
  }, []);

  const handleCloseApiDialog = useCallback(() => {
    setShowApiDialog(false);
  }, []);

  const handleCloseWebHookDialog = useCallback(() => {
    setShowWebHookDialog(false);
  }, []);

  const { t } = useTranslation();

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleOpenCompaniesDialog = useCallback(() => {
    setShowCompaniesDialog(true);
  }, []);

  const handleCloseCompaniesDialog = useCallback(() => {
    setShowCompaniesDialog(false);
  }, []);

  const handleToggleList = useCallback(() => {
    setOpenList(!openList);
  }, [openList]);

  const handleToggleMenageAccountList = useCallback(() => {
    setMenageAccountList(!menageAccountList);
  }, [menageAccountList]);

  const handleToggleDeveloperList = useCallback(() => {
    setOpenDeveloperList(!openDeveloperList);
  }, [openDeveloperList]);

  const resetCreateError = useCallback(() => {
    dispatch(commonActions.resetCreateError());
  }, [dispatch]);

  const handleCloseConsumptionCategoryDialog = useCallback(() => {
    setShowConsumptionCategoryDialog(false);
    resetCreateError();
  }, [resetCreateError]);

  const handleOpenConsumptionCategoryDialog = useCallback(
    (id: CategoriesType) => {
      setShowConsumptionCategoryDialog(true);
      setCurrentCategoryType(id);
    },
    [],
  );

  const handleCloseIncomeCategoryDialog = useCallback(() => {
    setShowIncomeCategoryDialog(false);
    resetCreateError();
  }, [resetCreateError]);

  const handleOpenIncomeCategoryDialog = useCallback((id: CategoriesType) => {
    setShowIncomeCategoryDialog(true);
    setCurrentCategoryType(id);
  }, []);

  const handleOpenProjectsDialog = useCallback(() => {
    setShowProjectsDialog(true);
  }, []);

  const handleCloseProjectsDialog = useCallback(() => {
    setShowProjectsDialog(false);
    resetCreateError();
  }, [resetCreateError]);

  const handleOpenTagsDialog = useCallback(() => {
    setShowTagsDialog(true);
  }, []);

  const handleCloseTagsDialog = useCallback(() => {
    setShowTagsDialog(false);
    resetCreateError();
  }, [resetCreateError]);

  const handleOpenChangePWDialog = useCallback(() => {
    setShowChangePWDialog(true);
  }, []);

  const handleCloseChangePWDialog = useCallback(() => {
    setShowChangePWDialog(false);
  }, []);

  const handleOpenAccountsDialog = useCallback(() => {
    setShowAccoutnsDialog(true);
  }, []);

  const handleCloseAccountsDialog = useCallback(() => {
    setShowAccoutnsDialog(false);
  }, []);

  const handleOpenClientsDialog = useCallback(() => {
    setShowClientsDialog(true);
  }, []);

  const handleCloseClientsDialog = useCallback(() => {
    setShowClientsDialog(false);
  }, []);

  const handleChangeCurrentClientType = useCallback(
    (event: any) => {
      const { id } = event.target;

      setCurrentClientType(id);
      handleOpenClientsDialog();
    },
    [handleOpenClientsDialog],
  );

  const currentLanguage =
    getCurrentLanguage().toLowerCase() === 'uk' ? 'ua' : getCurrentLanguage();

  return (
    <>
      <div
        id="cypress-settings-button"
        className={cn(
          classes.root,
          disableIcon && classes.disabledIcon,
          showSettingsPopup && classes.activeSettings,
        )}
        onClick={onToggleSettingsPopup}
        ref={anchorRef}
      >
        <img
          id="settings-icon"
          alt="SettingsIcon"
          src={SettingsIcon}
          className={(!showSettingsPopup && classes.settingIcon) || undefined}
        />
        {showSettingsPopup && (
          <div id="active-marker" className={classes.active} />
        )}
      </div>
      {showSettingsPopup && (
        <PopperComponent
          onClose={onCloseSettingsPopup}
          anchorRef={anchorRef}
          placement="bottom-start"
          paperClasses={classes.popper}
          disablePortal
        >
          <>
            <div className={classes.headerContainer}>
              <Typography
                id="cypress-settings-user-email"
                className={classes.email}
              >
                {user?.email}
              </Typography>
              <Typography
                className={classes.logout}
                onClick={onOpenPreviewRegisterDialog}
              >
                {t('userMenu.exit')}
              </Typography>
            </div>
            <div className={cn(classes.infoContainer, 'scrollable-list')}>
              {(Boolean(settingsPermissions?.typeIds.length) ||
                isOwner ||
                fastPermissionsType) && (
                <MenuList
                  autoFocusItem={showSettingsPopup}
                  id="menu-list-grow"
                  onKeyDown={onListKeyDown}
                  className={cn(
                    classes.contentContainer,
                    classes.minLeftWidth,
                    classes.separator,
                  )}
                >
                  <div className={cn(classes.flexRow, classes.marginBottom16)}>
                    <div className={classes.listItemTitle}>
                      {currentCompany?.name.toUpperCase()}
                    </div>
                    <div
                      id="cypress-companies-button"
                      className={classes.dotter}
                      onClick={handleOpenCompaniesDialog}
                    >
                      <Typography className={classes.editCompany}>
                        {t('company.allCompanies')}
                      </Typography>
                    </div>
                  </div>

                  <MenuItemWrapperComponent
                    onClick={handleToggleList}
                    className={classes.dropDownListItem}
                  >
                    {t('operationDialogs.counterparties.inputLabel')}
                    {openList ? (
                      <img alt="" src={DropUp} />
                    ) : (
                      <img alt="" src={DropDown} />
                    )}
                  </MenuItemWrapperComponent>
                  <Collapse
                    in={openList}
                    timeout={0}
                    unmountOnExit
                    className={classes.collapse}
                  >
                    {(!isGlobalCounterpartyListFeatureEnable ||
                      Boolean(clients.length)) && (
                      <PermissionItemWrapper label={SettingsName.clients}>
                        <MenuItemWrapperComponent
                          id={UrlPrefix.clients}
                          onClick={handleChangeCurrentClientType}
                        >
                          {t('operationDialogs.clients.inputLabel')}
                        </MenuItemWrapperComponent>
                      </PermissionItemWrapper>
                    )}
                    <PermissionItemWrapper label={SettingsName.investors}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.investors}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.investors.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper label={SettingsName.creditors}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.creditors}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.creditors.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper label={SettingsName.borrowers}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.borrowers}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.borrowers.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper label={SettingsName.suppliers}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.suppliers}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.suppliers.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper label={SettingsName.staffMembers}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.staffMembers}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.staffMembers.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper label={SettingsName.owners}>
                      <MenuItemWrapperComponent
                        id={UrlPrefix.owners}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.owners.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                    <PermissionItemWrapper
                      label={SettingsName.taxOrganisations}
                    >
                      <MenuItemWrapperComponent
                        id={UrlPrefix.taxOrganisations}
                        onClick={handleChangeCurrentClientType}
                      >
                        {t('operationDialogs.taxOrganisations.inputLabel')}
                      </MenuItemWrapperComponent>
                    </PermissionItemWrapper>
                  </Collapse>

                  <PermissionItemWrapper label={SettingsName.accounts}>
                    <MenuItemWrapperComponent
                      onClick={handleOpenAccountsDialog}
                    >
                      {t('settingsPage.accounts.title')}
                    </MenuItemWrapperComponent>
                  </PermissionItemWrapper>
                  <MenuItemWrapperComponent
                    onClick={handleOpenFixedCurrenciesDialog}
                  >
                    {t('userMenu.fixedCurrencies.title')}
                  </MenuItemWrapperComponent>
                  <PermissionItemWrapper label={SettingsName.projects}>
                    <MenuItemWrapperComponent
                      onClick={handleOpenProjectsDialog}
                    >
                      {t('settingsPage.projects.title')}
                    </MenuItemWrapperComponent>
                  </PermissionItemWrapper>
                  <PermissionItemWrapper label={SettingsName.tags}>
                    <MenuItemWrapperComponent onClick={handleOpenTagsDialog}>
                      {t('settingsPage.tags.title')}
                    </MenuItemWrapperComponent>
                  </PermissionItemWrapper>
                  <PermissionItemWrapper
                    label={SettingsName['categories/income']}
                  >
                    <div
                      className={categoriesClasses.menuItemWrapperRow}
                      onClick={() =>
                        handleOpenIncomeCategoryDialog(
                          CategoriesType.systemIncome,
                        )
                      }
                    >
                      {t('settingsPage.incomeCategories.systemTitle')}
                      <Tooltip
                        title={t('settingsPage.systemCategoriesTooltip')}
                      >
                        <img alt="system" src={SystemIcon} />
                      </Tooltip>
                    </div>
                  </PermissionItemWrapper>
                  <PermissionItemWrapper
                    label={SettingsName['categories/consumption']}
                  >
                    <div
                      className={categoriesClasses.menuItemWrapperRow}
                      onClick={() =>
                        handleOpenConsumptionCategoryDialog(
                          CategoriesType.systemConsumption,
                        )
                      }
                    >
                      {t('settingsPage.consumptionCategories.systemTitle')}
                      <Tooltip
                        title={t('settingsPage.systemCategoriesTooltip')}
                      >
                        <img alt="system" src={SystemIcon} />
                      </Tooltip>
                    </div>
                  </PermissionItemWrapper>
                  <PermissionItemWrapper
                    label={SettingsName['categories/income']}
                  >
                    <MenuItemWrapperComponent
                      onClick={() =>
                        handleOpenIncomeCategoryDialog(CategoriesType.myIncome)
                      }
                    >
                      {t('settingsPage.incomeCategories.myTitle')}
                    </MenuItemWrapperComponent>
                  </PermissionItemWrapper>
                  <PermissionItemWrapper
                    label={SettingsName['categories/consumption']}
                  >
                    <MenuItemWrapperComponent
                      onClick={() =>
                        handleOpenConsumptionCategoryDialog(
                          CategoriesType.myConsumption,
                        )
                      }
                    >
                      {t('settingsPage.consumptionCategories.myTitle')}
                    </MenuItemWrapperComponent>
                  </PermissionItemWrapper>
                  <MenuItemWrapperComponent
                    id="cypress-import-button"
                    onClick={onOpenImportDialog}
                  >
                    {t('userMenu.import')}
                  </MenuItemWrapperComponent>
                </MenuList>
              )}
              <MenuList
                autoFocusItem={showSettingsPopup}
                id="menu-list-grow"
                onKeyDown={onListKeyDown}
                className={cn(classes.contentContainer, classes.rightBlock)}
              >
                <div
                  className={cn(classes.listItemTitle, classes.marginBottom16)}
                >
                  {t('navigation.settings').toUpperCase()}
                </div>
                {showDeleteAccount && (
                  <MenuItemWrapperComponent
                    onClick={handleToggleMenageAccountList}
                    className={classes.dropDownListItem}
                  >
                    {t('operationDialogs.menageAccount.inputLabel')}
                    {menageAccountList ? (
                      <img alt="" src={DropUp} />
                    ) : (
                      <img alt="" src={DropDown} />
                    )}
                  </MenuItemWrapperComponent>
                )}
                {showSubscriptionButton && (
                  <MenuItemWrapperComponent
                    id="cypress-subscription-button"
                    onClick={onOpenSubscriptionDialog}
                  >
                    {t('subscription.title')}
                  </MenuItemWrapperComponent>
                )}
                {showSetupAndImplementFeature && (
                  <MenuItemWrapperComponent
                    id="cypress-setup-and-implement-button"
                    onClick={handleOpenSetupAndImplementDialog}
                  >
                    {t('setupImplementation.title')}
                  </MenuItemWrapperComponent>
                )}
                <MenuItemWrapperComponent onClick={handleOpenChangePWDialog}>
                  {t('userMenu.changePassword')}
                </MenuItemWrapperComponent>

                {subscriptionActive && !isLite(currentCompany) && (
                  <MenuItemWrapperComponent
                    onClick={handleToggleDeveloperList}
                    className={classes.dropDownListItem}
                  >
                    {t('userMenu.forDeveloper')}
                    {openDeveloperList ? (
                      <img alt="drop-up" src={DropUp} />
                    ) : (
                      <img alt="drop-down" src={DropDown} />
                    )}
                  </MenuItemWrapperComponent>
                )}
                <Collapse
                  in={openDeveloperList}
                  timeout={0}
                  unmountOnExit
                  className={classes.collapse}
                >
                  <MenuItemWrapperComponent
                    id="cypress-subscription-api"
                    onClick={handleOpenApiDialog}
                  >
                    {t('userMenu.api.title')}
                  </MenuItemWrapperComponent>
                  {(isOwner || (!isOwner && historyEnable)) && (
                    <MenuItemWrapperComponent
                      id="cypress-subscription-api"
                      onClick={handleOpenWebHookDialog}
                    >
                      {t('userMenu.webhook')}
                    </MenuItemWrapperComponent>
                  )}
                </Collapse>
                <MenuItemWrapperComponent onClick={onOpenTelegramBotDialog}>
                  {t('userMenu.telegramBot')}
                </MenuItemWrapperComponent>
                {showGrowsurfReferral && (
                  <MenuItemWrapperComponent onClick={handleOpenReferralDialog}>
                    {t('userMenu.referralProgram.title')}
                  </MenuItemWrapperComponent>
                )}
                {currentRoleId === OWNER_ROLE_ID && (
                  <MenuItemWrapperComponent>
                    <FormControlLabel
                      control={
                        <Switch
                          classes={classesSwitch}
                          checked={supportHasAccess}
                          onChange={onGiveSupportAccess}
                        />
                      }
                      label={
                        <div className={classes.labelRow}>
                          <Typography>
                            {t('userMenu.giveSupportAccess')}
                          </Typography>
                          <img
                            data-for="tooltip"
                            data-tip={t('userMenu.giveSupportAccessTooltip')}
                            src={QuestionIcon}
                            alt="question"
                            className={classes.tooltipIcon}
                          />
                        </div>
                      }
                      classes={{
                        label: classes.switchLabel,
                      }}
                    />
                  </MenuItemWrapperComponent>
                )}
                <MenuItemWrapperComponent onClick={onOpenLngDialog}>
                  {`🌍 ${t(
                    'userMenu.changeLang',
                  )}: ${currentLanguage.toUpperCase()}`}
                </MenuItemWrapperComponent>
              </MenuList>
              <ReactTooltipComponent
                id="tooltip"
                className={classes.tooltipBlock}
                effect="solid"
                type="light"
                place="top"
                backgroundColor="white"
                arrowColor="white"
                // offset={{ top: 15, left: 30 }}
              />
            </div>
          </>
        </PopperComponent>
      )}
      {showLngModal && (
        <LangsModal
          defaultLanguage={language}
          onClose={onCloseLngDialog}
          onSave={onSaveLng}
        />
      )}
      {showChangePWDialog && (
        <ChangePWDialog onClose={handleCloseChangePWDialog} />
      )}
      {showAccountsDialog && (
        <AccountDialog onClose={handleCloseAccountsDialog} fromSettings />
      )}
      {showTagsDialog && (
        <EditDialogHOCComponent
          type="tags"
          isEditable={isEditable(SettingsName.tags)}
          onClose={handleCloseTagsDialog}
        />
      )}
      {showProjectsDialog && (
        <EditDialogHOCComponent
          type="projects"
          isEditable={isEditable(SettingsName.projects)}
          onClose={handleCloseProjectsDialog}
        />
      )}
      {showClientsDialog && (
        <EditDialogHOCComponent
          isEditable={isEditable(SettingsName[currentClientType])}
          prefix={currentClientType}
          onClose={handleCloseClientsDialog}
        />
      )}
      {showIncomeCategoryDialog && (
        <CategoriesDialog
          type={currentCategoryType}
          showCreateButton={currentCategoryType === CategoriesType.myIncome}
          isEditable={isEditable(SettingsName['categories/income'])}
          onClose={handleCloseIncomeCategoryDialog}
        />
      )}
      {showConsumptionCategoryDialog && (
        <CategoriesDialog
          type={currentCategoryType}
          showCreateButton={
            currentCategoryType === CategoriesType.myConsumption
          }
          isEditable={isEditable(SettingsName['categories/consumption'])}
          onClose={handleCloseConsumptionCategoryDialog}
        />
      )}
      {showCompaniesDialog && (
        <CompaniesDialog
          onClose={handleCloseCompaniesDialog}
          showInitialCreateDialog={settingsDeepLinks?.openCreateCompanyLink}
        />
      )}
      {showImportDialog && <ImportDialog onClose={onCloseImportDialog} />}
      {showTelegramBotDialog && (
        <TelegramBotDialog onClose={onCloseTelegramBotDialog} />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={onClosePreviewRegisterDialog}
          callback={onLogout}
          shouldShowDemoAlertDialog={false}
        />
      )}
      {currentCompany && showSubscriptionDialog && isTrial(currentCompany) && (
        <SubscriptionDialog onClose={onCloseSubscriptionDialog} />
      )}
      {currentCompany && showSubscriptionDialog && !isTrial(currentCompany) && (
        <SubscriptionDetailsDialog
          onClose={onCloseSubscriptionDialog}
          onChangeTariff={() => {}}
        />
      )}
      {showApiDialog && <ApiDialog onClose={handleCloseApiDialog} />}
      {showWebHookDialog && (
        <WebhookDialog onClose={handleCloseWebHookDialog} />
      )}
      {showFixedCurrenciesDialog && (
        <FixedCurrencies onClose={handleCloseFixedCurrenciesDialog} />
      )}
      {showReferralDialog && (
        <ReferralDialog onClose={handleCloseReferralDialog} />
      )}
      {showSetupAndImplementDialog && (
        <SetupAndImplementFirstDialog
          onClose={handleCloseSetupAndImplementDialog}
        />
      )}
    </>
  );
}

export default React.memo(Settings);
