import { AppState } from "../../../store/reducers";
import { createSelector } from 'reselect';

export const getUser = (state: AppState) => state.auth.user;

export const getLanguage = (state: AppState) => state.company.lng;

export const getCompany = (state: AppState) => state.company.currentCompany;

export const selectCurrentCompany = createSelector(
    getCompany,
    (company) => company,
  );

export const selectIsDemoCompany = createSelector(
    selectCurrentCompany,
    (company) => company?.demo || false,
  );