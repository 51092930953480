import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import { useStyles } from './styles';

function ErrorDialog({ onClose }: { onClose(): void }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isIntegrationActiveStep, onDeleteIntegrationStep } =
    useOnboardingV2();

  useEffect(() => {
    if (isIntegrationActiveStep) {
      onDeleteIntegrationStep();
    }
  }, [isIntegrationActiveStep, onDeleteIntegrationStep]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.loadAccountsError.title')}
    >
      <Typography className={classes.description}>
        {t('bank.loadAccountsError.description')}
      </Typography>
      <CustomButton
        fullWidth
        action={onClose}
        className={classes.button}
        title={t('bank.loadAccountsError.accept')}
      />
    </Dialog>
  );
}

export default React.memo(ErrorDialog);
