import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import FacebookPixelContext from '../../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import ukrsibApi from '../../../../services/bankIntegrations/ukrsib';
import { UpdateCredentialsPayload } from '../../../../services/bankIntegrations/ukrsib.types';
import Storages, { StorageKey } from '../../../../services/Storages';
import integrationActions from '../../../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../../../store/integrations/sagas.types';
import {
  getUkrsibCode,
  getUkrsibIntegrationId,
} from '../../../../store/integrations/selectors';
import { IntegrationTypeId } from '../../../../store/integrations/types';
import { minOperationDate } from '../../../../utils/dateFormat';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import { showError } from '../../../../utils/showError';
import CalendarComponent from '../../../Operations/Components/CalendarComponent/CalendarComponent';
import ApprovedDialog from './ApprovedDialog';
import { Props } from './types';

const maxIntegrationDate = moment().valueOf() + getTimeOffset();

function UkrsibSecondDialog(props: Props) {
  const { onClose } = props;

  const dispatch = useDispatch();

  const facebookContext = useContext(FacebookPixelContext);

  const [accountIban, setAccountIban] = useState('');
  const [selectedDate, setSelectedDate] = useState<number>(maxIntegrationDate);
  const [showApprovedDialog, setShowApprovedDialog] = useState(false);

  const [accountIbanError, setAccountIbanError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');

  const { t } = useTranslation();
  const integrationId = useSelector(getUkrsibIntegrationId);
  const clientCode = useSelector(getUkrsibCode);

  const handleCloseApprovedDialog = useCallback(() => {
    setShowApprovedDialog(false);

    onClose();
  }, [onClose]);

  const handleChangeIban = useCallback((value: string) => {
    setAccountIban(value);
    setAccountIbanError(false);
  }, []);

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleClick = useCallback(async () => {
    setLoginErrorText('');

    if (!accountIban) {
      setAccountIbanError(true);
      return;
    }

    setLoading(true);

    if (integrationId) {
      try {
        const { accountId } = Storages.get(StorageKey.ukrsib);

        const payload: UpdateCredentialsPayload = {
          integrationId,
          accountIban,
          clientCode,
        };

        const integrationPayload: CreateIntegrationPayload = {
          facebookContext,
          typeId: IntegrationTypeId.UkrSib,
          accountId,
          startDate: new Date(selectedDate).getTime() + getTimeOffset(),
        };

        await ukrsibApi.updateCredentials(payload);

        dispatch(integrationActions.createIntegration(integrationPayload));

        onClose();
      } catch (error) {
        setShowApprovedDialog(true);

        setLoginErrorText(t('errors.loginPasswordError'));
        showError(error);
      }
    }

    setLoading(false);
  }, [
    t,
    onClose,
    dispatch,
    clientCode,
    accountIban,
    selectedDate,
    integrationId,
    facebookContext,
  ]);

  return (
    <>
      <Dialog isOpened onClose={onClose} title={t('bank.ukrsib.title')}>
        <TextFieldComponent
          value={accountIban}
          onChange={handleChangeIban}
          fullWidth
          placeholder={t('bank.ukrsib.iban')}
          isError={accountIbanError}
          errorText={t('errors.reqField')}
        />
        <CalendarComponent
          onChange={handleChangeDate}
          placeholder={t('integrations.dateDescription')}
          value={selectedDate}
          fullWidth
          maxDate={new Date(maxIntegrationDate)}
          minDate={minOperationDate()}
          fromIntegration
        />
        <CustomButton
          action={handleClick}
          fullWidth
          title={t('bank.set')}
          loading={loading}
          errorText={loginErrorText}
        />
      </Dialog>
      {showApprovedDialog && (
        <ApprovedDialog onClose={handleCloseApprovedDialog} />
      )}
    </>
  );
}

export default React.memo(UkrsibSecondDialog);
