import { all, call, put, takeLatest } from 'redux-saga/effects';

import { TAction } from '../../sagas/types';
import accountsApi from '../../services/accounts';
import { showError } from '../../utils/showError';
import {
  START_LOADING_PENDING,
  USER_UPDATE_PROFILE_SUCCESS,
} from '../auth/actions';
import { Context } from '../types';
import {
  CREATE_ACCOUNT_PENDING,
  CREATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_PENDING,
  DELETE_ACCOUNT_SUCCESS,
  GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
  GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
  GET_LOG_ACCOUNTS_PENDING,
  GET_LOG_ACCOUNTS_SUCCESS,
  GET_OPERATIONS_ACCOUNTS_PENDING,
  GET_OPERATIONS_ACCOUNTS_SUCCESS,
  GET_SETTINGS_ACCOUNTS_PENDING,
  GET_SETTINGS_ACCOUNTS_SUCCESS,
  UPDATE_GROUP_ACCOUNTS_PENDING,
  UPDATE_GROUP_ACCOUNTS_SUCCESS,
} from './actions';
import {
  CreateAccountPayload,
  DeleteAccountPayload,
  UpdateFuturePaymentsByPeriodPayload,
  UpdateFuturePaymentsByPeriodResponse,
  UpdateGroupAccountsPayload,
} from './sagas.types';

function* getLogAccounts() {
  try {
    const { data } = yield call(accountsApi.getAccounts, Context.log);

    yield put({ type: GET_LOG_ACCOUNTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getSettingsAccounts() {
  try {
    const { data } = yield call(accountsApi.getAccounts, Context.settings);

    yield put({ type: GET_SETTINGS_ACCOUNTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* getOperationsAccounts() {
  try {
    const { data } = yield call(accountsApi.getAccounts, Context.operation);

    yield put({ type: GET_OPERATIONS_ACCOUNTS_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

export function* createAccount(action: TAction<CreateAccountPayload>) {
  try {
    const {
      payload: { name, currencyId, balance, isCrypto },
    } = action;

    const { data } = yield call(
      accountsApi.createAccount,
      currencyId,
      name,
      balance,
      undefined,
      isCrypto,
    );

    yield put({ type: CREATE_ACCOUNT_SUCCESS, payload: { data } });
    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });
    yield put({ type: GET_LOG_ACCOUNTS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* deleteAccount(action: TAction<DeleteAccountPayload>) {
  try {
    const {
      payload: { id, showReload },
    } = action;

    const { data } = yield call(accountsApi.deleteAccount, id);

    if (showReload) {
      yield put({ type: START_LOADING_PENDING });
    } else {
      yield put({ type: DELETE_ACCOUNT_SUCCESS, payload: { data } });
      yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });
      yield put({ type: GET_LOG_ACCOUNTS_PENDING });
    }
  } catch (error) {
    showError(error);
  }
}

function* updateGroupAccounts(action: TAction<UpdateGroupAccountsPayload>) {
  try {
    const {
      payload: { accounts },
    } = action;

    yield all(
      accounts.map((account) => {
        const { _id, currency, ...rest } = account;

        return call(accountsApi.updatePropAccount, _id, {
          ...rest,
          currencyId: currency._id,
        });
      }),
    );
    yield put({ type: UPDATE_GROUP_ACCOUNTS_SUCCESS });
    yield put({ type: GET_LOG_ACCOUNTS_PENDING });
    yield put({ type: GET_SETTINGS_ACCOUNTS_PENDING });
  } catch (error) {
    showError(error);
  }
}

function* updateFuturePaymentsByPeriod(
  action: TAction<UpdateFuturePaymentsByPeriodPayload>,
) {
  try {
    const {
      payload: { futurePaymentsTotalMonths, updateProfile },
    } = action;
    const { data }: { data: UpdateFuturePaymentsByPeriodResponse } = yield call(
      accountsApi.getFuturePaymentsByPeriod,
      futurePaymentsTotalMonths,
    );

    yield put({
      type: GET_FUTURE_PAYMENTS_BY_PERIOD_SUCCESS,
      payload: { futurePayments: data.futurePayments },
    });

    if (updateProfile) {
      const payload = {
        token: data.user.token,
        data: data.user.user,
      };

      yield put({ type: USER_UPDATE_PROFILE_SUCCESS, payload });
    }
  } catch (error) {
    showError(error);
  }
}

export default function accountsSaga() {
  return all([
    takeLatest(GET_LOG_ACCOUNTS_PENDING, getLogAccounts),
    takeLatest(GET_SETTINGS_ACCOUNTS_PENDING, getSettingsAccounts),
    takeLatest(GET_OPERATIONS_ACCOUNTS_PENDING, getOperationsAccounts),
    takeLatest(CREATE_ACCOUNT_PENDING, createAccount),
    takeLatest(UPDATE_GROUP_ACCOUNTS_PENDING, updateGroupAccounts),
    takeLatest(DELETE_ACCOUNT_PENDING, deleteAccount),
    takeLatest(
      GET_FUTURE_PAYMENTS_BY_PERIOD_PENDING,
      updateFuturePaymentsByPeriod,
    ),
  ]);
}
