import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SHOW_UZBEK_PAYMENT_BANNER } from '../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../hooks/useUnleash';
import useUnleashGetVariant from '../../../hooks/useUnleashGetVariant';
import { useStyles } from './styles';

type Props = {
  oldScreen?: boolean;
};

function UzbekBadge({ oldScreen }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const showUzbekPaymentBanner = useUnleash(SHOW_UZBEK_PAYMENT_BANNER);
  const uzbekPaymentBannerVariant = useUnleashGetVariant(
    SHOW_UZBEK_PAYMENT_BANNER,
  );

  const handleOpenLink = useCallback(() => {
    if (uzbekPaymentBannerVariant?.payload) {
      const link = uzbekPaymentBannerVariant.payload.value;

      if (link) {
        window.open(link, '_blank');
      }
    }
  }, [uzbekPaymentBannerVariant]);

  if (!showUzbekPaymentBanner) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography
          className={cn(classes.title, oldScreen && classes.limitTitle)}
        >
          {t('subscriptionV2.uzCard')}
        </Typography>
        <div
          className={cn(
            classes.buttonRoot,
            oldScreen && classes.buttonRootAbsolute,
          )}
          onClick={handleOpenLink}
        >
          <Typography className={classes.buttonText}>
            {t('subscriptionV2.contact')}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UzbekBadge);
