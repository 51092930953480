import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import VideoIcon from '../../../../../assets/images/svg/play_circle_white.svg';
import useOnboardingV2 from '../../../../../hooks/useOnboardingV2';
import { getLanguage } from '../../../../../selectors/main';
import { useTooltipStyles } from '../TooltipWrapper/styles';

function VideoLinkComponent() {
  const { t } = useTranslation();
  const tooltipClasses = useTooltipStyles();

  const language = useSelector(getLanguage);

  const { onPlayOnboardingV2Video } = useOnboardingV2();

  const handleOpenVideoLink = useCallback(
    (event: any) => {
      event.stopPropagation();

      onPlayOnboardingV2Video();
    },
    [onPlayOnboardingV2Video],
  );

  if (language !== 'uk') {
    return null;
  }

  return (
    <div
      className={cn(tooltipClasses.flexRow, tooltipClasses.clickable)}
      onClick={handleOpenVideoLink}
    >
      <img src={VideoIcon} alt="video" className={tooltipClasses.playIcon} />
      <Typography
        className={cn(
          tooltipClasses.onboardingPopupText,
          tooltipClasses.noMargin,
        )}
      >
        {t('onboardingV2.tooltip.videoInstruction')}
      </Typography>
    </div>
  );
}

export default React.memo(VideoLinkComponent);
