import { Typography } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CheckIcon from '../../../assets/images/svg/checkbox.svg';
import UnCheckIcon from '../../../assets/images/svg/uncheckbox.svg';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import facturowniaApi from '../../../services/bankIntegrations/facturownia';
import { Seller } from '../../../services/bankIntegrations/facturownia.types';
import accountActions from '../../../store/accounts/actions';
import { getNewAccount } from '../../../store/accounts/selectors';
import integrationActions from '../../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import CreateDialog from '../../AccountsDialog/CreateAccountDialogWithoutBank';
import LoginDialog from './LoginDialog';
import RenderAccountSelector from './RenderAccountSelector';
import { useStyles } from './styles';
import { LoginProps, Props, Response, SelectedAccounts } from './types';

const defaultIntegrationDate =
  moment()
    .subtract(1, 'day')
    .set({ hours: 0, minutes: 0, seconds: 0 })
    .valueOf() + getTimeOffset();

function FacturowniaDialog(props: Props) {
  const { onClose, selectedAccountId, onCloseAccountsDialog } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const facebookContext = useContext(FacebookPixelContext);

  const dispatch = useDispatch();

  const newCreatedAccount = useSelector(getNewAccount);

  const [isFlipped, setIsFlipped] = useState(false);
  const [sellers, setSellers] = useState<Seller[] | null>(null);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<SelectedAccounts>(
    {},
  );
  const [showCreateAccountDialog, setShowCreateAccountDialog] = useState(false);
  const [currentSellerId, setCurrentSellerId] = useState<
    Seller['sellerBankAccount'] | null
  >(null);
  const [accountsErrors, setAccountsErrors] = useState<
    Seller['sellerBankAccount'][]
  >([]);

  const [loginData, setLoginData] = useState<LoginProps>({
    token: '',
    userDomain: '',
    selectedDate: defaultIntegrationDate,
  });

  const handleSetLoginData = useCallback(
    (field: keyof LoginProps, value: any) => {
      setLoginData((values) => ({
        ...values,
        [field]: value,
      }));
    },
    [],
  );

  const handleOpenCreateAccountDialog = useCallback((id: string) => {
    setCurrentSellerId(id);

    setShowCreateAccountDialog(true);
  }, []);

  const handleCloseCreateAccountDialog = useCallback(() => {
    setShowCreateAccountDialog(false);
  }, []);

  const handleClose = useCallback(() => {
    if (onCloseAccountsDialog) {
      onCloseAccountsDialog();
    } else {
      onClose();
    }
  }, [onCloseAccountsDialog, onClose]);

  const handleCloseSetupSellers = useCallback(() => {
    setSelectedAccounts({});
    setSelected([]);
    setSellers(null);

    setIsFlipped(!isFlipped);
  }, [isFlipped]);

  const handleUpdateAccountsErrors = useCallback(
    (id: string) => {
      const filtered = accountsErrors.filter((el) => el !== id);

      setAccountsErrors(filtered);
    },
    [accountsErrors],
  );

  const handleSetSelectedAccounts = useCallback((values: SelectedAccounts) => {
    setSelectedAccounts(values);
  }, []);

  const RenderRow = useCallback(
    ({ item }: { item: Seller }) => {
      const handleSelect = () => {
        setSelected((values) => [...values, item.sellerBankAccount]);
      };

      const handleUnSelect = () => {
        const filtered = selected.filter((el) => el !== item.sellerBankAccount);

        setSelected(filtered);
      };

      return (
        <div className={classes.row}>
          <div className={classes.info}>
            {selected.includes(item.sellerBankAccount) ? (
              <img
                src={CheckIcon}
                alt="checked"
                className={classes.icon}
                onClick={handleUnSelect}
              />
            ) : (
              <img
                src={UnCheckIcon}
                alt="unchecked"
                className={classes.icon}
                onClick={handleSelect}
              />
            )}
            <div className={classes.infoContent}>
              <Typography className={classes.name}>
                {item.sellerName}
              </Typography>
              <Typography>{item.sellerBankAccount}</Typography>
            </div>
          </div>
          <RenderAccountSelector
            item={item}
            selected={selected}
            accountsErrors={accountsErrors}
            onUpdateAccountsError={handleUpdateAccountsErrors}
            onSetSelectedAccounts={handleSetSelectedAccounts}
            selectedAccounts={selectedAccounts}
            onOpenCreateAccountDialog={handleOpenCreateAccountDialog}
          />
        </div>
      );
    },
    [
      classes,
      selected,
      accountsErrors,
      selectedAccounts,
      handleSetSelectedAccounts,
      handleUpdateAccountsErrors,
      handleOpenCreateAccountDialog,
    ],
  );

  const startSingleIntegration = useCallback(
    async (values: Seller[]) => {
      const { data }: AxiosResponse<Response> =
        await facturowniaApi.setTokenAndUserDomain({
          token: loginData.token,
          userDomain: loginData.userDomain,
          ...values[0],
        });

      dispatch(
        integrationActions.setFacturowniaIntegrationId(
          data.integrationCredentialId,
        ),
      );

      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.Facturownia,
        accountId: selectedAccountId,
        startDate: loginData.selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));

      if (onCloseAccountsDialog) {
        onCloseAccountsDialog();
      }
    },
    [
      dispatch,
      loginData,
      facebookContext,
      selectedAccountId,
      onCloseAccountsDialog,
    ],
  );

  const handleSetSellers = useCallback(
    (values: Seller[]) => {
      setSellers(values);

      if (values.length === 1) {
        startSingleIntegration(values);
      } else {
        setIsFlipped(true);
      }
    },
    [startSingleIntegration],
  );

  const handleCreateCredentials = useCallback(async () => {
    const errors: Seller['sellerBankAccount'][] = [];

    selected.forEach((el) => {
      if (!selectedAccounts[el]) {
        errors.push(el);
      }
    });

    if (errors.length) {
      setAccountsErrors(errors);

      return;
    }

    const requests: Promise<any>[] = [];
    const sellerBankIds: Seller['sellerBankAccount'][] = [];

    selected.forEach((el) => {
      const current = sellers?.find(
        (seller) => seller.sellerBankAccount === el,
      );

      if (current) {
        requests.push(
          facturowniaApi.setTokenAndUserDomain({
            token: loginData.token,
            userDomain: loginData.userDomain,
            ...current,
          }),
        );

        sellerBankIds.push(current.sellerBankAccount);
      }
    });

    const response: AxiosResponse<Response>[] = await Promise.all(requests);

    response.forEach((el, index) => {
      dispatch(
        integrationActions.setFacturowniaIntegrationId(
          el.data.integrationCredentialId,
        ),
      );

      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.Facturownia,
        accountId: selectedAccounts[sellerBankIds[index]]._id,
        startDate: loginData.selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));
    });

    handleClose();
  }, [
    sellers,
    dispatch,
    selected,
    loginData,
    handleClose,
    facebookContext,
    selectedAccounts,
  ]);

  useEffect(() => {
    dispatch(accountActions.getOperationAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (newCreatedAccount && currentSellerId) {
      handleSetSelectedAccounts({
        ...selectedAccounts,
        [currentSellerId]: newCreatedAccount,
      });

      setCurrentSellerId(null);

      dispatch(accountActions.resetNewAccount());
    }
  }, [
    dispatch,
    currentSellerId,
    selectedAccounts,
    newCreatedAccount,
    handleSetSelectedAccounts,
  ]);

  return (
    <>
      {!isFlipped && (
        <LoginDialog
          onClose={handleClose}
          onSetSellers={handleSetSellers}
          loginData={loginData}
          onSetLoginData={handleSetLoginData}
        />
      )}
      <Dialog
        isOpened={isFlipped}
        onClose={handleCloseSetupSellers}
        title={t('bank.facturownia.connect')}
        className={classes.root}
      >
        <Typography className={classes.description}>
          {t('bank.facturownia.decsription')}
        </Typography>
        <div className={cn(classes.sellersContainer, 'scrollable-list')}>
          {sellers?.map((el) => (
            <RenderRow key={el.sellerBankAccount} item={el} />
          ))}
        </div>
        <CustomButton
          title={t('bank.set')}
          action={handleCreateCredentials}
          fullWidth
          disabled={!selected.length}
        />
      </Dialog>
      {showCreateAccountDialog && (
        <CreateDialog onClose={handleCloseCreateAccountDialog} />
      )}
    </>
  );
}

export default React.memo(FacturowniaDialog);
