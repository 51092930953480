import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class HalykBankImportParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          isCurCase: [[0, { in: 'Филиал:' }]],
          preParserConfigs: {
            maxInterlineSpacingPx: 22,
            interlineSpacingAccuracy: 1,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' }, nextWord: { eq: 'Дата' } }],
                to: [
                  { word: { eq: 'операции' }, prevWord: { eq: 'операции' } },
                ],
              },
              delete: [
                {
                  to: [
                    { word: { eq: 'операции' }, prevWord: { eq: 'операции' } },
                  ],
                  count: 1,
                },
                {
                  from: [{ word: { startsWith: 'Местопечати' } }],
                  count: 1,
                },
              ],
              define: [
                { regexp: { str: '^([\\s\\d]+,\\d+)$' } },
                { dateFormat: 'DD.MM.YYYY' },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: { column: ['проведения', 0] },
              credit: { column: ['Приход', 5] },
              debit: { column: ['Расход', 6] },
              counterparty: { column: ['карточки', 8] },
              comment: { column: ['Описание', 2] },
              currency: { column: ['Валюта', 5] },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          preParserConfigs: {
            interlineSpacingAccuracy: 8,
            prepareRawConfig: {
              findHeader: {
                from: [
                  { word: { in: 'Дата' }, nextWord: { in: 'Номер документа' } },
                ],
                to: [
                  {
                    word: { in: 'Детали платежа' },
                    prevWord: { in: 'Контрагент' },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: { in: 'Детали платежа' },
                      prevWord: { in: 'Контрагент' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { in: 'Кредит' }, nextWord: { in: 'Обороты:' } },
                  ],
                },
              ],
              define: [
                {
                  or: [
                    { regexp: { str: '^([\\s\\d]+,\\d+)$' } },
                    { regexp: { str: '^([,\\d]+\\.\\d+)$' } },
                  ],
                },
                { dateFormat: 'DD.MM.YYYY' },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: { column: ['Дата', 0] },
              debit: { column: ['Дебет', 2] },
              credit: { column: ['Кредит', 3] },
              counterparty: { column: ['Контрагент', 4] },
              comment: { column: ['Детали платежа', 5] },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'KZT',
          isDESCOpOrder: true,
          proceedCaseConfig: {
            delete: [
              {
                to: [[0, { in: 'Дата валютирования' }]],
                count: 1,
              },
              {
                from: [[0, { eq: '' }]],
                count: 1,
              },
            ],
            fields: {
              date: { column: ['Дата валютирования', 0] },
              debit: { column: ['Дебет', 7] },
              credit: { column: ['Кредит', 8] },
              counterparty: { column: ['Контрагент', 4] },
              comment: { column: ['Назначение платежа', 9] },
            },
          },
        },
      },
    ],
  };
}
