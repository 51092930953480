import { Chip, MenuItem, Typography } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
// @ts-ignore
import { SelectComponents } from 'react-select/lib/components';

import { fontWeightBold } from '../../../../constants';
import { Value } from '../../../../store/types';
import { AutoSuggestProps } from '../../../types';

function Option(props: Partial<AutoSuggestProps>) {
  const { innerRef, isFocused, isSelected, innerProps, children, selectProps } =
    props;

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      className={selectProps?.classes.menuItem}
      style={{
        background: isFocused ? '#EEF3F5' : undefined,
        fontWeight: isSelected ? fontWeightBold : undefined,
        fontSize: 15,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function MultiValue(props: Partial<AutoSuggestProps>) {
  const { children, selectProps, removeProps } = props;
  const tabIndex = -1;

  return (
    <Chip
      color="primary"
      label={children}
      tabIndex={tabIndex}
      className={
        selectProps &&
        classNames(
          selectProps?.classes.chipRoot,
          selectProps?.classes.labelText,
        )
      }
      onDelete={removeProps && removeProps.onClick}
      deleteIcon={
        <CloseIcon
          color="white"
          className={selectProps?.classes.closeIcon}
          {...removeProps}
        />
      }
    />
  );
}

function Menu({
  selectProps,
  children,
  innerProps,
}: Partial<AutoSuggestProps>) {
  return (
    <div className={selectProps && selectProps?.classes.popper} {...innerProps}>
      {children}
    </div>
  );
}

function DropdownIndicator() {
  return null;
}
function IndicatorSeparator() {
  return null;
}
function NoOptionsMessage({ selectProps }: Partial<AutoSuggestProps>) {
  const noMessageText = selectProps?.classes.noMessageText;
  const t = selectProps?.t;

  return (
    <Typography className={noMessageText}>
      {t ? t('operationDialogs.noTags') : ''}
    </Typography>
  );
}

export const components: Partial<SelectComponents<Value>> = {
  Menu,
  Option,
  MultiValue,
  DropdownIndicator,
  IndicatorSeparator,
  NoOptionsMessage,
};
