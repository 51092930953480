import { Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import Dialog from '../../../components/Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';
import CustomButton from '../../../components/Button';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import { SetCredentialsResponse } from '../../../services/bankIntegrations/monoAcquiring.types';
import monoAcquiringApis from '../../../services/bankIntegrations/monoAcquiring';
import integrationActions from '../../../store/integrations/actions';

function MonoAcquiringIntegrationDialog(props: Props) {
  const { onClose, onCreateIntegration } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [token, setToken] = useState<string | null>(null);
  const [tokenError, setTokenError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const dispatch = useDispatch();

  const handleCreateIntegration = useCallback(async () => {
    const { data: integrationData }: { data: SetCredentialsResponse } =
      await monoAcquiringApis.setCredentials(token as string);

    if (!integrationData.valid) {
      setErrorText(t('integrations.monoAcquiring.badtoken'));
      setTokenError(true);
      return;
    }
    if (integrationData.integrationId) {
      dispatch(
        integrationActions.setMonoAcquiringCredentialsId(
          integrationData.integrationId,
        ),
      );
      setErrorText('');
      setTokenError(false);
      onCreateIntegration('UAH');
    }
  }, [onCreateIntegration, token, dispatch, t]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleChangeToken = useCallback(
    (value: string) => {
      if (!value) {
        setErrorText(t('system.fieldMustFilled'));
        setTokenError(true);
      } else {
        setErrorText('');
        setTokenError(false);
      }
      setToken(value);
    },
    [t],
  );

  return (
    <Dialog
      onClose={handleClose}
      title={t('integrations.monoAcquiring.title')}
      isOpened
    >
      <Typography
        className={cn(classes.bold, classes.text, classes.commonMargin)}
      >
        {t('integrations.monoAcquiring.step1')}
      </Typography>
      <Typography className={cn(classes.text, classes.commonMargin)}>
        {t('integrations.monoAcquiring.titleStep1')}
      </Typography>
      <div className={classes.lastMargin}>
        <a
          href="https://acquiring.monobank.ua/docs/online_acquiring/website/api/token"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          {t('integrations.monoAcquiring.help')}
        </a>
      </div>
      <Typography
        className={cn(classes.bold, classes.text, classes.commonMargin)}
      >
        {t('integrations.monoAcquiring.step2')}
      </Typography>
      <Typography className={cn(classes.text, classes.commonMargin)}>
        {t('integrations.monoAcquiring.titleStep2')}
      </Typography>
      <TextFieldComponent
        value={token}
        onChange={handleChangeToken}
        label={t('integrations.monoAcquiring.token')}
        placeholder={t('integrations.monoAcquiring.token')}
        isError={tokenError}
        errorText={errorText}
        type="password"
      />

      <CustomButton
        title={t('common.continue')}
        disabled={tokenError || token === null || token === ''}
        action={handleCreateIntegration}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(MonoAcquiringIntegrationDialog);
