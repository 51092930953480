import { createSelector } from 'reselect';

import { selectCompanyCurrency } from '../company/selectors';
import { AppState } from '../reducers';

export const getIsCopyComponent = (store: AppState) =>
  store.operations.isCopyComponent;

export const getCreateAndCopyState = (store: AppState) =>
  store.operations.createAndCopyState;

export const getOperation = (store: AppState) => store.operations;
export const getHistoryOperation = (store: AppState) => store.historyOperation;

export const getAttachment = (store: AppState) => store.operations.attachment;

export const getOperationProps = createSelector(
  selectCompanyCurrency,
  getOperation,
  (companyCurrency, operation) => {
    if (!companyCurrency) {
      return operation;
    }

    const { currencyValue, ...rest } = operation;

    let newCurrencyValue;

    if (currencyValue === null) {
      newCurrencyValue = {
        id: companyCurrency._id,
        name: `${companyCurrency._id} (${companyCurrency.symbol})`,
        symbol: companyCurrency.symbol,
      };
    } else {
      newCurrencyValue = currencyValue;
    }

    return { currencyValue: newCurrencyValue, ...rest };
  },
);

export const getHistoryOperationProps = createSelector(
  selectCompanyCurrency,
  getHistoryOperation,
  (companyCurrency, operation) => {
    if (!companyCurrency) {
      return operation;
    }

    const { currencyValue, ...rest } = operation;

    let newCurrencyValue;

    if (currencyValue === null) {
      newCurrencyValue = {
        id: companyCurrency._id,
        name: `${companyCurrency._id} (${companyCurrency.symbol})`,
        symbol: companyCurrency.symbol,
      };
    } else {
      newCurrencyValue = currencyValue;
    }

    return { currencyValue: newCurrencyValue, ...rest };
  },
);
