import { useEffect,useState } from 'react';

export default function useOnScreen(ref: any, rootId?: string) {
  const [isIntersecting, setIntersecting] = useState(false);
  const [boundingClientRect, setBoundingClientRect] = useState<DOMRectReadOnly>();

  const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      setBoundingClientRect(entry.boundingClientRect);
  }, {
    root: rootId ? document.querySelector(`#${rootId}`) : null
  });

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref.current]); // eslint-disable-line

  return { isIntersecting, boundingClientRect };
}
