import CustomButton from '../../../../components/Button';
import Dialog from '../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../components/TextField/TextFieldComponent';
import { getUser } from "../../../../store/auth/selectors";
import ukrsibApi from '../../../../services/bankIntegrations/ukrsib';
import { GetCredentialsPayload, GetCredentialsResponse } from "../../../../services/bankIntegrations/ukrsib.types";
import Storages, { StorageKey } from "../../../../services/Storages";
import { showError } from '../../../../utils/showError';
import { Props } from './types';
import { makeStyles } from "@material-ui/core/styles";
import { AxiosResponse } from "axios";
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {
    height: 94,
    overflow: 'auto'
  }
})

function FirstStepDialog(props: Props) {
  const { onClose, selectedAccountId, newAccountName } = props;

  const classes = useStyles();

  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [passwordRSA, setPasswordRSA] = useState('');
  const [privatRSAkey, setPrivatRSAkey] = useState('');

  const [clientIdError, setClientIdError] = useState(false);
  const [clientSecretError, setClientSecretError] = useState(false);
  const [passwordRSAError, setPasswordRSAError] = useState(false);
  const [privatRSAkeyError, setPrivatRSAkeyError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');

  const { t } = useTranslation();

  const user = useSelector(getUser);

  const handleChangeClientId = useCallback((value) => {
    setClientId(value);
    setClientIdError(false);
  }, []);

  const handleChangeClientSecret = useCallback((value) => {
    setClientSecret(value);
    setClientSecretError(false);
  }, []);

  const handleChangePasswordRSA = useCallback((value) => {
    setPasswordRSA(value);
    setPasswordRSAError(false);
  }, []);

  const handleChangePrivatRSAkey = useCallback((value) => {
    setPrivatRSAkey(value);
    setPrivatRSAkeyError(false);
  }, []);

  const handleClick = useCallback(async () => {
    setLoginErrorText('');

    if (!clientId) {
      setClientIdError(true);
      return;
    }

    if (!clientSecret) {
      setClientSecretError(true);
      return;
    }

    if (!passwordRSA) {
      setPasswordRSAError(true);
      return;
    }

    if (!privatRSAkey) {
      setPrivatRSAkeyError(true);
      return;
    }

    setLoading(true);

    try {
      const payload: GetCredentialsPayload = {
        clientSecret,
        clientId,
        keyPassword: privatRSAkey,
        privateKey: passwordRSA,
        redirectURL: window.location.origin,
        state: user?._id ?? clientId
      }

      const {
        data: { acceptingLink, integrationId },
      }: AxiosResponse<GetCredentialsResponse> = await ukrsibApi.getCredentials(payload);

      Storages.put(StorageKey.ukrsib, {
        integrationId,
        accountId: selectedAccountId,
        accountName: newAccountName,
        state: payload.state,
      });

      if (acceptingLink) {
        window.location.href = acceptingLink;
      }
    } catch (error) {
      setLoginErrorText(t('errors.loginPasswordError'));
      showError(error);
    }

    setLoading(false);
  }, [
    t,
    user,
    clientId,
    passwordRSA,
    privatRSAkey,
    clientSecret,
    newAccountName,
    selectedAccountId
  ]);

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('bank.ukrsib.title')}
    >
      <TextFieldComponent
        value={clientId}
        onChange={handleChangeClientId}
        fullWidth
        placeholder={t('bank.ukrsib.clientId')}
        isError={clientIdError}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        value={clientSecret}
        onChange={handleChangeClientSecret}
        fullWidth
        placeholder={t('bank.ukrsib.clientSecret')}
        isError={clientSecretError}
        errorText={t('errors.reqField')}
      />
      <TextFieldComponent
        value={passwordRSA}
        onChange={handleChangePasswordRSA}
        fullWidth
        placeholder={t('bank.ukrsib.privatRSAkey')}
        isError={passwordRSAError}
        errorText={t('errors.reqField')}
        multiline
        unlimited
        rootClassName={classes.root}
      />
      <TextFieldComponent
        value={privatRSAkey}
        onChange={handleChangePrivatRSAkey}
        fullWidth
        placeholder={t('bank.ukrsib.passwordRSA')}
        isError={privatRSAkeyError}
        errorText={t('errors.reqField')}
      />
      <CustomButton
        action={handleClick}
        fullWidth
        title={t('bank.set')}
        loading={loading}
        errorText={loginErrorText}
      />
    </Dialog>
  );
}

export default React.memo(FirstStepDialog);
