import {
  GetIntegrationAccountsPayload,
  SaltedgeCredentials,
  SaltedgeCredentialsPayload,
  SaltedgeReconnectPayload,
  SaltedgeUpdateConnectionPayload,
} from '../../store/integrations/types';
import Http from '../Http';

const getAllBanks = (countryCode: string) =>
  Http.makeInstance().get(
    `integrations/saltageApiCredentials/banks/${countryCode}`,
  );

const getIntegrationAccounts = (payload: GetIntegrationAccountsPayload) => {
  const { integrationId, connectionId } = payload;

  return Http.makeInstance().get(
    `integrations/saltageApiCredentials/accounts/${integrationId}?connectionId=${connectionId}`,
  );
};

const getCredentials = (data: SaltedgeCredentials) =>
  Http.makeInstance().post('integrations/saltageApiCredentials', { ...data });

const updateCredentials = (data: SaltedgeCredentialsPayload) =>
  Http.makeInstance().patch('integrations/saltageApiCredentials', { ...data });

const updateValidCredentials = (data: Omit<SaltedgeCredentials, 'from_date'>) =>
  Http.makeInstance().post('integrations/saltageApiCredentials/update', {
    ...data,
  });

const reconnectIntegration = (data: SaltedgeReconnectPayload) =>
  Http.makeInstance().post('integrations/saltageApiCredentials/reconnect ', {
    ...data,
  });

const updateConnection = (data: SaltedgeUpdateConnectionPayload) =>
  Http.makeInstance().patch('integrations/saltageApiCredentials/connection  ', {
    ...data,
  });

export default {
  getAllBanks,
  getCredentials,
  updateCredentials,
  updateValidCredentials,
  getIntegrationAccounts,
  reconnectIntegration,
  updateConnection,
};
