import i18next from 'i18next';
import moment from 'moment';
import { createSelector } from 'reselect';

import { getLanguage } from '../../selectors/main';
import { AppState } from '../reducers';
import { InvoicingStatus, SortLogByDate } from './types';

export const getInvoices = (state: AppState) => state.invoicing.log;

export const getInvoicesLoading = (state: AppState) => state.invoicing.loading;

export const getInvoicesGoods = (state: AppState) => state.invoicing.goods;

export const getMyInvoicesProps = (state: AppState) => state.invoicing.myProps;

export const getOrdersBy = (state: AppState) => state.invoicing.multiOrderBy;

export const getInvoicesClients = (state: AppState) =>
  state.invoicing.clients;

export const getLoadingCompanyInvoicingData = (state: AppState) =>
  state.invoicing.loadingCompanyInvoicingData;

export const getPeriodFilter = (state: AppState) =>
  state.invoicing.periodFilter;

export const getCustomFilters = (state: AppState) =>
  state.invoicing.customFilters;

export const selectClientsById = (id: string) => createSelector(
  getInvoicesClients,
  counterparties => counterparties.find(el => el._id === id)
);

export const selectStatusFilter = createSelector(getLanguage, (language) => {
  const i18 = i18next.getFixedT(language);

  return [
    {
      _id: InvoicingStatus.payed,
      label: i18('invoicing.payed'),
    },
    {
      _id: InvoicingStatus.notPayed,
      label: i18('invoicing.notPayed'),
    },
    {
      _id: InvoicingStatus.overdue,
      label: i18('invoicing.overdue'),
    },
  ];
});

export const treeNodeClients = createSelector(
  getInvoicesClients,
  getCustomFilters,
  getLanguage,
  (counterparties, customFilterClients, lng) => {
    const i18 = i18next.getFixedT(lng);

    const { clients } = customFilterClients;

    const children = counterparties.map((el) => ({
      id: el._id,
      label: el.label,
      checked: !clients || clients.includes(el._id),
    }));

    return [
      {
        label: i18('filters.all.counterparties'),
        checked: !customFilterClients,
        expanded: true,
        children,
      },
    ]
  },
);

export const treeNodeStatuses = createSelector(
  selectStatusFilter,
  getCustomFilters,
  getLanguage,
  (invoicingStatuses, customFilter, lng) => {
    const i18 = i18next.getFixedT(lng);

    const { statuses } = customFilter;

    const children = invoicingStatuses.map((el) => ({
      id: el._id,
      label: el.label,
      checked: !statuses || statuses.includes(el._id),
    }));

    return [
      {
        label: i18('invoicing.allStatuses'),
        checked: !statuses,
        expanded: true,
        children,
      },
    ]
  },
);

export const selectGoodsAutoComplete = createSelector(
  getInvoicesGoods,
  props => props.map(el => ({
    ...el,
    name: el.label,
  }))
);

export const selectMyPropsAutoComplete = createSelector(
  getMyInvoicesProps,
  props => props.map(el => ({
    ...el,
    name: el.label,
  }))
);

export const selectInvoiceClientsAutoComplete = createSelector(
  getInvoicesClients,
  props => props.map(el => ({
    ...el,
    name: el.label
  }))
)

export const getInvoicingCustomFilters = (state: AppState) => state.invoicing.customFilters;

export const getInvoicingPeriodFilter = (state: AppState) => state.invoicing.periodFilter;

export const isCustomFiltersActive = createSelector(getInvoicingCustomFilters, (customFilters) => {
  const { clients, statuses } = customFilters;

  return !!clients || !!statuses;
});

export const sortLogItemsByDate = createSelector(
  getInvoices,
  invoicing => {
    const { items } = invoicing;

    return items.reduce((acc: SortLogByDate, el) => {
      const { dateOfPayment } = el;
      const date = moment.utc(dateOfPayment).format('DD MMM YYYY');

      if (acc[date]) {
        acc[date].push(el);
      } else {
        acc[date] = [el];
      }

      return acc;
    }, {});
  }
)
