import { isNotEmpty } from 'class-validator';
import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  BasePDFPreParser,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class IziBankParser extends BaseImportParserV3 {
  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        async isCurCase(file: File, preParser: BasePDFPreParser) {
          const rawDocument = await preParser.getRawData(
            await file.arrayBuffer(),
          );
          return isNotEmpty(rawDocument[0]);
        },
        proceedCase: (importDocument) => {
          let header = [];
          let headerCompleted = false;
          const body = [];
          importDocument.forEach(el => {
            const fields = el.map(withUndefined => withUndefined.replaceAll(' undefined', '').replaceAll('\n', ' '));
            if(moment(fields[0], 'DD.MM.YYYY', true).isValid()) {
              headerCompleted = true;
              body.push(fields);
            } else if(!headerCompleted) {
              header = header.map((head, i) => `${head}\n${fields[i]}`)
            } else {
              const lastBodyIndex = body.length - 1;
              body[lastBodyIndex] = body[lastBodyIndex].map((bod, i) => `${bod}\n${fields[i]}`)
            }
          })
          const resultBody = body.map((row, i) => {
            const [date, time1, time2] = row[0].split('\n');
            const time = time1 || time2;
            const comment = row[1].replaceAll('\n', ' ');
            const mccRaw = row[2].split('\n').filter(Boolean);
            let mcc = mccRaw[0];
            if(mccRaw.length === 2 && body[i+1] && body[i+1][2].replaceAll('\n', '') === '') {
              body[i+1][2] = mccRaw[1];
            }
            const sumInAccountCurrencyRaw = row[3].split('\n').filter(Boolean);
            let sumInAccountCurrency = sumInAccountCurrencyRaw[0].replaceAll(',', '');
            if(sumInAccountCurrencyRaw.length === 2 && body[i+1] && body[i+1][3].replaceAll('\n', '') === '') {
              body[i+1][3] = sumInAccountCurrencyRaw[1];
            }
            const sumOperation = row[4].replaceAll('\n', '').replaceAll(',', '');
            const currency = row[5].replaceAll('\n', '').replaceAll(',', '');
            const rate = row[6].replaceAll('\n', '').replaceAll(',', '');
            const fee = row[7].replaceAll('\n', '').replaceAll(',', '');
            const remainder = row[8].replaceAll('\n', '').replaceAll(',', '');
            return [`${date} ${time}`, comment, mcc, sumInAccountCurrency, sumOperation, currency, rate, fee, remainder];
          })
          this.setDocumentHeader(header);
          this.setDocumentBody(resultBody);
          return (): ImportResultItemMask => {
            return {
              dateAndTime: this.getFirstValidCellByColumn(['Дата i час\nоперації\n( проведено\nбанком)', 0]),
              comment: this.getFirstValidCellByColumn(['Деталі операції', 1]),
              sum: this.getFirstValidCellByColumn(['Сума в\nвалюті\nкартки (UAH)', 3]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            // separatorsDistanceModifier: 100, // add to vertical borders pixels
            //interlineSpacingAccuracy: 100,
            //spaceLengthPx: 100,
            verticalAlign: 'top',
            prepareRawPDF: (self: BasePDFPreParser) => {
              self.findHeader(
                (word, etc) => (word === 'Дата i час' && etc?.nextWord === 'Деталі операції'),
                (word, etc) => (
                    (word === 'банком)' && etc?.prevWord === '(UAH)') ||
                    (word === 'банком)' && etc?.prevWord === 'операції')
                  ),
              );
              self.deleteFromTo(
                undefined,
                (word, etc) => {
                  if(etc?.nextWord !== 'Дата i час') return false;
                  //Період з 01.11.2023 по 30.11.2023
                  const splitedWord = word.split(' ');
                  if(splitedWord.length !== 5) return false;
                  if(
                    splitedWord[0] === 'Період' &&
                    splitedWord[1] === 'з' &&
                    moment(splitedWord[2], 'DD.MM.YYYY', true).isValid() &&
                    splitedWord[3] === 'по' &&
                    moment(splitedWord[4], 'DD.MM.YYYY', true).isValid()
                  ) return true;
                  return false;
                },
                1,
              );
              self.deleteFromTo(
                (word, etc) => (word === 'Всього надійшло:' && !isNaN(Number(etc?.nextWord?.replaceAll(',', '')))),
                undefined,
                1,
              );
              self.defineOperation([
                (value) => true//moment(value, 'DD.MM.YYYY', true).isValid(),
              ]);
            },
          },
        },
      },
    ],
  };
}
