import { makeStyles } from '@material-ui/core/styles';

import { alto, aquaHaze, flamingo } from '../../theme/colors';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
    cursor: 'pointer',
    '&:hover': {
      background: aquaHaze,
      borderRadius: 8,
    },
    '&:last-of-type': {
      marginBottom: 24,
    },
  },
  link: {
    marginBottom: 20,
    opacity: 0.3,
  },
  dragRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    borderStyle: 'dashed',
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.16)',
    marginBottom: 16,
    position: 'relative',
  },
  icon: {
    marginRight: 22,
  },
  questionIcon: {
    marginRight: 22,
    width: 20,
    height: 20,
    opacity: 0.5,
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
  },
  button: {
    marginBottom: 0,
  },
  dropButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    background: 'rgba(0, 0, 0, 0.06)',
    padding: '10px 24px',
    width: 172,
    cursor: 'pointer',
    '&:hover': {
      background: alto,
    },
  },
  dropTitle: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  smallText: {
    fontSize: 13,
    lineHeight: '20px',
  },
  margin8: {
    marginBottom: 8,
  },
  margin32: {
    marginBottom: 32,
  },
  dropIcon: {
    marginRight: 10,
  },
  errorText: {
    color: flamingo,
    marginBottom: 24,
    transition: 'margin 700ms',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 12,
    padding: '8px 10px',
    border: '1px solid rgba(0, 0, 0, 0.08)',
    marginBottom: 25,
    width: 'fit-content',
  },
  progress: {
    width: 'fit-content',
    background: 'rgba(0, 0, 0, 0.08)',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  smallDownloadIcon: {
    width: 16,
    height: 16,
    marginRight: 14,
    cursor: 'pointer',
  },
  smallCloseIcon: {
    width: 8,
    height: 8,
    cursor: 'pointer',
    marginLeft: 12,
  },
  fileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 250,
  },
  availableImportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 32,
    marginBottom: 32,
  },
  availableImportsRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  availableImportsTitle: {
    marginBottom: 10,
  },
  availableImport: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 40,
  },
  formatIcon: {
    marginRight: 8,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 13,
  },
  separator: {
    marginBottom: 16,
  },
});
