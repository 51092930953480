import parse from 'csv-parse/lib/sync';
import moment from 'moment';
import xlsx from 'xlsx';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class SantanderCsvParser extends BaseXlsxParser {
  protected COLUMNS_MAP = {
    date: [['date'], null],
    comment: [['comment'], null],
    sum: [['sum'], null],
    counterparty: [['counterparty'], null],
    debet: [['debet'], null],
    credit: [['credit'], null],
  };

  protected importName = 'Santander';
  protected beforeTransformed: AnyObject[];
  protected currency = 'PLN';
  private mainAccount: Account;
  private docType;

  public import(acounts: AnyObject[]) {
    this.acounts = acounts;
    this.beforeTransformed = this.doBeforeTranform();
    this.transformedData = this.beforeTransformed
      .map(this.transformOne.bind(this))
      .filter((res) => Object.keys(res).length);

    return this.doAfterTranform();
  }

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.values(this.headersJson);

    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames.includes(h))
          return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
      }
    });

    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, index): AnyObject {
    const currentColumns = this.getAllColumns(operation, index);

    try {
      const isAfter = moment(currentColumns.date).isAfter(
        moment().add(10, 'y'),
      );
      const isBefore = moment(currentColumns.date).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      if (isAfter || isBefore) return {};
    } catch (e) {
      console.log(e);
    }

    const { type, subType, accountFromId, accountToId, sum } =
      this.getTypeAndSum(currentColumns);

    const resultObject = {
      index,
      date: this.dateToFormat2(this.dateParser(currentColumns.date)),
      sum,
      type,
      subType,
    };
    this.addIfNotFalsy(resultObject, {
      accountFromId,
      accountToId,
      counterparty: currentColumns.counterparty,
    });

    this.setComments(resultObject, [currentColumns.comment]);
    this.setAccountIDsByType(resultObject, this.currency, this.currency);

    return resultObject;
  }

  private getTypeAndSum(currentColumns: AnyObject) {
    const resObj = {
      sum: undefined,
      type: undefined,
      subType: undefined,
      accountFromId: undefined,
      accountToId: undefined,
    };

    const consumptionType = {
      type: OperationType.CONSUMPTION,
      subType: 'supplier',
      accountFromId: this.mainAccount.normalizedLabel,
    };

    const incomeType = {
      type: OperationType.INCOME,
      subType: 'sale',
      accountToId: this.mainAccount.normalizedLabel,
    };

    const { integerPartOfSum, fractionalPartOfSum } = currentColumns;

    if (this.docType === 'docType1' || this.docType === 'docType3') {
      const sum = currentColumns.sum.split(',').join('.');

      if (sum > 0) {
        Object.assign(resObj, incomeType, { sum: Math.abs(sum) });
      } else {
        Object.assign(resObj, consumptionType, { sum: Math.abs(sum) });
      }
    } else if (this.docType === 'docType2') {
      if (currentColumns.debet) {
        const sum = this.parseSum(currentColumns.debet);
        Object.assign(resObj, incomeType, { sum });
      } else if (currentColumns.credit) {
        const sum = this.parseSum(currentColumns.credit);
        Object.assign(resObj, consumptionType, { sum });
      }
    }

    return resObj;
  }

  public setRawData(raw, account) {
    const buffer = Buffer.from(raw);
    this.mainAccount = account;

    this.docType = this.setDocType(buffer);
    this.headersJson = this.setHeaders();
    this.sheetJson = this.setSheetJson(buffer);
    // console.log(this.sheetJson);
  }

  private setDocType(buffer) {
    const docTypeMap = new Map([
      ['ABCDEFGH', 'docType1'],
      ['ABCFGJKMNO', 'docType2'],
      ['ABCDEH', 'docType3'],
    ]);

    const wb = xlsx.read(buffer, { type: 'buffer', cellDates: true });
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const [headersJson] = xlsx.utils.sheet_to_json(sheet, { header: 'A' });

    const headerType = Object.keys(headersJson).join('');

    return docTypeMap.get(headerType);
  }

  private setHeaders() {
    const headersForDoc = {
      docType1: {
        A: 'date',
        B: 'unuse',
        C: 'comment',
        D: 'counterparty',
        E: 'unuse',
        F: 'sum',
      },
      docType2: {
        A: 'unuse',
        B: 'unuse',
        C: 'date',
        D: 'comment',
        E: 'counterparty',
        F: 'unuse',
        G: 'unuse',
        H: 'unuse',
        I: 'unuse',
        J: 'unuse',
        K: 'credit',
        L: 'debet',
      },
      docType3: {
        A: 'unuse',
        B: 'date',
        C: 'comment',
        D: 'counterparty',
        E: 'unuse',
        F: 'sum',
      },
    };

    return headersForDoc[this.docType];
  }

  private setDelimiter(buffer) {
    const data = String.fromCharCode.apply(null, new Uint8Array(buffer));
    const delimiter = /(",|[\d-]{10},)/.test(data) ? ',' : ';'; // check coma-delimiter after date-field or "
    return delimiter;
  }

  private setSheetJson(buffer) {
    const defaultOptions = {
      from_line: 2,
      cast: false,
      relax_column_count: true,
      relax: true,
      columns: UPPER_ALPHABET,
    };

    let typeOptions = {};

    switch (this.docType) {
      case 'docType1':
        typeOptions = { delimiter: this.setDelimiter(buffer) };
        break;
      case 'docType2':
        typeOptions = { from_line: 3 };
        break;
      case 'docType3':
        typeOptions = {};
        break;
      default:
        return [];
    }

    return parse(buffer, Object.assign(defaultOptions, typeOptions));
  }
}
