import { useCallback, useState } from 'react';

function usePreviewRegister() {
  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const onClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const onOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  return {
    showPreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
  };
}

export default usePreviewRegister;
