import { Typography } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import InfoIcon from '../../../assets/images/svg/info.svg';
import CustomButton from '../../../components/Button';
import Dialog from '../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import accountsApi from '../../../services/accounts';
import westernBidApi from '../../../services/bankIntegrations/westernBid';
import accountActions from '../../../store/accounts/actions';
import { selectUSDCurrencyId } from '../../../store/currency/selectors';
import integrationActions from '../../../store/integrations/actions';
import { CreateIntegrationPayload } from '../../../store/integrations/sagas.types';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { showError } from '../../../utils/showError';
import CalendarComponent from '../../Operations/Components/CalendarComponent/CalendarComponent';
import { useStyles } from './styles';

type Props = {
  newAccountName?: string;
  selectedAccountId?: string;

  onClose(): void;
  onCloseAccountsDialog?(): void;
};

const maxIntegrationDate =
  moment().subtract(1, 'day').startOf('day').valueOf() + getTimeOffset();

function WesternBidDialog(props: Props) {
  const { onClose, newAccountName, selectedAccountId, onCloseAccountsDialog } =
    props;

  const classes = useStyles();

  const usdCurrencyId = useSelector(selectUSDCurrencyId);
  const { t } = useTranslation();

  const facebookContext = useContext(FacebookPixelContext);

  const dispatch = useDispatch();

  const [isFlipped, setIsFlipped] = useState(true);
  const [selectedDate, setSelectedDate] = useState(maxIntegrationDate);
  const [loginErrorText, setLoginErrorText] = useState('');
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiKeyError, setApiKeyError] = useState(false);

  const handleChangeDate = useCallback((value: any) => {
    setSelectedDate(value);
  }, []);

  const handleChangeLogin = useCallback((value: string) => {
    setLogin(value);
    setLoginError(false);
  }, []);

  const handleChangeApiKey = useCallback((value: string) => {
    setApiKey(value);
    setApiKeyError(false);
  }, []);

  const handleClose = useCallback(() => {
    if (onCloseAccountsDialog) {
      onCloseAccountsDialog();
    } else {
      onClose();
    }
  }, [onCloseAccountsDialog, onClose]);

  const handleCloseCalendar = useCallback(() => {
    setIsFlipped(!isFlipped);
  }, [isFlipped]);

  const handleSetCredentials = useCallback(
    (integrationId: string) => {
      dispatch(integrationActions.setWesternBidIntegrationId(integrationId));
    },
    [dispatch],
  );

  const handleCreateCredentials = useCallback(async () => {
    setLoginErrorText('');

    if (!login) {
      setLoginError(true);
      return;
    }

    if (!apiKey) {
      setApiKeyError(true);
      return;
    }

    setLoading(true);

    try {
      const {
        data: {
          credentials: { _id },
        },
      } = await westernBidApi.getCredentials(apiKey, login);
      handleSetCredentials(_id);
    } catch (error) {
      handleSetCredentials('');
      setLoginErrorText(t('errors.apiKeyLoginError'));
      showError(error);
    }

    setLoading(false);
    if (selectedAccountId) {
      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.WesternBid,
        accountId: selectedAccountId,
        startDate: selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));
    } else if (newAccountName) {
      const {
        data: { accounts, newAccount },
      } = await accountsApi.createAccount(
        usdCurrencyId,
        newAccountName,
        0,
        IntegrationTypeId.WesternBid,
      );

      dispatch(accountActions.setLogAccounts(accounts));

      const accountId = newAccount._id;

      const integrationPayload: CreateIntegrationPayload = {
        facebookContext,
        typeId: IntegrationTypeId.WesternBid,
        accountId,
        startDate: selectedDate,
      };

      dispatch(integrationActions.createIntegration(integrationPayload));
    }

    handleClose();
  }, [
    dispatch,
    handleClose,
    selectedDate,
    usdCurrencyId,
    newAccountName,
    facebookContext,
    selectedAccountId,
    t,
    apiKey,
    login,
    handleSetCredentials,
  ]);

  return (
    <Dialog
      isOpened={isFlipped}
      onClose={handleCloseCalendar}
      title={t('integrations.title')}
    >
      <Typography className={classes.westernBidDescription}>
        {t('bank.westernBidDescription')}
      </Typography>
      <div className={classes.westernBidStepOneWrapper}>
        <Typography className={classes.westernBidStep}>
          {t('bank.westernBidStepOne')}
        </Typography>
        <div>
          <Typography className={classes.westernBidText}>
            {t('bank.westernBidStepOneTitle')}
          </Typography>
          <ol
            className={cn(
              classes.westernBidStepOneList,
              classes.westernBidText,
            )}
          >
            <li>{t('bank.westernBidStepOneListOne')}</li>
            <li>{t('bank.westernBidStepOneListTwo')}</li>
          </ol>
        </div>
        <TextFieldComponent
          value={apiKey}
          onChange={handleChangeApiKey}
          fullWidth
          placeholder={t('bank.apiKey')}
          isError={apiKeyError}
          errorText={t('errors.reqField')}
        />
        <TextFieldComponent
          value={login}
          onChange={handleChangeLogin}
          fullWidth
          placeholder={t('bank.loginWesternBid')}
          isError={loginError}
          errorText={t('errors.reqField')}
        />
      </div>
      <div className={classes.westernBidStepTwoWrapper}>
        <Typography className={classes.westernBidStep}>
          {t('bank.westernBidStepTwo')}
        </Typography>
        <div>
          <Typography
            className={cn(classes.westernBidText, classes.marginBottom16)}
          >
            {t('bank.westernBidStepTwoTitle')}
          </Typography>
        </div>
      </div>
      <CalendarComponent
        onChange={handleChangeDate}
        placeholder={t('integrations.dateDescription')}
        value={selectedDate}
        fullWidth
        maxDate={new Date(maxIntegrationDate)}
        minDate={minOperationDate()}
        fromIntegration
      />
      <div className={classes.westernBidInfoWrapper}>
        <img src={InfoIcon} alt="info icon" />
        <Typography className={classes.westernBidInfoText}>
          {t('bank.westernBidInfo')}
        </Typography>
      </div>
      <CustomButton
        title={t('bank.set')}
        action={handleCreateCredentials}
        fullWidth
        loading={loading}
        errorText={loginErrorText}
      />
    </Dialog>
  );
}

export default React.memo(WesternBidDialog);
