import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
// @ts-ignore
import CreatableSelect from 'react-select/creatable';
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';

import PreviewRegisterDialog from '../../../../components/PreviewRegisterDialog';
import usePermissions from '../../../../hooks/usePermissions';
import usePreviewRegister from '../../../../hooks/usePreviewRegister';
import useSubscriptionActive from '../../../../hooks/useSubscriptionActive';
import { selectIsDemoCompany } from '../../../../store/company/selectors';
import { Tag } from '../../../../store/tags/types';
import { useStyles } from './styles';
import { Props } from './types';
import { components } from './Wrappers';

const formattedData = (data: Tag[] | null): any =>
  data?.reduce(
    (acc: any, d) => [
      ...acc,
      {
        value: d._id,
        label: d.label,
      },
    ],
    [],
  );

function Tags(props: Props) {
  const {
    values,
    onCreate,
    onChange,
    isCompare,
    difference,
    placeholder,
    selectedTags,
    allowedCreate,
    disablePortal,
  } = props;
  const classes = useStyles({
    topOffset: disablePortal ? 0 : 60,
  });
  const { t } = useTranslation();
  const subscriptionActive = useSubscriptionActive();
  const { operationEnable } = usePermissions();

  const isDemoCompany = useSelector(selectIsDemoCompany);

  const {
    showPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
  } = usePreviewRegister();

  const formattedOptions = useMemo(() => formattedData(values), [values]);

  const ref = useRef<{ data: Tag[] | null }>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (values?.length) {
      if (!ref.current?.data?.length) {
        // @ts-ignore
        ref.current = { data: values };
      } else {
        let element = false;
        for (let i = 0; i <= values?.length - 1; i += 1) {
          element = ref.current.data.some((d) => d._id === values[i]._id);

          if (!element) {
            if (selectedTags) {
              onChange([
                ...selectedTags,
                {
                  value: values[i]._id,
                  label: values[i].label,
                },
              ]);
            } else {
              onChange([
                {
                  value: values[i]._id,
                  label: values[i].label,
                },
              ]);
            }

            break;
          }
        }
      }
    }

    // @ts-ignore
    ref.current = { data: values };
  }, [values, selectedTags, onChange]);

  const formatCreateLabel = useCallback(
    (inputValue: string) => (
      <Typography variant="subtitle1">
        {`${t('common.create')} "${inputValue}"`}
      </Typography>
    ),
    [t],
  );

  const handleChange = useCallback(
    (value: any | any[]) => {
      if (value) {
        onChange([...value]);
      } else {
        onChange([]);
      }
    },
    [onChange],
  );

  const handleCreate = useCallback(
    (name: string) => {
      if (isDemoCompany) {
        onOpenPreviewRegisterDialog();
      } else {
        onCreate(name);
      }
    },
    [onCreate, isDemoCompany, onOpenPreviewRegisterDialog],
  );

  const menuListProps = useCallback(
    (menuListStylesProps: MenuListProps) => ({
      ...menuListStylesProps,
      maxHeight: 216,
      paddingBottom: 16,
      paddingTop: 16,
    }),
    [],
  );

  const Component = allowedCreate ? CreatableSelect : Select;

  return (
    <>
      <div
        id="cypress-tags-id"
        ref={componentRef}
        style={{ position: 'relative' }}
      >
        <Component
          isCompare={isCompare}
          isDisabled={
            (!allowedCreate && !values?.length) ||
            isCompare ||
            !subscriptionActive ||
            !operationEnable
          }
          isMulti
          fullWidth
          name="tags"
          classes={classes}
          menuPosition={disablePortal ? 'fixed' : 'absolute'}
          menuPlacement="bottom"
          menuPortalTarget={componentRef.current}
          placeholder={
            !selectedTags?.length && (
              <div className={classes.placeholder}>
                {placeholder || t('operationDialogs.tags.inputLabel')}
              </div>
            )
          }
          isClearable={false}
          value={selectedTags}
          onChange={handleChange}
          components={components}
          className={cn(
            classes.root,
            difference && classes.compareRoot,
            (!subscriptionActive || isCompare || !operationEnable) &&
              classes.disabledInput,
          )}
          options={formattedOptions}
          onCreateOption={handleCreate}
          formatCreateLabel={formatCreateLabel}
          styles={{
            placeholder: (base: any) => ({ ...base, margin: 0 }),
            valueContainer: (base: any) => ({ ...base, padding: '2px 16px' }),
            // @ts-ignore
            menuList: menuListProps,
            menuPortal: (base: any) =>
              disablePortal
                ? { ...base, zIndex: 99999, marginTop: 8 }
                : { zIndex: 99999, marginTop: 8 },
            control: () => ({
              width: 416,
              display: 'flex',
            }),
          }}
          t={t}
        />
      </div>
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog onClose={onClosePreviewRegisterDialog} />
      )}
    </>
  );
}

export default React.memo(Tags);
