import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  lastMargin: {
    marginBottom: 24,
  },
  placeholder: {
    opacity: 0.5
  },
  button: {
    background: 'rgba(255, 99, 51, 0.1)',
    '&:hover': {
      background: 'rgba(255, 99, 51, 0.1)',
    },
  }
});
