import {
  Button,
  FormControlLabel,
  Input,
  MenuItem,
  MenuList,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../../../components/Dialog/Dialog';
import PopperComponent from '../../../../components/PopperComponent';
import { persianGreen } from '../../../../theme/colors';
import { getTimeOffset } from '../../../../utils/dateToUTC';
import CalendarDialog from '../CalendarComponent';
import { periods, smallDaysArray } from './constants';
import { useStyles } from './OthersDialog.styles';
import {
  FinishProps,
  OtherPayloadProps,
  Period,
  Props,
} from './OthersDialog.types';
import StyledRadio from './StyledRadio';

const defaultDate = new Date().getTime() + getTimeOffset();

function OthersDialog(props: Props) {
  const { onClose, onSave, data, incomeDate } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const anchorRef = useRef<HTMLDivElement>(null);

  const [interval, setInterval] = useState(1);
  const [scheduleMaxCount, setScheduleMaxCount] = useState(1);
  const [selectedFinish, setSelectedFinish] = useState<FinishProps>(
    FinishProps.never,
  );
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [calendarValue, setCalendarValue] = useState<number | number[]>(
    defaultDate,
  );
  const [period, setPeriod] = useState(periods[0]);
  const [selectedDays, setSelectedDays] = useState<string[]>([
    String(new Date(incomeDate).getDay()),
  ]);

  const [showPeriod, setShowPeriod] = useState(false);

  const handleChangeInterval = useCallback((event: any) => {
    const { value } = event.target;

    if (value > 0) {
      setInterval(Number(value));
    }
  }, []);

  const handleChangeMaxCount = useCallback((event: any) => {
    const { value } = event.target;

    if (value > 0) {
      setScheduleMaxCount(Number(value));
    }
  }, []);

  const handleTogglePeriod = useCallback(() => {
    setShowPeriod(!showPeriod);
  }, [showPeriod]);

  const handleClosePeriod = useCallback(() => {
    setShowPeriod(false);
  }, []);

  const handleToggleSelectedDay = useCallback(
    (event: any) => {
      const { id } = event.target;

      if (selectedDays.includes(id)) {
        setSelectedDays((days) => days.filter((day) => day !== id));
      } else {
        setSelectedDays((days) => [...days, id]);
      }
    },
    [selectedDays],
  );

  const handleCloseCalendar = useCallback(() => {
    setCalendarOpen(false);
  }, []);

  const handleOpenCalendar = useCallback(() => {
    if (selectedFinish === 'date') {
      setCalendarOpen(true);
    }
  }, [selectedFinish]);

  const handleChangeDate = useCallback(
    (date: number | number[]) => {
      setCalendarValue(date);
      handleCloseCalendar();
    },
    [handleCloseCalendar],
  );

  const handleChangeFinish = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedFinish(event.target.value as FinishProps);
    },
    [],
  );

  const handleChangePeriod = useCallback(
    (value: Period) => {
      setPeriod(value);
      handleClosePeriod();
    },
    [handleClosePeriod],
  );

  const calendarRow = calendarValue
    ? moment(calendarValue as any).format('DD.MM.YYYY')
    : '';

  const renderOption = useCallback(
    (value: Period) => {
      if (period.id === value.id) {
        return (
          <div className={classes.selectedOptionContainer}>
            <Typography className={classes.selectedOptionText}>
              {value.name}
            </Typography>
            <CheckIcon color={persianGreen} />
          </div>
        );
      }

      return (
        <div className={classes.selectedOptionContainer}>{value.name}</div>
      );
    },
    [period, classes],
  );

  const handleSave = useCallback(() => {
    const payload = {
      scheduleInterval: interval,
      scheduleIntervalType: period.id,
      scheduleDays: selectedDays.length
        ? selectedDays.join(',')
        : `${new Date(incomeDate).getDay()}`,
    } as OtherPayloadProps;

    if (selectedFinish === FinishProps.date) {
      if (Array.isArray(calendarValue)) {
        // eslint-disable-next-line prefer-destructuring
        payload.scheduleEndDate = calendarValue[0];
      } else {
        payload.scheduleEndDate = calendarValue;
      }
    } else if (selectedFinish === FinishProps.after) {
      payload.scheduleMaxCount = scheduleMaxCount;
    }

    onSave(payload);
    onClose();
  }, [
    onSave,
    onClose,
    period,
    interval,
    incomeDate,
    selectedDays,
    calendarValue,
    selectedFinish,
    scheduleMaxCount,
  ]);

  useEffect(() => {
    if (data) {
      const {
        scheduleDays,
        scheduleEndDate,
        scheduleInterval,
        scheduleIntervalType,
        scheduleMaxCount: dataScheduleMaxCount,
      } = data;
      const localPeriod = periods.find((p) => p.id === scheduleIntervalType);

      if (localPeriod) {
        setPeriod(localPeriod);
      }

      if (scheduleDays) {
        setSelectedDays(scheduleDays.split(','));
      }

      if (scheduleInterval) {
        setInterval(scheduleInterval);
      }

      if (dataScheduleMaxCount) {
        setScheduleMaxCount(dataScheduleMaxCount);
        setSelectedFinish(FinishProps.after);
      } else if (scheduleEndDate) {
        setCalendarValue(scheduleEndDate);
        setSelectedFinish(FinishProps.date);
      }
    }
  }, [data]);

  return (
    <>
      <Dialog isOpened onClose={onClose} title={t('customPeriod.other.title')}>
        <Typography className={classes.subTitle}>
          {t('customPeriod.repeatWithInterval')}
        </Typography>
        <div className={classes.repeatContainer}>
          <div className={classes.repeatsSmallContainer}>
            <Input
              className={classes.inputContainer}
              type="number"
              value={interval}
              onChange={handleChangeInterval}
            />
          </div>
          <div
            className={classes.periodContainer}
            ref={anchorRef}
            onClick={handleTogglePeriod}
          >
            <Typography className={classes.periodText}>
              {period.name}
            </Typography>
            {showPeriod ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
          {showPeriod && (
            <PopperComponent
              onClose={handleClosePeriod}
              anchorRef={anchorRef}
              placement="bottom-start"
            >
              <MenuList className={classes.menuList}>
                {periods.map((el) => (
                  <MenuItem
                    onClick={() => handleChangePeriod(el)}
                    key={el.id}
                    value={el.id}
                  >
                    {renderOption(el)}
                  </MenuItem>
                ))}
              </MenuList>
            </PopperComponent>
          )}
        </div>
        <>
          {period.id === 2 && (
            <>
              <Typography className={classes.subTitle}>
                {t('customPeriod.daysOfRepeat')}
              </Typography>
              <div className={classes.displayFlexRow}>
                {smallDaysArray.map((day) => (
                  <div
                    id={day.id}
                    onClick={handleToggleSelectedDay}
                    className={cn(
                      classes.smallDay,
                      selectedDays.includes(day.id) && classes.smallDayActive,
                    )}
                    key={day.id}
                  >
                    {day.value}
                  </div>
                ))}
              </div>
            </>
          )}
        </>

        <Typography className={classes.subTitle}>
          {t('customPeriod.finish')}
        </Typography>
        <div className={classes.daysContainer} />

        <div className={classes.finishContainer}>
          <RadioGroup aria-label="position" name="position" defaultValue="top">
            <div className={classes.row}>
              <FormControlLabel
                value={FinishProps.never}
                control={
                  <StyledRadio
                    value={FinishProps.never}
                    checked={selectedFinish === FinishProps.never}
                    onChange={handleChangeFinish}
                  />
                }
                label={t('customPeriod.never')}
              />
            </div>
            <div className={classes.row}>
              <FormControlLabel
                value={FinishProps.date}
                control={
                  <StyledRadio
                    color="primary"
                    value={FinishProps.date}
                    checked={selectedFinish === FinishProps.date}
                    onChange={handleChangeFinish}
                  />
                }
                label={t('customPeriod.date')}
              />
              <div
                className={cn(
                  classes.repeatsContainer,
                  selectedFinish !== FinishProps.date && classes.disabled,
                )}
                onClick={handleOpenCalendar}
              >
                {calendarRow}
              </div>
            </div>
            <div className={classes.row}>
              <FormControlLabel
                className={classes.rootRadio}
                value={FinishProps.after}
                control={
                  <StyledRadio
                    color="primary"
                    value={FinishProps.after}
                    checked={selectedFinish === FinishProps.after}
                    onChange={handleChangeFinish}
                  />
                }
                label={t('customPeriod.after')}
              />
              <div
                className={cn(
                  classes.repeatsContainer,
                  selectedFinish !== FinishProps.after && classes.disabled,
                )}
              >
                <Input
                  disabled={selectedFinish !== FinishProps.after}
                  className={classes.inputContainer}
                  type="number"
                  value={scheduleMaxCount}
                  onChange={handleChangeMaxCount}
                  classes={{
                    input: classes.input,
                  }}
                />
                <span>{t('customPeriod.repeats').toLowerCase()}</span>
              </div>
            </div>
          </RadioGroup>
        </div>

        <div className={classes.footer}>
          <Button
            fullWidth
            className={classes.buttonContainer}
            onClick={handleSave}
          >
            <Typography className={classes.buttonText}>
              {t('common.apply')}
            </Typography>
          </Button>
          <Button
            fullWidth
            className={cn(
              classes.buttonContainer,
              classes.cancelButtonContainer,
            )}
            onClick={onClose}
          >
            <Typography className={classes.buttonText}>
              {t('common.reject')}
            </Typography>
          </Button>
        </div>
      </Dialog>

      {isCalendarOpen && (
        <CalendarDialog
          // @ts-ignore
          value={new Date(calendarValue - getTimeOffset())}
          onChange={handleChangeDate}
          onClose={handleCloseCalendar}
        />
      )}
    </>
  );
}

export default React.memo(OthersDialog);
