import Http from '../Http';
import { GetIntegrationIdPayload } from './facturownia.types';

const getSellers = (token: string, userDomain: string) =>
  Http.makeInstance().post(
    'integrations/fakturowniaApiCredentials/getAllSellers',
    {
      token,
      userDomain,
    },
  );

const setTokenAndUserDomain = (data: GetIntegrationIdPayload) => {
  const { token, userDomain, sellerBankAccount, sellerName } = data;

  return Http.makeInstance().post(
    '/integrations/fakturowniaApiCredentials/setTokenAndUserDomain',
    {
      token,
      userDomain,
      sellerBankAccount,
      sellerName,
    },
  );
};

export default {
  getSellers,
  setTokenAndUserDomain,
};
