import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SHOW_VIDEO_LINKS } from '../constants/featureToggles/featureToggle';
import { getLanguage } from '../selectors/main';
import useUnleashGetVariant from './useUnleashGetVariant';

export enum HelperPages {
  employees = 'employees',
  newAccount = 'newAccount',
  differenceAccount = 'differenceAccount',
  calendar = 'calendar',
  profitAndLoss = 'profitAndLoss',
  cashFlow = 'cashFlow',
  settingsIncome = 'settingsIncome',
  settingsConsumption = 'settingsConsumption',
  autoRules = 'autoRules',
  historyActions = 'historyActions',
  importExcel = 'importExcel',
  projects = 'projects',
  debit = 'debit',
  credit = 'credit',
  invoices = 'invoices',
  log = 'log',
}

type VariantProps = {
  link: string;
  label: string;
  enabled: boolean;
  title: string;
};

type Variant = {
  [p in HelperPages]: {
    [l in string]: VariantProps;
  };
};

function useHelpVideoLinks() {
  const [variant, setVariant] = useState<Variant | null>(null);

  const language = useSelector(getLanguage);

  const showVideoLinksVariants = useUnleashGetVariant(SHOW_VIDEO_LINKS);
  const showVideoLinks = !!showVideoLinksVariants?.enabled;
  const showVideoLinksPayload = showVideoLinksVariants?.payload;

  const getHelperProps = useCallback(
    (page?: HelperPages): VariantProps => {
      if (!variant || !page) {
        return { link: '', enabled: false, label: '', title: '' };
      }

      const pageVariant = variant[page];

      if (!pageVariant) {
        return { link: '', enabled: false, label: '', title: '' };
      }

      return (
        pageVariant[language] ?? {
          link: '',
          enabled: false,
          label: '',
          title: '',
        }
      );
    },
    [variant, language],
  );

  useEffect(() => {
    if (showVideoLinksPayload) {
      try {
        const value = JSON.parse(showVideoLinksPayload.value) as Variant;

        if (value) {
          setVariant(value);
        }
      } catch (e) {
        setVariant(null);
      }
    }
  }, [showVideoLinksPayload]);

  return {
    getHelperProps,
    showVideoLinks,
  };
}

export default useHelpVideoLinks;
