import i18next from 'i18next';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  ONBOARDING_STATUS,
  ONBOARDING_SUB_STEPS,
  OnboardingStepInfo,
} from '@finmap/core-constants';

import MsgToast from '../components/MsgToast';
import {
  DELAY_UPDATE_LOG,
  INTEGRATION_TOAST_ID,
  UPDATE_OPERATIONS_TOAST_ID,
} from '../constants';
import { Routes } from '../constants/routes';
import Http from '../services/Http';
import { GET_LOG_ACCOUNTS_PENDING } from '../store/accounts/actions';
import { AccountValue } from '../store/accounts/types';
import {
  RESET_INTEGRATION_ACCOUNT,
  RESET_INTEGRATION_IN_PROGRESS,
} from '../store/integrations/actions';
import { GET_JOURNAL_PENDING } from '../store/journal/actions';
import journalApi from '../store/journal/api';
import { JournalData } from '../store/journal/types';
import { onboardingActionTypes } from '../store/onboardingV2/actions';
import store from '../store/store';
import { declensions } from '../utils/declensions';
import { showError } from '../utils/showError';

export const updateOperationsToast = (count: number) => {
  const paymentText = declensions(count, [
    i18next.t('toasts.import.declensions.first'),
    i18next.t('toasts.import.declensions.second'),
    i18next.t('toasts.import.declensions.third'),
  ]);

  const updateText = declensions(count, [
    i18next.t('toasts.update.declensions.first'),
    i18next.t('toasts.update.declensions.second'),
    i18next.t('toasts.update.declensions.third'),
  ]);

  toast.update(UPDATE_OPERATIONS_TOAST_ID, {
    render: `${count} ${paymentText} ${i18next.t(
      'toasts.successfully',
    )} ${updateText}😎`,
    hideProgressBar: false,
    autoClose: 5000,
  });
};

async function checkOnboardingIntegration(onboardingStep: OnboardingStepInfo) {
  try {
    if (window.location.pathname === Routes.ON_BOARDING) {
      const { data: logCount } = await journalApi.getOperationsCount();

      if (logCount) {
        store.dispatch({ type: RESET_INTEGRATION_IN_PROGRESS });

        window.location.href = Routes.LOG;
      } else {
        setTimeout(async () => {
          const { data: logCountRepeat } =
            await journalApi.getOperationsCount();

          if (logCountRepeat) {
            window.location.href = Routes.LOG;

            store.dispatch({ type: RESET_INTEGRATION_IN_PROGRESS });
          } else {
            store.dispatch({
              type: onboardingActionTypes.UPDATE_ONBOARDING_V2_STEP,
              payload: {
                ...onboardingStep,
                stepSubCategory: ONBOARDING_SUB_STEPS.INTEGRATION_ADD_OPERATION,
                status: ONBOARDING_STATUS.ACTIVE,
              },
            });
            store.dispatch({ type: RESET_INTEGRATION_IN_PROGRESS });
          }
        }, DELAY_UPDATE_LOG);
      }
    } else {
      store.dispatch({ type: RESET_INTEGRATION_IN_PROGRESS });
    }
  } catch (error) {
    showError(error);
  }
}

export const startIntegrationInterval = async (
  accountId: string,
  bankName: string,
  onboardingStep: OnboardingStepInfo,
) => {
  const setIntegrationInterval = setInterval(async () => {
    const { data: accounts } = await Http.makeInstance().get(
      '/accounts?context=settings',
    );
    const integratedAccount: AccountValue = accounts.find(
      (el: AccountValue) => el._id === accountId,
    );

    if (!integratedAccount) {
      clearInterval(setIntegrationInterval);
    } else {
      const { integration } = integratedAccount;

      if (integration) {
        const { loaderDone, loaderState } = integration;

        const msgPercentTextDone = i18next.t('toasts.integrationDone', {
          postProcess: 'sprintf',
          sprintf: [bankName],
        });

        if (loaderDone) {
          toast.update(`${INTEGRATION_TOAST_ID}_${accountId}`, {
            render: msgPercentTextDone,
            autoClose: 5000,
            hideProgressBar: false,
          });

          setTimeout(() => {
            store.dispatch({
              type: RESET_INTEGRATION_ACCOUNT,
              payload: { id: accountId },
            });
            store.dispatch({ type: GET_LOG_ACCOUNTS_PENDING });
            store.dispatch({ type: GET_JOURNAL_PENDING });
          }, 1000);

          clearInterval(setIntegrationInterval);

          if (onboardingStep) {
            await checkOnboardingIntegration(onboardingStep);
          } else {
            store.dispatch({ type: RESET_INTEGRATION_IN_PROGRESS });
          }
        } else {
          const msgPercentTextInProgress = i18next.t(
            'toasts.integrationInProgress',
            {
              postProcess: 'sprintf',
              sprintf: [loaderState, '%', bankName],
            },
          );

          toast.update(`${INTEGRATION_TOAST_ID}_${accountId}`, {
            render: <MsgToast title={msgPercentTextInProgress} />,
            autoClose: false,
            hideProgressBar: false,
          });
        }
      } else {
        clearInterval(setIntegrationInterval);
      }
    }
  }, 1000);
};

export const sortByTime = (a: JournalData, b: JournalData) => {
  const firstDate = moment(a.date).unix();
  const secondDate = moment(b.date).unix();

  return firstDate > secondDate ? 1 : -1;
};

export function buildQueryRow(...rest: string[]) {
  const query = rest.reduce((acc: string[], el) => {
    if (el) {
      acc.push(el);
    }

    return acc;
  }, []);

  return query.join('&');
}
