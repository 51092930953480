import { FutureButtonState } from '../../scenes/InfoBlock/Journal/JournalContainer/types';
import { UPDATE_FUTURE_DATE_FILTER } from '../filters/actions';
import { FiltersState } from '../filters/types';
import { GetJournalPayload, LoadMorePayload } from './sagas.types';
import { JournalData } from './types';

export const JOURNAL_INIT = 'JOURNAL_INIT';

export const REMOVE_AND_UPDATE_ATTACHMENTS = 'REMOVE_AND_UPDATE_ATTACHMENTS';

export const GET_LOG_CONTEXT = 'GET_LOG_CONTEXT';

export const UPDATE_ONE_LOG_OPERATION = 'UPDATE_ONE_LOG_OPERATION';

export const UPDATE_GROUP_LOG_OPERATIONS = 'UPDATE_GROUP_LOG_OPERATIONS';

export const SET_IS_APPROVED_FUTURE = 'SET_IS_APPROVED_FUTURE';

export const GET_JOURNAL_OPERATIONS_COUNT_PENDING =
  'GET_JOURNAL_OPERATIONS_COUNT_PENDING';
export const GET_JOURNAL_OPERATIONS_COUNT_SUCCESS =
  'GET_JOURNAL_OPERATIONS_COUNT_SUCCESS';

export const UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS =
  'UPDATE_JOURNAL_AFTER_REMOVE_OPERATIONS';
export const SET_SORT_PARAMS = 'SET_SORT_PARAMS';

export const GET_JOURNAL_PENDING = 'GET_JOURNAL_PENDING';
export const GET_JOURNAL_SUCCESS = 'GET_JOURNAL_SUCCESS';

export const GET_JOURNAL_LOAD_MORE_PENDING = 'GET_JOURNAL_LOAD_MORE_PENDING';
export const GET_JOURNAL_LOAD_MORE_SUCCESS = 'GET_JOURNAL_LOAD_MORE_SUCCESS';

export const GET_FUTURE_JOURNAL_PENDING = 'GET_FUTURE_JOURNAL_PENDING';
export const GET_FUTURE_JOURNAL_SUCCESS = 'GET_FUTURE_JOURNAL_SUCCESS';

export const SET_SHOW_FUTURE_PAYMENTS = 'SET_SHOW_FUTURE_PAYMENTS';
export const RESET_JOURNAL_LOADING = 'RESET_JOURNAL_LOADING';

export const RESET_FUTURE_JOURNAL = 'RESET_FUTURE_JOURNAL';

export const UPDATE_ONE_FUTURE_OPERATION = 'UPDATE_ONE_FUTURE_OPERATION';
export const UPDATE_JOURNAL_SPECIFIC_OPERATIONS =
  'UPDATE_JOURNAL_SPECIFIC_OPERATIONS';

export const GET_FUTURE_PAYMENTS_LENGTH_PENDING =
  'GET_FUTURE_PAYMENTS_LENGTH_PENDING';
export const GET_FUTURE_PAYMENTS_LENGTH_SUCCESS =
  'GET_FUTURE_PAYMENTS_LENGTH_SUCCESS';

const updateJournal = (data?: GetJournalPayload) => ({
  type: GET_JOURNAL_PENDING,
  payload: data,
});

const loadMore = (data?: LoadMorePayload) => ({
  type: GET_JOURNAL_LOAD_MORE_PENDING,
  payload: data,
});

const getLogContext = (selector: keyof FiltersState) => ({
  type: GET_LOG_CONTEXT,
  payload: { selector },
});

const init = () => ({
  type: JOURNAL_INIT,
});

const setIsApprovedFuture = (value: boolean) => ({
  type: SET_IS_APPROVED_FUTURE,
  payload: { isApprovedFuture: value },
});

const setSortParams = (orders: any) => ({
  type: SET_SORT_PARAMS,
  payload: { orders },
});

const updateFutureJournal = (selector: keyof FiltersState) => ({
  type: GET_FUTURE_JOURNAL_PENDING,
  payload: { selector },
});
const setShowFuturePayments = (value: boolean) => ({
  type: SET_SHOW_FUTURE_PAYMENTS,
  payload: { value },
});
const updateFutureJournalDateFilter = (
  state: FutureButtonState,
  selector: keyof FiltersState = 'journal',
) => ({
  type: UPDATE_FUTURE_DATE_FILTER,
  payload: { state, selector },
});

const updateJournalSpecificOperations = (data: JournalData[]) => ({
  type: UPDATE_JOURNAL_SPECIFIC_OPERATIONS,
  payload: { items: data },
});

const removeAndUpdateAttachments = (
  operationId: string,
  attachmentId: string,
) => ({
  type: REMOVE_AND_UPDATE_ATTACHMENTS,
  payload: { operationId, attachmentId },
});

export default {
  init,
  loadMore,
  getLogContext,
  updateJournal,
  setSortParams,
  updateFutureJournal,
  setIsApprovedFuture,
  setShowFuturePayments,
  removeAndUpdateAttachments,
  updateFutureJournalDateFilter,
  updateJournalSpecificOperations,
};
