import i18n from 'i18next';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { OWNER_ROLE_ID, SUPPORT_EMAIL } from '../../../constants';
import usePermissions from '../../../hooks/usePermissions';
import useRestricted from '../../../hooks/useRestricted';
import FacebookPixelContext from '../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import Storages, { StorageKey } from '../../../services/Storages';
import authActions from '../../../store/auth/action';
import companyActions from '../../../store/company/actions';
import { selectCurrentCompany } from '../../../store/company/selectors';
import employeeActions from '../../../store/employees/actions';
import employeeApi from '../../../store/employees/api';
import { selectSupportEmployeeId } from '../../../store/employees/selectors';
import { isPreviewPage } from '../../../utils/isLocalhost';
import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

function useSettingsActions(settingsDeepLinks?: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { readAccess, showRestrictedDialog } = useRestricted();
  const facebookContext = useContext(FacebookPixelContext);
  const { settingsEnable } = usePermissions();

  const tabletDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1280),
  );

  const currentCompany = useSelector(selectCurrentCompany);
  const supportEmployeeId = useSelector(selectSupportEmployeeId);

  const showSubscriptionButton = !currentCompany?.demo && !isPreviewPage();

  const [showImportDialog, setShowImportDialog] = useState(
    settingsDeepLinks?.openImportsLink,
  );
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(
    settingsDeepLinks?.openTariffsLink,
  );
  const [showTelegramBotDialog, setShowTelegramBotDialog] = useState(false);

  const [showLngModal, setShowLngModal] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);

  const onOpenImportDialog = useCallback(() => {
    if (readAccess) {
      showRestrictedDialog();

      return;
    }

    setShowImportDialog(true);
  }, [readAccess, showRestrictedDialog]);

  const onCloseImportDialog = useCallback(() => {
    setShowImportDialog(false);
  }, []);

  const onOpenSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(true);
  }, []);

  const onCloseSubscriptionDialog = useCallback(() => {
    setShowSubscriptionDialog(false);
  }, []);

  const onOpenLngDialog = useCallback(() => {
    setShowLngModal(true);
  }, []);

  const onCloseLngDialog = useCallback(() => {
    setShowLngModal(false);
  }, []);

  const onGiveSupportAccess = useCallback(
    async (_event: {}, checked: boolean) => {
      if (checked) {
        await employeeApi.createInvite(OWNER_ROLE_ID, 'support', SUPPORT_EMAIL);
      } else if (supportEmployeeId) {
        await employeeApi.deleteEmployee(supportEmployeeId);
      }

      dispatch(employeeActions.loadEmployees());

      if (checked) {
        toast(t('toasts.giveAccessSuccessful'));
      } else {
        toast(t('toasts.switchOffAccessSuccessful'));
      }
    },
    [dispatch, t, supportEmployeeId],
  );

  const onOpenTelegramBotDialog = useCallback(() => {
    setShowTelegramBotDialog(true);
  }, []);

  const onCloseTelegramBotDialog = useCallback(() => {
    setShowTelegramBotDialog(false);
  }, []);

  const onLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const onSaveLng = useCallback(
    (lng: string) => {
      dispatch(companyActions.setLanguage({ lng }));

      Storages.put(StorageKey.language, lng);

      i18n.changeLanguage(lng);

      dispatch(authActions.userUpdateProfileLng({ facebookContext }));

      onCloseLngDialog();
    },
    [facebookContext, onCloseLngDialog, dispatch],
  );

  const onToggleSettingsPopup = useCallback(() => {
    if (settingsEnable && !showSettingsPopup && tabletDevice) {
      dispatch(companyActions.getSettingsContexts());
    }

    setShowSettingsPopup(!showSettingsPopup);
  }, [dispatch, showSettingsPopup, settingsEnable, tabletDevice]);

  const onCloseSettingsPopup = useCallback((event: any) => {
    const { id } = event.target;

    if (!id) {
      setShowSettingsPopup(false);
    }
  }, []);

  const onListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShowSettingsPopup(false);
    }
  }, []);

  return {
    onLogout,
    onSaveLng,
    showLngModal,
    onListKeyDown,
    onOpenLngDialog,
    onCloseLngDialog,
    showImportDialog,
    showSettingsPopup,
    onOpenImportDialog,
    onCloseImportDialog,
    onGiveSupportAccess,
    onCloseSettingsPopup,
    onToggleSettingsPopup,
    showTelegramBotDialog,
    showSubscriptionButton,
    showSubscriptionDialog,
    onOpenTelegramBotDialog,
    onOpenSubscriptionDialog,
    onCloseTelegramBotDialog,
    onCloseSubscriptionDialog,
  };
}

export default useSettingsActions;
