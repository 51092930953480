import { aquaHaze, persianGreen, white } from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 480,
    maxWidth: 'none',
    borderRadius: 24,
    overflowX: 'hidden',
    background: '#fff',
    textAlign: 'left',
    boxSizing: 'border-box',
    position: 'relative',
    '-webkit-appearance': 'none',
    [theme.breakpoints.down('md')]: {
      margin: 30,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 15,
    },
    '@media (max-width: 340px)': {
      margin: 5,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    lineHeight: '36px',
    marginBottom: 24,
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    marginBottom: 8,
  },
  item: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  income: {
    padding: '32px',
  },
  closeIcon: {
    position: 'absolute',
    top: 25,
    right: 25,
    width: 14,
    height: 14,
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  daysContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    width: 168,
  },
  cancelButtonContainer: {
    background: 'rgba(0, 0, 0, 0.06)',
  },
  rootRadio: {
    marginBottom: 30,
  },
  repeatContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  repeatsContainer: {
    width: 147,
    height: 40,
    borderRadius: 12,
    backgroundColor: aquaHaze,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 8,
  },
  repeatsSmallContainer: {
    width: 50,
    height: 40,
    borderRadius: 12,
    backgroundColor: aquaHaze,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 8,
    marginRight: 12,
  },
  inputContainer: {
    width: 50,
    marginRight: 10,
    marginTop: 0,
  },
  input: {
    padding: 0,
  },
  disabled: {
    background: white,
    border: '1px solid rgba(0, 0, 0, 0.08)',
    opacity: 0.2,
  },
  row: {
    display: 'flex',
    marginBottom: 28,
  },
  displayFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 26,
  },
  smallDay: {
    width: 36,
    height: 36,
    borderRadius: 12,
    background: aquaHaze,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  smallDayActive: {
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
  periodContainer: {
    background: aquaHaze,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 115,
    height: 40,
    paddingLeft: 16,
    paddingRight: 16,
  },
  periodText: {
    fontSize: 16,
    lineHeight: '22px',
    opacity: 0.5,
  },
  selectedOptionContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedOptionText: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  menuList: {
    minWidth: 200,
  },
}));
