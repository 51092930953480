import FinmapLogo from '../../../assets/images/svg/logo-finmap.svg';
import { NordigenBankType } from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';

export const testNordigenBanks: NordigenBankType[] = [
  {
    id: 'SANDBOXFINANCE_SFIN0000',
    logoLink: FinmapLogo,
    maxTakingDaysNumber: minOperationDate().getTime(),
    name: 'Demo Stage',
  },
];
