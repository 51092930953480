import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  signUpViaSocialNetworkWrapper: {},
  signUpViaSocialNetworkTitle: {
    position: 'relative',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#000000',
    opacity: 0.5,
    fontSize: 13,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 16,

    '&:before, &:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: 1,
      background: '#C4C4C4',
    },
  },
  socialNetworkWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
});
