import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginTop: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 5,
  },
  icon: {
    marginLeft: 27,
    cursor: 'pointer',
  },
  textBlock: {
    width: 324,
  },
});
