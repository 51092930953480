import './styles.scss';
import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback } from 'react';

import { black } from '../../theme/colors';
import TransitionComponent from '../TransitionComponent';
import { useStyles } from './styles';
import { Props } from './types';

function CustomButton(props: Props) {
  const {
    action,
    id,
    icon,
    title,
    loading,
    disabled,
    fullWidth,
    className,
    ga4TextId,
    iconClassName,
    errorText = '',
    textColor = black,
    wrapperRootClassName,
  } = props;
  const classes = useStyles();

  const handleClick = useCallback(
    (event: any) => {
      action(event);
    },
    [action],
  );

  return (
    <div className={cn(classes.root, wrapperRootClassName)}>
      <Button
        id={id}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={handleClick}
        className={cn(classes.button, className)}
      >
        {icon && <img src={icon} alt="union" className={iconClassName} />}
        <Typography
          id={ga4TextId}
          style={{ color: textColor }}
          className={classes.buttonText}
        >
          {title}
        </Typography>
        {loading && <div className="loader" />}
      </Button>
      <TransitionComponent enter={Boolean(errorText)} text={errorText} />
    </div>
  );
}

export default React.memo(CustomButton);
