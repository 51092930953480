import { makeStyles } from '@material-ui/core/styles';

import { outrageousOrange, persianGreen, white } from '../../../theme/colors';

export const useStyles = makeStyles({
  root: {
    maxWidth: 420,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonText: {
    color: white,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  separator: {
    border: '0.5px solid #000000',
    opacity: 0.2,
    marginBottom: 13,
  },
  lastSeparator: {
    marginTop: 13,
    marginBottom: 24,
  },
  buttonContainer: {
    background: 'linear-gradient(88.88deg, #000000 0%, #3D3D3D 100%)',
  },
  removeRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: outrageousOrange,
    marginTop: 23,
    cursor: 'pointer',
  },
  icon: {
    marginRight: 11,
    color: outrageousOrange,
  },
  removeText: {
    color: outrageousOrange,
  },
  cryptoAttention: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 16,
  },
  attentionIcon: {
    marginRight: 8,
    position: 'relative',
    top: 3,
  },
  halfOpacity: {
    opacity: 0.5,
  },
});
