import React from 'react';

import './input-eye.module.scss';
import OpenEye from '../../assets/images/svg/eye_gray_open.svg';
import CloseEye from '../../assets/images/svg/eye_gray_hide.svg';

export interface InputEyeProps {
  isOpen: boolean;
  onClick?: () => void;
  className?: string;
}

export function InputEye({
  className,
  isOpen,
  onClick,
}: InputEyeProps) {

  if(isOpen) 
    return <img
    src={OpenEye}
    alt="open"
    className={className}
    onClick={onClick}
  />

  return <img
  src={CloseEye}
  alt="open"
  className={className}
  onClick={onClick}
/>
}

export default InputEye;