export const SHOW_CZECH_LANGUAGE = 'showCzechLanguage';
export const SHOW_PORTUGUESE_LANGUAGE = 'showPortugueseLanguage';
export const SHOW_FRENCH_LANGUAGE = 'showFrenchLanguage';
export const SHOW_SPANISH_LANGUAGE = 'showSpanishLanguage';
export const SHOW_ITALIAN_LANGUAGE = 'showItalianLanguage';
export const SHOW_TURKISH_LANGUAGE = 'showTurkishLanguage';
export const SHOW_KAZAKH_LANGUAGE = 'showKazakhLanguage';
export const SHOW_RUSSIAN_LANGUAGE = 'showRussianLanguage';
export const SHOW_UZBEK_LANGUAGE = 'showUzbekLanguage';
export const SHOW_BRAZIL_LANGUAGE = 'showBrazilLanguage';
