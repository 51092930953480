import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class SantanderBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        async isCurCase(file: File) {
          const buffer = String.fromCharCode.apply(
            null,
            new Uint8Array(await file.arrayBuffer()),
          );
          return /^[\d-]{10};/.test(buffer);
        },
        proceedCase: (importDocument) => {
          const currency = importDocument[0][4];
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency,
            date: this.getFirstValidCellByColumn([1]),
            comment: this.getFirstValidCellByColumn([2]),
            counterparty: this.getFirstValidCellByColumn([3]),
            sum: this.getFirstValidCellByColumn([5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
      {
        async isCurCase(file: File) {
          const buffer = String.fromCharCode.apply(
            null,
            new Uint8Array(await file.arrayBuffer()),
          );
          return /^[\d-]{10},/.test(buffer);
        },
        proceedCase: (importDocument) => {
          const currency = importDocument[0][4];
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            currency,
            date: this.getFirstValidCellByColumn([1]),
            comment: this.getFirstValidCellByColumn([2]),
            counterparty: this.getFirstValidCellByColumn([3]),
            sum: this.getFirstValidCellByColumn([5]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: ',', simpleParse: true },
        },
      },
      {
        async isCurCase(file: File) {
          const buffer = String.fromCharCode.apply(
            null,
            new Uint8Array(await file.arrayBuffer()),
          );
          return /^\d{4}\/\d{3};\d{2}.\d{2}.\d{4};/.test(buffer);
        },
        proceedCase: (importDocument) => {
          const currency = importDocument[0][6];
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(2));
          return (): ImportResultItemMask => ({
            currency,
            date: this.getFirstValidCellByColumn([2]),
            comment: this.splitFields([3, 4]),
            //counterparty: this.getFirstValidCellByColumn([3]),
            debit: this.getFirstValidCellByColumn([10]),
            credit: this.getFirstValidCellByColumn([11]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: ';' },
        },
      },
      {
        proceedCase: (importDocument) => {
          const currency = importDocument[0][6];
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(2));
          return (): ImportResultItemMask => ({
            currency,
            date: this.getFirstValidCellByColumn([2]),
            comment: this.getFirstValidCellByColumn([3]),
            counterparty: this.getFirstValidCellByColumn([4]),
            debit: this.getFirstValidCellByColumn([10]),
            credit: this.getFirstValidCellByColumn([11]),
          });
        },
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: { CSVDelimiter: [';', ','] },
        },
      },
    ],
  };
}
