import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutoCompleteComponent from '../../../components/AutocompleteComponent';
import RemoveDialog from '../../../components/RemoveDialog/RemoveDialog';
import { clientPrefixBySubType } from '../../../constants';
import { NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW } from '../../../constants/featureToggles/featureToggle';
import { SHOW_ALERT_INTEGRATION_PAYMENT } from '../../../constants/featureToggles/integrationsFeatureToggles';
import useOnboardingV2 from '../../../hooks/useOnboardingV2';
import useUnleash from '../../../hooks/useUnleash';
import { SettingsName } from '../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import { selectCompanyCurrency } from '../../../store/company/selectors';
import { selectCryptoCurrencyCodes } from '../../../store/currency/selectors';
import {
  getHistoryOperationProps,
  getOperationProps,
} from '../../../store/operations/selectors';
import {
  OperationSubType,
  OperationType,
} from '../../../store/operations/types';
import { maxOperationDate, minOperationDate } from '../../../utils/dateFormat';
import { getTimeOffset } from '../../../utils/dateToUTC';
import useGetIsEditable from '../../Header/Settings/useGetIsEditable';
import AccountSelector from '../Components/AccountSelector';
import Additions from '../Components/Additions/Additions';
import AmountComponent from '../Components/Amount';
import CalendarComponent from '../Components/CalendarComponent/CalendarComponent';
import ConfirmChangeRepeatDialog from '../Components/ConfirmChangeRepeatDialog';
import ConfirmRemoveRepeatDialog from '../Components/ConfirmRemoveRepeatDialog';
import DateOfPaymentComponent from '../Components/DateOfPaymentComponent';
import FooterComponent from '../Components/FooterComponent';
import Repeat from '../Components/Repeat/Repeat';
import { SplitPaymentProjectDetailedInfo } from '../Components/SplittingPayment/types';
import operationsHOC from '../HOC';
import { Props as HOCProps } from '../HOC/types';
import { calculateSumAndCompanyCurrencySum } from '../HOC/utils';
import { useStyles } from './styles';

function IncomeComponentWithHOC(props: HOCProps) {
  const {
    tags,
    botId,
    isCopy,
    isEdit,
    client,
    account,
    repeats,
    clients,
    comments,
    projects,
    accounts,
    category,
    currency,
    editProps,
    isGeneric,
    isCompare,
    createTag,
    creditors,
    borrowers,
    investors,
    externalId,
    isNewState,
    changeTags,
    categories,
    difference,
    selectedTags,
    createClient,
    changeClient,
    integrationId,
    createProject,
    changeRepeats,
    changeAccount,
    createCategory,
    changeCategory,
    changeComments,
    createBorrower,
    createCreditor,
    createInvestor,
    initialScheduled,
    changeIncomeDate,
    onCreateOperation,
    onRemoveOperation,
    onSetOperationType,
    onChangeDateOfPayment,
    creatingClientInProgress,
    creatingProjectInProgress,
    onChangeDateOfPaymentPeriod,
    onChangeDateOfPaymentPeriodId,
    handleChangeSplitPaymentProjects,
    creatingIncomeCategoryInProgress,
    changeToTransfer,
  } = props;

  const { isEditable } = useGetIsEditable();

  const historyOperationProps = useSelector(getHistoryOperationProps);
  const opProps = useSelector(getOperationProps);

  const operationProps = isNewState ? historyOperationProps : opProps;

  const {
    amount,
    incomeDate,
    revertState,
    exchangeRate,
    endTimestamp,
    currencyValue,
    dateOfPayment,
    currencyAmount,
    startTimestamp,
    splitPaymentProjects,
    dateOfPaymentPeriodId,
  } = operationProps;

  const companyCurrency = useSelector(selectCompanyCurrency);
  const cryptoCurrencyCodes = useSelector(selectCryptoCurrencyCodes);

  const isCrypto = cryptoCurrencyCodes.includes(account?.currency.code || '');

  const classes = useStyles();
  const { t } = useTranslation();
  const showAlertIntegrationPayment = useUnleash(
    SHOW_ALERT_INTEGRATION_PAYMENT,
  );
  const newUserForClientAndCategoriesView = useUnleash(
    NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW,
  );
  const { isCreateOperationActiveStep } = useOnboardingV2();

  const [error, setShowError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [showSplitPaymentProjectsError, setShowSplitPaymentProjectsError] =
    useState(false);
  const [showDeletedProjectError, setShowDeletedProjectError] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showAlertChangeAmountDialog, setShowAlertChangeAmountDialog] =
    useState(false);

  const isIntegrationOperation =
    showAlertIntegrationPayment && integrationId && externalId && !botId;

  let subType;

  switch (category?.normalizedLabel) {
    case OperationSubType.investment:
      subType = OperationSubType.investment;
      break;
    case OperationSubType.loan:
      subType = OperationSubType.loan;
      break;
    case OperationSubType.loanrepayment:
      subType = OperationSubType.loanrepayment;
      break;
    default:
      subType = OperationSubType.sale;
  }

  const handleCreateOperation = useCallback(
    (scheduled: boolean, closeDialog = true) => {
      const normalizedLabel = category?.normalizedLabel;

      if (normalizedLabel === OperationSubType.investment) {
        onCreateOperation(scheduled, OperationSubType.investment, closeDialog);
      } else if (normalizedLabel === OperationSubType.loan) {
        onCreateOperation(scheduled, 'incomeLoan', closeDialog);
      } else if (normalizedLabel === OperationSubType.loanrepayment) {
        onCreateOperation(scheduled, 'incomeLoanRepayment', closeDialog);
      } else {
        onCreateOperation(scheduled, OperationSubType.sale, closeDialog);
      }
    },
    [category, onCreateOperation],
  );

  const handleShowConfirmDialog = useCallback(() => {
    setShowConfirmDialog(true);
  }, []);

  const handleCloseChangeDialog = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const handleShowRemoveDialog = useCallback(() => {
    setShowRemoveDialog(true);
  }, []);

  const handleCloseRemoveDialog = useCallback(() => {
    setShowRemoveDialog(false);
  }, []);

  const handleCloseAlertDialog = useCallback(() => {
    setShowAlertDialog(false);
  }, []);

  const handleCloseAlertChangeAmountDialog = useCallback(() => {
    setShowAlertChangeAmountDialog(false);
  }, []);

  const handleUpdate = useCallback(
    (scheduled: boolean) => {
      handleCreateOperation(scheduled);
    },
    [handleCreateOperation],
  );

  const handleCreate = useCallback(() => {
    if (account?.integration) {
      setShowAlertDialog(true);
    } else if (isCreateOperationActiveStep && (!amount || !Number(amount))) {
      setAmountError(true);
    } else {
      handleCreateOperation(repeats.id !== 1000);
    }
  }, [
    amount,
    repeats,
    account,
    handleCreateOperation,
    isCreateOperationActiveStep,
  ]);

  const onCreate = useCallback(() => {
    handleCreateOperation(repeats.id !== 1000);
  }, [handleCreateOperation, repeats]);

  const handleClickSaveButton = useCallback(() => {
    if (isIntegrationOperation && editProps?.sumDouble !== Number(amount)) {
      setShowAlertChangeAmountDialog(true);

      return;
    }

    if (!initialScheduled && repeats.id === 1000) {
      handleUpdate(false);
    } else if (initialScheduled) {
      handleShowConfirmDialog();
    } else {
      handleUpdate(repeats.id !== 1000);
    }
  }, [
    amount,
    repeats,
    editProps,
    handleUpdate,
    initialScheduled,
    isIntegrationOperation,
    handleShowConfirmDialog,
  ]);

  const handleRemoveOperation = useCallback(
    (scheduled = false) => {
      onRemoveOperation(scheduled);
    },
    [onRemoveOperation],
  );

  const handleCreateClient = useCallback(
    (newClientName: string) => {
      const normalizedLabel = category?.normalizedLabel;

      if (normalizedLabel === OperationSubType.investment) {
        createInvestor(newClientName);
      } else if (normalizedLabel === OperationSubType.loan) {
        createBorrower(newClientName);
      } else if (normalizedLabel === OperationSubType.loanrepayment) {
        createCreditor(newClientName);
      } else {
        createClient(newClientName);
      }
    },
    [category, createBorrower, createClient, createCreditor, createInvestor],
  );

  const clientsByCategory = useMemo(() => {
    const normalizedLabel = category?.normalizedLabel;

    if (normalizedLabel === OperationSubType.investment) {
      return investors;
    }

    if (normalizedLabel === OperationSubType.loan) {
      return borrowers;
    }

    if (normalizedLabel === OperationSubType.loanrepayment) {
      return creditors;
    }

    return clients;
  }, [category, investors, borrowers, creditors, clients]);

  const handleChangeCategory = useCallback(
    (value: string) => {
      changeCategory(value, OperationType.income);
    },
    [changeCategory],
  );

  useEffect(() => {
    if (incomeDate) {
      setShowError(false);
    }
  }, [incomeDate]);

  useEffect(() => {
    if (amount) {
      setAmountError(false);
    }
  }, [amount]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (splitPaymentProjects.length > 1) {
      // if currencyAmount changed check payment is spit between projects correctly
      const splitPaymentSum: number = Number(
        splitPaymentProjects
          .reduce(
            (acc: number, project: SplitPaymentProjectDetailedInfo) =>
              acc + Number(project.sum),
            0,
          )
          .toFixed(2),
      );

      const amountToCheck: number = Number(
        currencyAmount && currency?.id !== account?.currency._id
          ? currencyAmount
          : amount ?? 0,
      );
      if (amountToCheck !== splitPaymentSum) {
        setShowSplitPaymentProjectsError(true);
      } else {
        setShowSplitPaymentProjectsError(false);
      }
    } else {
      setShowSplitPaymentProjectsError(false);
      setShowDeletedProjectError(false);
    }
  }, [amount, currencyAmount, splitPaymentProjects]);

  useEffect(() => {
    if (splitPaymentProjects.length > 1 && exchangeRate) {
      handleChangeSplitPaymentProjects(
        splitPaymentProjects.map((projectInfo) => {
          const currencyAmountVal = (
            Number(exchangeRate ?? 0) * Number(projectInfo.sum)
          ).toFixed(3);
          const amountVal = projectInfo.sum;
          const calculateSumAndCompanyCurrencySumProps = {
            isEdit: false,
            currencyAmount: currencyAmountVal,
            account,
            isCrypto,
            revertState,
            exchangeRate,
            currencyValue,
            amount: amountVal,
            companyCurrency,
          };

          const {
            companyCurrencySum = null,
            transactionSum = null,
            sum,
          } = calculateSumAndCompanyCurrencySum(
            calculateSumAndCompanyCurrencySumProps,
          );
          return {
            project: projectInfo.project,
            percentages: projectInfo.percentages,
            sum: sum?.toFixed(2) ?? '',
            transactionSum,
            companyCurrencySum,
          };
        }),
      );
    }
  }, [exchangeRate]);

  useEffect(() => {
    onSetOperationType(OperationType.income);

    if (!isEdit) {
      changeIncomeDate(moment.utc().valueOf() + getTimeOffset());
    }
  }, [onSetOperationType, isEdit, changeIncomeDate]);

  return (
    <div id="cypress-sale-operation" className={classes.root}>
      <div className={classes.container}>
        <AccountSelector
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.accountToId)}
          value={account}
          onChange={changeAccount}
          accounts={accounts}
        />
        <AmountComponent
          isEdit={isEdit}
          isNewState={isNewState}
          isCompare={Boolean(isCompare)}
          difference={difference}
          isError={amountError}
        />
        <AutoCompleteComponent
          loading={creatingIncomeCategoryInProgress}
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.categoryId)}
          allowedCreate={isEditable(SettingsName['categories/income'])}
          onChange={handleChangeCategory}
          onCreate={createCategory}
          data={categories}
          type="category"
          value={category}
          disablePortal={false}
          operationType={OperationType.income}
        />
        <AutoCompleteComponent
          loading={creatingClientInProgress}
          isGeneric={Boolean(isGeneric)}
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.counterpartyId)}
          allowedCreate={isEditable(SettingsName.clients)}
          onChange={changeClient}
          onCreate={handleCreateClient}
          data={clientsByCategory}
          type={
            newUserForClientAndCategoriesView &&
            // @ts-ignore
            !clientPrefixBySubType[category?.normalizedLabel]
              ? 'myCounterparty'
              : // @ts-ignore
                clientPrefixBySubType[category?.normalizedLabel] || 'clients'
          }
          value={client}
        />
        <CalendarComponent
          testId="cypress-calendar"
          isCompare={Boolean(isCompare)}
          difference={Boolean(difference?.date)}
          fullWidth
          value={incomeDate}
          onChange={changeIncomeDate}
          error={error}
          placeholder={t('operationDialogs.incomeDate')}
          minDate={minOperationDate()}
          maxDate={maxOperationDate(incomeDate)}
        />
        <DateOfPaymentComponent
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
          periodId={dateOfPaymentPeriodId}
          type={OperationType.income}
          isEdit={!!isEdit}
          dateOfPayment={dateOfPayment}
          subType={subType}
          isCompare={!!isCompare}
          difference={!!difference?.dateOfPayment}
          incomeDate={incomeDate}
          minDate={minOperationDate()}
          maxDate={maxOperationDate(incomeDate)}
          isCustomCategory={!!category && !category.systemValue}
          onChangeDateOfPayment={onChangeDateOfPayment}
          onChangeDateOfPaymentPeriod={onChangeDateOfPaymentPeriod}
          onChangePeriodId={onChangeDateOfPaymentPeriodId}
        />
        {!isEdit && (
          <Repeat
            isCompare={Boolean(isCompare)}
            difference={Boolean(difference?.scheduled)}
            onChangePeriod={changeRepeats}
            repeats={repeats}
            incomeDate={incomeDate}
          />
        )}
        <Additions
          isNewState={isNewState}
          isCompare={Boolean(isCompare)}
          difference={difference}
          isAllowedCreateProject={isEditable(SettingsName.projects)}
          isAllowedCreateTag={isEditable(SettingsName.tags)}
          projects={projects}
          tags={tags}
          selectedTags={selectedTags}
          comments={comments}
          splitPaymentProjects={splitPaymentProjects}
          isVisibleProjectDropdown={
            !(
              Array.isArray(splitPaymentProjects) &&
              splitPaymentProjects?.length > 1
            )
          }
          onChangeProject={handleChangeSplitPaymentProjects}
          onChangeComments={changeComments}
          onCreateProject={createProject}
          onCreateTag={createTag}
          onChangeTags={changeTags}
          creatingProjectInProgress={creatingProjectInProgress}
          setShowDeletedProjectError={setShowDeletedProjectError}
          showSplitPaymentProjectsError={showSplitPaymentProjectsError}
          isCryptoCurrency={currency?.isCrypto ?? false}
          editProps={editProps}
        />
        <FooterComponent
          type={OperationType.income}
          isEdit={isEdit}
          isCopy={isCopy}
          isCompare={Boolean(isCompare)}
          onCreate={handleCreate}
          onShowRemoveDialog={handleShowRemoveDialog}
          onClickSaveButton={handleClickSaveButton}
          createOperation={handleCreateOperation}
          showProjectsError={
            showSplitPaymentProjectsError || showDeletedProjectError
          }
          changeToTransfer={changeToTransfer}
        />
      </div>
      {showRemoveDialog && repeats.id !== 1000 && (
        <ConfirmRemoveRepeatDialog
          type={OperationType.income}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {showAlertDialog && (
        <RemoveDialog
          type="confirmAddToIntegrationAccount"
          description={t('system.sureToAddPaymentDescription')}
          title={t('system.sureToAddPayment')}
          name=""
          alertText={t('system.addAnyway')}
          onRemove={onCreate}
          onClose={handleCloseAlertDialog}
        />
      )}
      {showAlertChangeAmountDialog && (
        <RemoveDialog
          type="confirmChangeToIntegrationAccount"
          description={t('system.sureToChangePaymentDescription')}
          title={t('system.sureToChangePayment')}
          name=""
          alertText={t('system.changeAnyway')}
          onRemove={onCreate}
          onClose={handleCloseAlertChangeAmountDialog}
        />
      )}
      {!isIntegrationOperation && showRemoveDialog && repeats.id >= 1000 && (
        <RemoveDialog
          type="confirmRemoveOperation"
          name={t('operation.removeDialogText.income').toLowerCase()}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {isIntegrationOperation && showRemoveDialog && repeats.id >= 1000 && (
        <RemoveDialog
          type="confirmRemoveIntegrationOperation"
          description={t('system.sureToRemovePaymentDescription')}
          title={t('system.sureToRemovePayment')}
          name=""
          alertText={t('system.deleteAnyway')}
          onRemove={handleRemoveOperation}
          onClose={handleCloseRemoveDialog}
        />
      )}
      {showConfirmDialog && (
        <ConfirmChangeRepeatDialog
          type={OperationType.income}
          onUpdate={handleUpdate}
          onClose={handleCloseChangeDialog}
        />
      )}
    </div>
  );
}

export default operationsHOC(IncomeComponentWithHOC);
