import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';

import CheckIcon from '../../../../../../assets/images/svg/checkmark.svg';
import UnVisibleIcon from '../../../../../../assets/images/svg/eye-hide.svg';
import VisibleIcon from '../../../../../../assets/images/svg/eye-open.svg';
import TrashIcon from '../../../../../../assets/images/svg/trash-red.svg';
import PreviewRegisterDialog from '../../../../../../components/PreviewRegisterDialog';
import RemoveDialog from '../../../../../../components/RemoveDialog/RemoveDialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../../../../components/TextField/TextFieldComponent.types';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../../../../../constants';
import usePreviewRegister from '../../../../../../hooks/usePreviewRegister';
import useRemoveCategory from '../../../../../../hooks/useRemoveCategory';
import useRestricted from '../../../../../../hooks/useRestricted';
import {
  selectSettingsConsumptionCategories,
  selectSettingsIncomeCategories,
} from '../../../../../../store/categories/selectors';
import { OperationType } from '../../../../../../store/operations/types';
import { findSubTypeInTranslation } from '../utils';
import { useStyles } from './styles';
import { Props } from './types';

function EditRow(props: Props) {
  const { item, onUpdate, onClose, type, categoriesType } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { showRestrictedDialog, fastPermissionsType, deleteAccess } =
    useRestricted();

  const incomeCategories = useSelector(selectSettingsIncomeCategories);
  const consumptionCategories = useSelector(
    selectSettingsConsumptionCategories,
  );
  const categories =
    type === OperationType.income ? incomeCategories : consumptionCategories;

  const [name, setName] = useState('');
  const [error, setError] = useState('');

  const [visible, setVisible] = useState(false);

  const [callback, setCallback] = useState<any | null>(null);

  const {
    showPreviewRegisterDialog,
    onOpenPreviewRegisterDialog,
    onClosePreviewRegisterDialog,
  } = usePreviewRegister();

  const {
    onRemoveCategory,
    showRemoveDialog,
    onOpenRemoveDialog,
    onCloseRemoveDialog,
  } = useRemoveCategory({
    item,
    setError,
    type: categoriesType,
    onCloseEditRow: onClose,
  });

  const handleChangeName = useCallback((value: string) => {
    setName(value);
    setError('');
  }, []);

  const handleChangeVisible = useCallback(() => {
    if (name) {
      onUpdate(name, !visible);
    }
  }, [name, onUpdate, visible]);

  const handleUpdate = useCallback(() => {
    if (!name) {
      setError(t('system.fieldMustFilled'));

      return;
    }

    if (name === item?.label) {
      onClose();

      return;
    }

    const isExistInDafaultCategories = findSubTypeInTranslation(name, type);
    const isExistCategory = categories.some((el) => {
      if (el.subcategories.length) {
        const isExistSubCategory = el.subcategories.some(
          (subEl) => subEl.normalizedLabel === normalize(name),
        );

        if (isExistSubCategory) {
          return true;
        }
      }

      return el.normalizedLabel === normalize(name);
    });

    if (isExistCategory || isExistInDafaultCategories) {
      setError(t('errors.create.category.exist'));

      return;
    }

    if (name) {
      onUpdate(name, visible);
    }
  }, [name, type, categories, t, onUpdate, visible, item, onClose]);

  const handleSetVisibleCallback = useCallback(() => {
    setCallback(() => handleChangeVisible);

    onOpenPreviewRegisterDialog();
  }, [handleChangeVisible, onOpenPreviewRegisterDialog]);

  const handleSetUpdateCallback = useCallback(() => {
    setCallback(() => handleUpdate);

    onOpenPreviewRegisterDialog();
  }, [handleUpdate, onOpenPreviewRegisterDialog]);

  const handleSetRemoveCallback = useCallback(() => {
    setCallback(() => onOpenRemoveDialog);

    onOpenPreviewRegisterDialog();
  }, [onOpenRemoveDialog, onOpenPreviewRegisterDialog]);

  const checkRemovePermissions = useCallback(() => {
    if (fastPermissionsType && !deleteAccess) {
      showRestrictedDialog(handleSetRemoveCallback);
    } else {
      handleSetRemoveCallback();
    }
  }, [
    deleteAccess,
    fastPermissionsType,
    handleSetRemoveCallback,
    showRestrictedDialog,
  ]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent & { keyCode: number }) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        event.stopPropagation();

        onClose();
      }

      if (event.keyCode === ENTER_KEY_CODE) {
        handleSetUpdateCallback();
      }
    },
    [onClose, handleSetUpdateCallback],
  );

  useEffect(() => {
    if (item) {
      const { label, visible: itemVisible } = item;

      setName(label);
      setVisible(itemVisible);
    }
  }, [item]);

  return (
    <>
      <div className={classes.container}>
        <TextFieldComponent
          active
          ga4Id={Ga4Errors.editCategoryNameError}
          onChange={handleChangeName}
          value={name}
          placeholder={t('common.title')}
          rootClassName={classes.textFieldRoot}
          isError={Boolean(error)}
          errorText={error}
          wrapErrorClassName={classes.error}
          onKeyDown={handleKeyDown}
        />

        <div className={classes.row}>
          <img
            alt=""
            src={CheckIcon}
            className={classes.icon}
            onClick={handleSetUpdateCallback}
          />
          {visible ? (
            <img
              alt=""
              src={VisibleIcon}
              className={classes.icon}
              onClick={handleSetVisibleCallback}
            />
          ) : (
            <img alt="" src={UnVisibleIcon} className={classes.icon} />
          )}
          <img
            alt=""
            src={TrashIcon}
            className={classes.icon}
            onClick={checkRemovePermissions}
          />
        </div>
      </div>
      {showRemoveDialog && (
        <RemoveDialog
          type="confirmRemoveCategories"
          onClose={onCloseRemoveDialog}
          onRemove={onRemoveCategory}
          name={item?.label || ''}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={onClosePreviewRegisterDialog}
          callback={callback}
        />
      )}
    </>
  );
}

export default React.memo(EditRow);
