import { CREATE_INTEGRATIONS_PENDING } from './actions';
import { CreateIntegrationPayload } from './sagas.types';

export const SET_NORDIGEN_INTEGRATION_ID = 'SET_NORDIGEN_INTEGRATION_ID';

const setNordigenIntegrationId = (id: string) => ({
  type: SET_NORDIGEN_INTEGRATION_ID,
  payload: { id },
});

const createNordigenIntegration = (data: CreateIntegrationPayload) => ({
  type: CREATE_INTEGRATIONS_PENDING,
  payload: data,
});

export default {
  setNordigenIntegrationId,
  createNordigenIntegration,
};
