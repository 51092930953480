import { Typography } from '@material-ui/core';
import cn from 'classnames';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AlertIcon from '../../assets/images/svg/alert-triangle.svg';
import DragAndDropIcon from '../../assets/images/svg/drug_drop_small.svg';
import EditIcon from '../../assets/images/svg/edit.svg';
import UnVisibleIcon from '../../assets/images/svg/eye-hide.svg';
import RestrictedIcon from '../../assets/images/svg/restricted.svg';
import Spinner from '../../components/Spinner';
import Tooltip from '../../components/Tooltip';
import { USE_RECONNECT_INTEGRATION } from '../../constants/featureToggles/integrationsFeatureToggles';
import usePermissions from '../../hooks/usePermissions';
import useRestricted from '../../hooks/useRestricted';
import useUnleash from '../../hooks/useUnleash';
import { AccountValue } from '../../store/accounts/types';
import { IntegrationTypeId } from '../../store/integrations/types';
import { useStyles } from './styles';
import utils from './utils';

type Props = {
  value: AccountValue;
  subscriptionActive: boolean;

  onReconnectAuth?(id: string, value: AccountValue): void;
  onEditRow(id: string): void;
};

function RenderAccountRow(props: Props) {
  const { value, onEditRow, subscriptionActive, onReconnectAuth } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { readAccess, openRestrictedDialog } = useRestricted();
  const { isEditableAccounts } = usePermissions();
  const useReconnectIntegration = useUnleash(USE_RECONNECT_INTEGRATION);

  const icon = utils.getIntegrationIcon(
    value.integration?.typeId,
    value.integration?.logoLink,
  );

  const isSaltedgeIntegration =
    value?.integration?.typeId === IntegrationTypeId.Saltedge;

  const reconnectIntegrationWarning =
    isSaltedgeIntegration && useReconnectIntegration;

  const handleReconnectBank = useCallback(() => {
    if (onReconnectAuth && value.integration) {
      onReconnectAuth(value.integration._id, value);
    }
  }, [onReconnectAuth, value]);

  const handleEditClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (readAccess || !isEditableAccounts) {
        openRestrictedDialog();
      } else {
        onEditRow(event.currentTarget.id);
      }
    },
    [onEditRow, readAccess, openRestrictedDialog, isEditableAccounts],
  );

  return (
    <div
      key={value._id}
      className={cn(classes.row, !value.visible && classes.opacity)}
      id={!value.visible ? `invisible-${value._id}` : `edit-${value._id}`}
      onClick={handleEditClick}
    >
      <div
        className={cn(
          classes.leftBlock,
          reconnectIntegrationWarning && classes.width60,
        )}
      >
        {isEditableAccounts && (
          <img
            src={DragAndDropIcon}
            alt="DragAndDropIcon"
            className={cn(classes.opacity, classes.margin16)}
          />
        )}
        {!isEditableAccounts && <div className={classes.margin16} />}
        <Typography className={cn(classes.opacity, classes.margin16)}>
          {value.currency?.symbol || ''}
        </Typography>
        <Typography
          className={cn(
            classes.textEclipse,
            reconnectIntegrationWarning && classes.margin16,
            reconnectIntegrationWarning && classes.textEclipseShort,
          )}
          title={value.name}
        >
          {value.name}
        </Typography>
        {reconnectIntegrationWarning && (
          <Tooltip
            title={t('integrations.repeatIntegration')}
            disablePortal={false}
            placement="top"
          >
            <img src={AlertIcon} alt="alert" />
          </Tooltip>
        )}
      </div>
      <div className={classes.rightBlock}>
        {!value.integration?.loaderDone && value.integration?.loaderState && (
          <>
            <Spinner />
            <div className={classes.flexRow}>
              <Typography className={classes.loaderStateNumber}>
                {`${value.integration.loaderState}%`}
              </Typography>
              <Typography className={cn(classes.loaderState, classes.hide)}>
                {t('integrations.loaded').toLowerCase()}...
              </Typography>
            </div>
          </>
        )}
        {reconnectIntegrationWarning && value.visible && (
          <div
            className={classes.reconnectButtonContainer}
            onClick={handleReconnectBank}
          >
            <Typography className={classes.reconnectButtonText}>
              {t('integrations.reconnectBank')}
            </Typography>
          </div>
        )}
        {value.integration?.loaderDone &&
          Boolean(value.integration) &&
          icon && (
            <>
              {!subscriptionActive && (
                <img
                  src={RestrictedIcon}
                  alt="restricted"
                  className={classes.margin16}
                />
              )}
              <img
                src={icon}
                alt="bank-icon"
                className={cn(
                  classes.bankIcon,
                  !reconnectIntegrationWarning && classes.margin8,
                )}
              />
            </>
          )}
        {value.visible &&
          !reconnectIntegrationWarning &&
          isEditableAccounts && (
            <img className={classes.pointer} alt="visible" src={EditIcon} />
          )}
        {!value.visible && (
          <img
            className={classes.pointer}
            alt="unvisible"
            src={UnVisibleIcon}
          />
        )}
      </div>
    </div>
  );
}

export default RenderAccountRow;
