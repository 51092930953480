import {
  aquaHaze,
  black,
  outrageousOrange,
  persianGreen,
} from '../../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  separator: {
    marginBottom: 24,
  },
  lastSeparator: {
    marginTop: 13,
    marginBottom: 24,
  },
  buttonText: {
    fontWeight: 'bold',
    lineHeight: '24px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
    color: persianGreen,
    marginBottom: 24,
  },
  removeRow: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    color: outrageousOrange,
    cursor: 'pointer',
  },
  icon: {
    marginRight: 11,
    color: outrageousOrange,
  },
  copyIcon: {
    marginRight: 20,
  },
  removeText: {
    fontSize: 16,
    lineHeight: '24px',
    color: outrageousOrange,
  },
  buttonTransferContainer: {
    background: 'linear-gradient(88.88deg, #000000 0%, #3D3D3D 100%)',
  },
  buttonConsumptionContainer: {
    background: 'linear-gradient(88.88deg, #FF9D85 0%, #FFD585 100%)',
  },
  addIcon: {
    marginRight: 20,
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  marginTop16: {
    marginTop: 16,
  },
  height40: {
    height: 40,
  },
  infoRow: {
    display: 'flex',
    paddingTop: 16,
    paddingBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      background: aquaHaze,
      borderRadius: 8,
    },
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    color: black,
    marginRight: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '85%',
  },
});
