import { AnyAction } from 'redux';

import {
  GET_CURRENCIES_FAILED,
  GET_CURRENCIES_FULFILLED,
  GET_CURRENCIES_PENDING,
  GET_CURRENCIES_REJECTED,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCY_PROVIDERS_SUCCESS,
  GET_CUSTOM_CURRENCY_RATES_SUCCESS,
  GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS,
  GET_EXCHANGE_RATE_FROM_TO_PENDING,
  RESET_RATES,
} from './actions';
import { Currency, CurrencyState } from './types';

const initialState: CurrencyState = {
  errors: {},
  isError: false,
  loading: false,
  exchangeRateFromTo: null,
  currencies: [],
  cryptoCurrencies: [],
  customCurrencyRates: [],
  providers: [],
  defaultProvider: null,
};

const currencyReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }: AnyAction,
) => {
  switch (type) {
    case GET_CUSTOM_CURRENCY_RATES_SUCCESS: {
      return {
        ...state,
        customCurrencyRates: payload.data,
      };
    }

    case GET_DEFAULT_CURRENCY_PROVIDER_SUCCESS: {
      return {
        ...state,
        defaultProvider: payload.provider,
      };
    }

    case GET_CURRENCY_PROVIDERS_SUCCESS: {
      return {
        ...state,
        providers: payload.providers,
      };
    }

    case GET_EXCHANGE_RATE_FROM_TO_PENDING:
    case GET_CURRENCIES_PENDING:
      return {
        ...state,
        errors: {},
        isError: false,
        loading: true,
      };

    case GET_CURRENCIES_REJECTED:
    case GET_CURRENCIES_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        errors: payload,
      };

    case GET_CURRENCIES_FULFILLED:
    case GET_CURRENCIES_SUCCESS: {
      const { currencies, cryptoCurrencies } = payload;

      const formatCryptoCurrencies = cryptoCurrencies.map((el: Currency) => ({
        ...el,
        isCrypto: true,
      }));

      return {
        ...state,
        errors: {},
        isError: false,
        loading: false,
        currencies,
        cryptoCurrencies: formatCryptoCurrencies,
      };
    }

    case RESET_RATES: {
      return {
        ...state,
        exchangeRate: null,
        exchangeRateFromTo: null,
      };
    }

    default:
      return state;
  }
};

export default currencyReducer;
