import {
  CreateAutoRulePayload,
  DeleteAutoRulePayload,
  UpdateAutoRulePayload,
  UpdateAutoRulesOrderPayload,
} from './sagas.types';

export enum ActionAutoRulesTypes {
  DELETE_AUTO_RULE_PENDING = 'DELETE_AUTO_RULE_PENDING',
  DELETE_AUTO_RULE_SUCCESS = 'DELETE_AUTO_RULE_SUCCESS',
  GET_AUTO_RULES_PENDING = 'GET_AUTO_RULES_PENDING',
  GET_AUTO_RULES_SUCCESS = 'GET_AUTO_RULES_SUCCESS',
  CREATE_AUTO_RULE_PENDING = 'CREATE_AUTO_RULE_PENDING',
  CREATE_AUTO_RULE_SUCCESS = 'CREATE_AUTO_RULE_SUCCESS',
  UPDATE_AUTO_RULE_PENDING = 'UPDATE_AUTO_RULE_PENDING',
  UPDATE_AUTO_RULE_SUCCESS = 'UPDATE_AUTO_RULE_SUCCESS',
  UPDATE_AUTO_RULE_ORDER_PENDING = 'UPDATE_AUTO_RULE_ORDER_PENDING',
  UPDATE_AUTO_RULE_ORDER_SUCCESS = 'UPDATE_AUTO_RULE_ORDER_SUCCESS',
}

const getAutoRulesList = () => ({
  type: ActionAutoRulesTypes.GET_AUTO_RULES_PENDING,
});

const updateAutoRule = (data: UpdateAutoRulePayload) => ({
  type: ActionAutoRulesTypes.UPDATE_AUTO_RULE_PENDING,
  payload: data,
});

const createAutoRule = (rule: CreateAutoRulePayload) => ({
  type: ActionAutoRulesTypes.CREATE_AUTO_RULE_PENDING,
  payload: rule,
});

const deleteAutoRule = ({ ruleId }: DeleteAutoRulePayload) => ({
  type: ActionAutoRulesTypes.DELETE_AUTO_RULE_PENDING,
  payload: { ruleId },
});

const updateAutoRulesOrder = (rules: UpdateAutoRulesOrderPayload[]) => ({
  type: ActionAutoRulesTypes.UPDATE_AUTO_RULE_ORDER_PENDING,
  payload: rules,
});

export default {
  deleteAutoRule,
  createAutoRule,
  updateAutoRule,
  getAutoRulesList,
  updateAutoRulesOrder,
};
