import React from 'react';
import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';

import { useStyles } from './styles';
import Dialog from '../dialog-molecule';

export interface AlertDialogProps {
  title: string;
  description: string;
  acceptText: string;
  buttonTextColor?: string;
  buttonBackground?: string;

  onClose(): void;
  onAccept(): void;
};

function AlertDialogComponent(props: AlertDialogProps) {
  const {
    title,
    onClose,
    onAccept,
    acceptText,
    description,
    buttonTextColor,
    buttonBackground,
  } = props;
  const classes = useStyles();

  return (
    <Dialog isOpened onClose={onClose} title={title}>
      <>
        {!!description && (
          <Typography className={classes.description}>{description}</Typography>
        )}
      </>
      <div className={classes.footer}>
        <Button
          className={cn(classes.button, buttonBackground)}
          onClick={onAccept}
          id="cypress-confirm-remove-button"
        >
          <Typography className={cn(classes.buttonText, buttonTextColor)}>
            {acceptText}
          </Typography>
        </Button>
      </div>
    </Dialog>
  );
}

export const AlertDialog = React.memo(AlertDialogComponent);

export default AlertDialog;
