import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '../../../../../../assets/images/svg/checkmark.svg';
import CloseIcon from '../../../../../../assets/images/svg/mini-cross-close.svg';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import { Ga4Errors } from '../../../../../../components/TextField/TextFieldComponent.types';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../../../../../constants';
import { useStyles } from './styles';
import { Props } from './types';
import useCreateSettingsCategory from '../../../../../../hooks/useCreateSettingsCategory';

function CreateRow(props: Props) {
  const { onClose, type } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    onPrevCreate,
    nameError: error,
    onSetCategoryLabel: handleChangeName,
    categoryName: name,
  } = useCreateSettingsCategory(type);

  const handleClose = useCallback(() => {
    handleChangeName('');
    onClose();
  }, [onClose, handleChangeName]);

  const handleCreate = useCallback(() => {
    onPrevCreate(onClose);
  }, [onPrevCreate, onClose]);

  const handleKeyDown = useCallback(
    (event: SyntheticEvent & { keyCode: number }) => {
      if (event.keyCode === ESCAPE_KEY_CODE) {
        event.stopPropagation();

        onClose();
      }

      if (event.keyCode === ENTER_KEY_CODE) {
        onPrevCreate();
      }
    },
    [onClose, onPrevCreate],
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <TextFieldComponent
          ga4Id={Ga4Errors.createCategoryNameError}
          active
          onChange={handleChangeName}
          placeholder={t('category.name')}
          value={name}
          isError={Boolean(error)}
          errorText={error}
          onKeyDown={handleKeyDown}
          rootClassName={classes.textBlock}
        />
        <div className={classes.row}>
          <img
            alt="check"
            src={CheckIcon}
            className={classes.icon}
            onClick={handleCreate}
          />
          <img
            alt="close"
            src={CloseIcon}
            className={classes.icon}
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(CreateRow);
