import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorIcon from '../../../../assets/images/svg/error-triangle-splitpayment.svg';
import SplitIcon from '../../../../assets/images/svg/splitting_payments.svg';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import { getOperationProps } from '../../../../store/operations/selectors';
import SplitPaymentProjectsInfo from './SplitPaymentProjectsInfo';
import SplittingPaymentDialog from './SplittingPaymentDialog';
import { useStyles } from './styles';
import { SplittingPaymentProps } from './types';

function SplittingPayment(props: SplittingPaymentProps) {
  const {
    projects,
    operationType,
    onCreateProject,
    setShowDeletedProjectError,
    onChangeSplitPaymentProjects,
    showSplitPaymentProjectsError,
    isCompare,
  } = props;

  const [isOpenPaymentDialog, setIsOpenPaymentDialog] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { splitPaymentProjects } = useSelector(getOperationProps);

  const onClick = useCallback(() => {
    setIsOpenPaymentDialog(true);
  }, []);

  const handleClosePaymentDialog = useCallback(() => {
    setIsOpenPaymentDialog(false);
  }, []);

  return (
    <>
      <ButtonRowWithIcons
        onClick={onClick}
        icon={SplitIcon}
        alt="splitPayment"
        label={
          splitPaymentProjects.length > 1
            ? t('operationDialogs.paymentIsSplit')
            : t('operationDialogs.splitPayment')
        }
        labelIcon={showSplitPaymentProjectsError ? [ErrorIcon] : []}
        wrapClasses={classes.splitPaymentRow}
        disabled={isCompare}
      >
        {splitPaymentProjects.length > 1 && (
          <SplitPaymentProjectsInfo
            operationType={operationType}
            setShowDeletedProjectError={setShowDeletedProjectError}
          />
        )}
      </ButtonRowWithIcons>
      {isOpenPaymentDialog && !isCompare && (
        <SplittingPaymentDialog
          onClose={handleClosePaymentDialog}
          projects={projects}
          onChangeSplitPaymentProjects={onChangeSplitPaymentProjects}
          onCreateProject={onCreateProject}
        />
      )}
    </>
  );
}

export default React.memo(SplittingPayment);
