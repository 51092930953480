import moment, { Moment } from 'moment';

import { MAX_OPERATION_DATE_IN_YEARS, MIN_OPERATION_DATE } from '../constants';
import { getTimeOffset } from './dateToUTC';

export const dateFormat = (
  date: Moment | number,
  isUtc: boolean,
  format = 'DD.MM.YYYY',
) => (isUtc ? moment.utc(date).format(format) : moment(date).format(format));

export function minOperationDate() {
  return new Date(MIN_OPERATION_DATE);
}

export function maxOperationDate(date: number) {
  const newDate =
    moment(date).add(MAX_OPERATION_DATE_IN_YEARS, 'years').valueOf() -
    getTimeOffset();

  return moment(newDate).toDate();
}
