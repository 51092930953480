import { makeStyles } from '@material-ui/core/styles';

import { globalStyles } from '../../../../styles/global-styles';
import { aquaHaze, jade } from '../../../../theme/colors';
import { StyleProps } from '../ConfirmChangeRepeatDialog/types';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    marginBottom: (props: StyleProps) => (props.isError ? 28 : 16),
    transition: 'margin 700ms',
    background: aquaHaze,
    borderRadius: 12,
    height: 56,
    boxSizing: 'border-box',
    '&:focus-within': {
      border: `1px solid ${jade}`,
    },
    cursor: 'pointer',
  },
  cursorDefault: {
    cursor: 'default',
  },
  container: {
    flex: 1,
    padding: '9px 16px',
  },
  rootSelect: {
    marginBottom: 0,
  },
  input: {
    ...globalStyles.input,
    ...globalStyles.alignCenter,
    position: 'relative',
    padding: '12px 16px',
    color: 'rgb(84, 84, 84)',
    border: '1px solid #b6b9aa',
    pointerEvents: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    '&:hover': {
      border: '1px solid transparent',
      '-webkit-appearance': 'none',
      boxShadow: '0 0 38px rgba(85,205,161,0.41)',
      '-webkit-box-shadow': '0 0 38px rgba(85,205,161,0.41)',
    },
  },
  smallPadding: {
    padding: '12px 20px',
  },
  selectRange: {
    padding: '8px 10px',
    minWidth: 155,
    fontSize: 15,
    borderBottom: '3px solid #57de8b',
    '&:hover': {
      borderBottom: '3px solid #57de8b',
    },
  },
  inputWithError: {
    marginBottom: 10,
  },
  calendarNone: {
    display: 'none',
  },
  calendarBlock: {
    display: 'block',
  },
  daysOfWeek: {
    color: '#3e98a3',
  },
  icon: {
    bottom: 5,
    position: 'relative',
  },
  income: {
    padding: 0,
  },
  calendarModalContainer: {
    width: 304,
    maxWidth: 'none',
    borderRadius: 10,
    overflowX: 'hidden',
    background: '#fff',
    textAlign: 'left',
    boxSizing: 'border-box',
    '-webkit-appearance': 'none',
  },
  calendarContainer: {
    minHeight: 340,
    marginBottom: 22,
    padding: 0,
  },
  navigationTitle: {
    fontWeight: 'bold',
    fontSize: 15,
    lineHeight: '20px',
  },
  iconContainer: {
    padding: 0,
  },
  error: {
    position: 'absolute',
    color: 'red',
    fontSize: 13,
    top: 56,
  },
  errorBackground: {
    background: 'rgba(255, 63, 46, 0.12)',
  },
  disabledInput: {
    cursor: 'unset',
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgba(0, 0, 0, 0.08)',
  },
});
