import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class WiseBusinessImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.CSV]: [
      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Date', 1]),
            sum: this.getFirstValidCellByColumn(['Amount', 2]),
            currency: this.getFirstValidCellByColumn(['Currency', 3]),
            comment: this.getFirstValidCellByColumn(['Description', 4]),
            counterparty:
              this.getFirstValidCellByColumn(['Payer Name', 10]) ||
              this.getFirstValidCellByColumn(['Payee Name', 11]),
          });
        },
        caseOptions: {
          defaultCurrency: 'USD',
          preParserConfigs: { CSVDelimiter: ',' },
        },
      },
    ],
  };
}
