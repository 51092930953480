import React from 'react';
import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';

import { useStyles } from './styles';
import './custom-button.module.scss';
import TransitionComponent from '../../atoms/transaction-component';
import { black } from '../../constants/theme-colors';

export interface CustomButtonProps {
  title: string;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  icon?: string;
  iconClassName?: string;
  errorText?: string;
  textColor?: string;
  wrapperRootClassName?: string;

  action(): void;
};

function CustomButtonComponent(props: CustomButtonProps) {
  const {
    action,
    title,
    fullWidth,
    className,
    disabled,
    loading,
    id,
    icon,
    iconClassName,
    textColor = black,
    errorText = '',
    wrapperRootClassName,
  } = props;
  const classes = useStyles();

  return (
    <div className={cn(classes.root, wrapperRootClassName)}>
      <Button
        id={id}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={action}
        className={cn(classes.button, className)}
      >
        {icon && <img src={icon} alt="union" className={iconClassName} />}
        <Typography style={{ color: textColor }} className={classes.buttonText}>
          {title}
        </Typography>
        {loading && <div className="loader" />}
      </Button>
      <TransitionComponent enter={!!errorText} text={errorText} />
    </div>
  );
}

export const CustomButton = React.memo(CustomButtonComponent);

export default CustomButton;