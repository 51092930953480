import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  DELAY_UPDATE_HISTORY_LOG,
  LIMIT_HISTORY_ITEMS_PER_PAGE,
} from '../../constants';
import { DEFAULT_EMPTY_ACCOUNT_ID } from '../../constants/defaultCategories';
import { TAction } from '../../sagas/types';
import { showError } from '../../utils/showError';
import { GET_LOG_ACCOUNTS_PENDING } from '../accounts/actions';
import { UPDATE_BY_LOCATION } from '../operations/actions';
import api from './api';
import {
  HistoryActionOperationPayload,
  HistoryUpdateOperationPayload,
} from './api.types';
import {
  DELETE_HISTORY_OPERATION_PENDING,
  GET_CREDIT_HISTORY_DATA_PENDING,
  GET_CREDIT_HISTORY_DATA_SUCCESS,
  GET_DEBIT_HISTORY_DATA_PENDING,
  GET_DEBIT_HISTORY_DATA_SUCCESS,
  GET_HISTORY_JOURNAL_PENDING,
  GET_HISTORY_JOURNAL_SUCCESS,
  GET_HISTORY_POPUP_PENDING,
  GET_HISTORY_POPUP_SUCCESS,
  HISTORY_JOURNAL_LOAD_MORE_PENDING,
  RESTORE_HISTORY_OPERATION_PENDING,
  UPDATE_HISTORY_OPERATION_PENDING,
} from './history.actions';
import { GetHistoryJournalPayload } from './history.actions.types';
import {
  selectHistoryCustomFilters,
  selectHistoryJournal,
  selectPeriodFilter,
} from './selectors';
import { HistoryCustomFilters } from './types';

function prepareHistoryCustomFiltersQuery(filters: HistoryCustomFilters) {
  const query: string[] = [];

  const { operations, types, users, accounts } = filters;

  if (types) {
    const typeIds = types.map((type) => type.id).join(',');
    query.push(`crudType=${typeIds}`);
  }

  if (operations) {
    const operationIds = operations.map((operation) => operation.id).join(',');
    query.push(`type=${operationIds}`);
  }

  if (users) {
    const userIds = users.map((user) => user.id).join(',');
    query.push(`users=${userIds}`);
  }

  if (accounts) {
    const accountIds = accounts.length
      ? accounts.map((el) => el.id).join(',')
      : DEFAULT_EMPTY_ACCOUNT_ID;

    query.push(`accountIds=${accountIds}`);
  }

  return query.join('&');
}

function* getHistoryPopup() {
  try {
    const page = 0;
    const limit = 5;

    const { data } = yield call(api.getHistoryJournal, '', '', page, limit);
    yield put({ type: GET_HISTORY_POPUP_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* getHistoryJournal(
  action: TAction<GetHistoryJournalPayload>,
): SagaIterator {
  try {
    const update = action?.payload?.update;

    const periodFilter = yield select(selectPeriodFilter);
    const customFilters = yield select(selectHistoryCustomFilters);
    const historyLog = yield select(selectHistoryJournal);

    const customFiltersQuery = yield prepareHistoryCustomFiltersQuery(
      customFilters,
    ) as any;

    const { id, ...filters } = periodFilter;

    const query =
      Object?.keys(filters)
        .map((filter) =>
          filters[filter] !== undefined ? `${filter}=${filters[filter]}` : '',
        )
        .join('&') || '';

    const { data } = yield call(
      api.getHistoryJournal,
      query,
      customFiltersQuery,
      0,
      update ? historyLog.items.length : LIMIT_HISTORY_ITEMS_PER_PAGE,
    );
    yield put({ type: GET_HISTORY_JOURNAL_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* loadMore(action: TAction<{ startIndex: number }>): SagaIterator {
  try {
    const { startIndex } = action.payload;

    const periodFilter = yield select(selectPeriodFilter);
    const customFilters = yield select(selectHistoryCustomFilters);

    const customFiltersQuery = yield prepareHistoryCustomFiltersQuery(
      customFilters,
    ) as any;

    const { id, ...filters } = periodFilter;

    const query =
      Object?.keys(filters)
        .map((filter) =>
          filters[filter] !== undefined ? `${filter}=${filters[filter]}` : '',
        )
        .join('&') || '';

    const { data } = yield call(
      api.getHistoryJournal,
      query,
      customFiltersQuery,
      startIndex,
      LIMIT_HISTORY_ITEMS_PER_PAGE,
    );

    yield put({
      type: GET_HISTORY_JOURNAL_SUCCESS,
      payload: { data, update: true },
    });
  } catch (error) {
    showError(error);
  }
}

function* loadDebitHistoryData(action: TAction<{ date: number | null }>) {
  try {
    const { date } = action.payload;

    const { data } = yield call(api.getDebitHistoryData, date);

    yield put({ type: GET_DEBIT_HISTORY_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* loadCreditHistoryData(action: TAction<{ date: number }>) {
  try {
    const { date } = action.payload;

    const { data } = yield call(api.getCreditHistoryData, date);

    yield put({ type: GET_CREDIT_HISTORY_DATA_SUCCESS, payload: { data } });
  } catch (error) {
    showError(error);
  }
}

function* deleteHistoryOperation(
  action: TAction<HistoryActionOperationPayload>,
) {
  try {
    const { operationId, historyId, type, subType, location } = action.payload;

    yield call(api.historyDeleteOperation, {
      operationId,
      historyId,
      type,
      subType,
    });

    yield delay(DELAY_UPDATE_HISTORY_LOG);

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

function* restoreHistoryOperation(
  action: TAction<HistoryActionOperationPayload>,
) {
  try {
    const { operationId, historyId, type, subType, location } = action.payload;

    yield call(api.historyRestoreOperation, {
      operationId,
      historyId,
      type,
      subType,
    });

    yield delay(DELAY_UPDATE_HISTORY_LOG);

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

function* updateHistoryOperation(
  action: TAction<HistoryUpdateOperationPayload>,
) {
  try {
    const { operationId, data, type, subType, location } = action.payload;

    yield call(api.historyUpdateOperation, {
      operationId,
      data,
      type,
      subType,
    });

    yield delay(DELAY_UPDATE_HISTORY_LOG);

    yield put({ type: GET_LOG_ACCOUNTS_PENDING });

    yield put({
      type: UPDATE_BY_LOCATION,
      payload: { location, updateLog: true },
    });
  } catch (error) {
    showError(error);
  }
}

export default function history() {
  return all([
    takeLatest(GET_HISTORY_JOURNAL_PENDING, getHistoryJournal),
    takeLatest(HISTORY_JOURNAL_LOAD_MORE_PENDING, loadMore),
    takeLatest(GET_HISTORY_POPUP_PENDING, getHistoryPopup),
    takeLatest(GET_DEBIT_HISTORY_DATA_PENDING, loadDebitHistoryData),
    takeLatest(GET_CREDIT_HISTORY_DATA_PENDING, loadCreditHistoryData),
    takeLatest(DELETE_HISTORY_OPERATION_PENDING, deleteHistoryOperation),
    takeLatest(RESTORE_HISTORY_OPERATION_PENDING, restoreHistoryOperation),
    takeLatest(UPDATE_HISTORY_OPERATION_PENDING, updateHistoryOperation),
  ]);
}
