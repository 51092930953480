import { makeStyles } from '@material-ui/core/styles';

import { white } from '../../constants/theme-colors';
import { JournalStyledProps } from '../../types/style-props';

export const useStyles = makeStyles({
  ownDropdownContainer: {
    marginTop: 8,
    background: white,
    boxShadow: '0 4px 32px rgba(0, 0, 0, 0.16)',
    borderRadius: 12,
    width: (props: JournalStyledProps) => props.width || 'fit-content',
  },
  popper: {
    zIndex: 10000,
  },
});
