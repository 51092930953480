import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class IpalYuliBankParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UZS',
          proceedCaseConfig: {
            removeOnlyEmpty: true,
            delete: [
              {
                to: [[2, { eq: '№ пп' }]],
                count: 1,
              },
              {
                from: [[1, { eq: 'Выписка лицевых счетов за ' }]],
                count: 1,
              },
              {
                from: [[15, { in: 'Итого за ' }]],
                to: [[15, { in: 'Итого за ' }]],
              },
            ],
            fields: {
              date: { column: ['Дата документа', 5] },
              debit: { column: ['Обороты по дебету', 32] },
              credit: { column: ['Обороты по кредиту', 36] },
              comment: { column: ['Назначение платежа', 38] },
            },
          },
        },
      },
    ],
  };
}
