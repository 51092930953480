import moment from 'moment';
import { AnyAction } from 'redux';

import { EXCHANGE_RATE_DIGITS } from '../../constants';
import {
  getDateOfPaymentPeriodId,
  prepareEditData,
} from '../../foundation/Operations/HOC/utils';
import { getTimeOffset } from '../../utils/dateToUTC';
import { formatStringPrice } from '../../utils/parseStringToNumber';
import { convertExpToString } from '../../utils/stringUtils';
import { PeriodDateOfPaymentPayload } from '../operations/types';
import { ActionTypes } from './actions';
import { HistoryOperationsState, OperationType } from './types';

const now = moment.utc().valueOf() + getTimeOffset();

export const initialState: HistoryOperationsState = {
  isCopyComponent: false,
  createAndCopyState: false,
  amount: null,
  account: null,
  exchangeRate: null,
  revertedExchangeRate: null,
  toAccount: null,
  currencyAmount: null,
  editOperationId: null,
  repeats: { id: 1000 },
  splitPaymentProjects: [],
  selectedTags: null,
  comments: '',
  salaryPeriodId: null,
  taxPeriodId: null,
  incomeDate: now,
  dateOfPayment: now,
  startTimestamp: 0,
  endTimestamp: 0,
  dateOfPaymentPeriodId: null,
  dateOfPaymentWasChanged: false,
  currencyValue: null,
  revertState: false,
  loadingRates: false,
  attachment: null,
  attachments: [],
  transactionSum: null,
  transactionCurrency: null,
  transactionCurrencyRate: null,
  type: null,
  historyOperationState: null,
};

const historyOperationReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload = {} }: AnyAction,
) => {
  const {
    rate,
    amount,
    repeats,
    account,
    comments,
    toAccount,
    taxPeriodId,
    revertState,
    selectedTags,
    exchangeRate,
    currencyValue,
    currencyAmount,
    salaryPeriodId,
    isCopyComponent,
    editOperationId,
    createAndCopyState,
    revertedExchangeRate,
    splitPaymentProjects,
    dateOfPaymentWasChanged,
  } = payload;

  switch (type) {
    case ActionTypes.RESET_HISTORY_PROPS: {
      return {
        ...initialState,
        incomeDate: moment.utc().valueOf(),
      };
    }

    case ActionTypes.SET_REVERTED_EXCHANGE_RATE: {
      return {
        ...state,
        revertedExchangeRate,
      };
    }

    case ActionTypes.GET_HISTORY_EXCHANGE_RATE_FROM_TO_PENDING: {
      return {
        ...state,
        loadingRates: true,
      };
    }

    case ActionTypes.GET_HISTORY_EXCHANGE_RATE_FROM_TO_SUCCESS: {
      return {
        ...state,
        exchangeRate: convertExpToString(rate),
        loadingRates: false,
      };
    }

    case ActionTypes.SET_HISTORY_ACCOUNT: {
      return { ...state, account };
    }

    case ActionTypes.SET_HISTORY_REVERT_STATE: {
      return { ...state, revertState };
    }

    case ActionTypes.SET_HISTORY_AMOUNT: {
      return { ...state, amount };
    }

    case ActionTypes.SET_HISTORY_COMMENTS: {
      return { ...state, comments };
    }

    case ActionTypes.SET_HISTORY_CURRENCY_AMOUNT: {
      return { ...state, currencyAmount };
    }

    case ActionTypes.SET_HISTORY_CURRENCY_VALUE: {
      return { ...state, currencyValue };
    }

    case ActionTypes.SET_HISTORY_DATE_OF_PAYMENT_WAS_CHANGED: {
      return { ...state, dateOfPaymentWasChanged };
    }

    case ActionTypes.SET_HISTORY_EDIT_OPERATION_ID: {
      return { ...state, editOperationId };
    }

    case ActionTypes.SET_HISTORY_EXCHANGE_RATE: {
      return { ...state, exchangeRate };
    }

    case ActionTypes.SET_HISTORY_INCOME_DATE: {
      return {
        ...state,
        incomeDate: payload.incomeDate,
        dateOfPayment: state.dateOfPaymentWasChanged
          ? state.dateOfPayment
          : payload.incomeDate,
      };
    }

    case ActionTypes.SET_HISTORY_SPLIT_PAYMENT_PROJECTS: {
      return { ...state, splitPaymentProjects };
    }

    case ActionTypes.SET_HISTORY_REPEATS: {
      return { ...state, repeats };
    }

    case ActionTypes.SET_HISTORY_DATE_OF_PAYMENT_PERIOD_ID: {
      return { ...state, dateOfPaymentPeriodId: payload.dateOfPaymentPeriodId };
    }

    case ActionTypes.SET_HISTORY_SALARY_PERIOD_ID: {
      return { ...state, salaryPeriodId };
    }

    case ActionTypes.SET_HISTORY_TAX_PERIOD_ID: {
      return { ...state, taxPeriodId };
    }

    case ActionTypes.SET_HISTORY_PERIOD_DATE_OF_PAYMENT: {
      const { incomeDate, startTimestamp, endTimestamp } =
        payload as PeriodDateOfPaymentPayload;

      const dateOfPaymentPeriodId = getDateOfPaymentPeriodId(
        incomeDate,
        endTimestamp,
        startTimestamp,
      );

      return {
        ...state,
        startTimestamp,
        endTimestamp,
        dateOfPaymentPeriodId,
      };
    }

    case ActionTypes.SET_HISTORY_DATE_OF_PAYMENT: {
      const { dateOfPayment } = payload;

      return {
        ...state,
        dateOfPayment,
        startTimestamp: 0,
        endTimestamp: 0,
        dateOfPaymentPeriodId: null,
      };
    }

    case ActionTypes.SET_HISTORY_TO_ACCOUNT: {
      return { ...state, toAccount };
    }

    case ActionTypes.SET_HISTORY_SELECTED_TAGS: {
      return { ...state, selectedTags };
    }

    case ActionTypes.SET_HISTORY_START_TIME_STAMP: {
      return { ...state, startTimeStamp: payload.startTimeStamp };
    }

    case ActionTypes.SET_HISTORY_END_TIME_STAMP: {
      return { ...state, endTimeStamp: payload.endTimeStamp };
    }

    case ActionTypes.SET_HISTORY_IS_COPY_COMPONENT: {
      return {
        ...state,
        editOperationId: isCopyComponent ? null : state.editOperationId,
        isCopyComponent,
      };
    }

    case ActionTypes.SET_HISTORY_CREATE_AND_COPY_STATE: {
      return {
        ...state,
        editOperationId: isCopyComponent ? null : state.editOperationId,
        createAndCopyState,
      };
    }

    case ActionTypes.SET_HISTORY_OPERATION_PROPS: {
      const {
        id,
        date,
        botId,
        canEdit,
        canDelete,
        type: operationType,
        sumDouble,
        scheduled,
        taxPeriod,
        externalId,
        attachments,
        salaryPeriod,
        integrationId,
        schedulePayload,
        schedulePeriodId,
        transactionSum,
        dateOfPayment,
        exchangeRate: editExchangeRate,
        transactionCurrency,
        dateOfPaymentPeriodId,
        transactionCurrencyRate,
        comments: editComments,
        endTimestamp: editEndTimeStamp,
        startTimestamp: editStartTimeStamp,
        currencyAmount: editCurrencyAmount,
      } = prepareEditData(payload.data);

      let editRepeats;

      if (scheduled) {
        const editScheduledPayload =
          schedulePeriodId === 3 ? schedulePayload : undefined;

        editRepeats = {
          id: schedulePeriodId,
          payload: editScheduledPayload,
        };
      } else {
        editRepeats = {
          id: 1000,
        };
      }

      const editAmount = state.isCopyComponent
        ? ''
        : (sumDouble || 0).toString();

      const operationId = state.isCopyComponent ? null : id;

      let operationPropState = {
        ...state,
        canEdit,
        canDelete,
        type: operationType,
        botId,
        attachments,
        externalId,
        integrationId,
        exchangeRate: formatStringPrice(
          editExchangeRate,
          false,
          EXCHANGE_RATE_DIGITS,
        ),
        revertedExchangeRate: editExchangeRate
          ? formatStringPrice(1 / editExchangeRate, false, EXCHANGE_RATE_DIGITS)
          : null,
        counterpartyId: payload.data.counterpartyId,
        incomeDate: date,
        startTimestamp: editStartTimeStamp,
        endTimestamp: editEndTimeStamp,
        dateOfPayment,
        dateOfPaymentPeriodId,
        repeats: editRepeats,
        editOperationId: operationId,
        comments: editComments,
        transactionSum,
        transactionCurrency,
        transactionCurrencyRate,
        amount: convertExpToString(Number(editAmount)) ?? 0,
        currencyAmount: String(editCurrencyAmount / 100),
      };

      if (payload.data.type !== OperationType.transfer) {
        if (!externalId || botId) {
          if (payload.data.currencyId === transactionCurrency) {
            operationPropState = {
              ...operationPropState,
              amount: convertExpToString(Number(editAmount)) ?? 0,
              currencyAmount: String(editCurrencyAmount / 100),
            };
          } else {
            operationPropState = {
              ...operationPropState,
              amount: convertExpToString(Number(editCurrencyAmount / 100)) ?? 0,
              currencyAmount: String(editAmount),
            };
          }
        } else {
          operationPropState = {
            ...operationPropState,
            amount: convertExpToString(Number(editAmount)),
            currencyAmount: String(editCurrencyAmount / 100),
            exchangeRate: formatStringPrice(
              editExchangeRate,
              false,
              EXCHANGE_RATE_DIGITS,
            ),
          };
        }
      } else {
        operationPropState = {
          ...operationPropState,
          amount: convertExpToString(Number(editAmount)),
          currencyAmount: String(editCurrencyAmount),
        };
      }

      if (salaryPeriod) {
        operationPropState = {
          ...operationPropState,
          salaryPeriodId: salaryPeriod,
        };
      }

      if (taxPeriod) {
        operationPropState = {
          ...operationPropState,
          taxPeriodId: taxPeriod,
        };
      }

      if (payload.data.type === OperationType.transfer) {
        operationPropState = {
          ...operationPropState,
          exchangeRate: convertExpToString(
            payload.data.exchangeRate,
          ).toString(),
        };
      }

      return operationPropState;
    }

    default:
      return state;
  }
};

export default historyOperationReducer;
