import moment from 'moment';

import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class AsiaAllianceBankParser extends BaseImportParserV3 {
  private prepareNewOperation(currentOp, body) {
    (currentOp[0] = moment(currentOp[0], 'DD.MM.YYYY hh:mm:ss').toDate()),
      (currentOp[1] = moment(
        currentOp[1].replaceAll(' ', ''),
        'DD.MM.YYYY',
      ).toDate());
    currentOp[2] = currentOp[2].replaceAll(' ', '');
    currentOp[4] = currentOp[4].replaceAll(',', '').replaceAll(' ', '');
    currentOp[4] = currentOp[4] ? Number(currentOp[4]) : currentOp[4];
    currentOp[5] = currentOp[5].replaceAll(',', '').replaceAll(' ', '');
    currentOp[5] = currentOp[5] ? Number(currentOp[5]) : currentOp[5];
    body.push(currentOp);
  }

  private addToOperation(currentOp, arr) {
    currentOp[0] += currentOp[0] ? ' ' + arr[0] : arr[0];
    currentOp[1] += currentOp[1] ? ' ' + arr[1] : arr[1];
    currentOp[2] += currentOp[2] ? ' ' + arr[2] : arr[2];
    currentOp[3] += currentOp[3] ? ' ' + arr[3] : arr[3];
    currentOp[4] += currentOp[4] ? ' ' + arr[4] : arr[4];
    currentOp[5] += currentOp[5] ? ' ' + arr[5] : arr[5];
  }

  //public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        proceedCase: (rawImportDocument) => {
          const importDocument = rawImportDocument.map((arr) => {
            return [arr[0], arr[1], arr[2], arr[3] + arr[4], arr[5], arr[6]];
          });
          this.setDocumentHeader(importDocument[0]);
          const body = [];
          let currentOp = [...importDocument[1]];
          let isNextByDate = false;
          importDocument.slice(2).forEach((arr) => {
            if (
              (arr[3].startsWith('МФО') ||
                moment(arr[1], 'DD.MM.YYYY', true).isValid()) &&
              !!currentOp[1]
            ) {
              if (!isNextByDate) {
                this.prepareNewOperation(currentOp, body);
                currentOp = [...arr];
              } else {
                this.addToOperation(currentOp, arr);
              }
              if (
                !arr[3].startsWith('МФО') &&
                moment(arr[1], 'DD.MM.YYYY', true).isValid()
              ) {
                isNextByDate = true;
              } else {
                isNextByDate = false;
              }
            } else {
              this.addToOperation(currentOp, arr);
            }
          });
          if (currentOp.length) {
            this.prepareNewOperation(currentOp, body);
          }
          this.setDocumentBody(body);
          return (): ImportResultItemMask => {
            return {
              date: this.getFirstValidCellByColumn(['Дата\nпроводки', 0]),
              time: this.getFirstValidCellByColumn(['Дата\nпроводки', 0]),
              comment: this.getFirstValidCellByColumn([
                'Корреспондент: Банк/Счет/Инн Наименование Назначение\nплатежа',
                3,
              ]),
              debit: this.getFirstValidCellByColumn(['Дебbт', 4]),
              credit: this.getFirstValidCellByColumn(['Кредит', 5]),
            };
          };
        },
        caseOptions: {
          defaultCurrency: 'UZS',
          isCurCase: [
            [
              [0, 1, 2, 3, 4, 5, 6],
              { eq: 'Выпискалицевогосчета(подокументам)' },
            ],
          ],
          preParserConfigs: {
            pageSeparatorsLengthPx: 4,
            maxInterlineSpacingPx: 10,
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' }, nextWord: { eq: 'Дата' } }],
                to: [{ word: { eq: 'платежа' }, nextWord: { eq: 'Дня' } }],
              },
              delete: [
                {
                  to: [{ word: { eq: 'Дня' }, prevWord: { eq: 'платежа' } }],
                  count: 1,
                },
                {
                  from: [
                    { word: { eq: 'Сумма' }, nextWord: { eq: 'оборотов' } },
                  ],
                  to: [{ word: { eq: 'платежа' }, nextWord: { eq: 'Дня' } }],
                },
                {
                  from: [
                    { word: { eq: 'Сумма' }, nextWord: { eq: 'оборотов' } },
                  ],
                  to: [
                    {
                      word: { dateFormat: 'DD.MM.YYYY' },
                      prevWord: { eq: 'ANK' },
                    },
                  ],
                },
                {
                  from: [
                    {
                      word: {
                        regexp:
                          { str: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)' },
                      },
                    },
                  ],
                  to: [{ word: { dateFormat: 'DD.MM.YYYY' } }],
                },
                {
                  from: [
                    { word: { eq: 'Выписка' }, nextWord: { eq: 'лицевого' } },
                  ],
                  to: [
                    {
                      word: { regexp: { str: 'Page \\d+ of \\d+' } },
                      prevWord: { eq: ')' },
                    },
                  ],
                },
                {
                  from: [
                    { word: { eq: 'Сумма' }, nextWord: { eq: 'оборотов' } },
                  ],
                  to: [
                    {
                      word: {
                        isNum: true,
                        replace: [
                          { from: ',', to: '' },
                          { from: ' ', to: '' },
                        ],
                      },
                      prevWord: { dateFormat: 'DD.MM.YYYY' },
                    },
                  ],
                },
              ],
              define: [{ is: true }],
            },
          },
        },
      },
    ],
  };
}
