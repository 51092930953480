import { useCallback, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useSelector } from 'react-redux';

import { FACEBOOK_PIXEL_ID } from '../../constants';
import facebookApi from '../../services/facebook';
import { getUser } from '../../store/auth/selectors';
import { selectIsDemoCompany } from '../../store/company/selectors';
import { FacebookPixelProvider } from './FacebookPixelContext';

const options = {
  autoConfig: true,
  debug: false,
};

function FacebookPixel({ children }: { children: JSX.Element[] }) {
  const isDemo = useSelector(selectIsDemoCompany);
  const user = useSelector(getUser);

  useEffect(() => {
    if (user) {
      const advancedMatching = {
        em: user.email,
        // @ts-ignore
        fn: user.name,
        ph: user.phone,
      };

      if (!window.fbq) {
        // @ts-ignore
        ReactPixel.init(FACEBOOK_PIXEL_ID, advancedMatching, options);
        ReactPixel.grantConsent();
        ReactPixel.revokeConsent();
      }
    }
  }, [user]);

  const sendData = useCallback(async () => {
    if (!isDemo) {
      const { data } = await facebookApi.getUserDataForFacebookPixel();

      ReactPixel.trackCustom('Activation', data);
    }
  }, [isDemo]);

  return (
    <FacebookPixelProvider value={{ sendData }}>
      {children}
    </FacebookPixelProvider>
  );
}

export default FacebookPixel;
