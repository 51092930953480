import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW } from '../../../../../constants/featureToggles/featureToggle';
import useUnleash from '../../../../../hooks/useUnleash';
import { SettingsName } from '../../../../../scenes/InfoBlock/Employees/EditRoleDialog/types';
import {
  selectSettingsBorrowers,
  selectSettingsClients,
  selectSettingsCreditors,
  selectSettingsInvestors,
  selectSettingsOwners,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsTaxOrganisations,
} from '../../../../../store/clients/selectors';
import { Client, UrlPrefix } from '../../../../../store/clients/types';

function useOwnComponentProps(
  prefix: UrlPrefix | undefined,
  isEditable: boolean,
) {
  const { t } = useTranslation();
  const newUserForClientAndCategoriesView = useUnleash(
    NEW_USER_FOR_CLIENT_AND_CATEGORIES_VIEW,
  );

  const clients = useSelector(selectSettingsClients);
  const investors = useSelector(selectSettingsInvestors);
  const borrowers = useSelector(selectSettingsBorrowers);
  const creditors = useSelector(selectSettingsCreditors);
  const owners = useSelector(selectSettingsOwners);
  const staffMembers = useSelector(selectSettingsStaffMembers);
  const suppliers = useSelector(selectSettingsSuppliers);
  const taxOrganisations = useSelector(selectSettingsTaxOrganisations);

  const ownComponentProps = {
    type: '' as SettingsName,
    createButtonText: '',
    createTitle: '',
    data: [] as Client[],
    isEditable,
  };

  switch (prefix) {
    case UrlPrefix.clients: {
      ownComponentProps.createButtonText = t('clients.add');
      ownComponentProps.createTitle = t('clients.title');
      ownComponentProps.data = clients;
      ownComponentProps.type = SettingsName.clients;

      break;
    }

    case UrlPrefix.investors: {
      ownComponentProps.createButtonText = t('investors.add');
      ownComponentProps.createTitle = t('investors.title');
      ownComponentProps.data = investors;
      ownComponentProps.type = SettingsName.investors;

      break;
    }

    case UrlPrefix.borrowers: {
      ownComponentProps.createButtonText = t('borrowers.add');
      ownComponentProps.createTitle = t('borrowers.title');
      ownComponentProps.data = borrowers;
      ownComponentProps.type = SettingsName.borrowers;

      break;
    }

    case UrlPrefix.creditors: {
      ownComponentProps.createButtonText = t('creditors.add');
      ownComponentProps.createTitle = t('creditors.title');
      ownComponentProps.data = creditors;
      ownComponentProps.type = SettingsName.creditors;

      break;
    }

    case UrlPrefix.suppliers: {
      if (!newUserForClientAndCategoriesView) {
        ownComponentProps.createButtonText = t('suppliers.add');
        ownComponentProps.createTitle = t('suppliers.title');
        ownComponentProps.data = suppliers;
        ownComponentProps.type = SettingsName.suppliers;
      } else {
        ownComponentProps.createButtonText = t('counterparties.add');
        ownComponentProps.createTitle = t('counterparties.title');
        ownComponentProps.data = suppliers;
        ownComponentProps.type = SettingsName.suppliers;
      }

      break;
    }

    case UrlPrefix.staffMembers: {
      ownComponentProps.createButtonText = t('staffMembers.add');
      ownComponentProps.createTitle = t('staffMembers.title');
      ownComponentProps.data = staffMembers;
      ownComponentProps.type = SettingsName.staffMembers;

      break;
    }

    case UrlPrefix.owners: {
      ownComponentProps.createButtonText = t('owners.add');
      ownComponentProps.createTitle = t('owners.title');
      ownComponentProps.data = owners;
      ownComponentProps.type = SettingsName.owners;

      break;
    }

    case UrlPrefix.taxOrganisations: {
      ownComponentProps.createButtonText = t('taxOrganisations.add');
      ownComponentProps.createTitle = t('taxOrganisations.title');
      ownComponentProps.data = taxOrganisations;
      ownComponentProps.type = SettingsName.taxOrganisations;

      break;
    }
  }

  return { ownComponentProps };
}

export default useOwnComponentProps;
