import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  description: {
    opacity: 0.5,
    marginBottom: 24,
  },
  button: {
    background: 'linear-gradient(88.88deg, #7FDEF5 0%, #7EF5AD 100%)',
  },
});
