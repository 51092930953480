import moment from 'moment';
import xlsx from 'xlsx';

import { normalize } from '@finmap/core-utils';
import {
  AnyObject,
  BaseXlsxParser,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class XlsxParserV2 extends BaseXlsxParser {
  protected readonly FORMAT3 = 'YYYY-MM-DD HH:mm:ss';
  protected COLUMNS_MAP = {
    date: [
      [
        'Дата платежа*',
        'Дата платежу*',
        'Payment date*',
        'Data płatności*', //pl
        'Data de pagamento*',
        'Datum platby', //cs
        'Fecha de pago*', //sp
      ],
      null,
    ],
    dateOfPayment: [
      [
        'Дата начисления',
        'Дата нарахування',
        'Accrual date',
        'Data naliczania',
        'Data de acumulação',
        'Data zaksięgowania', //pl
        'Datum obdržení', //cs
        'Fecha de devengo', //sp
      ],
      null,
    ],
    periodStartDate: [
      [
        'Период начисления (дата "c")',
        'Період нарахування (дата "з")',
        'Accrual period (date "from")',
        'Okres naliczania (data „od”)',
        'Período de acumulação (data "de")',
        'Okres księgowania (data "od")', //pl
        'Časové rozlišení (datum "od")', //cs
        'Período de devengo (fecha "desde")', //sp
      ],
      null,
    ],
    periodEndDate: [
      [
        'Период начисления (дата "по")',
        'Період нарахування (дата "по")',
        'Accrual period (date "to")',
        'Okres naliczania (data „przed”)',
        'Período de acumulação (data "antes")',
        'Okres księgowania (data "do")', //pl
        'Časové rozlišení (datum "do")', //cs
        'Período de devengo (fecha "hasta")', //sp
      ],
      null,
    ],
    sum: [
      [
        'Сумма в валюте счета*',
        'Сума в валюті рахунка*',
        'Amount in account currency*',
        'Kwota w walucie konta *',
        'Valor na moeda da conta*',
        'Kwota w walucie konta*', //pl
        'Částka v měně účtu', //cs
        'Importe en la divisa de la cuenta*', //sp
      ],
      null,
    ],
    companyCurrencySum: [
      [
        'Сумма в валюте компании',
        'Сума в валюті компанії',
        'Amount in company currency',
        'Kwota w walucie firmy', //pl
        'Valor na moeda da empresa',
        'Částka v měně společnosti', //cs
        'Importe en la divisa de la compañía', //sp
      ],
      null,
    ],
    from: [
      [
        'Со счета*',
        'З рахунку*',
        'From account*',
        'Z konta *', //pl
        'De conta*',
        'Z účtu', //cs
        'De la cuenta*', //sp
      ],
      null,
    ],
    to: [
      [
        'На счет*',
        'На рахунок*',
        'To account*',
        'Na konto *', //pl
        'A conta*',
        'Na účet', //cs
        'A la cuenta*', //sp
      ],
      null,
    ],
    subType: [
      [
        'Подтип*',
        'Підтип операції*',
        'Transaction subtype*',
        'Podtyp *', //pl
        'Subtipo*',
        'Podtyp transakce', //cs
        'Subtipo de transacción', //sp
      ],
      null,
    ],
    category: [
      [
        'Категория',
        'Категорія',
        'Category',
        'Kategoria', //pl
        'Categoria',
        'Kategorie', //cs
        'Categoría', //sp
      ],
      null,
    ],
    subCategory: [
      [
        'Подкатегория',
        'Підкатегорія',
        'Subcategory',
        'Podkategoria', //pl
        'Subcategoria',
        'Podkategorie', //cs
        'Subcategoria', //sp
      ],
      null,
    ],
    counterparty: [
      [
        'Контрагент',
        'Контрагент',
        'Counterparty',
        'Partner',
        'Contraparte',
        'Kontrahent', //pl
        'Příjemce', //cs
        'Contraparte', //sp
      ],
      null,
    ],
    project: [
      [
        'Проект',
        'Проект',
        'Project',
        'Projekt', //cs, pl
        'Projeto',
        'Proyecto', //sp
      ],
      null,
    ],
    tags: [
      [
        'Теги',
        'Теги',
        'Tags',
        'Tagi', //pl
        'Tags',
        'Štítky', //cs
        'Etiquetas', //sp
      ],
      null,
    ],
    comment: [
      [
        'Комментарий',
        'Коментар',
        'Comment',
        'Komentar',
        'Comentário',
        'Komentarz', //pl
        'Komentář', //cs
        'Comentario', //sp
      ],
      null,
    ],
  };

  protected importName = 'Finmap';
  protected index: number;

  protected doBeforeTranform(): AnyObject[] {
    const errors = this.getErrors();
    const HEADERS = Object.values(this.headersJson);

    const resources = this.translationsService.getResources();
    const langs = Object.keys(resources);

    HEADERS.forEach((h, index) => {
      for (const lang of langs) {
        for (const key of Object.keys(this.COLUMNS_MAP)) {
          const { importHeaders } = resources[lang].translation;
          if (!importHeaders || !Object.keys(importHeaders).length) continue;
          const stringToCheck = importHeaders[key].replace(/\*+/g, '').trim();
          const checkString = h.replace(/\*+/g, '').trim();
          if (stringToCheck.includes(checkString))
            return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
        }
      }
    });

    const { importHeaders } = this.currentUserTranslations.translation;

    this.index = 2;

    const {
      date: [dateDest],
      sum: [sumDest],
      from: [fromDest],
      to: [toDest],
    } = this.COLUMNS_MAP;

    if (typeof dateDest !== 'string')
      this.throwError(`${importHeaders.date} ${errors.notFound}`, 1);
    if (typeof sumDest !== 'string')
      this.throwError(`${importHeaders.sum} ${errors.notFound}`, 1);
    if (typeof fromDest !== 'string' && typeof toDest !== 'string')
      this.throwError(
        `${importHeaders.from} & ${importHeaders.to} ${errors.notFound}`,
        1,
      );

    return this.sheetJson;
  }

  private lastOperationIndex;

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const allColumns = this.getAllColumns(operation, index);
    const {
      date,
      dateOfPayment,
      periodStartDate,
      periodEndDate,
      sum,
      companyCurrencySum,
      from,
      to,
      subType,
      category,
      subCategory,
      counterparty,
      project,
      tags,
      comment,
    } = allColumns;
    const resO: AnyObject = { index };

    if (!date && !dateOfPayment) {
      return this.calculateForMutientities({ allColumns, errors, index });
    }

    this.lastOperationIndex = i;
    if (date) {
      const parsedDate = this.dateParser(date);
      if (parsedDate.toString() === 'Invalid Date')
        this.throwError(`${date} - ${errors.dateNotValid}`, index);
      resO.date = this.dateToFormat3(parsedDate);
    }

    if (dateOfPayment) {
      const parsedDate = this.dateParser(dateOfPayment);
      if (parsedDate.toString() === 'Invalid Date')
        this.throwError(`${dateOfPayment} - ${errors.dateNotValid}`, index);
      resO.dateOfPayment = this.dateToFormat2(parsedDate);
    }

    let isAfter, isBefore, isAfterPayment, isBeforePayment;
    try {
      isAfter = moment(resO.date).isAfter(moment().add(10, 'y'));
      isBefore = moment(resO.date).isBefore(moment('2015-01-01', 'YYYY/MM/DD'));
      isAfterPayment = moment(resO.dateOfPayment).isAfter(
        moment().add(10, 'y'),
      );
      isBeforePayment = moment(resO.dateOfPayment).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
    } catch (e) {
      console.log(e);
    }
    if (isAfter || isAfterPayment) this.throwError(errors.maxDate, index);
    if (isBefore || isBeforePayment) this.throwError(errors.minDate, index);

    if (sum === undefined) this.throwError(errors.sumIsRequired, index);
    const parsedSum = this.parseSum(sum);
    if (isNaN(parsedSum))
      this.throwError(`${sum} - ${errors.sumNotValid}`, index);
    resO.sum = parsedSum;

    if (!from && !to) this.throwError(errors.accountIsRequired, index);

    this.addTypeAndSubType(resO, allColumns);

    if (category) resO.category = category.toString();
    if (subCategory) resO.subCategory = subCategory.toString();
    if (counterparty) resO.counterparty = counterparty.toString();
    if (project) resO.project = project.toString();
    if (tags) resO.tags = tags.toString();
    if (comment) resO.comment = comment.toString();
    if (to) resO.accountToId = to.toString();
    if (from) resO.accountFromId = from.toString();
    if (companyCurrencySum)
      resO.companyCurrencySum = this.parseSum(companyCurrencySum);
    if (dateOfPayment)
      resO.dateOfPayment = this.dateToFormat2(this.dateParser(dateOfPayment));

    this.setAccountIDsByType(resO, null, null);
    try {
      this.prepareTransactionParams(resO);
    } catch (e) {
      //
    }

    this.index++;

    this.checkCategory(resO, index, errors);
    return resO;
  }

  protected prepareTransactionParams(operation) {
    if (operation.type === OperationType.TRANSFER) return;
    const account = this.acounts.find(
      (acc) =>
        acc._id === operation.accountToId ||
        acc._id === operation.accountFromId,
    );
    const currency = account?.currency?._id;
    if (!currency) return;
    operation.transactionSum = operation.sum; //case without sum!!!
    operation.transactionCurrency = currency;
    if (operation.companyCurrencySum && operation.sum)
      operation.transactionCurrencyRate =
        operation.companyCurrencySum / operation.sum;
  }

  protected calculateForMutientities({ allColumns, errors, index }) {
    const { project, sum, companyCurrencySum } = allColumns;
    if (!project || !sum || typeof this.lastOperationIndex !== 'number')
      this.throwError(errors.dateIsRequired, index);
    const parsedSum = this.parseSum(sum);
    if (isNaN(parsedSum))
      this.throwError(`${sum} - ${errors.sumNotValid}`, index);
    const result: any = {
      rawType: 'project',
      operationIndex: this.lastOperationIndex,
      sum: parsedSum,
      label: project,
    };
    if (companyCurrencySum) {
      const parsedCompanyCurrencySum = this.parseSum(companyCurrencySum);
      if (isNaN(parsedCompanyCurrencySum))
        this.throwError(`${companyCurrencySum} - ${errors.sumNotValid}`, index);
      result.companyCurrencySum = parsedCompanyCurrencySum;
    }
    return result;
  }

  protected doAfterTranform(): AnyObject[] {
    const errors = this.getErrors();
    this.transformedData.forEach((element) => {
      const { rawType, operationIndex, sum, companyCurrencySum, label } =
        element;
      if (rawType === 'project') {
        const result = { sum, companyCurrencySum, label };
        if (!this.transformedData[operationIndex].projects) {
          this.transformedData[operationIndex].projects = [result];
        } else {
          this.transformedData[operationIndex].projects.push(result);
        }
      }
    });
    this.transformedData.forEach((element, i) => {
      const { projects, sum, companyCurrencySum, index } = element;
      if (!projects) return;
      const projectsSum = projects.reduce(
        (prev, current) => prev + current.sum || 0,
        0,
      );
      if (projectsSum?.toFixed(2) !== sum?.toFixed(2))
        this.throwError(`${sum} - ${errors.sumNotValid}`, index); // TODO custom error

      if (companyCurrencySum) {
        const projectscompanyCurrencySum = projects.reduce(
          (prev, current) => prev + current.companyCurrencySum || 0,
          0,
        );
        if (
          companyCurrencySum?.toFixed(2) !==
          projectscompanyCurrencySum?.toFixed(2)
        )
          this.throwError(
            `${companyCurrencySum} - ${errors.sumNotValid}`,
            index,
          ); // TODO custom error
      }
      this.transformedData[i].projects = projects.map((project) => ({
        ...project,
        stake: Number(((project.sum / sum) * 100).toFixed(13)),
      }));
    });
    return this.transformedData.filter((element) => !element.rawType);
  }

  protected checkCategory(resO, index, errors) {
    if (!resO.category) return;
    const resourses = this.translationsService.getResources();
    if (resO.type === OperationType.INCOME) {
      for (const key in resourses) {
        const translation = resourses[key].translation;
        const consumptions = translation.accounts.consumption;
        const categoryKey = Object.keys(consumptions).find(
          (key) => consumptions[key] === resO.category,
        );
        if (categoryKey)
          this.throwError(
            errors.systemCategory
              .replace('%s', consumptions[categoryKey])
              .replace('%s', translation.operation.income),
            index,
          );
      }
    } else if (resO.type === OperationType.CONSUMPTION) {
      for (const key in resourses) {
        const translation = resourses[key].translation;
        const incomes = translation.accounts.income;
        const categoryKey = Object.keys(incomes).find(
          (key) => incomes[key] === resO.category,
        );
        if (categoryKey)
          this.throwError(
            errors.systemCategory
              .replace('%s', incomes[categoryKey])
              .replace('%s', translation.operation.consumption),
            index,
          );
      }
    }
  }

  protected dateToFormat3(date: Date): string {
    const dateMoment = moment(date);

    const nowMoment = moment();
    const nowHours = nowMoment.hours();
    const nowMinutes = nowMoment.minutes();
    const nowSeconds = nowMoment.seconds();

    return dateMoment
      .startOf('day')
      .set('second', nowSeconds)
      .set('minute', nowMinutes)
      .set('hour', nowHours)
      .format(this.FORMAT3);
  }

  protected getAccountIdByName(accounts: any, name: string) {
    const account = accounts.filter(
      (item: any) => item.normalizedLabel === normalize(name),
    );
    const errors = this.getErrors();

    if (!account.length)
      this.throwError(
        `${errors.account} ${name} ${errors.notFound}`,
        this.index,
      );

    return account[0]._id;
  }

  private addTypeAndSubType(
    resO,
    {
      to,
      from,
      category,
      periodStartDate,
      periodEndDate,
      subType: extSubType,
    }: any,
  ) {
    const errors = this.getErrors();

    if (to) resO.type = OperationType.INCOME;
    if (from) resO.type = OperationType.CONSUMPTION;
    if (to && from) return (resO.type = OperationType.TRANSFER);
    if (!to && !from) this.throwError(errors.accountIsRequired, this.index);

    const translations = this.translationsService.getResources();
    for (const translation in translations) {
      const subTypes =
        translations[translation]?.translation?.accounts?.[resO.type];
      for (const subT in subTypes) {
        if (
          normalize(subTypes[subT]) === normalize(category) &&
          subT !== 'title'
        ) {
          resO.subType = subT;
        }
      }
    }
    if (!resO.subType) {
      for (const translation in translations) {
        const subTypes =
          translations[translation]?.translation?.accounts?.[resO.type];
        for (const subT in subTypes) {
          if (
            normalize(subTypes[subT]) === normalize(extSubType) &&
            subT !== 'title'
          ) {
            resO.subType = subT;
          }
        }
      }
    }

    if (!resO.subType && resO.type === OperationType.INCOME)
      resO.subType = 'sale';
    if (!resO.subType && resO.type === OperationType.CONSUMPTION)
      resO.subType = 'supplier';
    if (
      ['sale', 'supplier'].includes(resO.subType) &&
      periodEndDate &&
      periodStartDate
    ) {
      const parsedStartDate = this.dateParser(periodStartDate);
      if (parsedStartDate.toString() === 'Invalid Date')
        this.throwError(
          `${periodStartDate} - ${errors.dateNotValid}`,
          this.index,
        );
      const parsedEndDate = this.dateParser(periodEndDate);
      if (parsedEndDate.toString() === 'Invalid Date')
        this.throwError(
          `${periodEndDate} - ${errors.dateNotValid}`,
          this.index,
        );
      resO.periodStartDate = this.dateToFormat2(parsedStartDate);
      resO.periodEndDate = this.dateToFormat2(parsedEndDate);
    }
    if (resO.subType === 'tax' || resO.subType === 'salary') {
      if (periodStartDate) {
        const parsedDate = this.dateParser(periodStartDate);
        if (parsedDate.toString() === 'Invalid Date')
          this.throwError(
            `${periodStartDate} - ${errors.dateNotValid}`,
            this.index,
          );
        resO.periodStartDate = this.dateToFormat2(parsedDate);
      }
      if (periodEndDate) {
        const parsedDate = this.dateParser(periodEndDate);
        if (parsedDate.toString() === 'Invalid Date')
          this.throwError(
            ` - ${periodEndDate} - ${errors.dateNotValid}`,
            this.index,
          );
        resO.periodEndDate = this.dateToFormat2(parsedDate);
      }
    }
  }

  public setRawData(raw, account) {
    // : Account
    const wb = xlsx.read(raw, {
      type: 'buffer',
      raw: false,
      dateNF: this.FORMAT2WithTime,
    }); // raw: false, dateNF: this.FORMAT2WithTime
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const allSheet = xlsx.utils.sheet_to_json(sheet, {
      header: 'A',
      raw: false,
      dateNF: this.FORMAT2WithTime,
    }); // raw: false, dateNF: this.FORMAT2WithTime

    this.headersJson = allSheet[0];
    this.sheetJson = allSheet.slice(1);
  }
}
