export type Role = {
  _id: string;
  label: string;
  orderIndex: number;
  description?: string;
  systemValue: boolean;
  fastPermissions?: string[];
}

export type Employee = {
  createdAt: string;
  email: string;
  name: string;
  role: string;
  roleId: string;
  userId?: string;
  _id: string;
};

export enum PermissionKind {
  edit = 'edit',
  read = 'read',
}

export enum PermissionValue {
  patch = 'patch',
  delete = 'delete',
  post = 'post',
  put = 'put',
  get = 'get',
}

export type PermissionType = {
  _id: string;
  __v: number;
  kind: PermissionKind;
  value: PermissionValue;
};

export type ResourceItem = {
  resourceItemId: string;
  typeIds: string[];
};

export type PermissionResource = {
  resourceId: string;
  items?: ResourceItem[];
  typeIds: string[];
};

export type Resource = {
  archived: boolean;
  systemValue: boolean;
  _id: string;
  label: string;
  __v: number;
};

export enum ContextRole {
  log = 'log',
  operation = 'operation',
  settings = 'settings',
  employees = 'employees',
  analytic = 'analytic',
  calendar = 'calendar',
  invoicing = 'invoicing',
  futurePayments = 'futurePayments',
  accountsVisibility = 'accountsVisibility',
}

export type Permission = {
  context: ContextRole;
  resources: PermissionResource[];
  typeIds: string[];
};

export type CurrentRolePermissions = {
  permissions: Permission[];
  roleId: string;
};

export type EmployeesState = {
  employees: Employee[];
  invites: Employee[];
  roles: Role[];
  permissionTypes: PermissionType[];
  resources: Resource[];
  currentRolePermissions: Permission[] | null;
  loadingRolePermissions: boolean;
  currentCreatedRole: Role | null;
};

export type SetEmployeesPayload = {
  data: {
    employees: Employee[];
    invites: Employee[];
  };
};

export type UpdateEmployeePayload = {
  id: string;
  roleId: string;
  name: string;
};

export enum FastPermissions {
  read = 'read',
  readWrite = 'edit',
  delete = 'delete',
  full = 'full',
}
