// @ts-ignore
import { getDaysInMonth } from '@wojtekmaj/date-utils';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
// @ts-ignore
import { getDayOfWeek } from 'react-calendar/src/shared/dates';

import Storages, { StorageKey } from '../../../../services/Storages';

const firstDate = (value: number) => {
  if (value) {
    return moment.utc(value).format('DD.MM.YYYY');
  }

  return '';
};

const formatDateRow = (value: number[]) => {
  const first = firstDate(value[0]);
  const second = firstDate(value[1]);

  return first === second ? first : `${first} - ${second}`;
};

function ucFirst(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

const formatSingleDate = (val: number | Date) => {
  const language = Storages.get(StorageKey.language);
  const date = val;

  const i18 = i18next.getFixedT(language);

  let locale = language;

  if (language === 'cz') {
    locale = 'cs';
  } else if (language === 'uz') {
    locale = 'uz-latn';
  }

  moment.locale(locale);

  const now = moment.utc().startOf('day');
  const nextDate = moment.utc(date).startOf('day');
  const days = nextDate.diff(now, 'day');

  const formatDDMMYYYY = moment.utc(date).format('DD.MM.YYYY');
  const formatDDMMMMYYYY = moment.utc(date).format('DD.MM.YYYY');
  const formatHHMM = moment.utc(date).format('HH:mm');

  if (days === -2) {
    return `${i18('days.beforeYesterday')} ${formatDDMMYYYY}, ${formatHHMM}`;
  }

  if (days === 2) {
    return `${i18('days.dayNextDay')} ${formatDDMMYYYY}, ${formatHHMM}`;
  }

  if (days < 2 && days > -2) {
    return `${
      nextDate.calendar().split(' ')[0]
    } ${formatDDMMMMYYYY}, ${formatHHMM}`;
  }

  return `${ucFirst(
    nextDate.format('dddd'),
  )}, ${formatDDMMMMYYYY}, ${formatHHMM}`;
};

const weekCount = (activeStartDate: Date) => {
  const numberOfDays = getDaysInMonth(activeStartDate);
  const startWeekday = getDayOfWeek(activeStartDate);

  const days = numberOfDays - (7 - startWeekday);
  return 1 + Math.ceil(days / 7);
};

function getNewSelectedDate(date: Moment, timeH: string, timeM: string) {
  const newDate = new Date();

  let hours;
  let minutes;
  if (timeH) {
    hours = Number(timeH);
  } else {
    hours = newDate.getHours();
  }

  if (timeM) {
    minutes = Number(timeM);
  } else {
    minutes = newDate.getMinutes();
  }

  const seconds = newDate.getSeconds();

  return date
    .set('hours', hours)
    .set('minutes', minutes)
    .set('seconds', seconds)
    .valueOf();
}

export default {
  weekCount,
  formatDateRow,
  formatSingleDate,
  getNewSelectedDate,
};
