import { GET_SETTINGS_CONTEXTS } from '../company/actions';
import {
  CreateInvitePayload,
  CreateRolePermissionsPayload,
} from './sagas.types';
import {Role, SetEmployeesPayload, UpdateEmployeePayload} from './types';

export const UPDATE_LIST_ROLES = 'UPDATE_LIST_ROLES';

export const GET_INVITES_PENDING = 'GET_INVITES_PENDING';
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS';

export const CREATE_INVITE_PENDING = 'CREATE_INVITE_PENDING';
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';

export const DELETE_INVITE_PENDING = 'DELETE_INVITE_PENDING';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';

export const UPDATE_EMPLOYEE_PENDING = 'UPDATE_EMPLOYEE_PENDING';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';

export const DELETE_EMPLOYEE_PENDING = 'DELETE_EMPLOYEE_PENDING';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';

export const CREATE_ROLE_PENDING = 'CREATE_ROLE_PENDING';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';

export const GET_ROLES_PENDING = 'GET_ROLES_PENDING';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';

export const UPDATE_ROLE_PENDING = 'UPDATE_ROLE_PENDING';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';

export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';

export const GET_PERMISSION_TYPES_PENDING = 'GET_PERMISSION_TYPES_PENDING';
export const GET_PERMISSION_TYPES_SUCCESS = 'GET_PERMISSION_TYPES_SUCCESS';

export const GET_RESOURCES_PENDING = 'GET_RESOURCES_PENDING';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';

export const CREATE_ROLE_PERMISSIONS_PENDING =
  'CREATE_ROLE_PERMISSIONS_PENDING';
export const CREATE_ROLE_PERMISSIONS_SUCCESS =
  'CREATE_ROLE_PERMISSIONS_SUCCESS';

export const GET_ROLE_PERMISSIONS_PENDING = 'GET_ROLE_PERMISSIONS_PENDING';
export const GET_ROLE_PERMISSIONS_SUCCESS = 'GET_ROLE_PERMISSIONS_SUCCESS';

export const GET_FAST_PERMISSIONS_FOR_ROLES_PENDING = 'GET_FAST_PERMISSIONS_FOR_ROLES_PENDING';
export const GET_FAST_PERMISSIONS_FOR_ROLES_SUCCESS = 'GET_FAST_PERMISSIONS_FOR_ROLES_SUCCESS';

export const RESET_CURRENT_ROLE_PERMISSIONS = 'RESET_CURRENT_ROLE_PERMISSIONS';

export const EMPLOYEES_INIT = 'EMPLOYEES_INIT';

const loadEmployees = () => ({
  type: GET_INVITES_PENDING,
});

const getFastPermissionsForRoles = () => ({
  type: GET_FAST_PERMISSIONS_FOR_ROLES_PENDING,
});

const setEmployees = (payload: SetEmployeesPayload) => ({
  type: GET_INVITES_SUCCESS,
  payload,
});

const createRole = (label: string) => ({
  type: CREATE_ROLE_PENDING,
  payload: { label },
});
const createInvite = (props: CreateInvitePayload) => ({
  type: CREATE_INVITE_PENDING,
  payload: props,
});

const updateEmployee = (data: UpdateEmployeePayload) => ({
  type: UPDATE_EMPLOYEE_PENDING,
  payload: data,
});

const removeEmployee = (id: string) => ({
  type: DELETE_EMPLOYEE_PENDING,
  payload: { id },
});

const removeInvite = (id: string) => ({
  type: DELETE_INVITE_PENDING,
  payload: { id },
});

const updateRole = (id: string, name: string) => ({
  type: UPDATE_ROLE_PENDING,
  payload: { id, name },
});

const createRolePermissions = (payload: CreateRolePermissionsPayload) => ({
  type: CREATE_ROLE_PERMISSIONS_PENDING,
  payload,
});

const getRolePermissions = (roleId: string) => ({
  type: GET_ROLE_PERMISSIONS_PENDING,
  payload: { roleId },
});

const resetRolePermissions = () => ({
  type: RESET_CURRENT_ROLE_PERMISSIONS,
});

const getSettingsContexts = () => ({
  type: GET_SETTINGS_CONTEXTS,
});

const updateListRoles = (roles: Role[]) => ({
  type: UPDATE_LIST_ROLES,
  payload: { roles }
});

const getRoles = () => ({
  type: GET_ROLES_PENDING,
});

export default {
  getRoles,
  createRole,
  updateRole,
  setEmployees,
  createInvite,
  removeInvite,
  loadEmployees,
  updateEmployee,
  removeEmployee,
  updateListRoles,
  getRolePermissions,
  getSettingsContexts,
  resetRolePermissions,
  createRolePermissions,
  getFastPermissionsForRoles
};
