import Dialog from '../../../../components/Dialog/Dialog';
import { useStyles } from './styles';
import { Props } from './types';
import { Button, Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function RemoveAccountDialog(props: Props) {
  const { onHide, onClose, onRemove, name } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={`${t('accounts.remove.title', {
        postProcess: 'sprintf',
        sprintf: [name, '%'],
      })}`}
    >
      <div>
        <Typography className={classes.description}>
          {t('accounts.remove.description')}
        </Typography>
        <div className={classes.footer}>
          <Button
            className={cn(classes.button, classes.buttonRemove)}
            onClick={onHide}
          >
            <Typography className={classes.buttonRemoveText}>
              {t('accounts.remove.hide')}
            </Typography>
          </Button>
          <Button className={cn(classes.buttonCancel)} onClick={onRemove}>
            <Typography className={classes.buttonCancelText}>
              {t('accounts.remove.removeWithOperations')}
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default React.memo(RemoveAccountDialog);
