import {
  black,
  catskillWhite,
  flamingo,
  persianGreen,
} from '../../../theme/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: 580,
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
    marginRight: 4,
    '&:last-of-type': {
      marginBottom: 24,
    },
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 'bold',
  },
  icon: {
    marginRight: 16,
    cursor: 'pointer',
  },
  arrowIcon: {
    color: black,
    marginLeft: 'auto',
  },
  accountsSelector: {
    background: catskillWhite,
    borderRadius: 12,
    padding: '10px 16px',
    width: 206,
  },
  newAccount: {
    color: persianGreen,
  },
  paper: {
    height: 212,
  },
  list: {
    overflow: 'auto',
    marginRight: 4,
    height: 200,
    paddingBottom: 0,
  },
  sellersContainer: {
    overflow: 'auto',
    maxHeight: 400,
    paddingBottom: 0,
  },
  error: {
    fontSize: 10,
    color: flamingo,
    position: 'absolute',
    bottom: -15,
    left: 10,
  },
  accountName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
