import CloseIcon from '../../../../assets/images/svg/close-dialog.svg';
import { OperationType } from '../../../../store/operations/types';
import { black, white } from '../../../../theme/colors';
import { useStyles } from './styles';
import { ColorByOperationType, Props } from './types';
import { Button, Dialog, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function ConfirmRemoveRepeatDialog(props: Props) {
  const { type, onRemove, onClose } = props;
  const { t } = useTranslation();

  const classes = useStyles({
    background: ColorByOperationType[type],
    color: type === OperationType.transfer ? white : black,
  });

  const handleChangeCurrent = useCallback(() => {
    onRemove(false);
  }, [onRemove]);

  const handleChangeAll = useCallback(() => {
    onRemove(true);
  }, [onRemove]);

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        className: classes.root,
      }}
    >
      <img
        alt=""
        src={CloseIcon}
        className={classes.closeIcon}
        onClick={onClose}
      />
      <Typography className={classes.title}>
        {t('operationDialogs.repeatDialog.removeTitle')}
      </Typography>
      <div className={classes.footer}>
        <Button className={classes.button} onClick={handleChangeCurrent}>
          <Typography className={classes.buttonText}>
            {t('operationDialogs.repeatDialog.thisOne')}
          </Typography>
        </Button>
        <Button className={classes.button} onClick={handleChangeAll}>
          <Typography className={classes.buttonText}>
            {t('operationDialogs.repeatDialog.all')}
          </Typography>
        </Button>
      </div>
    </Dialog>
  );
}

export default React.memo(ConfirmRemoveRepeatDialog);
