import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';

export interface CustomFiltersDialogFooterButtonsProps {
  isCustomFiltersActive: boolean;
  onDoneClick(): void;
  onSaveClick(): void;
  onResetClick(): void;
}

export function CustomFiltersDialogFooterButtons({
  isCustomFiltersActive,
  onDoneClick,
  onResetClick,
  onSaveClick,
}: CustomFiltersDialogFooterButtonsProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <div className={classes.footer}>
    <div>
      <Button className={classes.buttonAccept} onClick={onDoneClick}>
        <Typography className={classes.buttonText}>
          {t('common.done')}
        </Typography>
      </Button>
      <Button className={classes.buttonClear} onClick={onSaveClick}>
        <Typography className={classes.buttonText}>
          {t('filters.user.saveFilter')}
        </Typography>
      </Button>
    </div>
    {isCustomFiltersActive && (
      <Button className={classes.buttonClear} onClick={onResetClick}>
        <Typography className={classes.buttonText}>
          {t('filters.resetFilters')}
        </Typography>
      </Button>
    )}
  </div>
}

export default CustomFiltersDialogFooterButtons;