import axios, { AxiosInstance } from 'axios';

import { BASE_URL } from '../constants';
import i18n from '../i18n';
import store from '../store/store';

class ChargeBee {
  chargeBeeInstance: any;
  instance: AxiosInstance;

  constructor() {
    const isStage =
      window.location.hostname.includes('stage') ||
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('qa');

    this.chargeBeeInstance = window.Chargebee.init({
      site: isStage ? 'finmap-test' : 'finmap',
      publishableKey: isStage
        ? 'test_k6qHOxYCY4VangYNujxcurWYMbMEakPu3'
        : 'live_0ieLEb8RgjJbtqkIGtv9fSRp1Ewp1qbS',
    });

    this.instance = axios.create({ baseURL: BASE_URL });
  }

  setAuthHeader() {
    const { user } = store.getState().auth;

    // @ts-ignore
    if (user && user.token) {
      const { headers } = this.instance.defaults;
      // @ts-ignore
      headers.common.Authorization = `Bearer ${user.token}`;
    }
  }

  setLanguageHeader() {
    let { language } = i18n;

    if (language === 'uk') {
      language = 'ua';
    }

    const { headers } = this.instance.defaults;
    headers.common['Accept-Language'] = language;
  }

  makeInstance() {
    this.setAuthHeader();
    this.setLanguageHeader();

    return this.instance;
  }

  makeChargeBeeInstance() {
    return this.chargeBeeInstance;
  }
}
export { ChargeBee };
export default new ChargeBee();
