import { useCallback } from 'react';

function useDownload() {
  const onDownload = useCallback((fileName: string, reader: string | ArrayBuffer | null) => {
    if (fileName) {
      const link = document.createElement('a');

      document.body.appendChild(link);

      const json = JSON.stringify(reader);
      const blob = new Blob([json], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(url);
    }
  }, []);

  return { onDownload };
}

export default useDownload;
