import moment from 'moment';
import xlsx from 'xlsx';

import { Account } from '@finmap/core-entities/core-accounts';
import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

const UPPER_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export class OTPUkraineXlsParser extends BaseXlsxParser {
  protected importName = 'OTPUkraine';
  private mainAccount: Account;
  protected beforeTransformed: AnyObject[];
  protected currency = 'UAH';

  protected COLUMNS_MAP = {
    operation: [['Операція'], null],
    counterparty: [['Найменування контрагента'], null],
    date: [['Дата проведення'], null],
    sum: [['Сума'], null],
    comment: [['Призначення платежу'], null],
    currency: [['Валюта'], null],
  };

  protected doBeforeTranform(): AnyObject[] {
    const HEADERS = Object.values(this.headersJson);

    HEADERS.forEach((h, index) => {
      for (const [key, value] of Object.entries(this.COLUMNS_MAP)) {
        const [possibleNames] = value;
        if (possibleNames[0] === h)
          return (this.COLUMNS_MAP[key] = [h, UPPER_ALPHABET[index]]);
      }
    });

    return this.sheetJson;
  }

  protected transformOne(operation: AnyObject, index: number): AnyObject {
    const currentColumns = this.getAllColumns(operation, index);

    try {
      const isAfter = moment(currentColumns.date).isAfter(
        moment().add(10, 'y'),
      );
      const isBefore = moment(currentColumns.date).isBefore(
        moment('2015-01-01', 'YYYY/MM/DD'),
      );
      if (isAfter || isBefore) return {};
    } catch (e) {
      console.log(e);
    }

    const { type, subType, accountFromId, accountToId, sum } =
      this.getTypeAndSum(currentColumns);
    const comment = currentColumns.comment;

    const resultObject = {
      index,
    };
    this.addIfNotFalsy(resultObject, {
      date: this.dateToFormat2(this.dateParser(currentColumns.date)),
      accountFromId,
      accountToId,
      counterparty: currentColumns.counterparty,
      sum,
      type,
      subType,
      comment,
    });

    const currency = currentColumns.currency.toUpperCase();
    const currencyCode = CURRENCIES.find((cur) =>
      [cur.code, cur.native].includes(currency),
    )?.code;
    this.setAccountIDsByType(resultObject, currencyCode, currencyCode);

    return resultObject;
  }

  private getTypeAndSum(currentColumns: AnyObject) {
    let obj = {
      sum: undefined,
      type: undefined,
      subType: undefined,
      accountFromId: undefined,
      accountToId: undefined,
    };

    if (currentColumns.operation === 'Дебет') {
      obj.sum = this.parseSum(currentColumns.sum);
      obj.type = OperationType.CONSUMPTION;
      obj.subType = 'supplier';
      obj.accountFromId = this.mainAccount.normalizedLabel;
    } else if (currentColumns.operation === 'Кредит') {
      obj.sum = this.parseSum(currentColumns.sum);
      obj.type = OperationType.INCOME;
      obj.subType = 'sale';
      obj.accountToId = this.mainAccount.normalizedLabel;
    }

    return obj;
  }

  public setRawData(raw, account) {
    console.log(account);

    this.mainAccount = account;
    const wb = xlsx.read(raw, { type: 'buffer', cellDates: true });
    const wsName = wb.SheetNames[0];
    const sheet = wb.Sheets[wsName];

    const allSheet = xlsx.utils.sheet_to_json(sheet, { header: 'A' });

    this.headersJson = allSheet[0];
    this.sheetJson = allSheet.slice(1);
  }
}
