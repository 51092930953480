import { Tooltip } from '@material-ui/core';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CalendarIcon from '../../../../assets/images/svg/payment-calendar.svg';
import QuestionIcon from '../../../../assets/images/svg/payment-question.svg';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import usePermissions from '../../../../hooks/usePermissions';
import operationActios from '../../../../store/operations/actions';
import {
  OperationSubType,
  OperationType,
} from '../../../../store/operations/types';
import {
  maxOperationDate,
  minOperationDate,
} from '../../../../utils/dateFormat';
import CalendarDialog from '../CalendarComponent';
import CalendarComponent from '../CalendarComponent/CalendarComponent';
import CustomPeriod from '../CustomPeriod';
import RenderVariantRow from './RenderVariantRow';
import { useStyles } from './styles';
import { DayOfPaymentVariant, Props } from './types';

function DateOfPaymentComponent(props: Props) {
  const {
    type,
    isEdit,
    minDate,
    subType,
    maxDate,
    periodId,
    isCompare,
    difference,
    incomeDate,
    endTimestamp,
    dateOfPayment,
    startTimestamp,
    isCustomCategory,
    onChangePeriodId,
    onChangeDateOfPayment,
    onChangeDateOfPaymentPeriod,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { operationEnable } = usePermissions();

  const dispatch = useDispatch();

  const [activeTooltip, setActiveTooltip] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(
    DayOfPaymentVariant.singleDay,
  );

  const handleSetActiveTooltip = useCallback(() => {
    if (!isCompare) {
      setActiveTooltip(true);
    }
  }, [isCompare]);

  const handleSetInactiveTooltip = useCallback(() => {
    setActiveTooltip(false);
  }, []);

  const handleCloseCalendar = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleChangeDateOfPaymentPeriod = useCallback(
    (data: number[]) => {
      onChangeDateOfPaymentPeriod(data[0], data[1]);
    },
    [onChangeDateOfPaymentPeriod],
  );

  const handleChangeSingleDate = useCallback(
    (date: number) => {
      onChangeDateOfPayment(date);

      handleCloseCalendar();
    },
    [onChangeDateOfPayment, handleCloseCalendar],
  );

  const handleChangeView = useCallback(() => {
    if (!isCompare) {
      setCalendarView(true);
    }
  }, [isCompare]);

  const IconWithTooltip = useCallback(
    () => (
      <div>
        <Tooltip
          arrow
          onOpen={handleSetActiveTooltip}
          onClose={handleSetInactiveTooltip}
          classes={{
            tooltip: classes.tooltipContainer,
            arrow: classes.arrow,
          }}
          title={t('ticks.operationModal') || ''}
          placement="top"
        >
          <img
            src={QuestionIcon}
            alt="question"
            className={cn(!activeTooltip && classes.helpInfoIcon)}
          />
        </Tooltip>
      </div>
    ),
    [
      activeTooltip,
      classes,
      handleSetActiveTooltip,
      handleSetInactiveTooltip,
      t,
    ],
  );

  const handleChangeVariant = useCallback(
    (value: DayOfPaymentVariant) => {
      setSelectedVariant(value);

      if (value === DayOfPaymentVariant.singleDay) {
        dispatch(
          operationActios.setPeriodDateOfPayment({
            endTimestamp: 0,
            startTimestamp: 0,
            incomeDate,
          }),
        );
        dispatch(operationActios.setDateOfPayment(incomeDate));

        setCalendarView(true);
      } else {
        dispatch(
          operationActios.setPeriodDateOfPayment({
            endTimestamp: moment.utc(incomeDate).endOf('month').valueOf(),
            startTimestamp: moment.utc(incomeDate).startOf('month').valueOf(),
            incomeDate,
          }),
        );
      }
    },
    [dispatch, incomeDate],
  );

  useEffect(() => {
    if (isEdit) {
      if (periodId) {
        setCalendarView(true);
      }

      setSelectedVariant(
        periodId ? DayOfPaymentVariant.period : DayOfPaymentVariant.singleDay,
      );
    }
  }, [isEdit, periodId, isCustomCategory, dispatch]);

  const theSameDay = moment(dateOfPayment).isSame(moment(incomeDate), 'day');

  const prefix =
    subType === OperationSubType.salary
      ? 'operationDialogs.salaryPrefix'
      : undefined;

  if (calendarView || !theSameDay || difference) {
    return (
      <>
        <RenderVariantRow
          selectedVariant={selectedVariant}
          onChangeVariant={handleChangeVariant}
          type={type}
          isCustomCategory={isCustomCategory}
          isCompare={isCompare}
        />
        {selectedVariant === DayOfPaymentVariant.singleDay && (
          <CalendarComponent
            isCompare={isCompare}
            difference={difference}
            fullWidth
            value={dateOfPayment}
            onChange={handleChangeSingleDate}
            placeholder={
              type === OperationType.income
                ? t('operationDialogs.dayOfPayment.labelIncome')
                : t('operationDialogs.dayOfPayment.labelConsumption')
            }
            maxDate={maxDate}
            minDate={minDate}
          />
        )}
        {selectedVariant === DayOfPaymentVariant.period && (
          <div className={classes.margin16}>
            <CustomPeriod
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              isEdit={isEdit}
              isCompare={isCompare}
              difference={difference}
              prefix={prefix}
              periodId={periodId}
              onChangePeriodId={onChangePeriodId}
              date={incomeDate}
              onChange={handleChangeDateOfPaymentPeriod}
              minDate={minOperationDate()}
              maxDate={maxOperationDate(incomeDate)}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <ButtonRowWithIcons
        id="cypress-dayOfPayment-id"
        onClick={handleChangeView}
        disabled={isCompare || !operationEnable}
        wrapClasses={cn(difference && classes.compareRoot)}
        label={t('operationDialogs.dayOfPayment.title')}
        alt="calender"
        icon={CalendarIcon}
        SecondIconComponent={!isCompare ? IconWithTooltip : null}
      />
      {openDialog && (
        <CalendarDialog
          value={new Date(startTimestamp)}
          onClose={handleCloseCalendar}
          onChange={handleChangeSingleDate}
          maxDate={maxDate}
          minDate={minDate}
        />
      )}
    </>
  );
}

export default React.memo(DateOfPaymentComponent);
