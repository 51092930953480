import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  listRoot: {
    padding: 0,
    marginBottom: 18,
  },
  item: {
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 0,
    marginRight: 0,
    '&:hover': {
      marginRight: 0,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
