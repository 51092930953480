import parse from 'csv-parse/lib/sync';

import { BaseImportPreParser } from './base-import-preparser';

export class BaseCSVPreParser extends BaseImportPreParser {
  async getRawData(
    buffer: ArrayBuffer,
    {
      CSVDelimiter = ',',
      simpleParse = false,
    }: { CSVDelimiter?: string; simpleParse?: boolean } = {},
  ): Promise<string[][]> {
    const OPTIONS = { delimiter: CSVDelimiter, bom: true };

    if (simpleParse) {
      return (Buffer.from(buffer) || '')
        .toString()
        .split(/[\r\n]+/)
        .filter((a) => !!a.length)
        .map((a) => {
          const res: string[] = [''];
          let lastEl = 0;
          for (const el of a.split('')) {
            if (
              el === CSVDelimiter &&
              res[lastEl].split('').filter((e) => e === '"').length % 2 === 0
            ) {
              if (res[lastEl].startsWith('"') && res[lastEl].endsWith('"'))
                res[lastEl] = res[lastEl].slice(1, -1);
              lastEl++;
              res[lastEl] = '';
            } else res[lastEl] += el;
          }
          if (res[0].startsWith('"') && res[0].endsWith('"'))
            res[0] = res[0].slice(1, -1);
          if (res[lastEl].startsWith('"') && res[lastEl].endsWith('"'))
            res[lastEl] = res[lastEl].slice(1, -1);
          return res;
        });
    }
    const result: string[][] = parse(Buffer.from(buffer), OPTIONS);

    return result;
  }

  async preParse(
    buffer: ArrayBuffer,
    {
      CSVDelimiter = ',',
      simpleParse = false,
    }: { CSVDelimiter?: string; simpleParse?: boolean } = {},
  ): Promise<string[][]> {
    return this.getRawData(buffer, { CSVDelimiter, simpleParse });
  }
}
