import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import CustomButton from '../../../../../../components/Button';
import Dialog from '../../../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../../../components/TextField/TextFieldComponent';
import companyActions from '../../../../../../store/company/actions';

export type Props = {
  onClose(): void;
};

const validationSchema = Yup.object().shape({
  webHookUrl: Yup.string().url('errors.wrongUrl').required('errors.reqField'),
  webHookName: Yup.string().required('errors.reqField'),
});

function CreateWebhookDialog(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [webHookName, setWebHookName] = useState('');
  const [webHookUrl, setWebHookUrl] = useState('');

  const [nameError, setNameError] = useState('');
  const [urlError, setUrlError] = useState('');

  const validateFields = useCallback(
    async () =>
      validationSchema
        .validate({ webHookName, webHookUrl })
        .then(() => true)
        .catch((err) => {
          if (err.path === 'webHookUrl') {
            setUrlError(t(err.errors));
          }

          if (err.path === 'webHookName') {
            setNameError(t(err.errors));
          }

          return false;
        }),
    [webHookName, webHookUrl, t],
  );

  const handleChangeName = useCallback((val: string) => {
    setWebHookName(val);
    setNameError('');
  }, []);

  const handleChangeUrl = useCallback((val: string) => {
    setWebHookUrl(val);
    setUrlError('');
  }, []);

  const handleCreate = useCallback(async () => {
    if (await validateFields()) {
      dispatch(
        companyActions.createWebHook({ name: webHookName, url: webHookUrl }),
      );

      onClose();
    }
  }, [validateFields, dispatch, webHookName, webHookUrl, onClose]);

  return (
    <Dialog
      title={t('webhook.new', {
        postProcess: 'sprintf',
        sprintf: [''],
      })}
      onClose={onClose}
      isOpened
    >
      <TextFieldComponent
        value={webHookUrl}
        onChange={handleChangeUrl}
        placeholder={t('webhook.url')}
        isError={Boolean(urlError)}
        errorText={urlError}
      />
      <TextFieldComponent
        value={webHookName}
        onChange={handleChangeName}
        placeholder={t('webhook.name')}
        isError={Boolean(nameError)}
        errorText={nameError}
      />
      <CustomButton
        fullWidth
        action={handleCreate}
        title={t('webhook.create')}
      />
    </Dialog>
  );
}

export default React.memo(CreateWebhookDialog);
