import { makeStyles } from '@material-ui/core/styles';

import { black, deYork, white } from '../../../theme/colors';

export const useStyles = makeStyles({
  root: {
    background: deYork,
    borderRadius: 12,
    width: '100%',
    marginBottom: 15,
    position: 'relative',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 14px',
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
    lineHeight: '40px',
    color: white,
  },
  limitTitle: {
    width: '80%',
  },
  buttonRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: white,
    borderRadius: 30,
    width: 140,
    height: 32,
    cursor: 'pointer',
  },
  buttonRootAbsolute: {
    position: 'absolute',
    bottom: 10,
    right: 14,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 800,
    lineHeight: '25px',
    color: black,
  },
});
