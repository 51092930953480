import React from 'react';

import CloseDialogIconSvg from '../../assets/images/svg/close-dialog.svg';

export interface CloseDialogIconProps {
  className?: string;
  isActive?: boolean;
  onClick?(): void;
}

export function CloseDialogIcon({
  className,
  isActive = true,
  onClick,
}: CloseDialogIconProps) {
  if(!isActive) return null;

  return <img
    alt=""
    src={CloseDialogIconSvg}
    className={className}
    onClick={onClick}
  />
}

export default CloseDialogIcon;