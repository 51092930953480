import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';
export class BSIBankImportParser extends BaseImportParserV3 {
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            pageSeparatorsLengthPx: 14,
            interlineSpacingAccuracy: 5,
            separatorsDistanceModifier: 0.6,
            maxInterlineSpacingPx: 5,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [
                  { word: { in: 'Data Mov.' }, nextWord: { in: 'Valuta' } },
                ],
                to: [
                  { word: { in: 'Descrizione' }, prevWord: { in: 'Segno' } },
                ],
              },
              delete: [
                {
                  to: [
                    { word: { in: 'Descrizione' }, prevWord: { in: 'Segno' } },
                  ],
                  count: 1,
                },
                {
                  from: [{ word: { startsWith: 'Totale Avere' } }],
                },
              ],
              define: [{ dateFormat: 'DD/MM/YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              credit: {
                column: ['Importo', 2],
                split: { by: ' ', get: 0 },
                if: {
                  eq: [
                    {
                      column: ['Segno', 3],
                      replaceOne: [{ from: { regexp: { str: '\\s' } }, to: '' }],
                    },
                    '+',
                  ],
                },
              },
              debit: {
                column: ['Importo', 2],
                split: { by: ' ', get: 0 },
                if: {
                  eq: [
                    {
                      column: ['Segno', 3],
                      replaceOne: [{ from: { regexp: { str: '\\s' } }, to: '' }],
                    },
                    '-',
                  ],
                },
              },
              date: { column: ['Valuta', 1] },
              comment: { column: ['Descrizione', 4] },
              currency: { column: ['Importo', 2], split: { by: ' ', get: 1 } },
            },
          },
        },
      },
    ],
  };
}
