import React, { useCallback, useMemo, useState } from "react";
import { InputAdornment, TextField as TextFieldMaterial } from '@material-ui/core';
import cn from 'classnames';

import { useStyles } from './styles';
import { MAX_INPUT_LENGTH } from '../../constants/params';
import TransitionComponent from '../../atoms/transaction-component';
import './text-field.module.scss';
import InputEye from '../../atoms/input-eye'

export interface TextFieldComponentProps {
  id?: string;
  value: number | string | null;
  placeholder: string;
  label?: string;
  multiline?: boolean;
  disabled?: boolean;
  isError?: boolean;
  errorText?: string;
  rootClassName?: string;
  type?: string;
  fullWidth?: boolean;
  name?: string;
  injectClassName?: string;
  active?: boolean;
  maxLength?: number;
  isCompare?: boolean;
  difference?: boolean;
  wrapperInputRoot?: string;
  containerClassName?: string;
  readableOnly?: boolean;
  AdornmentComponent?: any;
  textEllipsis?: boolean;
  loading?: boolean;
  unlimited?: boolean;
  wrapErrorClassName?: string;
  isLabelNecessary?: boolean;

  onKeyDown?(event: any): void;
  onChange?(value: number | string): void;
  onFocus?(): void;
  onBlur?(): void;
  link?(): void;
};

function TextFieldComponent(props: TextFieldComponentProps) {
  const {
    id,
    type,
    name,
    link,
    value,
    label,
    onBlur,
    active,
    isError,
    onFocus,
    loading,
    onChange,
    disabled,
    multiline,
    onKeyDown,
    maxLength,
    errorText,
    unlimited,
    placeholder,
    readableOnly,
    textEllipsis,
    rootClassName,
    injectClassName,
    wrapperInputRoot,
    containerClassName,
    AdornmentComponent,
    wrapErrorClassName,
    fullWidth = true,
    isLabelNecessary = false,
  } = props;
  const classes = useStyles({
    isError,
    multiline: !!multiline,
    height: multiline ? 90 : 'unset',
  });

  const [showPW, setShowPW] = useState(false);

  const handleShowPW = useCallback(() => {
    setShowPW(true);
  }, []);

  const handleClosePW = useCallback(() => {
    setShowPW(false);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const currentType = type === 'password' && showPW ? 'text' : type;

  const inputProps = useMemo(() => {
    if (unlimited) {
      return  {
        className: cn(textEllipsis && classes.textEllipsis)
      };
    }

    return {
      maxLength: maxLength || MAX_INPUT_LENGTH,
      className: cn(textEllipsis && classes.textEllipsis),
    }
  }, [unlimited, textEllipsis, classes, maxLength]);

  return (
    <div className={cn(classes.root, isError && classes.error, rootClassName)}>
      <div className={cn(classes.container, containerClassName)}>
        <TextFieldMaterial
          id={id}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={active}
          autoComplete="new-password"
          name={name}
          fullWidth={fullWidth}
          type={currentType}
          disabled={disabled || readableOnly}
          className={cn(classes.textRoot, injectClassName)}
          multiline={multiline}
          onChange={handleChange}
          value={loading ? '' : value}
          label={isLabelNecessary ? label : (!!value && (label || placeholder))}
          placeholder={loading ? '' : placeholder}
          onKeyDown={onKeyDown}
          inputProps={inputProps}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            classes: {
              root: cn(classes.inputRoot, wrapperInputRoot),
              disabled: cn(readableOnly && classes.fullOpacity),
            },
            endAdornment: !!AdornmentComponent && !loading && (
              <InputAdornment position="end">
                {AdornmentComponent}
              </InputAdornment>
            ),
            startAdornment: loading && (
              <InputAdornment position="start" className="text-field">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: {
              disabled: cn(readableOnly && classes.fullOpacity),
            },
            shrink: true,
          }}
        />
        {type === 'password' && (
          <InputEye 
          isOpen={true} 
          className={classes.hint} 
          onClick={handleShowPW} 
        />)}
        
        {type === 'password' && showPW && (
          <InputEye 
            isOpen={false} 
            className={classes.hint} 
            onClick={handleClosePW} 
          />)}
      </div>
      <TransitionComponent
        className={cn('error', wrapErrorClassName)}
        text={errorText || ''}
        enter={!!isError}
        link={link}
      />
    </div>
  );
}

export const TextField = React.memo(TextFieldComponent);

export default TextField;
