import Http from '../Http';

const getCredentials = (login: string, password: string, licenseKey: string) =>
  Http.makeInstance().post('/integrations/checkboxApiCredentials', {
    login,
    password,
    licenseKey,
  });

export default {
  getCredentials,
};
