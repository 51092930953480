import CustomButton from "../../../../components/Button";
import Dialog from "../../../../components/Dialog/Dialog";
import { flamingo } from "../../../../theme/colors";
import { useStyles } from './styles';
import { Typography } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onClose(): void;
}

function FondyNotReadyDialog(props: Props) {
  const { onClose } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      isOpened
      onClose={onClose}
      title={t('common.error')}
    >
      <Typography className={cn(classes.lastMargin, classes.placeholder)}>
        {t('bank.fondy.errors.notAvailable')}
      </Typography>

      <CustomButton
        textColor={flamingo}
        className={classes.button}
        title={t('bank.repeatAuth')}
        action={onClose}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(FondyNotReadyDialog);
