import { PAYPAL_UNIVERSAL_NAME, WISE_UNIVERSAL_NAME } from '../../../constants';
import { NordigenBankType } from '../../../store/integrations/types';

export const defaultNordigenBanks: NordigenBankType[] = [
  {
    id: 'PAYPAL_PPLXLULL',
    logoLink: 'https://cdn.nordigen.com/ais/PAYPAL_PPLXLULL.png',
    maxTakingDaysNumber: 90,
    name: PAYPAL_UNIVERSAL_NAME,
  },
  {
    id: 'REVOLUT_REVOLT21',
    logoLink: 'https://cdn.nordigen.com/ais/REVOLUT_REVOGB21.png',
    maxTakingDaysNumber: 730,
    name: 'Revolut',
  },
  {
    id: 'WISE_TRWIGB22',
    logoLink: 'https://cdn.nordigen.com/ais/WISE_TRWIGB22.png',
    maxTakingDaysNumber: 730,
    name: WISE_UNIVERSAL_NAME,
  },
];

export const stripeNordigenBank: NordigenBankType = {
  id: 'STRIPE_STPUIE21',
  logoLink: 'https://cdn.nordigen.com/ais/STRIPE_STPUIE21.png',
  maxTakingDaysNumber: 730,
  name: 'Stripe',
};

export const revoluteNordigenBank: NordigenBankType = {
  id: 'REVOLUT_REVOLT21',
  logoLink: 'https://cdn.nordigen.com/ais/REVOLUT_REVOGB21.png',
  maxTakingDaysNumber: 730,
  name: 'Revolut',
};

export const wiseNordigenBank: NordigenBankType = {
  id: 'WISE_TRWIGB22',
  logoLink: 'https://cdn.nordigen.com/ais/WISE_TRWIGB22.png',
  maxTakingDaysNumber: 730,
  name: WISE_UNIVERSAL_NAME,
};

export const paypalNordigenBank: NordigenBankType = {
  id: 'PAYPAL_PPLXLULL',
  logoLink: 'https://cdn.nordigen.com/ais/PAYPAL_PPLXLULL.png',
  maxTakingDaysNumber: 90,
  name: PAYPAL_UNIVERSAL_NAME,
};
