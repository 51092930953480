import { Typography } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BannerUA from '../../../../../assets/images/referral/banner_referal_ua.png';
import { useStyles } from './styles';

function RenderUK() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.logoContainer}>
        <img src={BannerUA} alt="title" className={classes.logo} />
      </div>
      <Typography className={cn(classes.text, classes.marginBottom)}>
        {t('userMenu.referralProgram.condition')}
      </Typography>
      <ol className={classes.list}>
        <li>
          <Typography className={cn(classes.text, classes.marginBottom)}>
            {t('userMenu.referralProgram.description1')}
          </Typography>
        </li>
        <li>
          <Typography className={cn(classes.text, classes.marginBottom)}>
            {t('userMenu.referralProgram.description2')}
          </Typography>
        </li>
        <li>
          <Typography className={cn(classes.text, classes.marginBottom)}>
            {t('userMenu.referralProgram.description3')}
          </Typography>
        </li>
      </ol>
      <Typography className={cn(classes.text, classes.marginBottom)}>
        {t('userMenu.referralProgram.description4')}
      </Typography>
    </>
  );
}

export default React.memo(RenderUK);
