import moment from 'moment';

import {
  AnyObject,
  BaseXlsxParser,
  CURRENCIES,
  OperationType,
} from '@finmap/import-parsers/base-xlsx-parser';

export class Finmap1XlsxParser extends BaseXlsxParser {
  protected readonly COLUMNS_MAP = {
    date: [
      'Дата',
      'A',
      (date) => {
        if (!this.checkIfNotFalsy(date))
          return (errors) => `${errors.dateIsRequired}`;
        const isDate = date instanceof Date;
        if (!isDate) return (errors) => `${date} - ${errors.dateNotValid}`;
      },
    ],
    operationType: [
      'Тип операции',
      'B',
      (type) => {
        if (!this.checkIfNotFalsy(type))
          return (errors) => `${errors.typeIsRequired}`;
        const isType = ['Перевод', 'Расход', 'Доход'].some((w) => w === type);
        if (!isType) return (errors) => `${type} - ${errors.typeNotValid}`;
      },
    ],
    sum: ['Сумма', 'C', this.sumErrorer],
    currency: ['Валюта', 'D'],
    course: ['Курс', 'E'],
    bill: [
      'Счет',
      'F',
      (bill) => {
        if (!this.checkIfNotFalsy(bill))
          return (errors) => `${errors.accountIsRequired}`; // поле сумма должно быть заполнено???
        const isBill = typeof bill === 'string' && bill.length > 0;
        if (!isBill)
          return (errors) => `${errors.account} ${bill} ${errors.notFound}`;
      },
    ],
    counterparty: ['Контрагент', 'G'],
    onBill: ['На счет', 'H'],
    counterpartyOnBill: ['Контрагент (на счет)', 'I'],
    category: ['Категория', 'J'],
    project: ['Проект', 'K'],
    tags: ['Теги', 'L'],
    comment: ['Комментарий', 'M'],
    planned: ['Плановая', 'N'],
    repeatable: ['Повторяемая', 'O'],
  };

  private IS_NOT = {
    counterparty: ['Без контрагента', 'нет'],
    project: ['Без проекта'],
    category: ['Без категории'],
  };
  protected importName = 'Finmap1.0';

  private checkIfNot(value: string | undefined, arr) {
    const isNot = arr.every((not) => not !== value);
    return isNot ? value : undefined;
  }

  private getOpType(strType: string): OperationType {
    switch (strType) {
      case 'Доход':
        return OperationType.INCOME;
      case 'Расход':
        return OperationType.CONSUMPTION;
      case 'Перевод':
        return OperationType.TRANSFER;
    }
  }

  protected transformOne(operation: AnyObject, i: number): AnyObject {
    const errors = this.getErrors();
    const index = i + 2;
    const {
      comment,
      operationType,
      sum,
      counterparty,
      project,
      date,
      bill,
      category,
      onBill,
      tags,
      currency,
    } = this.getAllColumns(operation, index);

    let isAfter, isBefore;
    try {
      isAfter = moment(date).isAfter(moment().add(10, 'y'));
      isBefore = moment(date).isBefore(moment('2015-01-01', 'YYYY/MM/DD'));
    } catch (e) {
      console.log(e);
    }
    if (isAfter) this.throwError(errors.maxDate, index);
    if (isBefore) this.throwError(errors.minDate, index);

    const type = this.getOpType(operationType);

    const resO: AnyObject = {
      type,
      sum,
      index,
      date: this.dateToFormat2(date),
      // dateOfPayment: this.dateToFormat2(dateOfAccrual),
    };

    let accountFromId;
    let accountToId;

    if (type === OperationType.INCOME) accountToId = bill;
    if (type === OperationType.CONSUMPTION) accountFromId = bill;
    if (type === OperationType.TRANSFER) {
      accountFromId = bill;
      accountToId = onBill;
      resO.companyCurrencySum = sum;
      delete resO.sum;
    }
    const chackedCategory = this.checkIfNot(category, this.IS_NOT.category);

    this.addIfNotFalsy(resO, {
      category: chackedCategory,
      subType: this.getSubType(type, chackedCategory),
      counterparty: this.checkIfNot(counterparty, this.IS_NOT.counterparty),
      project: this.checkIfNot(project, this.IS_NOT.project),
      accountFromId,
      accountToId,
      tags,
    });

    const finCurrency: any = CURRENCIES.find((cur) => cur.value === currency);
    const finCurrencyCode = finCurrency ? finCurrency.code : 'UAH';
    this.setAccountIDsByType(resO, finCurrencyCode, finCurrencyCode);

    this.setComments(resO, [comment]);

    return resO;
  }
}
